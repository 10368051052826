.table {
  .thead-light {
    th {
      color: $table-head-color;
      background-color: $table-head-bg;
    }
  }
}

.table-striped {
  tbody {
    tr {
      &:nth-of-type(odd) {
        background-color: $table-accent-bg;
      }
    }
  }
}

.table-hover {
  tbody {
    tr {
      &:hover {
        background-color: $table-hover-bg;
      }
    }
  }
}
