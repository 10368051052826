/*!
 * FSO-Bootstrap v1.1.0 (https://bootstrap.docs.fso.arizona.edu/)
 * Copyright 2017-2018 FSO IT - FAST Team
 * Licenced under MIT (https://gitlab.fso.arizona.edu/FAST/fso-bootstrap/blob/master/LICENSE)
 * A theme for Bootstrap
 */
@import "https://cdn.uadigital.arizona.edu/lib/ua-brand-fonts/1.0.0/milo.min.css";
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #0c234b;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #ab0520;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --bloom: #ef4056;
  --chili: #8b0015;
  --sky: #81d3eb;
  --oasis: #378dbd;
  --azurite: #1e5288;
  --midnight: #001c48;
  --cool-gray: #e2e9eb;
  --warm-gray: #f4ede5;
  --leaf: #70b865;
  --river: #007d84;
  --silver: #9eabae;
  --mesa: #a95c42;
  --ash: #403635;
  --sage: #4a634e;
  --black: #000;
  --primary: #1e5288;
  --secondary: #9eabae;
  --success: #70b865;
  --info: #81d3eb;
  --warning: #f19e1f;
  --danger: #ef4056;
  --light: #dee2e6;
  --dark: #343a40;
  --primary-dark: #0c234b;
  --primary-light: #378dbd;
  --accent: #ab0520;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: MiloWeb, Verdana, Geneva, sans-serif;
  --font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: MiloWeb, Verdana, Geneva, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #8b0015;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #3f0009;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(222, 226, 230, 0.4);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(222, 226, 230, 0.5);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c0cfde;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #8aa5c1;
}

.table-hover .table-primary:hover {
  background-color: #afc2d5;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #afc2d5;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #e4e7e8;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #cdd3d5;
}

.table-hover .table-secondary:hover {
  background-color: #d6dbdc;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #d6dbdc;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d7ebd4;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #b5daaf;
}

.table-hover .table-success:hover {
  background-color: #c7e3c3;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #c7e3c3;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #dcf3f9;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #bde8f5;
}

.table-hover .table-info:hover {
  background-color: #c6ecf5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #c6ecf5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fbe4c0;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f8cd8b;
}

.table-hover .table-warning:hover {
  background-color: #f9daa8;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #f9daa8;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fbcad0;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f79ca7;
}

.table-hover .table-danger:hover {
  background-color: #f9b2bb;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f9b2bb;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #f6f7f8;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #eef0f2;
}

.table-hover .table-light:hover {
  background-color: #e8eaed;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #e8eaed;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-primary-dark,
.table-primary-dark > th,
.table-primary-dark > td {
  background-color: #bbc1cd;
}

.table-primary-dark th,
.table-primary-dark td,
.table-primary-dark thead th,
.table-primary-dark tbody + tbody {
  border-color: #818da1;
}

.table-hover .table-primary-dark:hover {
  background-color: #acb4c2;
}

.table-hover .table-primary-dark:hover > td,
.table-hover .table-primary-dark:hover > th {
  background-color: #acb4c2;
}

.table-primary-light,
.table-primary-light > th,
.table-primary-light > td {
  background-color: #c7dfed;
}

.table-primary-light th,
.table-primary-light td,
.table-primary-light thead th,
.table-primary-light tbody + tbody {
  border-color: #97c4dd;
}

.table-hover .table-primary-light:hover {
  background-color: #b4d4e7;
}

.table-hover .table-primary-light:hover > td,
.table-hover .table-primary-light:hover > th {
  background-color: #b4d4e7;
}

.table-accent,
.table-accent > th,
.table-accent > td {
  background-color: #e7b9c1;
}

.table-accent th,
.table-accent td,
.table-accent thead th,
.table-accent tbody + tbody {
  border-color: #d37d8b;
}

.table-hover .table-accent:hover {
  background-color: #e0a6b0;
}

.table-hover .table-accent:hover > td,
.table-hover .table-accent:hover > th {
  background-color: #e0a6b0;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(222, 226, 230, 0.5);
}

.table-hover .table-active:hover {
  background-color: rgba(207, 213, 219, 0.5);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(207, 213, 219, 0.5);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #343a40;
  background-color: #dee2e6;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    -webkit-transition: none;
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #4e91d8;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(30, 82, 136, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(30, 82, 136, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #70b865;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #212529;
  background-color: rgba(112, 184, 101, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #70b865;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2370b865' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #70b865;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(112, 184, 101, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(112, 184, 101, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #70b865;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2370b865' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #70b865;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(112, 184, 101, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(112, 184, 101, 0.25);
}

.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #70b865;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #70b865;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #70b865;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #90c888;
  background-color: #90c888;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(112, 184, 101, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(112, 184, 101, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #70b865;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #70b865;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #70b865;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(112, 184, 101, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(112, 184, 101, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ef4056;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(239, 64, 86, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ef4056;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ef4056' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23ef4056' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #ef4056;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(239, 64, 86, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(239, 64, 86, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #ef4056;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ef4056' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23ef4056' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #ef4056;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(239, 64, 86, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(239, 64, 86, 0.25);
}

.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ef4056;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ef4056;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #ef4056;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f36f80;
  background-color: #f36f80;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(239, 64, 86, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(239, 64, 86, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ef4056;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ef4056;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #ef4056;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(239, 64, 86, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(239, 64, 86, 0.25);
}

.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    -webkit-transition: none;
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(30, 82, 136, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(30, 82, 136, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #1e5288;
  border-color: #1e5288;
}

.btn-primary:hover {
  color: #fff;
  background-color: #173f69;
  border-color: #15395e;
}

.btn-primary:focus, .btn-primary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(64, 108, 154, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(64, 108, 154, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #1e5288;
  border-color: #1e5288;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #15395e;
  border-color: #123354;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(64, 108, 154, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(64, 108, 154, 0.5);
}

.btn-secondary {
  color: #212529;
  background-color: #9eabae;
  border-color: #9eabae;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #89999d;
  border-color: #829397;
}

.btn-secondary:focus, .btn-secondary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(139, 151, 154, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(139, 151, 154, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #212529;
  background-color: #9eabae;
  border-color: #9eabae;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #829397;
  border-color: #7b8d91;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(139, 151, 154, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(139, 151, 154, 0.5);
}

.btn-success {
  color: #212529;
  background-color: #70b865;
  border-color: #70b865;
}

.btn-success:hover {
  color: #fff;
  background-color: #5aa94e;
  border-color: #55a04a;
}

.btn-success:focus, .btn-success.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(100, 162, 92, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(100, 162, 92, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #212529;
  background-color: #70b865;
  border-color: #70b865;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #55a04a;
  border-color: #519746;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(100, 162, 92, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(100, 162, 92, 0.5);
}

.btn-info {
  color: #212529;
  background-color: #81d3eb;
  border-color: #81d3eb;
}

.btn-info:hover {
  color: #212529;
  background-color: #60c7e6;
  border-color: #55c4e4;
}

.btn-info:focus, .btn-info.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(115, 185, 206, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(115, 185, 206, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #212529;
  background-color: #81d3eb;
  border-color: #81d3eb;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #212529;
  background-color: #55c4e4;
  border-color: #4ac0e2;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(115, 185, 206, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(115, 185, 206, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #f19e1f;
  border-color: #f19e1f;
}

.btn-warning:hover {
  color: #fff;
  background-color: #dc8a0e;
  border-color: #d0830d;
}

.btn-warning:focus, .btn-warning.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(210, 140, 33, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(210, 140, 33, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #f19e1f;
  border-color: #f19e1f;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #d0830d;
  border-color: #c47b0c;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(210, 140, 33, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(210, 140, 33, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #ef4056;
  border-color: #ef4056;
}

.btn-danger:hover {
  color: #fff;
  background-color: #ec1d37;
  border-color: #e9132e;
}

.btn-danger:focus, .btn-danger.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(241, 93, 111, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(241, 93, 111, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #ef4056;
  border-color: #ef4056;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #e9132e;
  border-color: #dd122c;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(241, 93, 111, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(241, 93, 111, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #dee2e6;
  border-color: #dee2e6;
}

.btn-light:hover {
  color: #212529;
  background-color: #c8cfd6;
  border-color: #c1c9d0;
}

.btn-light:focus, .btn-light.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(194, 198, 202, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(194, 198, 202, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #dee2e6;
  border-color: #dee2e6;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #c1c9d0;
  border-color: #bac2cb;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(194, 198, 202, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(194, 198, 202, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-primary-dark {
  color: #fff;
  background-color: #0c234b;
  border-color: #0c234b;
}

.btn-primary-dark:hover {
  color: #fff;
  background-color: #07142a;
  border-color: #050e1f;
}

.btn-primary-dark:focus, .btn-primary-dark.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(48, 68, 102, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(48, 68, 102, 0.5);
}

.btn-primary-dark.disabled, .btn-primary-dark:disabled {
  color: #fff;
  background-color: #0c234b;
  border-color: #0c234b;
}

.btn-primary-dark:not(:disabled):not(.disabled):active, .btn-primary-dark:not(:disabled):not(.disabled).active,
.show > .btn-primary-dark.dropdown-toggle {
  color: #fff;
  background-color: #050e1f;
  border-color: #030914;
}

.btn-primary-dark:not(:disabled):not(.disabled):active:focus, .btn-primary-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(48, 68, 102, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(48, 68, 102, 0.5);
}

.btn-primary-light {
  color: #fff;
  background-color: #378dbd;
  border-color: #378dbd;
}

.btn-primary-light:hover {
  color: #fff;
  background-color: #2e779f;
  border-color: #2c7095;
}

.btn-primary-light:focus, .btn-primary-light.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(85, 158, 199, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(85, 158, 199, 0.5);
}

.btn-primary-light.disabled, .btn-primary-light:disabled {
  color: #fff;
  background-color: #378dbd;
  border-color: #378dbd;
}

.btn-primary-light:not(:disabled):not(.disabled):active, .btn-primary-light:not(:disabled):not(.disabled).active,
.show > .btn-primary-light.dropdown-toggle {
  color: #fff;
  background-color: #2c7095;
  border-color: #29688c;
}

.btn-primary-light:not(:disabled):not(.disabled):active:focus, .btn-primary-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(85, 158, 199, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(85, 158, 199, 0.5);
}

.btn-accent {
  color: #fff;
  background-color: #ab0520;
  border-color: #ab0520;
}

.btn-accent:hover {
  color: #fff;
  background-color: #860419;
  border-color: #790417;
}

.btn-accent:focus, .btn-accent.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(184, 43, 65, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(184, 43, 65, 0.5);
}

.btn-accent.disabled, .btn-accent:disabled {
  color: #fff;
  background-color: #ab0520;
  border-color: #ab0520;
}

.btn-accent:not(:disabled):not(.disabled):active, .btn-accent:not(:disabled):not(.disabled).active,
.show > .btn-accent.dropdown-toggle {
  color: #fff;
  background-color: #790417;
  border-color: #6d0314;
}

.btn-accent:not(:disabled):not(.disabled):active:focus, .btn-accent:not(:disabled):not(.disabled).active:focus,
.show > .btn-accent.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(184, 43, 65, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(184, 43, 65, 0.5);
}

.btn-outline-primary {
  color: #1e5288;
  border-color: #1e5288;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #1e5288;
  border-color: #1e5288;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(30, 82, 136, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(30, 82, 136, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #1e5288;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #1e5288;
  border-color: #1e5288;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(30, 82, 136, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(30, 82, 136, 0.5);
}

.btn-outline-secondary {
  color: #9eabae;
  border-color: #9eabae;
}

.btn-outline-secondary:hover {
  color: #212529;
  background-color: #9eabae;
  border-color: #9eabae;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(158, 171, 174, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(158, 171, 174, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #9eabae;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #212529;
  background-color: #9eabae;
  border-color: #9eabae;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(158, 171, 174, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(158, 171, 174, 0.5);
}

.btn-outline-success {
  color: #70b865;
  border-color: #70b865;
}

.btn-outline-success:hover {
  color: #212529;
  background-color: #70b865;
  border-color: #70b865;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(112, 184, 101, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(112, 184, 101, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #70b865;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #212529;
  background-color: #70b865;
  border-color: #70b865;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(112, 184, 101, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(112, 184, 101, 0.5);
}

.btn-outline-info {
  color: #81d3eb;
  border-color: #81d3eb;
}

.btn-outline-info:hover {
  color: #212529;
  background-color: #81d3eb;
  border-color: #81d3eb;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(129, 211, 235, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(129, 211, 235, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #81d3eb;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #81d3eb;
  border-color: #81d3eb;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(129, 211, 235, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(129, 211, 235, 0.5);
}

.btn-outline-warning {
  color: #f19e1f;
  border-color: #f19e1f;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #f19e1f;
  border-color: #f19e1f;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(241, 158, 31, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(241, 158, 31, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #f19e1f;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #f19e1f;
  border-color: #f19e1f;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(241, 158, 31, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(241, 158, 31, 0.5);
}

.btn-outline-danger {
  color: #ef4056;
  border-color: #ef4056;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #ef4056;
  border-color: #ef4056;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(239, 64, 86, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(239, 64, 86, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #ef4056;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #ef4056;
  border-color: #ef4056;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(239, 64, 86, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(239, 64, 86, 0.5);
}

.btn-outline-light {
  color: #dee2e6;
  border-color: #dee2e6;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #dee2e6;
  border-color: #dee2e6;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #dee2e6;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #dee2e6;
  border-color: #dee2e6;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-primary-dark {
  color: #0c234b;
  border-color: #0c234b;
}

.btn-outline-primary-dark:hover {
  color: #fff;
  background-color: #0c234b;
  border-color: #0c234b;
}

.btn-outline-primary-dark:focus, .btn-outline-primary-dark.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(12, 35, 75, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(12, 35, 75, 0.5);
}

.btn-outline-primary-dark.disabled, .btn-outline-primary-dark:disabled {
  color: #0c234b;
  background-color: transparent;
}

.btn-outline-primary-dark:not(:disabled):not(.disabled):active, .btn-outline-primary-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary-dark.dropdown-toggle {
  color: #fff;
  background-color: #0c234b;
  border-color: #0c234b;
}

.btn-outline-primary-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(12, 35, 75, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(12, 35, 75, 0.5);
}

.btn-outline-primary-light {
  color: #378dbd;
  border-color: #378dbd;
}

.btn-outline-primary-light:hover {
  color: #fff;
  background-color: #378dbd;
  border-color: #378dbd;
}

.btn-outline-primary-light:focus, .btn-outline-primary-light.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(55, 141, 189, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(55, 141, 189, 0.5);
}

.btn-outline-primary-light.disabled, .btn-outline-primary-light:disabled {
  color: #378dbd;
  background-color: transparent;
}

.btn-outline-primary-light:not(:disabled):not(.disabled):active, .btn-outline-primary-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary-light.dropdown-toggle {
  color: #fff;
  background-color: #378dbd;
  border-color: #378dbd;
}

.btn-outline-primary-light:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(55, 141, 189, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(55, 141, 189, 0.5);
}

.btn-outline-accent {
  color: #ab0520;
  border-color: #ab0520;
}

.btn-outline-accent:hover {
  color: #fff;
  background-color: #ab0520;
  border-color: #ab0520;
}

.btn-outline-accent:focus, .btn-outline-accent.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(171, 5, 32, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(171, 5, 32, 0.5);
}

.btn-outline-accent.disabled, .btn-outline-accent:disabled {
  color: #ab0520;
  background-color: transparent;
}

.btn-outline-accent:not(:disabled):not(.disabled):active, .btn-outline-accent:not(:disabled):not(.disabled).active,
.show > .btn-outline-accent.dropdown-toggle {
  color: #fff;
  background-color: #ab0520;
  border-color: #ab0520;
}

.btn-outline-accent:not(:disabled):not(.disabled):active:focus, .btn-outline-accent:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-accent.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(171, 5, 32, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(171, 5, 32, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #8b0015;
  text-decoration: none;
}

.btn-link:hover {
  color: #3f0009;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    -webkit-transition: none;
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    -webkit-transition: none;
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #1e5288;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #001c48;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #e9ecef;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #1e5288;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #1e5288;
  background-color: #1e5288;
}

.custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(30, 82, 136, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(30, 82, 136, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #4e91d8;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #77abe1;
  border-color: #77abe1;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #1e5288;
  background-color: #1e5288;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(30, 82, 136, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(30, 82, 136, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(30, 82, 136, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(30, 82, 136, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #4e91d8;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(30, 82, 136, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(30, 82, 136, 0.25);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #4e91d8;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(30, 82, 136, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(30, 82, 136, 0.25);
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(30, 82, 136, 0.25);
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(30, 82, 136, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(30, 82, 136, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(30, 82, 136, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #1e5288;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #77abe1;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #1e5288;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #77abe1;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #1e5288;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #77abe1;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    -webkit-transition: none;
    transition: none;
  }
}

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #e9ecef;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #e9ecef;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #e9ecef #e9ecef #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #001c48;
  background-color: #e9ecef;
}

.nav-fill .nav-item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #e9ecef;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #e9ecef;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-group > .card {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card .card-header {
  margin-bottom: -1px;
}

.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #fff;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #1e5288;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #001c48;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(30, 82, 136, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(30, 82, 136, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #1e5288;
  border-color: #1e5288;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    -webkit-transition: none;
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #1e5288;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #15395e;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(30, 82, 136, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(30, 82, 136, 0.5);
}

.badge-secondary {
  color: #212529;
  background-color: #9eabae;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #212529;
  background-color: #829397;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(158, 171, 174, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(158, 171, 174, 0.5);
}

.badge-success {
  color: #212529;
  background-color: #70b865;
}

a.badge-success:hover, a.badge-success:focus {
  color: #212529;
  background-color: #55a04a;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(112, 184, 101, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(112, 184, 101, 0.5);
}

.badge-info {
  color: #212529;
  background-color: #81d3eb;
}

a.badge-info:hover, a.badge-info:focus {
  color: #212529;
  background-color: #55c4e4;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(129, 211, 235, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(129, 211, 235, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #f19e1f;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d0830d;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(241, 158, 31, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(241, 158, 31, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #ef4056;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #e9132e;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(239, 64, 86, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(239, 64, 86, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #dee2e6;
}

a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #c1c9d0;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.badge-primary-dark {
  color: #fff;
  background-color: #0c234b;
}

a.badge-primary-dark:hover, a.badge-primary-dark:focus {
  color: #fff;
  background-color: #050e1f;
}

a.badge-primary-dark:focus, a.badge-primary-dark.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(12, 35, 75, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(12, 35, 75, 0.5);
}

.badge-primary-light {
  color: #fff;
  background-color: #378dbd;
}

a.badge-primary-light:hover, a.badge-primary-light:focus {
  color: #fff;
  background-color: #2c7095;
}

a.badge-primary-light:focus, a.badge-primary-light.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(55, 141, 189, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(55, 141, 189, 0.5);
}

.badge-accent {
  color: #fff;
  background-color: #ab0520;
}

a.badge-accent:hover, a.badge-accent:focus {
  color: #fff;
  background-color: #790417;
}

a.badge-accent:focus, a.badge-accent.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(171, 5, 32, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(171, 5, 32, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #fff;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #102b47;
  background-color: #d2dce7;
  border-color: #c0cfde;
}

.alert-primary hr {
  border-top-color: #afc2d5;
}

.alert-primary .alert-link {
  color: #07121d;
}

.alert-secondary {
  color: #52595a;
  background-color: #eceeef;
  border-color: #e4e7e8;
}

.alert-secondary hr {
  border-top-color: #d6dbdc;
}

.alert-secondary .alert-link {
  color: #3a3f3f;
}

.alert-success {
  color: #3a6035;
  background-color: #e2f1e0;
  border-color: #d7ebd4;
}

.alert-success hr {
  border-top-color: #c7e3c3;
}

.alert-success .alert-link {
  color: #263f23;
}

.alert-info {
  color: #436e7a;
  background-color: #e6f6fb;
  border-color: #dcf3f9;
}

.alert-info hr {
  border-top-color: #c6ecf5;
}

.alert-info .alert-link {
  color: #315059;
}

.alert-warning {
  color: #7d5210;
  background-color: #fcecd2;
  border-color: #fbe4c0;
}

.alert-warning hr {
  border-top-color: #f9daa8;
}

.alert-warning .alert-link {
  color: #50340a;
}

.alert-danger {
  color: #7c212d;
  background-color: #fcd9dd;
  border-color: #fbcad0;
}

.alert-danger hr {
  border-top-color: #f9b2bb;
}

.alert-danger .alert-link {
  color: #54161e;
}

.alert-light {
  color: #737678;
  background-color: #f8f9fa;
  border-color: #f6f7f8;
}

.alert-light hr {
  border-top-color: #e8eaed;
}

.alert-light .alert-link {
  color: #5a5c5e;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

.alert-primary-dark {
  color: #061227;
  background-color: #ced3db;
  border-color: #bbc1cd;
}

.alert-primary-dark hr {
  border-top-color: #acb4c2;
}

.alert-primary-dark .alert-link {
  color: black;
}

.alert-primary-light {
  color: #1d4962;
  background-color: #d7e8f2;
  border-color: #c7dfed;
}

.alert-primary-light hr {
  border-top-color: #b4d4e7;
}

.alert-primary-light .alert-link {
  color: #112c3b;
}

.alert-accent {
  color: #590311;
  background-color: #eecdd2;
  border-color: #e7b9c1;
}

.alert-accent hr {
  border-top-color: #e0a6b0;
}

.alert-accent .alert-link {
  color: #280108;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #1e5288;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    -webkit-transition: none;
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #1e5288;
  border-color: #1e5288;
}

.list-group-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}

.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.list-group-item-primary {
  color: #102b47;
  background-color: #c0cfde;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #102b47;
  background-color: #afc2d5;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #102b47;
  border-color: #102b47;
}

.list-group-item-secondary {
  color: #52595a;
  background-color: #e4e7e8;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #52595a;
  background-color: #d6dbdc;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #52595a;
  border-color: #52595a;
}

.list-group-item-success {
  color: #3a6035;
  background-color: #d7ebd4;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #3a6035;
  background-color: #c7e3c3;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #3a6035;
  border-color: #3a6035;
}

.list-group-item-info {
  color: #436e7a;
  background-color: #dcf3f9;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #436e7a;
  background-color: #c6ecf5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #436e7a;
  border-color: #436e7a;
}

.list-group-item-warning {
  color: #7d5210;
  background-color: #fbe4c0;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #7d5210;
  background-color: #f9daa8;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #7d5210;
  border-color: #7d5210;
}

.list-group-item-danger {
  color: #7c212d;
  background-color: #fbcad0;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #7c212d;
  background-color: #f9b2bb;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #7c212d;
  border-color: #7c212d;
}

.list-group-item-light {
  color: #737678;
  background-color: #f6f7f8;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #737678;
  background-color: #e8eaed;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #737678;
  border-color: #737678;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.list-group-item-primary-dark {
  color: #061227;
  background-color: #bbc1cd;
}

.list-group-item-primary-dark.list-group-item-action:hover, .list-group-item-primary-dark.list-group-item-action:focus {
  color: #061227;
  background-color: #acb4c2;
}

.list-group-item-primary-dark.list-group-item-action.active {
  color: #fff;
  background-color: #061227;
  border-color: #061227;
}

.list-group-item-primary-light {
  color: #1d4962;
  background-color: #c7dfed;
}

.list-group-item-primary-light.list-group-item-action:hover, .list-group-item-primary-light.list-group-item-action:focus {
  color: #1d4962;
  background-color: #b4d4e7;
}

.list-group-item-primary-light.list-group-item-action.active {
  color: #fff;
  background-color: #1d4962;
  border-color: #1d4962;
}

.list-group-item-accent {
  color: #590311;
  background-color: #e7b9c1;
}

.list-group-item-accent.list-group-item-action:hover, .list-group-item-accent.list-group-item-action:focus {
  color: #590311;
  background-color: #e0a6b0;
}

.list-group-item-accent.list-group-item-action.active {
  color: #fff;
  background-color: #590311;
  border-color: #590311;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    -webkit-transition: none;
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

.modal-dialog-scrollable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: MiloWeb, Verdana, Geneva, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #0c234b;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #0c234b;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #0c234b;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #0c234b;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #0c234b;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: MiloWeb, Verdana, Geneva, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: 0s 0.6s opacity;
  transition: 0s 0.6s opacity;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  -webkit-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
  animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #1e5288 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #15395e !important;
}

.bg-secondary {
  background-color: #9eabae !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #829397 !important;
}

.bg-success {
  background-color: #70b865 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #55a04a !important;
}

.bg-info {
  background-color: #81d3eb !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #55c4e4 !important;
}

.bg-warning {
  background-color: #f19e1f !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d0830d !important;
}

.bg-danger {
  background-color: #ef4056 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #e9132e !important;
}

.bg-light {
  background-color: #dee2e6 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #c1c9d0 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-primary-dark {
  background-color: #0c234b !important;
}

a.bg-primary-dark:hover, a.bg-primary-dark:focus,
button.bg-primary-dark:hover,
button.bg-primary-dark:focus {
  background-color: #050e1f !important;
}

.bg-primary-light {
  background-color: #378dbd !important;
}

a.bg-primary-light:hover, a.bg-primary-light:focus,
button.bg-primary-light:hover,
button.bg-primary-light:focus {
  background-color: #2c7095 !important;
}

.bg-accent {
  background-color: #ab0520 !important;
}

a.bg-accent:hover, a.bg-accent:focus,
button.bg-accent:hover,
button.bg-accent:focus {
  background-color: #790417 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #1e5288 !important;
}

.border-secondary {
  border-color: #9eabae !important;
}

.border-success {
  border-color: #70b865 !important;
}

.border-info {
  border-color: #81d3eb !important;
}

.border-warning {
  border-color: #f19e1f !important;
}

.border-danger {
  border-color: #ef4056 !important;
}

.border-light {
  border-color: #dee2e6 !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-primary-dark {
  border-color: #0c234b !important;
}

.border-primary-light {
  border-color: #378dbd !important;
}

.border-accent {
  border-color: #ab0520 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #1e5288 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #102c49 !important;
}

.text-secondary {
  color: #9eabae !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #74878b !important;
}

.text-success {
  color: #70b865 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #4c8f42 !important;
}

.text-info {
  color: #81d3eb !important;
}

a.text-info:hover, a.text-info:focus {
  color: #3fbce1 !important;
}

.text-warning {
  color: #f19e1f !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #b8740c !important;
}

.text-danger {
  color: #ef4056 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #d1122a !important;
}

.text-light {
  color: #dee2e6 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #b2bcc5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-primary-dark {
  color: #0c234b !important;
}

a.text-primary-dark:hover, a.text-primary-dark:focus {
  color: #010409 !important;
}

.text-primary-light {
  color: #378dbd !important;
}

a.text-primary-light:hover, a.text-primary-light:focus {
  color: #266182 !important;
}

.text-accent {
  color: #ab0520 !important;
}

a.text-accent:hover, a.text-accent:focus {
  color: #610312 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

.card .card-header {
  background-color: #e9ecef;
}

.card[class*=" bg-"] .card-header {
  background-color: rgba(0, 0, 0, 0.075);
}

.bg-success .card-header {
  color: #001c48;
}

.bg-info .card-header {
  color: #001c48;
}

.bg-danger .card-header {
  color: #000;
}

.text-blue {
  color: #0c234b;
}

.text-indigo {
  color: #6610f2;
}

.text-purple {
  color: #6f42c1;
}

.text-pink {
  color: #e83e8c;
}

.text-red {
  color: #ab0520;
}

.text-orange {
  color: #fd7e14;
}

.text-yellow {
  color: #ffc107;
}

.text-green {
  color: #28a745;
}

.text-teal {
  color: #20c997;
}

.text-cyan {
  color: #17a2b8;
}

.text-white {
  color: #fff;
}

.text-gray {
  color: #6c757d;
}

.text-gray-dark {
  color: #343a40;
}

.text-bloom {
  color: #ef4056;
}

.text-chili {
  color: #8b0015;
}

.text-sky {
  color: #81d3eb;
}

.text-oasis {
  color: #378dbd;
}

.text-azurite {
  color: #1e5288;
}

.text-midnight {
  color: #001c48;
}

.text-cool-gray {
  color: #e2e9eb;
}

.text-warm-gray {
  color: #f4ede5;
}

.text-leaf {
  color: #70b865;
}

.text-river {
  color: #007d84;
}

.text-silver {
  color: #9eabae;
}

.text-mesa {
  color: #a95c42;
}

.text-ash {
  color: #403635;
}

.text-sage {
  color: #4a634e;
}

.text-black {
  color: #000;
}

.text-primary {
  color: #1e5288;
}

.text-secondary {
  color: #9eabae;
}

.text-success {
  color: #70b865;
}

.text-info {
  color: #1e5288 !important;
}

.text-warning {
  color: #f19e1f;
}

.text-danger {
  color: #ef4056;
}

.text-light {
  color: #dee2e6;
}

.text-dark {
  color: #343a40;
}

.text-primary-dark {
  color: #0c234b;
}

.text-primary-light {
  color: #378dbd;
}

.text-accent {
  color: #ab0520;
}

.text-dark-silver {
  color: #49595e;
}

.bg-blue {
  color: #fff;
  background-color: #0c234b;
  color: #fff;
}

.bg-blue p,
.bg-blue h1,
.bg-blue h2,
.bg-blue h3,
.bg-blue h4,
.bg-blue h5,
.bg-blue h6,
.bg-blue .h1,
.bg-blue .h2,
.bg-blue .h3,
.bg-blue .h4,
.bg-blue .h5,
.bg-blue .h6 {
  color: #fff;
}

.bg-blue p.text-blue,
.bg-blue h1.text-blue,
.bg-blue h2.text-blue,
.bg-blue h3.text-blue,
.bg-blue h4.text-blue,
.bg-blue h5.text-blue,
.bg-blue h6.text-blue,
.bg-blue .h1.text-blue,
.bg-blue .h2.text-blue,
.bg-blue .h3.text-blue,
.bg-blue .h4.text-blue,
.bg-blue .h5.text-blue,
.bg-blue .h6.text-blue {
  color: #0c234b;
}

.bg-blue p.text-indigo,
.bg-blue h1.text-indigo,
.bg-blue h2.text-indigo,
.bg-blue h3.text-indigo,
.bg-blue h4.text-indigo,
.bg-blue h5.text-indigo,
.bg-blue h6.text-indigo,
.bg-blue .h1.text-indigo,
.bg-blue .h2.text-indigo,
.bg-blue .h3.text-indigo,
.bg-blue .h4.text-indigo,
.bg-blue .h5.text-indigo,
.bg-blue .h6.text-indigo {
  color: #6610f2;
}

.bg-blue p.text-purple,
.bg-blue h1.text-purple,
.bg-blue h2.text-purple,
.bg-blue h3.text-purple,
.bg-blue h4.text-purple,
.bg-blue h5.text-purple,
.bg-blue h6.text-purple,
.bg-blue .h1.text-purple,
.bg-blue .h2.text-purple,
.bg-blue .h3.text-purple,
.bg-blue .h4.text-purple,
.bg-blue .h5.text-purple,
.bg-blue .h6.text-purple {
  color: #6f42c1;
}

.bg-blue p.text-pink,
.bg-blue h1.text-pink,
.bg-blue h2.text-pink,
.bg-blue h3.text-pink,
.bg-blue h4.text-pink,
.bg-blue h5.text-pink,
.bg-blue h6.text-pink,
.bg-blue .h1.text-pink,
.bg-blue .h2.text-pink,
.bg-blue .h3.text-pink,
.bg-blue .h4.text-pink,
.bg-blue .h5.text-pink,
.bg-blue .h6.text-pink {
  color: #e83e8c;
}

.bg-blue p.text-red,
.bg-blue h1.text-red,
.bg-blue h2.text-red,
.bg-blue h3.text-red,
.bg-blue h4.text-red,
.bg-blue h5.text-red,
.bg-blue h6.text-red,
.bg-blue .h1.text-red,
.bg-blue .h2.text-red,
.bg-blue .h3.text-red,
.bg-blue .h4.text-red,
.bg-blue .h5.text-red,
.bg-blue .h6.text-red {
  color: #ab0520;
}

.bg-blue p.text-orange,
.bg-blue h1.text-orange,
.bg-blue h2.text-orange,
.bg-blue h3.text-orange,
.bg-blue h4.text-orange,
.bg-blue h5.text-orange,
.bg-blue h6.text-orange,
.bg-blue .h1.text-orange,
.bg-blue .h2.text-orange,
.bg-blue .h3.text-orange,
.bg-blue .h4.text-orange,
.bg-blue .h5.text-orange,
.bg-blue .h6.text-orange {
  color: #fd7e14;
}

.bg-blue p.text-yellow,
.bg-blue h1.text-yellow,
.bg-blue h2.text-yellow,
.bg-blue h3.text-yellow,
.bg-blue h4.text-yellow,
.bg-blue h5.text-yellow,
.bg-blue h6.text-yellow,
.bg-blue .h1.text-yellow,
.bg-blue .h2.text-yellow,
.bg-blue .h3.text-yellow,
.bg-blue .h4.text-yellow,
.bg-blue .h5.text-yellow,
.bg-blue .h6.text-yellow {
  color: #ffc107;
}

.bg-blue p.text-green,
.bg-blue h1.text-green,
.bg-blue h2.text-green,
.bg-blue h3.text-green,
.bg-blue h4.text-green,
.bg-blue h5.text-green,
.bg-blue h6.text-green,
.bg-blue .h1.text-green,
.bg-blue .h2.text-green,
.bg-blue .h3.text-green,
.bg-blue .h4.text-green,
.bg-blue .h5.text-green,
.bg-blue .h6.text-green {
  color: #28a745;
}

.bg-blue p.text-teal,
.bg-blue h1.text-teal,
.bg-blue h2.text-teal,
.bg-blue h3.text-teal,
.bg-blue h4.text-teal,
.bg-blue h5.text-teal,
.bg-blue h6.text-teal,
.bg-blue .h1.text-teal,
.bg-blue .h2.text-teal,
.bg-blue .h3.text-teal,
.bg-blue .h4.text-teal,
.bg-blue .h5.text-teal,
.bg-blue .h6.text-teal {
  color: #20c997;
}

.bg-blue p.text-cyan,
.bg-blue h1.text-cyan,
.bg-blue h2.text-cyan,
.bg-blue h3.text-cyan,
.bg-blue h4.text-cyan,
.bg-blue h5.text-cyan,
.bg-blue h6.text-cyan,
.bg-blue .h1.text-cyan,
.bg-blue .h2.text-cyan,
.bg-blue .h3.text-cyan,
.bg-blue .h4.text-cyan,
.bg-blue .h5.text-cyan,
.bg-blue .h6.text-cyan {
  color: #17a2b8;
}

.bg-blue p.text-white,
.bg-blue h1.text-white,
.bg-blue h2.text-white,
.bg-blue h3.text-white,
.bg-blue h4.text-white,
.bg-blue h5.text-white,
.bg-blue h6.text-white,
.bg-blue .h1.text-white,
.bg-blue .h2.text-white,
.bg-blue .h3.text-white,
.bg-blue .h4.text-white,
.bg-blue .h5.text-white,
.bg-blue .h6.text-white {
  color: #fff;
}

.bg-blue p.text-gray,
.bg-blue h1.text-gray,
.bg-blue h2.text-gray,
.bg-blue h3.text-gray,
.bg-blue h4.text-gray,
.bg-blue h5.text-gray,
.bg-blue h6.text-gray,
.bg-blue .h1.text-gray,
.bg-blue .h2.text-gray,
.bg-blue .h3.text-gray,
.bg-blue .h4.text-gray,
.bg-blue .h5.text-gray,
.bg-blue .h6.text-gray {
  color: #6c757d;
}

.bg-blue p.text-gray-dark,
.bg-blue h1.text-gray-dark,
.bg-blue h2.text-gray-dark,
.bg-blue h3.text-gray-dark,
.bg-blue h4.text-gray-dark,
.bg-blue h5.text-gray-dark,
.bg-blue h6.text-gray-dark,
.bg-blue .h1.text-gray-dark,
.bg-blue .h2.text-gray-dark,
.bg-blue .h3.text-gray-dark,
.bg-blue .h4.text-gray-dark,
.bg-blue .h5.text-gray-dark,
.bg-blue .h6.text-gray-dark {
  color: #343a40;
}

.bg-blue p.text-bloom,
.bg-blue h1.text-bloom,
.bg-blue h2.text-bloom,
.bg-blue h3.text-bloom,
.bg-blue h4.text-bloom,
.bg-blue h5.text-bloom,
.bg-blue h6.text-bloom,
.bg-blue .h1.text-bloom,
.bg-blue .h2.text-bloom,
.bg-blue .h3.text-bloom,
.bg-blue .h4.text-bloom,
.bg-blue .h5.text-bloom,
.bg-blue .h6.text-bloom {
  color: #ef4056;
}

.bg-blue p.text-chili,
.bg-blue h1.text-chili,
.bg-blue h2.text-chili,
.bg-blue h3.text-chili,
.bg-blue h4.text-chili,
.bg-blue h5.text-chili,
.bg-blue h6.text-chili,
.bg-blue .h1.text-chili,
.bg-blue .h2.text-chili,
.bg-blue .h3.text-chili,
.bg-blue .h4.text-chili,
.bg-blue .h5.text-chili,
.bg-blue .h6.text-chili {
  color: #8b0015;
}

.bg-blue p.text-sky,
.bg-blue h1.text-sky,
.bg-blue h2.text-sky,
.bg-blue h3.text-sky,
.bg-blue h4.text-sky,
.bg-blue h5.text-sky,
.bg-blue h6.text-sky,
.bg-blue .h1.text-sky,
.bg-blue .h2.text-sky,
.bg-blue .h3.text-sky,
.bg-blue .h4.text-sky,
.bg-blue .h5.text-sky,
.bg-blue .h6.text-sky {
  color: #81d3eb;
}

.bg-blue p.text-oasis,
.bg-blue h1.text-oasis,
.bg-blue h2.text-oasis,
.bg-blue h3.text-oasis,
.bg-blue h4.text-oasis,
.bg-blue h5.text-oasis,
.bg-blue h6.text-oasis,
.bg-blue .h1.text-oasis,
.bg-blue .h2.text-oasis,
.bg-blue .h3.text-oasis,
.bg-blue .h4.text-oasis,
.bg-blue .h5.text-oasis,
.bg-blue .h6.text-oasis {
  color: #378dbd;
}

.bg-blue p.text-azurite,
.bg-blue h1.text-azurite,
.bg-blue h2.text-azurite,
.bg-blue h3.text-azurite,
.bg-blue h4.text-azurite,
.bg-blue h5.text-azurite,
.bg-blue h6.text-azurite,
.bg-blue .h1.text-azurite,
.bg-blue .h2.text-azurite,
.bg-blue .h3.text-azurite,
.bg-blue .h4.text-azurite,
.bg-blue .h5.text-azurite,
.bg-blue .h6.text-azurite {
  color: #1e5288;
}

.bg-blue p.text-midnight,
.bg-blue h1.text-midnight,
.bg-blue h2.text-midnight,
.bg-blue h3.text-midnight,
.bg-blue h4.text-midnight,
.bg-blue h5.text-midnight,
.bg-blue h6.text-midnight,
.bg-blue .h1.text-midnight,
.bg-blue .h2.text-midnight,
.bg-blue .h3.text-midnight,
.bg-blue .h4.text-midnight,
.bg-blue .h5.text-midnight,
.bg-blue .h6.text-midnight {
  color: #001c48;
}

.bg-blue p.text-cool-gray,
.bg-blue h1.text-cool-gray,
.bg-blue h2.text-cool-gray,
.bg-blue h3.text-cool-gray,
.bg-blue h4.text-cool-gray,
.bg-blue h5.text-cool-gray,
.bg-blue h6.text-cool-gray,
.bg-blue .h1.text-cool-gray,
.bg-blue .h2.text-cool-gray,
.bg-blue .h3.text-cool-gray,
.bg-blue .h4.text-cool-gray,
.bg-blue .h5.text-cool-gray,
.bg-blue .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-blue p.text-warm-gray,
.bg-blue h1.text-warm-gray,
.bg-blue h2.text-warm-gray,
.bg-blue h3.text-warm-gray,
.bg-blue h4.text-warm-gray,
.bg-blue h5.text-warm-gray,
.bg-blue h6.text-warm-gray,
.bg-blue .h1.text-warm-gray,
.bg-blue .h2.text-warm-gray,
.bg-blue .h3.text-warm-gray,
.bg-blue .h4.text-warm-gray,
.bg-blue .h5.text-warm-gray,
.bg-blue .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-blue p.text-leaf,
.bg-blue h1.text-leaf,
.bg-blue h2.text-leaf,
.bg-blue h3.text-leaf,
.bg-blue h4.text-leaf,
.bg-blue h5.text-leaf,
.bg-blue h6.text-leaf,
.bg-blue .h1.text-leaf,
.bg-blue .h2.text-leaf,
.bg-blue .h3.text-leaf,
.bg-blue .h4.text-leaf,
.bg-blue .h5.text-leaf,
.bg-blue .h6.text-leaf {
  color: #70b865;
}

.bg-blue p.text-river,
.bg-blue h1.text-river,
.bg-blue h2.text-river,
.bg-blue h3.text-river,
.bg-blue h4.text-river,
.bg-blue h5.text-river,
.bg-blue h6.text-river,
.bg-blue .h1.text-river,
.bg-blue .h2.text-river,
.bg-blue .h3.text-river,
.bg-blue .h4.text-river,
.bg-blue .h5.text-river,
.bg-blue .h6.text-river {
  color: #007d84;
}

.bg-blue p.text-silver,
.bg-blue h1.text-silver,
.bg-blue h2.text-silver,
.bg-blue h3.text-silver,
.bg-blue h4.text-silver,
.bg-blue h5.text-silver,
.bg-blue h6.text-silver,
.bg-blue .h1.text-silver,
.bg-blue .h2.text-silver,
.bg-blue .h3.text-silver,
.bg-blue .h4.text-silver,
.bg-blue .h5.text-silver,
.bg-blue .h6.text-silver {
  color: #9eabae;
}

.bg-blue p.text-mesa,
.bg-blue h1.text-mesa,
.bg-blue h2.text-mesa,
.bg-blue h3.text-mesa,
.bg-blue h4.text-mesa,
.bg-blue h5.text-mesa,
.bg-blue h6.text-mesa,
.bg-blue .h1.text-mesa,
.bg-blue .h2.text-mesa,
.bg-blue .h3.text-mesa,
.bg-blue .h4.text-mesa,
.bg-blue .h5.text-mesa,
.bg-blue .h6.text-mesa {
  color: #a95c42;
}

.bg-blue p.text-ash,
.bg-blue h1.text-ash,
.bg-blue h2.text-ash,
.bg-blue h3.text-ash,
.bg-blue h4.text-ash,
.bg-blue h5.text-ash,
.bg-blue h6.text-ash,
.bg-blue .h1.text-ash,
.bg-blue .h2.text-ash,
.bg-blue .h3.text-ash,
.bg-blue .h4.text-ash,
.bg-blue .h5.text-ash,
.bg-blue .h6.text-ash {
  color: #403635;
}

.bg-blue p.text-sage,
.bg-blue h1.text-sage,
.bg-blue h2.text-sage,
.bg-blue h3.text-sage,
.bg-blue h4.text-sage,
.bg-blue h5.text-sage,
.bg-blue h6.text-sage,
.bg-blue .h1.text-sage,
.bg-blue .h2.text-sage,
.bg-blue .h3.text-sage,
.bg-blue .h4.text-sage,
.bg-blue .h5.text-sage,
.bg-blue .h6.text-sage {
  color: #4a634e;
}

.bg-blue p.text-black,
.bg-blue h1.text-black,
.bg-blue h2.text-black,
.bg-blue h3.text-black,
.bg-blue h4.text-black,
.bg-blue h5.text-black,
.bg-blue h6.text-black,
.bg-blue .h1.text-black,
.bg-blue .h2.text-black,
.bg-blue .h3.text-black,
.bg-blue .h4.text-black,
.bg-blue .h5.text-black,
.bg-blue .h6.text-black {
  color: #000;
}

.bg-blue p.text-primary,
.bg-blue h1.text-primary,
.bg-blue h2.text-primary,
.bg-blue h3.text-primary,
.bg-blue h4.text-primary,
.bg-blue h5.text-primary,
.bg-blue h6.text-primary,
.bg-blue .h1.text-primary,
.bg-blue .h2.text-primary,
.bg-blue .h3.text-primary,
.bg-blue .h4.text-primary,
.bg-blue .h5.text-primary,
.bg-blue .h6.text-primary {
  color: #1e5288;
}

.bg-blue p.text-secondary,
.bg-blue h1.text-secondary,
.bg-blue h2.text-secondary,
.bg-blue h3.text-secondary,
.bg-blue h4.text-secondary,
.bg-blue h5.text-secondary,
.bg-blue h6.text-secondary,
.bg-blue .h1.text-secondary,
.bg-blue .h2.text-secondary,
.bg-blue .h3.text-secondary,
.bg-blue .h4.text-secondary,
.bg-blue .h5.text-secondary,
.bg-blue .h6.text-secondary {
  color: #9eabae;
}

.bg-blue p.text-success,
.bg-blue h1.text-success,
.bg-blue h2.text-success,
.bg-blue h3.text-success,
.bg-blue h4.text-success,
.bg-blue h5.text-success,
.bg-blue h6.text-success,
.bg-blue .h1.text-success,
.bg-blue .h2.text-success,
.bg-blue .h3.text-success,
.bg-blue .h4.text-success,
.bg-blue .h5.text-success,
.bg-blue .h6.text-success {
  color: #70b865;
}

.bg-blue p.text-info,
.bg-blue h1.text-info,
.bg-blue h2.text-info,
.bg-blue h3.text-info,
.bg-blue h4.text-info,
.bg-blue h5.text-info,
.bg-blue h6.text-info,
.bg-blue .h1.text-info,
.bg-blue .h2.text-info,
.bg-blue .h3.text-info,
.bg-blue .h4.text-info,
.bg-blue .h5.text-info,
.bg-blue .h6.text-info {
  color: #81d3eb;
}

.bg-blue p.text-warning,
.bg-blue h1.text-warning,
.bg-blue h2.text-warning,
.bg-blue h3.text-warning,
.bg-blue h4.text-warning,
.bg-blue h5.text-warning,
.bg-blue h6.text-warning,
.bg-blue .h1.text-warning,
.bg-blue .h2.text-warning,
.bg-blue .h3.text-warning,
.bg-blue .h4.text-warning,
.bg-blue .h5.text-warning,
.bg-blue .h6.text-warning {
  color: #f19e1f;
}

.bg-blue p.text-danger,
.bg-blue h1.text-danger,
.bg-blue h2.text-danger,
.bg-blue h3.text-danger,
.bg-blue h4.text-danger,
.bg-blue h5.text-danger,
.bg-blue h6.text-danger,
.bg-blue .h1.text-danger,
.bg-blue .h2.text-danger,
.bg-blue .h3.text-danger,
.bg-blue .h4.text-danger,
.bg-blue .h5.text-danger,
.bg-blue .h6.text-danger {
  color: #ef4056;
}

.bg-blue p.text-light,
.bg-blue h1.text-light,
.bg-blue h2.text-light,
.bg-blue h3.text-light,
.bg-blue h4.text-light,
.bg-blue h5.text-light,
.bg-blue h6.text-light,
.bg-blue .h1.text-light,
.bg-blue .h2.text-light,
.bg-blue .h3.text-light,
.bg-blue .h4.text-light,
.bg-blue .h5.text-light,
.bg-blue .h6.text-light {
  color: #dee2e6;
}

.bg-blue p.text-dark,
.bg-blue h1.text-dark,
.bg-blue h2.text-dark,
.bg-blue h3.text-dark,
.bg-blue h4.text-dark,
.bg-blue h5.text-dark,
.bg-blue h6.text-dark,
.bg-blue .h1.text-dark,
.bg-blue .h2.text-dark,
.bg-blue .h3.text-dark,
.bg-blue .h4.text-dark,
.bg-blue .h5.text-dark,
.bg-blue .h6.text-dark {
  color: #343a40;
}

.bg-blue p.text-primary-dark,
.bg-blue h1.text-primary-dark,
.bg-blue h2.text-primary-dark,
.bg-blue h3.text-primary-dark,
.bg-blue h4.text-primary-dark,
.bg-blue h5.text-primary-dark,
.bg-blue h6.text-primary-dark,
.bg-blue .h1.text-primary-dark,
.bg-blue .h2.text-primary-dark,
.bg-blue .h3.text-primary-dark,
.bg-blue .h4.text-primary-dark,
.bg-blue .h5.text-primary-dark,
.bg-blue .h6.text-primary-dark {
  color: #0c234b;
}

.bg-blue p.text-primary-light,
.bg-blue h1.text-primary-light,
.bg-blue h2.text-primary-light,
.bg-blue h3.text-primary-light,
.bg-blue h4.text-primary-light,
.bg-blue h5.text-primary-light,
.bg-blue h6.text-primary-light,
.bg-blue .h1.text-primary-light,
.bg-blue .h2.text-primary-light,
.bg-blue .h3.text-primary-light,
.bg-blue .h4.text-primary-light,
.bg-blue .h5.text-primary-light,
.bg-blue .h6.text-primary-light {
  color: #378dbd;
}

.bg-blue p.text-accent,
.bg-blue h1.text-accent,
.bg-blue h2.text-accent,
.bg-blue h3.text-accent,
.bg-blue h4.text-accent,
.bg-blue h5.text-accent,
.bg-blue h6.text-accent,
.bg-blue .h1.text-accent,
.bg-blue .h2.text-accent,
.bg-blue .h3.text-accent,
.bg-blue .h4.text-accent,
.bg-blue .h5.text-accent,
.bg-blue .h6.text-accent {
  color: #ab0520;
}

.bg-indigo {
  color: #fff;
  background-color: #6610f2;
  color: #fff;
}

.bg-indigo p,
.bg-indigo h1,
.bg-indigo h2,
.bg-indigo h3,
.bg-indigo h4,
.bg-indigo h5,
.bg-indigo h6,
.bg-indigo .h1,
.bg-indigo .h2,
.bg-indigo .h3,
.bg-indigo .h4,
.bg-indigo .h5,
.bg-indigo .h6 {
  color: #fff;
}

.bg-indigo p.text-blue,
.bg-indigo h1.text-blue,
.bg-indigo h2.text-blue,
.bg-indigo h3.text-blue,
.bg-indigo h4.text-blue,
.bg-indigo h5.text-blue,
.bg-indigo h6.text-blue,
.bg-indigo .h1.text-blue,
.bg-indigo .h2.text-blue,
.bg-indigo .h3.text-blue,
.bg-indigo .h4.text-blue,
.bg-indigo .h5.text-blue,
.bg-indigo .h6.text-blue {
  color: #0c234b;
}

.bg-indigo p.text-indigo,
.bg-indigo h1.text-indigo,
.bg-indigo h2.text-indigo,
.bg-indigo h3.text-indigo,
.bg-indigo h4.text-indigo,
.bg-indigo h5.text-indigo,
.bg-indigo h6.text-indigo,
.bg-indigo .h1.text-indigo,
.bg-indigo .h2.text-indigo,
.bg-indigo .h3.text-indigo,
.bg-indigo .h4.text-indigo,
.bg-indigo .h5.text-indigo,
.bg-indigo .h6.text-indigo {
  color: #6610f2;
}

.bg-indigo p.text-purple,
.bg-indigo h1.text-purple,
.bg-indigo h2.text-purple,
.bg-indigo h3.text-purple,
.bg-indigo h4.text-purple,
.bg-indigo h5.text-purple,
.bg-indigo h6.text-purple,
.bg-indigo .h1.text-purple,
.bg-indigo .h2.text-purple,
.bg-indigo .h3.text-purple,
.bg-indigo .h4.text-purple,
.bg-indigo .h5.text-purple,
.bg-indigo .h6.text-purple {
  color: #6f42c1;
}

.bg-indigo p.text-pink,
.bg-indigo h1.text-pink,
.bg-indigo h2.text-pink,
.bg-indigo h3.text-pink,
.bg-indigo h4.text-pink,
.bg-indigo h5.text-pink,
.bg-indigo h6.text-pink,
.bg-indigo .h1.text-pink,
.bg-indigo .h2.text-pink,
.bg-indigo .h3.text-pink,
.bg-indigo .h4.text-pink,
.bg-indigo .h5.text-pink,
.bg-indigo .h6.text-pink {
  color: #e83e8c;
}

.bg-indigo p.text-red,
.bg-indigo h1.text-red,
.bg-indigo h2.text-red,
.bg-indigo h3.text-red,
.bg-indigo h4.text-red,
.bg-indigo h5.text-red,
.bg-indigo h6.text-red,
.bg-indigo .h1.text-red,
.bg-indigo .h2.text-red,
.bg-indigo .h3.text-red,
.bg-indigo .h4.text-red,
.bg-indigo .h5.text-red,
.bg-indigo .h6.text-red {
  color: #ab0520;
}

.bg-indigo p.text-orange,
.bg-indigo h1.text-orange,
.bg-indigo h2.text-orange,
.bg-indigo h3.text-orange,
.bg-indigo h4.text-orange,
.bg-indigo h5.text-orange,
.bg-indigo h6.text-orange,
.bg-indigo .h1.text-orange,
.bg-indigo .h2.text-orange,
.bg-indigo .h3.text-orange,
.bg-indigo .h4.text-orange,
.bg-indigo .h5.text-orange,
.bg-indigo .h6.text-orange {
  color: #fd7e14;
}

.bg-indigo p.text-yellow,
.bg-indigo h1.text-yellow,
.bg-indigo h2.text-yellow,
.bg-indigo h3.text-yellow,
.bg-indigo h4.text-yellow,
.bg-indigo h5.text-yellow,
.bg-indigo h6.text-yellow,
.bg-indigo .h1.text-yellow,
.bg-indigo .h2.text-yellow,
.bg-indigo .h3.text-yellow,
.bg-indigo .h4.text-yellow,
.bg-indigo .h5.text-yellow,
.bg-indigo .h6.text-yellow {
  color: #ffc107;
}

.bg-indigo p.text-green,
.bg-indigo h1.text-green,
.bg-indigo h2.text-green,
.bg-indigo h3.text-green,
.bg-indigo h4.text-green,
.bg-indigo h5.text-green,
.bg-indigo h6.text-green,
.bg-indigo .h1.text-green,
.bg-indigo .h2.text-green,
.bg-indigo .h3.text-green,
.bg-indigo .h4.text-green,
.bg-indigo .h5.text-green,
.bg-indigo .h6.text-green {
  color: #28a745;
}

.bg-indigo p.text-teal,
.bg-indigo h1.text-teal,
.bg-indigo h2.text-teal,
.bg-indigo h3.text-teal,
.bg-indigo h4.text-teal,
.bg-indigo h5.text-teal,
.bg-indigo h6.text-teal,
.bg-indigo .h1.text-teal,
.bg-indigo .h2.text-teal,
.bg-indigo .h3.text-teal,
.bg-indigo .h4.text-teal,
.bg-indigo .h5.text-teal,
.bg-indigo .h6.text-teal {
  color: #20c997;
}

.bg-indigo p.text-cyan,
.bg-indigo h1.text-cyan,
.bg-indigo h2.text-cyan,
.bg-indigo h3.text-cyan,
.bg-indigo h4.text-cyan,
.bg-indigo h5.text-cyan,
.bg-indigo h6.text-cyan,
.bg-indigo .h1.text-cyan,
.bg-indigo .h2.text-cyan,
.bg-indigo .h3.text-cyan,
.bg-indigo .h4.text-cyan,
.bg-indigo .h5.text-cyan,
.bg-indigo .h6.text-cyan {
  color: #17a2b8;
}

.bg-indigo p.text-white,
.bg-indigo h1.text-white,
.bg-indigo h2.text-white,
.bg-indigo h3.text-white,
.bg-indigo h4.text-white,
.bg-indigo h5.text-white,
.bg-indigo h6.text-white,
.bg-indigo .h1.text-white,
.bg-indigo .h2.text-white,
.bg-indigo .h3.text-white,
.bg-indigo .h4.text-white,
.bg-indigo .h5.text-white,
.bg-indigo .h6.text-white {
  color: #fff;
}

.bg-indigo p.text-gray,
.bg-indigo h1.text-gray,
.bg-indigo h2.text-gray,
.bg-indigo h3.text-gray,
.bg-indigo h4.text-gray,
.bg-indigo h5.text-gray,
.bg-indigo h6.text-gray,
.bg-indigo .h1.text-gray,
.bg-indigo .h2.text-gray,
.bg-indigo .h3.text-gray,
.bg-indigo .h4.text-gray,
.bg-indigo .h5.text-gray,
.bg-indigo .h6.text-gray {
  color: #6c757d;
}

.bg-indigo p.text-gray-dark,
.bg-indigo h1.text-gray-dark,
.bg-indigo h2.text-gray-dark,
.bg-indigo h3.text-gray-dark,
.bg-indigo h4.text-gray-dark,
.bg-indigo h5.text-gray-dark,
.bg-indigo h6.text-gray-dark,
.bg-indigo .h1.text-gray-dark,
.bg-indigo .h2.text-gray-dark,
.bg-indigo .h3.text-gray-dark,
.bg-indigo .h4.text-gray-dark,
.bg-indigo .h5.text-gray-dark,
.bg-indigo .h6.text-gray-dark {
  color: #343a40;
}

.bg-indigo p.text-bloom,
.bg-indigo h1.text-bloom,
.bg-indigo h2.text-bloom,
.bg-indigo h3.text-bloom,
.bg-indigo h4.text-bloom,
.bg-indigo h5.text-bloom,
.bg-indigo h6.text-bloom,
.bg-indigo .h1.text-bloom,
.bg-indigo .h2.text-bloom,
.bg-indigo .h3.text-bloom,
.bg-indigo .h4.text-bloom,
.bg-indigo .h5.text-bloom,
.bg-indigo .h6.text-bloom {
  color: #ef4056;
}

.bg-indigo p.text-chili,
.bg-indigo h1.text-chili,
.bg-indigo h2.text-chili,
.bg-indigo h3.text-chili,
.bg-indigo h4.text-chili,
.bg-indigo h5.text-chili,
.bg-indigo h6.text-chili,
.bg-indigo .h1.text-chili,
.bg-indigo .h2.text-chili,
.bg-indigo .h3.text-chili,
.bg-indigo .h4.text-chili,
.bg-indigo .h5.text-chili,
.bg-indigo .h6.text-chili {
  color: #8b0015;
}

.bg-indigo p.text-sky,
.bg-indigo h1.text-sky,
.bg-indigo h2.text-sky,
.bg-indigo h3.text-sky,
.bg-indigo h4.text-sky,
.bg-indigo h5.text-sky,
.bg-indigo h6.text-sky,
.bg-indigo .h1.text-sky,
.bg-indigo .h2.text-sky,
.bg-indigo .h3.text-sky,
.bg-indigo .h4.text-sky,
.bg-indigo .h5.text-sky,
.bg-indigo .h6.text-sky {
  color: #81d3eb;
}

.bg-indigo p.text-oasis,
.bg-indigo h1.text-oasis,
.bg-indigo h2.text-oasis,
.bg-indigo h3.text-oasis,
.bg-indigo h4.text-oasis,
.bg-indigo h5.text-oasis,
.bg-indigo h6.text-oasis,
.bg-indigo .h1.text-oasis,
.bg-indigo .h2.text-oasis,
.bg-indigo .h3.text-oasis,
.bg-indigo .h4.text-oasis,
.bg-indigo .h5.text-oasis,
.bg-indigo .h6.text-oasis {
  color: #378dbd;
}

.bg-indigo p.text-azurite,
.bg-indigo h1.text-azurite,
.bg-indigo h2.text-azurite,
.bg-indigo h3.text-azurite,
.bg-indigo h4.text-azurite,
.bg-indigo h5.text-azurite,
.bg-indigo h6.text-azurite,
.bg-indigo .h1.text-azurite,
.bg-indigo .h2.text-azurite,
.bg-indigo .h3.text-azurite,
.bg-indigo .h4.text-azurite,
.bg-indigo .h5.text-azurite,
.bg-indigo .h6.text-azurite {
  color: #1e5288;
}

.bg-indigo p.text-midnight,
.bg-indigo h1.text-midnight,
.bg-indigo h2.text-midnight,
.bg-indigo h3.text-midnight,
.bg-indigo h4.text-midnight,
.bg-indigo h5.text-midnight,
.bg-indigo h6.text-midnight,
.bg-indigo .h1.text-midnight,
.bg-indigo .h2.text-midnight,
.bg-indigo .h3.text-midnight,
.bg-indigo .h4.text-midnight,
.bg-indigo .h5.text-midnight,
.bg-indigo .h6.text-midnight {
  color: #001c48;
}

.bg-indigo p.text-cool-gray,
.bg-indigo h1.text-cool-gray,
.bg-indigo h2.text-cool-gray,
.bg-indigo h3.text-cool-gray,
.bg-indigo h4.text-cool-gray,
.bg-indigo h5.text-cool-gray,
.bg-indigo h6.text-cool-gray,
.bg-indigo .h1.text-cool-gray,
.bg-indigo .h2.text-cool-gray,
.bg-indigo .h3.text-cool-gray,
.bg-indigo .h4.text-cool-gray,
.bg-indigo .h5.text-cool-gray,
.bg-indigo .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-indigo p.text-warm-gray,
.bg-indigo h1.text-warm-gray,
.bg-indigo h2.text-warm-gray,
.bg-indigo h3.text-warm-gray,
.bg-indigo h4.text-warm-gray,
.bg-indigo h5.text-warm-gray,
.bg-indigo h6.text-warm-gray,
.bg-indigo .h1.text-warm-gray,
.bg-indigo .h2.text-warm-gray,
.bg-indigo .h3.text-warm-gray,
.bg-indigo .h4.text-warm-gray,
.bg-indigo .h5.text-warm-gray,
.bg-indigo .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-indigo p.text-leaf,
.bg-indigo h1.text-leaf,
.bg-indigo h2.text-leaf,
.bg-indigo h3.text-leaf,
.bg-indigo h4.text-leaf,
.bg-indigo h5.text-leaf,
.bg-indigo h6.text-leaf,
.bg-indigo .h1.text-leaf,
.bg-indigo .h2.text-leaf,
.bg-indigo .h3.text-leaf,
.bg-indigo .h4.text-leaf,
.bg-indigo .h5.text-leaf,
.bg-indigo .h6.text-leaf {
  color: #70b865;
}

.bg-indigo p.text-river,
.bg-indigo h1.text-river,
.bg-indigo h2.text-river,
.bg-indigo h3.text-river,
.bg-indigo h4.text-river,
.bg-indigo h5.text-river,
.bg-indigo h6.text-river,
.bg-indigo .h1.text-river,
.bg-indigo .h2.text-river,
.bg-indigo .h3.text-river,
.bg-indigo .h4.text-river,
.bg-indigo .h5.text-river,
.bg-indigo .h6.text-river {
  color: #007d84;
}

.bg-indigo p.text-silver,
.bg-indigo h1.text-silver,
.bg-indigo h2.text-silver,
.bg-indigo h3.text-silver,
.bg-indigo h4.text-silver,
.bg-indigo h5.text-silver,
.bg-indigo h6.text-silver,
.bg-indigo .h1.text-silver,
.bg-indigo .h2.text-silver,
.bg-indigo .h3.text-silver,
.bg-indigo .h4.text-silver,
.bg-indigo .h5.text-silver,
.bg-indigo .h6.text-silver {
  color: #9eabae;
}

.bg-indigo p.text-mesa,
.bg-indigo h1.text-mesa,
.bg-indigo h2.text-mesa,
.bg-indigo h3.text-mesa,
.bg-indigo h4.text-mesa,
.bg-indigo h5.text-mesa,
.bg-indigo h6.text-mesa,
.bg-indigo .h1.text-mesa,
.bg-indigo .h2.text-mesa,
.bg-indigo .h3.text-mesa,
.bg-indigo .h4.text-mesa,
.bg-indigo .h5.text-mesa,
.bg-indigo .h6.text-mesa {
  color: #a95c42;
}

.bg-indigo p.text-ash,
.bg-indigo h1.text-ash,
.bg-indigo h2.text-ash,
.bg-indigo h3.text-ash,
.bg-indigo h4.text-ash,
.bg-indigo h5.text-ash,
.bg-indigo h6.text-ash,
.bg-indigo .h1.text-ash,
.bg-indigo .h2.text-ash,
.bg-indigo .h3.text-ash,
.bg-indigo .h4.text-ash,
.bg-indigo .h5.text-ash,
.bg-indigo .h6.text-ash {
  color: #403635;
}

.bg-indigo p.text-sage,
.bg-indigo h1.text-sage,
.bg-indigo h2.text-sage,
.bg-indigo h3.text-sage,
.bg-indigo h4.text-sage,
.bg-indigo h5.text-sage,
.bg-indigo h6.text-sage,
.bg-indigo .h1.text-sage,
.bg-indigo .h2.text-sage,
.bg-indigo .h3.text-sage,
.bg-indigo .h4.text-sage,
.bg-indigo .h5.text-sage,
.bg-indigo .h6.text-sage {
  color: #4a634e;
}

.bg-indigo p.text-black,
.bg-indigo h1.text-black,
.bg-indigo h2.text-black,
.bg-indigo h3.text-black,
.bg-indigo h4.text-black,
.bg-indigo h5.text-black,
.bg-indigo h6.text-black,
.bg-indigo .h1.text-black,
.bg-indigo .h2.text-black,
.bg-indigo .h3.text-black,
.bg-indigo .h4.text-black,
.bg-indigo .h5.text-black,
.bg-indigo .h6.text-black {
  color: #000;
}

.bg-indigo p.text-primary,
.bg-indigo h1.text-primary,
.bg-indigo h2.text-primary,
.bg-indigo h3.text-primary,
.bg-indigo h4.text-primary,
.bg-indigo h5.text-primary,
.bg-indigo h6.text-primary,
.bg-indigo .h1.text-primary,
.bg-indigo .h2.text-primary,
.bg-indigo .h3.text-primary,
.bg-indigo .h4.text-primary,
.bg-indigo .h5.text-primary,
.bg-indigo .h6.text-primary {
  color: #1e5288;
}

.bg-indigo p.text-secondary,
.bg-indigo h1.text-secondary,
.bg-indigo h2.text-secondary,
.bg-indigo h3.text-secondary,
.bg-indigo h4.text-secondary,
.bg-indigo h5.text-secondary,
.bg-indigo h6.text-secondary,
.bg-indigo .h1.text-secondary,
.bg-indigo .h2.text-secondary,
.bg-indigo .h3.text-secondary,
.bg-indigo .h4.text-secondary,
.bg-indigo .h5.text-secondary,
.bg-indigo .h6.text-secondary {
  color: #9eabae;
}

.bg-indigo p.text-success,
.bg-indigo h1.text-success,
.bg-indigo h2.text-success,
.bg-indigo h3.text-success,
.bg-indigo h4.text-success,
.bg-indigo h5.text-success,
.bg-indigo h6.text-success,
.bg-indigo .h1.text-success,
.bg-indigo .h2.text-success,
.bg-indigo .h3.text-success,
.bg-indigo .h4.text-success,
.bg-indigo .h5.text-success,
.bg-indigo .h6.text-success {
  color: #70b865;
}

.bg-indigo p.text-info,
.bg-indigo h1.text-info,
.bg-indigo h2.text-info,
.bg-indigo h3.text-info,
.bg-indigo h4.text-info,
.bg-indigo h5.text-info,
.bg-indigo h6.text-info,
.bg-indigo .h1.text-info,
.bg-indigo .h2.text-info,
.bg-indigo .h3.text-info,
.bg-indigo .h4.text-info,
.bg-indigo .h5.text-info,
.bg-indigo .h6.text-info {
  color: #81d3eb;
}

.bg-indigo p.text-warning,
.bg-indigo h1.text-warning,
.bg-indigo h2.text-warning,
.bg-indigo h3.text-warning,
.bg-indigo h4.text-warning,
.bg-indigo h5.text-warning,
.bg-indigo h6.text-warning,
.bg-indigo .h1.text-warning,
.bg-indigo .h2.text-warning,
.bg-indigo .h3.text-warning,
.bg-indigo .h4.text-warning,
.bg-indigo .h5.text-warning,
.bg-indigo .h6.text-warning {
  color: #f19e1f;
}

.bg-indigo p.text-danger,
.bg-indigo h1.text-danger,
.bg-indigo h2.text-danger,
.bg-indigo h3.text-danger,
.bg-indigo h4.text-danger,
.bg-indigo h5.text-danger,
.bg-indigo h6.text-danger,
.bg-indigo .h1.text-danger,
.bg-indigo .h2.text-danger,
.bg-indigo .h3.text-danger,
.bg-indigo .h4.text-danger,
.bg-indigo .h5.text-danger,
.bg-indigo .h6.text-danger {
  color: #ef4056;
}

.bg-indigo p.text-light,
.bg-indigo h1.text-light,
.bg-indigo h2.text-light,
.bg-indigo h3.text-light,
.bg-indigo h4.text-light,
.bg-indigo h5.text-light,
.bg-indigo h6.text-light,
.bg-indigo .h1.text-light,
.bg-indigo .h2.text-light,
.bg-indigo .h3.text-light,
.bg-indigo .h4.text-light,
.bg-indigo .h5.text-light,
.bg-indigo .h6.text-light {
  color: #dee2e6;
}

.bg-indigo p.text-dark,
.bg-indigo h1.text-dark,
.bg-indigo h2.text-dark,
.bg-indigo h3.text-dark,
.bg-indigo h4.text-dark,
.bg-indigo h5.text-dark,
.bg-indigo h6.text-dark,
.bg-indigo .h1.text-dark,
.bg-indigo .h2.text-dark,
.bg-indigo .h3.text-dark,
.bg-indigo .h4.text-dark,
.bg-indigo .h5.text-dark,
.bg-indigo .h6.text-dark {
  color: #343a40;
}

.bg-indigo p.text-primary-dark,
.bg-indigo h1.text-primary-dark,
.bg-indigo h2.text-primary-dark,
.bg-indigo h3.text-primary-dark,
.bg-indigo h4.text-primary-dark,
.bg-indigo h5.text-primary-dark,
.bg-indigo h6.text-primary-dark,
.bg-indigo .h1.text-primary-dark,
.bg-indigo .h2.text-primary-dark,
.bg-indigo .h3.text-primary-dark,
.bg-indigo .h4.text-primary-dark,
.bg-indigo .h5.text-primary-dark,
.bg-indigo .h6.text-primary-dark {
  color: #0c234b;
}

.bg-indigo p.text-primary-light,
.bg-indigo h1.text-primary-light,
.bg-indigo h2.text-primary-light,
.bg-indigo h3.text-primary-light,
.bg-indigo h4.text-primary-light,
.bg-indigo h5.text-primary-light,
.bg-indigo h6.text-primary-light,
.bg-indigo .h1.text-primary-light,
.bg-indigo .h2.text-primary-light,
.bg-indigo .h3.text-primary-light,
.bg-indigo .h4.text-primary-light,
.bg-indigo .h5.text-primary-light,
.bg-indigo .h6.text-primary-light {
  color: #378dbd;
}

.bg-indigo p.text-accent,
.bg-indigo h1.text-accent,
.bg-indigo h2.text-accent,
.bg-indigo h3.text-accent,
.bg-indigo h4.text-accent,
.bg-indigo h5.text-accent,
.bg-indigo h6.text-accent,
.bg-indigo .h1.text-accent,
.bg-indigo .h2.text-accent,
.bg-indigo .h3.text-accent,
.bg-indigo .h4.text-accent,
.bg-indigo .h5.text-accent,
.bg-indigo .h6.text-accent {
  color: #ab0520;
}

.bg-purple {
  color: #fff;
  background-color: #6f42c1;
  color: #fff;
}

.bg-purple p,
.bg-purple h1,
.bg-purple h2,
.bg-purple h3,
.bg-purple h4,
.bg-purple h5,
.bg-purple h6,
.bg-purple .h1,
.bg-purple .h2,
.bg-purple .h3,
.bg-purple .h4,
.bg-purple .h5,
.bg-purple .h6 {
  color: #fff;
}

.bg-purple p.text-blue,
.bg-purple h1.text-blue,
.bg-purple h2.text-blue,
.bg-purple h3.text-blue,
.bg-purple h4.text-blue,
.bg-purple h5.text-blue,
.bg-purple h6.text-blue,
.bg-purple .h1.text-blue,
.bg-purple .h2.text-blue,
.bg-purple .h3.text-blue,
.bg-purple .h4.text-blue,
.bg-purple .h5.text-blue,
.bg-purple .h6.text-blue {
  color: #0c234b;
}

.bg-purple p.text-indigo,
.bg-purple h1.text-indigo,
.bg-purple h2.text-indigo,
.bg-purple h3.text-indigo,
.bg-purple h4.text-indigo,
.bg-purple h5.text-indigo,
.bg-purple h6.text-indigo,
.bg-purple .h1.text-indigo,
.bg-purple .h2.text-indigo,
.bg-purple .h3.text-indigo,
.bg-purple .h4.text-indigo,
.bg-purple .h5.text-indigo,
.bg-purple .h6.text-indigo {
  color: #6610f2;
}

.bg-purple p.text-purple,
.bg-purple h1.text-purple,
.bg-purple h2.text-purple,
.bg-purple h3.text-purple,
.bg-purple h4.text-purple,
.bg-purple h5.text-purple,
.bg-purple h6.text-purple,
.bg-purple .h1.text-purple,
.bg-purple .h2.text-purple,
.bg-purple .h3.text-purple,
.bg-purple .h4.text-purple,
.bg-purple .h5.text-purple,
.bg-purple .h6.text-purple {
  color: #6f42c1;
}

.bg-purple p.text-pink,
.bg-purple h1.text-pink,
.bg-purple h2.text-pink,
.bg-purple h3.text-pink,
.bg-purple h4.text-pink,
.bg-purple h5.text-pink,
.bg-purple h6.text-pink,
.bg-purple .h1.text-pink,
.bg-purple .h2.text-pink,
.bg-purple .h3.text-pink,
.bg-purple .h4.text-pink,
.bg-purple .h5.text-pink,
.bg-purple .h6.text-pink {
  color: #e83e8c;
}

.bg-purple p.text-red,
.bg-purple h1.text-red,
.bg-purple h2.text-red,
.bg-purple h3.text-red,
.bg-purple h4.text-red,
.bg-purple h5.text-red,
.bg-purple h6.text-red,
.bg-purple .h1.text-red,
.bg-purple .h2.text-red,
.bg-purple .h3.text-red,
.bg-purple .h4.text-red,
.bg-purple .h5.text-red,
.bg-purple .h6.text-red {
  color: #ab0520;
}

.bg-purple p.text-orange,
.bg-purple h1.text-orange,
.bg-purple h2.text-orange,
.bg-purple h3.text-orange,
.bg-purple h4.text-orange,
.bg-purple h5.text-orange,
.bg-purple h6.text-orange,
.bg-purple .h1.text-orange,
.bg-purple .h2.text-orange,
.bg-purple .h3.text-orange,
.bg-purple .h4.text-orange,
.bg-purple .h5.text-orange,
.bg-purple .h6.text-orange {
  color: #fd7e14;
}

.bg-purple p.text-yellow,
.bg-purple h1.text-yellow,
.bg-purple h2.text-yellow,
.bg-purple h3.text-yellow,
.bg-purple h4.text-yellow,
.bg-purple h5.text-yellow,
.bg-purple h6.text-yellow,
.bg-purple .h1.text-yellow,
.bg-purple .h2.text-yellow,
.bg-purple .h3.text-yellow,
.bg-purple .h4.text-yellow,
.bg-purple .h5.text-yellow,
.bg-purple .h6.text-yellow {
  color: #ffc107;
}

.bg-purple p.text-green,
.bg-purple h1.text-green,
.bg-purple h2.text-green,
.bg-purple h3.text-green,
.bg-purple h4.text-green,
.bg-purple h5.text-green,
.bg-purple h6.text-green,
.bg-purple .h1.text-green,
.bg-purple .h2.text-green,
.bg-purple .h3.text-green,
.bg-purple .h4.text-green,
.bg-purple .h5.text-green,
.bg-purple .h6.text-green {
  color: #28a745;
}

.bg-purple p.text-teal,
.bg-purple h1.text-teal,
.bg-purple h2.text-teal,
.bg-purple h3.text-teal,
.bg-purple h4.text-teal,
.bg-purple h5.text-teal,
.bg-purple h6.text-teal,
.bg-purple .h1.text-teal,
.bg-purple .h2.text-teal,
.bg-purple .h3.text-teal,
.bg-purple .h4.text-teal,
.bg-purple .h5.text-teal,
.bg-purple .h6.text-teal {
  color: #20c997;
}

.bg-purple p.text-cyan,
.bg-purple h1.text-cyan,
.bg-purple h2.text-cyan,
.bg-purple h3.text-cyan,
.bg-purple h4.text-cyan,
.bg-purple h5.text-cyan,
.bg-purple h6.text-cyan,
.bg-purple .h1.text-cyan,
.bg-purple .h2.text-cyan,
.bg-purple .h3.text-cyan,
.bg-purple .h4.text-cyan,
.bg-purple .h5.text-cyan,
.bg-purple .h6.text-cyan {
  color: #17a2b8;
}

.bg-purple p.text-white,
.bg-purple h1.text-white,
.bg-purple h2.text-white,
.bg-purple h3.text-white,
.bg-purple h4.text-white,
.bg-purple h5.text-white,
.bg-purple h6.text-white,
.bg-purple .h1.text-white,
.bg-purple .h2.text-white,
.bg-purple .h3.text-white,
.bg-purple .h4.text-white,
.bg-purple .h5.text-white,
.bg-purple .h6.text-white {
  color: #fff;
}

.bg-purple p.text-gray,
.bg-purple h1.text-gray,
.bg-purple h2.text-gray,
.bg-purple h3.text-gray,
.bg-purple h4.text-gray,
.bg-purple h5.text-gray,
.bg-purple h6.text-gray,
.bg-purple .h1.text-gray,
.bg-purple .h2.text-gray,
.bg-purple .h3.text-gray,
.bg-purple .h4.text-gray,
.bg-purple .h5.text-gray,
.bg-purple .h6.text-gray {
  color: #6c757d;
}

.bg-purple p.text-gray-dark,
.bg-purple h1.text-gray-dark,
.bg-purple h2.text-gray-dark,
.bg-purple h3.text-gray-dark,
.bg-purple h4.text-gray-dark,
.bg-purple h5.text-gray-dark,
.bg-purple h6.text-gray-dark,
.bg-purple .h1.text-gray-dark,
.bg-purple .h2.text-gray-dark,
.bg-purple .h3.text-gray-dark,
.bg-purple .h4.text-gray-dark,
.bg-purple .h5.text-gray-dark,
.bg-purple .h6.text-gray-dark {
  color: #343a40;
}

.bg-purple p.text-bloom,
.bg-purple h1.text-bloom,
.bg-purple h2.text-bloom,
.bg-purple h3.text-bloom,
.bg-purple h4.text-bloom,
.bg-purple h5.text-bloom,
.bg-purple h6.text-bloom,
.bg-purple .h1.text-bloom,
.bg-purple .h2.text-bloom,
.bg-purple .h3.text-bloom,
.bg-purple .h4.text-bloom,
.bg-purple .h5.text-bloom,
.bg-purple .h6.text-bloom {
  color: #ef4056;
}

.bg-purple p.text-chili,
.bg-purple h1.text-chili,
.bg-purple h2.text-chili,
.bg-purple h3.text-chili,
.bg-purple h4.text-chili,
.bg-purple h5.text-chili,
.bg-purple h6.text-chili,
.bg-purple .h1.text-chili,
.bg-purple .h2.text-chili,
.bg-purple .h3.text-chili,
.bg-purple .h4.text-chili,
.bg-purple .h5.text-chili,
.bg-purple .h6.text-chili {
  color: #8b0015;
}

.bg-purple p.text-sky,
.bg-purple h1.text-sky,
.bg-purple h2.text-sky,
.bg-purple h3.text-sky,
.bg-purple h4.text-sky,
.bg-purple h5.text-sky,
.bg-purple h6.text-sky,
.bg-purple .h1.text-sky,
.bg-purple .h2.text-sky,
.bg-purple .h3.text-sky,
.bg-purple .h4.text-sky,
.bg-purple .h5.text-sky,
.bg-purple .h6.text-sky {
  color: #81d3eb;
}

.bg-purple p.text-oasis,
.bg-purple h1.text-oasis,
.bg-purple h2.text-oasis,
.bg-purple h3.text-oasis,
.bg-purple h4.text-oasis,
.bg-purple h5.text-oasis,
.bg-purple h6.text-oasis,
.bg-purple .h1.text-oasis,
.bg-purple .h2.text-oasis,
.bg-purple .h3.text-oasis,
.bg-purple .h4.text-oasis,
.bg-purple .h5.text-oasis,
.bg-purple .h6.text-oasis {
  color: #378dbd;
}

.bg-purple p.text-azurite,
.bg-purple h1.text-azurite,
.bg-purple h2.text-azurite,
.bg-purple h3.text-azurite,
.bg-purple h4.text-azurite,
.bg-purple h5.text-azurite,
.bg-purple h6.text-azurite,
.bg-purple .h1.text-azurite,
.bg-purple .h2.text-azurite,
.bg-purple .h3.text-azurite,
.bg-purple .h4.text-azurite,
.bg-purple .h5.text-azurite,
.bg-purple .h6.text-azurite {
  color: #1e5288;
}

.bg-purple p.text-midnight,
.bg-purple h1.text-midnight,
.bg-purple h2.text-midnight,
.bg-purple h3.text-midnight,
.bg-purple h4.text-midnight,
.bg-purple h5.text-midnight,
.bg-purple h6.text-midnight,
.bg-purple .h1.text-midnight,
.bg-purple .h2.text-midnight,
.bg-purple .h3.text-midnight,
.bg-purple .h4.text-midnight,
.bg-purple .h5.text-midnight,
.bg-purple .h6.text-midnight {
  color: #001c48;
}

.bg-purple p.text-cool-gray,
.bg-purple h1.text-cool-gray,
.bg-purple h2.text-cool-gray,
.bg-purple h3.text-cool-gray,
.bg-purple h4.text-cool-gray,
.bg-purple h5.text-cool-gray,
.bg-purple h6.text-cool-gray,
.bg-purple .h1.text-cool-gray,
.bg-purple .h2.text-cool-gray,
.bg-purple .h3.text-cool-gray,
.bg-purple .h4.text-cool-gray,
.bg-purple .h5.text-cool-gray,
.bg-purple .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-purple p.text-warm-gray,
.bg-purple h1.text-warm-gray,
.bg-purple h2.text-warm-gray,
.bg-purple h3.text-warm-gray,
.bg-purple h4.text-warm-gray,
.bg-purple h5.text-warm-gray,
.bg-purple h6.text-warm-gray,
.bg-purple .h1.text-warm-gray,
.bg-purple .h2.text-warm-gray,
.bg-purple .h3.text-warm-gray,
.bg-purple .h4.text-warm-gray,
.bg-purple .h5.text-warm-gray,
.bg-purple .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-purple p.text-leaf,
.bg-purple h1.text-leaf,
.bg-purple h2.text-leaf,
.bg-purple h3.text-leaf,
.bg-purple h4.text-leaf,
.bg-purple h5.text-leaf,
.bg-purple h6.text-leaf,
.bg-purple .h1.text-leaf,
.bg-purple .h2.text-leaf,
.bg-purple .h3.text-leaf,
.bg-purple .h4.text-leaf,
.bg-purple .h5.text-leaf,
.bg-purple .h6.text-leaf {
  color: #70b865;
}

.bg-purple p.text-river,
.bg-purple h1.text-river,
.bg-purple h2.text-river,
.bg-purple h3.text-river,
.bg-purple h4.text-river,
.bg-purple h5.text-river,
.bg-purple h6.text-river,
.bg-purple .h1.text-river,
.bg-purple .h2.text-river,
.bg-purple .h3.text-river,
.bg-purple .h4.text-river,
.bg-purple .h5.text-river,
.bg-purple .h6.text-river {
  color: #007d84;
}

.bg-purple p.text-silver,
.bg-purple h1.text-silver,
.bg-purple h2.text-silver,
.bg-purple h3.text-silver,
.bg-purple h4.text-silver,
.bg-purple h5.text-silver,
.bg-purple h6.text-silver,
.bg-purple .h1.text-silver,
.bg-purple .h2.text-silver,
.bg-purple .h3.text-silver,
.bg-purple .h4.text-silver,
.bg-purple .h5.text-silver,
.bg-purple .h6.text-silver {
  color: #9eabae;
}

.bg-purple p.text-mesa,
.bg-purple h1.text-mesa,
.bg-purple h2.text-mesa,
.bg-purple h3.text-mesa,
.bg-purple h4.text-mesa,
.bg-purple h5.text-mesa,
.bg-purple h6.text-mesa,
.bg-purple .h1.text-mesa,
.bg-purple .h2.text-mesa,
.bg-purple .h3.text-mesa,
.bg-purple .h4.text-mesa,
.bg-purple .h5.text-mesa,
.bg-purple .h6.text-mesa {
  color: #a95c42;
}

.bg-purple p.text-ash,
.bg-purple h1.text-ash,
.bg-purple h2.text-ash,
.bg-purple h3.text-ash,
.bg-purple h4.text-ash,
.bg-purple h5.text-ash,
.bg-purple h6.text-ash,
.bg-purple .h1.text-ash,
.bg-purple .h2.text-ash,
.bg-purple .h3.text-ash,
.bg-purple .h4.text-ash,
.bg-purple .h5.text-ash,
.bg-purple .h6.text-ash {
  color: #403635;
}

.bg-purple p.text-sage,
.bg-purple h1.text-sage,
.bg-purple h2.text-sage,
.bg-purple h3.text-sage,
.bg-purple h4.text-sage,
.bg-purple h5.text-sage,
.bg-purple h6.text-sage,
.bg-purple .h1.text-sage,
.bg-purple .h2.text-sage,
.bg-purple .h3.text-sage,
.bg-purple .h4.text-sage,
.bg-purple .h5.text-sage,
.bg-purple .h6.text-sage {
  color: #4a634e;
}

.bg-purple p.text-black,
.bg-purple h1.text-black,
.bg-purple h2.text-black,
.bg-purple h3.text-black,
.bg-purple h4.text-black,
.bg-purple h5.text-black,
.bg-purple h6.text-black,
.bg-purple .h1.text-black,
.bg-purple .h2.text-black,
.bg-purple .h3.text-black,
.bg-purple .h4.text-black,
.bg-purple .h5.text-black,
.bg-purple .h6.text-black {
  color: #000;
}

.bg-purple p.text-primary,
.bg-purple h1.text-primary,
.bg-purple h2.text-primary,
.bg-purple h3.text-primary,
.bg-purple h4.text-primary,
.bg-purple h5.text-primary,
.bg-purple h6.text-primary,
.bg-purple .h1.text-primary,
.bg-purple .h2.text-primary,
.bg-purple .h3.text-primary,
.bg-purple .h4.text-primary,
.bg-purple .h5.text-primary,
.bg-purple .h6.text-primary {
  color: #1e5288;
}

.bg-purple p.text-secondary,
.bg-purple h1.text-secondary,
.bg-purple h2.text-secondary,
.bg-purple h3.text-secondary,
.bg-purple h4.text-secondary,
.bg-purple h5.text-secondary,
.bg-purple h6.text-secondary,
.bg-purple .h1.text-secondary,
.bg-purple .h2.text-secondary,
.bg-purple .h3.text-secondary,
.bg-purple .h4.text-secondary,
.bg-purple .h5.text-secondary,
.bg-purple .h6.text-secondary {
  color: #9eabae;
}

.bg-purple p.text-success,
.bg-purple h1.text-success,
.bg-purple h2.text-success,
.bg-purple h3.text-success,
.bg-purple h4.text-success,
.bg-purple h5.text-success,
.bg-purple h6.text-success,
.bg-purple .h1.text-success,
.bg-purple .h2.text-success,
.bg-purple .h3.text-success,
.bg-purple .h4.text-success,
.bg-purple .h5.text-success,
.bg-purple .h6.text-success {
  color: #70b865;
}

.bg-purple p.text-info,
.bg-purple h1.text-info,
.bg-purple h2.text-info,
.bg-purple h3.text-info,
.bg-purple h4.text-info,
.bg-purple h5.text-info,
.bg-purple h6.text-info,
.bg-purple .h1.text-info,
.bg-purple .h2.text-info,
.bg-purple .h3.text-info,
.bg-purple .h4.text-info,
.bg-purple .h5.text-info,
.bg-purple .h6.text-info {
  color: #81d3eb;
}

.bg-purple p.text-warning,
.bg-purple h1.text-warning,
.bg-purple h2.text-warning,
.bg-purple h3.text-warning,
.bg-purple h4.text-warning,
.bg-purple h5.text-warning,
.bg-purple h6.text-warning,
.bg-purple .h1.text-warning,
.bg-purple .h2.text-warning,
.bg-purple .h3.text-warning,
.bg-purple .h4.text-warning,
.bg-purple .h5.text-warning,
.bg-purple .h6.text-warning {
  color: #f19e1f;
}

.bg-purple p.text-danger,
.bg-purple h1.text-danger,
.bg-purple h2.text-danger,
.bg-purple h3.text-danger,
.bg-purple h4.text-danger,
.bg-purple h5.text-danger,
.bg-purple h6.text-danger,
.bg-purple .h1.text-danger,
.bg-purple .h2.text-danger,
.bg-purple .h3.text-danger,
.bg-purple .h4.text-danger,
.bg-purple .h5.text-danger,
.bg-purple .h6.text-danger {
  color: #ef4056;
}

.bg-purple p.text-light,
.bg-purple h1.text-light,
.bg-purple h2.text-light,
.bg-purple h3.text-light,
.bg-purple h4.text-light,
.bg-purple h5.text-light,
.bg-purple h6.text-light,
.bg-purple .h1.text-light,
.bg-purple .h2.text-light,
.bg-purple .h3.text-light,
.bg-purple .h4.text-light,
.bg-purple .h5.text-light,
.bg-purple .h6.text-light {
  color: #dee2e6;
}

.bg-purple p.text-dark,
.bg-purple h1.text-dark,
.bg-purple h2.text-dark,
.bg-purple h3.text-dark,
.bg-purple h4.text-dark,
.bg-purple h5.text-dark,
.bg-purple h6.text-dark,
.bg-purple .h1.text-dark,
.bg-purple .h2.text-dark,
.bg-purple .h3.text-dark,
.bg-purple .h4.text-dark,
.bg-purple .h5.text-dark,
.bg-purple .h6.text-dark {
  color: #343a40;
}

.bg-purple p.text-primary-dark,
.bg-purple h1.text-primary-dark,
.bg-purple h2.text-primary-dark,
.bg-purple h3.text-primary-dark,
.bg-purple h4.text-primary-dark,
.bg-purple h5.text-primary-dark,
.bg-purple h6.text-primary-dark,
.bg-purple .h1.text-primary-dark,
.bg-purple .h2.text-primary-dark,
.bg-purple .h3.text-primary-dark,
.bg-purple .h4.text-primary-dark,
.bg-purple .h5.text-primary-dark,
.bg-purple .h6.text-primary-dark {
  color: #0c234b;
}

.bg-purple p.text-primary-light,
.bg-purple h1.text-primary-light,
.bg-purple h2.text-primary-light,
.bg-purple h3.text-primary-light,
.bg-purple h4.text-primary-light,
.bg-purple h5.text-primary-light,
.bg-purple h6.text-primary-light,
.bg-purple .h1.text-primary-light,
.bg-purple .h2.text-primary-light,
.bg-purple .h3.text-primary-light,
.bg-purple .h4.text-primary-light,
.bg-purple .h5.text-primary-light,
.bg-purple .h6.text-primary-light {
  color: #378dbd;
}

.bg-purple p.text-accent,
.bg-purple h1.text-accent,
.bg-purple h2.text-accent,
.bg-purple h3.text-accent,
.bg-purple h4.text-accent,
.bg-purple h5.text-accent,
.bg-purple h6.text-accent,
.bg-purple .h1.text-accent,
.bg-purple .h2.text-accent,
.bg-purple .h3.text-accent,
.bg-purple .h4.text-accent,
.bg-purple .h5.text-accent,
.bg-purple .h6.text-accent {
  color: #ab0520;
}

.bg-pink {
  color: #fff;
  background-color: #e83e8c;
  color: #fff;
}

.bg-pink p,
.bg-pink h1,
.bg-pink h2,
.bg-pink h3,
.bg-pink h4,
.bg-pink h5,
.bg-pink h6,
.bg-pink .h1,
.bg-pink .h2,
.bg-pink .h3,
.bg-pink .h4,
.bg-pink .h5,
.bg-pink .h6 {
  color: #fff;
}

.bg-pink p.text-blue,
.bg-pink h1.text-blue,
.bg-pink h2.text-blue,
.bg-pink h3.text-blue,
.bg-pink h4.text-blue,
.bg-pink h5.text-blue,
.bg-pink h6.text-blue,
.bg-pink .h1.text-blue,
.bg-pink .h2.text-blue,
.bg-pink .h3.text-blue,
.bg-pink .h4.text-blue,
.bg-pink .h5.text-blue,
.bg-pink .h6.text-blue {
  color: #0c234b;
}

.bg-pink p.text-indigo,
.bg-pink h1.text-indigo,
.bg-pink h2.text-indigo,
.bg-pink h3.text-indigo,
.bg-pink h4.text-indigo,
.bg-pink h5.text-indigo,
.bg-pink h6.text-indigo,
.bg-pink .h1.text-indigo,
.bg-pink .h2.text-indigo,
.bg-pink .h3.text-indigo,
.bg-pink .h4.text-indigo,
.bg-pink .h5.text-indigo,
.bg-pink .h6.text-indigo {
  color: #6610f2;
}

.bg-pink p.text-purple,
.bg-pink h1.text-purple,
.bg-pink h2.text-purple,
.bg-pink h3.text-purple,
.bg-pink h4.text-purple,
.bg-pink h5.text-purple,
.bg-pink h6.text-purple,
.bg-pink .h1.text-purple,
.bg-pink .h2.text-purple,
.bg-pink .h3.text-purple,
.bg-pink .h4.text-purple,
.bg-pink .h5.text-purple,
.bg-pink .h6.text-purple {
  color: #6f42c1;
}

.bg-pink p.text-pink,
.bg-pink h1.text-pink,
.bg-pink h2.text-pink,
.bg-pink h3.text-pink,
.bg-pink h4.text-pink,
.bg-pink h5.text-pink,
.bg-pink h6.text-pink,
.bg-pink .h1.text-pink,
.bg-pink .h2.text-pink,
.bg-pink .h3.text-pink,
.bg-pink .h4.text-pink,
.bg-pink .h5.text-pink,
.bg-pink .h6.text-pink {
  color: #e83e8c;
}

.bg-pink p.text-red,
.bg-pink h1.text-red,
.bg-pink h2.text-red,
.bg-pink h3.text-red,
.bg-pink h4.text-red,
.bg-pink h5.text-red,
.bg-pink h6.text-red,
.bg-pink .h1.text-red,
.bg-pink .h2.text-red,
.bg-pink .h3.text-red,
.bg-pink .h4.text-red,
.bg-pink .h5.text-red,
.bg-pink .h6.text-red {
  color: #ab0520;
}

.bg-pink p.text-orange,
.bg-pink h1.text-orange,
.bg-pink h2.text-orange,
.bg-pink h3.text-orange,
.bg-pink h4.text-orange,
.bg-pink h5.text-orange,
.bg-pink h6.text-orange,
.bg-pink .h1.text-orange,
.bg-pink .h2.text-orange,
.bg-pink .h3.text-orange,
.bg-pink .h4.text-orange,
.bg-pink .h5.text-orange,
.bg-pink .h6.text-orange {
  color: #fd7e14;
}

.bg-pink p.text-yellow,
.bg-pink h1.text-yellow,
.bg-pink h2.text-yellow,
.bg-pink h3.text-yellow,
.bg-pink h4.text-yellow,
.bg-pink h5.text-yellow,
.bg-pink h6.text-yellow,
.bg-pink .h1.text-yellow,
.bg-pink .h2.text-yellow,
.bg-pink .h3.text-yellow,
.bg-pink .h4.text-yellow,
.bg-pink .h5.text-yellow,
.bg-pink .h6.text-yellow {
  color: #ffc107;
}

.bg-pink p.text-green,
.bg-pink h1.text-green,
.bg-pink h2.text-green,
.bg-pink h3.text-green,
.bg-pink h4.text-green,
.bg-pink h5.text-green,
.bg-pink h6.text-green,
.bg-pink .h1.text-green,
.bg-pink .h2.text-green,
.bg-pink .h3.text-green,
.bg-pink .h4.text-green,
.bg-pink .h5.text-green,
.bg-pink .h6.text-green {
  color: #28a745;
}

.bg-pink p.text-teal,
.bg-pink h1.text-teal,
.bg-pink h2.text-teal,
.bg-pink h3.text-teal,
.bg-pink h4.text-teal,
.bg-pink h5.text-teal,
.bg-pink h6.text-teal,
.bg-pink .h1.text-teal,
.bg-pink .h2.text-teal,
.bg-pink .h3.text-teal,
.bg-pink .h4.text-teal,
.bg-pink .h5.text-teal,
.bg-pink .h6.text-teal {
  color: #20c997;
}

.bg-pink p.text-cyan,
.bg-pink h1.text-cyan,
.bg-pink h2.text-cyan,
.bg-pink h3.text-cyan,
.bg-pink h4.text-cyan,
.bg-pink h5.text-cyan,
.bg-pink h6.text-cyan,
.bg-pink .h1.text-cyan,
.bg-pink .h2.text-cyan,
.bg-pink .h3.text-cyan,
.bg-pink .h4.text-cyan,
.bg-pink .h5.text-cyan,
.bg-pink .h6.text-cyan {
  color: #17a2b8;
}

.bg-pink p.text-white,
.bg-pink h1.text-white,
.bg-pink h2.text-white,
.bg-pink h3.text-white,
.bg-pink h4.text-white,
.bg-pink h5.text-white,
.bg-pink h6.text-white,
.bg-pink .h1.text-white,
.bg-pink .h2.text-white,
.bg-pink .h3.text-white,
.bg-pink .h4.text-white,
.bg-pink .h5.text-white,
.bg-pink .h6.text-white {
  color: #fff;
}

.bg-pink p.text-gray,
.bg-pink h1.text-gray,
.bg-pink h2.text-gray,
.bg-pink h3.text-gray,
.bg-pink h4.text-gray,
.bg-pink h5.text-gray,
.bg-pink h6.text-gray,
.bg-pink .h1.text-gray,
.bg-pink .h2.text-gray,
.bg-pink .h3.text-gray,
.bg-pink .h4.text-gray,
.bg-pink .h5.text-gray,
.bg-pink .h6.text-gray {
  color: #6c757d;
}

.bg-pink p.text-gray-dark,
.bg-pink h1.text-gray-dark,
.bg-pink h2.text-gray-dark,
.bg-pink h3.text-gray-dark,
.bg-pink h4.text-gray-dark,
.bg-pink h5.text-gray-dark,
.bg-pink h6.text-gray-dark,
.bg-pink .h1.text-gray-dark,
.bg-pink .h2.text-gray-dark,
.bg-pink .h3.text-gray-dark,
.bg-pink .h4.text-gray-dark,
.bg-pink .h5.text-gray-dark,
.bg-pink .h6.text-gray-dark {
  color: #343a40;
}

.bg-pink p.text-bloom,
.bg-pink h1.text-bloom,
.bg-pink h2.text-bloom,
.bg-pink h3.text-bloom,
.bg-pink h4.text-bloom,
.bg-pink h5.text-bloom,
.bg-pink h6.text-bloom,
.bg-pink .h1.text-bloom,
.bg-pink .h2.text-bloom,
.bg-pink .h3.text-bloom,
.bg-pink .h4.text-bloom,
.bg-pink .h5.text-bloom,
.bg-pink .h6.text-bloom {
  color: #ef4056;
}

.bg-pink p.text-chili,
.bg-pink h1.text-chili,
.bg-pink h2.text-chili,
.bg-pink h3.text-chili,
.bg-pink h4.text-chili,
.bg-pink h5.text-chili,
.bg-pink h6.text-chili,
.bg-pink .h1.text-chili,
.bg-pink .h2.text-chili,
.bg-pink .h3.text-chili,
.bg-pink .h4.text-chili,
.bg-pink .h5.text-chili,
.bg-pink .h6.text-chili {
  color: #8b0015;
}

.bg-pink p.text-sky,
.bg-pink h1.text-sky,
.bg-pink h2.text-sky,
.bg-pink h3.text-sky,
.bg-pink h4.text-sky,
.bg-pink h5.text-sky,
.bg-pink h6.text-sky,
.bg-pink .h1.text-sky,
.bg-pink .h2.text-sky,
.bg-pink .h3.text-sky,
.bg-pink .h4.text-sky,
.bg-pink .h5.text-sky,
.bg-pink .h6.text-sky {
  color: #81d3eb;
}

.bg-pink p.text-oasis,
.bg-pink h1.text-oasis,
.bg-pink h2.text-oasis,
.bg-pink h3.text-oasis,
.bg-pink h4.text-oasis,
.bg-pink h5.text-oasis,
.bg-pink h6.text-oasis,
.bg-pink .h1.text-oasis,
.bg-pink .h2.text-oasis,
.bg-pink .h3.text-oasis,
.bg-pink .h4.text-oasis,
.bg-pink .h5.text-oasis,
.bg-pink .h6.text-oasis {
  color: #378dbd;
}

.bg-pink p.text-azurite,
.bg-pink h1.text-azurite,
.bg-pink h2.text-azurite,
.bg-pink h3.text-azurite,
.bg-pink h4.text-azurite,
.bg-pink h5.text-azurite,
.bg-pink h6.text-azurite,
.bg-pink .h1.text-azurite,
.bg-pink .h2.text-azurite,
.bg-pink .h3.text-azurite,
.bg-pink .h4.text-azurite,
.bg-pink .h5.text-azurite,
.bg-pink .h6.text-azurite {
  color: #1e5288;
}

.bg-pink p.text-midnight,
.bg-pink h1.text-midnight,
.bg-pink h2.text-midnight,
.bg-pink h3.text-midnight,
.bg-pink h4.text-midnight,
.bg-pink h5.text-midnight,
.bg-pink h6.text-midnight,
.bg-pink .h1.text-midnight,
.bg-pink .h2.text-midnight,
.bg-pink .h3.text-midnight,
.bg-pink .h4.text-midnight,
.bg-pink .h5.text-midnight,
.bg-pink .h6.text-midnight {
  color: #001c48;
}

.bg-pink p.text-cool-gray,
.bg-pink h1.text-cool-gray,
.bg-pink h2.text-cool-gray,
.bg-pink h3.text-cool-gray,
.bg-pink h4.text-cool-gray,
.bg-pink h5.text-cool-gray,
.bg-pink h6.text-cool-gray,
.bg-pink .h1.text-cool-gray,
.bg-pink .h2.text-cool-gray,
.bg-pink .h3.text-cool-gray,
.bg-pink .h4.text-cool-gray,
.bg-pink .h5.text-cool-gray,
.bg-pink .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-pink p.text-warm-gray,
.bg-pink h1.text-warm-gray,
.bg-pink h2.text-warm-gray,
.bg-pink h3.text-warm-gray,
.bg-pink h4.text-warm-gray,
.bg-pink h5.text-warm-gray,
.bg-pink h6.text-warm-gray,
.bg-pink .h1.text-warm-gray,
.bg-pink .h2.text-warm-gray,
.bg-pink .h3.text-warm-gray,
.bg-pink .h4.text-warm-gray,
.bg-pink .h5.text-warm-gray,
.bg-pink .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-pink p.text-leaf,
.bg-pink h1.text-leaf,
.bg-pink h2.text-leaf,
.bg-pink h3.text-leaf,
.bg-pink h4.text-leaf,
.bg-pink h5.text-leaf,
.bg-pink h6.text-leaf,
.bg-pink .h1.text-leaf,
.bg-pink .h2.text-leaf,
.bg-pink .h3.text-leaf,
.bg-pink .h4.text-leaf,
.bg-pink .h5.text-leaf,
.bg-pink .h6.text-leaf {
  color: #70b865;
}

.bg-pink p.text-river,
.bg-pink h1.text-river,
.bg-pink h2.text-river,
.bg-pink h3.text-river,
.bg-pink h4.text-river,
.bg-pink h5.text-river,
.bg-pink h6.text-river,
.bg-pink .h1.text-river,
.bg-pink .h2.text-river,
.bg-pink .h3.text-river,
.bg-pink .h4.text-river,
.bg-pink .h5.text-river,
.bg-pink .h6.text-river {
  color: #007d84;
}

.bg-pink p.text-silver,
.bg-pink h1.text-silver,
.bg-pink h2.text-silver,
.bg-pink h3.text-silver,
.bg-pink h4.text-silver,
.bg-pink h5.text-silver,
.bg-pink h6.text-silver,
.bg-pink .h1.text-silver,
.bg-pink .h2.text-silver,
.bg-pink .h3.text-silver,
.bg-pink .h4.text-silver,
.bg-pink .h5.text-silver,
.bg-pink .h6.text-silver {
  color: #9eabae;
}

.bg-pink p.text-mesa,
.bg-pink h1.text-mesa,
.bg-pink h2.text-mesa,
.bg-pink h3.text-mesa,
.bg-pink h4.text-mesa,
.bg-pink h5.text-mesa,
.bg-pink h6.text-mesa,
.bg-pink .h1.text-mesa,
.bg-pink .h2.text-mesa,
.bg-pink .h3.text-mesa,
.bg-pink .h4.text-mesa,
.bg-pink .h5.text-mesa,
.bg-pink .h6.text-mesa {
  color: #a95c42;
}

.bg-pink p.text-ash,
.bg-pink h1.text-ash,
.bg-pink h2.text-ash,
.bg-pink h3.text-ash,
.bg-pink h4.text-ash,
.bg-pink h5.text-ash,
.bg-pink h6.text-ash,
.bg-pink .h1.text-ash,
.bg-pink .h2.text-ash,
.bg-pink .h3.text-ash,
.bg-pink .h4.text-ash,
.bg-pink .h5.text-ash,
.bg-pink .h6.text-ash {
  color: #403635;
}

.bg-pink p.text-sage,
.bg-pink h1.text-sage,
.bg-pink h2.text-sage,
.bg-pink h3.text-sage,
.bg-pink h4.text-sage,
.bg-pink h5.text-sage,
.bg-pink h6.text-sage,
.bg-pink .h1.text-sage,
.bg-pink .h2.text-sage,
.bg-pink .h3.text-sage,
.bg-pink .h4.text-sage,
.bg-pink .h5.text-sage,
.bg-pink .h6.text-sage {
  color: #4a634e;
}

.bg-pink p.text-black,
.bg-pink h1.text-black,
.bg-pink h2.text-black,
.bg-pink h3.text-black,
.bg-pink h4.text-black,
.bg-pink h5.text-black,
.bg-pink h6.text-black,
.bg-pink .h1.text-black,
.bg-pink .h2.text-black,
.bg-pink .h3.text-black,
.bg-pink .h4.text-black,
.bg-pink .h5.text-black,
.bg-pink .h6.text-black {
  color: #000;
}

.bg-pink p.text-primary,
.bg-pink h1.text-primary,
.bg-pink h2.text-primary,
.bg-pink h3.text-primary,
.bg-pink h4.text-primary,
.bg-pink h5.text-primary,
.bg-pink h6.text-primary,
.bg-pink .h1.text-primary,
.bg-pink .h2.text-primary,
.bg-pink .h3.text-primary,
.bg-pink .h4.text-primary,
.bg-pink .h5.text-primary,
.bg-pink .h6.text-primary {
  color: #1e5288;
}

.bg-pink p.text-secondary,
.bg-pink h1.text-secondary,
.bg-pink h2.text-secondary,
.bg-pink h3.text-secondary,
.bg-pink h4.text-secondary,
.bg-pink h5.text-secondary,
.bg-pink h6.text-secondary,
.bg-pink .h1.text-secondary,
.bg-pink .h2.text-secondary,
.bg-pink .h3.text-secondary,
.bg-pink .h4.text-secondary,
.bg-pink .h5.text-secondary,
.bg-pink .h6.text-secondary {
  color: #9eabae;
}

.bg-pink p.text-success,
.bg-pink h1.text-success,
.bg-pink h2.text-success,
.bg-pink h3.text-success,
.bg-pink h4.text-success,
.bg-pink h5.text-success,
.bg-pink h6.text-success,
.bg-pink .h1.text-success,
.bg-pink .h2.text-success,
.bg-pink .h3.text-success,
.bg-pink .h4.text-success,
.bg-pink .h5.text-success,
.bg-pink .h6.text-success {
  color: #70b865;
}

.bg-pink p.text-info,
.bg-pink h1.text-info,
.bg-pink h2.text-info,
.bg-pink h3.text-info,
.bg-pink h4.text-info,
.bg-pink h5.text-info,
.bg-pink h6.text-info,
.bg-pink .h1.text-info,
.bg-pink .h2.text-info,
.bg-pink .h3.text-info,
.bg-pink .h4.text-info,
.bg-pink .h5.text-info,
.bg-pink .h6.text-info {
  color: #81d3eb;
}

.bg-pink p.text-warning,
.bg-pink h1.text-warning,
.bg-pink h2.text-warning,
.bg-pink h3.text-warning,
.bg-pink h4.text-warning,
.bg-pink h5.text-warning,
.bg-pink h6.text-warning,
.bg-pink .h1.text-warning,
.bg-pink .h2.text-warning,
.bg-pink .h3.text-warning,
.bg-pink .h4.text-warning,
.bg-pink .h5.text-warning,
.bg-pink .h6.text-warning {
  color: #f19e1f;
}

.bg-pink p.text-danger,
.bg-pink h1.text-danger,
.bg-pink h2.text-danger,
.bg-pink h3.text-danger,
.bg-pink h4.text-danger,
.bg-pink h5.text-danger,
.bg-pink h6.text-danger,
.bg-pink .h1.text-danger,
.bg-pink .h2.text-danger,
.bg-pink .h3.text-danger,
.bg-pink .h4.text-danger,
.bg-pink .h5.text-danger,
.bg-pink .h6.text-danger {
  color: #ef4056;
}

.bg-pink p.text-light,
.bg-pink h1.text-light,
.bg-pink h2.text-light,
.bg-pink h3.text-light,
.bg-pink h4.text-light,
.bg-pink h5.text-light,
.bg-pink h6.text-light,
.bg-pink .h1.text-light,
.bg-pink .h2.text-light,
.bg-pink .h3.text-light,
.bg-pink .h4.text-light,
.bg-pink .h5.text-light,
.bg-pink .h6.text-light {
  color: #dee2e6;
}

.bg-pink p.text-dark,
.bg-pink h1.text-dark,
.bg-pink h2.text-dark,
.bg-pink h3.text-dark,
.bg-pink h4.text-dark,
.bg-pink h5.text-dark,
.bg-pink h6.text-dark,
.bg-pink .h1.text-dark,
.bg-pink .h2.text-dark,
.bg-pink .h3.text-dark,
.bg-pink .h4.text-dark,
.bg-pink .h5.text-dark,
.bg-pink .h6.text-dark {
  color: #343a40;
}

.bg-pink p.text-primary-dark,
.bg-pink h1.text-primary-dark,
.bg-pink h2.text-primary-dark,
.bg-pink h3.text-primary-dark,
.bg-pink h4.text-primary-dark,
.bg-pink h5.text-primary-dark,
.bg-pink h6.text-primary-dark,
.bg-pink .h1.text-primary-dark,
.bg-pink .h2.text-primary-dark,
.bg-pink .h3.text-primary-dark,
.bg-pink .h4.text-primary-dark,
.bg-pink .h5.text-primary-dark,
.bg-pink .h6.text-primary-dark {
  color: #0c234b;
}

.bg-pink p.text-primary-light,
.bg-pink h1.text-primary-light,
.bg-pink h2.text-primary-light,
.bg-pink h3.text-primary-light,
.bg-pink h4.text-primary-light,
.bg-pink h5.text-primary-light,
.bg-pink h6.text-primary-light,
.bg-pink .h1.text-primary-light,
.bg-pink .h2.text-primary-light,
.bg-pink .h3.text-primary-light,
.bg-pink .h4.text-primary-light,
.bg-pink .h5.text-primary-light,
.bg-pink .h6.text-primary-light {
  color: #378dbd;
}

.bg-pink p.text-accent,
.bg-pink h1.text-accent,
.bg-pink h2.text-accent,
.bg-pink h3.text-accent,
.bg-pink h4.text-accent,
.bg-pink h5.text-accent,
.bg-pink h6.text-accent,
.bg-pink .h1.text-accent,
.bg-pink .h2.text-accent,
.bg-pink .h3.text-accent,
.bg-pink .h4.text-accent,
.bg-pink .h5.text-accent,
.bg-pink .h6.text-accent {
  color: #ab0520;
}

.bg-red {
  color: #fff;
  background-color: #ab0520;
  color: #fff;
}

.bg-red p,
.bg-red h1,
.bg-red h2,
.bg-red h3,
.bg-red h4,
.bg-red h5,
.bg-red h6,
.bg-red .h1,
.bg-red .h2,
.bg-red .h3,
.bg-red .h4,
.bg-red .h5,
.bg-red .h6 {
  color: #fff;
}

.bg-red p.text-blue,
.bg-red h1.text-blue,
.bg-red h2.text-blue,
.bg-red h3.text-blue,
.bg-red h4.text-blue,
.bg-red h5.text-blue,
.bg-red h6.text-blue,
.bg-red .h1.text-blue,
.bg-red .h2.text-blue,
.bg-red .h3.text-blue,
.bg-red .h4.text-blue,
.bg-red .h5.text-blue,
.bg-red .h6.text-blue {
  color: #0c234b;
}

.bg-red p.text-indigo,
.bg-red h1.text-indigo,
.bg-red h2.text-indigo,
.bg-red h3.text-indigo,
.bg-red h4.text-indigo,
.bg-red h5.text-indigo,
.bg-red h6.text-indigo,
.bg-red .h1.text-indigo,
.bg-red .h2.text-indigo,
.bg-red .h3.text-indigo,
.bg-red .h4.text-indigo,
.bg-red .h5.text-indigo,
.bg-red .h6.text-indigo {
  color: #6610f2;
}

.bg-red p.text-purple,
.bg-red h1.text-purple,
.bg-red h2.text-purple,
.bg-red h3.text-purple,
.bg-red h4.text-purple,
.bg-red h5.text-purple,
.bg-red h6.text-purple,
.bg-red .h1.text-purple,
.bg-red .h2.text-purple,
.bg-red .h3.text-purple,
.bg-red .h4.text-purple,
.bg-red .h5.text-purple,
.bg-red .h6.text-purple {
  color: #6f42c1;
}

.bg-red p.text-pink,
.bg-red h1.text-pink,
.bg-red h2.text-pink,
.bg-red h3.text-pink,
.bg-red h4.text-pink,
.bg-red h5.text-pink,
.bg-red h6.text-pink,
.bg-red .h1.text-pink,
.bg-red .h2.text-pink,
.bg-red .h3.text-pink,
.bg-red .h4.text-pink,
.bg-red .h5.text-pink,
.bg-red .h6.text-pink {
  color: #e83e8c;
}

.bg-red p.text-red,
.bg-red h1.text-red,
.bg-red h2.text-red,
.bg-red h3.text-red,
.bg-red h4.text-red,
.bg-red h5.text-red,
.bg-red h6.text-red,
.bg-red .h1.text-red,
.bg-red .h2.text-red,
.bg-red .h3.text-red,
.bg-red .h4.text-red,
.bg-red .h5.text-red,
.bg-red .h6.text-red {
  color: #ab0520;
}

.bg-red p.text-orange,
.bg-red h1.text-orange,
.bg-red h2.text-orange,
.bg-red h3.text-orange,
.bg-red h4.text-orange,
.bg-red h5.text-orange,
.bg-red h6.text-orange,
.bg-red .h1.text-orange,
.bg-red .h2.text-orange,
.bg-red .h3.text-orange,
.bg-red .h4.text-orange,
.bg-red .h5.text-orange,
.bg-red .h6.text-orange {
  color: #fd7e14;
}

.bg-red p.text-yellow,
.bg-red h1.text-yellow,
.bg-red h2.text-yellow,
.bg-red h3.text-yellow,
.bg-red h4.text-yellow,
.bg-red h5.text-yellow,
.bg-red h6.text-yellow,
.bg-red .h1.text-yellow,
.bg-red .h2.text-yellow,
.bg-red .h3.text-yellow,
.bg-red .h4.text-yellow,
.bg-red .h5.text-yellow,
.bg-red .h6.text-yellow {
  color: #ffc107;
}

.bg-red p.text-green,
.bg-red h1.text-green,
.bg-red h2.text-green,
.bg-red h3.text-green,
.bg-red h4.text-green,
.bg-red h5.text-green,
.bg-red h6.text-green,
.bg-red .h1.text-green,
.bg-red .h2.text-green,
.bg-red .h3.text-green,
.bg-red .h4.text-green,
.bg-red .h5.text-green,
.bg-red .h6.text-green {
  color: #28a745;
}

.bg-red p.text-teal,
.bg-red h1.text-teal,
.bg-red h2.text-teal,
.bg-red h3.text-teal,
.bg-red h4.text-teal,
.bg-red h5.text-teal,
.bg-red h6.text-teal,
.bg-red .h1.text-teal,
.bg-red .h2.text-teal,
.bg-red .h3.text-teal,
.bg-red .h4.text-teal,
.bg-red .h5.text-teal,
.bg-red .h6.text-teal {
  color: #20c997;
}

.bg-red p.text-cyan,
.bg-red h1.text-cyan,
.bg-red h2.text-cyan,
.bg-red h3.text-cyan,
.bg-red h4.text-cyan,
.bg-red h5.text-cyan,
.bg-red h6.text-cyan,
.bg-red .h1.text-cyan,
.bg-red .h2.text-cyan,
.bg-red .h3.text-cyan,
.bg-red .h4.text-cyan,
.bg-red .h5.text-cyan,
.bg-red .h6.text-cyan {
  color: #17a2b8;
}

.bg-red p.text-white,
.bg-red h1.text-white,
.bg-red h2.text-white,
.bg-red h3.text-white,
.bg-red h4.text-white,
.bg-red h5.text-white,
.bg-red h6.text-white,
.bg-red .h1.text-white,
.bg-red .h2.text-white,
.bg-red .h3.text-white,
.bg-red .h4.text-white,
.bg-red .h5.text-white,
.bg-red .h6.text-white {
  color: #fff;
}

.bg-red p.text-gray,
.bg-red h1.text-gray,
.bg-red h2.text-gray,
.bg-red h3.text-gray,
.bg-red h4.text-gray,
.bg-red h5.text-gray,
.bg-red h6.text-gray,
.bg-red .h1.text-gray,
.bg-red .h2.text-gray,
.bg-red .h3.text-gray,
.bg-red .h4.text-gray,
.bg-red .h5.text-gray,
.bg-red .h6.text-gray {
  color: #6c757d;
}

.bg-red p.text-gray-dark,
.bg-red h1.text-gray-dark,
.bg-red h2.text-gray-dark,
.bg-red h3.text-gray-dark,
.bg-red h4.text-gray-dark,
.bg-red h5.text-gray-dark,
.bg-red h6.text-gray-dark,
.bg-red .h1.text-gray-dark,
.bg-red .h2.text-gray-dark,
.bg-red .h3.text-gray-dark,
.bg-red .h4.text-gray-dark,
.bg-red .h5.text-gray-dark,
.bg-red .h6.text-gray-dark {
  color: #343a40;
}

.bg-red p.text-bloom,
.bg-red h1.text-bloom,
.bg-red h2.text-bloom,
.bg-red h3.text-bloom,
.bg-red h4.text-bloom,
.bg-red h5.text-bloom,
.bg-red h6.text-bloom,
.bg-red .h1.text-bloom,
.bg-red .h2.text-bloom,
.bg-red .h3.text-bloom,
.bg-red .h4.text-bloom,
.bg-red .h5.text-bloom,
.bg-red .h6.text-bloom {
  color: #ef4056;
}

.bg-red p.text-chili,
.bg-red h1.text-chili,
.bg-red h2.text-chili,
.bg-red h3.text-chili,
.bg-red h4.text-chili,
.bg-red h5.text-chili,
.bg-red h6.text-chili,
.bg-red .h1.text-chili,
.bg-red .h2.text-chili,
.bg-red .h3.text-chili,
.bg-red .h4.text-chili,
.bg-red .h5.text-chili,
.bg-red .h6.text-chili {
  color: #8b0015;
}

.bg-red p.text-sky,
.bg-red h1.text-sky,
.bg-red h2.text-sky,
.bg-red h3.text-sky,
.bg-red h4.text-sky,
.bg-red h5.text-sky,
.bg-red h6.text-sky,
.bg-red .h1.text-sky,
.bg-red .h2.text-sky,
.bg-red .h3.text-sky,
.bg-red .h4.text-sky,
.bg-red .h5.text-sky,
.bg-red .h6.text-sky {
  color: #81d3eb;
}

.bg-red p.text-oasis,
.bg-red h1.text-oasis,
.bg-red h2.text-oasis,
.bg-red h3.text-oasis,
.bg-red h4.text-oasis,
.bg-red h5.text-oasis,
.bg-red h6.text-oasis,
.bg-red .h1.text-oasis,
.bg-red .h2.text-oasis,
.bg-red .h3.text-oasis,
.bg-red .h4.text-oasis,
.bg-red .h5.text-oasis,
.bg-red .h6.text-oasis {
  color: #378dbd;
}

.bg-red p.text-azurite,
.bg-red h1.text-azurite,
.bg-red h2.text-azurite,
.bg-red h3.text-azurite,
.bg-red h4.text-azurite,
.bg-red h5.text-azurite,
.bg-red h6.text-azurite,
.bg-red .h1.text-azurite,
.bg-red .h2.text-azurite,
.bg-red .h3.text-azurite,
.bg-red .h4.text-azurite,
.bg-red .h5.text-azurite,
.bg-red .h6.text-azurite {
  color: #1e5288;
}

.bg-red p.text-midnight,
.bg-red h1.text-midnight,
.bg-red h2.text-midnight,
.bg-red h3.text-midnight,
.bg-red h4.text-midnight,
.bg-red h5.text-midnight,
.bg-red h6.text-midnight,
.bg-red .h1.text-midnight,
.bg-red .h2.text-midnight,
.bg-red .h3.text-midnight,
.bg-red .h4.text-midnight,
.bg-red .h5.text-midnight,
.bg-red .h6.text-midnight {
  color: #001c48;
}

.bg-red p.text-cool-gray,
.bg-red h1.text-cool-gray,
.bg-red h2.text-cool-gray,
.bg-red h3.text-cool-gray,
.bg-red h4.text-cool-gray,
.bg-red h5.text-cool-gray,
.bg-red h6.text-cool-gray,
.bg-red .h1.text-cool-gray,
.bg-red .h2.text-cool-gray,
.bg-red .h3.text-cool-gray,
.bg-red .h4.text-cool-gray,
.bg-red .h5.text-cool-gray,
.bg-red .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-red p.text-warm-gray,
.bg-red h1.text-warm-gray,
.bg-red h2.text-warm-gray,
.bg-red h3.text-warm-gray,
.bg-red h4.text-warm-gray,
.bg-red h5.text-warm-gray,
.bg-red h6.text-warm-gray,
.bg-red .h1.text-warm-gray,
.bg-red .h2.text-warm-gray,
.bg-red .h3.text-warm-gray,
.bg-red .h4.text-warm-gray,
.bg-red .h5.text-warm-gray,
.bg-red .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-red p.text-leaf,
.bg-red h1.text-leaf,
.bg-red h2.text-leaf,
.bg-red h3.text-leaf,
.bg-red h4.text-leaf,
.bg-red h5.text-leaf,
.bg-red h6.text-leaf,
.bg-red .h1.text-leaf,
.bg-red .h2.text-leaf,
.bg-red .h3.text-leaf,
.bg-red .h4.text-leaf,
.bg-red .h5.text-leaf,
.bg-red .h6.text-leaf {
  color: #70b865;
}

.bg-red p.text-river,
.bg-red h1.text-river,
.bg-red h2.text-river,
.bg-red h3.text-river,
.bg-red h4.text-river,
.bg-red h5.text-river,
.bg-red h6.text-river,
.bg-red .h1.text-river,
.bg-red .h2.text-river,
.bg-red .h3.text-river,
.bg-red .h4.text-river,
.bg-red .h5.text-river,
.bg-red .h6.text-river {
  color: #007d84;
}

.bg-red p.text-silver,
.bg-red h1.text-silver,
.bg-red h2.text-silver,
.bg-red h3.text-silver,
.bg-red h4.text-silver,
.bg-red h5.text-silver,
.bg-red h6.text-silver,
.bg-red .h1.text-silver,
.bg-red .h2.text-silver,
.bg-red .h3.text-silver,
.bg-red .h4.text-silver,
.bg-red .h5.text-silver,
.bg-red .h6.text-silver {
  color: #9eabae;
}

.bg-red p.text-mesa,
.bg-red h1.text-mesa,
.bg-red h2.text-mesa,
.bg-red h3.text-mesa,
.bg-red h4.text-mesa,
.bg-red h5.text-mesa,
.bg-red h6.text-mesa,
.bg-red .h1.text-mesa,
.bg-red .h2.text-mesa,
.bg-red .h3.text-mesa,
.bg-red .h4.text-mesa,
.bg-red .h5.text-mesa,
.bg-red .h6.text-mesa {
  color: #a95c42;
}

.bg-red p.text-ash,
.bg-red h1.text-ash,
.bg-red h2.text-ash,
.bg-red h3.text-ash,
.bg-red h4.text-ash,
.bg-red h5.text-ash,
.bg-red h6.text-ash,
.bg-red .h1.text-ash,
.bg-red .h2.text-ash,
.bg-red .h3.text-ash,
.bg-red .h4.text-ash,
.bg-red .h5.text-ash,
.bg-red .h6.text-ash {
  color: #403635;
}

.bg-red p.text-sage,
.bg-red h1.text-sage,
.bg-red h2.text-sage,
.bg-red h3.text-sage,
.bg-red h4.text-sage,
.bg-red h5.text-sage,
.bg-red h6.text-sage,
.bg-red .h1.text-sage,
.bg-red .h2.text-sage,
.bg-red .h3.text-sage,
.bg-red .h4.text-sage,
.bg-red .h5.text-sage,
.bg-red .h6.text-sage {
  color: #4a634e;
}

.bg-red p.text-black,
.bg-red h1.text-black,
.bg-red h2.text-black,
.bg-red h3.text-black,
.bg-red h4.text-black,
.bg-red h5.text-black,
.bg-red h6.text-black,
.bg-red .h1.text-black,
.bg-red .h2.text-black,
.bg-red .h3.text-black,
.bg-red .h4.text-black,
.bg-red .h5.text-black,
.bg-red .h6.text-black {
  color: #000;
}

.bg-red p.text-primary,
.bg-red h1.text-primary,
.bg-red h2.text-primary,
.bg-red h3.text-primary,
.bg-red h4.text-primary,
.bg-red h5.text-primary,
.bg-red h6.text-primary,
.bg-red .h1.text-primary,
.bg-red .h2.text-primary,
.bg-red .h3.text-primary,
.bg-red .h4.text-primary,
.bg-red .h5.text-primary,
.bg-red .h6.text-primary {
  color: #1e5288;
}

.bg-red p.text-secondary,
.bg-red h1.text-secondary,
.bg-red h2.text-secondary,
.bg-red h3.text-secondary,
.bg-red h4.text-secondary,
.bg-red h5.text-secondary,
.bg-red h6.text-secondary,
.bg-red .h1.text-secondary,
.bg-red .h2.text-secondary,
.bg-red .h3.text-secondary,
.bg-red .h4.text-secondary,
.bg-red .h5.text-secondary,
.bg-red .h6.text-secondary {
  color: #9eabae;
}

.bg-red p.text-success,
.bg-red h1.text-success,
.bg-red h2.text-success,
.bg-red h3.text-success,
.bg-red h4.text-success,
.bg-red h5.text-success,
.bg-red h6.text-success,
.bg-red .h1.text-success,
.bg-red .h2.text-success,
.bg-red .h3.text-success,
.bg-red .h4.text-success,
.bg-red .h5.text-success,
.bg-red .h6.text-success {
  color: #70b865;
}

.bg-red p.text-info,
.bg-red h1.text-info,
.bg-red h2.text-info,
.bg-red h3.text-info,
.bg-red h4.text-info,
.bg-red h5.text-info,
.bg-red h6.text-info,
.bg-red .h1.text-info,
.bg-red .h2.text-info,
.bg-red .h3.text-info,
.bg-red .h4.text-info,
.bg-red .h5.text-info,
.bg-red .h6.text-info {
  color: #81d3eb;
}

.bg-red p.text-warning,
.bg-red h1.text-warning,
.bg-red h2.text-warning,
.bg-red h3.text-warning,
.bg-red h4.text-warning,
.bg-red h5.text-warning,
.bg-red h6.text-warning,
.bg-red .h1.text-warning,
.bg-red .h2.text-warning,
.bg-red .h3.text-warning,
.bg-red .h4.text-warning,
.bg-red .h5.text-warning,
.bg-red .h6.text-warning {
  color: #f19e1f;
}

.bg-red p.text-danger,
.bg-red h1.text-danger,
.bg-red h2.text-danger,
.bg-red h3.text-danger,
.bg-red h4.text-danger,
.bg-red h5.text-danger,
.bg-red h6.text-danger,
.bg-red .h1.text-danger,
.bg-red .h2.text-danger,
.bg-red .h3.text-danger,
.bg-red .h4.text-danger,
.bg-red .h5.text-danger,
.bg-red .h6.text-danger {
  color: #ef4056;
}

.bg-red p.text-light,
.bg-red h1.text-light,
.bg-red h2.text-light,
.bg-red h3.text-light,
.bg-red h4.text-light,
.bg-red h5.text-light,
.bg-red h6.text-light,
.bg-red .h1.text-light,
.bg-red .h2.text-light,
.bg-red .h3.text-light,
.bg-red .h4.text-light,
.bg-red .h5.text-light,
.bg-red .h6.text-light {
  color: #dee2e6;
}

.bg-red p.text-dark,
.bg-red h1.text-dark,
.bg-red h2.text-dark,
.bg-red h3.text-dark,
.bg-red h4.text-dark,
.bg-red h5.text-dark,
.bg-red h6.text-dark,
.bg-red .h1.text-dark,
.bg-red .h2.text-dark,
.bg-red .h3.text-dark,
.bg-red .h4.text-dark,
.bg-red .h5.text-dark,
.bg-red .h6.text-dark {
  color: #343a40;
}

.bg-red p.text-primary-dark,
.bg-red h1.text-primary-dark,
.bg-red h2.text-primary-dark,
.bg-red h3.text-primary-dark,
.bg-red h4.text-primary-dark,
.bg-red h5.text-primary-dark,
.bg-red h6.text-primary-dark,
.bg-red .h1.text-primary-dark,
.bg-red .h2.text-primary-dark,
.bg-red .h3.text-primary-dark,
.bg-red .h4.text-primary-dark,
.bg-red .h5.text-primary-dark,
.bg-red .h6.text-primary-dark {
  color: #0c234b;
}

.bg-red p.text-primary-light,
.bg-red h1.text-primary-light,
.bg-red h2.text-primary-light,
.bg-red h3.text-primary-light,
.bg-red h4.text-primary-light,
.bg-red h5.text-primary-light,
.bg-red h6.text-primary-light,
.bg-red .h1.text-primary-light,
.bg-red .h2.text-primary-light,
.bg-red .h3.text-primary-light,
.bg-red .h4.text-primary-light,
.bg-red .h5.text-primary-light,
.bg-red .h6.text-primary-light {
  color: #378dbd;
}

.bg-red p.text-accent,
.bg-red h1.text-accent,
.bg-red h2.text-accent,
.bg-red h3.text-accent,
.bg-red h4.text-accent,
.bg-red h5.text-accent,
.bg-red h6.text-accent,
.bg-red .h1.text-accent,
.bg-red .h2.text-accent,
.bg-red .h3.text-accent,
.bg-red .h4.text-accent,
.bg-red .h5.text-accent,
.bg-red .h6.text-accent {
  color: #ab0520;
}

.bg-orange {
  color: #fff;
  background-color: #fd7e14;
  color: #fff;
}

.bg-orange p,
.bg-orange h1,
.bg-orange h2,
.bg-orange h3,
.bg-orange h4,
.bg-orange h5,
.bg-orange h6,
.bg-orange .h1,
.bg-orange .h2,
.bg-orange .h3,
.bg-orange .h4,
.bg-orange .h5,
.bg-orange .h6 {
  color: #fff;
}

.bg-orange p.text-blue,
.bg-orange h1.text-blue,
.bg-orange h2.text-blue,
.bg-orange h3.text-blue,
.bg-orange h4.text-blue,
.bg-orange h5.text-blue,
.bg-orange h6.text-blue,
.bg-orange .h1.text-blue,
.bg-orange .h2.text-blue,
.bg-orange .h3.text-blue,
.bg-orange .h4.text-blue,
.bg-orange .h5.text-blue,
.bg-orange .h6.text-blue {
  color: #0c234b;
}

.bg-orange p.text-indigo,
.bg-orange h1.text-indigo,
.bg-orange h2.text-indigo,
.bg-orange h3.text-indigo,
.bg-orange h4.text-indigo,
.bg-orange h5.text-indigo,
.bg-orange h6.text-indigo,
.bg-orange .h1.text-indigo,
.bg-orange .h2.text-indigo,
.bg-orange .h3.text-indigo,
.bg-orange .h4.text-indigo,
.bg-orange .h5.text-indigo,
.bg-orange .h6.text-indigo {
  color: #6610f2;
}

.bg-orange p.text-purple,
.bg-orange h1.text-purple,
.bg-orange h2.text-purple,
.bg-orange h3.text-purple,
.bg-orange h4.text-purple,
.bg-orange h5.text-purple,
.bg-orange h6.text-purple,
.bg-orange .h1.text-purple,
.bg-orange .h2.text-purple,
.bg-orange .h3.text-purple,
.bg-orange .h4.text-purple,
.bg-orange .h5.text-purple,
.bg-orange .h6.text-purple {
  color: #6f42c1;
}

.bg-orange p.text-pink,
.bg-orange h1.text-pink,
.bg-orange h2.text-pink,
.bg-orange h3.text-pink,
.bg-orange h4.text-pink,
.bg-orange h5.text-pink,
.bg-orange h6.text-pink,
.bg-orange .h1.text-pink,
.bg-orange .h2.text-pink,
.bg-orange .h3.text-pink,
.bg-orange .h4.text-pink,
.bg-orange .h5.text-pink,
.bg-orange .h6.text-pink {
  color: #e83e8c;
}

.bg-orange p.text-red,
.bg-orange h1.text-red,
.bg-orange h2.text-red,
.bg-orange h3.text-red,
.bg-orange h4.text-red,
.bg-orange h5.text-red,
.bg-orange h6.text-red,
.bg-orange .h1.text-red,
.bg-orange .h2.text-red,
.bg-orange .h3.text-red,
.bg-orange .h4.text-red,
.bg-orange .h5.text-red,
.bg-orange .h6.text-red {
  color: #ab0520;
}

.bg-orange p.text-orange,
.bg-orange h1.text-orange,
.bg-orange h2.text-orange,
.bg-orange h3.text-orange,
.bg-orange h4.text-orange,
.bg-orange h5.text-orange,
.bg-orange h6.text-orange,
.bg-orange .h1.text-orange,
.bg-orange .h2.text-orange,
.bg-orange .h3.text-orange,
.bg-orange .h4.text-orange,
.bg-orange .h5.text-orange,
.bg-orange .h6.text-orange {
  color: #fd7e14;
}

.bg-orange p.text-yellow,
.bg-orange h1.text-yellow,
.bg-orange h2.text-yellow,
.bg-orange h3.text-yellow,
.bg-orange h4.text-yellow,
.bg-orange h5.text-yellow,
.bg-orange h6.text-yellow,
.bg-orange .h1.text-yellow,
.bg-orange .h2.text-yellow,
.bg-orange .h3.text-yellow,
.bg-orange .h4.text-yellow,
.bg-orange .h5.text-yellow,
.bg-orange .h6.text-yellow {
  color: #ffc107;
}

.bg-orange p.text-green,
.bg-orange h1.text-green,
.bg-orange h2.text-green,
.bg-orange h3.text-green,
.bg-orange h4.text-green,
.bg-orange h5.text-green,
.bg-orange h6.text-green,
.bg-orange .h1.text-green,
.bg-orange .h2.text-green,
.bg-orange .h3.text-green,
.bg-orange .h4.text-green,
.bg-orange .h5.text-green,
.bg-orange .h6.text-green {
  color: #28a745;
}

.bg-orange p.text-teal,
.bg-orange h1.text-teal,
.bg-orange h2.text-teal,
.bg-orange h3.text-teal,
.bg-orange h4.text-teal,
.bg-orange h5.text-teal,
.bg-orange h6.text-teal,
.bg-orange .h1.text-teal,
.bg-orange .h2.text-teal,
.bg-orange .h3.text-teal,
.bg-orange .h4.text-teal,
.bg-orange .h5.text-teal,
.bg-orange .h6.text-teal {
  color: #20c997;
}

.bg-orange p.text-cyan,
.bg-orange h1.text-cyan,
.bg-orange h2.text-cyan,
.bg-orange h3.text-cyan,
.bg-orange h4.text-cyan,
.bg-orange h5.text-cyan,
.bg-orange h6.text-cyan,
.bg-orange .h1.text-cyan,
.bg-orange .h2.text-cyan,
.bg-orange .h3.text-cyan,
.bg-orange .h4.text-cyan,
.bg-orange .h5.text-cyan,
.bg-orange .h6.text-cyan {
  color: #17a2b8;
}

.bg-orange p.text-white,
.bg-orange h1.text-white,
.bg-orange h2.text-white,
.bg-orange h3.text-white,
.bg-orange h4.text-white,
.bg-orange h5.text-white,
.bg-orange h6.text-white,
.bg-orange .h1.text-white,
.bg-orange .h2.text-white,
.bg-orange .h3.text-white,
.bg-orange .h4.text-white,
.bg-orange .h5.text-white,
.bg-orange .h6.text-white {
  color: #fff;
}

.bg-orange p.text-gray,
.bg-orange h1.text-gray,
.bg-orange h2.text-gray,
.bg-orange h3.text-gray,
.bg-orange h4.text-gray,
.bg-orange h5.text-gray,
.bg-orange h6.text-gray,
.bg-orange .h1.text-gray,
.bg-orange .h2.text-gray,
.bg-orange .h3.text-gray,
.bg-orange .h4.text-gray,
.bg-orange .h5.text-gray,
.bg-orange .h6.text-gray {
  color: #6c757d;
}

.bg-orange p.text-gray-dark,
.bg-orange h1.text-gray-dark,
.bg-orange h2.text-gray-dark,
.bg-orange h3.text-gray-dark,
.bg-orange h4.text-gray-dark,
.bg-orange h5.text-gray-dark,
.bg-orange h6.text-gray-dark,
.bg-orange .h1.text-gray-dark,
.bg-orange .h2.text-gray-dark,
.bg-orange .h3.text-gray-dark,
.bg-orange .h4.text-gray-dark,
.bg-orange .h5.text-gray-dark,
.bg-orange .h6.text-gray-dark {
  color: #343a40;
}

.bg-orange p.text-bloom,
.bg-orange h1.text-bloom,
.bg-orange h2.text-bloom,
.bg-orange h3.text-bloom,
.bg-orange h4.text-bloom,
.bg-orange h5.text-bloom,
.bg-orange h6.text-bloom,
.bg-orange .h1.text-bloom,
.bg-orange .h2.text-bloom,
.bg-orange .h3.text-bloom,
.bg-orange .h4.text-bloom,
.bg-orange .h5.text-bloom,
.bg-orange .h6.text-bloom {
  color: #ef4056;
}

.bg-orange p.text-chili,
.bg-orange h1.text-chili,
.bg-orange h2.text-chili,
.bg-orange h3.text-chili,
.bg-orange h4.text-chili,
.bg-orange h5.text-chili,
.bg-orange h6.text-chili,
.bg-orange .h1.text-chili,
.bg-orange .h2.text-chili,
.bg-orange .h3.text-chili,
.bg-orange .h4.text-chili,
.bg-orange .h5.text-chili,
.bg-orange .h6.text-chili {
  color: #8b0015;
}

.bg-orange p.text-sky,
.bg-orange h1.text-sky,
.bg-orange h2.text-sky,
.bg-orange h3.text-sky,
.bg-orange h4.text-sky,
.bg-orange h5.text-sky,
.bg-orange h6.text-sky,
.bg-orange .h1.text-sky,
.bg-orange .h2.text-sky,
.bg-orange .h3.text-sky,
.bg-orange .h4.text-sky,
.bg-orange .h5.text-sky,
.bg-orange .h6.text-sky {
  color: #81d3eb;
}

.bg-orange p.text-oasis,
.bg-orange h1.text-oasis,
.bg-orange h2.text-oasis,
.bg-orange h3.text-oasis,
.bg-orange h4.text-oasis,
.bg-orange h5.text-oasis,
.bg-orange h6.text-oasis,
.bg-orange .h1.text-oasis,
.bg-orange .h2.text-oasis,
.bg-orange .h3.text-oasis,
.bg-orange .h4.text-oasis,
.bg-orange .h5.text-oasis,
.bg-orange .h6.text-oasis {
  color: #378dbd;
}

.bg-orange p.text-azurite,
.bg-orange h1.text-azurite,
.bg-orange h2.text-azurite,
.bg-orange h3.text-azurite,
.bg-orange h4.text-azurite,
.bg-orange h5.text-azurite,
.bg-orange h6.text-azurite,
.bg-orange .h1.text-azurite,
.bg-orange .h2.text-azurite,
.bg-orange .h3.text-azurite,
.bg-orange .h4.text-azurite,
.bg-orange .h5.text-azurite,
.bg-orange .h6.text-azurite {
  color: #1e5288;
}

.bg-orange p.text-midnight,
.bg-orange h1.text-midnight,
.bg-orange h2.text-midnight,
.bg-orange h3.text-midnight,
.bg-orange h4.text-midnight,
.bg-orange h5.text-midnight,
.bg-orange h6.text-midnight,
.bg-orange .h1.text-midnight,
.bg-orange .h2.text-midnight,
.bg-orange .h3.text-midnight,
.bg-orange .h4.text-midnight,
.bg-orange .h5.text-midnight,
.bg-orange .h6.text-midnight {
  color: #001c48;
}

.bg-orange p.text-cool-gray,
.bg-orange h1.text-cool-gray,
.bg-orange h2.text-cool-gray,
.bg-orange h3.text-cool-gray,
.bg-orange h4.text-cool-gray,
.bg-orange h5.text-cool-gray,
.bg-orange h6.text-cool-gray,
.bg-orange .h1.text-cool-gray,
.bg-orange .h2.text-cool-gray,
.bg-orange .h3.text-cool-gray,
.bg-orange .h4.text-cool-gray,
.bg-orange .h5.text-cool-gray,
.bg-orange .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-orange p.text-warm-gray,
.bg-orange h1.text-warm-gray,
.bg-orange h2.text-warm-gray,
.bg-orange h3.text-warm-gray,
.bg-orange h4.text-warm-gray,
.bg-orange h5.text-warm-gray,
.bg-orange h6.text-warm-gray,
.bg-orange .h1.text-warm-gray,
.bg-orange .h2.text-warm-gray,
.bg-orange .h3.text-warm-gray,
.bg-orange .h4.text-warm-gray,
.bg-orange .h5.text-warm-gray,
.bg-orange .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-orange p.text-leaf,
.bg-orange h1.text-leaf,
.bg-orange h2.text-leaf,
.bg-orange h3.text-leaf,
.bg-orange h4.text-leaf,
.bg-orange h5.text-leaf,
.bg-orange h6.text-leaf,
.bg-orange .h1.text-leaf,
.bg-orange .h2.text-leaf,
.bg-orange .h3.text-leaf,
.bg-orange .h4.text-leaf,
.bg-orange .h5.text-leaf,
.bg-orange .h6.text-leaf {
  color: #70b865;
}

.bg-orange p.text-river,
.bg-orange h1.text-river,
.bg-orange h2.text-river,
.bg-orange h3.text-river,
.bg-orange h4.text-river,
.bg-orange h5.text-river,
.bg-orange h6.text-river,
.bg-orange .h1.text-river,
.bg-orange .h2.text-river,
.bg-orange .h3.text-river,
.bg-orange .h4.text-river,
.bg-orange .h5.text-river,
.bg-orange .h6.text-river {
  color: #007d84;
}

.bg-orange p.text-silver,
.bg-orange h1.text-silver,
.bg-orange h2.text-silver,
.bg-orange h3.text-silver,
.bg-orange h4.text-silver,
.bg-orange h5.text-silver,
.bg-orange h6.text-silver,
.bg-orange .h1.text-silver,
.bg-orange .h2.text-silver,
.bg-orange .h3.text-silver,
.bg-orange .h4.text-silver,
.bg-orange .h5.text-silver,
.bg-orange .h6.text-silver {
  color: #9eabae;
}

.bg-orange p.text-mesa,
.bg-orange h1.text-mesa,
.bg-orange h2.text-mesa,
.bg-orange h3.text-mesa,
.bg-orange h4.text-mesa,
.bg-orange h5.text-mesa,
.bg-orange h6.text-mesa,
.bg-orange .h1.text-mesa,
.bg-orange .h2.text-mesa,
.bg-orange .h3.text-mesa,
.bg-orange .h4.text-mesa,
.bg-orange .h5.text-mesa,
.bg-orange .h6.text-mesa {
  color: #a95c42;
}

.bg-orange p.text-ash,
.bg-orange h1.text-ash,
.bg-orange h2.text-ash,
.bg-orange h3.text-ash,
.bg-orange h4.text-ash,
.bg-orange h5.text-ash,
.bg-orange h6.text-ash,
.bg-orange .h1.text-ash,
.bg-orange .h2.text-ash,
.bg-orange .h3.text-ash,
.bg-orange .h4.text-ash,
.bg-orange .h5.text-ash,
.bg-orange .h6.text-ash {
  color: #403635;
}

.bg-orange p.text-sage,
.bg-orange h1.text-sage,
.bg-orange h2.text-sage,
.bg-orange h3.text-sage,
.bg-orange h4.text-sage,
.bg-orange h5.text-sage,
.bg-orange h6.text-sage,
.bg-orange .h1.text-sage,
.bg-orange .h2.text-sage,
.bg-orange .h3.text-sage,
.bg-orange .h4.text-sage,
.bg-orange .h5.text-sage,
.bg-orange .h6.text-sage {
  color: #4a634e;
}

.bg-orange p.text-black,
.bg-orange h1.text-black,
.bg-orange h2.text-black,
.bg-orange h3.text-black,
.bg-orange h4.text-black,
.bg-orange h5.text-black,
.bg-orange h6.text-black,
.bg-orange .h1.text-black,
.bg-orange .h2.text-black,
.bg-orange .h3.text-black,
.bg-orange .h4.text-black,
.bg-orange .h5.text-black,
.bg-orange .h6.text-black {
  color: #000;
}

.bg-orange p.text-primary,
.bg-orange h1.text-primary,
.bg-orange h2.text-primary,
.bg-orange h3.text-primary,
.bg-orange h4.text-primary,
.bg-orange h5.text-primary,
.bg-orange h6.text-primary,
.bg-orange .h1.text-primary,
.bg-orange .h2.text-primary,
.bg-orange .h3.text-primary,
.bg-orange .h4.text-primary,
.bg-orange .h5.text-primary,
.bg-orange .h6.text-primary {
  color: #1e5288;
}

.bg-orange p.text-secondary,
.bg-orange h1.text-secondary,
.bg-orange h2.text-secondary,
.bg-orange h3.text-secondary,
.bg-orange h4.text-secondary,
.bg-orange h5.text-secondary,
.bg-orange h6.text-secondary,
.bg-orange .h1.text-secondary,
.bg-orange .h2.text-secondary,
.bg-orange .h3.text-secondary,
.bg-orange .h4.text-secondary,
.bg-orange .h5.text-secondary,
.bg-orange .h6.text-secondary {
  color: #9eabae;
}

.bg-orange p.text-success,
.bg-orange h1.text-success,
.bg-orange h2.text-success,
.bg-orange h3.text-success,
.bg-orange h4.text-success,
.bg-orange h5.text-success,
.bg-orange h6.text-success,
.bg-orange .h1.text-success,
.bg-orange .h2.text-success,
.bg-orange .h3.text-success,
.bg-orange .h4.text-success,
.bg-orange .h5.text-success,
.bg-orange .h6.text-success {
  color: #70b865;
}

.bg-orange p.text-info,
.bg-orange h1.text-info,
.bg-orange h2.text-info,
.bg-orange h3.text-info,
.bg-orange h4.text-info,
.bg-orange h5.text-info,
.bg-orange h6.text-info,
.bg-orange .h1.text-info,
.bg-orange .h2.text-info,
.bg-orange .h3.text-info,
.bg-orange .h4.text-info,
.bg-orange .h5.text-info,
.bg-orange .h6.text-info {
  color: #81d3eb;
}

.bg-orange p.text-warning,
.bg-orange h1.text-warning,
.bg-orange h2.text-warning,
.bg-orange h3.text-warning,
.bg-orange h4.text-warning,
.bg-orange h5.text-warning,
.bg-orange h6.text-warning,
.bg-orange .h1.text-warning,
.bg-orange .h2.text-warning,
.bg-orange .h3.text-warning,
.bg-orange .h4.text-warning,
.bg-orange .h5.text-warning,
.bg-orange .h6.text-warning {
  color: #f19e1f;
}

.bg-orange p.text-danger,
.bg-orange h1.text-danger,
.bg-orange h2.text-danger,
.bg-orange h3.text-danger,
.bg-orange h4.text-danger,
.bg-orange h5.text-danger,
.bg-orange h6.text-danger,
.bg-orange .h1.text-danger,
.bg-orange .h2.text-danger,
.bg-orange .h3.text-danger,
.bg-orange .h4.text-danger,
.bg-orange .h5.text-danger,
.bg-orange .h6.text-danger {
  color: #ef4056;
}

.bg-orange p.text-light,
.bg-orange h1.text-light,
.bg-orange h2.text-light,
.bg-orange h3.text-light,
.bg-orange h4.text-light,
.bg-orange h5.text-light,
.bg-orange h6.text-light,
.bg-orange .h1.text-light,
.bg-orange .h2.text-light,
.bg-orange .h3.text-light,
.bg-orange .h4.text-light,
.bg-orange .h5.text-light,
.bg-orange .h6.text-light {
  color: #dee2e6;
}

.bg-orange p.text-dark,
.bg-orange h1.text-dark,
.bg-orange h2.text-dark,
.bg-orange h3.text-dark,
.bg-orange h4.text-dark,
.bg-orange h5.text-dark,
.bg-orange h6.text-dark,
.bg-orange .h1.text-dark,
.bg-orange .h2.text-dark,
.bg-orange .h3.text-dark,
.bg-orange .h4.text-dark,
.bg-orange .h5.text-dark,
.bg-orange .h6.text-dark {
  color: #343a40;
}

.bg-orange p.text-primary-dark,
.bg-orange h1.text-primary-dark,
.bg-orange h2.text-primary-dark,
.bg-orange h3.text-primary-dark,
.bg-orange h4.text-primary-dark,
.bg-orange h5.text-primary-dark,
.bg-orange h6.text-primary-dark,
.bg-orange .h1.text-primary-dark,
.bg-orange .h2.text-primary-dark,
.bg-orange .h3.text-primary-dark,
.bg-orange .h4.text-primary-dark,
.bg-orange .h5.text-primary-dark,
.bg-orange .h6.text-primary-dark {
  color: #0c234b;
}

.bg-orange p.text-primary-light,
.bg-orange h1.text-primary-light,
.bg-orange h2.text-primary-light,
.bg-orange h3.text-primary-light,
.bg-orange h4.text-primary-light,
.bg-orange h5.text-primary-light,
.bg-orange h6.text-primary-light,
.bg-orange .h1.text-primary-light,
.bg-orange .h2.text-primary-light,
.bg-orange .h3.text-primary-light,
.bg-orange .h4.text-primary-light,
.bg-orange .h5.text-primary-light,
.bg-orange .h6.text-primary-light {
  color: #378dbd;
}

.bg-orange p.text-accent,
.bg-orange h1.text-accent,
.bg-orange h2.text-accent,
.bg-orange h3.text-accent,
.bg-orange h4.text-accent,
.bg-orange h5.text-accent,
.bg-orange h6.text-accent,
.bg-orange .h1.text-accent,
.bg-orange .h2.text-accent,
.bg-orange .h3.text-accent,
.bg-orange .h4.text-accent,
.bg-orange .h5.text-accent,
.bg-orange .h6.text-accent {
  color: #ab0520;
}

.bg-yellow {
  color: #fff;
  background-color: #ffc107;
  color: #fff;
}

.bg-yellow p,
.bg-yellow h1,
.bg-yellow h2,
.bg-yellow h3,
.bg-yellow h4,
.bg-yellow h5,
.bg-yellow h6,
.bg-yellow .h1,
.bg-yellow .h2,
.bg-yellow .h3,
.bg-yellow .h4,
.bg-yellow .h5,
.bg-yellow .h6 {
  color: #fff;
}

.bg-yellow p.text-blue,
.bg-yellow h1.text-blue,
.bg-yellow h2.text-blue,
.bg-yellow h3.text-blue,
.bg-yellow h4.text-blue,
.bg-yellow h5.text-blue,
.bg-yellow h6.text-blue,
.bg-yellow .h1.text-blue,
.bg-yellow .h2.text-blue,
.bg-yellow .h3.text-blue,
.bg-yellow .h4.text-blue,
.bg-yellow .h5.text-blue,
.bg-yellow .h6.text-blue {
  color: #0c234b;
}

.bg-yellow p.text-indigo,
.bg-yellow h1.text-indigo,
.bg-yellow h2.text-indigo,
.bg-yellow h3.text-indigo,
.bg-yellow h4.text-indigo,
.bg-yellow h5.text-indigo,
.bg-yellow h6.text-indigo,
.bg-yellow .h1.text-indigo,
.bg-yellow .h2.text-indigo,
.bg-yellow .h3.text-indigo,
.bg-yellow .h4.text-indigo,
.bg-yellow .h5.text-indigo,
.bg-yellow .h6.text-indigo {
  color: #6610f2;
}

.bg-yellow p.text-purple,
.bg-yellow h1.text-purple,
.bg-yellow h2.text-purple,
.bg-yellow h3.text-purple,
.bg-yellow h4.text-purple,
.bg-yellow h5.text-purple,
.bg-yellow h6.text-purple,
.bg-yellow .h1.text-purple,
.bg-yellow .h2.text-purple,
.bg-yellow .h3.text-purple,
.bg-yellow .h4.text-purple,
.bg-yellow .h5.text-purple,
.bg-yellow .h6.text-purple {
  color: #6f42c1;
}

.bg-yellow p.text-pink,
.bg-yellow h1.text-pink,
.bg-yellow h2.text-pink,
.bg-yellow h3.text-pink,
.bg-yellow h4.text-pink,
.bg-yellow h5.text-pink,
.bg-yellow h6.text-pink,
.bg-yellow .h1.text-pink,
.bg-yellow .h2.text-pink,
.bg-yellow .h3.text-pink,
.bg-yellow .h4.text-pink,
.bg-yellow .h5.text-pink,
.bg-yellow .h6.text-pink {
  color: #e83e8c;
}

.bg-yellow p.text-red,
.bg-yellow h1.text-red,
.bg-yellow h2.text-red,
.bg-yellow h3.text-red,
.bg-yellow h4.text-red,
.bg-yellow h5.text-red,
.bg-yellow h6.text-red,
.bg-yellow .h1.text-red,
.bg-yellow .h2.text-red,
.bg-yellow .h3.text-red,
.bg-yellow .h4.text-red,
.bg-yellow .h5.text-red,
.bg-yellow .h6.text-red {
  color: #ab0520;
}

.bg-yellow p.text-orange,
.bg-yellow h1.text-orange,
.bg-yellow h2.text-orange,
.bg-yellow h3.text-orange,
.bg-yellow h4.text-orange,
.bg-yellow h5.text-orange,
.bg-yellow h6.text-orange,
.bg-yellow .h1.text-orange,
.bg-yellow .h2.text-orange,
.bg-yellow .h3.text-orange,
.bg-yellow .h4.text-orange,
.bg-yellow .h5.text-orange,
.bg-yellow .h6.text-orange {
  color: #fd7e14;
}

.bg-yellow p.text-yellow,
.bg-yellow h1.text-yellow,
.bg-yellow h2.text-yellow,
.bg-yellow h3.text-yellow,
.bg-yellow h4.text-yellow,
.bg-yellow h5.text-yellow,
.bg-yellow h6.text-yellow,
.bg-yellow .h1.text-yellow,
.bg-yellow .h2.text-yellow,
.bg-yellow .h3.text-yellow,
.bg-yellow .h4.text-yellow,
.bg-yellow .h5.text-yellow,
.bg-yellow .h6.text-yellow {
  color: #ffc107;
}

.bg-yellow p.text-green,
.bg-yellow h1.text-green,
.bg-yellow h2.text-green,
.bg-yellow h3.text-green,
.bg-yellow h4.text-green,
.bg-yellow h5.text-green,
.bg-yellow h6.text-green,
.bg-yellow .h1.text-green,
.bg-yellow .h2.text-green,
.bg-yellow .h3.text-green,
.bg-yellow .h4.text-green,
.bg-yellow .h5.text-green,
.bg-yellow .h6.text-green {
  color: #28a745;
}

.bg-yellow p.text-teal,
.bg-yellow h1.text-teal,
.bg-yellow h2.text-teal,
.bg-yellow h3.text-teal,
.bg-yellow h4.text-teal,
.bg-yellow h5.text-teal,
.bg-yellow h6.text-teal,
.bg-yellow .h1.text-teal,
.bg-yellow .h2.text-teal,
.bg-yellow .h3.text-teal,
.bg-yellow .h4.text-teal,
.bg-yellow .h5.text-teal,
.bg-yellow .h6.text-teal {
  color: #20c997;
}

.bg-yellow p.text-cyan,
.bg-yellow h1.text-cyan,
.bg-yellow h2.text-cyan,
.bg-yellow h3.text-cyan,
.bg-yellow h4.text-cyan,
.bg-yellow h5.text-cyan,
.bg-yellow h6.text-cyan,
.bg-yellow .h1.text-cyan,
.bg-yellow .h2.text-cyan,
.bg-yellow .h3.text-cyan,
.bg-yellow .h4.text-cyan,
.bg-yellow .h5.text-cyan,
.bg-yellow .h6.text-cyan {
  color: #17a2b8;
}

.bg-yellow p.text-white,
.bg-yellow h1.text-white,
.bg-yellow h2.text-white,
.bg-yellow h3.text-white,
.bg-yellow h4.text-white,
.bg-yellow h5.text-white,
.bg-yellow h6.text-white,
.bg-yellow .h1.text-white,
.bg-yellow .h2.text-white,
.bg-yellow .h3.text-white,
.bg-yellow .h4.text-white,
.bg-yellow .h5.text-white,
.bg-yellow .h6.text-white {
  color: #fff;
}

.bg-yellow p.text-gray,
.bg-yellow h1.text-gray,
.bg-yellow h2.text-gray,
.bg-yellow h3.text-gray,
.bg-yellow h4.text-gray,
.bg-yellow h5.text-gray,
.bg-yellow h6.text-gray,
.bg-yellow .h1.text-gray,
.bg-yellow .h2.text-gray,
.bg-yellow .h3.text-gray,
.bg-yellow .h4.text-gray,
.bg-yellow .h5.text-gray,
.bg-yellow .h6.text-gray {
  color: #6c757d;
}

.bg-yellow p.text-gray-dark,
.bg-yellow h1.text-gray-dark,
.bg-yellow h2.text-gray-dark,
.bg-yellow h3.text-gray-dark,
.bg-yellow h4.text-gray-dark,
.bg-yellow h5.text-gray-dark,
.bg-yellow h6.text-gray-dark,
.bg-yellow .h1.text-gray-dark,
.bg-yellow .h2.text-gray-dark,
.bg-yellow .h3.text-gray-dark,
.bg-yellow .h4.text-gray-dark,
.bg-yellow .h5.text-gray-dark,
.bg-yellow .h6.text-gray-dark {
  color: #343a40;
}

.bg-yellow p.text-bloom,
.bg-yellow h1.text-bloom,
.bg-yellow h2.text-bloom,
.bg-yellow h3.text-bloom,
.bg-yellow h4.text-bloom,
.bg-yellow h5.text-bloom,
.bg-yellow h6.text-bloom,
.bg-yellow .h1.text-bloom,
.bg-yellow .h2.text-bloom,
.bg-yellow .h3.text-bloom,
.bg-yellow .h4.text-bloom,
.bg-yellow .h5.text-bloom,
.bg-yellow .h6.text-bloom {
  color: #ef4056;
}

.bg-yellow p.text-chili,
.bg-yellow h1.text-chili,
.bg-yellow h2.text-chili,
.bg-yellow h3.text-chili,
.bg-yellow h4.text-chili,
.bg-yellow h5.text-chili,
.bg-yellow h6.text-chili,
.bg-yellow .h1.text-chili,
.bg-yellow .h2.text-chili,
.bg-yellow .h3.text-chili,
.bg-yellow .h4.text-chili,
.bg-yellow .h5.text-chili,
.bg-yellow .h6.text-chili {
  color: #8b0015;
}

.bg-yellow p.text-sky,
.bg-yellow h1.text-sky,
.bg-yellow h2.text-sky,
.bg-yellow h3.text-sky,
.bg-yellow h4.text-sky,
.bg-yellow h5.text-sky,
.bg-yellow h6.text-sky,
.bg-yellow .h1.text-sky,
.bg-yellow .h2.text-sky,
.bg-yellow .h3.text-sky,
.bg-yellow .h4.text-sky,
.bg-yellow .h5.text-sky,
.bg-yellow .h6.text-sky {
  color: #81d3eb;
}

.bg-yellow p.text-oasis,
.bg-yellow h1.text-oasis,
.bg-yellow h2.text-oasis,
.bg-yellow h3.text-oasis,
.bg-yellow h4.text-oasis,
.bg-yellow h5.text-oasis,
.bg-yellow h6.text-oasis,
.bg-yellow .h1.text-oasis,
.bg-yellow .h2.text-oasis,
.bg-yellow .h3.text-oasis,
.bg-yellow .h4.text-oasis,
.bg-yellow .h5.text-oasis,
.bg-yellow .h6.text-oasis {
  color: #378dbd;
}

.bg-yellow p.text-azurite,
.bg-yellow h1.text-azurite,
.bg-yellow h2.text-azurite,
.bg-yellow h3.text-azurite,
.bg-yellow h4.text-azurite,
.bg-yellow h5.text-azurite,
.bg-yellow h6.text-azurite,
.bg-yellow .h1.text-azurite,
.bg-yellow .h2.text-azurite,
.bg-yellow .h3.text-azurite,
.bg-yellow .h4.text-azurite,
.bg-yellow .h5.text-azurite,
.bg-yellow .h6.text-azurite {
  color: #1e5288;
}

.bg-yellow p.text-midnight,
.bg-yellow h1.text-midnight,
.bg-yellow h2.text-midnight,
.bg-yellow h3.text-midnight,
.bg-yellow h4.text-midnight,
.bg-yellow h5.text-midnight,
.bg-yellow h6.text-midnight,
.bg-yellow .h1.text-midnight,
.bg-yellow .h2.text-midnight,
.bg-yellow .h3.text-midnight,
.bg-yellow .h4.text-midnight,
.bg-yellow .h5.text-midnight,
.bg-yellow .h6.text-midnight {
  color: #001c48;
}

.bg-yellow p.text-cool-gray,
.bg-yellow h1.text-cool-gray,
.bg-yellow h2.text-cool-gray,
.bg-yellow h3.text-cool-gray,
.bg-yellow h4.text-cool-gray,
.bg-yellow h5.text-cool-gray,
.bg-yellow h6.text-cool-gray,
.bg-yellow .h1.text-cool-gray,
.bg-yellow .h2.text-cool-gray,
.bg-yellow .h3.text-cool-gray,
.bg-yellow .h4.text-cool-gray,
.bg-yellow .h5.text-cool-gray,
.bg-yellow .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-yellow p.text-warm-gray,
.bg-yellow h1.text-warm-gray,
.bg-yellow h2.text-warm-gray,
.bg-yellow h3.text-warm-gray,
.bg-yellow h4.text-warm-gray,
.bg-yellow h5.text-warm-gray,
.bg-yellow h6.text-warm-gray,
.bg-yellow .h1.text-warm-gray,
.bg-yellow .h2.text-warm-gray,
.bg-yellow .h3.text-warm-gray,
.bg-yellow .h4.text-warm-gray,
.bg-yellow .h5.text-warm-gray,
.bg-yellow .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-yellow p.text-leaf,
.bg-yellow h1.text-leaf,
.bg-yellow h2.text-leaf,
.bg-yellow h3.text-leaf,
.bg-yellow h4.text-leaf,
.bg-yellow h5.text-leaf,
.bg-yellow h6.text-leaf,
.bg-yellow .h1.text-leaf,
.bg-yellow .h2.text-leaf,
.bg-yellow .h3.text-leaf,
.bg-yellow .h4.text-leaf,
.bg-yellow .h5.text-leaf,
.bg-yellow .h6.text-leaf {
  color: #70b865;
}

.bg-yellow p.text-river,
.bg-yellow h1.text-river,
.bg-yellow h2.text-river,
.bg-yellow h3.text-river,
.bg-yellow h4.text-river,
.bg-yellow h5.text-river,
.bg-yellow h6.text-river,
.bg-yellow .h1.text-river,
.bg-yellow .h2.text-river,
.bg-yellow .h3.text-river,
.bg-yellow .h4.text-river,
.bg-yellow .h5.text-river,
.bg-yellow .h6.text-river {
  color: #007d84;
}

.bg-yellow p.text-silver,
.bg-yellow h1.text-silver,
.bg-yellow h2.text-silver,
.bg-yellow h3.text-silver,
.bg-yellow h4.text-silver,
.bg-yellow h5.text-silver,
.bg-yellow h6.text-silver,
.bg-yellow .h1.text-silver,
.bg-yellow .h2.text-silver,
.bg-yellow .h3.text-silver,
.bg-yellow .h4.text-silver,
.bg-yellow .h5.text-silver,
.bg-yellow .h6.text-silver {
  color: #9eabae;
}

.bg-yellow p.text-mesa,
.bg-yellow h1.text-mesa,
.bg-yellow h2.text-mesa,
.bg-yellow h3.text-mesa,
.bg-yellow h4.text-mesa,
.bg-yellow h5.text-mesa,
.bg-yellow h6.text-mesa,
.bg-yellow .h1.text-mesa,
.bg-yellow .h2.text-mesa,
.bg-yellow .h3.text-mesa,
.bg-yellow .h4.text-mesa,
.bg-yellow .h5.text-mesa,
.bg-yellow .h6.text-mesa {
  color: #a95c42;
}

.bg-yellow p.text-ash,
.bg-yellow h1.text-ash,
.bg-yellow h2.text-ash,
.bg-yellow h3.text-ash,
.bg-yellow h4.text-ash,
.bg-yellow h5.text-ash,
.bg-yellow h6.text-ash,
.bg-yellow .h1.text-ash,
.bg-yellow .h2.text-ash,
.bg-yellow .h3.text-ash,
.bg-yellow .h4.text-ash,
.bg-yellow .h5.text-ash,
.bg-yellow .h6.text-ash {
  color: #403635;
}

.bg-yellow p.text-sage,
.bg-yellow h1.text-sage,
.bg-yellow h2.text-sage,
.bg-yellow h3.text-sage,
.bg-yellow h4.text-sage,
.bg-yellow h5.text-sage,
.bg-yellow h6.text-sage,
.bg-yellow .h1.text-sage,
.bg-yellow .h2.text-sage,
.bg-yellow .h3.text-sage,
.bg-yellow .h4.text-sage,
.bg-yellow .h5.text-sage,
.bg-yellow .h6.text-sage {
  color: #4a634e;
}

.bg-yellow p.text-black,
.bg-yellow h1.text-black,
.bg-yellow h2.text-black,
.bg-yellow h3.text-black,
.bg-yellow h4.text-black,
.bg-yellow h5.text-black,
.bg-yellow h6.text-black,
.bg-yellow .h1.text-black,
.bg-yellow .h2.text-black,
.bg-yellow .h3.text-black,
.bg-yellow .h4.text-black,
.bg-yellow .h5.text-black,
.bg-yellow .h6.text-black {
  color: #000;
}

.bg-yellow p.text-primary,
.bg-yellow h1.text-primary,
.bg-yellow h2.text-primary,
.bg-yellow h3.text-primary,
.bg-yellow h4.text-primary,
.bg-yellow h5.text-primary,
.bg-yellow h6.text-primary,
.bg-yellow .h1.text-primary,
.bg-yellow .h2.text-primary,
.bg-yellow .h3.text-primary,
.bg-yellow .h4.text-primary,
.bg-yellow .h5.text-primary,
.bg-yellow .h6.text-primary {
  color: #1e5288;
}

.bg-yellow p.text-secondary,
.bg-yellow h1.text-secondary,
.bg-yellow h2.text-secondary,
.bg-yellow h3.text-secondary,
.bg-yellow h4.text-secondary,
.bg-yellow h5.text-secondary,
.bg-yellow h6.text-secondary,
.bg-yellow .h1.text-secondary,
.bg-yellow .h2.text-secondary,
.bg-yellow .h3.text-secondary,
.bg-yellow .h4.text-secondary,
.bg-yellow .h5.text-secondary,
.bg-yellow .h6.text-secondary {
  color: #9eabae;
}

.bg-yellow p.text-success,
.bg-yellow h1.text-success,
.bg-yellow h2.text-success,
.bg-yellow h3.text-success,
.bg-yellow h4.text-success,
.bg-yellow h5.text-success,
.bg-yellow h6.text-success,
.bg-yellow .h1.text-success,
.bg-yellow .h2.text-success,
.bg-yellow .h3.text-success,
.bg-yellow .h4.text-success,
.bg-yellow .h5.text-success,
.bg-yellow .h6.text-success {
  color: #70b865;
}

.bg-yellow p.text-info,
.bg-yellow h1.text-info,
.bg-yellow h2.text-info,
.bg-yellow h3.text-info,
.bg-yellow h4.text-info,
.bg-yellow h5.text-info,
.bg-yellow h6.text-info,
.bg-yellow .h1.text-info,
.bg-yellow .h2.text-info,
.bg-yellow .h3.text-info,
.bg-yellow .h4.text-info,
.bg-yellow .h5.text-info,
.bg-yellow .h6.text-info {
  color: #81d3eb;
}

.bg-yellow p.text-warning,
.bg-yellow h1.text-warning,
.bg-yellow h2.text-warning,
.bg-yellow h3.text-warning,
.bg-yellow h4.text-warning,
.bg-yellow h5.text-warning,
.bg-yellow h6.text-warning,
.bg-yellow .h1.text-warning,
.bg-yellow .h2.text-warning,
.bg-yellow .h3.text-warning,
.bg-yellow .h4.text-warning,
.bg-yellow .h5.text-warning,
.bg-yellow .h6.text-warning {
  color: #f19e1f;
}

.bg-yellow p.text-danger,
.bg-yellow h1.text-danger,
.bg-yellow h2.text-danger,
.bg-yellow h3.text-danger,
.bg-yellow h4.text-danger,
.bg-yellow h5.text-danger,
.bg-yellow h6.text-danger,
.bg-yellow .h1.text-danger,
.bg-yellow .h2.text-danger,
.bg-yellow .h3.text-danger,
.bg-yellow .h4.text-danger,
.bg-yellow .h5.text-danger,
.bg-yellow .h6.text-danger {
  color: #ef4056;
}

.bg-yellow p.text-light,
.bg-yellow h1.text-light,
.bg-yellow h2.text-light,
.bg-yellow h3.text-light,
.bg-yellow h4.text-light,
.bg-yellow h5.text-light,
.bg-yellow h6.text-light,
.bg-yellow .h1.text-light,
.bg-yellow .h2.text-light,
.bg-yellow .h3.text-light,
.bg-yellow .h4.text-light,
.bg-yellow .h5.text-light,
.bg-yellow .h6.text-light {
  color: #dee2e6;
}

.bg-yellow p.text-dark,
.bg-yellow h1.text-dark,
.bg-yellow h2.text-dark,
.bg-yellow h3.text-dark,
.bg-yellow h4.text-dark,
.bg-yellow h5.text-dark,
.bg-yellow h6.text-dark,
.bg-yellow .h1.text-dark,
.bg-yellow .h2.text-dark,
.bg-yellow .h3.text-dark,
.bg-yellow .h4.text-dark,
.bg-yellow .h5.text-dark,
.bg-yellow .h6.text-dark {
  color: #343a40;
}

.bg-yellow p.text-primary-dark,
.bg-yellow h1.text-primary-dark,
.bg-yellow h2.text-primary-dark,
.bg-yellow h3.text-primary-dark,
.bg-yellow h4.text-primary-dark,
.bg-yellow h5.text-primary-dark,
.bg-yellow h6.text-primary-dark,
.bg-yellow .h1.text-primary-dark,
.bg-yellow .h2.text-primary-dark,
.bg-yellow .h3.text-primary-dark,
.bg-yellow .h4.text-primary-dark,
.bg-yellow .h5.text-primary-dark,
.bg-yellow .h6.text-primary-dark {
  color: #0c234b;
}

.bg-yellow p.text-primary-light,
.bg-yellow h1.text-primary-light,
.bg-yellow h2.text-primary-light,
.bg-yellow h3.text-primary-light,
.bg-yellow h4.text-primary-light,
.bg-yellow h5.text-primary-light,
.bg-yellow h6.text-primary-light,
.bg-yellow .h1.text-primary-light,
.bg-yellow .h2.text-primary-light,
.bg-yellow .h3.text-primary-light,
.bg-yellow .h4.text-primary-light,
.bg-yellow .h5.text-primary-light,
.bg-yellow .h6.text-primary-light {
  color: #378dbd;
}

.bg-yellow p.text-accent,
.bg-yellow h1.text-accent,
.bg-yellow h2.text-accent,
.bg-yellow h3.text-accent,
.bg-yellow h4.text-accent,
.bg-yellow h5.text-accent,
.bg-yellow h6.text-accent,
.bg-yellow .h1.text-accent,
.bg-yellow .h2.text-accent,
.bg-yellow .h3.text-accent,
.bg-yellow .h4.text-accent,
.bg-yellow .h5.text-accent,
.bg-yellow .h6.text-accent {
  color: #ab0520;
}

.bg-green {
  color: #fff;
  background-color: #28a745;
  color: #fff;
}

.bg-green p,
.bg-green h1,
.bg-green h2,
.bg-green h3,
.bg-green h4,
.bg-green h5,
.bg-green h6,
.bg-green .h1,
.bg-green .h2,
.bg-green .h3,
.bg-green .h4,
.bg-green .h5,
.bg-green .h6 {
  color: #fff;
}

.bg-green p.text-blue,
.bg-green h1.text-blue,
.bg-green h2.text-blue,
.bg-green h3.text-blue,
.bg-green h4.text-blue,
.bg-green h5.text-blue,
.bg-green h6.text-blue,
.bg-green .h1.text-blue,
.bg-green .h2.text-blue,
.bg-green .h3.text-blue,
.bg-green .h4.text-blue,
.bg-green .h5.text-blue,
.bg-green .h6.text-blue {
  color: #0c234b;
}

.bg-green p.text-indigo,
.bg-green h1.text-indigo,
.bg-green h2.text-indigo,
.bg-green h3.text-indigo,
.bg-green h4.text-indigo,
.bg-green h5.text-indigo,
.bg-green h6.text-indigo,
.bg-green .h1.text-indigo,
.bg-green .h2.text-indigo,
.bg-green .h3.text-indigo,
.bg-green .h4.text-indigo,
.bg-green .h5.text-indigo,
.bg-green .h6.text-indigo {
  color: #6610f2;
}

.bg-green p.text-purple,
.bg-green h1.text-purple,
.bg-green h2.text-purple,
.bg-green h3.text-purple,
.bg-green h4.text-purple,
.bg-green h5.text-purple,
.bg-green h6.text-purple,
.bg-green .h1.text-purple,
.bg-green .h2.text-purple,
.bg-green .h3.text-purple,
.bg-green .h4.text-purple,
.bg-green .h5.text-purple,
.bg-green .h6.text-purple {
  color: #6f42c1;
}

.bg-green p.text-pink,
.bg-green h1.text-pink,
.bg-green h2.text-pink,
.bg-green h3.text-pink,
.bg-green h4.text-pink,
.bg-green h5.text-pink,
.bg-green h6.text-pink,
.bg-green .h1.text-pink,
.bg-green .h2.text-pink,
.bg-green .h3.text-pink,
.bg-green .h4.text-pink,
.bg-green .h5.text-pink,
.bg-green .h6.text-pink {
  color: #e83e8c;
}

.bg-green p.text-red,
.bg-green h1.text-red,
.bg-green h2.text-red,
.bg-green h3.text-red,
.bg-green h4.text-red,
.bg-green h5.text-red,
.bg-green h6.text-red,
.bg-green .h1.text-red,
.bg-green .h2.text-red,
.bg-green .h3.text-red,
.bg-green .h4.text-red,
.bg-green .h5.text-red,
.bg-green .h6.text-red {
  color: #ab0520;
}

.bg-green p.text-orange,
.bg-green h1.text-orange,
.bg-green h2.text-orange,
.bg-green h3.text-orange,
.bg-green h4.text-orange,
.bg-green h5.text-orange,
.bg-green h6.text-orange,
.bg-green .h1.text-orange,
.bg-green .h2.text-orange,
.bg-green .h3.text-orange,
.bg-green .h4.text-orange,
.bg-green .h5.text-orange,
.bg-green .h6.text-orange {
  color: #fd7e14;
}

.bg-green p.text-yellow,
.bg-green h1.text-yellow,
.bg-green h2.text-yellow,
.bg-green h3.text-yellow,
.bg-green h4.text-yellow,
.bg-green h5.text-yellow,
.bg-green h6.text-yellow,
.bg-green .h1.text-yellow,
.bg-green .h2.text-yellow,
.bg-green .h3.text-yellow,
.bg-green .h4.text-yellow,
.bg-green .h5.text-yellow,
.bg-green .h6.text-yellow {
  color: #ffc107;
}

.bg-green p.text-green,
.bg-green h1.text-green,
.bg-green h2.text-green,
.bg-green h3.text-green,
.bg-green h4.text-green,
.bg-green h5.text-green,
.bg-green h6.text-green,
.bg-green .h1.text-green,
.bg-green .h2.text-green,
.bg-green .h3.text-green,
.bg-green .h4.text-green,
.bg-green .h5.text-green,
.bg-green .h6.text-green {
  color: #28a745;
}

.bg-green p.text-teal,
.bg-green h1.text-teal,
.bg-green h2.text-teal,
.bg-green h3.text-teal,
.bg-green h4.text-teal,
.bg-green h5.text-teal,
.bg-green h6.text-teal,
.bg-green .h1.text-teal,
.bg-green .h2.text-teal,
.bg-green .h3.text-teal,
.bg-green .h4.text-teal,
.bg-green .h5.text-teal,
.bg-green .h6.text-teal {
  color: #20c997;
}

.bg-green p.text-cyan,
.bg-green h1.text-cyan,
.bg-green h2.text-cyan,
.bg-green h3.text-cyan,
.bg-green h4.text-cyan,
.bg-green h5.text-cyan,
.bg-green h6.text-cyan,
.bg-green .h1.text-cyan,
.bg-green .h2.text-cyan,
.bg-green .h3.text-cyan,
.bg-green .h4.text-cyan,
.bg-green .h5.text-cyan,
.bg-green .h6.text-cyan {
  color: #17a2b8;
}

.bg-green p.text-white,
.bg-green h1.text-white,
.bg-green h2.text-white,
.bg-green h3.text-white,
.bg-green h4.text-white,
.bg-green h5.text-white,
.bg-green h6.text-white,
.bg-green .h1.text-white,
.bg-green .h2.text-white,
.bg-green .h3.text-white,
.bg-green .h4.text-white,
.bg-green .h5.text-white,
.bg-green .h6.text-white {
  color: #fff;
}

.bg-green p.text-gray,
.bg-green h1.text-gray,
.bg-green h2.text-gray,
.bg-green h3.text-gray,
.bg-green h4.text-gray,
.bg-green h5.text-gray,
.bg-green h6.text-gray,
.bg-green .h1.text-gray,
.bg-green .h2.text-gray,
.bg-green .h3.text-gray,
.bg-green .h4.text-gray,
.bg-green .h5.text-gray,
.bg-green .h6.text-gray {
  color: #6c757d;
}

.bg-green p.text-gray-dark,
.bg-green h1.text-gray-dark,
.bg-green h2.text-gray-dark,
.bg-green h3.text-gray-dark,
.bg-green h4.text-gray-dark,
.bg-green h5.text-gray-dark,
.bg-green h6.text-gray-dark,
.bg-green .h1.text-gray-dark,
.bg-green .h2.text-gray-dark,
.bg-green .h3.text-gray-dark,
.bg-green .h4.text-gray-dark,
.bg-green .h5.text-gray-dark,
.bg-green .h6.text-gray-dark {
  color: #343a40;
}

.bg-green p.text-bloom,
.bg-green h1.text-bloom,
.bg-green h2.text-bloom,
.bg-green h3.text-bloom,
.bg-green h4.text-bloom,
.bg-green h5.text-bloom,
.bg-green h6.text-bloom,
.bg-green .h1.text-bloom,
.bg-green .h2.text-bloom,
.bg-green .h3.text-bloom,
.bg-green .h4.text-bloom,
.bg-green .h5.text-bloom,
.bg-green .h6.text-bloom {
  color: #ef4056;
}

.bg-green p.text-chili,
.bg-green h1.text-chili,
.bg-green h2.text-chili,
.bg-green h3.text-chili,
.bg-green h4.text-chili,
.bg-green h5.text-chili,
.bg-green h6.text-chili,
.bg-green .h1.text-chili,
.bg-green .h2.text-chili,
.bg-green .h3.text-chili,
.bg-green .h4.text-chili,
.bg-green .h5.text-chili,
.bg-green .h6.text-chili {
  color: #8b0015;
}

.bg-green p.text-sky,
.bg-green h1.text-sky,
.bg-green h2.text-sky,
.bg-green h3.text-sky,
.bg-green h4.text-sky,
.bg-green h5.text-sky,
.bg-green h6.text-sky,
.bg-green .h1.text-sky,
.bg-green .h2.text-sky,
.bg-green .h3.text-sky,
.bg-green .h4.text-sky,
.bg-green .h5.text-sky,
.bg-green .h6.text-sky {
  color: #81d3eb;
}

.bg-green p.text-oasis,
.bg-green h1.text-oasis,
.bg-green h2.text-oasis,
.bg-green h3.text-oasis,
.bg-green h4.text-oasis,
.bg-green h5.text-oasis,
.bg-green h6.text-oasis,
.bg-green .h1.text-oasis,
.bg-green .h2.text-oasis,
.bg-green .h3.text-oasis,
.bg-green .h4.text-oasis,
.bg-green .h5.text-oasis,
.bg-green .h6.text-oasis {
  color: #378dbd;
}

.bg-green p.text-azurite,
.bg-green h1.text-azurite,
.bg-green h2.text-azurite,
.bg-green h3.text-azurite,
.bg-green h4.text-azurite,
.bg-green h5.text-azurite,
.bg-green h6.text-azurite,
.bg-green .h1.text-azurite,
.bg-green .h2.text-azurite,
.bg-green .h3.text-azurite,
.bg-green .h4.text-azurite,
.bg-green .h5.text-azurite,
.bg-green .h6.text-azurite {
  color: #1e5288;
}

.bg-green p.text-midnight,
.bg-green h1.text-midnight,
.bg-green h2.text-midnight,
.bg-green h3.text-midnight,
.bg-green h4.text-midnight,
.bg-green h5.text-midnight,
.bg-green h6.text-midnight,
.bg-green .h1.text-midnight,
.bg-green .h2.text-midnight,
.bg-green .h3.text-midnight,
.bg-green .h4.text-midnight,
.bg-green .h5.text-midnight,
.bg-green .h6.text-midnight {
  color: #001c48;
}

.bg-green p.text-cool-gray,
.bg-green h1.text-cool-gray,
.bg-green h2.text-cool-gray,
.bg-green h3.text-cool-gray,
.bg-green h4.text-cool-gray,
.bg-green h5.text-cool-gray,
.bg-green h6.text-cool-gray,
.bg-green .h1.text-cool-gray,
.bg-green .h2.text-cool-gray,
.bg-green .h3.text-cool-gray,
.bg-green .h4.text-cool-gray,
.bg-green .h5.text-cool-gray,
.bg-green .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-green p.text-warm-gray,
.bg-green h1.text-warm-gray,
.bg-green h2.text-warm-gray,
.bg-green h3.text-warm-gray,
.bg-green h4.text-warm-gray,
.bg-green h5.text-warm-gray,
.bg-green h6.text-warm-gray,
.bg-green .h1.text-warm-gray,
.bg-green .h2.text-warm-gray,
.bg-green .h3.text-warm-gray,
.bg-green .h4.text-warm-gray,
.bg-green .h5.text-warm-gray,
.bg-green .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-green p.text-leaf,
.bg-green h1.text-leaf,
.bg-green h2.text-leaf,
.bg-green h3.text-leaf,
.bg-green h4.text-leaf,
.bg-green h5.text-leaf,
.bg-green h6.text-leaf,
.bg-green .h1.text-leaf,
.bg-green .h2.text-leaf,
.bg-green .h3.text-leaf,
.bg-green .h4.text-leaf,
.bg-green .h5.text-leaf,
.bg-green .h6.text-leaf {
  color: #70b865;
}

.bg-green p.text-river,
.bg-green h1.text-river,
.bg-green h2.text-river,
.bg-green h3.text-river,
.bg-green h4.text-river,
.bg-green h5.text-river,
.bg-green h6.text-river,
.bg-green .h1.text-river,
.bg-green .h2.text-river,
.bg-green .h3.text-river,
.bg-green .h4.text-river,
.bg-green .h5.text-river,
.bg-green .h6.text-river {
  color: #007d84;
}

.bg-green p.text-silver,
.bg-green h1.text-silver,
.bg-green h2.text-silver,
.bg-green h3.text-silver,
.bg-green h4.text-silver,
.bg-green h5.text-silver,
.bg-green h6.text-silver,
.bg-green .h1.text-silver,
.bg-green .h2.text-silver,
.bg-green .h3.text-silver,
.bg-green .h4.text-silver,
.bg-green .h5.text-silver,
.bg-green .h6.text-silver {
  color: #9eabae;
}

.bg-green p.text-mesa,
.bg-green h1.text-mesa,
.bg-green h2.text-mesa,
.bg-green h3.text-mesa,
.bg-green h4.text-mesa,
.bg-green h5.text-mesa,
.bg-green h6.text-mesa,
.bg-green .h1.text-mesa,
.bg-green .h2.text-mesa,
.bg-green .h3.text-mesa,
.bg-green .h4.text-mesa,
.bg-green .h5.text-mesa,
.bg-green .h6.text-mesa {
  color: #a95c42;
}

.bg-green p.text-ash,
.bg-green h1.text-ash,
.bg-green h2.text-ash,
.bg-green h3.text-ash,
.bg-green h4.text-ash,
.bg-green h5.text-ash,
.bg-green h6.text-ash,
.bg-green .h1.text-ash,
.bg-green .h2.text-ash,
.bg-green .h3.text-ash,
.bg-green .h4.text-ash,
.bg-green .h5.text-ash,
.bg-green .h6.text-ash {
  color: #403635;
}

.bg-green p.text-sage,
.bg-green h1.text-sage,
.bg-green h2.text-sage,
.bg-green h3.text-sage,
.bg-green h4.text-sage,
.bg-green h5.text-sage,
.bg-green h6.text-sage,
.bg-green .h1.text-sage,
.bg-green .h2.text-sage,
.bg-green .h3.text-sage,
.bg-green .h4.text-sage,
.bg-green .h5.text-sage,
.bg-green .h6.text-sage {
  color: #4a634e;
}

.bg-green p.text-black,
.bg-green h1.text-black,
.bg-green h2.text-black,
.bg-green h3.text-black,
.bg-green h4.text-black,
.bg-green h5.text-black,
.bg-green h6.text-black,
.bg-green .h1.text-black,
.bg-green .h2.text-black,
.bg-green .h3.text-black,
.bg-green .h4.text-black,
.bg-green .h5.text-black,
.bg-green .h6.text-black {
  color: #000;
}

.bg-green p.text-primary,
.bg-green h1.text-primary,
.bg-green h2.text-primary,
.bg-green h3.text-primary,
.bg-green h4.text-primary,
.bg-green h5.text-primary,
.bg-green h6.text-primary,
.bg-green .h1.text-primary,
.bg-green .h2.text-primary,
.bg-green .h3.text-primary,
.bg-green .h4.text-primary,
.bg-green .h5.text-primary,
.bg-green .h6.text-primary {
  color: #1e5288;
}

.bg-green p.text-secondary,
.bg-green h1.text-secondary,
.bg-green h2.text-secondary,
.bg-green h3.text-secondary,
.bg-green h4.text-secondary,
.bg-green h5.text-secondary,
.bg-green h6.text-secondary,
.bg-green .h1.text-secondary,
.bg-green .h2.text-secondary,
.bg-green .h3.text-secondary,
.bg-green .h4.text-secondary,
.bg-green .h5.text-secondary,
.bg-green .h6.text-secondary {
  color: #9eabae;
}

.bg-green p.text-success,
.bg-green h1.text-success,
.bg-green h2.text-success,
.bg-green h3.text-success,
.bg-green h4.text-success,
.bg-green h5.text-success,
.bg-green h6.text-success,
.bg-green .h1.text-success,
.bg-green .h2.text-success,
.bg-green .h3.text-success,
.bg-green .h4.text-success,
.bg-green .h5.text-success,
.bg-green .h6.text-success {
  color: #70b865;
}

.bg-green p.text-info,
.bg-green h1.text-info,
.bg-green h2.text-info,
.bg-green h3.text-info,
.bg-green h4.text-info,
.bg-green h5.text-info,
.bg-green h6.text-info,
.bg-green .h1.text-info,
.bg-green .h2.text-info,
.bg-green .h3.text-info,
.bg-green .h4.text-info,
.bg-green .h5.text-info,
.bg-green .h6.text-info {
  color: #81d3eb;
}

.bg-green p.text-warning,
.bg-green h1.text-warning,
.bg-green h2.text-warning,
.bg-green h3.text-warning,
.bg-green h4.text-warning,
.bg-green h5.text-warning,
.bg-green h6.text-warning,
.bg-green .h1.text-warning,
.bg-green .h2.text-warning,
.bg-green .h3.text-warning,
.bg-green .h4.text-warning,
.bg-green .h5.text-warning,
.bg-green .h6.text-warning {
  color: #f19e1f;
}

.bg-green p.text-danger,
.bg-green h1.text-danger,
.bg-green h2.text-danger,
.bg-green h3.text-danger,
.bg-green h4.text-danger,
.bg-green h5.text-danger,
.bg-green h6.text-danger,
.bg-green .h1.text-danger,
.bg-green .h2.text-danger,
.bg-green .h3.text-danger,
.bg-green .h4.text-danger,
.bg-green .h5.text-danger,
.bg-green .h6.text-danger {
  color: #ef4056;
}

.bg-green p.text-light,
.bg-green h1.text-light,
.bg-green h2.text-light,
.bg-green h3.text-light,
.bg-green h4.text-light,
.bg-green h5.text-light,
.bg-green h6.text-light,
.bg-green .h1.text-light,
.bg-green .h2.text-light,
.bg-green .h3.text-light,
.bg-green .h4.text-light,
.bg-green .h5.text-light,
.bg-green .h6.text-light {
  color: #dee2e6;
}

.bg-green p.text-dark,
.bg-green h1.text-dark,
.bg-green h2.text-dark,
.bg-green h3.text-dark,
.bg-green h4.text-dark,
.bg-green h5.text-dark,
.bg-green h6.text-dark,
.bg-green .h1.text-dark,
.bg-green .h2.text-dark,
.bg-green .h3.text-dark,
.bg-green .h4.text-dark,
.bg-green .h5.text-dark,
.bg-green .h6.text-dark {
  color: #343a40;
}

.bg-green p.text-primary-dark,
.bg-green h1.text-primary-dark,
.bg-green h2.text-primary-dark,
.bg-green h3.text-primary-dark,
.bg-green h4.text-primary-dark,
.bg-green h5.text-primary-dark,
.bg-green h6.text-primary-dark,
.bg-green .h1.text-primary-dark,
.bg-green .h2.text-primary-dark,
.bg-green .h3.text-primary-dark,
.bg-green .h4.text-primary-dark,
.bg-green .h5.text-primary-dark,
.bg-green .h6.text-primary-dark {
  color: #0c234b;
}

.bg-green p.text-primary-light,
.bg-green h1.text-primary-light,
.bg-green h2.text-primary-light,
.bg-green h3.text-primary-light,
.bg-green h4.text-primary-light,
.bg-green h5.text-primary-light,
.bg-green h6.text-primary-light,
.bg-green .h1.text-primary-light,
.bg-green .h2.text-primary-light,
.bg-green .h3.text-primary-light,
.bg-green .h4.text-primary-light,
.bg-green .h5.text-primary-light,
.bg-green .h6.text-primary-light {
  color: #378dbd;
}

.bg-green p.text-accent,
.bg-green h1.text-accent,
.bg-green h2.text-accent,
.bg-green h3.text-accent,
.bg-green h4.text-accent,
.bg-green h5.text-accent,
.bg-green h6.text-accent,
.bg-green .h1.text-accent,
.bg-green .h2.text-accent,
.bg-green .h3.text-accent,
.bg-green .h4.text-accent,
.bg-green .h5.text-accent,
.bg-green .h6.text-accent {
  color: #ab0520;
}

.bg-teal {
  color: #fff;
  background-color: #20c997;
  color: #fff;
}

.bg-teal p,
.bg-teal h1,
.bg-teal h2,
.bg-teal h3,
.bg-teal h4,
.bg-teal h5,
.bg-teal h6,
.bg-teal .h1,
.bg-teal .h2,
.bg-teal .h3,
.bg-teal .h4,
.bg-teal .h5,
.bg-teal .h6 {
  color: #fff;
}

.bg-teal p.text-blue,
.bg-teal h1.text-blue,
.bg-teal h2.text-blue,
.bg-teal h3.text-blue,
.bg-teal h4.text-blue,
.bg-teal h5.text-blue,
.bg-teal h6.text-blue,
.bg-teal .h1.text-blue,
.bg-teal .h2.text-blue,
.bg-teal .h3.text-blue,
.bg-teal .h4.text-blue,
.bg-teal .h5.text-blue,
.bg-teal .h6.text-blue {
  color: #0c234b;
}

.bg-teal p.text-indigo,
.bg-teal h1.text-indigo,
.bg-teal h2.text-indigo,
.bg-teal h3.text-indigo,
.bg-teal h4.text-indigo,
.bg-teal h5.text-indigo,
.bg-teal h6.text-indigo,
.bg-teal .h1.text-indigo,
.bg-teal .h2.text-indigo,
.bg-teal .h3.text-indigo,
.bg-teal .h4.text-indigo,
.bg-teal .h5.text-indigo,
.bg-teal .h6.text-indigo {
  color: #6610f2;
}

.bg-teal p.text-purple,
.bg-teal h1.text-purple,
.bg-teal h2.text-purple,
.bg-teal h3.text-purple,
.bg-teal h4.text-purple,
.bg-teal h5.text-purple,
.bg-teal h6.text-purple,
.bg-teal .h1.text-purple,
.bg-teal .h2.text-purple,
.bg-teal .h3.text-purple,
.bg-teal .h4.text-purple,
.bg-teal .h5.text-purple,
.bg-teal .h6.text-purple {
  color: #6f42c1;
}

.bg-teal p.text-pink,
.bg-teal h1.text-pink,
.bg-teal h2.text-pink,
.bg-teal h3.text-pink,
.bg-teal h4.text-pink,
.bg-teal h5.text-pink,
.bg-teal h6.text-pink,
.bg-teal .h1.text-pink,
.bg-teal .h2.text-pink,
.bg-teal .h3.text-pink,
.bg-teal .h4.text-pink,
.bg-teal .h5.text-pink,
.bg-teal .h6.text-pink {
  color: #e83e8c;
}

.bg-teal p.text-red,
.bg-teal h1.text-red,
.bg-teal h2.text-red,
.bg-teal h3.text-red,
.bg-teal h4.text-red,
.bg-teal h5.text-red,
.bg-teal h6.text-red,
.bg-teal .h1.text-red,
.bg-teal .h2.text-red,
.bg-teal .h3.text-red,
.bg-teal .h4.text-red,
.bg-teal .h5.text-red,
.bg-teal .h6.text-red {
  color: #ab0520;
}

.bg-teal p.text-orange,
.bg-teal h1.text-orange,
.bg-teal h2.text-orange,
.bg-teal h3.text-orange,
.bg-teal h4.text-orange,
.bg-teal h5.text-orange,
.bg-teal h6.text-orange,
.bg-teal .h1.text-orange,
.bg-teal .h2.text-orange,
.bg-teal .h3.text-orange,
.bg-teal .h4.text-orange,
.bg-teal .h5.text-orange,
.bg-teal .h6.text-orange {
  color: #fd7e14;
}

.bg-teal p.text-yellow,
.bg-teal h1.text-yellow,
.bg-teal h2.text-yellow,
.bg-teal h3.text-yellow,
.bg-teal h4.text-yellow,
.bg-teal h5.text-yellow,
.bg-teal h6.text-yellow,
.bg-teal .h1.text-yellow,
.bg-teal .h2.text-yellow,
.bg-teal .h3.text-yellow,
.bg-teal .h4.text-yellow,
.bg-teal .h5.text-yellow,
.bg-teal .h6.text-yellow {
  color: #ffc107;
}

.bg-teal p.text-green,
.bg-teal h1.text-green,
.bg-teal h2.text-green,
.bg-teal h3.text-green,
.bg-teal h4.text-green,
.bg-teal h5.text-green,
.bg-teal h6.text-green,
.bg-teal .h1.text-green,
.bg-teal .h2.text-green,
.bg-teal .h3.text-green,
.bg-teal .h4.text-green,
.bg-teal .h5.text-green,
.bg-teal .h6.text-green {
  color: #28a745;
}

.bg-teal p.text-teal,
.bg-teal h1.text-teal,
.bg-teal h2.text-teal,
.bg-teal h3.text-teal,
.bg-teal h4.text-teal,
.bg-teal h5.text-teal,
.bg-teal h6.text-teal,
.bg-teal .h1.text-teal,
.bg-teal .h2.text-teal,
.bg-teal .h3.text-teal,
.bg-teal .h4.text-teal,
.bg-teal .h5.text-teal,
.bg-teal .h6.text-teal {
  color: #20c997;
}

.bg-teal p.text-cyan,
.bg-teal h1.text-cyan,
.bg-teal h2.text-cyan,
.bg-teal h3.text-cyan,
.bg-teal h4.text-cyan,
.bg-teal h5.text-cyan,
.bg-teal h6.text-cyan,
.bg-teal .h1.text-cyan,
.bg-teal .h2.text-cyan,
.bg-teal .h3.text-cyan,
.bg-teal .h4.text-cyan,
.bg-teal .h5.text-cyan,
.bg-teal .h6.text-cyan {
  color: #17a2b8;
}

.bg-teal p.text-white,
.bg-teal h1.text-white,
.bg-teal h2.text-white,
.bg-teal h3.text-white,
.bg-teal h4.text-white,
.bg-teal h5.text-white,
.bg-teal h6.text-white,
.bg-teal .h1.text-white,
.bg-teal .h2.text-white,
.bg-teal .h3.text-white,
.bg-teal .h4.text-white,
.bg-teal .h5.text-white,
.bg-teal .h6.text-white {
  color: #fff;
}

.bg-teal p.text-gray,
.bg-teal h1.text-gray,
.bg-teal h2.text-gray,
.bg-teal h3.text-gray,
.bg-teal h4.text-gray,
.bg-teal h5.text-gray,
.bg-teal h6.text-gray,
.bg-teal .h1.text-gray,
.bg-teal .h2.text-gray,
.bg-teal .h3.text-gray,
.bg-teal .h4.text-gray,
.bg-teal .h5.text-gray,
.bg-teal .h6.text-gray {
  color: #6c757d;
}

.bg-teal p.text-gray-dark,
.bg-teal h1.text-gray-dark,
.bg-teal h2.text-gray-dark,
.bg-teal h3.text-gray-dark,
.bg-teal h4.text-gray-dark,
.bg-teal h5.text-gray-dark,
.bg-teal h6.text-gray-dark,
.bg-teal .h1.text-gray-dark,
.bg-teal .h2.text-gray-dark,
.bg-teal .h3.text-gray-dark,
.bg-teal .h4.text-gray-dark,
.bg-teal .h5.text-gray-dark,
.bg-teal .h6.text-gray-dark {
  color: #343a40;
}

.bg-teal p.text-bloom,
.bg-teal h1.text-bloom,
.bg-teal h2.text-bloom,
.bg-teal h3.text-bloom,
.bg-teal h4.text-bloom,
.bg-teal h5.text-bloom,
.bg-teal h6.text-bloom,
.bg-teal .h1.text-bloom,
.bg-teal .h2.text-bloom,
.bg-teal .h3.text-bloom,
.bg-teal .h4.text-bloom,
.bg-teal .h5.text-bloom,
.bg-teal .h6.text-bloom {
  color: #ef4056;
}

.bg-teal p.text-chili,
.bg-teal h1.text-chili,
.bg-teal h2.text-chili,
.bg-teal h3.text-chili,
.bg-teal h4.text-chili,
.bg-teal h5.text-chili,
.bg-teal h6.text-chili,
.bg-teal .h1.text-chili,
.bg-teal .h2.text-chili,
.bg-teal .h3.text-chili,
.bg-teal .h4.text-chili,
.bg-teal .h5.text-chili,
.bg-teal .h6.text-chili {
  color: #8b0015;
}

.bg-teal p.text-sky,
.bg-teal h1.text-sky,
.bg-teal h2.text-sky,
.bg-teal h3.text-sky,
.bg-teal h4.text-sky,
.bg-teal h5.text-sky,
.bg-teal h6.text-sky,
.bg-teal .h1.text-sky,
.bg-teal .h2.text-sky,
.bg-teal .h3.text-sky,
.bg-teal .h4.text-sky,
.bg-teal .h5.text-sky,
.bg-teal .h6.text-sky {
  color: #81d3eb;
}

.bg-teal p.text-oasis,
.bg-teal h1.text-oasis,
.bg-teal h2.text-oasis,
.bg-teal h3.text-oasis,
.bg-teal h4.text-oasis,
.bg-teal h5.text-oasis,
.bg-teal h6.text-oasis,
.bg-teal .h1.text-oasis,
.bg-teal .h2.text-oasis,
.bg-teal .h3.text-oasis,
.bg-teal .h4.text-oasis,
.bg-teal .h5.text-oasis,
.bg-teal .h6.text-oasis {
  color: #378dbd;
}

.bg-teal p.text-azurite,
.bg-teal h1.text-azurite,
.bg-teal h2.text-azurite,
.bg-teal h3.text-azurite,
.bg-teal h4.text-azurite,
.bg-teal h5.text-azurite,
.bg-teal h6.text-azurite,
.bg-teal .h1.text-azurite,
.bg-teal .h2.text-azurite,
.bg-teal .h3.text-azurite,
.bg-teal .h4.text-azurite,
.bg-teal .h5.text-azurite,
.bg-teal .h6.text-azurite {
  color: #1e5288;
}

.bg-teal p.text-midnight,
.bg-teal h1.text-midnight,
.bg-teal h2.text-midnight,
.bg-teal h3.text-midnight,
.bg-teal h4.text-midnight,
.bg-teal h5.text-midnight,
.bg-teal h6.text-midnight,
.bg-teal .h1.text-midnight,
.bg-teal .h2.text-midnight,
.bg-teal .h3.text-midnight,
.bg-teal .h4.text-midnight,
.bg-teal .h5.text-midnight,
.bg-teal .h6.text-midnight {
  color: #001c48;
}

.bg-teal p.text-cool-gray,
.bg-teal h1.text-cool-gray,
.bg-teal h2.text-cool-gray,
.bg-teal h3.text-cool-gray,
.bg-teal h4.text-cool-gray,
.bg-teal h5.text-cool-gray,
.bg-teal h6.text-cool-gray,
.bg-teal .h1.text-cool-gray,
.bg-teal .h2.text-cool-gray,
.bg-teal .h3.text-cool-gray,
.bg-teal .h4.text-cool-gray,
.bg-teal .h5.text-cool-gray,
.bg-teal .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-teal p.text-warm-gray,
.bg-teal h1.text-warm-gray,
.bg-teal h2.text-warm-gray,
.bg-teal h3.text-warm-gray,
.bg-teal h4.text-warm-gray,
.bg-teal h5.text-warm-gray,
.bg-teal h6.text-warm-gray,
.bg-teal .h1.text-warm-gray,
.bg-teal .h2.text-warm-gray,
.bg-teal .h3.text-warm-gray,
.bg-teal .h4.text-warm-gray,
.bg-teal .h5.text-warm-gray,
.bg-teal .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-teal p.text-leaf,
.bg-teal h1.text-leaf,
.bg-teal h2.text-leaf,
.bg-teal h3.text-leaf,
.bg-teal h4.text-leaf,
.bg-teal h5.text-leaf,
.bg-teal h6.text-leaf,
.bg-teal .h1.text-leaf,
.bg-teal .h2.text-leaf,
.bg-teal .h3.text-leaf,
.bg-teal .h4.text-leaf,
.bg-teal .h5.text-leaf,
.bg-teal .h6.text-leaf {
  color: #70b865;
}

.bg-teal p.text-river,
.bg-teal h1.text-river,
.bg-teal h2.text-river,
.bg-teal h3.text-river,
.bg-teal h4.text-river,
.bg-teal h5.text-river,
.bg-teal h6.text-river,
.bg-teal .h1.text-river,
.bg-teal .h2.text-river,
.bg-teal .h3.text-river,
.bg-teal .h4.text-river,
.bg-teal .h5.text-river,
.bg-teal .h6.text-river {
  color: #007d84;
}

.bg-teal p.text-silver,
.bg-teal h1.text-silver,
.bg-teal h2.text-silver,
.bg-teal h3.text-silver,
.bg-teal h4.text-silver,
.bg-teal h5.text-silver,
.bg-teal h6.text-silver,
.bg-teal .h1.text-silver,
.bg-teal .h2.text-silver,
.bg-teal .h3.text-silver,
.bg-teal .h4.text-silver,
.bg-teal .h5.text-silver,
.bg-teal .h6.text-silver {
  color: #9eabae;
}

.bg-teal p.text-mesa,
.bg-teal h1.text-mesa,
.bg-teal h2.text-mesa,
.bg-teal h3.text-mesa,
.bg-teal h4.text-mesa,
.bg-teal h5.text-mesa,
.bg-teal h6.text-mesa,
.bg-teal .h1.text-mesa,
.bg-teal .h2.text-mesa,
.bg-teal .h3.text-mesa,
.bg-teal .h4.text-mesa,
.bg-teal .h5.text-mesa,
.bg-teal .h6.text-mesa {
  color: #a95c42;
}

.bg-teal p.text-ash,
.bg-teal h1.text-ash,
.bg-teal h2.text-ash,
.bg-teal h3.text-ash,
.bg-teal h4.text-ash,
.bg-teal h5.text-ash,
.bg-teal h6.text-ash,
.bg-teal .h1.text-ash,
.bg-teal .h2.text-ash,
.bg-teal .h3.text-ash,
.bg-teal .h4.text-ash,
.bg-teal .h5.text-ash,
.bg-teal .h6.text-ash {
  color: #403635;
}

.bg-teal p.text-sage,
.bg-teal h1.text-sage,
.bg-teal h2.text-sage,
.bg-teal h3.text-sage,
.bg-teal h4.text-sage,
.bg-teal h5.text-sage,
.bg-teal h6.text-sage,
.bg-teal .h1.text-sage,
.bg-teal .h2.text-sage,
.bg-teal .h3.text-sage,
.bg-teal .h4.text-sage,
.bg-teal .h5.text-sage,
.bg-teal .h6.text-sage {
  color: #4a634e;
}

.bg-teal p.text-black,
.bg-teal h1.text-black,
.bg-teal h2.text-black,
.bg-teal h3.text-black,
.bg-teal h4.text-black,
.bg-teal h5.text-black,
.bg-teal h6.text-black,
.bg-teal .h1.text-black,
.bg-teal .h2.text-black,
.bg-teal .h3.text-black,
.bg-teal .h4.text-black,
.bg-teal .h5.text-black,
.bg-teal .h6.text-black {
  color: #000;
}

.bg-teal p.text-primary,
.bg-teal h1.text-primary,
.bg-teal h2.text-primary,
.bg-teal h3.text-primary,
.bg-teal h4.text-primary,
.bg-teal h5.text-primary,
.bg-teal h6.text-primary,
.bg-teal .h1.text-primary,
.bg-teal .h2.text-primary,
.bg-teal .h3.text-primary,
.bg-teal .h4.text-primary,
.bg-teal .h5.text-primary,
.bg-teal .h6.text-primary {
  color: #1e5288;
}

.bg-teal p.text-secondary,
.bg-teal h1.text-secondary,
.bg-teal h2.text-secondary,
.bg-teal h3.text-secondary,
.bg-teal h4.text-secondary,
.bg-teal h5.text-secondary,
.bg-teal h6.text-secondary,
.bg-teal .h1.text-secondary,
.bg-teal .h2.text-secondary,
.bg-teal .h3.text-secondary,
.bg-teal .h4.text-secondary,
.bg-teal .h5.text-secondary,
.bg-teal .h6.text-secondary {
  color: #9eabae;
}

.bg-teal p.text-success,
.bg-teal h1.text-success,
.bg-teal h2.text-success,
.bg-teal h3.text-success,
.bg-teal h4.text-success,
.bg-teal h5.text-success,
.bg-teal h6.text-success,
.bg-teal .h1.text-success,
.bg-teal .h2.text-success,
.bg-teal .h3.text-success,
.bg-teal .h4.text-success,
.bg-teal .h5.text-success,
.bg-teal .h6.text-success {
  color: #70b865;
}

.bg-teal p.text-info,
.bg-teal h1.text-info,
.bg-teal h2.text-info,
.bg-teal h3.text-info,
.bg-teal h4.text-info,
.bg-teal h5.text-info,
.bg-teal h6.text-info,
.bg-teal .h1.text-info,
.bg-teal .h2.text-info,
.bg-teal .h3.text-info,
.bg-teal .h4.text-info,
.bg-teal .h5.text-info,
.bg-teal .h6.text-info {
  color: #81d3eb;
}

.bg-teal p.text-warning,
.bg-teal h1.text-warning,
.bg-teal h2.text-warning,
.bg-teal h3.text-warning,
.bg-teal h4.text-warning,
.bg-teal h5.text-warning,
.bg-teal h6.text-warning,
.bg-teal .h1.text-warning,
.bg-teal .h2.text-warning,
.bg-teal .h3.text-warning,
.bg-teal .h4.text-warning,
.bg-teal .h5.text-warning,
.bg-teal .h6.text-warning {
  color: #f19e1f;
}

.bg-teal p.text-danger,
.bg-teal h1.text-danger,
.bg-teal h2.text-danger,
.bg-teal h3.text-danger,
.bg-teal h4.text-danger,
.bg-teal h5.text-danger,
.bg-teal h6.text-danger,
.bg-teal .h1.text-danger,
.bg-teal .h2.text-danger,
.bg-teal .h3.text-danger,
.bg-teal .h4.text-danger,
.bg-teal .h5.text-danger,
.bg-teal .h6.text-danger {
  color: #ef4056;
}

.bg-teal p.text-light,
.bg-teal h1.text-light,
.bg-teal h2.text-light,
.bg-teal h3.text-light,
.bg-teal h4.text-light,
.bg-teal h5.text-light,
.bg-teal h6.text-light,
.bg-teal .h1.text-light,
.bg-teal .h2.text-light,
.bg-teal .h3.text-light,
.bg-teal .h4.text-light,
.bg-teal .h5.text-light,
.bg-teal .h6.text-light {
  color: #dee2e6;
}

.bg-teal p.text-dark,
.bg-teal h1.text-dark,
.bg-teal h2.text-dark,
.bg-teal h3.text-dark,
.bg-teal h4.text-dark,
.bg-teal h5.text-dark,
.bg-teal h6.text-dark,
.bg-teal .h1.text-dark,
.bg-teal .h2.text-dark,
.bg-teal .h3.text-dark,
.bg-teal .h4.text-dark,
.bg-teal .h5.text-dark,
.bg-teal .h6.text-dark {
  color: #343a40;
}

.bg-teal p.text-primary-dark,
.bg-teal h1.text-primary-dark,
.bg-teal h2.text-primary-dark,
.bg-teal h3.text-primary-dark,
.bg-teal h4.text-primary-dark,
.bg-teal h5.text-primary-dark,
.bg-teal h6.text-primary-dark,
.bg-teal .h1.text-primary-dark,
.bg-teal .h2.text-primary-dark,
.bg-teal .h3.text-primary-dark,
.bg-teal .h4.text-primary-dark,
.bg-teal .h5.text-primary-dark,
.bg-teal .h6.text-primary-dark {
  color: #0c234b;
}

.bg-teal p.text-primary-light,
.bg-teal h1.text-primary-light,
.bg-teal h2.text-primary-light,
.bg-teal h3.text-primary-light,
.bg-teal h4.text-primary-light,
.bg-teal h5.text-primary-light,
.bg-teal h6.text-primary-light,
.bg-teal .h1.text-primary-light,
.bg-teal .h2.text-primary-light,
.bg-teal .h3.text-primary-light,
.bg-teal .h4.text-primary-light,
.bg-teal .h5.text-primary-light,
.bg-teal .h6.text-primary-light {
  color: #378dbd;
}

.bg-teal p.text-accent,
.bg-teal h1.text-accent,
.bg-teal h2.text-accent,
.bg-teal h3.text-accent,
.bg-teal h4.text-accent,
.bg-teal h5.text-accent,
.bg-teal h6.text-accent,
.bg-teal .h1.text-accent,
.bg-teal .h2.text-accent,
.bg-teal .h3.text-accent,
.bg-teal .h4.text-accent,
.bg-teal .h5.text-accent,
.bg-teal .h6.text-accent {
  color: #ab0520;
}

.bg-cyan {
  color: #fff;
  background-color: #17a2b8;
  color: #fff;
}

.bg-cyan p,
.bg-cyan h1,
.bg-cyan h2,
.bg-cyan h3,
.bg-cyan h4,
.bg-cyan h5,
.bg-cyan h6,
.bg-cyan .h1,
.bg-cyan .h2,
.bg-cyan .h3,
.bg-cyan .h4,
.bg-cyan .h5,
.bg-cyan .h6 {
  color: #fff;
}

.bg-cyan p.text-blue,
.bg-cyan h1.text-blue,
.bg-cyan h2.text-blue,
.bg-cyan h3.text-blue,
.bg-cyan h4.text-blue,
.bg-cyan h5.text-blue,
.bg-cyan h6.text-blue,
.bg-cyan .h1.text-blue,
.bg-cyan .h2.text-blue,
.bg-cyan .h3.text-blue,
.bg-cyan .h4.text-blue,
.bg-cyan .h5.text-blue,
.bg-cyan .h6.text-blue {
  color: #0c234b;
}

.bg-cyan p.text-indigo,
.bg-cyan h1.text-indigo,
.bg-cyan h2.text-indigo,
.bg-cyan h3.text-indigo,
.bg-cyan h4.text-indigo,
.bg-cyan h5.text-indigo,
.bg-cyan h6.text-indigo,
.bg-cyan .h1.text-indigo,
.bg-cyan .h2.text-indigo,
.bg-cyan .h3.text-indigo,
.bg-cyan .h4.text-indigo,
.bg-cyan .h5.text-indigo,
.bg-cyan .h6.text-indigo {
  color: #6610f2;
}

.bg-cyan p.text-purple,
.bg-cyan h1.text-purple,
.bg-cyan h2.text-purple,
.bg-cyan h3.text-purple,
.bg-cyan h4.text-purple,
.bg-cyan h5.text-purple,
.bg-cyan h6.text-purple,
.bg-cyan .h1.text-purple,
.bg-cyan .h2.text-purple,
.bg-cyan .h3.text-purple,
.bg-cyan .h4.text-purple,
.bg-cyan .h5.text-purple,
.bg-cyan .h6.text-purple {
  color: #6f42c1;
}

.bg-cyan p.text-pink,
.bg-cyan h1.text-pink,
.bg-cyan h2.text-pink,
.bg-cyan h3.text-pink,
.bg-cyan h4.text-pink,
.bg-cyan h5.text-pink,
.bg-cyan h6.text-pink,
.bg-cyan .h1.text-pink,
.bg-cyan .h2.text-pink,
.bg-cyan .h3.text-pink,
.bg-cyan .h4.text-pink,
.bg-cyan .h5.text-pink,
.bg-cyan .h6.text-pink {
  color: #e83e8c;
}

.bg-cyan p.text-red,
.bg-cyan h1.text-red,
.bg-cyan h2.text-red,
.bg-cyan h3.text-red,
.bg-cyan h4.text-red,
.bg-cyan h5.text-red,
.bg-cyan h6.text-red,
.bg-cyan .h1.text-red,
.bg-cyan .h2.text-red,
.bg-cyan .h3.text-red,
.bg-cyan .h4.text-red,
.bg-cyan .h5.text-red,
.bg-cyan .h6.text-red {
  color: #ab0520;
}

.bg-cyan p.text-orange,
.bg-cyan h1.text-orange,
.bg-cyan h2.text-orange,
.bg-cyan h3.text-orange,
.bg-cyan h4.text-orange,
.bg-cyan h5.text-orange,
.bg-cyan h6.text-orange,
.bg-cyan .h1.text-orange,
.bg-cyan .h2.text-orange,
.bg-cyan .h3.text-orange,
.bg-cyan .h4.text-orange,
.bg-cyan .h5.text-orange,
.bg-cyan .h6.text-orange {
  color: #fd7e14;
}

.bg-cyan p.text-yellow,
.bg-cyan h1.text-yellow,
.bg-cyan h2.text-yellow,
.bg-cyan h3.text-yellow,
.bg-cyan h4.text-yellow,
.bg-cyan h5.text-yellow,
.bg-cyan h6.text-yellow,
.bg-cyan .h1.text-yellow,
.bg-cyan .h2.text-yellow,
.bg-cyan .h3.text-yellow,
.bg-cyan .h4.text-yellow,
.bg-cyan .h5.text-yellow,
.bg-cyan .h6.text-yellow {
  color: #ffc107;
}

.bg-cyan p.text-green,
.bg-cyan h1.text-green,
.bg-cyan h2.text-green,
.bg-cyan h3.text-green,
.bg-cyan h4.text-green,
.bg-cyan h5.text-green,
.bg-cyan h6.text-green,
.bg-cyan .h1.text-green,
.bg-cyan .h2.text-green,
.bg-cyan .h3.text-green,
.bg-cyan .h4.text-green,
.bg-cyan .h5.text-green,
.bg-cyan .h6.text-green {
  color: #28a745;
}

.bg-cyan p.text-teal,
.bg-cyan h1.text-teal,
.bg-cyan h2.text-teal,
.bg-cyan h3.text-teal,
.bg-cyan h4.text-teal,
.bg-cyan h5.text-teal,
.bg-cyan h6.text-teal,
.bg-cyan .h1.text-teal,
.bg-cyan .h2.text-teal,
.bg-cyan .h3.text-teal,
.bg-cyan .h4.text-teal,
.bg-cyan .h5.text-teal,
.bg-cyan .h6.text-teal {
  color: #20c997;
}

.bg-cyan p.text-cyan,
.bg-cyan h1.text-cyan,
.bg-cyan h2.text-cyan,
.bg-cyan h3.text-cyan,
.bg-cyan h4.text-cyan,
.bg-cyan h5.text-cyan,
.bg-cyan h6.text-cyan,
.bg-cyan .h1.text-cyan,
.bg-cyan .h2.text-cyan,
.bg-cyan .h3.text-cyan,
.bg-cyan .h4.text-cyan,
.bg-cyan .h5.text-cyan,
.bg-cyan .h6.text-cyan {
  color: #17a2b8;
}

.bg-cyan p.text-white,
.bg-cyan h1.text-white,
.bg-cyan h2.text-white,
.bg-cyan h3.text-white,
.bg-cyan h4.text-white,
.bg-cyan h5.text-white,
.bg-cyan h6.text-white,
.bg-cyan .h1.text-white,
.bg-cyan .h2.text-white,
.bg-cyan .h3.text-white,
.bg-cyan .h4.text-white,
.bg-cyan .h5.text-white,
.bg-cyan .h6.text-white {
  color: #fff;
}

.bg-cyan p.text-gray,
.bg-cyan h1.text-gray,
.bg-cyan h2.text-gray,
.bg-cyan h3.text-gray,
.bg-cyan h4.text-gray,
.bg-cyan h5.text-gray,
.bg-cyan h6.text-gray,
.bg-cyan .h1.text-gray,
.bg-cyan .h2.text-gray,
.bg-cyan .h3.text-gray,
.bg-cyan .h4.text-gray,
.bg-cyan .h5.text-gray,
.bg-cyan .h6.text-gray {
  color: #6c757d;
}

.bg-cyan p.text-gray-dark,
.bg-cyan h1.text-gray-dark,
.bg-cyan h2.text-gray-dark,
.bg-cyan h3.text-gray-dark,
.bg-cyan h4.text-gray-dark,
.bg-cyan h5.text-gray-dark,
.bg-cyan h6.text-gray-dark,
.bg-cyan .h1.text-gray-dark,
.bg-cyan .h2.text-gray-dark,
.bg-cyan .h3.text-gray-dark,
.bg-cyan .h4.text-gray-dark,
.bg-cyan .h5.text-gray-dark,
.bg-cyan .h6.text-gray-dark {
  color: #343a40;
}

.bg-cyan p.text-bloom,
.bg-cyan h1.text-bloom,
.bg-cyan h2.text-bloom,
.bg-cyan h3.text-bloom,
.bg-cyan h4.text-bloom,
.bg-cyan h5.text-bloom,
.bg-cyan h6.text-bloom,
.bg-cyan .h1.text-bloom,
.bg-cyan .h2.text-bloom,
.bg-cyan .h3.text-bloom,
.bg-cyan .h4.text-bloom,
.bg-cyan .h5.text-bloom,
.bg-cyan .h6.text-bloom {
  color: #ef4056;
}

.bg-cyan p.text-chili,
.bg-cyan h1.text-chili,
.bg-cyan h2.text-chili,
.bg-cyan h3.text-chili,
.bg-cyan h4.text-chili,
.bg-cyan h5.text-chili,
.bg-cyan h6.text-chili,
.bg-cyan .h1.text-chili,
.bg-cyan .h2.text-chili,
.bg-cyan .h3.text-chili,
.bg-cyan .h4.text-chili,
.bg-cyan .h5.text-chili,
.bg-cyan .h6.text-chili {
  color: #8b0015;
}

.bg-cyan p.text-sky,
.bg-cyan h1.text-sky,
.bg-cyan h2.text-sky,
.bg-cyan h3.text-sky,
.bg-cyan h4.text-sky,
.bg-cyan h5.text-sky,
.bg-cyan h6.text-sky,
.bg-cyan .h1.text-sky,
.bg-cyan .h2.text-sky,
.bg-cyan .h3.text-sky,
.bg-cyan .h4.text-sky,
.bg-cyan .h5.text-sky,
.bg-cyan .h6.text-sky {
  color: #81d3eb;
}

.bg-cyan p.text-oasis,
.bg-cyan h1.text-oasis,
.bg-cyan h2.text-oasis,
.bg-cyan h3.text-oasis,
.bg-cyan h4.text-oasis,
.bg-cyan h5.text-oasis,
.bg-cyan h6.text-oasis,
.bg-cyan .h1.text-oasis,
.bg-cyan .h2.text-oasis,
.bg-cyan .h3.text-oasis,
.bg-cyan .h4.text-oasis,
.bg-cyan .h5.text-oasis,
.bg-cyan .h6.text-oasis {
  color: #378dbd;
}

.bg-cyan p.text-azurite,
.bg-cyan h1.text-azurite,
.bg-cyan h2.text-azurite,
.bg-cyan h3.text-azurite,
.bg-cyan h4.text-azurite,
.bg-cyan h5.text-azurite,
.bg-cyan h6.text-azurite,
.bg-cyan .h1.text-azurite,
.bg-cyan .h2.text-azurite,
.bg-cyan .h3.text-azurite,
.bg-cyan .h4.text-azurite,
.bg-cyan .h5.text-azurite,
.bg-cyan .h6.text-azurite {
  color: #1e5288;
}

.bg-cyan p.text-midnight,
.bg-cyan h1.text-midnight,
.bg-cyan h2.text-midnight,
.bg-cyan h3.text-midnight,
.bg-cyan h4.text-midnight,
.bg-cyan h5.text-midnight,
.bg-cyan h6.text-midnight,
.bg-cyan .h1.text-midnight,
.bg-cyan .h2.text-midnight,
.bg-cyan .h3.text-midnight,
.bg-cyan .h4.text-midnight,
.bg-cyan .h5.text-midnight,
.bg-cyan .h6.text-midnight {
  color: #001c48;
}

.bg-cyan p.text-cool-gray,
.bg-cyan h1.text-cool-gray,
.bg-cyan h2.text-cool-gray,
.bg-cyan h3.text-cool-gray,
.bg-cyan h4.text-cool-gray,
.bg-cyan h5.text-cool-gray,
.bg-cyan h6.text-cool-gray,
.bg-cyan .h1.text-cool-gray,
.bg-cyan .h2.text-cool-gray,
.bg-cyan .h3.text-cool-gray,
.bg-cyan .h4.text-cool-gray,
.bg-cyan .h5.text-cool-gray,
.bg-cyan .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-cyan p.text-warm-gray,
.bg-cyan h1.text-warm-gray,
.bg-cyan h2.text-warm-gray,
.bg-cyan h3.text-warm-gray,
.bg-cyan h4.text-warm-gray,
.bg-cyan h5.text-warm-gray,
.bg-cyan h6.text-warm-gray,
.bg-cyan .h1.text-warm-gray,
.bg-cyan .h2.text-warm-gray,
.bg-cyan .h3.text-warm-gray,
.bg-cyan .h4.text-warm-gray,
.bg-cyan .h5.text-warm-gray,
.bg-cyan .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-cyan p.text-leaf,
.bg-cyan h1.text-leaf,
.bg-cyan h2.text-leaf,
.bg-cyan h3.text-leaf,
.bg-cyan h4.text-leaf,
.bg-cyan h5.text-leaf,
.bg-cyan h6.text-leaf,
.bg-cyan .h1.text-leaf,
.bg-cyan .h2.text-leaf,
.bg-cyan .h3.text-leaf,
.bg-cyan .h4.text-leaf,
.bg-cyan .h5.text-leaf,
.bg-cyan .h6.text-leaf {
  color: #70b865;
}

.bg-cyan p.text-river,
.bg-cyan h1.text-river,
.bg-cyan h2.text-river,
.bg-cyan h3.text-river,
.bg-cyan h4.text-river,
.bg-cyan h5.text-river,
.bg-cyan h6.text-river,
.bg-cyan .h1.text-river,
.bg-cyan .h2.text-river,
.bg-cyan .h3.text-river,
.bg-cyan .h4.text-river,
.bg-cyan .h5.text-river,
.bg-cyan .h6.text-river {
  color: #007d84;
}

.bg-cyan p.text-silver,
.bg-cyan h1.text-silver,
.bg-cyan h2.text-silver,
.bg-cyan h3.text-silver,
.bg-cyan h4.text-silver,
.bg-cyan h5.text-silver,
.bg-cyan h6.text-silver,
.bg-cyan .h1.text-silver,
.bg-cyan .h2.text-silver,
.bg-cyan .h3.text-silver,
.bg-cyan .h4.text-silver,
.bg-cyan .h5.text-silver,
.bg-cyan .h6.text-silver {
  color: #9eabae;
}

.bg-cyan p.text-mesa,
.bg-cyan h1.text-mesa,
.bg-cyan h2.text-mesa,
.bg-cyan h3.text-mesa,
.bg-cyan h4.text-mesa,
.bg-cyan h5.text-mesa,
.bg-cyan h6.text-mesa,
.bg-cyan .h1.text-mesa,
.bg-cyan .h2.text-mesa,
.bg-cyan .h3.text-mesa,
.bg-cyan .h4.text-mesa,
.bg-cyan .h5.text-mesa,
.bg-cyan .h6.text-mesa {
  color: #a95c42;
}

.bg-cyan p.text-ash,
.bg-cyan h1.text-ash,
.bg-cyan h2.text-ash,
.bg-cyan h3.text-ash,
.bg-cyan h4.text-ash,
.bg-cyan h5.text-ash,
.bg-cyan h6.text-ash,
.bg-cyan .h1.text-ash,
.bg-cyan .h2.text-ash,
.bg-cyan .h3.text-ash,
.bg-cyan .h4.text-ash,
.bg-cyan .h5.text-ash,
.bg-cyan .h6.text-ash {
  color: #403635;
}

.bg-cyan p.text-sage,
.bg-cyan h1.text-sage,
.bg-cyan h2.text-sage,
.bg-cyan h3.text-sage,
.bg-cyan h4.text-sage,
.bg-cyan h5.text-sage,
.bg-cyan h6.text-sage,
.bg-cyan .h1.text-sage,
.bg-cyan .h2.text-sage,
.bg-cyan .h3.text-sage,
.bg-cyan .h4.text-sage,
.bg-cyan .h5.text-sage,
.bg-cyan .h6.text-sage {
  color: #4a634e;
}

.bg-cyan p.text-black,
.bg-cyan h1.text-black,
.bg-cyan h2.text-black,
.bg-cyan h3.text-black,
.bg-cyan h4.text-black,
.bg-cyan h5.text-black,
.bg-cyan h6.text-black,
.bg-cyan .h1.text-black,
.bg-cyan .h2.text-black,
.bg-cyan .h3.text-black,
.bg-cyan .h4.text-black,
.bg-cyan .h5.text-black,
.bg-cyan .h6.text-black {
  color: #000;
}

.bg-cyan p.text-primary,
.bg-cyan h1.text-primary,
.bg-cyan h2.text-primary,
.bg-cyan h3.text-primary,
.bg-cyan h4.text-primary,
.bg-cyan h5.text-primary,
.bg-cyan h6.text-primary,
.bg-cyan .h1.text-primary,
.bg-cyan .h2.text-primary,
.bg-cyan .h3.text-primary,
.bg-cyan .h4.text-primary,
.bg-cyan .h5.text-primary,
.bg-cyan .h6.text-primary {
  color: #1e5288;
}

.bg-cyan p.text-secondary,
.bg-cyan h1.text-secondary,
.bg-cyan h2.text-secondary,
.bg-cyan h3.text-secondary,
.bg-cyan h4.text-secondary,
.bg-cyan h5.text-secondary,
.bg-cyan h6.text-secondary,
.bg-cyan .h1.text-secondary,
.bg-cyan .h2.text-secondary,
.bg-cyan .h3.text-secondary,
.bg-cyan .h4.text-secondary,
.bg-cyan .h5.text-secondary,
.bg-cyan .h6.text-secondary {
  color: #9eabae;
}

.bg-cyan p.text-success,
.bg-cyan h1.text-success,
.bg-cyan h2.text-success,
.bg-cyan h3.text-success,
.bg-cyan h4.text-success,
.bg-cyan h5.text-success,
.bg-cyan h6.text-success,
.bg-cyan .h1.text-success,
.bg-cyan .h2.text-success,
.bg-cyan .h3.text-success,
.bg-cyan .h4.text-success,
.bg-cyan .h5.text-success,
.bg-cyan .h6.text-success {
  color: #70b865;
}

.bg-cyan p.text-info,
.bg-cyan h1.text-info,
.bg-cyan h2.text-info,
.bg-cyan h3.text-info,
.bg-cyan h4.text-info,
.bg-cyan h5.text-info,
.bg-cyan h6.text-info,
.bg-cyan .h1.text-info,
.bg-cyan .h2.text-info,
.bg-cyan .h3.text-info,
.bg-cyan .h4.text-info,
.bg-cyan .h5.text-info,
.bg-cyan .h6.text-info {
  color: #81d3eb;
}

.bg-cyan p.text-warning,
.bg-cyan h1.text-warning,
.bg-cyan h2.text-warning,
.bg-cyan h3.text-warning,
.bg-cyan h4.text-warning,
.bg-cyan h5.text-warning,
.bg-cyan h6.text-warning,
.bg-cyan .h1.text-warning,
.bg-cyan .h2.text-warning,
.bg-cyan .h3.text-warning,
.bg-cyan .h4.text-warning,
.bg-cyan .h5.text-warning,
.bg-cyan .h6.text-warning {
  color: #f19e1f;
}

.bg-cyan p.text-danger,
.bg-cyan h1.text-danger,
.bg-cyan h2.text-danger,
.bg-cyan h3.text-danger,
.bg-cyan h4.text-danger,
.bg-cyan h5.text-danger,
.bg-cyan h6.text-danger,
.bg-cyan .h1.text-danger,
.bg-cyan .h2.text-danger,
.bg-cyan .h3.text-danger,
.bg-cyan .h4.text-danger,
.bg-cyan .h5.text-danger,
.bg-cyan .h6.text-danger {
  color: #ef4056;
}

.bg-cyan p.text-light,
.bg-cyan h1.text-light,
.bg-cyan h2.text-light,
.bg-cyan h3.text-light,
.bg-cyan h4.text-light,
.bg-cyan h5.text-light,
.bg-cyan h6.text-light,
.bg-cyan .h1.text-light,
.bg-cyan .h2.text-light,
.bg-cyan .h3.text-light,
.bg-cyan .h4.text-light,
.bg-cyan .h5.text-light,
.bg-cyan .h6.text-light {
  color: #dee2e6;
}

.bg-cyan p.text-dark,
.bg-cyan h1.text-dark,
.bg-cyan h2.text-dark,
.bg-cyan h3.text-dark,
.bg-cyan h4.text-dark,
.bg-cyan h5.text-dark,
.bg-cyan h6.text-dark,
.bg-cyan .h1.text-dark,
.bg-cyan .h2.text-dark,
.bg-cyan .h3.text-dark,
.bg-cyan .h4.text-dark,
.bg-cyan .h5.text-dark,
.bg-cyan .h6.text-dark {
  color: #343a40;
}

.bg-cyan p.text-primary-dark,
.bg-cyan h1.text-primary-dark,
.bg-cyan h2.text-primary-dark,
.bg-cyan h3.text-primary-dark,
.bg-cyan h4.text-primary-dark,
.bg-cyan h5.text-primary-dark,
.bg-cyan h6.text-primary-dark,
.bg-cyan .h1.text-primary-dark,
.bg-cyan .h2.text-primary-dark,
.bg-cyan .h3.text-primary-dark,
.bg-cyan .h4.text-primary-dark,
.bg-cyan .h5.text-primary-dark,
.bg-cyan .h6.text-primary-dark {
  color: #0c234b;
}

.bg-cyan p.text-primary-light,
.bg-cyan h1.text-primary-light,
.bg-cyan h2.text-primary-light,
.bg-cyan h3.text-primary-light,
.bg-cyan h4.text-primary-light,
.bg-cyan h5.text-primary-light,
.bg-cyan h6.text-primary-light,
.bg-cyan .h1.text-primary-light,
.bg-cyan .h2.text-primary-light,
.bg-cyan .h3.text-primary-light,
.bg-cyan .h4.text-primary-light,
.bg-cyan .h5.text-primary-light,
.bg-cyan .h6.text-primary-light {
  color: #378dbd;
}

.bg-cyan p.text-accent,
.bg-cyan h1.text-accent,
.bg-cyan h2.text-accent,
.bg-cyan h3.text-accent,
.bg-cyan h4.text-accent,
.bg-cyan h5.text-accent,
.bg-cyan h6.text-accent,
.bg-cyan .h1.text-accent,
.bg-cyan .h2.text-accent,
.bg-cyan .h3.text-accent,
.bg-cyan .h4.text-accent,
.bg-cyan .h5.text-accent,
.bg-cyan .h6.text-accent {
  color: #ab0520;
}

.bg-white {
  color: #fff;
  background-color: #fff;
  color: #000;
}

.bg-white p,
.bg-white h1,
.bg-white h2,
.bg-white h3,
.bg-white h4,
.bg-white h5,
.bg-white h6,
.bg-white .h1,
.bg-white .h2,
.bg-white .h3,
.bg-white .h4,
.bg-white .h5,
.bg-white .h6 {
  color: #000;
}

.bg-white p.text-blue,
.bg-white h1.text-blue,
.bg-white h2.text-blue,
.bg-white h3.text-blue,
.bg-white h4.text-blue,
.bg-white h5.text-blue,
.bg-white h6.text-blue,
.bg-white .h1.text-blue,
.bg-white .h2.text-blue,
.bg-white .h3.text-blue,
.bg-white .h4.text-blue,
.bg-white .h5.text-blue,
.bg-white .h6.text-blue {
  color: #0c234b;
}

.bg-white p.text-indigo,
.bg-white h1.text-indigo,
.bg-white h2.text-indigo,
.bg-white h3.text-indigo,
.bg-white h4.text-indigo,
.bg-white h5.text-indigo,
.bg-white h6.text-indigo,
.bg-white .h1.text-indigo,
.bg-white .h2.text-indigo,
.bg-white .h3.text-indigo,
.bg-white .h4.text-indigo,
.bg-white .h5.text-indigo,
.bg-white .h6.text-indigo {
  color: #6610f2;
}

.bg-white p.text-purple,
.bg-white h1.text-purple,
.bg-white h2.text-purple,
.bg-white h3.text-purple,
.bg-white h4.text-purple,
.bg-white h5.text-purple,
.bg-white h6.text-purple,
.bg-white .h1.text-purple,
.bg-white .h2.text-purple,
.bg-white .h3.text-purple,
.bg-white .h4.text-purple,
.bg-white .h5.text-purple,
.bg-white .h6.text-purple {
  color: #6f42c1;
}

.bg-white p.text-pink,
.bg-white h1.text-pink,
.bg-white h2.text-pink,
.bg-white h3.text-pink,
.bg-white h4.text-pink,
.bg-white h5.text-pink,
.bg-white h6.text-pink,
.bg-white .h1.text-pink,
.bg-white .h2.text-pink,
.bg-white .h3.text-pink,
.bg-white .h4.text-pink,
.bg-white .h5.text-pink,
.bg-white .h6.text-pink {
  color: #e83e8c;
}

.bg-white p.text-red,
.bg-white h1.text-red,
.bg-white h2.text-red,
.bg-white h3.text-red,
.bg-white h4.text-red,
.bg-white h5.text-red,
.bg-white h6.text-red,
.bg-white .h1.text-red,
.bg-white .h2.text-red,
.bg-white .h3.text-red,
.bg-white .h4.text-red,
.bg-white .h5.text-red,
.bg-white .h6.text-red {
  color: #ab0520;
}

.bg-white p.text-orange,
.bg-white h1.text-orange,
.bg-white h2.text-orange,
.bg-white h3.text-orange,
.bg-white h4.text-orange,
.bg-white h5.text-orange,
.bg-white h6.text-orange,
.bg-white .h1.text-orange,
.bg-white .h2.text-orange,
.bg-white .h3.text-orange,
.bg-white .h4.text-orange,
.bg-white .h5.text-orange,
.bg-white .h6.text-orange {
  color: #fd7e14;
}

.bg-white p.text-yellow,
.bg-white h1.text-yellow,
.bg-white h2.text-yellow,
.bg-white h3.text-yellow,
.bg-white h4.text-yellow,
.bg-white h5.text-yellow,
.bg-white h6.text-yellow,
.bg-white .h1.text-yellow,
.bg-white .h2.text-yellow,
.bg-white .h3.text-yellow,
.bg-white .h4.text-yellow,
.bg-white .h5.text-yellow,
.bg-white .h6.text-yellow {
  color: #ffc107;
}

.bg-white p.text-green,
.bg-white h1.text-green,
.bg-white h2.text-green,
.bg-white h3.text-green,
.bg-white h4.text-green,
.bg-white h5.text-green,
.bg-white h6.text-green,
.bg-white .h1.text-green,
.bg-white .h2.text-green,
.bg-white .h3.text-green,
.bg-white .h4.text-green,
.bg-white .h5.text-green,
.bg-white .h6.text-green {
  color: #28a745;
}

.bg-white p.text-teal,
.bg-white h1.text-teal,
.bg-white h2.text-teal,
.bg-white h3.text-teal,
.bg-white h4.text-teal,
.bg-white h5.text-teal,
.bg-white h6.text-teal,
.bg-white .h1.text-teal,
.bg-white .h2.text-teal,
.bg-white .h3.text-teal,
.bg-white .h4.text-teal,
.bg-white .h5.text-teal,
.bg-white .h6.text-teal {
  color: #20c997;
}

.bg-white p.text-cyan,
.bg-white h1.text-cyan,
.bg-white h2.text-cyan,
.bg-white h3.text-cyan,
.bg-white h4.text-cyan,
.bg-white h5.text-cyan,
.bg-white h6.text-cyan,
.bg-white .h1.text-cyan,
.bg-white .h2.text-cyan,
.bg-white .h3.text-cyan,
.bg-white .h4.text-cyan,
.bg-white .h5.text-cyan,
.bg-white .h6.text-cyan {
  color: #17a2b8;
}

.bg-white p.text-white,
.bg-white h1.text-white,
.bg-white h2.text-white,
.bg-white h3.text-white,
.bg-white h4.text-white,
.bg-white h5.text-white,
.bg-white h6.text-white,
.bg-white .h1.text-white,
.bg-white .h2.text-white,
.bg-white .h3.text-white,
.bg-white .h4.text-white,
.bg-white .h5.text-white,
.bg-white .h6.text-white {
  color: #fff;
}

.bg-white p.text-gray,
.bg-white h1.text-gray,
.bg-white h2.text-gray,
.bg-white h3.text-gray,
.bg-white h4.text-gray,
.bg-white h5.text-gray,
.bg-white h6.text-gray,
.bg-white .h1.text-gray,
.bg-white .h2.text-gray,
.bg-white .h3.text-gray,
.bg-white .h4.text-gray,
.bg-white .h5.text-gray,
.bg-white .h6.text-gray {
  color: #6c757d;
}

.bg-white p.text-gray-dark,
.bg-white h1.text-gray-dark,
.bg-white h2.text-gray-dark,
.bg-white h3.text-gray-dark,
.bg-white h4.text-gray-dark,
.bg-white h5.text-gray-dark,
.bg-white h6.text-gray-dark,
.bg-white .h1.text-gray-dark,
.bg-white .h2.text-gray-dark,
.bg-white .h3.text-gray-dark,
.bg-white .h4.text-gray-dark,
.bg-white .h5.text-gray-dark,
.bg-white .h6.text-gray-dark {
  color: #343a40;
}

.bg-white p.text-bloom,
.bg-white h1.text-bloom,
.bg-white h2.text-bloom,
.bg-white h3.text-bloom,
.bg-white h4.text-bloom,
.bg-white h5.text-bloom,
.bg-white h6.text-bloom,
.bg-white .h1.text-bloom,
.bg-white .h2.text-bloom,
.bg-white .h3.text-bloom,
.bg-white .h4.text-bloom,
.bg-white .h5.text-bloom,
.bg-white .h6.text-bloom {
  color: #ef4056;
}

.bg-white p.text-chili,
.bg-white h1.text-chili,
.bg-white h2.text-chili,
.bg-white h3.text-chili,
.bg-white h4.text-chili,
.bg-white h5.text-chili,
.bg-white h6.text-chili,
.bg-white .h1.text-chili,
.bg-white .h2.text-chili,
.bg-white .h3.text-chili,
.bg-white .h4.text-chili,
.bg-white .h5.text-chili,
.bg-white .h6.text-chili {
  color: #8b0015;
}

.bg-white p.text-sky,
.bg-white h1.text-sky,
.bg-white h2.text-sky,
.bg-white h3.text-sky,
.bg-white h4.text-sky,
.bg-white h5.text-sky,
.bg-white h6.text-sky,
.bg-white .h1.text-sky,
.bg-white .h2.text-sky,
.bg-white .h3.text-sky,
.bg-white .h4.text-sky,
.bg-white .h5.text-sky,
.bg-white .h6.text-sky {
  color: #81d3eb;
}

.bg-white p.text-oasis,
.bg-white h1.text-oasis,
.bg-white h2.text-oasis,
.bg-white h3.text-oasis,
.bg-white h4.text-oasis,
.bg-white h5.text-oasis,
.bg-white h6.text-oasis,
.bg-white .h1.text-oasis,
.bg-white .h2.text-oasis,
.bg-white .h3.text-oasis,
.bg-white .h4.text-oasis,
.bg-white .h5.text-oasis,
.bg-white .h6.text-oasis {
  color: #378dbd;
}

.bg-white p.text-azurite,
.bg-white h1.text-azurite,
.bg-white h2.text-azurite,
.bg-white h3.text-azurite,
.bg-white h4.text-azurite,
.bg-white h5.text-azurite,
.bg-white h6.text-azurite,
.bg-white .h1.text-azurite,
.bg-white .h2.text-azurite,
.bg-white .h3.text-azurite,
.bg-white .h4.text-azurite,
.bg-white .h5.text-azurite,
.bg-white .h6.text-azurite {
  color: #1e5288;
}

.bg-white p.text-midnight,
.bg-white h1.text-midnight,
.bg-white h2.text-midnight,
.bg-white h3.text-midnight,
.bg-white h4.text-midnight,
.bg-white h5.text-midnight,
.bg-white h6.text-midnight,
.bg-white .h1.text-midnight,
.bg-white .h2.text-midnight,
.bg-white .h3.text-midnight,
.bg-white .h4.text-midnight,
.bg-white .h5.text-midnight,
.bg-white .h6.text-midnight {
  color: #001c48;
}

.bg-white p.text-cool-gray,
.bg-white h1.text-cool-gray,
.bg-white h2.text-cool-gray,
.bg-white h3.text-cool-gray,
.bg-white h4.text-cool-gray,
.bg-white h5.text-cool-gray,
.bg-white h6.text-cool-gray,
.bg-white .h1.text-cool-gray,
.bg-white .h2.text-cool-gray,
.bg-white .h3.text-cool-gray,
.bg-white .h4.text-cool-gray,
.bg-white .h5.text-cool-gray,
.bg-white .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-white p.text-warm-gray,
.bg-white h1.text-warm-gray,
.bg-white h2.text-warm-gray,
.bg-white h3.text-warm-gray,
.bg-white h4.text-warm-gray,
.bg-white h5.text-warm-gray,
.bg-white h6.text-warm-gray,
.bg-white .h1.text-warm-gray,
.bg-white .h2.text-warm-gray,
.bg-white .h3.text-warm-gray,
.bg-white .h4.text-warm-gray,
.bg-white .h5.text-warm-gray,
.bg-white .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-white p.text-leaf,
.bg-white h1.text-leaf,
.bg-white h2.text-leaf,
.bg-white h3.text-leaf,
.bg-white h4.text-leaf,
.bg-white h5.text-leaf,
.bg-white h6.text-leaf,
.bg-white .h1.text-leaf,
.bg-white .h2.text-leaf,
.bg-white .h3.text-leaf,
.bg-white .h4.text-leaf,
.bg-white .h5.text-leaf,
.bg-white .h6.text-leaf {
  color: #70b865;
}

.bg-white p.text-river,
.bg-white h1.text-river,
.bg-white h2.text-river,
.bg-white h3.text-river,
.bg-white h4.text-river,
.bg-white h5.text-river,
.bg-white h6.text-river,
.bg-white .h1.text-river,
.bg-white .h2.text-river,
.bg-white .h3.text-river,
.bg-white .h4.text-river,
.bg-white .h5.text-river,
.bg-white .h6.text-river {
  color: #007d84;
}

.bg-white p.text-silver,
.bg-white h1.text-silver,
.bg-white h2.text-silver,
.bg-white h3.text-silver,
.bg-white h4.text-silver,
.bg-white h5.text-silver,
.bg-white h6.text-silver,
.bg-white .h1.text-silver,
.bg-white .h2.text-silver,
.bg-white .h3.text-silver,
.bg-white .h4.text-silver,
.bg-white .h5.text-silver,
.bg-white .h6.text-silver {
  color: #9eabae;
}

.bg-white p.text-mesa,
.bg-white h1.text-mesa,
.bg-white h2.text-mesa,
.bg-white h3.text-mesa,
.bg-white h4.text-mesa,
.bg-white h5.text-mesa,
.bg-white h6.text-mesa,
.bg-white .h1.text-mesa,
.bg-white .h2.text-mesa,
.bg-white .h3.text-mesa,
.bg-white .h4.text-mesa,
.bg-white .h5.text-mesa,
.bg-white .h6.text-mesa {
  color: #a95c42;
}

.bg-white p.text-ash,
.bg-white h1.text-ash,
.bg-white h2.text-ash,
.bg-white h3.text-ash,
.bg-white h4.text-ash,
.bg-white h5.text-ash,
.bg-white h6.text-ash,
.bg-white .h1.text-ash,
.bg-white .h2.text-ash,
.bg-white .h3.text-ash,
.bg-white .h4.text-ash,
.bg-white .h5.text-ash,
.bg-white .h6.text-ash {
  color: #403635;
}

.bg-white p.text-sage,
.bg-white h1.text-sage,
.bg-white h2.text-sage,
.bg-white h3.text-sage,
.bg-white h4.text-sage,
.bg-white h5.text-sage,
.bg-white h6.text-sage,
.bg-white .h1.text-sage,
.bg-white .h2.text-sage,
.bg-white .h3.text-sage,
.bg-white .h4.text-sage,
.bg-white .h5.text-sage,
.bg-white .h6.text-sage {
  color: #4a634e;
}

.bg-white p.text-black,
.bg-white h1.text-black,
.bg-white h2.text-black,
.bg-white h3.text-black,
.bg-white h4.text-black,
.bg-white h5.text-black,
.bg-white h6.text-black,
.bg-white .h1.text-black,
.bg-white .h2.text-black,
.bg-white .h3.text-black,
.bg-white .h4.text-black,
.bg-white .h5.text-black,
.bg-white .h6.text-black {
  color: #000;
}

.bg-white p.text-primary,
.bg-white h1.text-primary,
.bg-white h2.text-primary,
.bg-white h3.text-primary,
.bg-white h4.text-primary,
.bg-white h5.text-primary,
.bg-white h6.text-primary,
.bg-white .h1.text-primary,
.bg-white .h2.text-primary,
.bg-white .h3.text-primary,
.bg-white .h4.text-primary,
.bg-white .h5.text-primary,
.bg-white .h6.text-primary {
  color: #1e5288;
}

.bg-white p.text-secondary,
.bg-white h1.text-secondary,
.bg-white h2.text-secondary,
.bg-white h3.text-secondary,
.bg-white h4.text-secondary,
.bg-white h5.text-secondary,
.bg-white h6.text-secondary,
.bg-white .h1.text-secondary,
.bg-white .h2.text-secondary,
.bg-white .h3.text-secondary,
.bg-white .h4.text-secondary,
.bg-white .h5.text-secondary,
.bg-white .h6.text-secondary {
  color: #9eabae;
}

.bg-white p.text-success,
.bg-white h1.text-success,
.bg-white h2.text-success,
.bg-white h3.text-success,
.bg-white h4.text-success,
.bg-white h5.text-success,
.bg-white h6.text-success,
.bg-white .h1.text-success,
.bg-white .h2.text-success,
.bg-white .h3.text-success,
.bg-white .h4.text-success,
.bg-white .h5.text-success,
.bg-white .h6.text-success {
  color: #70b865;
}

.bg-white p.text-info,
.bg-white h1.text-info,
.bg-white h2.text-info,
.bg-white h3.text-info,
.bg-white h4.text-info,
.bg-white h5.text-info,
.bg-white h6.text-info,
.bg-white .h1.text-info,
.bg-white .h2.text-info,
.bg-white .h3.text-info,
.bg-white .h4.text-info,
.bg-white .h5.text-info,
.bg-white .h6.text-info {
  color: #81d3eb;
}

.bg-white p.text-warning,
.bg-white h1.text-warning,
.bg-white h2.text-warning,
.bg-white h3.text-warning,
.bg-white h4.text-warning,
.bg-white h5.text-warning,
.bg-white h6.text-warning,
.bg-white .h1.text-warning,
.bg-white .h2.text-warning,
.bg-white .h3.text-warning,
.bg-white .h4.text-warning,
.bg-white .h5.text-warning,
.bg-white .h6.text-warning {
  color: #f19e1f;
}

.bg-white p.text-danger,
.bg-white h1.text-danger,
.bg-white h2.text-danger,
.bg-white h3.text-danger,
.bg-white h4.text-danger,
.bg-white h5.text-danger,
.bg-white h6.text-danger,
.bg-white .h1.text-danger,
.bg-white .h2.text-danger,
.bg-white .h3.text-danger,
.bg-white .h4.text-danger,
.bg-white .h5.text-danger,
.bg-white .h6.text-danger {
  color: #ef4056;
}

.bg-white p.text-light,
.bg-white h1.text-light,
.bg-white h2.text-light,
.bg-white h3.text-light,
.bg-white h4.text-light,
.bg-white h5.text-light,
.bg-white h6.text-light,
.bg-white .h1.text-light,
.bg-white .h2.text-light,
.bg-white .h3.text-light,
.bg-white .h4.text-light,
.bg-white .h5.text-light,
.bg-white .h6.text-light {
  color: #dee2e6;
}

.bg-white p.text-dark,
.bg-white h1.text-dark,
.bg-white h2.text-dark,
.bg-white h3.text-dark,
.bg-white h4.text-dark,
.bg-white h5.text-dark,
.bg-white h6.text-dark,
.bg-white .h1.text-dark,
.bg-white .h2.text-dark,
.bg-white .h3.text-dark,
.bg-white .h4.text-dark,
.bg-white .h5.text-dark,
.bg-white .h6.text-dark {
  color: #343a40;
}

.bg-white p.text-primary-dark,
.bg-white h1.text-primary-dark,
.bg-white h2.text-primary-dark,
.bg-white h3.text-primary-dark,
.bg-white h4.text-primary-dark,
.bg-white h5.text-primary-dark,
.bg-white h6.text-primary-dark,
.bg-white .h1.text-primary-dark,
.bg-white .h2.text-primary-dark,
.bg-white .h3.text-primary-dark,
.bg-white .h4.text-primary-dark,
.bg-white .h5.text-primary-dark,
.bg-white .h6.text-primary-dark {
  color: #0c234b;
}

.bg-white p.text-primary-light,
.bg-white h1.text-primary-light,
.bg-white h2.text-primary-light,
.bg-white h3.text-primary-light,
.bg-white h4.text-primary-light,
.bg-white h5.text-primary-light,
.bg-white h6.text-primary-light,
.bg-white .h1.text-primary-light,
.bg-white .h2.text-primary-light,
.bg-white .h3.text-primary-light,
.bg-white .h4.text-primary-light,
.bg-white .h5.text-primary-light,
.bg-white .h6.text-primary-light {
  color: #378dbd;
}

.bg-white p.text-accent,
.bg-white h1.text-accent,
.bg-white h2.text-accent,
.bg-white h3.text-accent,
.bg-white h4.text-accent,
.bg-white h5.text-accent,
.bg-white h6.text-accent,
.bg-white .h1.text-accent,
.bg-white .h2.text-accent,
.bg-white .h3.text-accent,
.bg-white .h4.text-accent,
.bg-white .h5.text-accent,
.bg-white .h6.text-accent {
  color: #ab0520;
}

.bg-gray {
  color: #fff;
  background-color: #6c757d;
  color: #fff;
}

.bg-gray p,
.bg-gray h1,
.bg-gray h2,
.bg-gray h3,
.bg-gray h4,
.bg-gray h5,
.bg-gray h6,
.bg-gray .h1,
.bg-gray .h2,
.bg-gray .h3,
.bg-gray .h4,
.bg-gray .h5,
.bg-gray .h6 {
  color: #fff;
}

.bg-gray p.text-blue,
.bg-gray h1.text-blue,
.bg-gray h2.text-blue,
.bg-gray h3.text-blue,
.bg-gray h4.text-blue,
.bg-gray h5.text-blue,
.bg-gray h6.text-blue,
.bg-gray .h1.text-blue,
.bg-gray .h2.text-blue,
.bg-gray .h3.text-blue,
.bg-gray .h4.text-blue,
.bg-gray .h5.text-blue,
.bg-gray .h6.text-blue {
  color: #0c234b;
}

.bg-gray p.text-indigo,
.bg-gray h1.text-indigo,
.bg-gray h2.text-indigo,
.bg-gray h3.text-indigo,
.bg-gray h4.text-indigo,
.bg-gray h5.text-indigo,
.bg-gray h6.text-indigo,
.bg-gray .h1.text-indigo,
.bg-gray .h2.text-indigo,
.bg-gray .h3.text-indigo,
.bg-gray .h4.text-indigo,
.bg-gray .h5.text-indigo,
.bg-gray .h6.text-indigo {
  color: #6610f2;
}

.bg-gray p.text-purple,
.bg-gray h1.text-purple,
.bg-gray h2.text-purple,
.bg-gray h3.text-purple,
.bg-gray h4.text-purple,
.bg-gray h5.text-purple,
.bg-gray h6.text-purple,
.bg-gray .h1.text-purple,
.bg-gray .h2.text-purple,
.bg-gray .h3.text-purple,
.bg-gray .h4.text-purple,
.bg-gray .h5.text-purple,
.bg-gray .h6.text-purple {
  color: #6f42c1;
}

.bg-gray p.text-pink,
.bg-gray h1.text-pink,
.bg-gray h2.text-pink,
.bg-gray h3.text-pink,
.bg-gray h4.text-pink,
.bg-gray h5.text-pink,
.bg-gray h6.text-pink,
.bg-gray .h1.text-pink,
.bg-gray .h2.text-pink,
.bg-gray .h3.text-pink,
.bg-gray .h4.text-pink,
.bg-gray .h5.text-pink,
.bg-gray .h6.text-pink {
  color: #e83e8c;
}

.bg-gray p.text-red,
.bg-gray h1.text-red,
.bg-gray h2.text-red,
.bg-gray h3.text-red,
.bg-gray h4.text-red,
.bg-gray h5.text-red,
.bg-gray h6.text-red,
.bg-gray .h1.text-red,
.bg-gray .h2.text-red,
.bg-gray .h3.text-red,
.bg-gray .h4.text-red,
.bg-gray .h5.text-red,
.bg-gray .h6.text-red {
  color: #ab0520;
}

.bg-gray p.text-orange,
.bg-gray h1.text-orange,
.bg-gray h2.text-orange,
.bg-gray h3.text-orange,
.bg-gray h4.text-orange,
.bg-gray h5.text-orange,
.bg-gray h6.text-orange,
.bg-gray .h1.text-orange,
.bg-gray .h2.text-orange,
.bg-gray .h3.text-orange,
.bg-gray .h4.text-orange,
.bg-gray .h5.text-orange,
.bg-gray .h6.text-orange {
  color: #fd7e14;
}

.bg-gray p.text-yellow,
.bg-gray h1.text-yellow,
.bg-gray h2.text-yellow,
.bg-gray h3.text-yellow,
.bg-gray h4.text-yellow,
.bg-gray h5.text-yellow,
.bg-gray h6.text-yellow,
.bg-gray .h1.text-yellow,
.bg-gray .h2.text-yellow,
.bg-gray .h3.text-yellow,
.bg-gray .h4.text-yellow,
.bg-gray .h5.text-yellow,
.bg-gray .h6.text-yellow {
  color: #ffc107;
}

.bg-gray p.text-green,
.bg-gray h1.text-green,
.bg-gray h2.text-green,
.bg-gray h3.text-green,
.bg-gray h4.text-green,
.bg-gray h5.text-green,
.bg-gray h6.text-green,
.bg-gray .h1.text-green,
.bg-gray .h2.text-green,
.bg-gray .h3.text-green,
.bg-gray .h4.text-green,
.bg-gray .h5.text-green,
.bg-gray .h6.text-green {
  color: #28a745;
}

.bg-gray p.text-teal,
.bg-gray h1.text-teal,
.bg-gray h2.text-teal,
.bg-gray h3.text-teal,
.bg-gray h4.text-teal,
.bg-gray h5.text-teal,
.bg-gray h6.text-teal,
.bg-gray .h1.text-teal,
.bg-gray .h2.text-teal,
.bg-gray .h3.text-teal,
.bg-gray .h4.text-teal,
.bg-gray .h5.text-teal,
.bg-gray .h6.text-teal {
  color: #20c997;
}

.bg-gray p.text-cyan,
.bg-gray h1.text-cyan,
.bg-gray h2.text-cyan,
.bg-gray h3.text-cyan,
.bg-gray h4.text-cyan,
.bg-gray h5.text-cyan,
.bg-gray h6.text-cyan,
.bg-gray .h1.text-cyan,
.bg-gray .h2.text-cyan,
.bg-gray .h3.text-cyan,
.bg-gray .h4.text-cyan,
.bg-gray .h5.text-cyan,
.bg-gray .h6.text-cyan {
  color: #17a2b8;
}

.bg-gray p.text-white,
.bg-gray h1.text-white,
.bg-gray h2.text-white,
.bg-gray h3.text-white,
.bg-gray h4.text-white,
.bg-gray h5.text-white,
.bg-gray h6.text-white,
.bg-gray .h1.text-white,
.bg-gray .h2.text-white,
.bg-gray .h3.text-white,
.bg-gray .h4.text-white,
.bg-gray .h5.text-white,
.bg-gray .h6.text-white {
  color: #fff;
}

.bg-gray p.text-gray,
.bg-gray h1.text-gray,
.bg-gray h2.text-gray,
.bg-gray h3.text-gray,
.bg-gray h4.text-gray,
.bg-gray h5.text-gray,
.bg-gray h6.text-gray,
.bg-gray .h1.text-gray,
.bg-gray .h2.text-gray,
.bg-gray .h3.text-gray,
.bg-gray .h4.text-gray,
.bg-gray .h5.text-gray,
.bg-gray .h6.text-gray {
  color: #6c757d;
}

.bg-gray p.text-gray-dark,
.bg-gray h1.text-gray-dark,
.bg-gray h2.text-gray-dark,
.bg-gray h3.text-gray-dark,
.bg-gray h4.text-gray-dark,
.bg-gray h5.text-gray-dark,
.bg-gray h6.text-gray-dark,
.bg-gray .h1.text-gray-dark,
.bg-gray .h2.text-gray-dark,
.bg-gray .h3.text-gray-dark,
.bg-gray .h4.text-gray-dark,
.bg-gray .h5.text-gray-dark,
.bg-gray .h6.text-gray-dark {
  color: #343a40;
}

.bg-gray p.text-bloom,
.bg-gray h1.text-bloom,
.bg-gray h2.text-bloom,
.bg-gray h3.text-bloom,
.bg-gray h4.text-bloom,
.bg-gray h5.text-bloom,
.bg-gray h6.text-bloom,
.bg-gray .h1.text-bloom,
.bg-gray .h2.text-bloom,
.bg-gray .h3.text-bloom,
.bg-gray .h4.text-bloom,
.bg-gray .h5.text-bloom,
.bg-gray .h6.text-bloom {
  color: #ef4056;
}

.bg-gray p.text-chili,
.bg-gray h1.text-chili,
.bg-gray h2.text-chili,
.bg-gray h3.text-chili,
.bg-gray h4.text-chili,
.bg-gray h5.text-chili,
.bg-gray h6.text-chili,
.bg-gray .h1.text-chili,
.bg-gray .h2.text-chili,
.bg-gray .h3.text-chili,
.bg-gray .h4.text-chili,
.bg-gray .h5.text-chili,
.bg-gray .h6.text-chili {
  color: #8b0015;
}

.bg-gray p.text-sky,
.bg-gray h1.text-sky,
.bg-gray h2.text-sky,
.bg-gray h3.text-sky,
.bg-gray h4.text-sky,
.bg-gray h5.text-sky,
.bg-gray h6.text-sky,
.bg-gray .h1.text-sky,
.bg-gray .h2.text-sky,
.bg-gray .h3.text-sky,
.bg-gray .h4.text-sky,
.bg-gray .h5.text-sky,
.bg-gray .h6.text-sky {
  color: #81d3eb;
}

.bg-gray p.text-oasis,
.bg-gray h1.text-oasis,
.bg-gray h2.text-oasis,
.bg-gray h3.text-oasis,
.bg-gray h4.text-oasis,
.bg-gray h5.text-oasis,
.bg-gray h6.text-oasis,
.bg-gray .h1.text-oasis,
.bg-gray .h2.text-oasis,
.bg-gray .h3.text-oasis,
.bg-gray .h4.text-oasis,
.bg-gray .h5.text-oasis,
.bg-gray .h6.text-oasis {
  color: #378dbd;
}

.bg-gray p.text-azurite,
.bg-gray h1.text-azurite,
.bg-gray h2.text-azurite,
.bg-gray h3.text-azurite,
.bg-gray h4.text-azurite,
.bg-gray h5.text-azurite,
.bg-gray h6.text-azurite,
.bg-gray .h1.text-azurite,
.bg-gray .h2.text-azurite,
.bg-gray .h3.text-azurite,
.bg-gray .h4.text-azurite,
.bg-gray .h5.text-azurite,
.bg-gray .h6.text-azurite {
  color: #1e5288;
}

.bg-gray p.text-midnight,
.bg-gray h1.text-midnight,
.bg-gray h2.text-midnight,
.bg-gray h3.text-midnight,
.bg-gray h4.text-midnight,
.bg-gray h5.text-midnight,
.bg-gray h6.text-midnight,
.bg-gray .h1.text-midnight,
.bg-gray .h2.text-midnight,
.bg-gray .h3.text-midnight,
.bg-gray .h4.text-midnight,
.bg-gray .h5.text-midnight,
.bg-gray .h6.text-midnight {
  color: #001c48;
}

.bg-gray p.text-cool-gray,
.bg-gray h1.text-cool-gray,
.bg-gray h2.text-cool-gray,
.bg-gray h3.text-cool-gray,
.bg-gray h4.text-cool-gray,
.bg-gray h5.text-cool-gray,
.bg-gray h6.text-cool-gray,
.bg-gray .h1.text-cool-gray,
.bg-gray .h2.text-cool-gray,
.bg-gray .h3.text-cool-gray,
.bg-gray .h4.text-cool-gray,
.bg-gray .h5.text-cool-gray,
.bg-gray .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-gray p.text-warm-gray,
.bg-gray h1.text-warm-gray,
.bg-gray h2.text-warm-gray,
.bg-gray h3.text-warm-gray,
.bg-gray h4.text-warm-gray,
.bg-gray h5.text-warm-gray,
.bg-gray h6.text-warm-gray,
.bg-gray .h1.text-warm-gray,
.bg-gray .h2.text-warm-gray,
.bg-gray .h3.text-warm-gray,
.bg-gray .h4.text-warm-gray,
.bg-gray .h5.text-warm-gray,
.bg-gray .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-gray p.text-leaf,
.bg-gray h1.text-leaf,
.bg-gray h2.text-leaf,
.bg-gray h3.text-leaf,
.bg-gray h4.text-leaf,
.bg-gray h5.text-leaf,
.bg-gray h6.text-leaf,
.bg-gray .h1.text-leaf,
.bg-gray .h2.text-leaf,
.bg-gray .h3.text-leaf,
.bg-gray .h4.text-leaf,
.bg-gray .h5.text-leaf,
.bg-gray .h6.text-leaf {
  color: #70b865;
}

.bg-gray p.text-river,
.bg-gray h1.text-river,
.bg-gray h2.text-river,
.bg-gray h3.text-river,
.bg-gray h4.text-river,
.bg-gray h5.text-river,
.bg-gray h6.text-river,
.bg-gray .h1.text-river,
.bg-gray .h2.text-river,
.bg-gray .h3.text-river,
.bg-gray .h4.text-river,
.bg-gray .h5.text-river,
.bg-gray .h6.text-river {
  color: #007d84;
}

.bg-gray p.text-silver,
.bg-gray h1.text-silver,
.bg-gray h2.text-silver,
.bg-gray h3.text-silver,
.bg-gray h4.text-silver,
.bg-gray h5.text-silver,
.bg-gray h6.text-silver,
.bg-gray .h1.text-silver,
.bg-gray .h2.text-silver,
.bg-gray .h3.text-silver,
.bg-gray .h4.text-silver,
.bg-gray .h5.text-silver,
.bg-gray .h6.text-silver {
  color: #9eabae;
}

.bg-gray p.text-mesa,
.bg-gray h1.text-mesa,
.bg-gray h2.text-mesa,
.bg-gray h3.text-mesa,
.bg-gray h4.text-mesa,
.bg-gray h5.text-mesa,
.bg-gray h6.text-mesa,
.bg-gray .h1.text-mesa,
.bg-gray .h2.text-mesa,
.bg-gray .h3.text-mesa,
.bg-gray .h4.text-mesa,
.bg-gray .h5.text-mesa,
.bg-gray .h6.text-mesa {
  color: #a95c42;
}

.bg-gray p.text-ash,
.bg-gray h1.text-ash,
.bg-gray h2.text-ash,
.bg-gray h3.text-ash,
.bg-gray h4.text-ash,
.bg-gray h5.text-ash,
.bg-gray h6.text-ash,
.bg-gray .h1.text-ash,
.bg-gray .h2.text-ash,
.bg-gray .h3.text-ash,
.bg-gray .h4.text-ash,
.bg-gray .h5.text-ash,
.bg-gray .h6.text-ash {
  color: #403635;
}

.bg-gray p.text-sage,
.bg-gray h1.text-sage,
.bg-gray h2.text-sage,
.bg-gray h3.text-sage,
.bg-gray h4.text-sage,
.bg-gray h5.text-sage,
.bg-gray h6.text-sage,
.bg-gray .h1.text-sage,
.bg-gray .h2.text-sage,
.bg-gray .h3.text-sage,
.bg-gray .h4.text-sage,
.bg-gray .h5.text-sage,
.bg-gray .h6.text-sage {
  color: #4a634e;
}

.bg-gray p.text-black,
.bg-gray h1.text-black,
.bg-gray h2.text-black,
.bg-gray h3.text-black,
.bg-gray h4.text-black,
.bg-gray h5.text-black,
.bg-gray h6.text-black,
.bg-gray .h1.text-black,
.bg-gray .h2.text-black,
.bg-gray .h3.text-black,
.bg-gray .h4.text-black,
.bg-gray .h5.text-black,
.bg-gray .h6.text-black {
  color: #000;
}

.bg-gray p.text-primary,
.bg-gray h1.text-primary,
.bg-gray h2.text-primary,
.bg-gray h3.text-primary,
.bg-gray h4.text-primary,
.bg-gray h5.text-primary,
.bg-gray h6.text-primary,
.bg-gray .h1.text-primary,
.bg-gray .h2.text-primary,
.bg-gray .h3.text-primary,
.bg-gray .h4.text-primary,
.bg-gray .h5.text-primary,
.bg-gray .h6.text-primary {
  color: #1e5288;
}

.bg-gray p.text-secondary,
.bg-gray h1.text-secondary,
.bg-gray h2.text-secondary,
.bg-gray h3.text-secondary,
.bg-gray h4.text-secondary,
.bg-gray h5.text-secondary,
.bg-gray h6.text-secondary,
.bg-gray .h1.text-secondary,
.bg-gray .h2.text-secondary,
.bg-gray .h3.text-secondary,
.bg-gray .h4.text-secondary,
.bg-gray .h5.text-secondary,
.bg-gray .h6.text-secondary {
  color: #9eabae;
}

.bg-gray p.text-success,
.bg-gray h1.text-success,
.bg-gray h2.text-success,
.bg-gray h3.text-success,
.bg-gray h4.text-success,
.bg-gray h5.text-success,
.bg-gray h6.text-success,
.bg-gray .h1.text-success,
.bg-gray .h2.text-success,
.bg-gray .h3.text-success,
.bg-gray .h4.text-success,
.bg-gray .h5.text-success,
.bg-gray .h6.text-success {
  color: #70b865;
}

.bg-gray p.text-info,
.bg-gray h1.text-info,
.bg-gray h2.text-info,
.bg-gray h3.text-info,
.bg-gray h4.text-info,
.bg-gray h5.text-info,
.bg-gray h6.text-info,
.bg-gray .h1.text-info,
.bg-gray .h2.text-info,
.bg-gray .h3.text-info,
.bg-gray .h4.text-info,
.bg-gray .h5.text-info,
.bg-gray .h6.text-info {
  color: #81d3eb;
}

.bg-gray p.text-warning,
.bg-gray h1.text-warning,
.bg-gray h2.text-warning,
.bg-gray h3.text-warning,
.bg-gray h4.text-warning,
.bg-gray h5.text-warning,
.bg-gray h6.text-warning,
.bg-gray .h1.text-warning,
.bg-gray .h2.text-warning,
.bg-gray .h3.text-warning,
.bg-gray .h4.text-warning,
.bg-gray .h5.text-warning,
.bg-gray .h6.text-warning {
  color: #f19e1f;
}

.bg-gray p.text-danger,
.bg-gray h1.text-danger,
.bg-gray h2.text-danger,
.bg-gray h3.text-danger,
.bg-gray h4.text-danger,
.bg-gray h5.text-danger,
.bg-gray h6.text-danger,
.bg-gray .h1.text-danger,
.bg-gray .h2.text-danger,
.bg-gray .h3.text-danger,
.bg-gray .h4.text-danger,
.bg-gray .h5.text-danger,
.bg-gray .h6.text-danger {
  color: #ef4056;
}

.bg-gray p.text-light,
.bg-gray h1.text-light,
.bg-gray h2.text-light,
.bg-gray h3.text-light,
.bg-gray h4.text-light,
.bg-gray h5.text-light,
.bg-gray h6.text-light,
.bg-gray .h1.text-light,
.bg-gray .h2.text-light,
.bg-gray .h3.text-light,
.bg-gray .h4.text-light,
.bg-gray .h5.text-light,
.bg-gray .h6.text-light {
  color: #dee2e6;
}

.bg-gray p.text-dark,
.bg-gray h1.text-dark,
.bg-gray h2.text-dark,
.bg-gray h3.text-dark,
.bg-gray h4.text-dark,
.bg-gray h5.text-dark,
.bg-gray h6.text-dark,
.bg-gray .h1.text-dark,
.bg-gray .h2.text-dark,
.bg-gray .h3.text-dark,
.bg-gray .h4.text-dark,
.bg-gray .h5.text-dark,
.bg-gray .h6.text-dark {
  color: #343a40;
}

.bg-gray p.text-primary-dark,
.bg-gray h1.text-primary-dark,
.bg-gray h2.text-primary-dark,
.bg-gray h3.text-primary-dark,
.bg-gray h4.text-primary-dark,
.bg-gray h5.text-primary-dark,
.bg-gray h6.text-primary-dark,
.bg-gray .h1.text-primary-dark,
.bg-gray .h2.text-primary-dark,
.bg-gray .h3.text-primary-dark,
.bg-gray .h4.text-primary-dark,
.bg-gray .h5.text-primary-dark,
.bg-gray .h6.text-primary-dark {
  color: #0c234b;
}

.bg-gray p.text-primary-light,
.bg-gray h1.text-primary-light,
.bg-gray h2.text-primary-light,
.bg-gray h3.text-primary-light,
.bg-gray h4.text-primary-light,
.bg-gray h5.text-primary-light,
.bg-gray h6.text-primary-light,
.bg-gray .h1.text-primary-light,
.bg-gray .h2.text-primary-light,
.bg-gray .h3.text-primary-light,
.bg-gray .h4.text-primary-light,
.bg-gray .h5.text-primary-light,
.bg-gray .h6.text-primary-light {
  color: #378dbd;
}

.bg-gray p.text-accent,
.bg-gray h1.text-accent,
.bg-gray h2.text-accent,
.bg-gray h3.text-accent,
.bg-gray h4.text-accent,
.bg-gray h5.text-accent,
.bg-gray h6.text-accent,
.bg-gray .h1.text-accent,
.bg-gray .h2.text-accent,
.bg-gray .h3.text-accent,
.bg-gray .h4.text-accent,
.bg-gray .h5.text-accent,
.bg-gray .h6.text-accent {
  color: #ab0520;
}

.bg-gray-dark {
  color: #fff;
  background-color: #343a40;
  color: #fff;
}

.bg-gray-dark p,
.bg-gray-dark h1,
.bg-gray-dark h2,
.bg-gray-dark h3,
.bg-gray-dark h4,
.bg-gray-dark h5,
.bg-gray-dark h6,
.bg-gray-dark .h1,
.bg-gray-dark .h2,
.bg-gray-dark .h3,
.bg-gray-dark .h4,
.bg-gray-dark .h5,
.bg-gray-dark .h6 {
  color: #fff;
}

.bg-gray-dark p.text-blue,
.bg-gray-dark h1.text-blue,
.bg-gray-dark h2.text-blue,
.bg-gray-dark h3.text-blue,
.bg-gray-dark h4.text-blue,
.bg-gray-dark h5.text-blue,
.bg-gray-dark h6.text-blue,
.bg-gray-dark .h1.text-blue,
.bg-gray-dark .h2.text-blue,
.bg-gray-dark .h3.text-blue,
.bg-gray-dark .h4.text-blue,
.bg-gray-dark .h5.text-blue,
.bg-gray-dark .h6.text-blue {
  color: #0c234b;
}

.bg-gray-dark p.text-indigo,
.bg-gray-dark h1.text-indigo,
.bg-gray-dark h2.text-indigo,
.bg-gray-dark h3.text-indigo,
.bg-gray-dark h4.text-indigo,
.bg-gray-dark h5.text-indigo,
.bg-gray-dark h6.text-indigo,
.bg-gray-dark .h1.text-indigo,
.bg-gray-dark .h2.text-indigo,
.bg-gray-dark .h3.text-indigo,
.bg-gray-dark .h4.text-indigo,
.bg-gray-dark .h5.text-indigo,
.bg-gray-dark .h6.text-indigo {
  color: #6610f2;
}

.bg-gray-dark p.text-purple,
.bg-gray-dark h1.text-purple,
.bg-gray-dark h2.text-purple,
.bg-gray-dark h3.text-purple,
.bg-gray-dark h4.text-purple,
.bg-gray-dark h5.text-purple,
.bg-gray-dark h6.text-purple,
.bg-gray-dark .h1.text-purple,
.bg-gray-dark .h2.text-purple,
.bg-gray-dark .h3.text-purple,
.bg-gray-dark .h4.text-purple,
.bg-gray-dark .h5.text-purple,
.bg-gray-dark .h6.text-purple {
  color: #6f42c1;
}

.bg-gray-dark p.text-pink,
.bg-gray-dark h1.text-pink,
.bg-gray-dark h2.text-pink,
.bg-gray-dark h3.text-pink,
.bg-gray-dark h4.text-pink,
.bg-gray-dark h5.text-pink,
.bg-gray-dark h6.text-pink,
.bg-gray-dark .h1.text-pink,
.bg-gray-dark .h2.text-pink,
.bg-gray-dark .h3.text-pink,
.bg-gray-dark .h4.text-pink,
.bg-gray-dark .h5.text-pink,
.bg-gray-dark .h6.text-pink {
  color: #e83e8c;
}

.bg-gray-dark p.text-red,
.bg-gray-dark h1.text-red,
.bg-gray-dark h2.text-red,
.bg-gray-dark h3.text-red,
.bg-gray-dark h4.text-red,
.bg-gray-dark h5.text-red,
.bg-gray-dark h6.text-red,
.bg-gray-dark .h1.text-red,
.bg-gray-dark .h2.text-red,
.bg-gray-dark .h3.text-red,
.bg-gray-dark .h4.text-red,
.bg-gray-dark .h5.text-red,
.bg-gray-dark .h6.text-red {
  color: #ab0520;
}

.bg-gray-dark p.text-orange,
.bg-gray-dark h1.text-orange,
.bg-gray-dark h2.text-orange,
.bg-gray-dark h3.text-orange,
.bg-gray-dark h4.text-orange,
.bg-gray-dark h5.text-orange,
.bg-gray-dark h6.text-orange,
.bg-gray-dark .h1.text-orange,
.bg-gray-dark .h2.text-orange,
.bg-gray-dark .h3.text-orange,
.bg-gray-dark .h4.text-orange,
.bg-gray-dark .h5.text-orange,
.bg-gray-dark .h6.text-orange {
  color: #fd7e14;
}

.bg-gray-dark p.text-yellow,
.bg-gray-dark h1.text-yellow,
.bg-gray-dark h2.text-yellow,
.bg-gray-dark h3.text-yellow,
.bg-gray-dark h4.text-yellow,
.bg-gray-dark h5.text-yellow,
.bg-gray-dark h6.text-yellow,
.bg-gray-dark .h1.text-yellow,
.bg-gray-dark .h2.text-yellow,
.bg-gray-dark .h3.text-yellow,
.bg-gray-dark .h4.text-yellow,
.bg-gray-dark .h5.text-yellow,
.bg-gray-dark .h6.text-yellow {
  color: #ffc107;
}

.bg-gray-dark p.text-green,
.bg-gray-dark h1.text-green,
.bg-gray-dark h2.text-green,
.bg-gray-dark h3.text-green,
.bg-gray-dark h4.text-green,
.bg-gray-dark h5.text-green,
.bg-gray-dark h6.text-green,
.bg-gray-dark .h1.text-green,
.bg-gray-dark .h2.text-green,
.bg-gray-dark .h3.text-green,
.bg-gray-dark .h4.text-green,
.bg-gray-dark .h5.text-green,
.bg-gray-dark .h6.text-green {
  color: #28a745;
}

.bg-gray-dark p.text-teal,
.bg-gray-dark h1.text-teal,
.bg-gray-dark h2.text-teal,
.bg-gray-dark h3.text-teal,
.bg-gray-dark h4.text-teal,
.bg-gray-dark h5.text-teal,
.bg-gray-dark h6.text-teal,
.bg-gray-dark .h1.text-teal,
.bg-gray-dark .h2.text-teal,
.bg-gray-dark .h3.text-teal,
.bg-gray-dark .h4.text-teal,
.bg-gray-dark .h5.text-teal,
.bg-gray-dark .h6.text-teal {
  color: #20c997;
}

.bg-gray-dark p.text-cyan,
.bg-gray-dark h1.text-cyan,
.bg-gray-dark h2.text-cyan,
.bg-gray-dark h3.text-cyan,
.bg-gray-dark h4.text-cyan,
.bg-gray-dark h5.text-cyan,
.bg-gray-dark h6.text-cyan,
.bg-gray-dark .h1.text-cyan,
.bg-gray-dark .h2.text-cyan,
.bg-gray-dark .h3.text-cyan,
.bg-gray-dark .h4.text-cyan,
.bg-gray-dark .h5.text-cyan,
.bg-gray-dark .h6.text-cyan {
  color: #17a2b8;
}

.bg-gray-dark p.text-white,
.bg-gray-dark h1.text-white,
.bg-gray-dark h2.text-white,
.bg-gray-dark h3.text-white,
.bg-gray-dark h4.text-white,
.bg-gray-dark h5.text-white,
.bg-gray-dark h6.text-white,
.bg-gray-dark .h1.text-white,
.bg-gray-dark .h2.text-white,
.bg-gray-dark .h3.text-white,
.bg-gray-dark .h4.text-white,
.bg-gray-dark .h5.text-white,
.bg-gray-dark .h6.text-white {
  color: #fff;
}

.bg-gray-dark p.text-gray,
.bg-gray-dark h1.text-gray,
.bg-gray-dark h2.text-gray,
.bg-gray-dark h3.text-gray,
.bg-gray-dark h4.text-gray,
.bg-gray-dark h5.text-gray,
.bg-gray-dark h6.text-gray,
.bg-gray-dark .h1.text-gray,
.bg-gray-dark .h2.text-gray,
.bg-gray-dark .h3.text-gray,
.bg-gray-dark .h4.text-gray,
.bg-gray-dark .h5.text-gray,
.bg-gray-dark .h6.text-gray {
  color: #6c757d;
}

.bg-gray-dark p.text-gray-dark,
.bg-gray-dark h1.text-gray-dark,
.bg-gray-dark h2.text-gray-dark,
.bg-gray-dark h3.text-gray-dark,
.bg-gray-dark h4.text-gray-dark,
.bg-gray-dark h5.text-gray-dark,
.bg-gray-dark h6.text-gray-dark,
.bg-gray-dark .h1.text-gray-dark,
.bg-gray-dark .h2.text-gray-dark,
.bg-gray-dark .h3.text-gray-dark,
.bg-gray-dark .h4.text-gray-dark,
.bg-gray-dark .h5.text-gray-dark,
.bg-gray-dark .h6.text-gray-dark {
  color: #343a40;
}

.bg-gray-dark p.text-bloom,
.bg-gray-dark h1.text-bloom,
.bg-gray-dark h2.text-bloom,
.bg-gray-dark h3.text-bloom,
.bg-gray-dark h4.text-bloom,
.bg-gray-dark h5.text-bloom,
.bg-gray-dark h6.text-bloom,
.bg-gray-dark .h1.text-bloom,
.bg-gray-dark .h2.text-bloom,
.bg-gray-dark .h3.text-bloom,
.bg-gray-dark .h4.text-bloom,
.bg-gray-dark .h5.text-bloom,
.bg-gray-dark .h6.text-bloom {
  color: #ef4056;
}

.bg-gray-dark p.text-chili,
.bg-gray-dark h1.text-chili,
.bg-gray-dark h2.text-chili,
.bg-gray-dark h3.text-chili,
.bg-gray-dark h4.text-chili,
.bg-gray-dark h5.text-chili,
.bg-gray-dark h6.text-chili,
.bg-gray-dark .h1.text-chili,
.bg-gray-dark .h2.text-chili,
.bg-gray-dark .h3.text-chili,
.bg-gray-dark .h4.text-chili,
.bg-gray-dark .h5.text-chili,
.bg-gray-dark .h6.text-chili {
  color: #8b0015;
}

.bg-gray-dark p.text-sky,
.bg-gray-dark h1.text-sky,
.bg-gray-dark h2.text-sky,
.bg-gray-dark h3.text-sky,
.bg-gray-dark h4.text-sky,
.bg-gray-dark h5.text-sky,
.bg-gray-dark h6.text-sky,
.bg-gray-dark .h1.text-sky,
.bg-gray-dark .h2.text-sky,
.bg-gray-dark .h3.text-sky,
.bg-gray-dark .h4.text-sky,
.bg-gray-dark .h5.text-sky,
.bg-gray-dark .h6.text-sky {
  color: #81d3eb;
}

.bg-gray-dark p.text-oasis,
.bg-gray-dark h1.text-oasis,
.bg-gray-dark h2.text-oasis,
.bg-gray-dark h3.text-oasis,
.bg-gray-dark h4.text-oasis,
.bg-gray-dark h5.text-oasis,
.bg-gray-dark h6.text-oasis,
.bg-gray-dark .h1.text-oasis,
.bg-gray-dark .h2.text-oasis,
.bg-gray-dark .h3.text-oasis,
.bg-gray-dark .h4.text-oasis,
.bg-gray-dark .h5.text-oasis,
.bg-gray-dark .h6.text-oasis {
  color: #378dbd;
}

.bg-gray-dark p.text-azurite,
.bg-gray-dark h1.text-azurite,
.bg-gray-dark h2.text-azurite,
.bg-gray-dark h3.text-azurite,
.bg-gray-dark h4.text-azurite,
.bg-gray-dark h5.text-azurite,
.bg-gray-dark h6.text-azurite,
.bg-gray-dark .h1.text-azurite,
.bg-gray-dark .h2.text-azurite,
.bg-gray-dark .h3.text-azurite,
.bg-gray-dark .h4.text-azurite,
.bg-gray-dark .h5.text-azurite,
.bg-gray-dark .h6.text-azurite {
  color: #1e5288;
}

.bg-gray-dark p.text-midnight,
.bg-gray-dark h1.text-midnight,
.bg-gray-dark h2.text-midnight,
.bg-gray-dark h3.text-midnight,
.bg-gray-dark h4.text-midnight,
.bg-gray-dark h5.text-midnight,
.bg-gray-dark h6.text-midnight,
.bg-gray-dark .h1.text-midnight,
.bg-gray-dark .h2.text-midnight,
.bg-gray-dark .h3.text-midnight,
.bg-gray-dark .h4.text-midnight,
.bg-gray-dark .h5.text-midnight,
.bg-gray-dark .h6.text-midnight {
  color: #001c48;
}

.bg-gray-dark p.text-cool-gray,
.bg-gray-dark h1.text-cool-gray,
.bg-gray-dark h2.text-cool-gray,
.bg-gray-dark h3.text-cool-gray,
.bg-gray-dark h4.text-cool-gray,
.bg-gray-dark h5.text-cool-gray,
.bg-gray-dark h6.text-cool-gray,
.bg-gray-dark .h1.text-cool-gray,
.bg-gray-dark .h2.text-cool-gray,
.bg-gray-dark .h3.text-cool-gray,
.bg-gray-dark .h4.text-cool-gray,
.bg-gray-dark .h5.text-cool-gray,
.bg-gray-dark .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-gray-dark p.text-warm-gray,
.bg-gray-dark h1.text-warm-gray,
.bg-gray-dark h2.text-warm-gray,
.bg-gray-dark h3.text-warm-gray,
.bg-gray-dark h4.text-warm-gray,
.bg-gray-dark h5.text-warm-gray,
.bg-gray-dark h6.text-warm-gray,
.bg-gray-dark .h1.text-warm-gray,
.bg-gray-dark .h2.text-warm-gray,
.bg-gray-dark .h3.text-warm-gray,
.bg-gray-dark .h4.text-warm-gray,
.bg-gray-dark .h5.text-warm-gray,
.bg-gray-dark .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-gray-dark p.text-leaf,
.bg-gray-dark h1.text-leaf,
.bg-gray-dark h2.text-leaf,
.bg-gray-dark h3.text-leaf,
.bg-gray-dark h4.text-leaf,
.bg-gray-dark h5.text-leaf,
.bg-gray-dark h6.text-leaf,
.bg-gray-dark .h1.text-leaf,
.bg-gray-dark .h2.text-leaf,
.bg-gray-dark .h3.text-leaf,
.bg-gray-dark .h4.text-leaf,
.bg-gray-dark .h5.text-leaf,
.bg-gray-dark .h6.text-leaf {
  color: #70b865;
}

.bg-gray-dark p.text-river,
.bg-gray-dark h1.text-river,
.bg-gray-dark h2.text-river,
.bg-gray-dark h3.text-river,
.bg-gray-dark h4.text-river,
.bg-gray-dark h5.text-river,
.bg-gray-dark h6.text-river,
.bg-gray-dark .h1.text-river,
.bg-gray-dark .h2.text-river,
.bg-gray-dark .h3.text-river,
.bg-gray-dark .h4.text-river,
.bg-gray-dark .h5.text-river,
.bg-gray-dark .h6.text-river {
  color: #007d84;
}

.bg-gray-dark p.text-silver,
.bg-gray-dark h1.text-silver,
.bg-gray-dark h2.text-silver,
.bg-gray-dark h3.text-silver,
.bg-gray-dark h4.text-silver,
.bg-gray-dark h5.text-silver,
.bg-gray-dark h6.text-silver,
.bg-gray-dark .h1.text-silver,
.bg-gray-dark .h2.text-silver,
.bg-gray-dark .h3.text-silver,
.bg-gray-dark .h4.text-silver,
.bg-gray-dark .h5.text-silver,
.bg-gray-dark .h6.text-silver {
  color: #9eabae;
}

.bg-gray-dark p.text-mesa,
.bg-gray-dark h1.text-mesa,
.bg-gray-dark h2.text-mesa,
.bg-gray-dark h3.text-mesa,
.bg-gray-dark h4.text-mesa,
.bg-gray-dark h5.text-mesa,
.bg-gray-dark h6.text-mesa,
.bg-gray-dark .h1.text-mesa,
.bg-gray-dark .h2.text-mesa,
.bg-gray-dark .h3.text-mesa,
.bg-gray-dark .h4.text-mesa,
.bg-gray-dark .h5.text-mesa,
.bg-gray-dark .h6.text-mesa {
  color: #a95c42;
}

.bg-gray-dark p.text-ash,
.bg-gray-dark h1.text-ash,
.bg-gray-dark h2.text-ash,
.bg-gray-dark h3.text-ash,
.bg-gray-dark h4.text-ash,
.bg-gray-dark h5.text-ash,
.bg-gray-dark h6.text-ash,
.bg-gray-dark .h1.text-ash,
.bg-gray-dark .h2.text-ash,
.bg-gray-dark .h3.text-ash,
.bg-gray-dark .h4.text-ash,
.bg-gray-dark .h5.text-ash,
.bg-gray-dark .h6.text-ash {
  color: #403635;
}

.bg-gray-dark p.text-sage,
.bg-gray-dark h1.text-sage,
.bg-gray-dark h2.text-sage,
.bg-gray-dark h3.text-sage,
.bg-gray-dark h4.text-sage,
.bg-gray-dark h5.text-sage,
.bg-gray-dark h6.text-sage,
.bg-gray-dark .h1.text-sage,
.bg-gray-dark .h2.text-sage,
.bg-gray-dark .h3.text-sage,
.bg-gray-dark .h4.text-sage,
.bg-gray-dark .h5.text-sage,
.bg-gray-dark .h6.text-sage {
  color: #4a634e;
}

.bg-gray-dark p.text-black,
.bg-gray-dark h1.text-black,
.bg-gray-dark h2.text-black,
.bg-gray-dark h3.text-black,
.bg-gray-dark h4.text-black,
.bg-gray-dark h5.text-black,
.bg-gray-dark h6.text-black,
.bg-gray-dark .h1.text-black,
.bg-gray-dark .h2.text-black,
.bg-gray-dark .h3.text-black,
.bg-gray-dark .h4.text-black,
.bg-gray-dark .h5.text-black,
.bg-gray-dark .h6.text-black {
  color: #000;
}

.bg-gray-dark p.text-primary,
.bg-gray-dark h1.text-primary,
.bg-gray-dark h2.text-primary,
.bg-gray-dark h3.text-primary,
.bg-gray-dark h4.text-primary,
.bg-gray-dark h5.text-primary,
.bg-gray-dark h6.text-primary,
.bg-gray-dark .h1.text-primary,
.bg-gray-dark .h2.text-primary,
.bg-gray-dark .h3.text-primary,
.bg-gray-dark .h4.text-primary,
.bg-gray-dark .h5.text-primary,
.bg-gray-dark .h6.text-primary {
  color: #1e5288;
}

.bg-gray-dark p.text-secondary,
.bg-gray-dark h1.text-secondary,
.bg-gray-dark h2.text-secondary,
.bg-gray-dark h3.text-secondary,
.bg-gray-dark h4.text-secondary,
.bg-gray-dark h5.text-secondary,
.bg-gray-dark h6.text-secondary,
.bg-gray-dark .h1.text-secondary,
.bg-gray-dark .h2.text-secondary,
.bg-gray-dark .h3.text-secondary,
.bg-gray-dark .h4.text-secondary,
.bg-gray-dark .h5.text-secondary,
.bg-gray-dark .h6.text-secondary {
  color: #9eabae;
}

.bg-gray-dark p.text-success,
.bg-gray-dark h1.text-success,
.bg-gray-dark h2.text-success,
.bg-gray-dark h3.text-success,
.bg-gray-dark h4.text-success,
.bg-gray-dark h5.text-success,
.bg-gray-dark h6.text-success,
.bg-gray-dark .h1.text-success,
.bg-gray-dark .h2.text-success,
.bg-gray-dark .h3.text-success,
.bg-gray-dark .h4.text-success,
.bg-gray-dark .h5.text-success,
.bg-gray-dark .h6.text-success {
  color: #70b865;
}

.bg-gray-dark p.text-info,
.bg-gray-dark h1.text-info,
.bg-gray-dark h2.text-info,
.bg-gray-dark h3.text-info,
.bg-gray-dark h4.text-info,
.bg-gray-dark h5.text-info,
.bg-gray-dark h6.text-info,
.bg-gray-dark .h1.text-info,
.bg-gray-dark .h2.text-info,
.bg-gray-dark .h3.text-info,
.bg-gray-dark .h4.text-info,
.bg-gray-dark .h5.text-info,
.bg-gray-dark .h6.text-info {
  color: #81d3eb;
}

.bg-gray-dark p.text-warning,
.bg-gray-dark h1.text-warning,
.bg-gray-dark h2.text-warning,
.bg-gray-dark h3.text-warning,
.bg-gray-dark h4.text-warning,
.bg-gray-dark h5.text-warning,
.bg-gray-dark h6.text-warning,
.bg-gray-dark .h1.text-warning,
.bg-gray-dark .h2.text-warning,
.bg-gray-dark .h3.text-warning,
.bg-gray-dark .h4.text-warning,
.bg-gray-dark .h5.text-warning,
.bg-gray-dark .h6.text-warning {
  color: #f19e1f;
}

.bg-gray-dark p.text-danger,
.bg-gray-dark h1.text-danger,
.bg-gray-dark h2.text-danger,
.bg-gray-dark h3.text-danger,
.bg-gray-dark h4.text-danger,
.bg-gray-dark h5.text-danger,
.bg-gray-dark h6.text-danger,
.bg-gray-dark .h1.text-danger,
.bg-gray-dark .h2.text-danger,
.bg-gray-dark .h3.text-danger,
.bg-gray-dark .h4.text-danger,
.bg-gray-dark .h5.text-danger,
.bg-gray-dark .h6.text-danger {
  color: #ef4056;
}

.bg-gray-dark p.text-light,
.bg-gray-dark h1.text-light,
.bg-gray-dark h2.text-light,
.bg-gray-dark h3.text-light,
.bg-gray-dark h4.text-light,
.bg-gray-dark h5.text-light,
.bg-gray-dark h6.text-light,
.bg-gray-dark .h1.text-light,
.bg-gray-dark .h2.text-light,
.bg-gray-dark .h3.text-light,
.bg-gray-dark .h4.text-light,
.bg-gray-dark .h5.text-light,
.bg-gray-dark .h6.text-light {
  color: #dee2e6;
}

.bg-gray-dark p.text-dark,
.bg-gray-dark h1.text-dark,
.bg-gray-dark h2.text-dark,
.bg-gray-dark h3.text-dark,
.bg-gray-dark h4.text-dark,
.bg-gray-dark h5.text-dark,
.bg-gray-dark h6.text-dark,
.bg-gray-dark .h1.text-dark,
.bg-gray-dark .h2.text-dark,
.bg-gray-dark .h3.text-dark,
.bg-gray-dark .h4.text-dark,
.bg-gray-dark .h5.text-dark,
.bg-gray-dark .h6.text-dark {
  color: #343a40;
}

.bg-gray-dark p.text-primary-dark,
.bg-gray-dark h1.text-primary-dark,
.bg-gray-dark h2.text-primary-dark,
.bg-gray-dark h3.text-primary-dark,
.bg-gray-dark h4.text-primary-dark,
.bg-gray-dark h5.text-primary-dark,
.bg-gray-dark h6.text-primary-dark,
.bg-gray-dark .h1.text-primary-dark,
.bg-gray-dark .h2.text-primary-dark,
.bg-gray-dark .h3.text-primary-dark,
.bg-gray-dark .h4.text-primary-dark,
.bg-gray-dark .h5.text-primary-dark,
.bg-gray-dark .h6.text-primary-dark {
  color: #0c234b;
}

.bg-gray-dark p.text-primary-light,
.bg-gray-dark h1.text-primary-light,
.bg-gray-dark h2.text-primary-light,
.bg-gray-dark h3.text-primary-light,
.bg-gray-dark h4.text-primary-light,
.bg-gray-dark h5.text-primary-light,
.bg-gray-dark h6.text-primary-light,
.bg-gray-dark .h1.text-primary-light,
.bg-gray-dark .h2.text-primary-light,
.bg-gray-dark .h3.text-primary-light,
.bg-gray-dark .h4.text-primary-light,
.bg-gray-dark .h5.text-primary-light,
.bg-gray-dark .h6.text-primary-light {
  color: #378dbd;
}

.bg-gray-dark p.text-accent,
.bg-gray-dark h1.text-accent,
.bg-gray-dark h2.text-accent,
.bg-gray-dark h3.text-accent,
.bg-gray-dark h4.text-accent,
.bg-gray-dark h5.text-accent,
.bg-gray-dark h6.text-accent,
.bg-gray-dark .h1.text-accent,
.bg-gray-dark .h2.text-accent,
.bg-gray-dark .h3.text-accent,
.bg-gray-dark .h4.text-accent,
.bg-gray-dark .h5.text-accent,
.bg-gray-dark .h6.text-accent {
  color: #ab0520;
}

.bg-bloom {
  color: #fff;
  background-color: #ef4056;
  color: #000;
}

.bg-bloom p,
.bg-bloom h1,
.bg-bloom h2,
.bg-bloom h3,
.bg-bloom h4,
.bg-bloom h5,
.bg-bloom h6,
.bg-bloom .h1,
.bg-bloom .h2,
.bg-bloom .h3,
.bg-bloom .h4,
.bg-bloom .h5,
.bg-bloom .h6 {
  color: #000;
}

.bg-bloom p.text-blue,
.bg-bloom h1.text-blue,
.bg-bloom h2.text-blue,
.bg-bloom h3.text-blue,
.bg-bloom h4.text-blue,
.bg-bloom h5.text-blue,
.bg-bloom h6.text-blue,
.bg-bloom .h1.text-blue,
.bg-bloom .h2.text-blue,
.bg-bloom .h3.text-blue,
.bg-bloom .h4.text-blue,
.bg-bloom .h5.text-blue,
.bg-bloom .h6.text-blue {
  color: #0c234b;
}

.bg-bloom p.text-indigo,
.bg-bloom h1.text-indigo,
.bg-bloom h2.text-indigo,
.bg-bloom h3.text-indigo,
.bg-bloom h4.text-indigo,
.bg-bloom h5.text-indigo,
.bg-bloom h6.text-indigo,
.bg-bloom .h1.text-indigo,
.bg-bloom .h2.text-indigo,
.bg-bloom .h3.text-indigo,
.bg-bloom .h4.text-indigo,
.bg-bloom .h5.text-indigo,
.bg-bloom .h6.text-indigo {
  color: #6610f2;
}

.bg-bloom p.text-purple,
.bg-bloom h1.text-purple,
.bg-bloom h2.text-purple,
.bg-bloom h3.text-purple,
.bg-bloom h4.text-purple,
.bg-bloom h5.text-purple,
.bg-bloom h6.text-purple,
.bg-bloom .h1.text-purple,
.bg-bloom .h2.text-purple,
.bg-bloom .h3.text-purple,
.bg-bloom .h4.text-purple,
.bg-bloom .h5.text-purple,
.bg-bloom .h6.text-purple {
  color: #6f42c1;
}

.bg-bloom p.text-pink,
.bg-bloom h1.text-pink,
.bg-bloom h2.text-pink,
.bg-bloom h3.text-pink,
.bg-bloom h4.text-pink,
.bg-bloom h5.text-pink,
.bg-bloom h6.text-pink,
.bg-bloom .h1.text-pink,
.bg-bloom .h2.text-pink,
.bg-bloom .h3.text-pink,
.bg-bloom .h4.text-pink,
.bg-bloom .h5.text-pink,
.bg-bloom .h6.text-pink {
  color: #e83e8c;
}

.bg-bloom p.text-red,
.bg-bloom h1.text-red,
.bg-bloom h2.text-red,
.bg-bloom h3.text-red,
.bg-bloom h4.text-red,
.bg-bloom h5.text-red,
.bg-bloom h6.text-red,
.bg-bloom .h1.text-red,
.bg-bloom .h2.text-red,
.bg-bloom .h3.text-red,
.bg-bloom .h4.text-red,
.bg-bloom .h5.text-red,
.bg-bloom .h6.text-red {
  color: #ab0520;
}

.bg-bloom p.text-orange,
.bg-bloom h1.text-orange,
.bg-bloom h2.text-orange,
.bg-bloom h3.text-orange,
.bg-bloom h4.text-orange,
.bg-bloom h5.text-orange,
.bg-bloom h6.text-orange,
.bg-bloom .h1.text-orange,
.bg-bloom .h2.text-orange,
.bg-bloom .h3.text-orange,
.bg-bloom .h4.text-orange,
.bg-bloom .h5.text-orange,
.bg-bloom .h6.text-orange {
  color: #fd7e14;
}

.bg-bloom p.text-yellow,
.bg-bloom h1.text-yellow,
.bg-bloom h2.text-yellow,
.bg-bloom h3.text-yellow,
.bg-bloom h4.text-yellow,
.bg-bloom h5.text-yellow,
.bg-bloom h6.text-yellow,
.bg-bloom .h1.text-yellow,
.bg-bloom .h2.text-yellow,
.bg-bloom .h3.text-yellow,
.bg-bloom .h4.text-yellow,
.bg-bloom .h5.text-yellow,
.bg-bloom .h6.text-yellow {
  color: #ffc107;
}

.bg-bloom p.text-green,
.bg-bloom h1.text-green,
.bg-bloom h2.text-green,
.bg-bloom h3.text-green,
.bg-bloom h4.text-green,
.bg-bloom h5.text-green,
.bg-bloom h6.text-green,
.bg-bloom .h1.text-green,
.bg-bloom .h2.text-green,
.bg-bloom .h3.text-green,
.bg-bloom .h4.text-green,
.bg-bloom .h5.text-green,
.bg-bloom .h6.text-green {
  color: #28a745;
}

.bg-bloom p.text-teal,
.bg-bloom h1.text-teal,
.bg-bloom h2.text-teal,
.bg-bloom h3.text-teal,
.bg-bloom h4.text-teal,
.bg-bloom h5.text-teal,
.bg-bloom h6.text-teal,
.bg-bloom .h1.text-teal,
.bg-bloom .h2.text-teal,
.bg-bloom .h3.text-teal,
.bg-bloom .h4.text-teal,
.bg-bloom .h5.text-teal,
.bg-bloom .h6.text-teal {
  color: #20c997;
}

.bg-bloom p.text-cyan,
.bg-bloom h1.text-cyan,
.bg-bloom h2.text-cyan,
.bg-bloom h3.text-cyan,
.bg-bloom h4.text-cyan,
.bg-bloom h5.text-cyan,
.bg-bloom h6.text-cyan,
.bg-bloom .h1.text-cyan,
.bg-bloom .h2.text-cyan,
.bg-bloom .h3.text-cyan,
.bg-bloom .h4.text-cyan,
.bg-bloom .h5.text-cyan,
.bg-bloom .h6.text-cyan {
  color: #17a2b8;
}

.bg-bloom p.text-white,
.bg-bloom h1.text-white,
.bg-bloom h2.text-white,
.bg-bloom h3.text-white,
.bg-bloom h4.text-white,
.bg-bloom h5.text-white,
.bg-bloom h6.text-white,
.bg-bloom .h1.text-white,
.bg-bloom .h2.text-white,
.bg-bloom .h3.text-white,
.bg-bloom .h4.text-white,
.bg-bloom .h5.text-white,
.bg-bloom .h6.text-white {
  color: #fff;
}

.bg-bloom p.text-gray,
.bg-bloom h1.text-gray,
.bg-bloom h2.text-gray,
.bg-bloom h3.text-gray,
.bg-bloom h4.text-gray,
.bg-bloom h5.text-gray,
.bg-bloom h6.text-gray,
.bg-bloom .h1.text-gray,
.bg-bloom .h2.text-gray,
.bg-bloom .h3.text-gray,
.bg-bloom .h4.text-gray,
.bg-bloom .h5.text-gray,
.bg-bloom .h6.text-gray {
  color: #6c757d;
}

.bg-bloom p.text-gray-dark,
.bg-bloom h1.text-gray-dark,
.bg-bloom h2.text-gray-dark,
.bg-bloom h3.text-gray-dark,
.bg-bloom h4.text-gray-dark,
.bg-bloom h5.text-gray-dark,
.bg-bloom h6.text-gray-dark,
.bg-bloom .h1.text-gray-dark,
.bg-bloom .h2.text-gray-dark,
.bg-bloom .h3.text-gray-dark,
.bg-bloom .h4.text-gray-dark,
.bg-bloom .h5.text-gray-dark,
.bg-bloom .h6.text-gray-dark {
  color: #343a40;
}

.bg-bloom p.text-bloom,
.bg-bloom h1.text-bloom,
.bg-bloom h2.text-bloom,
.bg-bloom h3.text-bloom,
.bg-bloom h4.text-bloom,
.bg-bloom h5.text-bloom,
.bg-bloom h6.text-bloom,
.bg-bloom .h1.text-bloom,
.bg-bloom .h2.text-bloom,
.bg-bloom .h3.text-bloom,
.bg-bloom .h4.text-bloom,
.bg-bloom .h5.text-bloom,
.bg-bloom .h6.text-bloom {
  color: #ef4056;
}

.bg-bloom p.text-chili,
.bg-bloom h1.text-chili,
.bg-bloom h2.text-chili,
.bg-bloom h3.text-chili,
.bg-bloom h4.text-chili,
.bg-bloom h5.text-chili,
.bg-bloom h6.text-chili,
.bg-bloom .h1.text-chili,
.bg-bloom .h2.text-chili,
.bg-bloom .h3.text-chili,
.bg-bloom .h4.text-chili,
.bg-bloom .h5.text-chili,
.bg-bloom .h6.text-chili {
  color: #8b0015;
}

.bg-bloom p.text-sky,
.bg-bloom h1.text-sky,
.bg-bloom h2.text-sky,
.bg-bloom h3.text-sky,
.bg-bloom h4.text-sky,
.bg-bloom h5.text-sky,
.bg-bloom h6.text-sky,
.bg-bloom .h1.text-sky,
.bg-bloom .h2.text-sky,
.bg-bloom .h3.text-sky,
.bg-bloom .h4.text-sky,
.bg-bloom .h5.text-sky,
.bg-bloom .h6.text-sky {
  color: #81d3eb;
}

.bg-bloom p.text-oasis,
.bg-bloom h1.text-oasis,
.bg-bloom h2.text-oasis,
.bg-bloom h3.text-oasis,
.bg-bloom h4.text-oasis,
.bg-bloom h5.text-oasis,
.bg-bloom h6.text-oasis,
.bg-bloom .h1.text-oasis,
.bg-bloom .h2.text-oasis,
.bg-bloom .h3.text-oasis,
.bg-bloom .h4.text-oasis,
.bg-bloom .h5.text-oasis,
.bg-bloom .h6.text-oasis {
  color: #378dbd;
}

.bg-bloom p.text-azurite,
.bg-bloom h1.text-azurite,
.bg-bloom h2.text-azurite,
.bg-bloom h3.text-azurite,
.bg-bloom h4.text-azurite,
.bg-bloom h5.text-azurite,
.bg-bloom h6.text-azurite,
.bg-bloom .h1.text-azurite,
.bg-bloom .h2.text-azurite,
.bg-bloom .h3.text-azurite,
.bg-bloom .h4.text-azurite,
.bg-bloom .h5.text-azurite,
.bg-bloom .h6.text-azurite {
  color: #1e5288;
}

.bg-bloom p.text-midnight,
.bg-bloom h1.text-midnight,
.bg-bloom h2.text-midnight,
.bg-bloom h3.text-midnight,
.bg-bloom h4.text-midnight,
.bg-bloom h5.text-midnight,
.bg-bloom h6.text-midnight,
.bg-bloom .h1.text-midnight,
.bg-bloom .h2.text-midnight,
.bg-bloom .h3.text-midnight,
.bg-bloom .h4.text-midnight,
.bg-bloom .h5.text-midnight,
.bg-bloom .h6.text-midnight {
  color: #001c48;
}

.bg-bloom p.text-cool-gray,
.bg-bloom h1.text-cool-gray,
.bg-bloom h2.text-cool-gray,
.bg-bloom h3.text-cool-gray,
.bg-bloom h4.text-cool-gray,
.bg-bloom h5.text-cool-gray,
.bg-bloom h6.text-cool-gray,
.bg-bloom .h1.text-cool-gray,
.bg-bloom .h2.text-cool-gray,
.bg-bloom .h3.text-cool-gray,
.bg-bloom .h4.text-cool-gray,
.bg-bloom .h5.text-cool-gray,
.bg-bloom .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-bloom p.text-warm-gray,
.bg-bloom h1.text-warm-gray,
.bg-bloom h2.text-warm-gray,
.bg-bloom h3.text-warm-gray,
.bg-bloom h4.text-warm-gray,
.bg-bloom h5.text-warm-gray,
.bg-bloom h6.text-warm-gray,
.bg-bloom .h1.text-warm-gray,
.bg-bloom .h2.text-warm-gray,
.bg-bloom .h3.text-warm-gray,
.bg-bloom .h4.text-warm-gray,
.bg-bloom .h5.text-warm-gray,
.bg-bloom .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-bloom p.text-leaf,
.bg-bloom h1.text-leaf,
.bg-bloom h2.text-leaf,
.bg-bloom h3.text-leaf,
.bg-bloom h4.text-leaf,
.bg-bloom h5.text-leaf,
.bg-bloom h6.text-leaf,
.bg-bloom .h1.text-leaf,
.bg-bloom .h2.text-leaf,
.bg-bloom .h3.text-leaf,
.bg-bloom .h4.text-leaf,
.bg-bloom .h5.text-leaf,
.bg-bloom .h6.text-leaf {
  color: #70b865;
}

.bg-bloom p.text-river,
.bg-bloom h1.text-river,
.bg-bloom h2.text-river,
.bg-bloom h3.text-river,
.bg-bloom h4.text-river,
.bg-bloom h5.text-river,
.bg-bloom h6.text-river,
.bg-bloom .h1.text-river,
.bg-bloom .h2.text-river,
.bg-bloom .h3.text-river,
.bg-bloom .h4.text-river,
.bg-bloom .h5.text-river,
.bg-bloom .h6.text-river {
  color: #007d84;
}

.bg-bloom p.text-silver,
.bg-bloom h1.text-silver,
.bg-bloom h2.text-silver,
.bg-bloom h3.text-silver,
.bg-bloom h4.text-silver,
.bg-bloom h5.text-silver,
.bg-bloom h6.text-silver,
.bg-bloom .h1.text-silver,
.bg-bloom .h2.text-silver,
.bg-bloom .h3.text-silver,
.bg-bloom .h4.text-silver,
.bg-bloom .h5.text-silver,
.bg-bloom .h6.text-silver {
  color: #9eabae;
}

.bg-bloom p.text-mesa,
.bg-bloom h1.text-mesa,
.bg-bloom h2.text-mesa,
.bg-bloom h3.text-mesa,
.bg-bloom h4.text-mesa,
.bg-bloom h5.text-mesa,
.bg-bloom h6.text-mesa,
.bg-bloom .h1.text-mesa,
.bg-bloom .h2.text-mesa,
.bg-bloom .h3.text-mesa,
.bg-bloom .h4.text-mesa,
.bg-bloom .h5.text-mesa,
.bg-bloom .h6.text-mesa {
  color: #a95c42;
}

.bg-bloom p.text-ash,
.bg-bloom h1.text-ash,
.bg-bloom h2.text-ash,
.bg-bloom h3.text-ash,
.bg-bloom h4.text-ash,
.bg-bloom h5.text-ash,
.bg-bloom h6.text-ash,
.bg-bloom .h1.text-ash,
.bg-bloom .h2.text-ash,
.bg-bloom .h3.text-ash,
.bg-bloom .h4.text-ash,
.bg-bloom .h5.text-ash,
.bg-bloom .h6.text-ash {
  color: #403635;
}

.bg-bloom p.text-sage,
.bg-bloom h1.text-sage,
.bg-bloom h2.text-sage,
.bg-bloom h3.text-sage,
.bg-bloom h4.text-sage,
.bg-bloom h5.text-sage,
.bg-bloom h6.text-sage,
.bg-bloom .h1.text-sage,
.bg-bloom .h2.text-sage,
.bg-bloom .h3.text-sage,
.bg-bloom .h4.text-sage,
.bg-bloom .h5.text-sage,
.bg-bloom .h6.text-sage {
  color: #4a634e;
}

.bg-bloom p.text-black,
.bg-bloom h1.text-black,
.bg-bloom h2.text-black,
.bg-bloom h3.text-black,
.bg-bloom h4.text-black,
.bg-bloom h5.text-black,
.bg-bloom h6.text-black,
.bg-bloom .h1.text-black,
.bg-bloom .h2.text-black,
.bg-bloom .h3.text-black,
.bg-bloom .h4.text-black,
.bg-bloom .h5.text-black,
.bg-bloom .h6.text-black {
  color: #000;
}

.bg-bloom p.text-primary,
.bg-bloom h1.text-primary,
.bg-bloom h2.text-primary,
.bg-bloom h3.text-primary,
.bg-bloom h4.text-primary,
.bg-bloom h5.text-primary,
.bg-bloom h6.text-primary,
.bg-bloom .h1.text-primary,
.bg-bloom .h2.text-primary,
.bg-bloom .h3.text-primary,
.bg-bloom .h4.text-primary,
.bg-bloom .h5.text-primary,
.bg-bloom .h6.text-primary {
  color: #1e5288;
}

.bg-bloom p.text-secondary,
.bg-bloom h1.text-secondary,
.bg-bloom h2.text-secondary,
.bg-bloom h3.text-secondary,
.bg-bloom h4.text-secondary,
.bg-bloom h5.text-secondary,
.bg-bloom h6.text-secondary,
.bg-bloom .h1.text-secondary,
.bg-bloom .h2.text-secondary,
.bg-bloom .h3.text-secondary,
.bg-bloom .h4.text-secondary,
.bg-bloom .h5.text-secondary,
.bg-bloom .h6.text-secondary {
  color: #9eabae;
}

.bg-bloom p.text-success,
.bg-bloom h1.text-success,
.bg-bloom h2.text-success,
.bg-bloom h3.text-success,
.bg-bloom h4.text-success,
.bg-bloom h5.text-success,
.bg-bloom h6.text-success,
.bg-bloom .h1.text-success,
.bg-bloom .h2.text-success,
.bg-bloom .h3.text-success,
.bg-bloom .h4.text-success,
.bg-bloom .h5.text-success,
.bg-bloom .h6.text-success {
  color: #70b865;
}

.bg-bloom p.text-info,
.bg-bloom h1.text-info,
.bg-bloom h2.text-info,
.bg-bloom h3.text-info,
.bg-bloom h4.text-info,
.bg-bloom h5.text-info,
.bg-bloom h6.text-info,
.bg-bloom .h1.text-info,
.bg-bloom .h2.text-info,
.bg-bloom .h3.text-info,
.bg-bloom .h4.text-info,
.bg-bloom .h5.text-info,
.bg-bloom .h6.text-info {
  color: #81d3eb;
}

.bg-bloom p.text-warning,
.bg-bloom h1.text-warning,
.bg-bloom h2.text-warning,
.bg-bloom h3.text-warning,
.bg-bloom h4.text-warning,
.bg-bloom h5.text-warning,
.bg-bloom h6.text-warning,
.bg-bloom .h1.text-warning,
.bg-bloom .h2.text-warning,
.bg-bloom .h3.text-warning,
.bg-bloom .h4.text-warning,
.bg-bloom .h5.text-warning,
.bg-bloom .h6.text-warning {
  color: #f19e1f;
}

.bg-bloom p.text-danger,
.bg-bloom h1.text-danger,
.bg-bloom h2.text-danger,
.bg-bloom h3.text-danger,
.bg-bloom h4.text-danger,
.bg-bloom h5.text-danger,
.bg-bloom h6.text-danger,
.bg-bloom .h1.text-danger,
.bg-bloom .h2.text-danger,
.bg-bloom .h3.text-danger,
.bg-bloom .h4.text-danger,
.bg-bloom .h5.text-danger,
.bg-bloom .h6.text-danger {
  color: #ef4056;
}

.bg-bloom p.text-light,
.bg-bloom h1.text-light,
.bg-bloom h2.text-light,
.bg-bloom h3.text-light,
.bg-bloom h4.text-light,
.bg-bloom h5.text-light,
.bg-bloom h6.text-light,
.bg-bloom .h1.text-light,
.bg-bloom .h2.text-light,
.bg-bloom .h3.text-light,
.bg-bloom .h4.text-light,
.bg-bloom .h5.text-light,
.bg-bloom .h6.text-light {
  color: #dee2e6;
}

.bg-bloom p.text-dark,
.bg-bloom h1.text-dark,
.bg-bloom h2.text-dark,
.bg-bloom h3.text-dark,
.bg-bloom h4.text-dark,
.bg-bloom h5.text-dark,
.bg-bloom h6.text-dark,
.bg-bloom .h1.text-dark,
.bg-bloom .h2.text-dark,
.bg-bloom .h3.text-dark,
.bg-bloom .h4.text-dark,
.bg-bloom .h5.text-dark,
.bg-bloom .h6.text-dark {
  color: #343a40;
}

.bg-bloom p.text-primary-dark,
.bg-bloom h1.text-primary-dark,
.bg-bloom h2.text-primary-dark,
.bg-bloom h3.text-primary-dark,
.bg-bloom h4.text-primary-dark,
.bg-bloom h5.text-primary-dark,
.bg-bloom h6.text-primary-dark,
.bg-bloom .h1.text-primary-dark,
.bg-bloom .h2.text-primary-dark,
.bg-bloom .h3.text-primary-dark,
.bg-bloom .h4.text-primary-dark,
.bg-bloom .h5.text-primary-dark,
.bg-bloom .h6.text-primary-dark {
  color: #0c234b;
}

.bg-bloom p.text-primary-light,
.bg-bloom h1.text-primary-light,
.bg-bloom h2.text-primary-light,
.bg-bloom h3.text-primary-light,
.bg-bloom h4.text-primary-light,
.bg-bloom h5.text-primary-light,
.bg-bloom h6.text-primary-light,
.bg-bloom .h1.text-primary-light,
.bg-bloom .h2.text-primary-light,
.bg-bloom .h3.text-primary-light,
.bg-bloom .h4.text-primary-light,
.bg-bloom .h5.text-primary-light,
.bg-bloom .h6.text-primary-light {
  color: #378dbd;
}

.bg-bloom p.text-accent,
.bg-bloom h1.text-accent,
.bg-bloom h2.text-accent,
.bg-bloom h3.text-accent,
.bg-bloom h4.text-accent,
.bg-bloom h5.text-accent,
.bg-bloom h6.text-accent,
.bg-bloom .h1.text-accent,
.bg-bloom .h2.text-accent,
.bg-bloom .h3.text-accent,
.bg-bloom .h4.text-accent,
.bg-bloom .h5.text-accent,
.bg-bloom .h6.text-accent {
  color: #ab0520;
}

.bg-chili {
  color: #fff;
  background-color: #8b0015;
  color: #fff;
}

.bg-chili p,
.bg-chili h1,
.bg-chili h2,
.bg-chili h3,
.bg-chili h4,
.bg-chili h5,
.bg-chili h6,
.bg-chili .h1,
.bg-chili .h2,
.bg-chili .h3,
.bg-chili .h4,
.bg-chili .h5,
.bg-chili .h6 {
  color: #fff;
}

.bg-chili p.text-blue,
.bg-chili h1.text-blue,
.bg-chili h2.text-blue,
.bg-chili h3.text-blue,
.bg-chili h4.text-blue,
.bg-chili h5.text-blue,
.bg-chili h6.text-blue,
.bg-chili .h1.text-blue,
.bg-chili .h2.text-blue,
.bg-chili .h3.text-blue,
.bg-chili .h4.text-blue,
.bg-chili .h5.text-blue,
.bg-chili .h6.text-blue {
  color: #0c234b;
}

.bg-chili p.text-indigo,
.bg-chili h1.text-indigo,
.bg-chili h2.text-indigo,
.bg-chili h3.text-indigo,
.bg-chili h4.text-indigo,
.bg-chili h5.text-indigo,
.bg-chili h6.text-indigo,
.bg-chili .h1.text-indigo,
.bg-chili .h2.text-indigo,
.bg-chili .h3.text-indigo,
.bg-chili .h4.text-indigo,
.bg-chili .h5.text-indigo,
.bg-chili .h6.text-indigo {
  color: #6610f2;
}

.bg-chili p.text-purple,
.bg-chili h1.text-purple,
.bg-chili h2.text-purple,
.bg-chili h3.text-purple,
.bg-chili h4.text-purple,
.bg-chili h5.text-purple,
.bg-chili h6.text-purple,
.bg-chili .h1.text-purple,
.bg-chili .h2.text-purple,
.bg-chili .h3.text-purple,
.bg-chili .h4.text-purple,
.bg-chili .h5.text-purple,
.bg-chili .h6.text-purple {
  color: #6f42c1;
}

.bg-chili p.text-pink,
.bg-chili h1.text-pink,
.bg-chili h2.text-pink,
.bg-chili h3.text-pink,
.bg-chili h4.text-pink,
.bg-chili h5.text-pink,
.bg-chili h6.text-pink,
.bg-chili .h1.text-pink,
.bg-chili .h2.text-pink,
.bg-chili .h3.text-pink,
.bg-chili .h4.text-pink,
.bg-chili .h5.text-pink,
.bg-chili .h6.text-pink {
  color: #e83e8c;
}

.bg-chili p.text-red,
.bg-chili h1.text-red,
.bg-chili h2.text-red,
.bg-chili h3.text-red,
.bg-chili h4.text-red,
.bg-chili h5.text-red,
.bg-chili h6.text-red,
.bg-chili .h1.text-red,
.bg-chili .h2.text-red,
.bg-chili .h3.text-red,
.bg-chili .h4.text-red,
.bg-chili .h5.text-red,
.bg-chili .h6.text-red {
  color: #ab0520;
}

.bg-chili p.text-orange,
.bg-chili h1.text-orange,
.bg-chili h2.text-orange,
.bg-chili h3.text-orange,
.bg-chili h4.text-orange,
.bg-chili h5.text-orange,
.bg-chili h6.text-orange,
.bg-chili .h1.text-orange,
.bg-chili .h2.text-orange,
.bg-chili .h3.text-orange,
.bg-chili .h4.text-orange,
.bg-chili .h5.text-orange,
.bg-chili .h6.text-orange {
  color: #fd7e14;
}

.bg-chili p.text-yellow,
.bg-chili h1.text-yellow,
.bg-chili h2.text-yellow,
.bg-chili h3.text-yellow,
.bg-chili h4.text-yellow,
.bg-chili h5.text-yellow,
.bg-chili h6.text-yellow,
.bg-chili .h1.text-yellow,
.bg-chili .h2.text-yellow,
.bg-chili .h3.text-yellow,
.bg-chili .h4.text-yellow,
.bg-chili .h5.text-yellow,
.bg-chili .h6.text-yellow {
  color: #ffc107;
}

.bg-chili p.text-green,
.bg-chili h1.text-green,
.bg-chili h2.text-green,
.bg-chili h3.text-green,
.bg-chili h4.text-green,
.bg-chili h5.text-green,
.bg-chili h6.text-green,
.bg-chili .h1.text-green,
.bg-chili .h2.text-green,
.bg-chili .h3.text-green,
.bg-chili .h4.text-green,
.bg-chili .h5.text-green,
.bg-chili .h6.text-green {
  color: #28a745;
}

.bg-chili p.text-teal,
.bg-chili h1.text-teal,
.bg-chili h2.text-teal,
.bg-chili h3.text-teal,
.bg-chili h4.text-teal,
.bg-chili h5.text-teal,
.bg-chili h6.text-teal,
.bg-chili .h1.text-teal,
.bg-chili .h2.text-teal,
.bg-chili .h3.text-teal,
.bg-chili .h4.text-teal,
.bg-chili .h5.text-teal,
.bg-chili .h6.text-teal {
  color: #20c997;
}

.bg-chili p.text-cyan,
.bg-chili h1.text-cyan,
.bg-chili h2.text-cyan,
.bg-chili h3.text-cyan,
.bg-chili h4.text-cyan,
.bg-chili h5.text-cyan,
.bg-chili h6.text-cyan,
.bg-chili .h1.text-cyan,
.bg-chili .h2.text-cyan,
.bg-chili .h3.text-cyan,
.bg-chili .h4.text-cyan,
.bg-chili .h5.text-cyan,
.bg-chili .h6.text-cyan {
  color: #17a2b8;
}

.bg-chili p.text-white,
.bg-chili h1.text-white,
.bg-chili h2.text-white,
.bg-chili h3.text-white,
.bg-chili h4.text-white,
.bg-chili h5.text-white,
.bg-chili h6.text-white,
.bg-chili .h1.text-white,
.bg-chili .h2.text-white,
.bg-chili .h3.text-white,
.bg-chili .h4.text-white,
.bg-chili .h5.text-white,
.bg-chili .h6.text-white {
  color: #fff;
}

.bg-chili p.text-gray,
.bg-chili h1.text-gray,
.bg-chili h2.text-gray,
.bg-chili h3.text-gray,
.bg-chili h4.text-gray,
.bg-chili h5.text-gray,
.bg-chili h6.text-gray,
.bg-chili .h1.text-gray,
.bg-chili .h2.text-gray,
.bg-chili .h3.text-gray,
.bg-chili .h4.text-gray,
.bg-chili .h5.text-gray,
.bg-chili .h6.text-gray {
  color: #6c757d;
}

.bg-chili p.text-gray-dark,
.bg-chili h1.text-gray-dark,
.bg-chili h2.text-gray-dark,
.bg-chili h3.text-gray-dark,
.bg-chili h4.text-gray-dark,
.bg-chili h5.text-gray-dark,
.bg-chili h6.text-gray-dark,
.bg-chili .h1.text-gray-dark,
.bg-chili .h2.text-gray-dark,
.bg-chili .h3.text-gray-dark,
.bg-chili .h4.text-gray-dark,
.bg-chili .h5.text-gray-dark,
.bg-chili .h6.text-gray-dark {
  color: #343a40;
}

.bg-chili p.text-bloom,
.bg-chili h1.text-bloom,
.bg-chili h2.text-bloom,
.bg-chili h3.text-bloom,
.bg-chili h4.text-bloom,
.bg-chili h5.text-bloom,
.bg-chili h6.text-bloom,
.bg-chili .h1.text-bloom,
.bg-chili .h2.text-bloom,
.bg-chili .h3.text-bloom,
.bg-chili .h4.text-bloom,
.bg-chili .h5.text-bloom,
.bg-chili .h6.text-bloom {
  color: #ef4056;
}

.bg-chili p.text-chili,
.bg-chili h1.text-chili,
.bg-chili h2.text-chili,
.bg-chili h3.text-chili,
.bg-chili h4.text-chili,
.bg-chili h5.text-chili,
.bg-chili h6.text-chili,
.bg-chili .h1.text-chili,
.bg-chili .h2.text-chili,
.bg-chili .h3.text-chili,
.bg-chili .h4.text-chili,
.bg-chili .h5.text-chili,
.bg-chili .h6.text-chili {
  color: #8b0015;
}

.bg-chili p.text-sky,
.bg-chili h1.text-sky,
.bg-chili h2.text-sky,
.bg-chili h3.text-sky,
.bg-chili h4.text-sky,
.bg-chili h5.text-sky,
.bg-chili h6.text-sky,
.bg-chili .h1.text-sky,
.bg-chili .h2.text-sky,
.bg-chili .h3.text-sky,
.bg-chili .h4.text-sky,
.bg-chili .h5.text-sky,
.bg-chili .h6.text-sky {
  color: #81d3eb;
}

.bg-chili p.text-oasis,
.bg-chili h1.text-oasis,
.bg-chili h2.text-oasis,
.bg-chili h3.text-oasis,
.bg-chili h4.text-oasis,
.bg-chili h5.text-oasis,
.bg-chili h6.text-oasis,
.bg-chili .h1.text-oasis,
.bg-chili .h2.text-oasis,
.bg-chili .h3.text-oasis,
.bg-chili .h4.text-oasis,
.bg-chili .h5.text-oasis,
.bg-chili .h6.text-oasis {
  color: #378dbd;
}

.bg-chili p.text-azurite,
.bg-chili h1.text-azurite,
.bg-chili h2.text-azurite,
.bg-chili h3.text-azurite,
.bg-chili h4.text-azurite,
.bg-chili h5.text-azurite,
.bg-chili h6.text-azurite,
.bg-chili .h1.text-azurite,
.bg-chili .h2.text-azurite,
.bg-chili .h3.text-azurite,
.bg-chili .h4.text-azurite,
.bg-chili .h5.text-azurite,
.bg-chili .h6.text-azurite {
  color: #1e5288;
}

.bg-chili p.text-midnight,
.bg-chili h1.text-midnight,
.bg-chili h2.text-midnight,
.bg-chili h3.text-midnight,
.bg-chili h4.text-midnight,
.bg-chili h5.text-midnight,
.bg-chili h6.text-midnight,
.bg-chili .h1.text-midnight,
.bg-chili .h2.text-midnight,
.bg-chili .h3.text-midnight,
.bg-chili .h4.text-midnight,
.bg-chili .h5.text-midnight,
.bg-chili .h6.text-midnight {
  color: #001c48;
}

.bg-chili p.text-cool-gray,
.bg-chili h1.text-cool-gray,
.bg-chili h2.text-cool-gray,
.bg-chili h3.text-cool-gray,
.bg-chili h4.text-cool-gray,
.bg-chili h5.text-cool-gray,
.bg-chili h6.text-cool-gray,
.bg-chili .h1.text-cool-gray,
.bg-chili .h2.text-cool-gray,
.bg-chili .h3.text-cool-gray,
.bg-chili .h4.text-cool-gray,
.bg-chili .h5.text-cool-gray,
.bg-chili .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-chili p.text-warm-gray,
.bg-chili h1.text-warm-gray,
.bg-chili h2.text-warm-gray,
.bg-chili h3.text-warm-gray,
.bg-chili h4.text-warm-gray,
.bg-chili h5.text-warm-gray,
.bg-chili h6.text-warm-gray,
.bg-chili .h1.text-warm-gray,
.bg-chili .h2.text-warm-gray,
.bg-chili .h3.text-warm-gray,
.bg-chili .h4.text-warm-gray,
.bg-chili .h5.text-warm-gray,
.bg-chili .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-chili p.text-leaf,
.bg-chili h1.text-leaf,
.bg-chili h2.text-leaf,
.bg-chili h3.text-leaf,
.bg-chili h4.text-leaf,
.bg-chili h5.text-leaf,
.bg-chili h6.text-leaf,
.bg-chili .h1.text-leaf,
.bg-chili .h2.text-leaf,
.bg-chili .h3.text-leaf,
.bg-chili .h4.text-leaf,
.bg-chili .h5.text-leaf,
.bg-chili .h6.text-leaf {
  color: #70b865;
}

.bg-chili p.text-river,
.bg-chili h1.text-river,
.bg-chili h2.text-river,
.bg-chili h3.text-river,
.bg-chili h4.text-river,
.bg-chili h5.text-river,
.bg-chili h6.text-river,
.bg-chili .h1.text-river,
.bg-chili .h2.text-river,
.bg-chili .h3.text-river,
.bg-chili .h4.text-river,
.bg-chili .h5.text-river,
.bg-chili .h6.text-river {
  color: #007d84;
}

.bg-chili p.text-silver,
.bg-chili h1.text-silver,
.bg-chili h2.text-silver,
.bg-chili h3.text-silver,
.bg-chili h4.text-silver,
.bg-chili h5.text-silver,
.bg-chili h6.text-silver,
.bg-chili .h1.text-silver,
.bg-chili .h2.text-silver,
.bg-chili .h3.text-silver,
.bg-chili .h4.text-silver,
.bg-chili .h5.text-silver,
.bg-chili .h6.text-silver {
  color: #9eabae;
}

.bg-chili p.text-mesa,
.bg-chili h1.text-mesa,
.bg-chili h2.text-mesa,
.bg-chili h3.text-mesa,
.bg-chili h4.text-mesa,
.bg-chili h5.text-mesa,
.bg-chili h6.text-mesa,
.bg-chili .h1.text-mesa,
.bg-chili .h2.text-mesa,
.bg-chili .h3.text-mesa,
.bg-chili .h4.text-mesa,
.bg-chili .h5.text-mesa,
.bg-chili .h6.text-mesa {
  color: #a95c42;
}

.bg-chili p.text-ash,
.bg-chili h1.text-ash,
.bg-chili h2.text-ash,
.bg-chili h3.text-ash,
.bg-chili h4.text-ash,
.bg-chili h5.text-ash,
.bg-chili h6.text-ash,
.bg-chili .h1.text-ash,
.bg-chili .h2.text-ash,
.bg-chili .h3.text-ash,
.bg-chili .h4.text-ash,
.bg-chili .h5.text-ash,
.bg-chili .h6.text-ash {
  color: #403635;
}

.bg-chili p.text-sage,
.bg-chili h1.text-sage,
.bg-chili h2.text-sage,
.bg-chili h3.text-sage,
.bg-chili h4.text-sage,
.bg-chili h5.text-sage,
.bg-chili h6.text-sage,
.bg-chili .h1.text-sage,
.bg-chili .h2.text-sage,
.bg-chili .h3.text-sage,
.bg-chili .h4.text-sage,
.bg-chili .h5.text-sage,
.bg-chili .h6.text-sage {
  color: #4a634e;
}

.bg-chili p.text-black,
.bg-chili h1.text-black,
.bg-chili h2.text-black,
.bg-chili h3.text-black,
.bg-chili h4.text-black,
.bg-chili h5.text-black,
.bg-chili h6.text-black,
.bg-chili .h1.text-black,
.bg-chili .h2.text-black,
.bg-chili .h3.text-black,
.bg-chili .h4.text-black,
.bg-chili .h5.text-black,
.bg-chili .h6.text-black {
  color: #000;
}

.bg-chili p.text-primary,
.bg-chili h1.text-primary,
.bg-chili h2.text-primary,
.bg-chili h3.text-primary,
.bg-chili h4.text-primary,
.bg-chili h5.text-primary,
.bg-chili h6.text-primary,
.bg-chili .h1.text-primary,
.bg-chili .h2.text-primary,
.bg-chili .h3.text-primary,
.bg-chili .h4.text-primary,
.bg-chili .h5.text-primary,
.bg-chili .h6.text-primary {
  color: #1e5288;
}

.bg-chili p.text-secondary,
.bg-chili h1.text-secondary,
.bg-chili h2.text-secondary,
.bg-chili h3.text-secondary,
.bg-chili h4.text-secondary,
.bg-chili h5.text-secondary,
.bg-chili h6.text-secondary,
.bg-chili .h1.text-secondary,
.bg-chili .h2.text-secondary,
.bg-chili .h3.text-secondary,
.bg-chili .h4.text-secondary,
.bg-chili .h5.text-secondary,
.bg-chili .h6.text-secondary {
  color: #9eabae;
}

.bg-chili p.text-success,
.bg-chili h1.text-success,
.bg-chili h2.text-success,
.bg-chili h3.text-success,
.bg-chili h4.text-success,
.bg-chili h5.text-success,
.bg-chili h6.text-success,
.bg-chili .h1.text-success,
.bg-chili .h2.text-success,
.bg-chili .h3.text-success,
.bg-chili .h4.text-success,
.bg-chili .h5.text-success,
.bg-chili .h6.text-success {
  color: #70b865;
}

.bg-chili p.text-info,
.bg-chili h1.text-info,
.bg-chili h2.text-info,
.bg-chili h3.text-info,
.bg-chili h4.text-info,
.bg-chili h5.text-info,
.bg-chili h6.text-info,
.bg-chili .h1.text-info,
.bg-chili .h2.text-info,
.bg-chili .h3.text-info,
.bg-chili .h4.text-info,
.bg-chili .h5.text-info,
.bg-chili .h6.text-info {
  color: #81d3eb;
}

.bg-chili p.text-warning,
.bg-chili h1.text-warning,
.bg-chili h2.text-warning,
.bg-chili h3.text-warning,
.bg-chili h4.text-warning,
.bg-chili h5.text-warning,
.bg-chili h6.text-warning,
.bg-chili .h1.text-warning,
.bg-chili .h2.text-warning,
.bg-chili .h3.text-warning,
.bg-chili .h4.text-warning,
.bg-chili .h5.text-warning,
.bg-chili .h6.text-warning {
  color: #f19e1f;
}

.bg-chili p.text-danger,
.bg-chili h1.text-danger,
.bg-chili h2.text-danger,
.bg-chili h3.text-danger,
.bg-chili h4.text-danger,
.bg-chili h5.text-danger,
.bg-chili h6.text-danger,
.bg-chili .h1.text-danger,
.bg-chili .h2.text-danger,
.bg-chili .h3.text-danger,
.bg-chili .h4.text-danger,
.bg-chili .h5.text-danger,
.bg-chili .h6.text-danger {
  color: #ef4056;
}

.bg-chili p.text-light,
.bg-chili h1.text-light,
.bg-chili h2.text-light,
.bg-chili h3.text-light,
.bg-chili h4.text-light,
.bg-chili h5.text-light,
.bg-chili h6.text-light,
.bg-chili .h1.text-light,
.bg-chili .h2.text-light,
.bg-chili .h3.text-light,
.bg-chili .h4.text-light,
.bg-chili .h5.text-light,
.bg-chili .h6.text-light {
  color: #dee2e6;
}

.bg-chili p.text-dark,
.bg-chili h1.text-dark,
.bg-chili h2.text-dark,
.bg-chili h3.text-dark,
.bg-chili h4.text-dark,
.bg-chili h5.text-dark,
.bg-chili h6.text-dark,
.bg-chili .h1.text-dark,
.bg-chili .h2.text-dark,
.bg-chili .h3.text-dark,
.bg-chili .h4.text-dark,
.bg-chili .h5.text-dark,
.bg-chili .h6.text-dark {
  color: #343a40;
}

.bg-chili p.text-primary-dark,
.bg-chili h1.text-primary-dark,
.bg-chili h2.text-primary-dark,
.bg-chili h3.text-primary-dark,
.bg-chili h4.text-primary-dark,
.bg-chili h5.text-primary-dark,
.bg-chili h6.text-primary-dark,
.bg-chili .h1.text-primary-dark,
.bg-chili .h2.text-primary-dark,
.bg-chili .h3.text-primary-dark,
.bg-chili .h4.text-primary-dark,
.bg-chili .h5.text-primary-dark,
.bg-chili .h6.text-primary-dark {
  color: #0c234b;
}

.bg-chili p.text-primary-light,
.bg-chili h1.text-primary-light,
.bg-chili h2.text-primary-light,
.bg-chili h3.text-primary-light,
.bg-chili h4.text-primary-light,
.bg-chili h5.text-primary-light,
.bg-chili h6.text-primary-light,
.bg-chili .h1.text-primary-light,
.bg-chili .h2.text-primary-light,
.bg-chili .h3.text-primary-light,
.bg-chili .h4.text-primary-light,
.bg-chili .h5.text-primary-light,
.bg-chili .h6.text-primary-light {
  color: #378dbd;
}

.bg-chili p.text-accent,
.bg-chili h1.text-accent,
.bg-chili h2.text-accent,
.bg-chili h3.text-accent,
.bg-chili h4.text-accent,
.bg-chili h5.text-accent,
.bg-chili h6.text-accent,
.bg-chili .h1.text-accent,
.bg-chili .h2.text-accent,
.bg-chili .h3.text-accent,
.bg-chili .h4.text-accent,
.bg-chili .h5.text-accent,
.bg-chili .h6.text-accent {
  color: #ab0520;
}

.bg-sky {
  color: #fff;
  background-color: #81d3eb;
  color: #001c48;
}

.bg-sky p,
.bg-sky h1,
.bg-sky h2,
.bg-sky h3,
.bg-sky h4,
.bg-sky h5,
.bg-sky h6,
.bg-sky .h1,
.bg-sky .h2,
.bg-sky .h3,
.bg-sky .h4,
.bg-sky .h5,
.bg-sky .h6 {
  color: #001c48;
}

.bg-sky p.text-blue,
.bg-sky h1.text-blue,
.bg-sky h2.text-blue,
.bg-sky h3.text-blue,
.bg-sky h4.text-blue,
.bg-sky h5.text-blue,
.bg-sky h6.text-blue,
.bg-sky .h1.text-blue,
.bg-sky .h2.text-blue,
.bg-sky .h3.text-blue,
.bg-sky .h4.text-blue,
.bg-sky .h5.text-blue,
.bg-sky .h6.text-blue {
  color: #0c234b;
}

.bg-sky p.text-indigo,
.bg-sky h1.text-indigo,
.bg-sky h2.text-indigo,
.bg-sky h3.text-indigo,
.bg-sky h4.text-indigo,
.bg-sky h5.text-indigo,
.bg-sky h6.text-indigo,
.bg-sky .h1.text-indigo,
.bg-sky .h2.text-indigo,
.bg-sky .h3.text-indigo,
.bg-sky .h4.text-indigo,
.bg-sky .h5.text-indigo,
.bg-sky .h6.text-indigo {
  color: #6610f2;
}

.bg-sky p.text-purple,
.bg-sky h1.text-purple,
.bg-sky h2.text-purple,
.bg-sky h3.text-purple,
.bg-sky h4.text-purple,
.bg-sky h5.text-purple,
.bg-sky h6.text-purple,
.bg-sky .h1.text-purple,
.bg-sky .h2.text-purple,
.bg-sky .h3.text-purple,
.bg-sky .h4.text-purple,
.bg-sky .h5.text-purple,
.bg-sky .h6.text-purple {
  color: #6f42c1;
}

.bg-sky p.text-pink,
.bg-sky h1.text-pink,
.bg-sky h2.text-pink,
.bg-sky h3.text-pink,
.bg-sky h4.text-pink,
.bg-sky h5.text-pink,
.bg-sky h6.text-pink,
.bg-sky .h1.text-pink,
.bg-sky .h2.text-pink,
.bg-sky .h3.text-pink,
.bg-sky .h4.text-pink,
.bg-sky .h5.text-pink,
.bg-sky .h6.text-pink {
  color: #e83e8c;
}

.bg-sky p.text-red,
.bg-sky h1.text-red,
.bg-sky h2.text-red,
.bg-sky h3.text-red,
.bg-sky h4.text-red,
.bg-sky h5.text-red,
.bg-sky h6.text-red,
.bg-sky .h1.text-red,
.bg-sky .h2.text-red,
.bg-sky .h3.text-red,
.bg-sky .h4.text-red,
.bg-sky .h5.text-red,
.bg-sky .h6.text-red {
  color: #ab0520;
}

.bg-sky p.text-orange,
.bg-sky h1.text-orange,
.bg-sky h2.text-orange,
.bg-sky h3.text-orange,
.bg-sky h4.text-orange,
.bg-sky h5.text-orange,
.bg-sky h6.text-orange,
.bg-sky .h1.text-orange,
.bg-sky .h2.text-orange,
.bg-sky .h3.text-orange,
.bg-sky .h4.text-orange,
.bg-sky .h5.text-orange,
.bg-sky .h6.text-orange {
  color: #fd7e14;
}

.bg-sky p.text-yellow,
.bg-sky h1.text-yellow,
.bg-sky h2.text-yellow,
.bg-sky h3.text-yellow,
.bg-sky h4.text-yellow,
.bg-sky h5.text-yellow,
.bg-sky h6.text-yellow,
.bg-sky .h1.text-yellow,
.bg-sky .h2.text-yellow,
.bg-sky .h3.text-yellow,
.bg-sky .h4.text-yellow,
.bg-sky .h5.text-yellow,
.bg-sky .h6.text-yellow {
  color: #ffc107;
}

.bg-sky p.text-green,
.bg-sky h1.text-green,
.bg-sky h2.text-green,
.bg-sky h3.text-green,
.bg-sky h4.text-green,
.bg-sky h5.text-green,
.bg-sky h6.text-green,
.bg-sky .h1.text-green,
.bg-sky .h2.text-green,
.bg-sky .h3.text-green,
.bg-sky .h4.text-green,
.bg-sky .h5.text-green,
.bg-sky .h6.text-green {
  color: #28a745;
}

.bg-sky p.text-teal,
.bg-sky h1.text-teal,
.bg-sky h2.text-teal,
.bg-sky h3.text-teal,
.bg-sky h4.text-teal,
.bg-sky h5.text-teal,
.bg-sky h6.text-teal,
.bg-sky .h1.text-teal,
.bg-sky .h2.text-teal,
.bg-sky .h3.text-teal,
.bg-sky .h4.text-teal,
.bg-sky .h5.text-teal,
.bg-sky .h6.text-teal {
  color: #20c997;
}

.bg-sky p.text-cyan,
.bg-sky h1.text-cyan,
.bg-sky h2.text-cyan,
.bg-sky h3.text-cyan,
.bg-sky h4.text-cyan,
.bg-sky h5.text-cyan,
.bg-sky h6.text-cyan,
.bg-sky .h1.text-cyan,
.bg-sky .h2.text-cyan,
.bg-sky .h3.text-cyan,
.bg-sky .h4.text-cyan,
.bg-sky .h5.text-cyan,
.bg-sky .h6.text-cyan {
  color: #17a2b8;
}

.bg-sky p.text-white,
.bg-sky h1.text-white,
.bg-sky h2.text-white,
.bg-sky h3.text-white,
.bg-sky h4.text-white,
.bg-sky h5.text-white,
.bg-sky h6.text-white,
.bg-sky .h1.text-white,
.bg-sky .h2.text-white,
.bg-sky .h3.text-white,
.bg-sky .h4.text-white,
.bg-sky .h5.text-white,
.bg-sky .h6.text-white {
  color: #fff;
}

.bg-sky p.text-gray,
.bg-sky h1.text-gray,
.bg-sky h2.text-gray,
.bg-sky h3.text-gray,
.bg-sky h4.text-gray,
.bg-sky h5.text-gray,
.bg-sky h6.text-gray,
.bg-sky .h1.text-gray,
.bg-sky .h2.text-gray,
.bg-sky .h3.text-gray,
.bg-sky .h4.text-gray,
.bg-sky .h5.text-gray,
.bg-sky .h6.text-gray {
  color: #6c757d;
}

.bg-sky p.text-gray-dark,
.bg-sky h1.text-gray-dark,
.bg-sky h2.text-gray-dark,
.bg-sky h3.text-gray-dark,
.bg-sky h4.text-gray-dark,
.bg-sky h5.text-gray-dark,
.bg-sky h6.text-gray-dark,
.bg-sky .h1.text-gray-dark,
.bg-sky .h2.text-gray-dark,
.bg-sky .h3.text-gray-dark,
.bg-sky .h4.text-gray-dark,
.bg-sky .h5.text-gray-dark,
.bg-sky .h6.text-gray-dark {
  color: #343a40;
}

.bg-sky p.text-bloom,
.bg-sky h1.text-bloom,
.bg-sky h2.text-bloom,
.bg-sky h3.text-bloom,
.bg-sky h4.text-bloom,
.bg-sky h5.text-bloom,
.bg-sky h6.text-bloom,
.bg-sky .h1.text-bloom,
.bg-sky .h2.text-bloom,
.bg-sky .h3.text-bloom,
.bg-sky .h4.text-bloom,
.bg-sky .h5.text-bloom,
.bg-sky .h6.text-bloom {
  color: #ef4056;
}

.bg-sky p.text-chili,
.bg-sky h1.text-chili,
.bg-sky h2.text-chili,
.bg-sky h3.text-chili,
.bg-sky h4.text-chili,
.bg-sky h5.text-chili,
.bg-sky h6.text-chili,
.bg-sky .h1.text-chili,
.bg-sky .h2.text-chili,
.bg-sky .h3.text-chili,
.bg-sky .h4.text-chili,
.bg-sky .h5.text-chili,
.bg-sky .h6.text-chili {
  color: #8b0015;
}

.bg-sky p.text-sky,
.bg-sky h1.text-sky,
.bg-sky h2.text-sky,
.bg-sky h3.text-sky,
.bg-sky h4.text-sky,
.bg-sky h5.text-sky,
.bg-sky h6.text-sky,
.bg-sky .h1.text-sky,
.bg-sky .h2.text-sky,
.bg-sky .h3.text-sky,
.bg-sky .h4.text-sky,
.bg-sky .h5.text-sky,
.bg-sky .h6.text-sky {
  color: #81d3eb;
}

.bg-sky p.text-oasis,
.bg-sky h1.text-oasis,
.bg-sky h2.text-oasis,
.bg-sky h3.text-oasis,
.bg-sky h4.text-oasis,
.bg-sky h5.text-oasis,
.bg-sky h6.text-oasis,
.bg-sky .h1.text-oasis,
.bg-sky .h2.text-oasis,
.bg-sky .h3.text-oasis,
.bg-sky .h4.text-oasis,
.bg-sky .h5.text-oasis,
.bg-sky .h6.text-oasis {
  color: #378dbd;
}

.bg-sky p.text-azurite,
.bg-sky h1.text-azurite,
.bg-sky h2.text-azurite,
.bg-sky h3.text-azurite,
.bg-sky h4.text-azurite,
.bg-sky h5.text-azurite,
.bg-sky h6.text-azurite,
.bg-sky .h1.text-azurite,
.bg-sky .h2.text-azurite,
.bg-sky .h3.text-azurite,
.bg-sky .h4.text-azurite,
.bg-sky .h5.text-azurite,
.bg-sky .h6.text-azurite {
  color: #1e5288;
}

.bg-sky p.text-midnight,
.bg-sky h1.text-midnight,
.bg-sky h2.text-midnight,
.bg-sky h3.text-midnight,
.bg-sky h4.text-midnight,
.bg-sky h5.text-midnight,
.bg-sky h6.text-midnight,
.bg-sky .h1.text-midnight,
.bg-sky .h2.text-midnight,
.bg-sky .h3.text-midnight,
.bg-sky .h4.text-midnight,
.bg-sky .h5.text-midnight,
.bg-sky .h6.text-midnight {
  color: #001c48;
}

.bg-sky p.text-cool-gray,
.bg-sky h1.text-cool-gray,
.bg-sky h2.text-cool-gray,
.bg-sky h3.text-cool-gray,
.bg-sky h4.text-cool-gray,
.bg-sky h5.text-cool-gray,
.bg-sky h6.text-cool-gray,
.bg-sky .h1.text-cool-gray,
.bg-sky .h2.text-cool-gray,
.bg-sky .h3.text-cool-gray,
.bg-sky .h4.text-cool-gray,
.bg-sky .h5.text-cool-gray,
.bg-sky .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-sky p.text-warm-gray,
.bg-sky h1.text-warm-gray,
.bg-sky h2.text-warm-gray,
.bg-sky h3.text-warm-gray,
.bg-sky h4.text-warm-gray,
.bg-sky h5.text-warm-gray,
.bg-sky h6.text-warm-gray,
.bg-sky .h1.text-warm-gray,
.bg-sky .h2.text-warm-gray,
.bg-sky .h3.text-warm-gray,
.bg-sky .h4.text-warm-gray,
.bg-sky .h5.text-warm-gray,
.bg-sky .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-sky p.text-leaf,
.bg-sky h1.text-leaf,
.bg-sky h2.text-leaf,
.bg-sky h3.text-leaf,
.bg-sky h4.text-leaf,
.bg-sky h5.text-leaf,
.bg-sky h6.text-leaf,
.bg-sky .h1.text-leaf,
.bg-sky .h2.text-leaf,
.bg-sky .h3.text-leaf,
.bg-sky .h4.text-leaf,
.bg-sky .h5.text-leaf,
.bg-sky .h6.text-leaf {
  color: #70b865;
}

.bg-sky p.text-river,
.bg-sky h1.text-river,
.bg-sky h2.text-river,
.bg-sky h3.text-river,
.bg-sky h4.text-river,
.bg-sky h5.text-river,
.bg-sky h6.text-river,
.bg-sky .h1.text-river,
.bg-sky .h2.text-river,
.bg-sky .h3.text-river,
.bg-sky .h4.text-river,
.bg-sky .h5.text-river,
.bg-sky .h6.text-river {
  color: #007d84;
}

.bg-sky p.text-silver,
.bg-sky h1.text-silver,
.bg-sky h2.text-silver,
.bg-sky h3.text-silver,
.bg-sky h4.text-silver,
.bg-sky h5.text-silver,
.bg-sky h6.text-silver,
.bg-sky .h1.text-silver,
.bg-sky .h2.text-silver,
.bg-sky .h3.text-silver,
.bg-sky .h4.text-silver,
.bg-sky .h5.text-silver,
.bg-sky .h6.text-silver {
  color: #9eabae;
}

.bg-sky p.text-mesa,
.bg-sky h1.text-mesa,
.bg-sky h2.text-mesa,
.bg-sky h3.text-mesa,
.bg-sky h4.text-mesa,
.bg-sky h5.text-mesa,
.bg-sky h6.text-mesa,
.bg-sky .h1.text-mesa,
.bg-sky .h2.text-mesa,
.bg-sky .h3.text-mesa,
.bg-sky .h4.text-mesa,
.bg-sky .h5.text-mesa,
.bg-sky .h6.text-mesa {
  color: #a95c42;
}

.bg-sky p.text-ash,
.bg-sky h1.text-ash,
.bg-sky h2.text-ash,
.bg-sky h3.text-ash,
.bg-sky h4.text-ash,
.bg-sky h5.text-ash,
.bg-sky h6.text-ash,
.bg-sky .h1.text-ash,
.bg-sky .h2.text-ash,
.bg-sky .h3.text-ash,
.bg-sky .h4.text-ash,
.bg-sky .h5.text-ash,
.bg-sky .h6.text-ash {
  color: #403635;
}

.bg-sky p.text-sage,
.bg-sky h1.text-sage,
.bg-sky h2.text-sage,
.bg-sky h3.text-sage,
.bg-sky h4.text-sage,
.bg-sky h5.text-sage,
.bg-sky h6.text-sage,
.bg-sky .h1.text-sage,
.bg-sky .h2.text-sage,
.bg-sky .h3.text-sage,
.bg-sky .h4.text-sage,
.bg-sky .h5.text-sage,
.bg-sky .h6.text-sage {
  color: #4a634e;
}

.bg-sky p.text-black,
.bg-sky h1.text-black,
.bg-sky h2.text-black,
.bg-sky h3.text-black,
.bg-sky h4.text-black,
.bg-sky h5.text-black,
.bg-sky h6.text-black,
.bg-sky .h1.text-black,
.bg-sky .h2.text-black,
.bg-sky .h3.text-black,
.bg-sky .h4.text-black,
.bg-sky .h5.text-black,
.bg-sky .h6.text-black {
  color: #000;
}

.bg-sky p.text-primary,
.bg-sky h1.text-primary,
.bg-sky h2.text-primary,
.bg-sky h3.text-primary,
.bg-sky h4.text-primary,
.bg-sky h5.text-primary,
.bg-sky h6.text-primary,
.bg-sky .h1.text-primary,
.bg-sky .h2.text-primary,
.bg-sky .h3.text-primary,
.bg-sky .h4.text-primary,
.bg-sky .h5.text-primary,
.bg-sky .h6.text-primary {
  color: #1e5288;
}

.bg-sky p.text-secondary,
.bg-sky h1.text-secondary,
.bg-sky h2.text-secondary,
.bg-sky h3.text-secondary,
.bg-sky h4.text-secondary,
.bg-sky h5.text-secondary,
.bg-sky h6.text-secondary,
.bg-sky .h1.text-secondary,
.bg-sky .h2.text-secondary,
.bg-sky .h3.text-secondary,
.bg-sky .h4.text-secondary,
.bg-sky .h5.text-secondary,
.bg-sky .h6.text-secondary {
  color: #9eabae;
}

.bg-sky p.text-success,
.bg-sky h1.text-success,
.bg-sky h2.text-success,
.bg-sky h3.text-success,
.bg-sky h4.text-success,
.bg-sky h5.text-success,
.bg-sky h6.text-success,
.bg-sky .h1.text-success,
.bg-sky .h2.text-success,
.bg-sky .h3.text-success,
.bg-sky .h4.text-success,
.bg-sky .h5.text-success,
.bg-sky .h6.text-success {
  color: #70b865;
}

.bg-sky p.text-info,
.bg-sky h1.text-info,
.bg-sky h2.text-info,
.bg-sky h3.text-info,
.bg-sky h4.text-info,
.bg-sky h5.text-info,
.bg-sky h6.text-info,
.bg-sky .h1.text-info,
.bg-sky .h2.text-info,
.bg-sky .h3.text-info,
.bg-sky .h4.text-info,
.bg-sky .h5.text-info,
.bg-sky .h6.text-info {
  color: #81d3eb;
}

.bg-sky p.text-warning,
.bg-sky h1.text-warning,
.bg-sky h2.text-warning,
.bg-sky h3.text-warning,
.bg-sky h4.text-warning,
.bg-sky h5.text-warning,
.bg-sky h6.text-warning,
.bg-sky .h1.text-warning,
.bg-sky .h2.text-warning,
.bg-sky .h3.text-warning,
.bg-sky .h4.text-warning,
.bg-sky .h5.text-warning,
.bg-sky .h6.text-warning {
  color: #f19e1f;
}

.bg-sky p.text-danger,
.bg-sky h1.text-danger,
.bg-sky h2.text-danger,
.bg-sky h3.text-danger,
.bg-sky h4.text-danger,
.bg-sky h5.text-danger,
.bg-sky h6.text-danger,
.bg-sky .h1.text-danger,
.bg-sky .h2.text-danger,
.bg-sky .h3.text-danger,
.bg-sky .h4.text-danger,
.bg-sky .h5.text-danger,
.bg-sky .h6.text-danger {
  color: #ef4056;
}

.bg-sky p.text-light,
.bg-sky h1.text-light,
.bg-sky h2.text-light,
.bg-sky h3.text-light,
.bg-sky h4.text-light,
.bg-sky h5.text-light,
.bg-sky h6.text-light,
.bg-sky .h1.text-light,
.bg-sky .h2.text-light,
.bg-sky .h3.text-light,
.bg-sky .h4.text-light,
.bg-sky .h5.text-light,
.bg-sky .h6.text-light {
  color: #dee2e6;
}

.bg-sky p.text-dark,
.bg-sky h1.text-dark,
.bg-sky h2.text-dark,
.bg-sky h3.text-dark,
.bg-sky h4.text-dark,
.bg-sky h5.text-dark,
.bg-sky h6.text-dark,
.bg-sky .h1.text-dark,
.bg-sky .h2.text-dark,
.bg-sky .h3.text-dark,
.bg-sky .h4.text-dark,
.bg-sky .h5.text-dark,
.bg-sky .h6.text-dark {
  color: #343a40;
}

.bg-sky p.text-primary-dark,
.bg-sky h1.text-primary-dark,
.bg-sky h2.text-primary-dark,
.bg-sky h3.text-primary-dark,
.bg-sky h4.text-primary-dark,
.bg-sky h5.text-primary-dark,
.bg-sky h6.text-primary-dark,
.bg-sky .h1.text-primary-dark,
.bg-sky .h2.text-primary-dark,
.bg-sky .h3.text-primary-dark,
.bg-sky .h4.text-primary-dark,
.bg-sky .h5.text-primary-dark,
.bg-sky .h6.text-primary-dark {
  color: #0c234b;
}

.bg-sky p.text-primary-light,
.bg-sky h1.text-primary-light,
.bg-sky h2.text-primary-light,
.bg-sky h3.text-primary-light,
.bg-sky h4.text-primary-light,
.bg-sky h5.text-primary-light,
.bg-sky h6.text-primary-light,
.bg-sky .h1.text-primary-light,
.bg-sky .h2.text-primary-light,
.bg-sky .h3.text-primary-light,
.bg-sky .h4.text-primary-light,
.bg-sky .h5.text-primary-light,
.bg-sky .h6.text-primary-light {
  color: #378dbd;
}

.bg-sky p.text-accent,
.bg-sky h1.text-accent,
.bg-sky h2.text-accent,
.bg-sky h3.text-accent,
.bg-sky h4.text-accent,
.bg-sky h5.text-accent,
.bg-sky h6.text-accent,
.bg-sky .h1.text-accent,
.bg-sky .h2.text-accent,
.bg-sky .h3.text-accent,
.bg-sky .h4.text-accent,
.bg-sky .h5.text-accent,
.bg-sky .h6.text-accent {
  color: #ab0520;
}

.bg-oasis {
  color: #fff;
  background-color: #378dbd;
  color: #001c48;
}

.bg-oasis p,
.bg-oasis h1,
.bg-oasis h2,
.bg-oasis h3,
.bg-oasis h4,
.bg-oasis h5,
.bg-oasis h6,
.bg-oasis .h1,
.bg-oasis .h2,
.bg-oasis .h3,
.bg-oasis .h4,
.bg-oasis .h5,
.bg-oasis .h6 {
  color: #001c48;
}

.bg-oasis p.text-blue,
.bg-oasis h1.text-blue,
.bg-oasis h2.text-blue,
.bg-oasis h3.text-blue,
.bg-oasis h4.text-blue,
.bg-oasis h5.text-blue,
.bg-oasis h6.text-blue,
.bg-oasis .h1.text-blue,
.bg-oasis .h2.text-blue,
.bg-oasis .h3.text-blue,
.bg-oasis .h4.text-blue,
.bg-oasis .h5.text-blue,
.bg-oasis .h6.text-blue {
  color: #0c234b;
}

.bg-oasis p.text-indigo,
.bg-oasis h1.text-indigo,
.bg-oasis h2.text-indigo,
.bg-oasis h3.text-indigo,
.bg-oasis h4.text-indigo,
.bg-oasis h5.text-indigo,
.bg-oasis h6.text-indigo,
.bg-oasis .h1.text-indigo,
.bg-oasis .h2.text-indigo,
.bg-oasis .h3.text-indigo,
.bg-oasis .h4.text-indigo,
.bg-oasis .h5.text-indigo,
.bg-oasis .h6.text-indigo {
  color: #6610f2;
}

.bg-oasis p.text-purple,
.bg-oasis h1.text-purple,
.bg-oasis h2.text-purple,
.bg-oasis h3.text-purple,
.bg-oasis h4.text-purple,
.bg-oasis h5.text-purple,
.bg-oasis h6.text-purple,
.bg-oasis .h1.text-purple,
.bg-oasis .h2.text-purple,
.bg-oasis .h3.text-purple,
.bg-oasis .h4.text-purple,
.bg-oasis .h5.text-purple,
.bg-oasis .h6.text-purple {
  color: #6f42c1;
}

.bg-oasis p.text-pink,
.bg-oasis h1.text-pink,
.bg-oasis h2.text-pink,
.bg-oasis h3.text-pink,
.bg-oasis h4.text-pink,
.bg-oasis h5.text-pink,
.bg-oasis h6.text-pink,
.bg-oasis .h1.text-pink,
.bg-oasis .h2.text-pink,
.bg-oasis .h3.text-pink,
.bg-oasis .h4.text-pink,
.bg-oasis .h5.text-pink,
.bg-oasis .h6.text-pink {
  color: #e83e8c;
}

.bg-oasis p.text-red,
.bg-oasis h1.text-red,
.bg-oasis h2.text-red,
.bg-oasis h3.text-red,
.bg-oasis h4.text-red,
.bg-oasis h5.text-red,
.bg-oasis h6.text-red,
.bg-oasis .h1.text-red,
.bg-oasis .h2.text-red,
.bg-oasis .h3.text-red,
.bg-oasis .h4.text-red,
.bg-oasis .h5.text-red,
.bg-oasis .h6.text-red {
  color: #ab0520;
}

.bg-oasis p.text-orange,
.bg-oasis h1.text-orange,
.bg-oasis h2.text-orange,
.bg-oasis h3.text-orange,
.bg-oasis h4.text-orange,
.bg-oasis h5.text-orange,
.bg-oasis h6.text-orange,
.bg-oasis .h1.text-orange,
.bg-oasis .h2.text-orange,
.bg-oasis .h3.text-orange,
.bg-oasis .h4.text-orange,
.bg-oasis .h5.text-orange,
.bg-oasis .h6.text-orange {
  color: #fd7e14;
}

.bg-oasis p.text-yellow,
.bg-oasis h1.text-yellow,
.bg-oasis h2.text-yellow,
.bg-oasis h3.text-yellow,
.bg-oasis h4.text-yellow,
.bg-oasis h5.text-yellow,
.bg-oasis h6.text-yellow,
.bg-oasis .h1.text-yellow,
.bg-oasis .h2.text-yellow,
.bg-oasis .h3.text-yellow,
.bg-oasis .h4.text-yellow,
.bg-oasis .h5.text-yellow,
.bg-oasis .h6.text-yellow {
  color: #ffc107;
}

.bg-oasis p.text-green,
.bg-oasis h1.text-green,
.bg-oasis h2.text-green,
.bg-oasis h3.text-green,
.bg-oasis h4.text-green,
.bg-oasis h5.text-green,
.bg-oasis h6.text-green,
.bg-oasis .h1.text-green,
.bg-oasis .h2.text-green,
.bg-oasis .h3.text-green,
.bg-oasis .h4.text-green,
.bg-oasis .h5.text-green,
.bg-oasis .h6.text-green {
  color: #28a745;
}

.bg-oasis p.text-teal,
.bg-oasis h1.text-teal,
.bg-oasis h2.text-teal,
.bg-oasis h3.text-teal,
.bg-oasis h4.text-teal,
.bg-oasis h5.text-teal,
.bg-oasis h6.text-teal,
.bg-oasis .h1.text-teal,
.bg-oasis .h2.text-teal,
.bg-oasis .h3.text-teal,
.bg-oasis .h4.text-teal,
.bg-oasis .h5.text-teal,
.bg-oasis .h6.text-teal {
  color: #20c997;
}

.bg-oasis p.text-cyan,
.bg-oasis h1.text-cyan,
.bg-oasis h2.text-cyan,
.bg-oasis h3.text-cyan,
.bg-oasis h4.text-cyan,
.bg-oasis h5.text-cyan,
.bg-oasis h6.text-cyan,
.bg-oasis .h1.text-cyan,
.bg-oasis .h2.text-cyan,
.bg-oasis .h3.text-cyan,
.bg-oasis .h4.text-cyan,
.bg-oasis .h5.text-cyan,
.bg-oasis .h6.text-cyan {
  color: #17a2b8;
}

.bg-oasis p.text-white,
.bg-oasis h1.text-white,
.bg-oasis h2.text-white,
.bg-oasis h3.text-white,
.bg-oasis h4.text-white,
.bg-oasis h5.text-white,
.bg-oasis h6.text-white,
.bg-oasis .h1.text-white,
.bg-oasis .h2.text-white,
.bg-oasis .h3.text-white,
.bg-oasis .h4.text-white,
.bg-oasis .h5.text-white,
.bg-oasis .h6.text-white {
  color: #fff;
}

.bg-oasis p.text-gray,
.bg-oasis h1.text-gray,
.bg-oasis h2.text-gray,
.bg-oasis h3.text-gray,
.bg-oasis h4.text-gray,
.bg-oasis h5.text-gray,
.bg-oasis h6.text-gray,
.bg-oasis .h1.text-gray,
.bg-oasis .h2.text-gray,
.bg-oasis .h3.text-gray,
.bg-oasis .h4.text-gray,
.bg-oasis .h5.text-gray,
.bg-oasis .h6.text-gray {
  color: #6c757d;
}

.bg-oasis p.text-gray-dark,
.bg-oasis h1.text-gray-dark,
.bg-oasis h2.text-gray-dark,
.bg-oasis h3.text-gray-dark,
.bg-oasis h4.text-gray-dark,
.bg-oasis h5.text-gray-dark,
.bg-oasis h6.text-gray-dark,
.bg-oasis .h1.text-gray-dark,
.bg-oasis .h2.text-gray-dark,
.bg-oasis .h3.text-gray-dark,
.bg-oasis .h4.text-gray-dark,
.bg-oasis .h5.text-gray-dark,
.bg-oasis .h6.text-gray-dark {
  color: #343a40;
}

.bg-oasis p.text-bloom,
.bg-oasis h1.text-bloom,
.bg-oasis h2.text-bloom,
.bg-oasis h3.text-bloom,
.bg-oasis h4.text-bloom,
.bg-oasis h5.text-bloom,
.bg-oasis h6.text-bloom,
.bg-oasis .h1.text-bloom,
.bg-oasis .h2.text-bloom,
.bg-oasis .h3.text-bloom,
.bg-oasis .h4.text-bloom,
.bg-oasis .h5.text-bloom,
.bg-oasis .h6.text-bloom {
  color: #ef4056;
}

.bg-oasis p.text-chili,
.bg-oasis h1.text-chili,
.bg-oasis h2.text-chili,
.bg-oasis h3.text-chili,
.bg-oasis h4.text-chili,
.bg-oasis h5.text-chili,
.bg-oasis h6.text-chili,
.bg-oasis .h1.text-chili,
.bg-oasis .h2.text-chili,
.bg-oasis .h3.text-chili,
.bg-oasis .h4.text-chili,
.bg-oasis .h5.text-chili,
.bg-oasis .h6.text-chili {
  color: #8b0015;
}

.bg-oasis p.text-sky,
.bg-oasis h1.text-sky,
.bg-oasis h2.text-sky,
.bg-oasis h3.text-sky,
.bg-oasis h4.text-sky,
.bg-oasis h5.text-sky,
.bg-oasis h6.text-sky,
.bg-oasis .h1.text-sky,
.bg-oasis .h2.text-sky,
.bg-oasis .h3.text-sky,
.bg-oasis .h4.text-sky,
.bg-oasis .h5.text-sky,
.bg-oasis .h6.text-sky {
  color: #81d3eb;
}

.bg-oasis p.text-oasis,
.bg-oasis h1.text-oasis,
.bg-oasis h2.text-oasis,
.bg-oasis h3.text-oasis,
.bg-oasis h4.text-oasis,
.bg-oasis h5.text-oasis,
.bg-oasis h6.text-oasis,
.bg-oasis .h1.text-oasis,
.bg-oasis .h2.text-oasis,
.bg-oasis .h3.text-oasis,
.bg-oasis .h4.text-oasis,
.bg-oasis .h5.text-oasis,
.bg-oasis .h6.text-oasis {
  color: #378dbd;
}

.bg-oasis p.text-azurite,
.bg-oasis h1.text-azurite,
.bg-oasis h2.text-azurite,
.bg-oasis h3.text-azurite,
.bg-oasis h4.text-azurite,
.bg-oasis h5.text-azurite,
.bg-oasis h6.text-azurite,
.bg-oasis .h1.text-azurite,
.bg-oasis .h2.text-azurite,
.bg-oasis .h3.text-azurite,
.bg-oasis .h4.text-azurite,
.bg-oasis .h5.text-azurite,
.bg-oasis .h6.text-azurite {
  color: #1e5288;
}

.bg-oasis p.text-midnight,
.bg-oasis h1.text-midnight,
.bg-oasis h2.text-midnight,
.bg-oasis h3.text-midnight,
.bg-oasis h4.text-midnight,
.bg-oasis h5.text-midnight,
.bg-oasis h6.text-midnight,
.bg-oasis .h1.text-midnight,
.bg-oasis .h2.text-midnight,
.bg-oasis .h3.text-midnight,
.bg-oasis .h4.text-midnight,
.bg-oasis .h5.text-midnight,
.bg-oasis .h6.text-midnight {
  color: #001c48;
}

.bg-oasis p.text-cool-gray,
.bg-oasis h1.text-cool-gray,
.bg-oasis h2.text-cool-gray,
.bg-oasis h3.text-cool-gray,
.bg-oasis h4.text-cool-gray,
.bg-oasis h5.text-cool-gray,
.bg-oasis h6.text-cool-gray,
.bg-oasis .h1.text-cool-gray,
.bg-oasis .h2.text-cool-gray,
.bg-oasis .h3.text-cool-gray,
.bg-oasis .h4.text-cool-gray,
.bg-oasis .h5.text-cool-gray,
.bg-oasis .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-oasis p.text-warm-gray,
.bg-oasis h1.text-warm-gray,
.bg-oasis h2.text-warm-gray,
.bg-oasis h3.text-warm-gray,
.bg-oasis h4.text-warm-gray,
.bg-oasis h5.text-warm-gray,
.bg-oasis h6.text-warm-gray,
.bg-oasis .h1.text-warm-gray,
.bg-oasis .h2.text-warm-gray,
.bg-oasis .h3.text-warm-gray,
.bg-oasis .h4.text-warm-gray,
.bg-oasis .h5.text-warm-gray,
.bg-oasis .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-oasis p.text-leaf,
.bg-oasis h1.text-leaf,
.bg-oasis h2.text-leaf,
.bg-oasis h3.text-leaf,
.bg-oasis h4.text-leaf,
.bg-oasis h5.text-leaf,
.bg-oasis h6.text-leaf,
.bg-oasis .h1.text-leaf,
.bg-oasis .h2.text-leaf,
.bg-oasis .h3.text-leaf,
.bg-oasis .h4.text-leaf,
.bg-oasis .h5.text-leaf,
.bg-oasis .h6.text-leaf {
  color: #70b865;
}

.bg-oasis p.text-river,
.bg-oasis h1.text-river,
.bg-oasis h2.text-river,
.bg-oasis h3.text-river,
.bg-oasis h4.text-river,
.bg-oasis h5.text-river,
.bg-oasis h6.text-river,
.bg-oasis .h1.text-river,
.bg-oasis .h2.text-river,
.bg-oasis .h3.text-river,
.bg-oasis .h4.text-river,
.bg-oasis .h5.text-river,
.bg-oasis .h6.text-river {
  color: #007d84;
}

.bg-oasis p.text-silver,
.bg-oasis h1.text-silver,
.bg-oasis h2.text-silver,
.bg-oasis h3.text-silver,
.bg-oasis h4.text-silver,
.bg-oasis h5.text-silver,
.bg-oasis h6.text-silver,
.bg-oasis .h1.text-silver,
.bg-oasis .h2.text-silver,
.bg-oasis .h3.text-silver,
.bg-oasis .h4.text-silver,
.bg-oasis .h5.text-silver,
.bg-oasis .h6.text-silver {
  color: #9eabae;
}

.bg-oasis p.text-mesa,
.bg-oasis h1.text-mesa,
.bg-oasis h2.text-mesa,
.bg-oasis h3.text-mesa,
.bg-oasis h4.text-mesa,
.bg-oasis h5.text-mesa,
.bg-oasis h6.text-mesa,
.bg-oasis .h1.text-mesa,
.bg-oasis .h2.text-mesa,
.bg-oasis .h3.text-mesa,
.bg-oasis .h4.text-mesa,
.bg-oasis .h5.text-mesa,
.bg-oasis .h6.text-mesa {
  color: #a95c42;
}

.bg-oasis p.text-ash,
.bg-oasis h1.text-ash,
.bg-oasis h2.text-ash,
.bg-oasis h3.text-ash,
.bg-oasis h4.text-ash,
.bg-oasis h5.text-ash,
.bg-oasis h6.text-ash,
.bg-oasis .h1.text-ash,
.bg-oasis .h2.text-ash,
.bg-oasis .h3.text-ash,
.bg-oasis .h4.text-ash,
.bg-oasis .h5.text-ash,
.bg-oasis .h6.text-ash {
  color: #403635;
}

.bg-oasis p.text-sage,
.bg-oasis h1.text-sage,
.bg-oasis h2.text-sage,
.bg-oasis h3.text-sage,
.bg-oasis h4.text-sage,
.bg-oasis h5.text-sage,
.bg-oasis h6.text-sage,
.bg-oasis .h1.text-sage,
.bg-oasis .h2.text-sage,
.bg-oasis .h3.text-sage,
.bg-oasis .h4.text-sage,
.bg-oasis .h5.text-sage,
.bg-oasis .h6.text-sage {
  color: #4a634e;
}

.bg-oasis p.text-black,
.bg-oasis h1.text-black,
.bg-oasis h2.text-black,
.bg-oasis h3.text-black,
.bg-oasis h4.text-black,
.bg-oasis h5.text-black,
.bg-oasis h6.text-black,
.bg-oasis .h1.text-black,
.bg-oasis .h2.text-black,
.bg-oasis .h3.text-black,
.bg-oasis .h4.text-black,
.bg-oasis .h5.text-black,
.bg-oasis .h6.text-black {
  color: #000;
}

.bg-oasis p.text-primary,
.bg-oasis h1.text-primary,
.bg-oasis h2.text-primary,
.bg-oasis h3.text-primary,
.bg-oasis h4.text-primary,
.bg-oasis h5.text-primary,
.bg-oasis h6.text-primary,
.bg-oasis .h1.text-primary,
.bg-oasis .h2.text-primary,
.bg-oasis .h3.text-primary,
.bg-oasis .h4.text-primary,
.bg-oasis .h5.text-primary,
.bg-oasis .h6.text-primary {
  color: #1e5288;
}

.bg-oasis p.text-secondary,
.bg-oasis h1.text-secondary,
.bg-oasis h2.text-secondary,
.bg-oasis h3.text-secondary,
.bg-oasis h4.text-secondary,
.bg-oasis h5.text-secondary,
.bg-oasis h6.text-secondary,
.bg-oasis .h1.text-secondary,
.bg-oasis .h2.text-secondary,
.bg-oasis .h3.text-secondary,
.bg-oasis .h4.text-secondary,
.bg-oasis .h5.text-secondary,
.bg-oasis .h6.text-secondary {
  color: #9eabae;
}

.bg-oasis p.text-success,
.bg-oasis h1.text-success,
.bg-oasis h2.text-success,
.bg-oasis h3.text-success,
.bg-oasis h4.text-success,
.bg-oasis h5.text-success,
.bg-oasis h6.text-success,
.bg-oasis .h1.text-success,
.bg-oasis .h2.text-success,
.bg-oasis .h3.text-success,
.bg-oasis .h4.text-success,
.bg-oasis .h5.text-success,
.bg-oasis .h6.text-success {
  color: #70b865;
}

.bg-oasis p.text-info,
.bg-oasis h1.text-info,
.bg-oasis h2.text-info,
.bg-oasis h3.text-info,
.bg-oasis h4.text-info,
.bg-oasis h5.text-info,
.bg-oasis h6.text-info,
.bg-oasis .h1.text-info,
.bg-oasis .h2.text-info,
.bg-oasis .h3.text-info,
.bg-oasis .h4.text-info,
.bg-oasis .h5.text-info,
.bg-oasis .h6.text-info {
  color: #81d3eb;
}

.bg-oasis p.text-warning,
.bg-oasis h1.text-warning,
.bg-oasis h2.text-warning,
.bg-oasis h3.text-warning,
.bg-oasis h4.text-warning,
.bg-oasis h5.text-warning,
.bg-oasis h6.text-warning,
.bg-oasis .h1.text-warning,
.bg-oasis .h2.text-warning,
.bg-oasis .h3.text-warning,
.bg-oasis .h4.text-warning,
.bg-oasis .h5.text-warning,
.bg-oasis .h6.text-warning {
  color: #f19e1f;
}

.bg-oasis p.text-danger,
.bg-oasis h1.text-danger,
.bg-oasis h2.text-danger,
.bg-oasis h3.text-danger,
.bg-oasis h4.text-danger,
.bg-oasis h5.text-danger,
.bg-oasis h6.text-danger,
.bg-oasis .h1.text-danger,
.bg-oasis .h2.text-danger,
.bg-oasis .h3.text-danger,
.bg-oasis .h4.text-danger,
.bg-oasis .h5.text-danger,
.bg-oasis .h6.text-danger {
  color: #ef4056;
}

.bg-oasis p.text-light,
.bg-oasis h1.text-light,
.bg-oasis h2.text-light,
.bg-oasis h3.text-light,
.bg-oasis h4.text-light,
.bg-oasis h5.text-light,
.bg-oasis h6.text-light,
.bg-oasis .h1.text-light,
.bg-oasis .h2.text-light,
.bg-oasis .h3.text-light,
.bg-oasis .h4.text-light,
.bg-oasis .h5.text-light,
.bg-oasis .h6.text-light {
  color: #dee2e6;
}

.bg-oasis p.text-dark,
.bg-oasis h1.text-dark,
.bg-oasis h2.text-dark,
.bg-oasis h3.text-dark,
.bg-oasis h4.text-dark,
.bg-oasis h5.text-dark,
.bg-oasis h6.text-dark,
.bg-oasis .h1.text-dark,
.bg-oasis .h2.text-dark,
.bg-oasis .h3.text-dark,
.bg-oasis .h4.text-dark,
.bg-oasis .h5.text-dark,
.bg-oasis .h6.text-dark {
  color: #343a40;
}

.bg-oasis p.text-primary-dark,
.bg-oasis h1.text-primary-dark,
.bg-oasis h2.text-primary-dark,
.bg-oasis h3.text-primary-dark,
.bg-oasis h4.text-primary-dark,
.bg-oasis h5.text-primary-dark,
.bg-oasis h6.text-primary-dark,
.bg-oasis .h1.text-primary-dark,
.bg-oasis .h2.text-primary-dark,
.bg-oasis .h3.text-primary-dark,
.bg-oasis .h4.text-primary-dark,
.bg-oasis .h5.text-primary-dark,
.bg-oasis .h6.text-primary-dark {
  color: #0c234b;
}

.bg-oasis p.text-primary-light,
.bg-oasis h1.text-primary-light,
.bg-oasis h2.text-primary-light,
.bg-oasis h3.text-primary-light,
.bg-oasis h4.text-primary-light,
.bg-oasis h5.text-primary-light,
.bg-oasis h6.text-primary-light,
.bg-oasis .h1.text-primary-light,
.bg-oasis .h2.text-primary-light,
.bg-oasis .h3.text-primary-light,
.bg-oasis .h4.text-primary-light,
.bg-oasis .h5.text-primary-light,
.bg-oasis .h6.text-primary-light {
  color: #378dbd;
}

.bg-oasis p.text-accent,
.bg-oasis h1.text-accent,
.bg-oasis h2.text-accent,
.bg-oasis h3.text-accent,
.bg-oasis h4.text-accent,
.bg-oasis h5.text-accent,
.bg-oasis h6.text-accent,
.bg-oasis .h1.text-accent,
.bg-oasis .h2.text-accent,
.bg-oasis .h3.text-accent,
.bg-oasis .h4.text-accent,
.bg-oasis .h5.text-accent,
.bg-oasis .h6.text-accent {
  color: #ab0520;
}

.bg-azurite {
  color: #fff;
  background-color: #1e5288;
  color: #fff;
}

.bg-azurite p,
.bg-azurite h1,
.bg-azurite h2,
.bg-azurite h3,
.bg-azurite h4,
.bg-azurite h5,
.bg-azurite h6,
.bg-azurite .h1,
.bg-azurite .h2,
.bg-azurite .h3,
.bg-azurite .h4,
.bg-azurite .h5,
.bg-azurite .h6 {
  color: #fff;
}

.bg-azurite p.text-blue,
.bg-azurite h1.text-blue,
.bg-azurite h2.text-blue,
.bg-azurite h3.text-blue,
.bg-azurite h4.text-blue,
.bg-azurite h5.text-blue,
.bg-azurite h6.text-blue,
.bg-azurite .h1.text-blue,
.bg-azurite .h2.text-blue,
.bg-azurite .h3.text-blue,
.bg-azurite .h4.text-blue,
.bg-azurite .h5.text-blue,
.bg-azurite .h6.text-blue {
  color: #0c234b;
}

.bg-azurite p.text-indigo,
.bg-azurite h1.text-indigo,
.bg-azurite h2.text-indigo,
.bg-azurite h3.text-indigo,
.bg-azurite h4.text-indigo,
.bg-azurite h5.text-indigo,
.bg-azurite h6.text-indigo,
.bg-azurite .h1.text-indigo,
.bg-azurite .h2.text-indigo,
.bg-azurite .h3.text-indigo,
.bg-azurite .h4.text-indigo,
.bg-azurite .h5.text-indigo,
.bg-azurite .h6.text-indigo {
  color: #6610f2;
}

.bg-azurite p.text-purple,
.bg-azurite h1.text-purple,
.bg-azurite h2.text-purple,
.bg-azurite h3.text-purple,
.bg-azurite h4.text-purple,
.bg-azurite h5.text-purple,
.bg-azurite h6.text-purple,
.bg-azurite .h1.text-purple,
.bg-azurite .h2.text-purple,
.bg-azurite .h3.text-purple,
.bg-azurite .h4.text-purple,
.bg-azurite .h5.text-purple,
.bg-azurite .h6.text-purple {
  color: #6f42c1;
}

.bg-azurite p.text-pink,
.bg-azurite h1.text-pink,
.bg-azurite h2.text-pink,
.bg-azurite h3.text-pink,
.bg-azurite h4.text-pink,
.bg-azurite h5.text-pink,
.bg-azurite h6.text-pink,
.bg-azurite .h1.text-pink,
.bg-azurite .h2.text-pink,
.bg-azurite .h3.text-pink,
.bg-azurite .h4.text-pink,
.bg-azurite .h5.text-pink,
.bg-azurite .h6.text-pink {
  color: #e83e8c;
}

.bg-azurite p.text-red,
.bg-azurite h1.text-red,
.bg-azurite h2.text-red,
.bg-azurite h3.text-red,
.bg-azurite h4.text-red,
.bg-azurite h5.text-red,
.bg-azurite h6.text-red,
.bg-azurite .h1.text-red,
.bg-azurite .h2.text-red,
.bg-azurite .h3.text-red,
.bg-azurite .h4.text-red,
.bg-azurite .h5.text-red,
.bg-azurite .h6.text-red {
  color: #ab0520;
}

.bg-azurite p.text-orange,
.bg-azurite h1.text-orange,
.bg-azurite h2.text-orange,
.bg-azurite h3.text-orange,
.bg-azurite h4.text-orange,
.bg-azurite h5.text-orange,
.bg-azurite h6.text-orange,
.bg-azurite .h1.text-orange,
.bg-azurite .h2.text-orange,
.bg-azurite .h3.text-orange,
.bg-azurite .h4.text-orange,
.bg-azurite .h5.text-orange,
.bg-azurite .h6.text-orange {
  color: #fd7e14;
}

.bg-azurite p.text-yellow,
.bg-azurite h1.text-yellow,
.bg-azurite h2.text-yellow,
.bg-azurite h3.text-yellow,
.bg-azurite h4.text-yellow,
.bg-azurite h5.text-yellow,
.bg-azurite h6.text-yellow,
.bg-azurite .h1.text-yellow,
.bg-azurite .h2.text-yellow,
.bg-azurite .h3.text-yellow,
.bg-azurite .h4.text-yellow,
.bg-azurite .h5.text-yellow,
.bg-azurite .h6.text-yellow {
  color: #ffc107;
}

.bg-azurite p.text-green,
.bg-azurite h1.text-green,
.bg-azurite h2.text-green,
.bg-azurite h3.text-green,
.bg-azurite h4.text-green,
.bg-azurite h5.text-green,
.bg-azurite h6.text-green,
.bg-azurite .h1.text-green,
.bg-azurite .h2.text-green,
.bg-azurite .h3.text-green,
.bg-azurite .h4.text-green,
.bg-azurite .h5.text-green,
.bg-azurite .h6.text-green {
  color: #28a745;
}

.bg-azurite p.text-teal,
.bg-azurite h1.text-teal,
.bg-azurite h2.text-teal,
.bg-azurite h3.text-teal,
.bg-azurite h4.text-teal,
.bg-azurite h5.text-teal,
.bg-azurite h6.text-teal,
.bg-azurite .h1.text-teal,
.bg-azurite .h2.text-teal,
.bg-azurite .h3.text-teal,
.bg-azurite .h4.text-teal,
.bg-azurite .h5.text-teal,
.bg-azurite .h6.text-teal {
  color: #20c997;
}

.bg-azurite p.text-cyan,
.bg-azurite h1.text-cyan,
.bg-azurite h2.text-cyan,
.bg-azurite h3.text-cyan,
.bg-azurite h4.text-cyan,
.bg-azurite h5.text-cyan,
.bg-azurite h6.text-cyan,
.bg-azurite .h1.text-cyan,
.bg-azurite .h2.text-cyan,
.bg-azurite .h3.text-cyan,
.bg-azurite .h4.text-cyan,
.bg-azurite .h5.text-cyan,
.bg-azurite .h6.text-cyan {
  color: #17a2b8;
}

.bg-azurite p.text-white,
.bg-azurite h1.text-white,
.bg-azurite h2.text-white,
.bg-azurite h3.text-white,
.bg-azurite h4.text-white,
.bg-azurite h5.text-white,
.bg-azurite h6.text-white,
.bg-azurite .h1.text-white,
.bg-azurite .h2.text-white,
.bg-azurite .h3.text-white,
.bg-azurite .h4.text-white,
.bg-azurite .h5.text-white,
.bg-azurite .h6.text-white {
  color: #fff;
}

.bg-azurite p.text-gray,
.bg-azurite h1.text-gray,
.bg-azurite h2.text-gray,
.bg-azurite h3.text-gray,
.bg-azurite h4.text-gray,
.bg-azurite h5.text-gray,
.bg-azurite h6.text-gray,
.bg-azurite .h1.text-gray,
.bg-azurite .h2.text-gray,
.bg-azurite .h3.text-gray,
.bg-azurite .h4.text-gray,
.bg-azurite .h5.text-gray,
.bg-azurite .h6.text-gray {
  color: #6c757d;
}

.bg-azurite p.text-gray-dark,
.bg-azurite h1.text-gray-dark,
.bg-azurite h2.text-gray-dark,
.bg-azurite h3.text-gray-dark,
.bg-azurite h4.text-gray-dark,
.bg-azurite h5.text-gray-dark,
.bg-azurite h6.text-gray-dark,
.bg-azurite .h1.text-gray-dark,
.bg-azurite .h2.text-gray-dark,
.bg-azurite .h3.text-gray-dark,
.bg-azurite .h4.text-gray-dark,
.bg-azurite .h5.text-gray-dark,
.bg-azurite .h6.text-gray-dark {
  color: #343a40;
}

.bg-azurite p.text-bloom,
.bg-azurite h1.text-bloom,
.bg-azurite h2.text-bloom,
.bg-azurite h3.text-bloom,
.bg-azurite h4.text-bloom,
.bg-azurite h5.text-bloom,
.bg-azurite h6.text-bloom,
.bg-azurite .h1.text-bloom,
.bg-azurite .h2.text-bloom,
.bg-azurite .h3.text-bloom,
.bg-azurite .h4.text-bloom,
.bg-azurite .h5.text-bloom,
.bg-azurite .h6.text-bloom {
  color: #ef4056;
}

.bg-azurite p.text-chili,
.bg-azurite h1.text-chili,
.bg-azurite h2.text-chili,
.bg-azurite h3.text-chili,
.bg-azurite h4.text-chili,
.bg-azurite h5.text-chili,
.bg-azurite h6.text-chili,
.bg-azurite .h1.text-chili,
.bg-azurite .h2.text-chili,
.bg-azurite .h3.text-chili,
.bg-azurite .h4.text-chili,
.bg-azurite .h5.text-chili,
.bg-azurite .h6.text-chili {
  color: #8b0015;
}

.bg-azurite p.text-sky,
.bg-azurite h1.text-sky,
.bg-azurite h2.text-sky,
.bg-azurite h3.text-sky,
.bg-azurite h4.text-sky,
.bg-azurite h5.text-sky,
.bg-azurite h6.text-sky,
.bg-azurite .h1.text-sky,
.bg-azurite .h2.text-sky,
.bg-azurite .h3.text-sky,
.bg-azurite .h4.text-sky,
.bg-azurite .h5.text-sky,
.bg-azurite .h6.text-sky {
  color: #81d3eb;
}

.bg-azurite p.text-oasis,
.bg-azurite h1.text-oasis,
.bg-azurite h2.text-oasis,
.bg-azurite h3.text-oasis,
.bg-azurite h4.text-oasis,
.bg-azurite h5.text-oasis,
.bg-azurite h6.text-oasis,
.bg-azurite .h1.text-oasis,
.bg-azurite .h2.text-oasis,
.bg-azurite .h3.text-oasis,
.bg-azurite .h4.text-oasis,
.bg-azurite .h5.text-oasis,
.bg-azurite .h6.text-oasis {
  color: #378dbd;
}

.bg-azurite p.text-azurite,
.bg-azurite h1.text-azurite,
.bg-azurite h2.text-azurite,
.bg-azurite h3.text-azurite,
.bg-azurite h4.text-azurite,
.bg-azurite h5.text-azurite,
.bg-azurite h6.text-azurite,
.bg-azurite .h1.text-azurite,
.bg-azurite .h2.text-azurite,
.bg-azurite .h3.text-azurite,
.bg-azurite .h4.text-azurite,
.bg-azurite .h5.text-azurite,
.bg-azurite .h6.text-azurite {
  color: #1e5288;
}

.bg-azurite p.text-midnight,
.bg-azurite h1.text-midnight,
.bg-azurite h2.text-midnight,
.bg-azurite h3.text-midnight,
.bg-azurite h4.text-midnight,
.bg-azurite h5.text-midnight,
.bg-azurite h6.text-midnight,
.bg-azurite .h1.text-midnight,
.bg-azurite .h2.text-midnight,
.bg-azurite .h3.text-midnight,
.bg-azurite .h4.text-midnight,
.bg-azurite .h5.text-midnight,
.bg-azurite .h6.text-midnight {
  color: #001c48;
}

.bg-azurite p.text-cool-gray,
.bg-azurite h1.text-cool-gray,
.bg-azurite h2.text-cool-gray,
.bg-azurite h3.text-cool-gray,
.bg-azurite h4.text-cool-gray,
.bg-azurite h5.text-cool-gray,
.bg-azurite h6.text-cool-gray,
.bg-azurite .h1.text-cool-gray,
.bg-azurite .h2.text-cool-gray,
.bg-azurite .h3.text-cool-gray,
.bg-azurite .h4.text-cool-gray,
.bg-azurite .h5.text-cool-gray,
.bg-azurite .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-azurite p.text-warm-gray,
.bg-azurite h1.text-warm-gray,
.bg-azurite h2.text-warm-gray,
.bg-azurite h3.text-warm-gray,
.bg-azurite h4.text-warm-gray,
.bg-azurite h5.text-warm-gray,
.bg-azurite h6.text-warm-gray,
.bg-azurite .h1.text-warm-gray,
.bg-azurite .h2.text-warm-gray,
.bg-azurite .h3.text-warm-gray,
.bg-azurite .h4.text-warm-gray,
.bg-azurite .h5.text-warm-gray,
.bg-azurite .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-azurite p.text-leaf,
.bg-azurite h1.text-leaf,
.bg-azurite h2.text-leaf,
.bg-azurite h3.text-leaf,
.bg-azurite h4.text-leaf,
.bg-azurite h5.text-leaf,
.bg-azurite h6.text-leaf,
.bg-azurite .h1.text-leaf,
.bg-azurite .h2.text-leaf,
.bg-azurite .h3.text-leaf,
.bg-azurite .h4.text-leaf,
.bg-azurite .h5.text-leaf,
.bg-azurite .h6.text-leaf {
  color: #70b865;
}

.bg-azurite p.text-river,
.bg-azurite h1.text-river,
.bg-azurite h2.text-river,
.bg-azurite h3.text-river,
.bg-azurite h4.text-river,
.bg-azurite h5.text-river,
.bg-azurite h6.text-river,
.bg-azurite .h1.text-river,
.bg-azurite .h2.text-river,
.bg-azurite .h3.text-river,
.bg-azurite .h4.text-river,
.bg-azurite .h5.text-river,
.bg-azurite .h6.text-river {
  color: #007d84;
}

.bg-azurite p.text-silver,
.bg-azurite h1.text-silver,
.bg-azurite h2.text-silver,
.bg-azurite h3.text-silver,
.bg-azurite h4.text-silver,
.bg-azurite h5.text-silver,
.bg-azurite h6.text-silver,
.bg-azurite .h1.text-silver,
.bg-azurite .h2.text-silver,
.bg-azurite .h3.text-silver,
.bg-azurite .h4.text-silver,
.bg-azurite .h5.text-silver,
.bg-azurite .h6.text-silver {
  color: #9eabae;
}

.bg-azurite p.text-mesa,
.bg-azurite h1.text-mesa,
.bg-azurite h2.text-mesa,
.bg-azurite h3.text-mesa,
.bg-azurite h4.text-mesa,
.bg-azurite h5.text-mesa,
.bg-azurite h6.text-mesa,
.bg-azurite .h1.text-mesa,
.bg-azurite .h2.text-mesa,
.bg-azurite .h3.text-mesa,
.bg-azurite .h4.text-mesa,
.bg-azurite .h5.text-mesa,
.bg-azurite .h6.text-mesa {
  color: #a95c42;
}

.bg-azurite p.text-ash,
.bg-azurite h1.text-ash,
.bg-azurite h2.text-ash,
.bg-azurite h3.text-ash,
.bg-azurite h4.text-ash,
.bg-azurite h5.text-ash,
.bg-azurite h6.text-ash,
.bg-azurite .h1.text-ash,
.bg-azurite .h2.text-ash,
.bg-azurite .h3.text-ash,
.bg-azurite .h4.text-ash,
.bg-azurite .h5.text-ash,
.bg-azurite .h6.text-ash {
  color: #403635;
}

.bg-azurite p.text-sage,
.bg-azurite h1.text-sage,
.bg-azurite h2.text-sage,
.bg-azurite h3.text-sage,
.bg-azurite h4.text-sage,
.bg-azurite h5.text-sage,
.bg-azurite h6.text-sage,
.bg-azurite .h1.text-sage,
.bg-azurite .h2.text-sage,
.bg-azurite .h3.text-sage,
.bg-azurite .h4.text-sage,
.bg-azurite .h5.text-sage,
.bg-azurite .h6.text-sage {
  color: #4a634e;
}

.bg-azurite p.text-black,
.bg-azurite h1.text-black,
.bg-azurite h2.text-black,
.bg-azurite h3.text-black,
.bg-azurite h4.text-black,
.bg-azurite h5.text-black,
.bg-azurite h6.text-black,
.bg-azurite .h1.text-black,
.bg-azurite .h2.text-black,
.bg-azurite .h3.text-black,
.bg-azurite .h4.text-black,
.bg-azurite .h5.text-black,
.bg-azurite .h6.text-black {
  color: #000;
}

.bg-azurite p.text-primary,
.bg-azurite h1.text-primary,
.bg-azurite h2.text-primary,
.bg-azurite h3.text-primary,
.bg-azurite h4.text-primary,
.bg-azurite h5.text-primary,
.bg-azurite h6.text-primary,
.bg-azurite .h1.text-primary,
.bg-azurite .h2.text-primary,
.bg-azurite .h3.text-primary,
.bg-azurite .h4.text-primary,
.bg-azurite .h5.text-primary,
.bg-azurite .h6.text-primary {
  color: #1e5288;
}

.bg-azurite p.text-secondary,
.bg-azurite h1.text-secondary,
.bg-azurite h2.text-secondary,
.bg-azurite h3.text-secondary,
.bg-azurite h4.text-secondary,
.bg-azurite h5.text-secondary,
.bg-azurite h6.text-secondary,
.bg-azurite .h1.text-secondary,
.bg-azurite .h2.text-secondary,
.bg-azurite .h3.text-secondary,
.bg-azurite .h4.text-secondary,
.bg-azurite .h5.text-secondary,
.bg-azurite .h6.text-secondary {
  color: #9eabae;
}

.bg-azurite p.text-success,
.bg-azurite h1.text-success,
.bg-azurite h2.text-success,
.bg-azurite h3.text-success,
.bg-azurite h4.text-success,
.bg-azurite h5.text-success,
.bg-azurite h6.text-success,
.bg-azurite .h1.text-success,
.bg-azurite .h2.text-success,
.bg-azurite .h3.text-success,
.bg-azurite .h4.text-success,
.bg-azurite .h5.text-success,
.bg-azurite .h6.text-success {
  color: #70b865;
}

.bg-azurite p.text-info,
.bg-azurite h1.text-info,
.bg-azurite h2.text-info,
.bg-azurite h3.text-info,
.bg-azurite h4.text-info,
.bg-azurite h5.text-info,
.bg-azurite h6.text-info,
.bg-azurite .h1.text-info,
.bg-azurite .h2.text-info,
.bg-azurite .h3.text-info,
.bg-azurite .h4.text-info,
.bg-azurite .h5.text-info,
.bg-azurite .h6.text-info {
  color: #81d3eb;
}

.bg-azurite p.text-warning,
.bg-azurite h1.text-warning,
.bg-azurite h2.text-warning,
.bg-azurite h3.text-warning,
.bg-azurite h4.text-warning,
.bg-azurite h5.text-warning,
.bg-azurite h6.text-warning,
.bg-azurite .h1.text-warning,
.bg-azurite .h2.text-warning,
.bg-azurite .h3.text-warning,
.bg-azurite .h4.text-warning,
.bg-azurite .h5.text-warning,
.bg-azurite .h6.text-warning {
  color: #f19e1f;
}

.bg-azurite p.text-danger,
.bg-azurite h1.text-danger,
.bg-azurite h2.text-danger,
.bg-azurite h3.text-danger,
.bg-azurite h4.text-danger,
.bg-azurite h5.text-danger,
.bg-azurite h6.text-danger,
.bg-azurite .h1.text-danger,
.bg-azurite .h2.text-danger,
.bg-azurite .h3.text-danger,
.bg-azurite .h4.text-danger,
.bg-azurite .h5.text-danger,
.bg-azurite .h6.text-danger {
  color: #ef4056;
}

.bg-azurite p.text-light,
.bg-azurite h1.text-light,
.bg-azurite h2.text-light,
.bg-azurite h3.text-light,
.bg-azurite h4.text-light,
.bg-azurite h5.text-light,
.bg-azurite h6.text-light,
.bg-azurite .h1.text-light,
.bg-azurite .h2.text-light,
.bg-azurite .h3.text-light,
.bg-azurite .h4.text-light,
.bg-azurite .h5.text-light,
.bg-azurite .h6.text-light {
  color: #dee2e6;
}

.bg-azurite p.text-dark,
.bg-azurite h1.text-dark,
.bg-azurite h2.text-dark,
.bg-azurite h3.text-dark,
.bg-azurite h4.text-dark,
.bg-azurite h5.text-dark,
.bg-azurite h6.text-dark,
.bg-azurite .h1.text-dark,
.bg-azurite .h2.text-dark,
.bg-azurite .h3.text-dark,
.bg-azurite .h4.text-dark,
.bg-azurite .h5.text-dark,
.bg-azurite .h6.text-dark {
  color: #343a40;
}

.bg-azurite p.text-primary-dark,
.bg-azurite h1.text-primary-dark,
.bg-azurite h2.text-primary-dark,
.bg-azurite h3.text-primary-dark,
.bg-azurite h4.text-primary-dark,
.bg-azurite h5.text-primary-dark,
.bg-azurite h6.text-primary-dark,
.bg-azurite .h1.text-primary-dark,
.bg-azurite .h2.text-primary-dark,
.bg-azurite .h3.text-primary-dark,
.bg-azurite .h4.text-primary-dark,
.bg-azurite .h5.text-primary-dark,
.bg-azurite .h6.text-primary-dark {
  color: #0c234b;
}

.bg-azurite p.text-primary-light,
.bg-azurite h1.text-primary-light,
.bg-azurite h2.text-primary-light,
.bg-azurite h3.text-primary-light,
.bg-azurite h4.text-primary-light,
.bg-azurite h5.text-primary-light,
.bg-azurite h6.text-primary-light,
.bg-azurite .h1.text-primary-light,
.bg-azurite .h2.text-primary-light,
.bg-azurite .h3.text-primary-light,
.bg-azurite .h4.text-primary-light,
.bg-azurite .h5.text-primary-light,
.bg-azurite .h6.text-primary-light {
  color: #378dbd;
}

.bg-azurite p.text-accent,
.bg-azurite h1.text-accent,
.bg-azurite h2.text-accent,
.bg-azurite h3.text-accent,
.bg-azurite h4.text-accent,
.bg-azurite h5.text-accent,
.bg-azurite h6.text-accent,
.bg-azurite .h1.text-accent,
.bg-azurite .h2.text-accent,
.bg-azurite .h3.text-accent,
.bg-azurite .h4.text-accent,
.bg-azurite .h5.text-accent,
.bg-azurite .h6.text-accent {
  color: #ab0520;
}

.bg-midnight {
  color: #fff;
  background-color: #001c48;
  color: #fff;
}

.bg-midnight p,
.bg-midnight h1,
.bg-midnight h2,
.bg-midnight h3,
.bg-midnight h4,
.bg-midnight h5,
.bg-midnight h6,
.bg-midnight .h1,
.bg-midnight .h2,
.bg-midnight .h3,
.bg-midnight .h4,
.bg-midnight .h5,
.bg-midnight .h6 {
  color: #fff;
}

.bg-midnight p.text-blue,
.bg-midnight h1.text-blue,
.bg-midnight h2.text-blue,
.bg-midnight h3.text-blue,
.bg-midnight h4.text-blue,
.bg-midnight h5.text-blue,
.bg-midnight h6.text-blue,
.bg-midnight .h1.text-blue,
.bg-midnight .h2.text-blue,
.bg-midnight .h3.text-blue,
.bg-midnight .h4.text-blue,
.bg-midnight .h5.text-blue,
.bg-midnight .h6.text-blue {
  color: #0c234b;
}

.bg-midnight p.text-indigo,
.bg-midnight h1.text-indigo,
.bg-midnight h2.text-indigo,
.bg-midnight h3.text-indigo,
.bg-midnight h4.text-indigo,
.bg-midnight h5.text-indigo,
.bg-midnight h6.text-indigo,
.bg-midnight .h1.text-indigo,
.bg-midnight .h2.text-indigo,
.bg-midnight .h3.text-indigo,
.bg-midnight .h4.text-indigo,
.bg-midnight .h5.text-indigo,
.bg-midnight .h6.text-indigo {
  color: #6610f2;
}

.bg-midnight p.text-purple,
.bg-midnight h1.text-purple,
.bg-midnight h2.text-purple,
.bg-midnight h3.text-purple,
.bg-midnight h4.text-purple,
.bg-midnight h5.text-purple,
.bg-midnight h6.text-purple,
.bg-midnight .h1.text-purple,
.bg-midnight .h2.text-purple,
.bg-midnight .h3.text-purple,
.bg-midnight .h4.text-purple,
.bg-midnight .h5.text-purple,
.bg-midnight .h6.text-purple {
  color: #6f42c1;
}

.bg-midnight p.text-pink,
.bg-midnight h1.text-pink,
.bg-midnight h2.text-pink,
.bg-midnight h3.text-pink,
.bg-midnight h4.text-pink,
.bg-midnight h5.text-pink,
.bg-midnight h6.text-pink,
.bg-midnight .h1.text-pink,
.bg-midnight .h2.text-pink,
.bg-midnight .h3.text-pink,
.bg-midnight .h4.text-pink,
.bg-midnight .h5.text-pink,
.bg-midnight .h6.text-pink {
  color: #e83e8c;
}

.bg-midnight p.text-red,
.bg-midnight h1.text-red,
.bg-midnight h2.text-red,
.bg-midnight h3.text-red,
.bg-midnight h4.text-red,
.bg-midnight h5.text-red,
.bg-midnight h6.text-red,
.bg-midnight .h1.text-red,
.bg-midnight .h2.text-red,
.bg-midnight .h3.text-red,
.bg-midnight .h4.text-red,
.bg-midnight .h5.text-red,
.bg-midnight .h6.text-red {
  color: #ab0520;
}

.bg-midnight p.text-orange,
.bg-midnight h1.text-orange,
.bg-midnight h2.text-orange,
.bg-midnight h3.text-orange,
.bg-midnight h4.text-orange,
.bg-midnight h5.text-orange,
.bg-midnight h6.text-orange,
.bg-midnight .h1.text-orange,
.bg-midnight .h2.text-orange,
.bg-midnight .h3.text-orange,
.bg-midnight .h4.text-orange,
.bg-midnight .h5.text-orange,
.bg-midnight .h6.text-orange {
  color: #fd7e14;
}

.bg-midnight p.text-yellow,
.bg-midnight h1.text-yellow,
.bg-midnight h2.text-yellow,
.bg-midnight h3.text-yellow,
.bg-midnight h4.text-yellow,
.bg-midnight h5.text-yellow,
.bg-midnight h6.text-yellow,
.bg-midnight .h1.text-yellow,
.bg-midnight .h2.text-yellow,
.bg-midnight .h3.text-yellow,
.bg-midnight .h4.text-yellow,
.bg-midnight .h5.text-yellow,
.bg-midnight .h6.text-yellow {
  color: #ffc107;
}

.bg-midnight p.text-green,
.bg-midnight h1.text-green,
.bg-midnight h2.text-green,
.bg-midnight h3.text-green,
.bg-midnight h4.text-green,
.bg-midnight h5.text-green,
.bg-midnight h6.text-green,
.bg-midnight .h1.text-green,
.bg-midnight .h2.text-green,
.bg-midnight .h3.text-green,
.bg-midnight .h4.text-green,
.bg-midnight .h5.text-green,
.bg-midnight .h6.text-green {
  color: #28a745;
}

.bg-midnight p.text-teal,
.bg-midnight h1.text-teal,
.bg-midnight h2.text-teal,
.bg-midnight h3.text-teal,
.bg-midnight h4.text-teal,
.bg-midnight h5.text-teal,
.bg-midnight h6.text-teal,
.bg-midnight .h1.text-teal,
.bg-midnight .h2.text-teal,
.bg-midnight .h3.text-teal,
.bg-midnight .h4.text-teal,
.bg-midnight .h5.text-teal,
.bg-midnight .h6.text-teal {
  color: #20c997;
}

.bg-midnight p.text-cyan,
.bg-midnight h1.text-cyan,
.bg-midnight h2.text-cyan,
.bg-midnight h3.text-cyan,
.bg-midnight h4.text-cyan,
.bg-midnight h5.text-cyan,
.bg-midnight h6.text-cyan,
.bg-midnight .h1.text-cyan,
.bg-midnight .h2.text-cyan,
.bg-midnight .h3.text-cyan,
.bg-midnight .h4.text-cyan,
.bg-midnight .h5.text-cyan,
.bg-midnight .h6.text-cyan {
  color: #17a2b8;
}

.bg-midnight p.text-white,
.bg-midnight h1.text-white,
.bg-midnight h2.text-white,
.bg-midnight h3.text-white,
.bg-midnight h4.text-white,
.bg-midnight h5.text-white,
.bg-midnight h6.text-white,
.bg-midnight .h1.text-white,
.bg-midnight .h2.text-white,
.bg-midnight .h3.text-white,
.bg-midnight .h4.text-white,
.bg-midnight .h5.text-white,
.bg-midnight .h6.text-white {
  color: #fff;
}

.bg-midnight p.text-gray,
.bg-midnight h1.text-gray,
.bg-midnight h2.text-gray,
.bg-midnight h3.text-gray,
.bg-midnight h4.text-gray,
.bg-midnight h5.text-gray,
.bg-midnight h6.text-gray,
.bg-midnight .h1.text-gray,
.bg-midnight .h2.text-gray,
.bg-midnight .h3.text-gray,
.bg-midnight .h4.text-gray,
.bg-midnight .h5.text-gray,
.bg-midnight .h6.text-gray {
  color: #6c757d;
}

.bg-midnight p.text-gray-dark,
.bg-midnight h1.text-gray-dark,
.bg-midnight h2.text-gray-dark,
.bg-midnight h3.text-gray-dark,
.bg-midnight h4.text-gray-dark,
.bg-midnight h5.text-gray-dark,
.bg-midnight h6.text-gray-dark,
.bg-midnight .h1.text-gray-dark,
.bg-midnight .h2.text-gray-dark,
.bg-midnight .h3.text-gray-dark,
.bg-midnight .h4.text-gray-dark,
.bg-midnight .h5.text-gray-dark,
.bg-midnight .h6.text-gray-dark {
  color: #343a40;
}

.bg-midnight p.text-bloom,
.bg-midnight h1.text-bloom,
.bg-midnight h2.text-bloom,
.bg-midnight h3.text-bloom,
.bg-midnight h4.text-bloom,
.bg-midnight h5.text-bloom,
.bg-midnight h6.text-bloom,
.bg-midnight .h1.text-bloom,
.bg-midnight .h2.text-bloom,
.bg-midnight .h3.text-bloom,
.bg-midnight .h4.text-bloom,
.bg-midnight .h5.text-bloom,
.bg-midnight .h6.text-bloom {
  color: #ef4056;
}

.bg-midnight p.text-chili,
.bg-midnight h1.text-chili,
.bg-midnight h2.text-chili,
.bg-midnight h3.text-chili,
.bg-midnight h4.text-chili,
.bg-midnight h5.text-chili,
.bg-midnight h6.text-chili,
.bg-midnight .h1.text-chili,
.bg-midnight .h2.text-chili,
.bg-midnight .h3.text-chili,
.bg-midnight .h4.text-chili,
.bg-midnight .h5.text-chili,
.bg-midnight .h6.text-chili {
  color: #8b0015;
}

.bg-midnight p.text-sky,
.bg-midnight h1.text-sky,
.bg-midnight h2.text-sky,
.bg-midnight h3.text-sky,
.bg-midnight h4.text-sky,
.bg-midnight h5.text-sky,
.bg-midnight h6.text-sky,
.bg-midnight .h1.text-sky,
.bg-midnight .h2.text-sky,
.bg-midnight .h3.text-sky,
.bg-midnight .h4.text-sky,
.bg-midnight .h5.text-sky,
.bg-midnight .h6.text-sky {
  color: #81d3eb;
}

.bg-midnight p.text-oasis,
.bg-midnight h1.text-oasis,
.bg-midnight h2.text-oasis,
.bg-midnight h3.text-oasis,
.bg-midnight h4.text-oasis,
.bg-midnight h5.text-oasis,
.bg-midnight h6.text-oasis,
.bg-midnight .h1.text-oasis,
.bg-midnight .h2.text-oasis,
.bg-midnight .h3.text-oasis,
.bg-midnight .h4.text-oasis,
.bg-midnight .h5.text-oasis,
.bg-midnight .h6.text-oasis {
  color: #378dbd;
}

.bg-midnight p.text-azurite,
.bg-midnight h1.text-azurite,
.bg-midnight h2.text-azurite,
.bg-midnight h3.text-azurite,
.bg-midnight h4.text-azurite,
.bg-midnight h5.text-azurite,
.bg-midnight h6.text-azurite,
.bg-midnight .h1.text-azurite,
.bg-midnight .h2.text-azurite,
.bg-midnight .h3.text-azurite,
.bg-midnight .h4.text-azurite,
.bg-midnight .h5.text-azurite,
.bg-midnight .h6.text-azurite {
  color: #1e5288;
}

.bg-midnight p.text-midnight,
.bg-midnight h1.text-midnight,
.bg-midnight h2.text-midnight,
.bg-midnight h3.text-midnight,
.bg-midnight h4.text-midnight,
.bg-midnight h5.text-midnight,
.bg-midnight h6.text-midnight,
.bg-midnight .h1.text-midnight,
.bg-midnight .h2.text-midnight,
.bg-midnight .h3.text-midnight,
.bg-midnight .h4.text-midnight,
.bg-midnight .h5.text-midnight,
.bg-midnight .h6.text-midnight {
  color: #001c48;
}

.bg-midnight p.text-cool-gray,
.bg-midnight h1.text-cool-gray,
.bg-midnight h2.text-cool-gray,
.bg-midnight h3.text-cool-gray,
.bg-midnight h4.text-cool-gray,
.bg-midnight h5.text-cool-gray,
.bg-midnight h6.text-cool-gray,
.bg-midnight .h1.text-cool-gray,
.bg-midnight .h2.text-cool-gray,
.bg-midnight .h3.text-cool-gray,
.bg-midnight .h4.text-cool-gray,
.bg-midnight .h5.text-cool-gray,
.bg-midnight .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-midnight p.text-warm-gray,
.bg-midnight h1.text-warm-gray,
.bg-midnight h2.text-warm-gray,
.bg-midnight h3.text-warm-gray,
.bg-midnight h4.text-warm-gray,
.bg-midnight h5.text-warm-gray,
.bg-midnight h6.text-warm-gray,
.bg-midnight .h1.text-warm-gray,
.bg-midnight .h2.text-warm-gray,
.bg-midnight .h3.text-warm-gray,
.bg-midnight .h4.text-warm-gray,
.bg-midnight .h5.text-warm-gray,
.bg-midnight .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-midnight p.text-leaf,
.bg-midnight h1.text-leaf,
.bg-midnight h2.text-leaf,
.bg-midnight h3.text-leaf,
.bg-midnight h4.text-leaf,
.bg-midnight h5.text-leaf,
.bg-midnight h6.text-leaf,
.bg-midnight .h1.text-leaf,
.bg-midnight .h2.text-leaf,
.bg-midnight .h3.text-leaf,
.bg-midnight .h4.text-leaf,
.bg-midnight .h5.text-leaf,
.bg-midnight .h6.text-leaf {
  color: #70b865;
}

.bg-midnight p.text-river,
.bg-midnight h1.text-river,
.bg-midnight h2.text-river,
.bg-midnight h3.text-river,
.bg-midnight h4.text-river,
.bg-midnight h5.text-river,
.bg-midnight h6.text-river,
.bg-midnight .h1.text-river,
.bg-midnight .h2.text-river,
.bg-midnight .h3.text-river,
.bg-midnight .h4.text-river,
.bg-midnight .h5.text-river,
.bg-midnight .h6.text-river {
  color: #007d84;
}

.bg-midnight p.text-silver,
.bg-midnight h1.text-silver,
.bg-midnight h2.text-silver,
.bg-midnight h3.text-silver,
.bg-midnight h4.text-silver,
.bg-midnight h5.text-silver,
.bg-midnight h6.text-silver,
.bg-midnight .h1.text-silver,
.bg-midnight .h2.text-silver,
.bg-midnight .h3.text-silver,
.bg-midnight .h4.text-silver,
.bg-midnight .h5.text-silver,
.bg-midnight .h6.text-silver {
  color: #9eabae;
}

.bg-midnight p.text-mesa,
.bg-midnight h1.text-mesa,
.bg-midnight h2.text-mesa,
.bg-midnight h3.text-mesa,
.bg-midnight h4.text-mesa,
.bg-midnight h5.text-mesa,
.bg-midnight h6.text-mesa,
.bg-midnight .h1.text-mesa,
.bg-midnight .h2.text-mesa,
.bg-midnight .h3.text-mesa,
.bg-midnight .h4.text-mesa,
.bg-midnight .h5.text-mesa,
.bg-midnight .h6.text-mesa {
  color: #a95c42;
}

.bg-midnight p.text-ash,
.bg-midnight h1.text-ash,
.bg-midnight h2.text-ash,
.bg-midnight h3.text-ash,
.bg-midnight h4.text-ash,
.bg-midnight h5.text-ash,
.bg-midnight h6.text-ash,
.bg-midnight .h1.text-ash,
.bg-midnight .h2.text-ash,
.bg-midnight .h3.text-ash,
.bg-midnight .h4.text-ash,
.bg-midnight .h5.text-ash,
.bg-midnight .h6.text-ash {
  color: #403635;
}

.bg-midnight p.text-sage,
.bg-midnight h1.text-sage,
.bg-midnight h2.text-sage,
.bg-midnight h3.text-sage,
.bg-midnight h4.text-sage,
.bg-midnight h5.text-sage,
.bg-midnight h6.text-sage,
.bg-midnight .h1.text-sage,
.bg-midnight .h2.text-sage,
.bg-midnight .h3.text-sage,
.bg-midnight .h4.text-sage,
.bg-midnight .h5.text-sage,
.bg-midnight .h6.text-sage {
  color: #4a634e;
}

.bg-midnight p.text-black,
.bg-midnight h1.text-black,
.bg-midnight h2.text-black,
.bg-midnight h3.text-black,
.bg-midnight h4.text-black,
.bg-midnight h5.text-black,
.bg-midnight h6.text-black,
.bg-midnight .h1.text-black,
.bg-midnight .h2.text-black,
.bg-midnight .h3.text-black,
.bg-midnight .h4.text-black,
.bg-midnight .h5.text-black,
.bg-midnight .h6.text-black {
  color: #000;
}

.bg-midnight p.text-primary,
.bg-midnight h1.text-primary,
.bg-midnight h2.text-primary,
.bg-midnight h3.text-primary,
.bg-midnight h4.text-primary,
.bg-midnight h5.text-primary,
.bg-midnight h6.text-primary,
.bg-midnight .h1.text-primary,
.bg-midnight .h2.text-primary,
.bg-midnight .h3.text-primary,
.bg-midnight .h4.text-primary,
.bg-midnight .h5.text-primary,
.bg-midnight .h6.text-primary {
  color: #1e5288;
}

.bg-midnight p.text-secondary,
.bg-midnight h1.text-secondary,
.bg-midnight h2.text-secondary,
.bg-midnight h3.text-secondary,
.bg-midnight h4.text-secondary,
.bg-midnight h5.text-secondary,
.bg-midnight h6.text-secondary,
.bg-midnight .h1.text-secondary,
.bg-midnight .h2.text-secondary,
.bg-midnight .h3.text-secondary,
.bg-midnight .h4.text-secondary,
.bg-midnight .h5.text-secondary,
.bg-midnight .h6.text-secondary {
  color: #9eabae;
}

.bg-midnight p.text-success,
.bg-midnight h1.text-success,
.bg-midnight h2.text-success,
.bg-midnight h3.text-success,
.bg-midnight h4.text-success,
.bg-midnight h5.text-success,
.bg-midnight h6.text-success,
.bg-midnight .h1.text-success,
.bg-midnight .h2.text-success,
.bg-midnight .h3.text-success,
.bg-midnight .h4.text-success,
.bg-midnight .h5.text-success,
.bg-midnight .h6.text-success {
  color: #70b865;
}

.bg-midnight p.text-info,
.bg-midnight h1.text-info,
.bg-midnight h2.text-info,
.bg-midnight h3.text-info,
.bg-midnight h4.text-info,
.bg-midnight h5.text-info,
.bg-midnight h6.text-info,
.bg-midnight .h1.text-info,
.bg-midnight .h2.text-info,
.bg-midnight .h3.text-info,
.bg-midnight .h4.text-info,
.bg-midnight .h5.text-info,
.bg-midnight .h6.text-info {
  color: #81d3eb;
}

.bg-midnight p.text-warning,
.bg-midnight h1.text-warning,
.bg-midnight h2.text-warning,
.bg-midnight h3.text-warning,
.bg-midnight h4.text-warning,
.bg-midnight h5.text-warning,
.bg-midnight h6.text-warning,
.bg-midnight .h1.text-warning,
.bg-midnight .h2.text-warning,
.bg-midnight .h3.text-warning,
.bg-midnight .h4.text-warning,
.bg-midnight .h5.text-warning,
.bg-midnight .h6.text-warning {
  color: #f19e1f;
}

.bg-midnight p.text-danger,
.bg-midnight h1.text-danger,
.bg-midnight h2.text-danger,
.bg-midnight h3.text-danger,
.bg-midnight h4.text-danger,
.bg-midnight h5.text-danger,
.bg-midnight h6.text-danger,
.bg-midnight .h1.text-danger,
.bg-midnight .h2.text-danger,
.bg-midnight .h3.text-danger,
.bg-midnight .h4.text-danger,
.bg-midnight .h5.text-danger,
.bg-midnight .h6.text-danger {
  color: #ef4056;
}

.bg-midnight p.text-light,
.bg-midnight h1.text-light,
.bg-midnight h2.text-light,
.bg-midnight h3.text-light,
.bg-midnight h4.text-light,
.bg-midnight h5.text-light,
.bg-midnight h6.text-light,
.bg-midnight .h1.text-light,
.bg-midnight .h2.text-light,
.bg-midnight .h3.text-light,
.bg-midnight .h4.text-light,
.bg-midnight .h5.text-light,
.bg-midnight .h6.text-light {
  color: #dee2e6;
}

.bg-midnight p.text-dark,
.bg-midnight h1.text-dark,
.bg-midnight h2.text-dark,
.bg-midnight h3.text-dark,
.bg-midnight h4.text-dark,
.bg-midnight h5.text-dark,
.bg-midnight h6.text-dark,
.bg-midnight .h1.text-dark,
.bg-midnight .h2.text-dark,
.bg-midnight .h3.text-dark,
.bg-midnight .h4.text-dark,
.bg-midnight .h5.text-dark,
.bg-midnight .h6.text-dark {
  color: #343a40;
}

.bg-midnight p.text-primary-dark,
.bg-midnight h1.text-primary-dark,
.bg-midnight h2.text-primary-dark,
.bg-midnight h3.text-primary-dark,
.bg-midnight h4.text-primary-dark,
.bg-midnight h5.text-primary-dark,
.bg-midnight h6.text-primary-dark,
.bg-midnight .h1.text-primary-dark,
.bg-midnight .h2.text-primary-dark,
.bg-midnight .h3.text-primary-dark,
.bg-midnight .h4.text-primary-dark,
.bg-midnight .h5.text-primary-dark,
.bg-midnight .h6.text-primary-dark {
  color: #0c234b;
}

.bg-midnight p.text-primary-light,
.bg-midnight h1.text-primary-light,
.bg-midnight h2.text-primary-light,
.bg-midnight h3.text-primary-light,
.bg-midnight h4.text-primary-light,
.bg-midnight h5.text-primary-light,
.bg-midnight h6.text-primary-light,
.bg-midnight .h1.text-primary-light,
.bg-midnight .h2.text-primary-light,
.bg-midnight .h3.text-primary-light,
.bg-midnight .h4.text-primary-light,
.bg-midnight .h5.text-primary-light,
.bg-midnight .h6.text-primary-light {
  color: #378dbd;
}

.bg-midnight p.text-accent,
.bg-midnight h1.text-accent,
.bg-midnight h2.text-accent,
.bg-midnight h3.text-accent,
.bg-midnight h4.text-accent,
.bg-midnight h5.text-accent,
.bg-midnight h6.text-accent,
.bg-midnight .h1.text-accent,
.bg-midnight .h2.text-accent,
.bg-midnight .h3.text-accent,
.bg-midnight .h4.text-accent,
.bg-midnight .h5.text-accent,
.bg-midnight .h6.text-accent {
  color: #ab0520;
}

.bg-cool-gray {
  color: #fff;
  background-color: #e2e9eb;
  color: #001c48;
}

.bg-cool-gray p,
.bg-cool-gray h1,
.bg-cool-gray h2,
.bg-cool-gray h3,
.bg-cool-gray h4,
.bg-cool-gray h5,
.bg-cool-gray h6,
.bg-cool-gray .h1,
.bg-cool-gray .h2,
.bg-cool-gray .h3,
.bg-cool-gray .h4,
.bg-cool-gray .h5,
.bg-cool-gray .h6 {
  color: #001c48;
}

.bg-cool-gray p.text-blue,
.bg-cool-gray h1.text-blue,
.bg-cool-gray h2.text-blue,
.bg-cool-gray h3.text-blue,
.bg-cool-gray h4.text-blue,
.bg-cool-gray h5.text-blue,
.bg-cool-gray h6.text-blue,
.bg-cool-gray .h1.text-blue,
.bg-cool-gray .h2.text-blue,
.bg-cool-gray .h3.text-blue,
.bg-cool-gray .h4.text-blue,
.bg-cool-gray .h5.text-blue,
.bg-cool-gray .h6.text-blue {
  color: #0c234b;
}

.bg-cool-gray p.text-indigo,
.bg-cool-gray h1.text-indigo,
.bg-cool-gray h2.text-indigo,
.bg-cool-gray h3.text-indigo,
.bg-cool-gray h4.text-indigo,
.bg-cool-gray h5.text-indigo,
.bg-cool-gray h6.text-indigo,
.bg-cool-gray .h1.text-indigo,
.bg-cool-gray .h2.text-indigo,
.bg-cool-gray .h3.text-indigo,
.bg-cool-gray .h4.text-indigo,
.bg-cool-gray .h5.text-indigo,
.bg-cool-gray .h6.text-indigo {
  color: #6610f2;
}

.bg-cool-gray p.text-purple,
.bg-cool-gray h1.text-purple,
.bg-cool-gray h2.text-purple,
.bg-cool-gray h3.text-purple,
.bg-cool-gray h4.text-purple,
.bg-cool-gray h5.text-purple,
.bg-cool-gray h6.text-purple,
.bg-cool-gray .h1.text-purple,
.bg-cool-gray .h2.text-purple,
.bg-cool-gray .h3.text-purple,
.bg-cool-gray .h4.text-purple,
.bg-cool-gray .h5.text-purple,
.bg-cool-gray .h6.text-purple {
  color: #6f42c1;
}

.bg-cool-gray p.text-pink,
.bg-cool-gray h1.text-pink,
.bg-cool-gray h2.text-pink,
.bg-cool-gray h3.text-pink,
.bg-cool-gray h4.text-pink,
.bg-cool-gray h5.text-pink,
.bg-cool-gray h6.text-pink,
.bg-cool-gray .h1.text-pink,
.bg-cool-gray .h2.text-pink,
.bg-cool-gray .h3.text-pink,
.bg-cool-gray .h4.text-pink,
.bg-cool-gray .h5.text-pink,
.bg-cool-gray .h6.text-pink {
  color: #e83e8c;
}

.bg-cool-gray p.text-red,
.bg-cool-gray h1.text-red,
.bg-cool-gray h2.text-red,
.bg-cool-gray h3.text-red,
.bg-cool-gray h4.text-red,
.bg-cool-gray h5.text-red,
.bg-cool-gray h6.text-red,
.bg-cool-gray .h1.text-red,
.bg-cool-gray .h2.text-red,
.bg-cool-gray .h3.text-red,
.bg-cool-gray .h4.text-red,
.bg-cool-gray .h5.text-red,
.bg-cool-gray .h6.text-red {
  color: #ab0520;
}

.bg-cool-gray p.text-orange,
.bg-cool-gray h1.text-orange,
.bg-cool-gray h2.text-orange,
.bg-cool-gray h3.text-orange,
.bg-cool-gray h4.text-orange,
.bg-cool-gray h5.text-orange,
.bg-cool-gray h6.text-orange,
.bg-cool-gray .h1.text-orange,
.bg-cool-gray .h2.text-orange,
.bg-cool-gray .h3.text-orange,
.bg-cool-gray .h4.text-orange,
.bg-cool-gray .h5.text-orange,
.bg-cool-gray .h6.text-orange {
  color: #fd7e14;
}

.bg-cool-gray p.text-yellow,
.bg-cool-gray h1.text-yellow,
.bg-cool-gray h2.text-yellow,
.bg-cool-gray h3.text-yellow,
.bg-cool-gray h4.text-yellow,
.bg-cool-gray h5.text-yellow,
.bg-cool-gray h6.text-yellow,
.bg-cool-gray .h1.text-yellow,
.bg-cool-gray .h2.text-yellow,
.bg-cool-gray .h3.text-yellow,
.bg-cool-gray .h4.text-yellow,
.bg-cool-gray .h5.text-yellow,
.bg-cool-gray .h6.text-yellow {
  color: #ffc107;
}

.bg-cool-gray p.text-green,
.bg-cool-gray h1.text-green,
.bg-cool-gray h2.text-green,
.bg-cool-gray h3.text-green,
.bg-cool-gray h4.text-green,
.bg-cool-gray h5.text-green,
.bg-cool-gray h6.text-green,
.bg-cool-gray .h1.text-green,
.bg-cool-gray .h2.text-green,
.bg-cool-gray .h3.text-green,
.bg-cool-gray .h4.text-green,
.bg-cool-gray .h5.text-green,
.bg-cool-gray .h6.text-green {
  color: #28a745;
}

.bg-cool-gray p.text-teal,
.bg-cool-gray h1.text-teal,
.bg-cool-gray h2.text-teal,
.bg-cool-gray h3.text-teal,
.bg-cool-gray h4.text-teal,
.bg-cool-gray h5.text-teal,
.bg-cool-gray h6.text-teal,
.bg-cool-gray .h1.text-teal,
.bg-cool-gray .h2.text-teal,
.bg-cool-gray .h3.text-teal,
.bg-cool-gray .h4.text-teal,
.bg-cool-gray .h5.text-teal,
.bg-cool-gray .h6.text-teal {
  color: #20c997;
}

.bg-cool-gray p.text-cyan,
.bg-cool-gray h1.text-cyan,
.bg-cool-gray h2.text-cyan,
.bg-cool-gray h3.text-cyan,
.bg-cool-gray h4.text-cyan,
.bg-cool-gray h5.text-cyan,
.bg-cool-gray h6.text-cyan,
.bg-cool-gray .h1.text-cyan,
.bg-cool-gray .h2.text-cyan,
.bg-cool-gray .h3.text-cyan,
.bg-cool-gray .h4.text-cyan,
.bg-cool-gray .h5.text-cyan,
.bg-cool-gray .h6.text-cyan {
  color: #17a2b8;
}

.bg-cool-gray p.text-white,
.bg-cool-gray h1.text-white,
.bg-cool-gray h2.text-white,
.bg-cool-gray h3.text-white,
.bg-cool-gray h4.text-white,
.bg-cool-gray h5.text-white,
.bg-cool-gray h6.text-white,
.bg-cool-gray .h1.text-white,
.bg-cool-gray .h2.text-white,
.bg-cool-gray .h3.text-white,
.bg-cool-gray .h4.text-white,
.bg-cool-gray .h5.text-white,
.bg-cool-gray .h6.text-white {
  color: #fff;
}

.bg-cool-gray p.text-gray,
.bg-cool-gray h1.text-gray,
.bg-cool-gray h2.text-gray,
.bg-cool-gray h3.text-gray,
.bg-cool-gray h4.text-gray,
.bg-cool-gray h5.text-gray,
.bg-cool-gray h6.text-gray,
.bg-cool-gray .h1.text-gray,
.bg-cool-gray .h2.text-gray,
.bg-cool-gray .h3.text-gray,
.bg-cool-gray .h4.text-gray,
.bg-cool-gray .h5.text-gray,
.bg-cool-gray .h6.text-gray {
  color: #6c757d;
}

.bg-cool-gray p.text-gray-dark,
.bg-cool-gray h1.text-gray-dark,
.bg-cool-gray h2.text-gray-dark,
.bg-cool-gray h3.text-gray-dark,
.bg-cool-gray h4.text-gray-dark,
.bg-cool-gray h5.text-gray-dark,
.bg-cool-gray h6.text-gray-dark,
.bg-cool-gray .h1.text-gray-dark,
.bg-cool-gray .h2.text-gray-dark,
.bg-cool-gray .h3.text-gray-dark,
.bg-cool-gray .h4.text-gray-dark,
.bg-cool-gray .h5.text-gray-dark,
.bg-cool-gray .h6.text-gray-dark {
  color: #343a40;
}

.bg-cool-gray p.text-bloom,
.bg-cool-gray h1.text-bloom,
.bg-cool-gray h2.text-bloom,
.bg-cool-gray h3.text-bloom,
.bg-cool-gray h4.text-bloom,
.bg-cool-gray h5.text-bloom,
.bg-cool-gray h6.text-bloom,
.bg-cool-gray .h1.text-bloom,
.bg-cool-gray .h2.text-bloom,
.bg-cool-gray .h3.text-bloom,
.bg-cool-gray .h4.text-bloom,
.bg-cool-gray .h5.text-bloom,
.bg-cool-gray .h6.text-bloom {
  color: #ef4056;
}

.bg-cool-gray p.text-chili,
.bg-cool-gray h1.text-chili,
.bg-cool-gray h2.text-chili,
.bg-cool-gray h3.text-chili,
.bg-cool-gray h4.text-chili,
.bg-cool-gray h5.text-chili,
.bg-cool-gray h6.text-chili,
.bg-cool-gray .h1.text-chili,
.bg-cool-gray .h2.text-chili,
.bg-cool-gray .h3.text-chili,
.bg-cool-gray .h4.text-chili,
.bg-cool-gray .h5.text-chili,
.bg-cool-gray .h6.text-chili {
  color: #8b0015;
}

.bg-cool-gray p.text-sky,
.bg-cool-gray h1.text-sky,
.bg-cool-gray h2.text-sky,
.bg-cool-gray h3.text-sky,
.bg-cool-gray h4.text-sky,
.bg-cool-gray h5.text-sky,
.bg-cool-gray h6.text-sky,
.bg-cool-gray .h1.text-sky,
.bg-cool-gray .h2.text-sky,
.bg-cool-gray .h3.text-sky,
.bg-cool-gray .h4.text-sky,
.bg-cool-gray .h5.text-sky,
.bg-cool-gray .h6.text-sky {
  color: #81d3eb;
}

.bg-cool-gray p.text-oasis,
.bg-cool-gray h1.text-oasis,
.bg-cool-gray h2.text-oasis,
.bg-cool-gray h3.text-oasis,
.bg-cool-gray h4.text-oasis,
.bg-cool-gray h5.text-oasis,
.bg-cool-gray h6.text-oasis,
.bg-cool-gray .h1.text-oasis,
.bg-cool-gray .h2.text-oasis,
.bg-cool-gray .h3.text-oasis,
.bg-cool-gray .h4.text-oasis,
.bg-cool-gray .h5.text-oasis,
.bg-cool-gray .h6.text-oasis {
  color: #378dbd;
}

.bg-cool-gray p.text-azurite,
.bg-cool-gray h1.text-azurite,
.bg-cool-gray h2.text-azurite,
.bg-cool-gray h3.text-azurite,
.bg-cool-gray h4.text-azurite,
.bg-cool-gray h5.text-azurite,
.bg-cool-gray h6.text-azurite,
.bg-cool-gray .h1.text-azurite,
.bg-cool-gray .h2.text-azurite,
.bg-cool-gray .h3.text-azurite,
.bg-cool-gray .h4.text-azurite,
.bg-cool-gray .h5.text-azurite,
.bg-cool-gray .h6.text-azurite {
  color: #1e5288;
}

.bg-cool-gray p.text-midnight,
.bg-cool-gray h1.text-midnight,
.bg-cool-gray h2.text-midnight,
.bg-cool-gray h3.text-midnight,
.bg-cool-gray h4.text-midnight,
.bg-cool-gray h5.text-midnight,
.bg-cool-gray h6.text-midnight,
.bg-cool-gray .h1.text-midnight,
.bg-cool-gray .h2.text-midnight,
.bg-cool-gray .h3.text-midnight,
.bg-cool-gray .h4.text-midnight,
.bg-cool-gray .h5.text-midnight,
.bg-cool-gray .h6.text-midnight {
  color: #001c48;
}

.bg-cool-gray p.text-cool-gray,
.bg-cool-gray h1.text-cool-gray,
.bg-cool-gray h2.text-cool-gray,
.bg-cool-gray h3.text-cool-gray,
.bg-cool-gray h4.text-cool-gray,
.bg-cool-gray h5.text-cool-gray,
.bg-cool-gray h6.text-cool-gray,
.bg-cool-gray .h1.text-cool-gray,
.bg-cool-gray .h2.text-cool-gray,
.bg-cool-gray .h3.text-cool-gray,
.bg-cool-gray .h4.text-cool-gray,
.bg-cool-gray .h5.text-cool-gray,
.bg-cool-gray .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-cool-gray p.text-warm-gray,
.bg-cool-gray h1.text-warm-gray,
.bg-cool-gray h2.text-warm-gray,
.bg-cool-gray h3.text-warm-gray,
.bg-cool-gray h4.text-warm-gray,
.bg-cool-gray h5.text-warm-gray,
.bg-cool-gray h6.text-warm-gray,
.bg-cool-gray .h1.text-warm-gray,
.bg-cool-gray .h2.text-warm-gray,
.bg-cool-gray .h3.text-warm-gray,
.bg-cool-gray .h4.text-warm-gray,
.bg-cool-gray .h5.text-warm-gray,
.bg-cool-gray .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-cool-gray p.text-leaf,
.bg-cool-gray h1.text-leaf,
.bg-cool-gray h2.text-leaf,
.bg-cool-gray h3.text-leaf,
.bg-cool-gray h4.text-leaf,
.bg-cool-gray h5.text-leaf,
.bg-cool-gray h6.text-leaf,
.bg-cool-gray .h1.text-leaf,
.bg-cool-gray .h2.text-leaf,
.bg-cool-gray .h3.text-leaf,
.bg-cool-gray .h4.text-leaf,
.bg-cool-gray .h5.text-leaf,
.bg-cool-gray .h6.text-leaf {
  color: #70b865;
}

.bg-cool-gray p.text-river,
.bg-cool-gray h1.text-river,
.bg-cool-gray h2.text-river,
.bg-cool-gray h3.text-river,
.bg-cool-gray h4.text-river,
.bg-cool-gray h5.text-river,
.bg-cool-gray h6.text-river,
.bg-cool-gray .h1.text-river,
.bg-cool-gray .h2.text-river,
.bg-cool-gray .h3.text-river,
.bg-cool-gray .h4.text-river,
.bg-cool-gray .h5.text-river,
.bg-cool-gray .h6.text-river {
  color: #007d84;
}

.bg-cool-gray p.text-silver,
.bg-cool-gray h1.text-silver,
.bg-cool-gray h2.text-silver,
.bg-cool-gray h3.text-silver,
.bg-cool-gray h4.text-silver,
.bg-cool-gray h5.text-silver,
.bg-cool-gray h6.text-silver,
.bg-cool-gray .h1.text-silver,
.bg-cool-gray .h2.text-silver,
.bg-cool-gray .h3.text-silver,
.bg-cool-gray .h4.text-silver,
.bg-cool-gray .h5.text-silver,
.bg-cool-gray .h6.text-silver {
  color: #9eabae;
}

.bg-cool-gray p.text-mesa,
.bg-cool-gray h1.text-mesa,
.bg-cool-gray h2.text-mesa,
.bg-cool-gray h3.text-mesa,
.bg-cool-gray h4.text-mesa,
.bg-cool-gray h5.text-mesa,
.bg-cool-gray h6.text-mesa,
.bg-cool-gray .h1.text-mesa,
.bg-cool-gray .h2.text-mesa,
.bg-cool-gray .h3.text-mesa,
.bg-cool-gray .h4.text-mesa,
.bg-cool-gray .h5.text-mesa,
.bg-cool-gray .h6.text-mesa {
  color: #a95c42;
}

.bg-cool-gray p.text-ash,
.bg-cool-gray h1.text-ash,
.bg-cool-gray h2.text-ash,
.bg-cool-gray h3.text-ash,
.bg-cool-gray h4.text-ash,
.bg-cool-gray h5.text-ash,
.bg-cool-gray h6.text-ash,
.bg-cool-gray .h1.text-ash,
.bg-cool-gray .h2.text-ash,
.bg-cool-gray .h3.text-ash,
.bg-cool-gray .h4.text-ash,
.bg-cool-gray .h5.text-ash,
.bg-cool-gray .h6.text-ash {
  color: #403635;
}

.bg-cool-gray p.text-sage,
.bg-cool-gray h1.text-sage,
.bg-cool-gray h2.text-sage,
.bg-cool-gray h3.text-sage,
.bg-cool-gray h4.text-sage,
.bg-cool-gray h5.text-sage,
.bg-cool-gray h6.text-sage,
.bg-cool-gray .h1.text-sage,
.bg-cool-gray .h2.text-sage,
.bg-cool-gray .h3.text-sage,
.bg-cool-gray .h4.text-sage,
.bg-cool-gray .h5.text-sage,
.bg-cool-gray .h6.text-sage {
  color: #4a634e;
}

.bg-cool-gray p.text-black,
.bg-cool-gray h1.text-black,
.bg-cool-gray h2.text-black,
.bg-cool-gray h3.text-black,
.bg-cool-gray h4.text-black,
.bg-cool-gray h5.text-black,
.bg-cool-gray h6.text-black,
.bg-cool-gray .h1.text-black,
.bg-cool-gray .h2.text-black,
.bg-cool-gray .h3.text-black,
.bg-cool-gray .h4.text-black,
.bg-cool-gray .h5.text-black,
.bg-cool-gray .h6.text-black {
  color: #000;
}

.bg-cool-gray p.text-primary,
.bg-cool-gray h1.text-primary,
.bg-cool-gray h2.text-primary,
.bg-cool-gray h3.text-primary,
.bg-cool-gray h4.text-primary,
.bg-cool-gray h5.text-primary,
.bg-cool-gray h6.text-primary,
.bg-cool-gray .h1.text-primary,
.bg-cool-gray .h2.text-primary,
.bg-cool-gray .h3.text-primary,
.bg-cool-gray .h4.text-primary,
.bg-cool-gray .h5.text-primary,
.bg-cool-gray .h6.text-primary {
  color: #1e5288;
}

.bg-cool-gray p.text-secondary,
.bg-cool-gray h1.text-secondary,
.bg-cool-gray h2.text-secondary,
.bg-cool-gray h3.text-secondary,
.bg-cool-gray h4.text-secondary,
.bg-cool-gray h5.text-secondary,
.bg-cool-gray h6.text-secondary,
.bg-cool-gray .h1.text-secondary,
.bg-cool-gray .h2.text-secondary,
.bg-cool-gray .h3.text-secondary,
.bg-cool-gray .h4.text-secondary,
.bg-cool-gray .h5.text-secondary,
.bg-cool-gray .h6.text-secondary {
  color: #9eabae;
}

.bg-cool-gray p.text-success,
.bg-cool-gray h1.text-success,
.bg-cool-gray h2.text-success,
.bg-cool-gray h3.text-success,
.bg-cool-gray h4.text-success,
.bg-cool-gray h5.text-success,
.bg-cool-gray h6.text-success,
.bg-cool-gray .h1.text-success,
.bg-cool-gray .h2.text-success,
.bg-cool-gray .h3.text-success,
.bg-cool-gray .h4.text-success,
.bg-cool-gray .h5.text-success,
.bg-cool-gray .h6.text-success {
  color: #70b865;
}

.bg-cool-gray p.text-info,
.bg-cool-gray h1.text-info,
.bg-cool-gray h2.text-info,
.bg-cool-gray h3.text-info,
.bg-cool-gray h4.text-info,
.bg-cool-gray h5.text-info,
.bg-cool-gray h6.text-info,
.bg-cool-gray .h1.text-info,
.bg-cool-gray .h2.text-info,
.bg-cool-gray .h3.text-info,
.bg-cool-gray .h4.text-info,
.bg-cool-gray .h5.text-info,
.bg-cool-gray .h6.text-info {
  color: #81d3eb;
}

.bg-cool-gray p.text-warning,
.bg-cool-gray h1.text-warning,
.bg-cool-gray h2.text-warning,
.bg-cool-gray h3.text-warning,
.bg-cool-gray h4.text-warning,
.bg-cool-gray h5.text-warning,
.bg-cool-gray h6.text-warning,
.bg-cool-gray .h1.text-warning,
.bg-cool-gray .h2.text-warning,
.bg-cool-gray .h3.text-warning,
.bg-cool-gray .h4.text-warning,
.bg-cool-gray .h5.text-warning,
.bg-cool-gray .h6.text-warning {
  color: #f19e1f;
}

.bg-cool-gray p.text-danger,
.bg-cool-gray h1.text-danger,
.bg-cool-gray h2.text-danger,
.bg-cool-gray h3.text-danger,
.bg-cool-gray h4.text-danger,
.bg-cool-gray h5.text-danger,
.bg-cool-gray h6.text-danger,
.bg-cool-gray .h1.text-danger,
.bg-cool-gray .h2.text-danger,
.bg-cool-gray .h3.text-danger,
.bg-cool-gray .h4.text-danger,
.bg-cool-gray .h5.text-danger,
.bg-cool-gray .h6.text-danger {
  color: #ef4056;
}

.bg-cool-gray p.text-light,
.bg-cool-gray h1.text-light,
.bg-cool-gray h2.text-light,
.bg-cool-gray h3.text-light,
.bg-cool-gray h4.text-light,
.bg-cool-gray h5.text-light,
.bg-cool-gray h6.text-light,
.bg-cool-gray .h1.text-light,
.bg-cool-gray .h2.text-light,
.bg-cool-gray .h3.text-light,
.bg-cool-gray .h4.text-light,
.bg-cool-gray .h5.text-light,
.bg-cool-gray .h6.text-light {
  color: #dee2e6;
}

.bg-cool-gray p.text-dark,
.bg-cool-gray h1.text-dark,
.bg-cool-gray h2.text-dark,
.bg-cool-gray h3.text-dark,
.bg-cool-gray h4.text-dark,
.bg-cool-gray h5.text-dark,
.bg-cool-gray h6.text-dark,
.bg-cool-gray .h1.text-dark,
.bg-cool-gray .h2.text-dark,
.bg-cool-gray .h3.text-dark,
.bg-cool-gray .h4.text-dark,
.bg-cool-gray .h5.text-dark,
.bg-cool-gray .h6.text-dark {
  color: #343a40;
}

.bg-cool-gray p.text-primary-dark,
.bg-cool-gray h1.text-primary-dark,
.bg-cool-gray h2.text-primary-dark,
.bg-cool-gray h3.text-primary-dark,
.bg-cool-gray h4.text-primary-dark,
.bg-cool-gray h5.text-primary-dark,
.bg-cool-gray h6.text-primary-dark,
.bg-cool-gray .h1.text-primary-dark,
.bg-cool-gray .h2.text-primary-dark,
.bg-cool-gray .h3.text-primary-dark,
.bg-cool-gray .h4.text-primary-dark,
.bg-cool-gray .h5.text-primary-dark,
.bg-cool-gray .h6.text-primary-dark {
  color: #0c234b;
}

.bg-cool-gray p.text-primary-light,
.bg-cool-gray h1.text-primary-light,
.bg-cool-gray h2.text-primary-light,
.bg-cool-gray h3.text-primary-light,
.bg-cool-gray h4.text-primary-light,
.bg-cool-gray h5.text-primary-light,
.bg-cool-gray h6.text-primary-light,
.bg-cool-gray .h1.text-primary-light,
.bg-cool-gray .h2.text-primary-light,
.bg-cool-gray .h3.text-primary-light,
.bg-cool-gray .h4.text-primary-light,
.bg-cool-gray .h5.text-primary-light,
.bg-cool-gray .h6.text-primary-light {
  color: #378dbd;
}

.bg-cool-gray p.text-accent,
.bg-cool-gray h1.text-accent,
.bg-cool-gray h2.text-accent,
.bg-cool-gray h3.text-accent,
.bg-cool-gray h4.text-accent,
.bg-cool-gray h5.text-accent,
.bg-cool-gray h6.text-accent,
.bg-cool-gray .h1.text-accent,
.bg-cool-gray .h2.text-accent,
.bg-cool-gray .h3.text-accent,
.bg-cool-gray .h4.text-accent,
.bg-cool-gray .h5.text-accent,
.bg-cool-gray .h6.text-accent {
  color: #ab0520;
}

.bg-warm-gray {
  color: #fff;
  background-color: #f4ede5;
  color: #001c48;
}

.bg-warm-gray p,
.bg-warm-gray h1,
.bg-warm-gray h2,
.bg-warm-gray h3,
.bg-warm-gray h4,
.bg-warm-gray h5,
.bg-warm-gray h6,
.bg-warm-gray .h1,
.bg-warm-gray .h2,
.bg-warm-gray .h3,
.bg-warm-gray .h4,
.bg-warm-gray .h5,
.bg-warm-gray .h6 {
  color: #001c48;
}

.bg-warm-gray p.text-blue,
.bg-warm-gray h1.text-blue,
.bg-warm-gray h2.text-blue,
.bg-warm-gray h3.text-blue,
.bg-warm-gray h4.text-blue,
.bg-warm-gray h5.text-blue,
.bg-warm-gray h6.text-blue,
.bg-warm-gray .h1.text-blue,
.bg-warm-gray .h2.text-blue,
.bg-warm-gray .h3.text-blue,
.bg-warm-gray .h4.text-blue,
.bg-warm-gray .h5.text-blue,
.bg-warm-gray .h6.text-blue {
  color: #0c234b;
}

.bg-warm-gray p.text-indigo,
.bg-warm-gray h1.text-indigo,
.bg-warm-gray h2.text-indigo,
.bg-warm-gray h3.text-indigo,
.bg-warm-gray h4.text-indigo,
.bg-warm-gray h5.text-indigo,
.bg-warm-gray h6.text-indigo,
.bg-warm-gray .h1.text-indigo,
.bg-warm-gray .h2.text-indigo,
.bg-warm-gray .h3.text-indigo,
.bg-warm-gray .h4.text-indigo,
.bg-warm-gray .h5.text-indigo,
.bg-warm-gray .h6.text-indigo {
  color: #6610f2;
}

.bg-warm-gray p.text-purple,
.bg-warm-gray h1.text-purple,
.bg-warm-gray h2.text-purple,
.bg-warm-gray h3.text-purple,
.bg-warm-gray h4.text-purple,
.bg-warm-gray h5.text-purple,
.bg-warm-gray h6.text-purple,
.bg-warm-gray .h1.text-purple,
.bg-warm-gray .h2.text-purple,
.bg-warm-gray .h3.text-purple,
.bg-warm-gray .h4.text-purple,
.bg-warm-gray .h5.text-purple,
.bg-warm-gray .h6.text-purple {
  color: #6f42c1;
}

.bg-warm-gray p.text-pink,
.bg-warm-gray h1.text-pink,
.bg-warm-gray h2.text-pink,
.bg-warm-gray h3.text-pink,
.bg-warm-gray h4.text-pink,
.bg-warm-gray h5.text-pink,
.bg-warm-gray h6.text-pink,
.bg-warm-gray .h1.text-pink,
.bg-warm-gray .h2.text-pink,
.bg-warm-gray .h3.text-pink,
.bg-warm-gray .h4.text-pink,
.bg-warm-gray .h5.text-pink,
.bg-warm-gray .h6.text-pink {
  color: #e83e8c;
}

.bg-warm-gray p.text-red,
.bg-warm-gray h1.text-red,
.bg-warm-gray h2.text-red,
.bg-warm-gray h3.text-red,
.bg-warm-gray h4.text-red,
.bg-warm-gray h5.text-red,
.bg-warm-gray h6.text-red,
.bg-warm-gray .h1.text-red,
.bg-warm-gray .h2.text-red,
.bg-warm-gray .h3.text-red,
.bg-warm-gray .h4.text-red,
.bg-warm-gray .h5.text-red,
.bg-warm-gray .h6.text-red {
  color: #ab0520;
}

.bg-warm-gray p.text-orange,
.bg-warm-gray h1.text-orange,
.bg-warm-gray h2.text-orange,
.bg-warm-gray h3.text-orange,
.bg-warm-gray h4.text-orange,
.bg-warm-gray h5.text-orange,
.bg-warm-gray h6.text-orange,
.bg-warm-gray .h1.text-orange,
.bg-warm-gray .h2.text-orange,
.bg-warm-gray .h3.text-orange,
.bg-warm-gray .h4.text-orange,
.bg-warm-gray .h5.text-orange,
.bg-warm-gray .h6.text-orange {
  color: #fd7e14;
}

.bg-warm-gray p.text-yellow,
.bg-warm-gray h1.text-yellow,
.bg-warm-gray h2.text-yellow,
.bg-warm-gray h3.text-yellow,
.bg-warm-gray h4.text-yellow,
.bg-warm-gray h5.text-yellow,
.bg-warm-gray h6.text-yellow,
.bg-warm-gray .h1.text-yellow,
.bg-warm-gray .h2.text-yellow,
.bg-warm-gray .h3.text-yellow,
.bg-warm-gray .h4.text-yellow,
.bg-warm-gray .h5.text-yellow,
.bg-warm-gray .h6.text-yellow {
  color: #ffc107;
}

.bg-warm-gray p.text-green,
.bg-warm-gray h1.text-green,
.bg-warm-gray h2.text-green,
.bg-warm-gray h3.text-green,
.bg-warm-gray h4.text-green,
.bg-warm-gray h5.text-green,
.bg-warm-gray h6.text-green,
.bg-warm-gray .h1.text-green,
.bg-warm-gray .h2.text-green,
.bg-warm-gray .h3.text-green,
.bg-warm-gray .h4.text-green,
.bg-warm-gray .h5.text-green,
.bg-warm-gray .h6.text-green {
  color: #28a745;
}

.bg-warm-gray p.text-teal,
.bg-warm-gray h1.text-teal,
.bg-warm-gray h2.text-teal,
.bg-warm-gray h3.text-teal,
.bg-warm-gray h4.text-teal,
.bg-warm-gray h5.text-teal,
.bg-warm-gray h6.text-teal,
.bg-warm-gray .h1.text-teal,
.bg-warm-gray .h2.text-teal,
.bg-warm-gray .h3.text-teal,
.bg-warm-gray .h4.text-teal,
.bg-warm-gray .h5.text-teal,
.bg-warm-gray .h6.text-teal {
  color: #20c997;
}

.bg-warm-gray p.text-cyan,
.bg-warm-gray h1.text-cyan,
.bg-warm-gray h2.text-cyan,
.bg-warm-gray h3.text-cyan,
.bg-warm-gray h4.text-cyan,
.bg-warm-gray h5.text-cyan,
.bg-warm-gray h6.text-cyan,
.bg-warm-gray .h1.text-cyan,
.bg-warm-gray .h2.text-cyan,
.bg-warm-gray .h3.text-cyan,
.bg-warm-gray .h4.text-cyan,
.bg-warm-gray .h5.text-cyan,
.bg-warm-gray .h6.text-cyan {
  color: #17a2b8;
}

.bg-warm-gray p.text-white,
.bg-warm-gray h1.text-white,
.bg-warm-gray h2.text-white,
.bg-warm-gray h3.text-white,
.bg-warm-gray h4.text-white,
.bg-warm-gray h5.text-white,
.bg-warm-gray h6.text-white,
.bg-warm-gray .h1.text-white,
.bg-warm-gray .h2.text-white,
.bg-warm-gray .h3.text-white,
.bg-warm-gray .h4.text-white,
.bg-warm-gray .h5.text-white,
.bg-warm-gray .h6.text-white {
  color: #fff;
}

.bg-warm-gray p.text-gray,
.bg-warm-gray h1.text-gray,
.bg-warm-gray h2.text-gray,
.bg-warm-gray h3.text-gray,
.bg-warm-gray h4.text-gray,
.bg-warm-gray h5.text-gray,
.bg-warm-gray h6.text-gray,
.bg-warm-gray .h1.text-gray,
.bg-warm-gray .h2.text-gray,
.bg-warm-gray .h3.text-gray,
.bg-warm-gray .h4.text-gray,
.bg-warm-gray .h5.text-gray,
.bg-warm-gray .h6.text-gray {
  color: #6c757d;
}

.bg-warm-gray p.text-gray-dark,
.bg-warm-gray h1.text-gray-dark,
.bg-warm-gray h2.text-gray-dark,
.bg-warm-gray h3.text-gray-dark,
.bg-warm-gray h4.text-gray-dark,
.bg-warm-gray h5.text-gray-dark,
.bg-warm-gray h6.text-gray-dark,
.bg-warm-gray .h1.text-gray-dark,
.bg-warm-gray .h2.text-gray-dark,
.bg-warm-gray .h3.text-gray-dark,
.bg-warm-gray .h4.text-gray-dark,
.bg-warm-gray .h5.text-gray-dark,
.bg-warm-gray .h6.text-gray-dark {
  color: #343a40;
}

.bg-warm-gray p.text-bloom,
.bg-warm-gray h1.text-bloom,
.bg-warm-gray h2.text-bloom,
.bg-warm-gray h3.text-bloom,
.bg-warm-gray h4.text-bloom,
.bg-warm-gray h5.text-bloom,
.bg-warm-gray h6.text-bloom,
.bg-warm-gray .h1.text-bloom,
.bg-warm-gray .h2.text-bloom,
.bg-warm-gray .h3.text-bloom,
.bg-warm-gray .h4.text-bloom,
.bg-warm-gray .h5.text-bloom,
.bg-warm-gray .h6.text-bloom {
  color: #ef4056;
}

.bg-warm-gray p.text-chili,
.bg-warm-gray h1.text-chili,
.bg-warm-gray h2.text-chili,
.bg-warm-gray h3.text-chili,
.bg-warm-gray h4.text-chili,
.bg-warm-gray h5.text-chili,
.bg-warm-gray h6.text-chili,
.bg-warm-gray .h1.text-chili,
.bg-warm-gray .h2.text-chili,
.bg-warm-gray .h3.text-chili,
.bg-warm-gray .h4.text-chili,
.bg-warm-gray .h5.text-chili,
.bg-warm-gray .h6.text-chili {
  color: #8b0015;
}

.bg-warm-gray p.text-sky,
.bg-warm-gray h1.text-sky,
.bg-warm-gray h2.text-sky,
.bg-warm-gray h3.text-sky,
.bg-warm-gray h4.text-sky,
.bg-warm-gray h5.text-sky,
.bg-warm-gray h6.text-sky,
.bg-warm-gray .h1.text-sky,
.bg-warm-gray .h2.text-sky,
.bg-warm-gray .h3.text-sky,
.bg-warm-gray .h4.text-sky,
.bg-warm-gray .h5.text-sky,
.bg-warm-gray .h6.text-sky {
  color: #81d3eb;
}

.bg-warm-gray p.text-oasis,
.bg-warm-gray h1.text-oasis,
.bg-warm-gray h2.text-oasis,
.bg-warm-gray h3.text-oasis,
.bg-warm-gray h4.text-oasis,
.bg-warm-gray h5.text-oasis,
.bg-warm-gray h6.text-oasis,
.bg-warm-gray .h1.text-oasis,
.bg-warm-gray .h2.text-oasis,
.bg-warm-gray .h3.text-oasis,
.bg-warm-gray .h4.text-oasis,
.bg-warm-gray .h5.text-oasis,
.bg-warm-gray .h6.text-oasis {
  color: #378dbd;
}

.bg-warm-gray p.text-azurite,
.bg-warm-gray h1.text-azurite,
.bg-warm-gray h2.text-azurite,
.bg-warm-gray h3.text-azurite,
.bg-warm-gray h4.text-azurite,
.bg-warm-gray h5.text-azurite,
.bg-warm-gray h6.text-azurite,
.bg-warm-gray .h1.text-azurite,
.bg-warm-gray .h2.text-azurite,
.bg-warm-gray .h3.text-azurite,
.bg-warm-gray .h4.text-azurite,
.bg-warm-gray .h5.text-azurite,
.bg-warm-gray .h6.text-azurite {
  color: #1e5288;
}

.bg-warm-gray p.text-midnight,
.bg-warm-gray h1.text-midnight,
.bg-warm-gray h2.text-midnight,
.bg-warm-gray h3.text-midnight,
.bg-warm-gray h4.text-midnight,
.bg-warm-gray h5.text-midnight,
.bg-warm-gray h6.text-midnight,
.bg-warm-gray .h1.text-midnight,
.bg-warm-gray .h2.text-midnight,
.bg-warm-gray .h3.text-midnight,
.bg-warm-gray .h4.text-midnight,
.bg-warm-gray .h5.text-midnight,
.bg-warm-gray .h6.text-midnight {
  color: #001c48;
}

.bg-warm-gray p.text-cool-gray,
.bg-warm-gray h1.text-cool-gray,
.bg-warm-gray h2.text-cool-gray,
.bg-warm-gray h3.text-cool-gray,
.bg-warm-gray h4.text-cool-gray,
.bg-warm-gray h5.text-cool-gray,
.bg-warm-gray h6.text-cool-gray,
.bg-warm-gray .h1.text-cool-gray,
.bg-warm-gray .h2.text-cool-gray,
.bg-warm-gray .h3.text-cool-gray,
.bg-warm-gray .h4.text-cool-gray,
.bg-warm-gray .h5.text-cool-gray,
.bg-warm-gray .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-warm-gray p.text-warm-gray,
.bg-warm-gray h1.text-warm-gray,
.bg-warm-gray h2.text-warm-gray,
.bg-warm-gray h3.text-warm-gray,
.bg-warm-gray h4.text-warm-gray,
.bg-warm-gray h5.text-warm-gray,
.bg-warm-gray h6.text-warm-gray,
.bg-warm-gray .h1.text-warm-gray,
.bg-warm-gray .h2.text-warm-gray,
.bg-warm-gray .h3.text-warm-gray,
.bg-warm-gray .h4.text-warm-gray,
.bg-warm-gray .h5.text-warm-gray,
.bg-warm-gray .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-warm-gray p.text-leaf,
.bg-warm-gray h1.text-leaf,
.bg-warm-gray h2.text-leaf,
.bg-warm-gray h3.text-leaf,
.bg-warm-gray h4.text-leaf,
.bg-warm-gray h5.text-leaf,
.bg-warm-gray h6.text-leaf,
.bg-warm-gray .h1.text-leaf,
.bg-warm-gray .h2.text-leaf,
.bg-warm-gray .h3.text-leaf,
.bg-warm-gray .h4.text-leaf,
.bg-warm-gray .h5.text-leaf,
.bg-warm-gray .h6.text-leaf {
  color: #70b865;
}

.bg-warm-gray p.text-river,
.bg-warm-gray h1.text-river,
.bg-warm-gray h2.text-river,
.bg-warm-gray h3.text-river,
.bg-warm-gray h4.text-river,
.bg-warm-gray h5.text-river,
.bg-warm-gray h6.text-river,
.bg-warm-gray .h1.text-river,
.bg-warm-gray .h2.text-river,
.bg-warm-gray .h3.text-river,
.bg-warm-gray .h4.text-river,
.bg-warm-gray .h5.text-river,
.bg-warm-gray .h6.text-river {
  color: #007d84;
}

.bg-warm-gray p.text-silver,
.bg-warm-gray h1.text-silver,
.bg-warm-gray h2.text-silver,
.bg-warm-gray h3.text-silver,
.bg-warm-gray h4.text-silver,
.bg-warm-gray h5.text-silver,
.bg-warm-gray h6.text-silver,
.bg-warm-gray .h1.text-silver,
.bg-warm-gray .h2.text-silver,
.bg-warm-gray .h3.text-silver,
.bg-warm-gray .h4.text-silver,
.bg-warm-gray .h5.text-silver,
.bg-warm-gray .h6.text-silver {
  color: #9eabae;
}

.bg-warm-gray p.text-mesa,
.bg-warm-gray h1.text-mesa,
.bg-warm-gray h2.text-mesa,
.bg-warm-gray h3.text-mesa,
.bg-warm-gray h4.text-mesa,
.bg-warm-gray h5.text-mesa,
.bg-warm-gray h6.text-mesa,
.bg-warm-gray .h1.text-mesa,
.bg-warm-gray .h2.text-mesa,
.bg-warm-gray .h3.text-mesa,
.bg-warm-gray .h4.text-mesa,
.bg-warm-gray .h5.text-mesa,
.bg-warm-gray .h6.text-mesa {
  color: #a95c42;
}

.bg-warm-gray p.text-ash,
.bg-warm-gray h1.text-ash,
.bg-warm-gray h2.text-ash,
.bg-warm-gray h3.text-ash,
.bg-warm-gray h4.text-ash,
.bg-warm-gray h5.text-ash,
.bg-warm-gray h6.text-ash,
.bg-warm-gray .h1.text-ash,
.bg-warm-gray .h2.text-ash,
.bg-warm-gray .h3.text-ash,
.bg-warm-gray .h4.text-ash,
.bg-warm-gray .h5.text-ash,
.bg-warm-gray .h6.text-ash {
  color: #403635;
}

.bg-warm-gray p.text-sage,
.bg-warm-gray h1.text-sage,
.bg-warm-gray h2.text-sage,
.bg-warm-gray h3.text-sage,
.bg-warm-gray h4.text-sage,
.bg-warm-gray h5.text-sage,
.bg-warm-gray h6.text-sage,
.bg-warm-gray .h1.text-sage,
.bg-warm-gray .h2.text-sage,
.bg-warm-gray .h3.text-sage,
.bg-warm-gray .h4.text-sage,
.bg-warm-gray .h5.text-sage,
.bg-warm-gray .h6.text-sage {
  color: #4a634e;
}

.bg-warm-gray p.text-black,
.bg-warm-gray h1.text-black,
.bg-warm-gray h2.text-black,
.bg-warm-gray h3.text-black,
.bg-warm-gray h4.text-black,
.bg-warm-gray h5.text-black,
.bg-warm-gray h6.text-black,
.bg-warm-gray .h1.text-black,
.bg-warm-gray .h2.text-black,
.bg-warm-gray .h3.text-black,
.bg-warm-gray .h4.text-black,
.bg-warm-gray .h5.text-black,
.bg-warm-gray .h6.text-black {
  color: #000;
}

.bg-warm-gray p.text-primary,
.bg-warm-gray h1.text-primary,
.bg-warm-gray h2.text-primary,
.bg-warm-gray h3.text-primary,
.bg-warm-gray h4.text-primary,
.bg-warm-gray h5.text-primary,
.bg-warm-gray h6.text-primary,
.bg-warm-gray .h1.text-primary,
.bg-warm-gray .h2.text-primary,
.bg-warm-gray .h3.text-primary,
.bg-warm-gray .h4.text-primary,
.bg-warm-gray .h5.text-primary,
.bg-warm-gray .h6.text-primary {
  color: #1e5288;
}

.bg-warm-gray p.text-secondary,
.bg-warm-gray h1.text-secondary,
.bg-warm-gray h2.text-secondary,
.bg-warm-gray h3.text-secondary,
.bg-warm-gray h4.text-secondary,
.bg-warm-gray h5.text-secondary,
.bg-warm-gray h6.text-secondary,
.bg-warm-gray .h1.text-secondary,
.bg-warm-gray .h2.text-secondary,
.bg-warm-gray .h3.text-secondary,
.bg-warm-gray .h4.text-secondary,
.bg-warm-gray .h5.text-secondary,
.bg-warm-gray .h6.text-secondary {
  color: #9eabae;
}

.bg-warm-gray p.text-success,
.bg-warm-gray h1.text-success,
.bg-warm-gray h2.text-success,
.bg-warm-gray h3.text-success,
.bg-warm-gray h4.text-success,
.bg-warm-gray h5.text-success,
.bg-warm-gray h6.text-success,
.bg-warm-gray .h1.text-success,
.bg-warm-gray .h2.text-success,
.bg-warm-gray .h3.text-success,
.bg-warm-gray .h4.text-success,
.bg-warm-gray .h5.text-success,
.bg-warm-gray .h6.text-success {
  color: #70b865;
}

.bg-warm-gray p.text-info,
.bg-warm-gray h1.text-info,
.bg-warm-gray h2.text-info,
.bg-warm-gray h3.text-info,
.bg-warm-gray h4.text-info,
.bg-warm-gray h5.text-info,
.bg-warm-gray h6.text-info,
.bg-warm-gray .h1.text-info,
.bg-warm-gray .h2.text-info,
.bg-warm-gray .h3.text-info,
.bg-warm-gray .h4.text-info,
.bg-warm-gray .h5.text-info,
.bg-warm-gray .h6.text-info {
  color: #81d3eb;
}

.bg-warm-gray p.text-warning,
.bg-warm-gray h1.text-warning,
.bg-warm-gray h2.text-warning,
.bg-warm-gray h3.text-warning,
.bg-warm-gray h4.text-warning,
.bg-warm-gray h5.text-warning,
.bg-warm-gray h6.text-warning,
.bg-warm-gray .h1.text-warning,
.bg-warm-gray .h2.text-warning,
.bg-warm-gray .h3.text-warning,
.bg-warm-gray .h4.text-warning,
.bg-warm-gray .h5.text-warning,
.bg-warm-gray .h6.text-warning {
  color: #f19e1f;
}

.bg-warm-gray p.text-danger,
.bg-warm-gray h1.text-danger,
.bg-warm-gray h2.text-danger,
.bg-warm-gray h3.text-danger,
.bg-warm-gray h4.text-danger,
.bg-warm-gray h5.text-danger,
.bg-warm-gray h6.text-danger,
.bg-warm-gray .h1.text-danger,
.bg-warm-gray .h2.text-danger,
.bg-warm-gray .h3.text-danger,
.bg-warm-gray .h4.text-danger,
.bg-warm-gray .h5.text-danger,
.bg-warm-gray .h6.text-danger {
  color: #ef4056;
}

.bg-warm-gray p.text-light,
.bg-warm-gray h1.text-light,
.bg-warm-gray h2.text-light,
.bg-warm-gray h3.text-light,
.bg-warm-gray h4.text-light,
.bg-warm-gray h5.text-light,
.bg-warm-gray h6.text-light,
.bg-warm-gray .h1.text-light,
.bg-warm-gray .h2.text-light,
.bg-warm-gray .h3.text-light,
.bg-warm-gray .h4.text-light,
.bg-warm-gray .h5.text-light,
.bg-warm-gray .h6.text-light {
  color: #dee2e6;
}

.bg-warm-gray p.text-dark,
.bg-warm-gray h1.text-dark,
.bg-warm-gray h2.text-dark,
.bg-warm-gray h3.text-dark,
.bg-warm-gray h4.text-dark,
.bg-warm-gray h5.text-dark,
.bg-warm-gray h6.text-dark,
.bg-warm-gray .h1.text-dark,
.bg-warm-gray .h2.text-dark,
.bg-warm-gray .h3.text-dark,
.bg-warm-gray .h4.text-dark,
.bg-warm-gray .h5.text-dark,
.bg-warm-gray .h6.text-dark {
  color: #343a40;
}

.bg-warm-gray p.text-primary-dark,
.bg-warm-gray h1.text-primary-dark,
.bg-warm-gray h2.text-primary-dark,
.bg-warm-gray h3.text-primary-dark,
.bg-warm-gray h4.text-primary-dark,
.bg-warm-gray h5.text-primary-dark,
.bg-warm-gray h6.text-primary-dark,
.bg-warm-gray .h1.text-primary-dark,
.bg-warm-gray .h2.text-primary-dark,
.bg-warm-gray .h3.text-primary-dark,
.bg-warm-gray .h4.text-primary-dark,
.bg-warm-gray .h5.text-primary-dark,
.bg-warm-gray .h6.text-primary-dark {
  color: #0c234b;
}

.bg-warm-gray p.text-primary-light,
.bg-warm-gray h1.text-primary-light,
.bg-warm-gray h2.text-primary-light,
.bg-warm-gray h3.text-primary-light,
.bg-warm-gray h4.text-primary-light,
.bg-warm-gray h5.text-primary-light,
.bg-warm-gray h6.text-primary-light,
.bg-warm-gray .h1.text-primary-light,
.bg-warm-gray .h2.text-primary-light,
.bg-warm-gray .h3.text-primary-light,
.bg-warm-gray .h4.text-primary-light,
.bg-warm-gray .h5.text-primary-light,
.bg-warm-gray .h6.text-primary-light {
  color: #378dbd;
}

.bg-warm-gray p.text-accent,
.bg-warm-gray h1.text-accent,
.bg-warm-gray h2.text-accent,
.bg-warm-gray h3.text-accent,
.bg-warm-gray h4.text-accent,
.bg-warm-gray h5.text-accent,
.bg-warm-gray h6.text-accent,
.bg-warm-gray .h1.text-accent,
.bg-warm-gray .h2.text-accent,
.bg-warm-gray .h3.text-accent,
.bg-warm-gray .h4.text-accent,
.bg-warm-gray .h5.text-accent,
.bg-warm-gray .h6.text-accent {
  color: #ab0520;
}

.bg-leaf {
  color: #fff;
  background-color: #70b865;
  color: #001c48;
}

.bg-leaf p,
.bg-leaf h1,
.bg-leaf h2,
.bg-leaf h3,
.bg-leaf h4,
.bg-leaf h5,
.bg-leaf h6,
.bg-leaf .h1,
.bg-leaf .h2,
.bg-leaf .h3,
.bg-leaf .h4,
.bg-leaf .h5,
.bg-leaf .h6 {
  color: #001c48;
}

.bg-leaf p.text-blue,
.bg-leaf h1.text-blue,
.bg-leaf h2.text-blue,
.bg-leaf h3.text-blue,
.bg-leaf h4.text-blue,
.bg-leaf h5.text-blue,
.bg-leaf h6.text-blue,
.bg-leaf .h1.text-blue,
.bg-leaf .h2.text-blue,
.bg-leaf .h3.text-blue,
.bg-leaf .h4.text-blue,
.bg-leaf .h5.text-blue,
.bg-leaf .h6.text-blue {
  color: #0c234b;
}

.bg-leaf p.text-indigo,
.bg-leaf h1.text-indigo,
.bg-leaf h2.text-indigo,
.bg-leaf h3.text-indigo,
.bg-leaf h4.text-indigo,
.bg-leaf h5.text-indigo,
.bg-leaf h6.text-indigo,
.bg-leaf .h1.text-indigo,
.bg-leaf .h2.text-indigo,
.bg-leaf .h3.text-indigo,
.bg-leaf .h4.text-indigo,
.bg-leaf .h5.text-indigo,
.bg-leaf .h6.text-indigo {
  color: #6610f2;
}

.bg-leaf p.text-purple,
.bg-leaf h1.text-purple,
.bg-leaf h2.text-purple,
.bg-leaf h3.text-purple,
.bg-leaf h4.text-purple,
.bg-leaf h5.text-purple,
.bg-leaf h6.text-purple,
.bg-leaf .h1.text-purple,
.bg-leaf .h2.text-purple,
.bg-leaf .h3.text-purple,
.bg-leaf .h4.text-purple,
.bg-leaf .h5.text-purple,
.bg-leaf .h6.text-purple {
  color: #6f42c1;
}

.bg-leaf p.text-pink,
.bg-leaf h1.text-pink,
.bg-leaf h2.text-pink,
.bg-leaf h3.text-pink,
.bg-leaf h4.text-pink,
.bg-leaf h5.text-pink,
.bg-leaf h6.text-pink,
.bg-leaf .h1.text-pink,
.bg-leaf .h2.text-pink,
.bg-leaf .h3.text-pink,
.bg-leaf .h4.text-pink,
.bg-leaf .h5.text-pink,
.bg-leaf .h6.text-pink {
  color: #e83e8c;
}

.bg-leaf p.text-red,
.bg-leaf h1.text-red,
.bg-leaf h2.text-red,
.bg-leaf h3.text-red,
.bg-leaf h4.text-red,
.bg-leaf h5.text-red,
.bg-leaf h6.text-red,
.bg-leaf .h1.text-red,
.bg-leaf .h2.text-red,
.bg-leaf .h3.text-red,
.bg-leaf .h4.text-red,
.bg-leaf .h5.text-red,
.bg-leaf .h6.text-red {
  color: #ab0520;
}

.bg-leaf p.text-orange,
.bg-leaf h1.text-orange,
.bg-leaf h2.text-orange,
.bg-leaf h3.text-orange,
.bg-leaf h4.text-orange,
.bg-leaf h5.text-orange,
.bg-leaf h6.text-orange,
.bg-leaf .h1.text-orange,
.bg-leaf .h2.text-orange,
.bg-leaf .h3.text-orange,
.bg-leaf .h4.text-orange,
.bg-leaf .h5.text-orange,
.bg-leaf .h6.text-orange {
  color: #fd7e14;
}

.bg-leaf p.text-yellow,
.bg-leaf h1.text-yellow,
.bg-leaf h2.text-yellow,
.bg-leaf h3.text-yellow,
.bg-leaf h4.text-yellow,
.bg-leaf h5.text-yellow,
.bg-leaf h6.text-yellow,
.bg-leaf .h1.text-yellow,
.bg-leaf .h2.text-yellow,
.bg-leaf .h3.text-yellow,
.bg-leaf .h4.text-yellow,
.bg-leaf .h5.text-yellow,
.bg-leaf .h6.text-yellow {
  color: #ffc107;
}

.bg-leaf p.text-green,
.bg-leaf h1.text-green,
.bg-leaf h2.text-green,
.bg-leaf h3.text-green,
.bg-leaf h4.text-green,
.bg-leaf h5.text-green,
.bg-leaf h6.text-green,
.bg-leaf .h1.text-green,
.bg-leaf .h2.text-green,
.bg-leaf .h3.text-green,
.bg-leaf .h4.text-green,
.bg-leaf .h5.text-green,
.bg-leaf .h6.text-green {
  color: #28a745;
}

.bg-leaf p.text-teal,
.bg-leaf h1.text-teal,
.bg-leaf h2.text-teal,
.bg-leaf h3.text-teal,
.bg-leaf h4.text-teal,
.bg-leaf h5.text-teal,
.bg-leaf h6.text-teal,
.bg-leaf .h1.text-teal,
.bg-leaf .h2.text-teal,
.bg-leaf .h3.text-teal,
.bg-leaf .h4.text-teal,
.bg-leaf .h5.text-teal,
.bg-leaf .h6.text-teal {
  color: #20c997;
}

.bg-leaf p.text-cyan,
.bg-leaf h1.text-cyan,
.bg-leaf h2.text-cyan,
.bg-leaf h3.text-cyan,
.bg-leaf h4.text-cyan,
.bg-leaf h5.text-cyan,
.bg-leaf h6.text-cyan,
.bg-leaf .h1.text-cyan,
.bg-leaf .h2.text-cyan,
.bg-leaf .h3.text-cyan,
.bg-leaf .h4.text-cyan,
.bg-leaf .h5.text-cyan,
.bg-leaf .h6.text-cyan {
  color: #17a2b8;
}

.bg-leaf p.text-white,
.bg-leaf h1.text-white,
.bg-leaf h2.text-white,
.bg-leaf h3.text-white,
.bg-leaf h4.text-white,
.bg-leaf h5.text-white,
.bg-leaf h6.text-white,
.bg-leaf .h1.text-white,
.bg-leaf .h2.text-white,
.bg-leaf .h3.text-white,
.bg-leaf .h4.text-white,
.bg-leaf .h5.text-white,
.bg-leaf .h6.text-white {
  color: #fff;
}

.bg-leaf p.text-gray,
.bg-leaf h1.text-gray,
.bg-leaf h2.text-gray,
.bg-leaf h3.text-gray,
.bg-leaf h4.text-gray,
.bg-leaf h5.text-gray,
.bg-leaf h6.text-gray,
.bg-leaf .h1.text-gray,
.bg-leaf .h2.text-gray,
.bg-leaf .h3.text-gray,
.bg-leaf .h4.text-gray,
.bg-leaf .h5.text-gray,
.bg-leaf .h6.text-gray {
  color: #6c757d;
}

.bg-leaf p.text-gray-dark,
.bg-leaf h1.text-gray-dark,
.bg-leaf h2.text-gray-dark,
.bg-leaf h3.text-gray-dark,
.bg-leaf h4.text-gray-dark,
.bg-leaf h5.text-gray-dark,
.bg-leaf h6.text-gray-dark,
.bg-leaf .h1.text-gray-dark,
.bg-leaf .h2.text-gray-dark,
.bg-leaf .h3.text-gray-dark,
.bg-leaf .h4.text-gray-dark,
.bg-leaf .h5.text-gray-dark,
.bg-leaf .h6.text-gray-dark {
  color: #343a40;
}

.bg-leaf p.text-bloom,
.bg-leaf h1.text-bloom,
.bg-leaf h2.text-bloom,
.bg-leaf h3.text-bloom,
.bg-leaf h4.text-bloom,
.bg-leaf h5.text-bloom,
.bg-leaf h6.text-bloom,
.bg-leaf .h1.text-bloom,
.bg-leaf .h2.text-bloom,
.bg-leaf .h3.text-bloom,
.bg-leaf .h4.text-bloom,
.bg-leaf .h5.text-bloom,
.bg-leaf .h6.text-bloom {
  color: #ef4056;
}

.bg-leaf p.text-chili,
.bg-leaf h1.text-chili,
.bg-leaf h2.text-chili,
.bg-leaf h3.text-chili,
.bg-leaf h4.text-chili,
.bg-leaf h5.text-chili,
.bg-leaf h6.text-chili,
.bg-leaf .h1.text-chili,
.bg-leaf .h2.text-chili,
.bg-leaf .h3.text-chili,
.bg-leaf .h4.text-chili,
.bg-leaf .h5.text-chili,
.bg-leaf .h6.text-chili {
  color: #8b0015;
}

.bg-leaf p.text-sky,
.bg-leaf h1.text-sky,
.bg-leaf h2.text-sky,
.bg-leaf h3.text-sky,
.bg-leaf h4.text-sky,
.bg-leaf h5.text-sky,
.bg-leaf h6.text-sky,
.bg-leaf .h1.text-sky,
.bg-leaf .h2.text-sky,
.bg-leaf .h3.text-sky,
.bg-leaf .h4.text-sky,
.bg-leaf .h5.text-sky,
.bg-leaf .h6.text-sky {
  color: #81d3eb;
}

.bg-leaf p.text-oasis,
.bg-leaf h1.text-oasis,
.bg-leaf h2.text-oasis,
.bg-leaf h3.text-oasis,
.bg-leaf h4.text-oasis,
.bg-leaf h5.text-oasis,
.bg-leaf h6.text-oasis,
.bg-leaf .h1.text-oasis,
.bg-leaf .h2.text-oasis,
.bg-leaf .h3.text-oasis,
.bg-leaf .h4.text-oasis,
.bg-leaf .h5.text-oasis,
.bg-leaf .h6.text-oasis {
  color: #378dbd;
}

.bg-leaf p.text-azurite,
.bg-leaf h1.text-azurite,
.bg-leaf h2.text-azurite,
.bg-leaf h3.text-azurite,
.bg-leaf h4.text-azurite,
.bg-leaf h5.text-azurite,
.bg-leaf h6.text-azurite,
.bg-leaf .h1.text-azurite,
.bg-leaf .h2.text-azurite,
.bg-leaf .h3.text-azurite,
.bg-leaf .h4.text-azurite,
.bg-leaf .h5.text-azurite,
.bg-leaf .h6.text-azurite {
  color: #1e5288;
}

.bg-leaf p.text-midnight,
.bg-leaf h1.text-midnight,
.bg-leaf h2.text-midnight,
.bg-leaf h3.text-midnight,
.bg-leaf h4.text-midnight,
.bg-leaf h5.text-midnight,
.bg-leaf h6.text-midnight,
.bg-leaf .h1.text-midnight,
.bg-leaf .h2.text-midnight,
.bg-leaf .h3.text-midnight,
.bg-leaf .h4.text-midnight,
.bg-leaf .h5.text-midnight,
.bg-leaf .h6.text-midnight {
  color: #001c48;
}

.bg-leaf p.text-cool-gray,
.bg-leaf h1.text-cool-gray,
.bg-leaf h2.text-cool-gray,
.bg-leaf h3.text-cool-gray,
.bg-leaf h4.text-cool-gray,
.bg-leaf h5.text-cool-gray,
.bg-leaf h6.text-cool-gray,
.bg-leaf .h1.text-cool-gray,
.bg-leaf .h2.text-cool-gray,
.bg-leaf .h3.text-cool-gray,
.bg-leaf .h4.text-cool-gray,
.bg-leaf .h5.text-cool-gray,
.bg-leaf .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-leaf p.text-warm-gray,
.bg-leaf h1.text-warm-gray,
.bg-leaf h2.text-warm-gray,
.bg-leaf h3.text-warm-gray,
.bg-leaf h4.text-warm-gray,
.bg-leaf h5.text-warm-gray,
.bg-leaf h6.text-warm-gray,
.bg-leaf .h1.text-warm-gray,
.bg-leaf .h2.text-warm-gray,
.bg-leaf .h3.text-warm-gray,
.bg-leaf .h4.text-warm-gray,
.bg-leaf .h5.text-warm-gray,
.bg-leaf .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-leaf p.text-leaf,
.bg-leaf h1.text-leaf,
.bg-leaf h2.text-leaf,
.bg-leaf h3.text-leaf,
.bg-leaf h4.text-leaf,
.bg-leaf h5.text-leaf,
.bg-leaf h6.text-leaf,
.bg-leaf .h1.text-leaf,
.bg-leaf .h2.text-leaf,
.bg-leaf .h3.text-leaf,
.bg-leaf .h4.text-leaf,
.bg-leaf .h5.text-leaf,
.bg-leaf .h6.text-leaf {
  color: #70b865;
}

.bg-leaf p.text-river,
.bg-leaf h1.text-river,
.bg-leaf h2.text-river,
.bg-leaf h3.text-river,
.bg-leaf h4.text-river,
.bg-leaf h5.text-river,
.bg-leaf h6.text-river,
.bg-leaf .h1.text-river,
.bg-leaf .h2.text-river,
.bg-leaf .h3.text-river,
.bg-leaf .h4.text-river,
.bg-leaf .h5.text-river,
.bg-leaf .h6.text-river {
  color: #007d84;
}

.bg-leaf p.text-silver,
.bg-leaf h1.text-silver,
.bg-leaf h2.text-silver,
.bg-leaf h3.text-silver,
.bg-leaf h4.text-silver,
.bg-leaf h5.text-silver,
.bg-leaf h6.text-silver,
.bg-leaf .h1.text-silver,
.bg-leaf .h2.text-silver,
.bg-leaf .h3.text-silver,
.bg-leaf .h4.text-silver,
.bg-leaf .h5.text-silver,
.bg-leaf .h6.text-silver {
  color: #9eabae;
}

.bg-leaf p.text-mesa,
.bg-leaf h1.text-mesa,
.bg-leaf h2.text-mesa,
.bg-leaf h3.text-mesa,
.bg-leaf h4.text-mesa,
.bg-leaf h5.text-mesa,
.bg-leaf h6.text-mesa,
.bg-leaf .h1.text-mesa,
.bg-leaf .h2.text-mesa,
.bg-leaf .h3.text-mesa,
.bg-leaf .h4.text-mesa,
.bg-leaf .h5.text-mesa,
.bg-leaf .h6.text-mesa {
  color: #a95c42;
}

.bg-leaf p.text-ash,
.bg-leaf h1.text-ash,
.bg-leaf h2.text-ash,
.bg-leaf h3.text-ash,
.bg-leaf h4.text-ash,
.bg-leaf h5.text-ash,
.bg-leaf h6.text-ash,
.bg-leaf .h1.text-ash,
.bg-leaf .h2.text-ash,
.bg-leaf .h3.text-ash,
.bg-leaf .h4.text-ash,
.bg-leaf .h5.text-ash,
.bg-leaf .h6.text-ash {
  color: #403635;
}

.bg-leaf p.text-sage,
.bg-leaf h1.text-sage,
.bg-leaf h2.text-sage,
.bg-leaf h3.text-sage,
.bg-leaf h4.text-sage,
.bg-leaf h5.text-sage,
.bg-leaf h6.text-sage,
.bg-leaf .h1.text-sage,
.bg-leaf .h2.text-sage,
.bg-leaf .h3.text-sage,
.bg-leaf .h4.text-sage,
.bg-leaf .h5.text-sage,
.bg-leaf .h6.text-sage {
  color: #4a634e;
}

.bg-leaf p.text-black,
.bg-leaf h1.text-black,
.bg-leaf h2.text-black,
.bg-leaf h3.text-black,
.bg-leaf h4.text-black,
.bg-leaf h5.text-black,
.bg-leaf h6.text-black,
.bg-leaf .h1.text-black,
.bg-leaf .h2.text-black,
.bg-leaf .h3.text-black,
.bg-leaf .h4.text-black,
.bg-leaf .h5.text-black,
.bg-leaf .h6.text-black {
  color: #000;
}

.bg-leaf p.text-primary,
.bg-leaf h1.text-primary,
.bg-leaf h2.text-primary,
.bg-leaf h3.text-primary,
.bg-leaf h4.text-primary,
.bg-leaf h5.text-primary,
.bg-leaf h6.text-primary,
.bg-leaf .h1.text-primary,
.bg-leaf .h2.text-primary,
.bg-leaf .h3.text-primary,
.bg-leaf .h4.text-primary,
.bg-leaf .h5.text-primary,
.bg-leaf .h6.text-primary {
  color: #1e5288;
}

.bg-leaf p.text-secondary,
.bg-leaf h1.text-secondary,
.bg-leaf h2.text-secondary,
.bg-leaf h3.text-secondary,
.bg-leaf h4.text-secondary,
.bg-leaf h5.text-secondary,
.bg-leaf h6.text-secondary,
.bg-leaf .h1.text-secondary,
.bg-leaf .h2.text-secondary,
.bg-leaf .h3.text-secondary,
.bg-leaf .h4.text-secondary,
.bg-leaf .h5.text-secondary,
.bg-leaf .h6.text-secondary {
  color: #9eabae;
}

.bg-leaf p.text-success,
.bg-leaf h1.text-success,
.bg-leaf h2.text-success,
.bg-leaf h3.text-success,
.bg-leaf h4.text-success,
.bg-leaf h5.text-success,
.bg-leaf h6.text-success,
.bg-leaf .h1.text-success,
.bg-leaf .h2.text-success,
.bg-leaf .h3.text-success,
.bg-leaf .h4.text-success,
.bg-leaf .h5.text-success,
.bg-leaf .h6.text-success {
  color: #70b865;
}

.bg-leaf p.text-info,
.bg-leaf h1.text-info,
.bg-leaf h2.text-info,
.bg-leaf h3.text-info,
.bg-leaf h4.text-info,
.bg-leaf h5.text-info,
.bg-leaf h6.text-info,
.bg-leaf .h1.text-info,
.bg-leaf .h2.text-info,
.bg-leaf .h3.text-info,
.bg-leaf .h4.text-info,
.bg-leaf .h5.text-info,
.bg-leaf .h6.text-info {
  color: #81d3eb;
}

.bg-leaf p.text-warning,
.bg-leaf h1.text-warning,
.bg-leaf h2.text-warning,
.bg-leaf h3.text-warning,
.bg-leaf h4.text-warning,
.bg-leaf h5.text-warning,
.bg-leaf h6.text-warning,
.bg-leaf .h1.text-warning,
.bg-leaf .h2.text-warning,
.bg-leaf .h3.text-warning,
.bg-leaf .h4.text-warning,
.bg-leaf .h5.text-warning,
.bg-leaf .h6.text-warning {
  color: #f19e1f;
}

.bg-leaf p.text-danger,
.bg-leaf h1.text-danger,
.bg-leaf h2.text-danger,
.bg-leaf h3.text-danger,
.bg-leaf h4.text-danger,
.bg-leaf h5.text-danger,
.bg-leaf h6.text-danger,
.bg-leaf .h1.text-danger,
.bg-leaf .h2.text-danger,
.bg-leaf .h3.text-danger,
.bg-leaf .h4.text-danger,
.bg-leaf .h5.text-danger,
.bg-leaf .h6.text-danger {
  color: #ef4056;
}

.bg-leaf p.text-light,
.bg-leaf h1.text-light,
.bg-leaf h2.text-light,
.bg-leaf h3.text-light,
.bg-leaf h4.text-light,
.bg-leaf h5.text-light,
.bg-leaf h6.text-light,
.bg-leaf .h1.text-light,
.bg-leaf .h2.text-light,
.bg-leaf .h3.text-light,
.bg-leaf .h4.text-light,
.bg-leaf .h5.text-light,
.bg-leaf .h6.text-light {
  color: #dee2e6;
}

.bg-leaf p.text-dark,
.bg-leaf h1.text-dark,
.bg-leaf h2.text-dark,
.bg-leaf h3.text-dark,
.bg-leaf h4.text-dark,
.bg-leaf h5.text-dark,
.bg-leaf h6.text-dark,
.bg-leaf .h1.text-dark,
.bg-leaf .h2.text-dark,
.bg-leaf .h3.text-dark,
.bg-leaf .h4.text-dark,
.bg-leaf .h5.text-dark,
.bg-leaf .h6.text-dark {
  color: #343a40;
}

.bg-leaf p.text-primary-dark,
.bg-leaf h1.text-primary-dark,
.bg-leaf h2.text-primary-dark,
.bg-leaf h3.text-primary-dark,
.bg-leaf h4.text-primary-dark,
.bg-leaf h5.text-primary-dark,
.bg-leaf h6.text-primary-dark,
.bg-leaf .h1.text-primary-dark,
.bg-leaf .h2.text-primary-dark,
.bg-leaf .h3.text-primary-dark,
.bg-leaf .h4.text-primary-dark,
.bg-leaf .h5.text-primary-dark,
.bg-leaf .h6.text-primary-dark {
  color: #0c234b;
}

.bg-leaf p.text-primary-light,
.bg-leaf h1.text-primary-light,
.bg-leaf h2.text-primary-light,
.bg-leaf h3.text-primary-light,
.bg-leaf h4.text-primary-light,
.bg-leaf h5.text-primary-light,
.bg-leaf h6.text-primary-light,
.bg-leaf .h1.text-primary-light,
.bg-leaf .h2.text-primary-light,
.bg-leaf .h3.text-primary-light,
.bg-leaf .h4.text-primary-light,
.bg-leaf .h5.text-primary-light,
.bg-leaf .h6.text-primary-light {
  color: #378dbd;
}

.bg-leaf p.text-accent,
.bg-leaf h1.text-accent,
.bg-leaf h2.text-accent,
.bg-leaf h3.text-accent,
.bg-leaf h4.text-accent,
.bg-leaf h5.text-accent,
.bg-leaf h6.text-accent,
.bg-leaf .h1.text-accent,
.bg-leaf .h2.text-accent,
.bg-leaf .h3.text-accent,
.bg-leaf .h4.text-accent,
.bg-leaf .h5.text-accent,
.bg-leaf .h6.text-accent {
  color: #ab0520;
}

.bg-river {
  color: #fff;
  background-color: #007d84;
  color: #fff;
}

.bg-river p,
.bg-river h1,
.bg-river h2,
.bg-river h3,
.bg-river h4,
.bg-river h5,
.bg-river h6,
.bg-river .h1,
.bg-river .h2,
.bg-river .h3,
.bg-river .h4,
.bg-river .h5,
.bg-river .h6 {
  color: #fff;
}

.bg-river p.text-blue,
.bg-river h1.text-blue,
.bg-river h2.text-blue,
.bg-river h3.text-blue,
.bg-river h4.text-blue,
.bg-river h5.text-blue,
.bg-river h6.text-blue,
.bg-river .h1.text-blue,
.bg-river .h2.text-blue,
.bg-river .h3.text-blue,
.bg-river .h4.text-blue,
.bg-river .h5.text-blue,
.bg-river .h6.text-blue {
  color: #0c234b;
}

.bg-river p.text-indigo,
.bg-river h1.text-indigo,
.bg-river h2.text-indigo,
.bg-river h3.text-indigo,
.bg-river h4.text-indigo,
.bg-river h5.text-indigo,
.bg-river h6.text-indigo,
.bg-river .h1.text-indigo,
.bg-river .h2.text-indigo,
.bg-river .h3.text-indigo,
.bg-river .h4.text-indigo,
.bg-river .h5.text-indigo,
.bg-river .h6.text-indigo {
  color: #6610f2;
}

.bg-river p.text-purple,
.bg-river h1.text-purple,
.bg-river h2.text-purple,
.bg-river h3.text-purple,
.bg-river h4.text-purple,
.bg-river h5.text-purple,
.bg-river h6.text-purple,
.bg-river .h1.text-purple,
.bg-river .h2.text-purple,
.bg-river .h3.text-purple,
.bg-river .h4.text-purple,
.bg-river .h5.text-purple,
.bg-river .h6.text-purple {
  color: #6f42c1;
}

.bg-river p.text-pink,
.bg-river h1.text-pink,
.bg-river h2.text-pink,
.bg-river h3.text-pink,
.bg-river h4.text-pink,
.bg-river h5.text-pink,
.bg-river h6.text-pink,
.bg-river .h1.text-pink,
.bg-river .h2.text-pink,
.bg-river .h3.text-pink,
.bg-river .h4.text-pink,
.bg-river .h5.text-pink,
.bg-river .h6.text-pink {
  color: #e83e8c;
}

.bg-river p.text-red,
.bg-river h1.text-red,
.bg-river h2.text-red,
.bg-river h3.text-red,
.bg-river h4.text-red,
.bg-river h5.text-red,
.bg-river h6.text-red,
.bg-river .h1.text-red,
.bg-river .h2.text-red,
.bg-river .h3.text-red,
.bg-river .h4.text-red,
.bg-river .h5.text-red,
.bg-river .h6.text-red {
  color: #ab0520;
}

.bg-river p.text-orange,
.bg-river h1.text-orange,
.bg-river h2.text-orange,
.bg-river h3.text-orange,
.bg-river h4.text-orange,
.bg-river h5.text-orange,
.bg-river h6.text-orange,
.bg-river .h1.text-orange,
.bg-river .h2.text-orange,
.bg-river .h3.text-orange,
.bg-river .h4.text-orange,
.bg-river .h5.text-orange,
.bg-river .h6.text-orange {
  color: #fd7e14;
}

.bg-river p.text-yellow,
.bg-river h1.text-yellow,
.bg-river h2.text-yellow,
.bg-river h3.text-yellow,
.bg-river h4.text-yellow,
.bg-river h5.text-yellow,
.bg-river h6.text-yellow,
.bg-river .h1.text-yellow,
.bg-river .h2.text-yellow,
.bg-river .h3.text-yellow,
.bg-river .h4.text-yellow,
.bg-river .h5.text-yellow,
.bg-river .h6.text-yellow {
  color: #ffc107;
}

.bg-river p.text-green,
.bg-river h1.text-green,
.bg-river h2.text-green,
.bg-river h3.text-green,
.bg-river h4.text-green,
.bg-river h5.text-green,
.bg-river h6.text-green,
.bg-river .h1.text-green,
.bg-river .h2.text-green,
.bg-river .h3.text-green,
.bg-river .h4.text-green,
.bg-river .h5.text-green,
.bg-river .h6.text-green {
  color: #28a745;
}

.bg-river p.text-teal,
.bg-river h1.text-teal,
.bg-river h2.text-teal,
.bg-river h3.text-teal,
.bg-river h4.text-teal,
.bg-river h5.text-teal,
.bg-river h6.text-teal,
.bg-river .h1.text-teal,
.bg-river .h2.text-teal,
.bg-river .h3.text-teal,
.bg-river .h4.text-teal,
.bg-river .h5.text-teal,
.bg-river .h6.text-teal {
  color: #20c997;
}

.bg-river p.text-cyan,
.bg-river h1.text-cyan,
.bg-river h2.text-cyan,
.bg-river h3.text-cyan,
.bg-river h4.text-cyan,
.bg-river h5.text-cyan,
.bg-river h6.text-cyan,
.bg-river .h1.text-cyan,
.bg-river .h2.text-cyan,
.bg-river .h3.text-cyan,
.bg-river .h4.text-cyan,
.bg-river .h5.text-cyan,
.bg-river .h6.text-cyan {
  color: #17a2b8;
}

.bg-river p.text-white,
.bg-river h1.text-white,
.bg-river h2.text-white,
.bg-river h3.text-white,
.bg-river h4.text-white,
.bg-river h5.text-white,
.bg-river h6.text-white,
.bg-river .h1.text-white,
.bg-river .h2.text-white,
.bg-river .h3.text-white,
.bg-river .h4.text-white,
.bg-river .h5.text-white,
.bg-river .h6.text-white {
  color: #fff;
}

.bg-river p.text-gray,
.bg-river h1.text-gray,
.bg-river h2.text-gray,
.bg-river h3.text-gray,
.bg-river h4.text-gray,
.bg-river h5.text-gray,
.bg-river h6.text-gray,
.bg-river .h1.text-gray,
.bg-river .h2.text-gray,
.bg-river .h3.text-gray,
.bg-river .h4.text-gray,
.bg-river .h5.text-gray,
.bg-river .h6.text-gray {
  color: #6c757d;
}

.bg-river p.text-gray-dark,
.bg-river h1.text-gray-dark,
.bg-river h2.text-gray-dark,
.bg-river h3.text-gray-dark,
.bg-river h4.text-gray-dark,
.bg-river h5.text-gray-dark,
.bg-river h6.text-gray-dark,
.bg-river .h1.text-gray-dark,
.bg-river .h2.text-gray-dark,
.bg-river .h3.text-gray-dark,
.bg-river .h4.text-gray-dark,
.bg-river .h5.text-gray-dark,
.bg-river .h6.text-gray-dark {
  color: #343a40;
}

.bg-river p.text-bloom,
.bg-river h1.text-bloom,
.bg-river h2.text-bloom,
.bg-river h3.text-bloom,
.bg-river h4.text-bloom,
.bg-river h5.text-bloom,
.bg-river h6.text-bloom,
.bg-river .h1.text-bloom,
.bg-river .h2.text-bloom,
.bg-river .h3.text-bloom,
.bg-river .h4.text-bloom,
.bg-river .h5.text-bloom,
.bg-river .h6.text-bloom {
  color: #ef4056;
}

.bg-river p.text-chili,
.bg-river h1.text-chili,
.bg-river h2.text-chili,
.bg-river h3.text-chili,
.bg-river h4.text-chili,
.bg-river h5.text-chili,
.bg-river h6.text-chili,
.bg-river .h1.text-chili,
.bg-river .h2.text-chili,
.bg-river .h3.text-chili,
.bg-river .h4.text-chili,
.bg-river .h5.text-chili,
.bg-river .h6.text-chili {
  color: #8b0015;
}

.bg-river p.text-sky,
.bg-river h1.text-sky,
.bg-river h2.text-sky,
.bg-river h3.text-sky,
.bg-river h4.text-sky,
.bg-river h5.text-sky,
.bg-river h6.text-sky,
.bg-river .h1.text-sky,
.bg-river .h2.text-sky,
.bg-river .h3.text-sky,
.bg-river .h4.text-sky,
.bg-river .h5.text-sky,
.bg-river .h6.text-sky {
  color: #81d3eb;
}

.bg-river p.text-oasis,
.bg-river h1.text-oasis,
.bg-river h2.text-oasis,
.bg-river h3.text-oasis,
.bg-river h4.text-oasis,
.bg-river h5.text-oasis,
.bg-river h6.text-oasis,
.bg-river .h1.text-oasis,
.bg-river .h2.text-oasis,
.bg-river .h3.text-oasis,
.bg-river .h4.text-oasis,
.bg-river .h5.text-oasis,
.bg-river .h6.text-oasis {
  color: #378dbd;
}

.bg-river p.text-azurite,
.bg-river h1.text-azurite,
.bg-river h2.text-azurite,
.bg-river h3.text-azurite,
.bg-river h4.text-azurite,
.bg-river h5.text-azurite,
.bg-river h6.text-azurite,
.bg-river .h1.text-azurite,
.bg-river .h2.text-azurite,
.bg-river .h3.text-azurite,
.bg-river .h4.text-azurite,
.bg-river .h5.text-azurite,
.bg-river .h6.text-azurite {
  color: #1e5288;
}

.bg-river p.text-midnight,
.bg-river h1.text-midnight,
.bg-river h2.text-midnight,
.bg-river h3.text-midnight,
.bg-river h4.text-midnight,
.bg-river h5.text-midnight,
.bg-river h6.text-midnight,
.bg-river .h1.text-midnight,
.bg-river .h2.text-midnight,
.bg-river .h3.text-midnight,
.bg-river .h4.text-midnight,
.bg-river .h5.text-midnight,
.bg-river .h6.text-midnight {
  color: #001c48;
}

.bg-river p.text-cool-gray,
.bg-river h1.text-cool-gray,
.bg-river h2.text-cool-gray,
.bg-river h3.text-cool-gray,
.bg-river h4.text-cool-gray,
.bg-river h5.text-cool-gray,
.bg-river h6.text-cool-gray,
.bg-river .h1.text-cool-gray,
.bg-river .h2.text-cool-gray,
.bg-river .h3.text-cool-gray,
.bg-river .h4.text-cool-gray,
.bg-river .h5.text-cool-gray,
.bg-river .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-river p.text-warm-gray,
.bg-river h1.text-warm-gray,
.bg-river h2.text-warm-gray,
.bg-river h3.text-warm-gray,
.bg-river h4.text-warm-gray,
.bg-river h5.text-warm-gray,
.bg-river h6.text-warm-gray,
.bg-river .h1.text-warm-gray,
.bg-river .h2.text-warm-gray,
.bg-river .h3.text-warm-gray,
.bg-river .h4.text-warm-gray,
.bg-river .h5.text-warm-gray,
.bg-river .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-river p.text-leaf,
.bg-river h1.text-leaf,
.bg-river h2.text-leaf,
.bg-river h3.text-leaf,
.bg-river h4.text-leaf,
.bg-river h5.text-leaf,
.bg-river h6.text-leaf,
.bg-river .h1.text-leaf,
.bg-river .h2.text-leaf,
.bg-river .h3.text-leaf,
.bg-river .h4.text-leaf,
.bg-river .h5.text-leaf,
.bg-river .h6.text-leaf {
  color: #70b865;
}

.bg-river p.text-river,
.bg-river h1.text-river,
.bg-river h2.text-river,
.bg-river h3.text-river,
.bg-river h4.text-river,
.bg-river h5.text-river,
.bg-river h6.text-river,
.bg-river .h1.text-river,
.bg-river .h2.text-river,
.bg-river .h3.text-river,
.bg-river .h4.text-river,
.bg-river .h5.text-river,
.bg-river .h6.text-river {
  color: #007d84;
}

.bg-river p.text-silver,
.bg-river h1.text-silver,
.bg-river h2.text-silver,
.bg-river h3.text-silver,
.bg-river h4.text-silver,
.bg-river h5.text-silver,
.bg-river h6.text-silver,
.bg-river .h1.text-silver,
.bg-river .h2.text-silver,
.bg-river .h3.text-silver,
.bg-river .h4.text-silver,
.bg-river .h5.text-silver,
.bg-river .h6.text-silver {
  color: #9eabae;
}

.bg-river p.text-mesa,
.bg-river h1.text-mesa,
.bg-river h2.text-mesa,
.bg-river h3.text-mesa,
.bg-river h4.text-mesa,
.bg-river h5.text-mesa,
.bg-river h6.text-mesa,
.bg-river .h1.text-mesa,
.bg-river .h2.text-mesa,
.bg-river .h3.text-mesa,
.bg-river .h4.text-mesa,
.bg-river .h5.text-mesa,
.bg-river .h6.text-mesa {
  color: #a95c42;
}

.bg-river p.text-ash,
.bg-river h1.text-ash,
.bg-river h2.text-ash,
.bg-river h3.text-ash,
.bg-river h4.text-ash,
.bg-river h5.text-ash,
.bg-river h6.text-ash,
.bg-river .h1.text-ash,
.bg-river .h2.text-ash,
.bg-river .h3.text-ash,
.bg-river .h4.text-ash,
.bg-river .h5.text-ash,
.bg-river .h6.text-ash {
  color: #403635;
}

.bg-river p.text-sage,
.bg-river h1.text-sage,
.bg-river h2.text-sage,
.bg-river h3.text-sage,
.bg-river h4.text-sage,
.bg-river h5.text-sage,
.bg-river h6.text-sage,
.bg-river .h1.text-sage,
.bg-river .h2.text-sage,
.bg-river .h3.text-sage,
.bg-river .h4.text-sage,
.bg-river .h5.text-sage,
.bg-river .h6.text-sage {
  color: #4a634e;
}

.bg-river p.text-black,
.bg-river h1.text-black,
.bg-river h2.text-black,
.bg-river h3.text-black,
.bg-river h4.text-black,
.bg-river h5.text-black,
.bg-river h6.text-black,
.bg-river .h1.text-black,
.bg-river .h2.text-black,
.bg-river .h3.text-black,
.bg-river .h4.text-black,
.bg-river .h5.text-black,
.bg-river .h6.text-black {
  color: #000;
}

.bg-river p.text-primary,
.bg-river h1.text-primary,
.bg-river h2.text-primary,
.bg-river h3.text-primary,
.bg-river h4.text-primary,
.bg-river h5.text-primary,
.bg-river h6.text-primary,
.bg-river .h1.text-primary,
.bg-river .h2.text-primary,
.bg-river .h3.text-primary,
.bg-river .h4.text-primary,
.bg-river .h5.text-primary,
.bg-river .h6.text-primary {
  color: #1e5288;
}

.bg-river p.text-secondary,
.bg-river h1.text-secondary,
.bg-river h2.text-secondary,
.bg-river h3.text-secondary,
.bg-river h4.text-secondary,
.bg-river h5.text-secondary,
.bg-river h6.text-secondary,
.bg-river .h1.text-secondary,
.bg-river .h2.text-secondary,
.bg-river .h3.text-secondary,
.bg-river .h4.text-secondary,
.bg-river .h5.text-secondary,
.bg-river .h6.text-secondary {
  color: #9eabae;
}

.bg-river p.text-success,
.bg-river h1.text-success,
.bg-river h2.text-success,
.bg-river h3.text-success,
.bg-river h4.text-success,
.bg-river h5.text-success,
.bg-river h6.text-success,
.bg-river .h1.text-success,
.bg-river .h2.text-success,
.bg-river .h3.text-success,
.bg-river .h4.text-success,
.bg-river .h5.text-success,
.bg-river .h6.text-success {
  color: #70b865;
}

.bg-river p.text-info,
.bg-river h1.text-info,
.bg-river h2.text-info,
.bg-river h3.text-info,
.bg-river h4.text-info,
.bg-river h5.text-info,
.bg-river h6.text-info,
.bg-river .h1.text-info,
.bg-river .h2.text-info,
.bg-river .h3.text-info,
.bg-river .h4.text-info,
.bg-river .h5.text-info,
.bg-river .h6.text-info {
  color: #81d3eb;
}

.bg-river p.text-warning,
.bg-river h1.text-warning,
.bg-river h2.text-warning,
.bg-river h3.text-warning,
.bg-river h4.text-warning,
.bg-river h5.text-warning,
.bg-river h6.text-warning,
.bg-river .h1.text-warning,
.bg-river .h2.text-warning,
.bg-river .h3.text-warning,
.bg-river .h4.text-warning,
.bg-river .h5.text-warning,
.bg-river .h6.text-warning {
  color: #f19e1f;
}

.bg-river p.text-danger,
.bg-river h1.text-danger,
.bg-river h2.text-danger,
.bg-river h3.text-danger,
.bg-river h4.text-danger,
.bg-river h5.text-danger,
.bg-river h6.text-danger,
.bg-river .h1.text-danger,
.bg-river .h2.text-danger,
.bg-river .h3.text-danger,
.bg-river .h4.text-danger,
.bg-river .h5.text-danger,
.bg-river .h6.text-danger {
  color: #ef4056;
}

.bg-river p.text-light,
.bg-river h1.text-light,
.bg-river h2.text-light,
.bg-river h3.text-light,
.bg-river h4.text-light,
.bg-river h5.text-light,
.bg-river h6.text-light,
.bg-river .h1.text-light,
.bg-river .h2.text-light,
.bg-river .h3.text-light,
.bg-river .h4.text-light,
.bg-river .h5.text-light,
.bg-river .h6.text-light {
  color: #dee2e6;
}

.bg-river p.text-dark,
.bg-river h1.text-dark,
.bg-river h2.text-dark,
.bg-river h3.text-dark,
.bg-river h4.text-dark,
.bg-river h5.text-dark,
.bg-river h6.text-dark,
.bg-river .h1.text-dark,
.bg-river .h2.text-dark,
.bg-river .h3.text-dark,
.bg-river .h4.text-dark,
.bg-river .h5.text-dark,
.bg-river .h6.text-dark {
  color: #343a40;
}

.bg-river p.text-primary-dark,
.bg-river h1.text-primary-dark,
.bg-river h2.text-primary-dark,
.bg-river h3.text-primary-dark,
.bg-river h4.text-primary-dark,
.bg-river h5.text-primary-dark,
.bg-river h6.text-primary-dark,
.bg-river .h1.text-primary-dark,
.bg-river .h2.text-primary-dark,
.bg-river .h3.text-primary-dark,
.bg-river .h4.text-primary-dark,
.bg-river .h5.text-primary-dark,
.bg-river .h6.text-primary-dark {
  color: #0c234b;
}

.bg-river p.text-primary-light,
.bg-river h1.text-primary-light,
.bg-river h2.text-primary-light,
.bg-river h3.text-primary-light,
.bg-river h4.text-primary-light,
.bg-river h5.text-primary-light,
.bg-river h6.text-primary-light,
.bg-river .h1.text-primary-light,
.bg-river .h2.text-primary-light,
.bg-river .h3.text-primary-light,
.bg-river .h4.text-primary-light,
.bg-river .h5.text-primary-light,
.bg-river .h6.text-primary-light {
  color: #378dbd;
}

.bg-river p.text-accent,
.bg-river h1.text-accent,
.bg-river h2.text-accent,
.bg-river h3.text-accent,
.bg-river h4.text-accent,
.bg-river h5.text-accent,
.bg-river h6.text-accent,
.bg-river .h1.text-accent,
.bg-river .h2.text-accent,
.bg-river .h3.text-accent,
.bg-river .h4.text-accent,
.bg-river .h5.text-accent,
.bg-river .h6.text-accent {
  color: #ab0520;
}

.bg-silver {
  color: #fff;
  background-color: #9eabae;
  color: #001c48;
}

.bg-silver p,
.bg-silver h1,
.bg-silver h2,
.bg-silver h3,
.bg-silver h4,
.bg-silver h5,
.bg-silver h6,
.bg-silver .h1,
.bg-silver .h2,
.bg-silver .h3,
.bg-silver .h4,
.bg-silver .h5,
.bg-silver .h6 {
  color: #001c48;
}

.bg-silver p.text-blue,
.bg-silver h1.text-blue,
.bg-silver h2.text-blue,
.bg-silver h3.text-blue,
.bg-silver h4.text-blue,
.bg-silver h5.text-blue,
.bg-silver h6.text-blue,
.bg-silver .h1.text-blue,
.bg-silver .h2.text-blue,
.bg-silver .h3.text-blue,
.bg-silver .h4.text-blue,
.bg-silver .h5.text-blue,
.bg-silver .h6.text-blue {
  color: #0c234b;
}

.bg-silver p.text-indigo,
.bg-silver h1.text-indigo,
.bg-silver h2.text-indigo,
.bg-silver h3.text-indigo,
.bg-silver h4.text-indigo,
.bg-silver h5.text-indigo,
.bg-silver h6.text-indigo,
.bg-silver .h1.text-indigo,
.bg-silver .h2.text-indigo,
.bg-silver .h3.text-indigo,
.bg-silver .h4.text-indigo,
.bg-silver .h5.text-indigo,
.bg-silver .h6.text-indigo {
  color: #6610f2;
}

.bg-silver p.text-purple,
.bg-silver h1.text-purple,
.bg-silver h2.text-purple,
.bg-silver h3.text-purple,
.bg-silver h4.text-purple,
.bg-silver h5.text-purple,
.bg-silver h6.text-purple,
.bg-silver .h1.text-purple,
.bg-silver .h2.text-purple,
.bg-silver .h3.text-purple,
.bg-silver .h4.text-purple,
.bg-silver .h5.text-purple,
.bg-silver .h6.text-purple {
  color: #6f42c1;
}

.bg-silver p.text-pink,
.bg-silver h1.text-pink,
.bg-silver h2.text-pink,
.bg-silver h3.text-pink,
.bg-silver h4.text-pink,
.bg-silver h5.text-pink,
.bg-silver h6.text-pink,
.bg-silver .h1.text-pink,
.bg-silver .h2.text-pink,
.bg-silver .h3.text-pink,
.bg-silver .h4.text-pink,
.bg-silver .h5.text-pink,
.bg-silver .h6.text-pink {
  color: #e83e8c;
}

.bg-silver p.text-red,
.bg-silver h1.text-red,
.bg-silver h2.text-red,
.bg-silver h3.text-red,
.bg-silver h4.text-red,
.bg-silver h5.text-red,
.bg-silver h6.text-red,
.bg-silver .h1.text-red,
.bg-silver .h2.text-red,
.bg-silver .h3.text-red,
.bg-silver .h4.text-red,
.bg-silver .h5.text-red,
.bg-silver .h6.text-red {
  color: #ab0520;
}

.bg-silver p.text-orange,
.bg-silver h1.text-orange,
.bg-silver h2.text-orange,
.bg-silver h3.text-orange,
.bg-silver h4.text-orange,
.bg-silver h5.text-orange,
.bg-silver h6.text-orange,
.bg-silver .h1.text-orange,
.bg-silver .h2.text-orange,
.bg-silver .h3.text-orange,
.bg-silver .h4.text-orange,
.bg-silver .h5.text-orange,
.bg-silver .h6.text-orange {
  color: #fd7e14;
}

.bg-silver p.text-yellow,
.bg-silver h1.text-yellow,
.bg-silver h2.text-yellow,
.bg-silver h3.text-yellow,
.bg-silver h4.text-yellow,
.bg-silver h5.text-yellow,
.bg-silver h6.text-yellow,
.bg-silver .h1.text-yellow,
.bg-silver .h2.text-yellow,
.bg-silver .h3.text-yellow,
.bg-silver .h4.text-yellow,
.bg-silver .h5.text-yellow,
.bg-silver .h6.text-yellow {
  color: #ffc107;
}

.bg-silver p.text-green,
.bg-silver h1.text-green,
.bg-silver h2.text-green,
.bg-silver h3.text-green,
.bg-silver h4.text-green,
.bg-silver h5.text-green,
.bg-silver h6.text-green,
.bg-silver .h1.text-green,
.bg-silver .h2.text-green,
.bg-silver .h3.text-green,
.bg-silver .h4.text-green,
.bg-silver .h5.text-green,
.bg-silver .h6.text-green {
  color: #28a745;
}

.bg-silver p.text-teal,
.bg-silver h1.text-teal,
.bg-silver h2.text-teal,
.bg-silver h3.text-teal,
.bg-silver h4.text-teal,
.bg-silver h5.text-teal,
.bg-silver h6.text-teal,
.bg-silver .h1.text-teal,
.bg-silver .h2.text-teal,
.bg-silver .h3.text-teal,
.bg-silver .h4.text-teal,
.bg-silver .h5.text-teal,
.bg-silver .h6.text-teal {
  color: #20c997;
}

.bg-silver p.text-cyan,
.bg-silver h1.text-cyan,
.bg-silver h2.text-cyan,
.bg-silver h3.text-cyan,
.bg-silver h4.text-cyan,
.bg-silver h5.text-cyan,
.bg-silver h6.text-cyan,
.bg-silver .h1.text-cyan,
.bg-silver .h2.text-cyan,
.bg-silver .h3.text-cyan,
.bg-silver .h4.text-cyan,
.bg-silver .h5.text-cyan,
.bg-silver .h6.text-cyan {
  color: #17a2b8;
}

.bg-silver p.text-white,
.bg-silver h1.text-white,
.bg-silver h2.text-white,
.bg-silver h3.text-white,
.bg-silver h4.text-white,
.bg-silver h5.text-white,
.bg-silver h6.text-white,
.bg-silver .h1.text-white,
.bg-silver .h2.text-white,
.bg-silver .h3.text-white,
.bg-silver .h4.text-white,
.bg-silver .h5.text-white,
.bg-silver .h6.text-white {
  color: #fff;
}

.bg-silver p.text-gray,
.bg-silver h1.text-gray,
.bg-silver h2.text-gray,
.bg-silver h3.text-gray,
.bg-silver h4.text-gray,
.bg-silver h5.text-gray,
.bg-silver h6.text-gray,
.bg-silver .h1.text-gray,
.bg-silver .h2.text-gray,
.bg-silver .h3.text-gray,
.bg-silver .h4.text-gray,
.bg-silver .h5.text-gray,
.bg-silver .h6.text-gray {
  color: #6c757d;
}

.bg-silver p.text-gray-dark,
.bg-silver h1.text-gray-dark,
.bg-silver h2.text-gray-dark,
.bg-silver h3.text-gray-dark,
.bg-silver h4.text-gray-dark,
.bg-silver h5.text-gray-dark,
.bg-silver h6.text-gray-dark,
.bg-silver .h1.text-gray-dark,
.bg-silver .h2.text-gray-dark,
.bg-silver .h3.text-gray-dark,
.bg-silver .h4.text-gray-dark,
.bg-silver .h5.text-gray-dark,
.bg-silver .h6.text-gray-dark {
  color: #343a40;
}

.bg-silver p.text-bloom,
.bg-silver h1.text-bloom,
.bg-silver h2.text-bloom,
.bg-silver h3.text-bloom,
.bg-silver h4.text-bloom,
.bg-silver h5.text-bloom,
.bg-silver h6.text-bloom,
.bg-silver .h1.text-bloom,
.bg-silver .h2.text-bloom,
.bg-silver .h3.text-bloom,
.bg-silver .h4.text-bloom,
.bg-silver .h5.text-bloom,
.bg-silver .h6.text-bloom {
  color: #ef4056;
}

.bg-silver p.text-chili,
.bg-silver h1.text-chili,
.bg-silver h2.text-chili,
.bg-silver h3.text-chili,
.bg-silver h4.text-chili,
.bg-silver h5.text-chili,
.bg-silver h6.text-chili,
.bg-silver .h1.text-chili,
.bg-silver .h2.text-chili,
.bg-silver .h3.text-chili,
.bg-silver .h4.text-chili,
.bg-silver .h5.text-chili,
.bg-silver .h6.text-chili {
  color: #8b0015;
}

.bg-silver p.text-sky,
.bg-silver h1.text-sky,
.bg-silver h2.text-sky,
.bg-silver h3.text-sky,
.bg-silver h4.text-sky,
.bg-silver h5.text-sky,
.bg-silver h6.text-sky,
.bg-silver .h1.text-sky,
.bg-silver .h2.text-sky,
.bg-silver .h3.text-sky,
.bg-silver .h4.text-sky,
.bg-silver .h5.text-sky,
.bg-silver .h6.text-sky {
  color: #81d3eb;
}

.bg-silver p.text-oasis,
.bg-silver h1.text-oasis,
.bg-silver h2.text-oasis,
.bg-silver h3.text-oasis,
.bg-silver h4.text-oasis,
.bg-silver h5.text-oasis,
.bg-silver h6.text-oasis,
.bg-silver .h1.text-oasis,
.bg-silver .h2.text-oasis,
.bg-silver .h3.text-oasis,
.bg-silver .h4.text-oasis,
.bg-silver .h5.text-oasis,
.bg-silver .h6.text-oasis {
  color: #378dbd;
}

.bg-silver p.text-azurite,
.bg-silver h1.text-azurite,
.bg-silver h2.text-azurite,
.bg-silver h3.text-azurite,
.bg-silver h4.text-azurite,
.bg-silver h5.text-azurite,
.bg-silver h6.text-azurite,
.bg-silver .h1.text-azurite,
.bg-silver .h2.text-azurite,
.bg-silver .h3.text-azurite,
.bg-silver .h4.text-azurite,
.bg-silver .h5.text-azurite,
.bg-silver .h6.text-azurite {
  color: #1e5288;
}

.bg-silver p.text-midnight,
.bg-silver h1.text-midnight,
.bg-silver h2.text-midnight,
.bg-silver h3.text-midnight,
.bg-silver h4.text-midnight,
.bg-silver h5.text-midnight,
.bg-silver h6.text-midnight,
.bg-silver .h1.text-midnight,
.bg-silver .h2.text-midnight,
.bg-silver .h3.text-midnight,
.bg-silver .h4.text-midnight,
.bg-silver .h5.text-midnight,
.bg-silver .h6.text-midnight {
  color: #001c48;
}

.bg-silver p.text-cool-gray,
.bg-silver h1.text-cool-gray,
.bg-silver h2.text-cool-gray,
.bg-silver h3.text-cool-gray,
.bg-silver h4.text-cool-gray,
.bg-silver h5.text-cool-gray,
.bg-silver h6.text-cool-gray,
.bg-silver .h1.text-cool-gray,
.bg-silver .h2.text-cool-gray,
.bg-silver .h3.text-cool-gray,
.bg-silver .h4.text-cool-gray,
.bg-silver .h5.text-cool-gray,
.bg-silver .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-silver p.text-warm-gray,
.bg-silver h1.text-warm-gray,
.bg-silver h2.text-warm-gray,
.bg-silver h3.text-warm-gray,
.bg-silver h4.text-warm-gray,
.bg-silver h5.text-warm-gray,
.bg-silver h6.text-warm-gray,
.bg-silver .h1.text-warm-gray,
.bg-silver .h2.text-warm-gray,
.bg-silver .h3.text-warm-gray,
.bg-silver .h4.text-warm-gray,
.bg-silver .h5.text-warm-gray,
.bg-silver .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-silver p.text-leaf,
.bg-silver h1.text-leaf,
.bg-silver h2.text-leaf,
.bg-silver h3.text-leaf,
.bg-silver h4.text-leaf,
.bg-silver h5.text-leaf,
.bg-silver h6.text-leaf,
.bg-silver .h1.text-leaf,
.bg-silver .h2.text-leaf,
.bg-silver .h3.text-leaf,
.bg-silver .h4.text-leaf,
.bg-silver .h5.text-leaf,
.bg-silver .h6.text-leaf {
  color: #70b865;
}

.bg-silver p.text-river,
.bg-silver h1.text-river,
.bg-silver h2.text-river,
.bg-silver h3.text-river,
.bg-silver h4.text-river,
.bg-silver h5.text-river,
.bg-silver h6.text-river,
.bg-silver .h1.text-river,
.bg-silver .h2.text-river,
.bg-silver .h3.text-river,
.bg-silver .h4.text-river,
.bg-silver .h5.text-river,
.bg-silver .h6.text-river {
  color: #007d84;
}

.bg-silver p.text-silver,
.bg-silver h1.text-silver,
.bg-silver h2.text-silver,
.bg-silver h3.text-silver,
.bg-silver h4.text-silver,
.bg-silver h5.text-silver,
.bg-silver h6.text-silver,
.bg-silver .h1.text-silver,
.bg-silver .h2.text-silver,
.bg-silver .h3.text-silver,
.bg-silver .h4.text-silver,
.bg-silver .h5.text-silver,
.bg-silver .h6.text-silver {
  color: #9eabae;
}

.bg-silver p.text-mesa,
.bg-silver h1.text-mesa,
.bg-silver h2.text-mesa,
.bg-silver h3.text-mesa,
.bg-silver h4.text-mesa,
.bg-silver h5.text-mesa,
.bg-silver h6.text-mesa,
.bg-silver .h1.text-mesa,
.bg-silver .h2.text-mesa,
.bg-silver .h3.text-mesa,
.bg-silver .h4.text-mesa,
.bg-silver .h5.text-mesa,
.bg-silver .h6.text-mesa {
  color: #a95c42;
}

.bg-silver p.text-ash,
.bg-silver h1.text-ash,
.bg-silver h2.text-ash,
.bg-silver h3.text-ash,
.bg-silver h4.text-ash,
.bg-silver h5.text-ash,
.bg-silver h6.text-ash,
.bg-silver .h1.text-ash,
.bg-silver .h2.text-ash,
.bg-silver .h3.text-ash,
.bg-silver .h4.text-ash,
.bg-silver .h5.text-ash,
.bg-silver .h6.text-ash {
  color: #403635;
}

.bg-silver p.text-sage,
.bg-silver h1.text-sage,
.bg-silver h2.text-sage,
.bg-silver h3.text-sage,
.bg-silver h4.text-sage,
.bg-silver h5.text-sage,
.bg-silver h6.text-sage,
.bg-silver .h1.text-sage,
.bg-silver .h2.text-sage,
.bg-silver .h3.text-sage,
.bg-silver .h4.text-sage,
.bg-silver .h5.text-sage,
.bg-silver .h6.text-sage {
  color: #4a634e;
}

.bg-silver p.text-black,
.bg-silver h1.text-black,
.bg-silver h2.text-black,
.bg-silver h3.text-black,
.bg-silver h4.text-black,
.bg-silver h5.text-black,
.bg-silver h6.text-black,
.bg-silver .h1.text-black,
.bg-silver .h2.text-black,
.bg-silver .h3.text-black,
.bg-silver .h4.text-black,
.bg-silver .h5.text-black,
.bg-silver .h6.text-black {
  color: #000;
}

.bg-silver p.text-primary,
.bg-silver h1.text-primary,
.bg-silver h2.text-primary,
.bg-silver h3.text-primary,
.bg-silver h4.text-primary,
.bg-silver h5.text-primary,
.bg-silver h6.text-primary,
.bg-silver .h1.text-primary,
.bg-silver .h2.text-primary,
.bg-silver .h3.text-primary,
.bg-silver .h4.text-primary,
.bg-silver .h5.text-primary,
.bg-silver .h6.text-primary {
  color: #1e5288;
}

.bg-silver p.text-secondary,
.bg-silver h1.text-secondary,
.bg-silver h2.text-secondary,
.bg-silver h3.text-secondary,
.bg-silver h4.text-secondary,
.bg-silver h5.text-secondary,
.bg-silver h6.text-secondary,
.bg-silver .h1.text-secondary,
.bg-silver .h2.text-secondary,
.bg-silver .h3.text-secondary,
.bg-silver .h4.text-secondary,
.bg-silver .h5.text-secondary,
.bg-silver .h6.text-secondary {
  color: #9eabae;
}

.bg-silver p.text-success,
.bg-silver h1.text-success,
.bg-silver h2.text-success,
.bg-silver h3.text-success,
.bg-silver h4.text-success,
.bg-silver h5.text-success,
.bg-silver h6.text-success,
.bg-silver .h1.text-success,
.bg-silver .h2.text-success,
.bg-silver .h3.text-success,
.bg-silver .h4.text-success,
.bg-silver .h5.text-success,
.bg-silver .h6.text-success {
  color: #70b865;
}

.bg-silver p.text-info,
.bg-silver h1.text-info,
.bg-silver h2.text-info,
.bg-silver h3.text-info,
.bg-silver h4.text-info,
.bg-silver h5.text-info,
.bg-silver h6.text-info,
.bg-silver .h1.text-info,
.bg-silver .h2.text-info,
.bg-silver .h3.text-info,
.bg-silver .h4.text-info,
.bg-silver .h5.text-info,
.bg-silver .h6.text-info {
  color: #81d3eb;
}

.bg-silver p.text-warning,
.bg-silver h1.text-warning,
.bg-silver h2.text-warning,
.bg-silver h3.text-warning,
.bg-silver h4.text-warning,
.bg-silver h5.text-warning,
.bg-silver h6.text-warning,
.bg-silver .h1.text-warning,
.bg-silver .h2.text-warning,
.bg-silver .h3.text-warning,
.bg-silver .h4.text-warning,
.bg-silver .h5.text-warning,
.bg-silver .h6.text-warning {
  color: #f19e1f;
}

.bg-silver p.text-danger,
.bg-silver h1.text-danger,
.bg-silver h2.text-danger,
.bg-silver h3.text-danger,
.bg-silver h4.text-danger,
.bg-silver h5.text-danger,
.bg-silver h6.text-danger,
.bg-silver .h1.text-danger,
.bg-silver .h2.text-danger,
.bg-silver .h3.text-danger,
.bg-silver .h4.text-danger,
.bg-silver .h5.text-danger,
.bg-silver .h6.text-danger {
  color: #ef4056;
}

.bg-silver p.text-light,
.bg-silver h1.text-light,
.bg-silver h2.text-light,
.bg-silver h3.text-light,
.bg-silver h4.text-light,
.bg-silver h5.text-light,
.bg-silver h6.text-light,
.bg-silver .h1.text-light,
.bg-silver .h2.text-light,
.bg-silver .h3.text-light,
.bg-silver .h4.text-light,
.bg-silver .h5.text-light,
.bg-silver .h6.text-light {
  color: #dee2e6;
}

.bg-silver p.text-dark,
.bg-silver h1.text-dark,
.bg-silver h2.text-dark,
.bg-silver h3.text-dark,
.bg-silver h4.text-dark,
.bg-silver h5.text-dark,
.bg-silver h6.text-dark,
.bg-silver .h1.text-dark,
.bg-silver .h2.text-dark,
.bg-silver .h3.text-dark,
.bg-silver .h4.text-dark,
.bg-silver .h5.text-dark,
.bg-silver .h6.text-dark {
  color: #343a40;
}

.bg-silver p.text-primary-dark,
.bg-silver h1.text-primary-dark,
.bg-silver h2.text-primary-dark,
.bg-silver h3.text-primary-dark,
.bg-silver h4.text-primary-dark,
.bg-silver h5.text-primary-dark,
.bg-silver h6.text-primary-dark,
.bg-silver .h1.text-primary-dark,
.bg-silver .h2.text-primary-dark,
.bg-silver .h3.text-primary-dark,
.bg-silver .h4.text-primary-dark,
.bg-silver .h5.text-primary-dark,
.bg-silver .h6.text-primary-dark {
  color: #0c234b;
}

.bg-silver p.text-primary-light,
.bg-silver h1.text-primary-light,
.bg-silver h2.text-primary-light,
.bg-silver h3.text-primary-light,
.bg-silver h4.text-primary-light,
.bg-silver h5.text-primary-light,
.bg-silver h6.text-primary-light,
.bg-silver .h1.text-primary-light,
.bg-silver .h2.text-primary-light,
.bg-silver .h3.text-primary-light,
.bg-silver .h4.text-primary-light,
.bg-silver .h5.text-primary-light,
.bg-silver .h6.text-primary-light {
  color: #378dbd;
}

.bg-silver p.text-accent,
.bg-silver h1.text-accent,
.bg-silver h2.text-accent,
.bg-silver h3.text-accent,
.bg-silver h4.text-accent,
.bg-silver h5.text-accent,
.bg-silver h6.text-accent,
.bg-silver .h1.text-accent,
.bg-silver .h2.text-accent,
.bg-silver .h3.text-accent,
.bg-silver .h4.text-accent,
.bg-silver .h5.text-accent,
.bg-silver .h6.text-accent {
  color: #ab0520;
}

.bg-mesa {
  color: #fff;
  background-color: #a95c42;
  color: #fff;
}

.bg-mesa p,
.bg-mesa h1,
.bg-mesa h2,
.bg-mesa h3,
.bg-mesa h4,
.bg-mesa h5,
.bg-mesa h6,
.bg-mesa .h1,
.bg-mesa .h2,
.bg-mesa .h3,
.bg-mesa .h4,
.bg-mesa .h5,
.bg-mesa .h6 {
  color: #fff;
}

.bg-mesa p.text-blue,
.bg-mesa h1.text-blue,
.bg-mesa h2.text-blue,
.bg-mesa h3.text-blue,
.bg-mesa h4.text-blue,
.bg-mesa h5.text-blue,
.bg-mesa h6.text-blue,
.bg-mesa .h1.text-blue,
.bg-mesa .h2.text-blue,
.bg-mesa .h3.text-blue,
.bg-mesa .h4.text-blue,
.bg-mesa .h5.text-blue,
.bg-mesa .h6.text-blue {
  color: #0c234b;
}

.bg-mesa p.text-indigo,
.bg-mesa h1.text-indigo,
.bg-mesa h2.text-indigo,
.bg-mesa h3.text-indigo,
.bg-mesa h4.text-indigo,
.bg-mesa h5.text-indigo,
.bg-mesa h6.text-indigo,
.bg-mesa .h1.text-indigo,
.bg-mesa .h2.text-indigo,
.bg-mesa .h3.text-indigo,
.bg-mesa .h4.text-indigo,
.bg-mesa .h5.text-indigo,
.bg-mesa .h6.text-indigo {
  color: #6610f2;
}

.bg-mesa p.text-purple,
.bg-mesa h1.text-purple,
.bg-mesa h2.text-purple,
.bg-mesa h3.text-purple,
.bg-mesa h4.text-purple,
.bg-mesa h5.text-purple,
.bg-mesa h6.text-purple,
.bg-mesa .h1.text-purple,
.bg-mesa .h2.text-purple,
.bg-mesa .h3.text-purple,
.bg-mesa .h4.text-purple,
.bg-mesa .h5.text-purple,
.bg-mesa .h6.text-purple {
  color: #6f42c1;
}

.bg-mesa p.text-pink,
.bg-mesa h1.text-pink,
.bg-mesa h2.text-pink,
.bg-mesa h3.text-pink,
.bg-mesa h4.text-pink,
.bg-mesa h5.text-pink,
.bg-mesa h6.text-pink,
.bg-mesa .h1.text-pink,
.bg-mesa .h2.text-pink,
.bg-mesa .h3.text-pink,
.bg-mesa .h4.text-pink,
.bg-mesa .h5.text-pink,
.bg-mesa .h6.text-pink {
  color: #e83e8c;
}

.bg-mesa p.text-red,
.bg-mesa h1.text-red,
.bg-mesa h2.text-red,
.bg-mesa h3.text-red,
.bg-mesa h4.text-red,
.bg-mesa h5.text-red,
.bg-mesa h6.text-red,
.bg-mesa .h1.text-red,
.bg-mesa .h2.text-red,
.bg-mesa .h3.text-red,
.bg-mesa .h4.text-red,
.bg-mesa .h5.text-red,
.bg-mesa .h6.text-red {
  color: #ab0520;
}

.bg-mesa p.text-orange,
.bg-mesa h1.text-orange,
.bg-mesa h2.text-orange,
.bg-mesa h3.text-orange,
.bg-mesa h4.text-orange,
.bg-mesa h5.text-orange,
.bg-mesa h6.text-orange,
.bg-mesa .h1.text-orange,
.bg-mesa .h2.text-orange,
.bg-mesa .h3.text-orange,
.bg-mesa .h4.text-orange,
.bg-mesa .h5.text-orange,
.bg-mesa .h6.text-orange {
  color: #fd7e14;
}

.bg-mesa p.text-yellow,
.bg-mesa h1.text-yellow,
.bg-mesa h2.text-yellow,
.bg-mesa h3.text-yellow,
.bg-mesa h4.text-yellow,
.bg-mesa h5.text-yellow,
.bg-mesa h6.text-yellow,
.bg-mesa .h1.text-yellow,
.bg-mesa .h2.text-yellow,
.bg-mesa .h3.text-yellow,
.bg-mesa .h4.text-yellow,
.bg-mesa .h5.text-yellow,
.bg-mesa .h6.text-yellow {
  color: #ffc107;
}

.bg-mesa p.text-green,
.bg-mesa h1.text-green,
.bg-mesa h2.text-green,
.bg-mesa h3.text-green,
.bg-mesa h4.text-green,
.bg-mesa h5.text-green,
.bg-mesa h6.text-green,
.bg-mesa .h1.text-green,
.bg-mesa .h2.text-green,
.bg-mesa .h3.text-green,
.bg-mesa .h4.text-green,
.bg-mesa .h5.text-green,
.bg-mesa .h6.text-green {
  color: #28a745;
}

.bg-mesa p.text-teal,
.bg-mesa h1.text-teal,
.bg-mesa h2.text-teal,
.bg-mesa h3.text-teal,
.bg-mesa h4.text-teal,
.bg-mesa h5.text-teal,
.bg-mesa h6.text-teal,
.bg-mesa .h1.text-teal,
.bg-mesa .h2.text-teal,
.bg-mesa .h3.text-teal,
.bg-mesa .h4.text-teal,
.bg-mesa .h5.text-teal,
.bg-mesa .h6.text-teal {
  color: #20c997;
}

.bg-mesa p.text-cyan,
.bg-mesa h1.text-cyan,
.bg-mesa h2.text-cyan,
.bg-mesa h3.text-cyan,
.bg-mesa h4.text-cyan,
.bg-mesa h5.text-cyan,
.bg-mesa h6.text-cyan,
.bg-mesa .h1.text-cyan,
.bg-mesa .h2.text-cyan,
.bg-mesa .h3.text-cyan,
.bg-mesa .h4.text-cyan,
.bg-mesa .h5.text-cyan,
.bg-mesa .h6.text-cyan {
  color: #17a2b8;
}

.bg-mesa p.text-white,
.bg-mesa h1.text-white,
.bg-mesa h2.text-white,
.bg-mesa h3.text-white,
.bg-mesa h4.text-white,
.bg-mesa h5.text-white,
.bg-mesa h6.text-white,
.bg-mesa .h1.text-white,
.bg-mesa .h2.text-white,
.bg-mesa .h3.text-white,
.bg-mesa .h4.text-white,
.bg-mesa .h5.text-white,
.bg-mesa .h6.text-white {
  color: #fff;
}

.bg-mesa p.text-gray,
.bg-mesa h1.text-gray,
.bg-mesa h2.text-gray,
.bg-mesa h3.text-gray,
.bg-mesa h4.text-gray,
.bg-mesa h5.text-gray,
.bg-mesa h6.text-gray,
.bg-mesa .h1.text-gray,
.bg-mesa .h2.text-gray,
.bg-mesa .h3.text-gray,
.bg-mesa .h4.text-gray,
.bg-mesa .h5.text-gray,
.bg-mesa .h6.text-gray {
  color: #6c757d;
}

.bg-mesa p.text-gray-dark,
.bg-mesa h1.text-gray-dark,
.bg-mesa h2.text-gray-dark,
.bg-mesa h3.text-gray-dark,
.bg-mesa h4.text-gray-dark,
.bg-mesa h5.text-gray-dark,
.bg-mesa h6.text-gray-dark,
.bg-mesa .h1.text-gray-dark,
.bg-mesa .h2.text-gray-dark,
.bg-mesa .h3.text-gray-dark,
.bg-mesa .h4.text-gray-dark,
.bg-mesa .h5.text-gray-dark,
.bg-mesa .h6.text-gray-dark {
  color: #343a40;
}

.bg-mesa p.text-bloom,
.bg-mesa h1.text-bloom,
.bg-mesa h2.text-bloom,
.bg-mesa h3.text-bloom,
.bg-mesa h4.text-bloom,
.bg-mesa h5.text-bloom,
.bg-mesa h6.text-bloom,
.bg-mesa .h1.text-bloom,
.bg-mesa .h2.text-bloom,
.bg-mesa .h3.text-bloom,
.bg-mesa .h4.text-bloom,
.bg-mesa .h5.text-bloom,
.bg-mesa .h6.text-bloom {
  color: #ef4056;
}

.bg-mesa p.text-chili,
.bg-mesa h1.text-chili,
.bg-mesa h2.text-chili,
.bg-mesa h3.text-chili,
.bg-mesa h4.text-chili,
.bg-mesa h5.text-chili,
.bg-mesa h6.text-chili,
.bg-mesa .h1.text-chili,
.bg-mesa .h2.text-chili,
.bg-mesa .h3.text-chili,
.bg-mesa .h4.text-chili,
.bg-mesa .h5.text-chili,
.bg-mesa .h6.text-chili {
  color: #8b0015;
}

.bg-mesa p.text-sky,
.bg-mesa h1.text-sky,
.bg-mesa h2.text-sky,
.bg-mesa h3.text-sky,
.bg-mesa h4.text-sky,
.bg-mesa h5.text-sky,
.bg-mesa h6.text-sky,
.bg-mesa .h1.text-sky,
.bg-mesa .h2.text-sky,
.bg-mesa .h3.text-sky,
.bg-mesa .h4.text-sky,
.bg-mesa .h5.text-sky,
.bg-mesa .h6.text-sky {
  color: #81d3eb;
}

.bg-mesa p.text-oasis,
.bg-mesa h1.text-oasis,
.bg-mesa h2.text-oasis,
.bg-mesa h3.text-oasis,
.bg-mesa h4.text-oasis,
.bg-mesa h5.text-oasis,
.bg-mesa h6.text-oasis,
.bg-mesa .h1.text-oasis,
.bg-mesa .h2.text-oasis,
.bg-mesa .h3.text-oasis,
.bg-mesa .h4.text-oasis,
.bg-mesa .h5.text-oasis,
.bg-mesa .h6.text-oasis {
  color: #378dbd;
}

.bg-mesa p.text-azurite,
.bg-mesa h1.text-azurite,
.bg-mesa h2.text-azurite,
.bg-mesa h3.text-azurite,
.bg-mesa h4.text-azurite,
.bg-mesa h5.text-azurite,
.bg-mesa h6.text-azurite,
.bg-mesa .h1.text-azurite,
.bg-mesa .h2.text-azurite,
.bg-mesa .h3.text-azurite,
.bg-mesa .h4.text-azurite,
.bg-mesa .h5.text-azurite,
.bg-mesa .h6.text-azurite {
  color: #1e5288;
}

.bg-mesa p.text-midnight,
.bg-mesa h1.text-midnight,
.bg-mesa h2.text-midnight,
.bg-mesa h3.text-midnight,
.bg-mesa h4.text-midnight,
.bg-mesa h5.text-midnight,
.bg-mesa h6.text-midnight,
.bg-mesa .h1.text-midnight,
.bg-mesa .h2.text-midnight,
.bg-mesa .h3.text-midnight,
.bg-mesa .h4.text-midnight,
.bg-mesa .h5.text-midnight,
.bg-mesa .h6.text-midnight {
  color: #001c48;
}

.bg-mesa p.text-cool-gray,
.bg-mesa h1.text-cool-gray,
.bg-mesa h2.text-cool-gray,
.bg-mesa h3.text-cool-gray,
.bg-mesa h4.text-cool-gray,
.bg-mesa h5.text-cool-gray,
.bg-mesa h6.text-cool-gray,
.bg-mesa .h1.text-cool-gray,
.bg-mesa .h2.text-cool-gray,
.bg-mesa .h3.text-cool-gray,
.bg-mesa .h4.text-cool-gray,
.bg-mesa .h5.text-cool-gray,
.bg-mesa .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-mesa p.text-warm-gray,
.bg-mesa h1.text-warm-gray,
.bg-mesa h2.text-warm-gray,
.bg-mesa h3.text-warm-gray,
.bg-mesa h4.text-warm-gray,
.bg-mesa h5.text-warm-gray,
.bg-mesa h6.text-warm-gray,
.bg-mesa .h1.text-warm-gray,
.bg-mesa .h2.text-warm-gray,
.bg-mesa .h3.text-warm-gray,
.bg-mesa .h4.text-warm-gray,
.bg-mesa .h5.text-warm-gray,
.bg-mesa .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-mesa p.text-leaf,
.bg-mesa h1.text-leaf,
.bg-mesa h2.text-leaf,
.bg-mesa h3.text-leaf,
.bg-mesa h4.text-leaf,
.bg-mesa h5.text-leaf,
.bg-mesa h6.text-leaf,
.bg-mesa .h1.text-leaf,
.bg-mesa .h2.text-leaf,
.bg-mesa .h3.text-leaf,
.bg-mesa .h4.text-leaf,
.bg-mesa .h5.text-leaf,
.bg-mesa .h6.text-leaf {
  color: #70b865;
}

.bg-mesa p.text-river,
.bg-mesa h1.text-river,
.bg-mesa h2.text-river,
.bg-mesa h3.text-river,
.bg-mesa h4.text-river,
.bg-mesa h5.text-river,
.bg-mesa h6.text-river,
.bg-mesa .h1.text-river,
.bg-mesa .h2.text-river,
.bg-mesa .h3.text-river,
.bg-mesa .h4.text-river,
.bg-mesa .h5.text-river,
.bg-mesa .h6.text-river {
  color: #007d84;
}

.bg-mesa p.text-silver,
.bg-mesa h1.text-silver,
.bg-mesa h2.text-silver,
.bg-mesa h3.text-silver,
.bg-mesa h4.text-silver,
.bg-mesa h5.text-silver,
.bg-mesa h6.text-silver,
.bg-mesa .h1.text-silver,
.bg-mesa .h2.text-silver,
.bg-mesa .h3.text-silver,
.bg-mesa .h4.text-silver,
.bg-mesa .h5.text-silver,
.bg-mesa .h6.text-silver {
  color: #9eabae;
}

.bg-mesa p.text-mesa,
.bg-mesa h1.text-mesa,
.bg-mesa h2.text-mesa,
.bg-mesa h3.text-mesa,
.bg-mesa h4.text-mesa,
.bg-mesa h5.text-mesa,
.bg-mesa h6.text-mesa,
.bg-mesa .h1.text-mesa,
.bg-mesa .h2.text-mesa,
.bg-mesa .h3.text-mesa,
.bg-mesa .h4.text-mesa,
.bg-mesa .h5.text-mesa,
.bg-mesa .h6.text-mesa {
  color: #a95c42;
}

.bg-mesa p.text-ash,
.bg-mesa h1.text-ash,
.bg-mesa h2.text-ash,
.bg-mesa h3.text-ash,
.bg-mesa h4.text-ash,
.bg-mesa h5.text-ash,
.bg-mesa h6.text-ash,
.bg-mesa .h1.text-ash,
.bg-mesa .h2.text-ash,
.bg-mesa .h3.text-ash,
.bg-mesa .h4.text-ash,
.bg-mesa .h5.text-ash,
.bg-mesa .h6.text-ash {
  color: #403635;
}

.bg-mesa p.text-sage,
.bg-mesa h1.text-sage,
.bg-mesa h2.text-sage,
.bg-mesa h3.text-sage,
.bg-mesa h4.text-sage,
.bg-mesa h5.text-sage,
.bg-mesa h6.text-sage,
.bg-mesa .h1.text-sage,
.bg-mesa .h2.text-sage,
.bg-mesa .h3.text-sage,
.bg-mesa .h4.text-sage,
.bg-mesa .h5.text-sage,
.bg-mesa .h6.text-sage {
  color: #4a634e;
}

.bg-mesa p.text-black,
.bg-mesa h1.text-black,
.bg-mesa h2.text-black,
.bg-mesa h3.text-black,
.bg-mesa h4.text-black,
.bg-mesa h5.text-black,
.bg-mesa h6.text-black,
.bg-mesa .h1.text-black,
.bg-mesa .h2.text-black,
.bg-mesa .h3.text-black,
.bg-mesa .h4.text-black,
.bg-mesa .h5.text-black,
.bg-mesa .h6.text-black {
  color: #000;
}

.bg-mesa p.text-primary,
.bg-mesa h1.text-primary,
.bg-mesa h2.text-primary,
.bg-mesa h3.text-primary,
.bg-mesa h4.text-primary,
.bg-mesa h5.text-primary,
.bg-mesa h6.text-primary,
.bg-mesa .h1.text-primary,
.bg-mesa .h2.text-primary,
.bg-mesa .h3.text-primary,
.bg-mesa .h4.text-primary,
.bg-mesa .h5.text-primary,
.bg-mesa .h6.text-primary {
  color: #1e5288;
}

.bg-mesa p.text-secondary,
.bg-mesa h1.text-secondary,
.bg-mesa h2.text-secondary,
.bg-mesa h3.text-secondary,
.bg-mesa h4.text-secondary,
.bg-mesa h5.text-secondary,
.bg-mesa h6.text-secondary,
.bg-mesa .h1.text-secondary,
.bg-mesa .h2.text-secondary,
.bg-mesa .h3.text-secondary,
.bg-mesa .h4.text-secondary,
.bg-mesa .h5.text-secondary,
.bg-mesa .h6.text-secondary {
  color: #9eabae;
}

.bg-mesa p.text-success,
.bg-mesa h1.text-success,
.bg-mesa h2.text-success,
.bg-mesa h3.text-success,
.bg-mesa h4.text-success,
.bg-mesa h5.text-success,
.bg-mesa h6.text-success,
.bg-mesa .h1.text-success,
.bg-mesa .h2.text-success,
.bg-mesa .h3.text-success,
.bg-mesa .h4.text-success,
.bg-mesa .h5.text-success,
.bg-mesa .h6.text-success {
  color: #70b865;
}

.bg-mesa p.text-info,
.bg-mesa h1.text-info,
.bg-mesa h2.text-info,
.bg-mesa h3.text-info,
.bg-mesa h4.text-info,
.bg-mesa h5.text-info,
.bg-mesa h6.text-info,
.bg-mesa .h1.text-info,
.bg-mesa .h2.text-info,
.bg-mesa .h3.text-info,
.bg-mesa .h4.text-info,
.bg-mesa .h5.text-info,
.bg-mesa .h6.text-info {
  color: #81d3eb;
}

.bg-mesa p.text-warning,
.bg-mesa h1.text-warning,
.bg-mesa h2.text-warning,
.bg-mesa h3.text-warning,
.bg-mesa h4.text-warning,
.bg-mesa h5.text-warning,
.bg-mesa h6.text-warning,
.bg-mesa .h1.text-warning,
.bg-mesa .h2.text-warning,
.bg-mesa .h3.text-warning,
.bg-mesa .h4.text-warning,
.bg-mesa .h5.text-warning,
.bg-mesa .h6.text-warning {
  color: #f19e1f;
}

.bg-mesa p.text-danger,
.bg-mesa h1.text-danger,
.bg-mesa h2.text-danger,
.bg-mesa h3.text-danger,
.bg-mesa h4.text-danger,
.bg-mesa h5.text-danger,
.bg-mesa h6.text-danger,
.bg-mesa .h1.text-danger,
.bg-mesa .h2.text-danger,
.bg-mesa .h3.text-danger,
.bg-mesa .h4.text-danger,
.bg-mesa .h5.text-danger,
.bg-mesa .h6.text-danger {
  color: #ef4056;
}

.bg-mesa p.text-light,
.bg-mesa h1.text-light,
.bg-mesa h2.text-light,
.bg-mesa h3.text-light,
.bg-mesa h4.text-light,
.bg-mesa h5.text-light,
.bg-mesa h6.text-light,
.bg-mesa .h1.text-light,
.bg-mesa .h2.text-light,
.bg-mesa .h3.text-light,
.bg-mesa .h4.text-light,
.bg-mesa .h5.text-light,
.bg-mesa .h6.text-light {
  color: #dee2e6;
}

.bg-mesa p.text-dark,
.bg-mesa h1.text-dark,
.bg-mesa h2.text-dark,
.bg-mesa h3.text-dark,
.bg-mesa h4.text-dark,
.bg-mesa h5.text-dark,
.bg-mesa h6.text-dark,
.bg-mesa .h1.text-dark,
.bg-mesa .h2.text-dark,
.bg-mesa .h3.text-dark,
.bg-mesa .h4.text-dark,
.bg-mesa .h5.text-dark,
.bg-mesa .h6.text-dark {
  color: #343a40;
}

.bg-mesa p.text-primary-dark,
.bg-mesa h1.text-primary-dark,
.bg-mesa h2.text-primary-dark,
.bg-mesa h3.text-primary-dark,
.bg-mesa h4.text-primary-dark,
.bg-mesa h5.text-primary-dark,
.bg-mesa h6.text-primary-dark,
.bg-mesa .h1.text-primary-dark,
.bg-mesa .h2.text-primary-dark,
.bg-mesa .h3.text-primary-dark,
.bg-mesa .h4.text-primary-dark,
.bg-mesa .h5.text-primary-dark,
.bg-mesa .h6.text-primary-dark {
  color: #0c234b;
}

.bg-mesa p.text-primary-light,
.bg-mesa h1.text-primary-light,
.bg-mesa h2.text-primary-light,
.bg-mesa h3.text-primary-light,
.bg-mesa h4.text-primary-light,
.bg-mesa h5.text-primary-light,
.bg-mesa h6.text-primary-light,
.bg-mesa .h1.text-primary-light,
.bg-mesa .h2.text-primary-light,
.bg-mesa .h3.text-primary-light,
.bg-mesa .h4.text-primary-light,
.bg-mesa .h5.text-primary-light,
.bg-mesa .h6.text-primary-light {
  color: #378dbd;
}

.bg-mesa p.text-accent,
.bg-mesa h1.text-accent,
.bg-mesa h2.text-accent,
.bg-mesa h3.text-accent,
.bg-mesa h4.text-accent,
.bg-mesa h5.text-accent,
.bg-mesa h6.text-accent,
.bg-mesa .h1.text-accent,
.bg-mesa .h2.text-accent,
.bg-mesa .h3.text-accent,
.bg-mesa .h4.text-accent,
.bg-mesa .h5.text-accent,
.bg-mesa .h6.text-accent {
  color: #ab0520;
}

.bg-ash {
  color: #fff;
  background-color: #403635;
  color: #fff;
}

.bg-ash p,
.bg-ash h1,
.bg-ash h2,
.bg-ash h3,
.bg-ash h4,
.bg-ash h5,
.bg-ash h6,
.bg-ash .h1,
.bg-ash .h2,
.bg-ash .h3,
.bg-ash .h4,
.bg-ash .h5,
.bg-ash .h6 {
  color: #fff;
}

.bg-ash p.text-blue,
.bg-ash h1.text-blue,
.bg-ash h2.text-blue,
.bg-ash h3.text-blue,
.bg-ash h4.text-blue,
.bg-ash h5.text-blue,
.bg-ash h6.text-blue,
.bg-ash .h1.text-blue,
.bg-ash .h2.text-blue,
.bg-ash .h3.text-blue,
.bg-ash .h4.text-blue,
.bg-ash .h5.text-blue,
.bg-ash .h6.text-blue {
  color: #0c234b;
}

.bg-ash p.text-indigo,
.bg-ash h1.text-indigo,
.bg-ash h2.text-indigo,
.bg-ash h3.text-indigo,
.bg-ash h4.text-indigo,
.bg-ash h5.text-indigo,
.bg-ash h6.text-indigo,
.bg-ash .h1.text-indigo,
.bg-ash .h2.text-indigo,
.bg-ash .h3.text-indigo,
.bg-ash .h4.text-indigo,
.bg-ash .h5.text-indigo,
.bg-ash .h6.text-indigo {
  color: #6610f2;
}

.bg-ash p.text-purple,
.bg-ash h1.text-purple,
.bg-ash h2.text-purple,
.bg-ash h3.text-purple,
.bg-ash h4.text-purple,
.bg-ash h5.text-purple,
.bg-ash h6.text-purple,
.bg-ash .h1.text-purple,
.bg-ash .h2.text-purple,
.bg-ash .h3.text-purple,
.bg-ash .h4.text-purple,
.bg-ash .h5.text-purple,
.bg-ash .h6.text-purple {
  color: #6f42c1;
}

.bg-ash p.text-pink,
.bg-ash h1.text-pink,
.bg-ash h2.text-pink,
.bg-ash h3.text-pink,
.bg-ash h4.text-pink,
.bg-ash h5.text-pink,
.bg-ash h6.text-pink,
.bg-ash .h1.text-pink,
.bg-ash .h2.text-pink,
.bg-ash .h3.text-pink,
.bg-ash .h4.text-pink,
.bg-ash .h5.text-pink,
.bg-ash .h6.text-pink {
  color: #e83e8c;
}

.bg-ash p.text-red,
.bg-ash h1.text-red,
.bg-ash h2.text-red,
.bg-ash h3.text-red,
.bg-ash h4.text-red,
.bg-ash h5.text-red,
.bg-ash h6.text-red,
.bg-ash .h1.text-red,
.bg-ash .h2.text-red,
.bg-ash .h3.text-red,
.bg-ash .h4.text-red,
.bg-ash .h5.text-red,
.bg-ash .h6.text-red {
  color: #ab0520;
}

.bg-ash p.text-orange,
.bg-ash h1.text-orange,
.bg-ash h2.text-orange,
.bg-ash h3.text-orange,
.bg-ash h4.text-orange,
.bg-ash h5.text-orange,
.bg-ash h6.text-orange,
.bg-ash .h1.text-orange,
.bg-ash .h2.text-orange,
.bg-ash .h3.text-orange,
.bg-ash .h4.text-orange,
.bg-ash .h5.text-orange,
.bg-ash .h6.text-orange {
  color: #fd7e14;
}

.bg-ash p.text-yellow,
.bg-ash h1.text-yellow,
.bg-ash h2.text-yellow,
.bg-ash h3.text-yellow,
.bg-ash h4.text-yellow,
.bg-ash h5.text-yellow,
.bg-ash h6.text-yellow,
.bg-ash .h1.text-yellow,
.bg-ash .h2.text-yellow,
.bg-ash .h3.text-yellow,
.bg-ash .h4.text-yellow,
.bg-ash .h5.text-yellow,
.bg-ash .h6.text-yellow {
  color: #ffc107;
}

.bg-ash p.text-green,
.bg-ash h1.text-green,
.bg-ash h2.text-green,
.bg-ash h3.text-green,
.bg-ash h4.text-green,
.bg-ash h5.text-green,
.bg-ash h6.text-green,
.bg-ash .h1.text-green,
.bg-ash .h2.text-green,
.bg-ash .h3.text-green,
.bg-ash .h4.text-green,
.bg-ash .h5.text-green,
.bg-ash .h6.text-green {
  color: #28a745;
}

.bg-ash p.text-teal,
.bg-ash h1.text-teal,
.bg-ash h2.text-teal,
.bg-ash h3.text-teal,
.bg-ash h4.text-teal,
.bg-ash h5.text-teal,
.bg-ash h6.text-teal,
.bg-ash .h1.text-teal,
.bg-ash .h2.text-teal,
.bg-ash .h3.text-teal,
.bg-ash .h4.text-teal,
.bg-ash .h5.text-teal,
.bg-ash .h6.text-teal {
  color: #20c997;
}

.bg-ash p.text-cyan,
.bg-ash h1.text-cyan,
.bg-ash h2.text-cyan,
.bg-ash h3.text-cyan,
.bg-ash h4.text-cyan,
.bg-ash h5.text-cyan,
.bg-ash h6.text-cyan,
.bg-ash .h1.text-cyan,
.bg-ash .h2.text-cyan,
.bg-ash .h3.text-cyan,
.bg-ash .h4.text-cyan,
.bg-ash .h5.text-cyan,
.bg-ash .h6.text-cyan {
  color: #17a2b8;
}

.bg-ash p.text-white,
.bg-ash h1.text-white,
.bg-ash h2.text-white,
.bg-ash h3.text-white,
.bg-ash h4.text-white,
.bg-ash h5.text-white,
.bg-ash h6.text-white,
.bg-ash .h1.text-white,
.bg-ash .h2.text-white,
.bg-ash .h3.text-white,
.bg-ash .h4.text-white,
.bg-ash .h5.text-white,
.bg-ash .h6.text-white {
  color: #fff;
}

.bg-ash p.text-gray,
.bg-ash h1.text-gray,
.bg-ash h2.text-gray,
.bg-ash h3.text-gray,
.bg-ash h4.text-gray,
.bg-ash h5.text-gray,
.bg-ash h6.text-gray,
.bg-ash .h1.text-gray,
.bg-ash .h2.text-gray,
.bg-ash .h3.text-gray,
.bg-ash .h4.text-gray,
.bg-ash .h5.text-gray,
.bg-ash .h6.text-gray {
  color: #6c757d;
}

.bg-ash p.text-gray-dark,
.bg-ash h1.text-gray-dark,
.bg-ash h2.text-gray-dark,
.bg-ash h3.text-gray-dark,
.bg-ash h4.text-gray-dark,
.bg-ash h5.text-gray-dark,
.bg-ash h6.text-gray-dark,
.bg-ash .h1.text-gray-dark,
.bg-ash .h2.text-gray-dark,
.bg-ash .h3.text-gray-dark,
.bg-ash .h4.text-gray-dark,
.bg-ash .h5.text-gray-dark,
.bg-ash .h6.text-gray-dark {
  color: #343a40;
}

.bg-ash p.text-bloom,
.bg-ash h1.text-bloom,
.bg-ash h2.text-bloom,
.bg-ash h3.text-bloom,
.bg-ash h4.text-bloom,
.bg-ash h5.text-bloom,
.bg-ash h6.text-bloom,
.bg-ash .h1.text-bloom,
.bg-ash .h2.text-bloom,
.bg-ash .h3.text-bloom,
.bg-ash .h4.text-bloom,
.bg-ash .h5.text-bloom,
.bg-ash .h6.text-bloom {
  color: #ef4056;
}

.bg-ash p.text-chili,
.bg-ash h1.text-chili,
.bg-ash h2.text-chili,
.bg-ash h3.text-chili,
.bg-ash h4.text-chili,
.bg-ash h5.text-chili,
.bg-ash h6.text-chili,
.bg-ash .h1.text-chili,
.bg-ash .h2.text-chili,
.bg-ash .h3.text-chili,
.bg-ash .h4.text-chili,
.bg-ash .h5.text-chili,
.bg-ash .h6.text-chili {
  color: #8b0015;
}

.bg-ash p.text-sky,
.bg-ash h1.text-sky,
.bg-ash h2.text-sky,
.bg-ash h3.text-sky,
.bg-ash h4.text-sky,
.bg-ash h5.text-sky,
.bg-ash h6.text-sky,
.bg-ash .h1.text-sky,
.bg-ash .h2.text-sky,
.bg-ash .h3.text-sky,
.bg-ash .h4.text-sky,
.bg-ash .h5.text-sky,
.bg-ash .h6.text-sky {
  color: #81d3eb;
}

.bg-ash p.text-oasis,
.bg-ash h1.text-oasis,
.bg-ash h2.text-oasis,
.bg-ash h3.text-oasis,
.bg-ash h4.text-oasis,
.bg-ash h5.text-oasis,
.bg-ash h6.text-oasis,
.bg-ash .h1.text-oasis,
.bg-ash .h2.text-oasis,
.bg-ash .h3.text-oasis,
.bg-ash .h4.text-oasis,
.bg-ash .h5.text-oasis,
.bg-ash .h6.text-oasis {
  color: #378dbd;
}

.bg-ash p.text-azurite,
.bg-ash h1.text-azurite,
.bg-ash h2.text-azurite,
.bg-ash h3.text-azurite,
.bg-ash h4.text-azurite,
.bg-ash h5.text-azurite,
.bg-ash h6.text-azurite,
.bg-ash .h1.text-azurite,
.bg-ash .h2.text-azurite,
.bg-ash .h3.text-azurite,
.bg-ash .h4.text-azurite,
.bg-ash .h5.text-azurite,
.bg-ash .h6.text-azurite {
  color: #1e5288;
}

.bg-ash p.text-midnight,
.bg-ash h1.text-midnight,
.bg-ash h2.text-midnight,
.bg-ash h3.text-midnight,
.bg-ash h4.text-midnight,
.bg-ash h5.text-midnight,
.bg-ash h6.text-midnight,
.bg-ash .h1.text-midnight,
.bg-ash .h2.text-midnight,
.bg-ash .h3.text-midnight,
.bg-ash .h4.text-midnight,
.bg-ash .h5.text-midnight,
.bg-ash .h6.text-midnight {
  color: #001c48;
}

.bg-ash p.text-cool-gray,
.bg-ash h1.text-cool-gray,
.bg-ash h2.text-cool-gray,
.bg-ash h3.text-cool-gray,
.bg-ash h4.text-cool-gray,
.bg-ash h5.text-cool-gray,
.bg-ash h6.text-cool-gray,
.bg-ash .h1.text-cool-gray,
.bg-ash .h2.text-cool-gray,
.bg-ash .h3.text-cool-gray,
.bg-ash .h4.text-cool-gray,
.bg-ash .h5.text-cool-gray,
.bg-ash .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-ash p.text-warm-gray,
.bg-ash h1.text-warm-gray,
.bg-ash h2.text-warm-gray,
.bg-ash h3.text-warm-gray,
.bg-ash h4.text-warm-gray,
.bg-ash h5.text-warm-gray,
.bg-ash h6.text-warm-gray,
.bg-ash .h1.text-warm-gray,
.bg-ash .h2.text-warm-gray,
.bg-ash .h3.text-warm-gray,
.bg-ash .h4.text-warm-gray,
.bg-ash .h5.text-warm-gray,
.bg-ash .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-ash p.text-leaf,
.bg-ash h1.text-leaf,
.bg-ash h2.text-leaf,
.bg-ash h3.text-leaf,
.bg-ash h4.text-leaf,
.bg-ash h5.text-leaf,
.bg-ash h6.text-leaf,
.bg-ash .h1.text-leaf,
.bg-ash .h2.text-leaf,
.bg-ash .h3.text-leaf,
.bg-ash .h4.text-leaf,
.bg-ash .h5.text-leaf,
.bg-ash .h6.text-leaf {
  color: #70b865;
}

.bg-ash p.text-river,
.bg-ash h1.text-river,
.bg-ash h2.text-river,
.bg-ash h3.text-river,
.bg-ash h4.text-river,
.bg-ash h5.text-river,
.bg-ash h6.text-river,
.bg-ash .h1.text-river,
.bg-ash .h2.text-river,
.bg-ash .h3.text-river,
.bg-ash .h4.text-river,
.bg-ash .h5.text-river,
.bg-ash .h6.text-river {
  color: #007d84;
}

.bg-ash p.text-silver,
.bg-ash h1.text-silver,
.bg-ash h2.text-silver,
.bg-ash h3.text-silver,
.bg-ash h4.text-silver,
.bg-ash h5.text-silver,
.bg-ash h6.text-silver,
.bg-ash .h1.text-silver,
.bg-ash .h2.text-silver,
.bg-ash .h3.text-silver,
.bg-ash .h4.text-silver,
.bg-ash .h5.text-silver,
.bg-ash .h6.text-silver {
  color: #9eabae;
}

.bg-ash p.text-mesa,
.bg-ash h1.text-mesa,
.bg-ash h2.text-mesa,
.bg-ash h3.text-mesa,
.bg-ash h4.text-mesa,
.bg-ash h5.text-mesa,
.bg-ash h6.text-mesa,
.bg-ash .h1.text-mesa,
.bg-ash .h2.text-mesa,
.bg-ash .h3.text-mesa,
.bg-ash .h4.text-mesa,
.bg-ash .h5.text-mesa,
.bg-ash .h6.text-mesa {
  color: #a95c42;
}

.bg-ash p.text-ash,
.bg-ash h1.text-ash,
.bg-ash h2.text-ash,
.bg-ash h3.text-ash,
.bg-ash h4.text-ash,
.bg-ash h5.text-ash,
.bg-ash h6.text-ash,
.bg-ash .h1.text-ash,
.bg-ash .h2.text-ash,
.bg-ash .h3.text-ash,
.bg-ash .h4.text-ash,
.bg-ash .h5.text-ash,
.bg-ash .h6.text-ash {
  color: #403635;
}

.bg-ash p.text-sage,
.bg-ash h1.text-sage,
.bg-ash h2.text-sage,
.bg-ash h3.text-sage,
.bg-ash h4.text-sage,
.bg-ash h5.text-sage,
.bg-ash h6.text-sage,
.bg-ash .h1.text-sage,
.bg-ash .h2.text-sage,
.bg-ash .h3.text-sage,
.bg-ash .h4.text-sage,
.bg-ash .h5.text-sage,
.bg-ash .h6.text-sage {
  color: #4a634e;
}

.bg-ash p.text-black,
.bg-ash h1.text-black,
.bg-ash h2.text-black,
.bg-ash h3.text-black,
.bg-ash h4.text-black,
.bg-ash h5.text-black,
.bg-ash h6.text-black,
.bg-ash .h1.text-black,
.bg-ash .h2.text-black,
.bg-ash .h3.text-black,
.bg-ash .h4.text-black,
.bg-ash .h5.text-black,
.bg-ash .h6.text-black {
  color: #000;
}

.bg-ash p.text-primary,
.bg-ash h1.text-primary,
.bg-ash h2.text-primary,
.bg-ash h3.text-primary,
.bg-ash h4.text-primary,
.bg-ash h5.text-primary,
.bg-ash h6.text-primary,
.bg-ash .h1.text-primary,
.bg-ash .h2.text-primary,
.bg-ash .h3.text-primary,
.bg-ash .h4.text-primary,
.bg-ash .h5.text-primary,
.bg-ash .h6.text-primary {
  color: #1e5288;
}

.bg-ash p.text-secondary,
.bg-ash h1.text-secondary,
.bg-ash h2.text-secondary,
.bg-ash h3.text-secondary,
.bg-ash h4.text-secondary,
.bg-ash h5.text-secondary,
.bg-ash h6.text-secondary,
.bg-ash .h1.text-secondary,
.bg-ash .h2.text-secondary,
.bg-ash .h3.text-secondary,
.bg-ash .h4.text-secondary,
.bg-ash .h5.text-secondary,
.bg-ash .h6.text-secondary {
  color: #9eabae;
}

.bg-ash p.text-success,
.bg-ash h1.text-success,
.bg-ash h2.text-success,
.bg-ash h3.text-success,
.bg-ash h4.text-success,
.bg-ash h5.text-success,
.bg-ash h6.text-success,
.bg-ash .h1.text-success,
.bg-ash .h2.text-success,
.bg-ash .h3.text-success,
.bg-ash .h4.text-success,
.bg-ash .h5.text-success,
.bg-ash .h6.text-success {
  color: #70b865;
}

.bg-ash p.text-info,
.bg-ash h1.text-info,
.bg-ash h2.text-info,
.bg-ash h3.text-info,
.bg-ash h4.text-info,
.bg-ash h5.text-info,
.bg-ash h6.text-info,
.bg-ash .h1.text-info,
.bg-ash .h2.text-info,
.bg-ash .h3.text-info,
.bg-ash .h4.text-info,
.bg-ash .h5.text-info,
.bg-ash .h6.text-info {
  color: #81d3eb;
}

.bg-ash p.text-warning,
.bg-ash h1.text-warning,
.bg-ash h2.text-warning,
.bg-ash h3.text-warning,
.bg-ash h4.text-warning,
.bg-ash h5.text-warning,
.bg-ash h6.text-warning,
.bg-ash .h1.text-warning,
.bg-ash .h2.text-warning,
.bg-ash .h3.text-warning,
.bg-ash .h4.text-warning,
.bg-ash .h5.text-warning,
.bg-ash .h6.text-warning {
  color: #f19e1f;
}

.bg-ash p.text-danger,
.bg-ash h1.text-danger,
.bg-ash h2.text-danger,
.bg-ash h3.text-danger,
.bg-ash h4.text-danger,
.bg-ash h5.text-danger,
.bg-ash h6.text-danger,
.bg-ash .h1.text-danger,
.bg-ash .h2.text-danger,
.bg-ash .h3.text-danger,
.bg-ash .h4.text-danger,
.bg-ash .h5.text-danger,
.bg-ash .h6.text-danger {
  color: #ef4056;
}

.bg-ash p.text-light,
.bg-ash h1.text-light,
.bg-ash h2.text-light,
.bg-ash h3.text-light,
.bg-ash h4.text-light,
.bg-ash h5.text-light,
.bg-ash h6.text-light,
.bg-ash .h1.text-light,
.bg-ash .h2.text-light,
.bg-ash .h3.text-light,
.bg-ash .h4.text-light,
.bg-ash .h5.text-light,
.bg-ash .h6.text-light {
  color: #dee2e6;
}

.bg-ash p.text-dark,
.bg-ash h1.text-dark,
.bg-ash h2.text-dark,
.bg-ash h3.text-dark,
.bg-ash h4.text-dark,
.bg-ash h5.text-dark,
.bg-ash h6.text-dark,
.bg-ash .h1.text-dark,
.bg-ash .h2.text-dark,
.bg-ash .h3.text-dark,
.bg-ash .h4.text-dark,
.bg-ash .h5.text-dark,
.bg-ash .h6.text-dark {
  color: #343a40;
}

.bg-ash p.text-primary-dark,
.bg-ash h1.text-primary-dark,
.bg-ash h2.text-primary-dark,
.bg-ash h3.text-primary-dark,
.bg-ash h4.text-primary-dark,
.bg-ash h5.text-primary-dark,
.bg-ash h6.text-primary-dark,
.bg-ash .h1.text-primary-dark,
.bg-ash .h2.text-primary-dark,
.bg-ash .h3.text-primary-dark,
.bg-ash .h4.text-primary-dark,
.bg-ash .h5.text-primary-dark,
.bg-ash .h6.text-primary-dark {
  color: #0c234b;
}

.bg-ash p.text-primary-light,
.bg-ash h1.text-primary-light,
.bg-ash h2.text-primary-light,
.bg-ash h3.text-primary-light,
.bg-ash h4.text-primary-light,
.bg-ash h5.text-primary-light,
.bg-ash h6.text-primary-light,
.bg-ash .h1.text-primary-light,
.bg-ash .h2.text-primary-light,
.bg-ash .h3.text-primary-light,
.bg-ash .h4.text-primary-light,
.bg-ash .h5.text-primary-light,
.bg-ash .h6.text-primary-light {
  color: #378dbd;
}

.bg-ash p.text-accent,
.bg-ash h1.text-accent,
.bg-ash h2.text-accent,
.bg-ash h3.text-accent,
.bg-ash h4.text-accent,
.bg-ash h5.text-accent,
.bg-ash h6.text-accent,
.bg-ash .h1.text-accent,
.bg-ash .h2.text-accent,
.bg-ash .h3.text-accent,
.bg-ash .h4.text-accent,
.bg-ash .h5.text-accent,
.bg-ash .h6.text-accent {
  color: #ab0520;
}

.bg-sage {
  color: #fff;
  background-color: #4a634e;
  color: #fff;
}

.bg-sage p,
.bg-sage h1,
.bg-sage h2,
.bg-sage h3,
.bg-sage h4,
.bg-sage h5,
.bg-sage h6,
.bg-sage .h1,
.bg-sage .h2,
.bg-sage .h3,
.bg-sage .h4,
.bg-sage .h5,
.bg-sage .h6 {
  color: #fff;
}

.bg-sage p.text-blue,
.bg-sage h1.text-blue,
.bg-sage h2.text-blue,
.bg-sage h3.text-blue,
.bg-sage h4.text-blue,
.bg-sage h5.text-blue,
.bg-sage h6.text-blue,
.bg-sage .h1.text-blue,
.bg-sage .h2.text-blue,
.bg-sage .h3.text-blue,
.bg-sage .h4.text-blue,
.bg-sage .h5.text-blue,
.bg-sage .h6.text-blue {
  color: #0c234b;
}

.bg-sage p.text-indigo,
.bg-sage h1.text-indigo,
.bg-sage h2.text-indigo,
.bg-sage h3.text-indigo,
.bg-sage h4.text-indigo,
.bg-sage h5.text-indigo,
.bg-sage h6.text-indigo,
.bg-sage .h1.text-indigo,
.bg-sage .h2.text-indigo,
.bg-sage .h3.text-indigo,
.bg-sage .h4.text-indigo,
.bg-sage .h5.text-indigo,
.bg-sage .h6.text-indigo {
  color: #6610f2;
}

.bg-sage p.text-purple,
.bg-sage h1.text-purple,
.bg-sage h2.text-purple,
.bg-sage h3.text-purple,
.bg-sage h4.text-purple,
.bg-sage h5.text-purple,
.bg-sage h6.text-purple,
.bg-sage .h1.text-purple,
.bg-sage .h2.text-purple,
.bg-sage .h3.text-purple,
.bg-sage .h4.text-purple,
.bg-sage .h5.text-purple,
.bg-sage .h6.text-purple {
  color: #6f42c1;
}

.bg-sage p.text-pink,
.bg-sage h1.text-pink,
.bg-sage h2.text-pink,
.bg-sage h3.text-pink,
.bg-sage h4.text-pink,
.bg-sage h5.text-pink,
.bg-sage h6.text-pink,
.bg-sage .h1.text-pink,
.bg-sage .h2.text-pink,
.bg-sage .h3.text-pink,
.bg-sage .h4.text-pink,
.bg-sage .h5.text-pink,
.bg-sage .h6.text-pink {
  color: #e83e8c;
}

.bg-sage p.text-red,
.bg-sage h1.text-red,
.bg-sage h2.text-red,
.bg-sage h3.text-red,
.bg-sage h4.text-red,
.bg-sage h5.text-red,
.bg-sage h6.text-red,
.bg-sage .h1.text-red,
.bg-sage .h2.text-red,
.bg-sage .h3.text-red,
.bg-sage .h4.text-red,
.bg-sage .h5.text-red,
.bg-sage .h6.text-red {
  color: #ab0520;
}

.bg-sage p.text-orange,
.bg-sage h1.text-orange,
.bg-sage h2.text-orange,
.bg-sage h3.text-orange,
.bg-sage h4.text-orange,
.bg-sage h5.text-orange,
.bg-sage h6.text-orange,
.bg-sage .h1.text-orange,
.bg-sage .h2.text-orange,
.bg-sage .h3.text-orange,
.bg-sage .h4.text-orange,
.bg-sage .h5.text-orange,
.bg-sage .h6.text-orange {
  color: #fd7e14;
}

.bg-sage p.text-yellow,
.bg-sage h1.text-yellow,
.bg-sage h2.text-yellow,
.bg-sage h3.text-yellow,
.bg-sage h4.text-yellow,
.bg-sage h5.text-yellow,
.bg-sage h6.text-yellow,
.bg-sage .h1.text-yellow,
.bg-sage .h2.text-yellow,
.bg-sage .h3.text-yellow,
.bg-sage .h4.text-yellow,
.bg-sage .h5.text-yellow,
.bg-sage .h6.text-yellow {
  color: #ffc107;
}

.bg-sage p.text-green,
.bg-sage h1.text-green,
.bg-sage h2.text-green,
.bg-sage h3.text-green,
.bg-sage h4.text-green,
.bg-sage h5.text-green,
.bg-sage h6.text-green,
.bg-sage .h1.text-green,
.bg-sage .h2.text-green,
.bg-sage .h3.text-green,
.bg-sage .h4.text-green,
.bg-sage .h5.text-green,
.bg-sage .h6.text-green {
  color: #28a745;
}

.bg-sage p.text-teal,
.bg-sage h1.text-teal,
.bg-sage h2.text-teal,
.bg-sage h3.text-teal,
.bg-sage h4.text-teal,
.bg-sage h5.text-teal,
.bg-sage h6.text-teal,
.bg-sage .h1.text-teal,
.bg-sage .h2.text-teal,
.bg-sage .h3.text-teal,
.bg-sage .h4.text-teal,
.bg-sage .h5.text-teal,
.bg-sage .h6.text-teal {
  color: #20c997;
}

.bg-sage p.text-cyan,
.bg-sage h1.text-cyan,
.bg-sage h2.text-cyan,
.bg-sage h3.text-cyan,
.bg-sage h4.text-cyan,
.bg-sage h5.text-cyan,
.bg-sage h6.text-cyan,
.bg-sage .h1.text-cyan,
.bg-sage .h2.text-cyan,
.bg-sage .h3.text-cyan,
.bg-sage .h4.text-cyan,
.bg-sage .h5.text-cyan,
.bg-sage .h6.text-cyan {
  color: #17a2b8;
}

.bg-sage p.text-white,
.bg-sage h1.text-white,
.bg-sage h2.text-white,
.bg-sage h3.text-white,
.bg-sage h4.text-white,
.bg-sage h5.text-white,
.bg-sage h6.text-white,
.bg-sage .h1.text-white,
.bg-sage .h2.text-white,
.bg-sage .h3.text-white,
.bg-sage .h4.text-white,
.bg-sage .h5.text-white,
.bg-sage .h6.text-white {
  color: #fff;
}

.bg-sage p.text-gray,
.bg-sage h1.text-gray,
.bg-sage h2.text-gray,
.bg-sage h3.text-gray,
.bg-sage h4.text-gray,
.bg-sage h5.text-gray,
.bg-sage h6.text-gray,
.bg-sage .h1.text-gray,
.bg-sage .h2.text-gray,
.bg-sage .h3.text-gray,
.bg-sage .h4.text-gray,
.bg-sage .h5.text-gray,
.bg-sage .h6.text-gray {
  color: #6c757d;
}

.bg-sage p.text-gray-dark,
.bg-sage h1.text-gray-dark,
.bg-sage h2.text-gray-dark,
.bg-sage h3.text-gray-dark,
.bg-sage h4.text-gray-dark,
.bg-sage h5.text-gray-dark,
.bg-sage h6.text-gray-dark,
.bg-sage .h1.text-gray-dark,
.bg-sage .h2.text-gray-dark,
.bg-sage .h3.text-gray-dark,
.bg-sage .h4.text-gray-dark,
.bg-sage .h5.text-gray-dark,
.bg-sage .h6.text-gray-dark {
  color: #343a40;
}

.bg-sage p.text-bloom,
.bg-sage h1.text-bloom,
.bg-sage h2.text-bloom,
.bg-sage h3.text-bloom,
.bg-sage h4.text-bloom,
.bg-sage h5.text-bloom,
.bg-sage h6.text-bloom,
.bg-sage .h1.text-bloom,
.bg-sage .h2.text-bloom,
.bg-sage .h3.text-bloom,
.bg-sage .h4.text-bloom,
.bg-sage .h5.text-bloom,
.bg-sage .h6.text-bloom {
  color: #ef4056;
}

.bg-sage p.text-chili,
.bg-sage h1.text-chili,
.bg-sage h2.text-chili,
.bg-sage h3.text-chili,
.bg-sage h4.text-chili,
.bg-sage h5.text-chili,
.bg-sage h6.text-chili,
.bg-sage .h1.text-chili,
.bg-sage .h2.text-chili,
.bg-sage .h3.text-chili,
.bg-sage .h4.text-chili,
.bg-sage .h5.text-chili,
.bg-sage .h6.text-chili {
  color: #8b0015;
}

.bg-sage p.text-sky,
.bg-sage h1.text-sky,
.bg-sage h2.text-sky,
.bg-sage h3.text-sky,
.bg-sage h4.text-sky,
.bg-sage h5.text-sky,
.bg-sage h6.text-sky,
.bg-sage .h1.text-sky,
.bg-sage .h2.text-sky,
.bg-sage .h3.text-sky,
.bg-sage .h4.text-sky,
.bg-sage .h5.text-sky,
.bg-sage .h6.text-sky {
  color: #81d3eb;
}

.bg-sage p.text-oasis,
.bg-sage h1.text-oasis,
.bg-sage h2.text-oasis,
.bg-sage h3.text-oasis,
.bg-sage h4.text-oasis,
.bg-sage h5.text-oasis,
.bg-sage h6.text-oasis,
.bg-sage .h1.text-oasis,
.bg-sage .h2.text-oasis,
.bg-sage .h3.text-oasis,
.bg-sage .h4.text-oasis,
.bg-sage .h5.text-oasis,
.bg-sage .h6.text-oasis {
  color: #378dbd;
}

.bg-sage p.text-azurite,
.bg-sage h1.text-azurite,
.bg-sage h2.text-azurite,
.bg-sage h3.text-azurite,
.bg-sage h4.text-azurite,
.bg-sage h5.text-azurite,
.bg-sage h6.text-azurite,
.bg-sage .h1.text-azurite,
.bg-sage .h2.text-azurite,
.bg-sage .h3.text-azurite,
.bg-sage .h4.text-azurite,
.bg-sage .h5.text-azurite,
.bg-sage .h6.text-azurite {
  color: #1e5288;
}

.bg-sage p.text-midnight,
.bg-sage h1.text-midnight,
.bg-sage h2.text-midnight,
.bg-sage h3.text-midnight,
.bg-sage h4.text-midnight,
.bg-sage h5.text-midnight,
.bg-sage h6.text-midnight,
.bg-sage .h1.text-midnight,
.bg-sage .h2.text-midnight,
.bg-sage .h3.text-midnight,
.bg-sage .h4.text-midnight,
.bg-sage .h5.text-midnight,
.bg-sage .h6.text-midnight {
  color: #001c48;
}

.bg-sage p.text-cool-gray,
.bg-sage h1.text-cool-gray,
.bg-sage h2.text-cool-gray,
.bg-sage h3.text-cool-gray,
.bg-sage h4.text-cool-gray,
.bg-sage h5.text-cool-gray,
.bg-sage h6.text-cool-gray,
.bg-sage .h1.text-cool-gray,
.bg-sage .h2.text-cool-gray,
.bg-sage .h3.text-cool-gray,
.bg-sage .h4.text-cool-gray,
.bg-sage .h5.text-cool-gray,
.bg-sage .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-sage p.text-warm-gray,
.bg-sage h1.text-warm-gray,
.bg-sage h2.text-warm-gray,
.bg-sage h3.text-warm-gray,
.bg-sage h4.text-warm-gray,
.bg-sage h5.text-warm-gray,
.bg-sage h6.text-warm-gray,
.bg-sage .h1.text-warm-gray,
.bg-sage .h2.text-warm-gray,
.bg-sage .h3.text-warm-gray,
.bg-sage .h4.text-warm-gray,
.bg-sage .h5.text-warm-gray,
.bg-sage .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-sage p.text-leaf,
.bg-sage h1.text-leaf,
.bg-sage h2.text-leaf,
.bg-sage h3.text-leaf,
.bg-sage h4.text-leaf,
.bg-sage h5.text-leaf,
.bg-sage h6.text-leaf,
.bg-sage .h1.text-leaf,
.bg-sage .h2.text-leaf,
.bg-sage .h3.text-leaf,
.bg-sage .h4.text-leaf,
.bg-sage .h5.text-leaf,
.bg-sage .h6.text-leaf {
  color: #70b865;
}

.bg-sage p.text-river,
.bg-sage h1.text-river,
.bg-sage h2.text-river,
.bg-sage h3.text-river,
.bg-sage h4.text-river,
.bg-sage h5.text-river,
.bg-sage h6.text-river,
.bg-sage .h1.text-river,
.bg-sage .h2.text-river,
.bg-sage .h3.text-river,
.bg-sage .h4.text-river,
.bg-sage .h5.text-river,
.bg-sage .h6.text-river {
  color: #007d84;
}

.bg-sage p.text-silver,
.bg-sage h1.text-silver,
.bg-sage h2.text-silver,
.bg-sage h3.text-silver,
.bg-sage h4.text-silver,
.bg-sage h5.text-silver,
.bg-sage h6.text-silver,
.bg-sage .h1.text-silver,
.bg-sage .h2.text-silver,
.bg-sage .h3.text-silver,
.bg-sage .h4.text-silver,
.bg-sage .h5.text-silver,
.bg-sage .h6.text-silver {
  color: #9eabae;
}

.bg-sage p.text-mesa,
.bg-sage h1.text-mesa,
.bg-sage h2.text-mesa,
.bg-sage h3.text-mesa,
.bg-sage h4.text-mesa,
.bg-sage h5.text-mesa,
.bg-sage h6.text-mesa,
.bg-sage .h1.text-mesa,
.bg-sage .h2.text-mesa,
.bg-sage .h3.text-mesa,
.bg-sage .h4.text-mesa,
.bg-sage .h5.text-mesa,
.bg-sage .h6.text-mesa {
  color: #a95c42;
}

.bg-sage p.text-ash,
.bg-sage h1.text-ash,
.bg-sage h2.text-ash,
.bg-sage h3.text-ash,
.bg-sage h4.text-ash,
.bg-sage h5.text-ash,
.bg-sage h6.text-ash,
.bg-sage .h1.text-ash,
.bg-sage .h2.text-ash,
.bg-sage .h3.text-ash,
.bg-sage .h4.text-ash,
.bg-sage .h5.text-ash,
.bg-sage .h6.text-ash {
  color: #403635;
}

.bg-sage p.text-sage,
.bg-sage h1.text-sage,
.bg-sage h2.text-sage,
.bg-sage h3.text-sage,
.bg-sage h4.text-sage,
.bg-sage h5.text-sage,
.bg-sage h6.text-sage,
.bg-sage .h1.text-sage,
.bg-sage .h2.text-sage,
.bg-sage .h3.text-sage,
.bg-sage .h4.text-sage,
.bg-sage .h5.text-sage,
.bg-sage .h6.text-sage {
  color: #4a634e;
}

.bg-sage p.text-black,
.bg-sage h1.text-black,
.bg-sage h2.text-black,
.bg-sage h3.text-black,
.bg-sage h4.text-black,
.bg-sage h5.text-black,
.bg-sage h6.text-black,
.bg-sage .h1.text-black,
.bg-sage .h2.text-black,
.bg-sage .h3.text-black,
.bg-sage .h4.text-black,
.bg-sage .h5.text-black,
.bg-sage .h6.text-black {
  color: #000;
}

.bg-sage p.text-primary,
.bg-sage h1.text-primary,
.bg-sage h2.text-primary,
.bg-sage h3.text-primary,
.bg-sage h4.text-primary,
.bg-sage h5.text-primary,
.bg-sage h6.text-primary,
.bg-sage .h1.text-primary,
.bg-sage .h2.text-primary,
.bg-sage .h3.text-primary,
.bg-sage .h4.text-primary,
.bg-sage .h5.text-primary,
.bg-sage .h6.text-primary {
  color: #1e5288;
}

.bg-sage p.text-secondary,
.bg-sage h1.text-secondary,
.bg-sage h2.text-secondary,
.bg-sage h3.text-secondary,
.bg-sage h4.text-secondary,
.bg-sage h5.text-secondary,
.bg-sage h6.text-secondary,
.bg-sage .h1.text-secondary,
.bg-sage .h2.text-secondary,
.bg-sage .h3.text-secondary,
.bg-sage .h4.text-secondary,
.bg-sage .h5.text-secondary,
.bg-sage .h6.text-secondary {
  color: #9eabae;
}

.bg-sage p.text-success,
.bg-sage h1.text-success,
.bg-sage h2.text-success,
.bg-sage h3.text-success,
.bg-sage h4.text-success,
.bg-sage h5.text-success,
.bg-sage h6.text-success,
.bg-sage .h1.text-success,
.bg-sage .h2.text-success,
.bg-sage .h3.text-success,
.bg-sage .h4.text-success,
.bg-sage .h5.text-success,
.bg-sage .h6.text-success {
  color: #70b865;
}

.bg-sage p.text-info,
.bg-sage h1.text-info,
.bg-sage h2.text-info,
.bg-sage h3.text-info,
.bg-sage h4.text-info,
.bg-sage h5.text-info,
.bg-sage h6.text-info,
.bg-sage .h1.text-info,
.bg-sage .h2.text-info,
.bg-sage .h3.text-info,
.bg-sage .h4.text-info,
.bg-sage .h5.text-info,
.bg-sage .h6.text-info {
  color: #81d3eb;
}

.bg-sage p.text-warning,
.bg-sage h1.text-warning,
.bg-sage h2.text-warning,
.bg-sage h3.text-warning,
.bg-sage h4.text-warning,
.bg-sage h5.text-warning,
.bg-sage h6.text-warning,
.bg-sage .h1.text-warning,
.bg-sage .h2.text-warning,
.bg-sage .h3.text-warning,
.bg-sage .h4.text-warning,
.bg-sage .h5.text-warning,
.bg-sage .h6.text-warning {
  color: #f19e1f;
}

.bg-sage p.text-danger,
.bg-sage h1.text-danger,
.bg-sage h2.text-danger,
.bg-sage h3.text-danger,
.bg-sage h4.text-danger,
.bg-sage h5.text-danger,
.bg-sage h6.text-danger,
.bg-sage .h1.text-danger,
.bg-sage .h2.text-danger,
.bg-sage .h3.text-danger,
.bg-sage .h4.text-danger,
.bg-sage .h5.text-danger,
.bg-sage .h6.text-danger {
  color: #ef4056;
}

.bg-sage p.text-light,
.bg-sage h1.text-light,
.bg-sage h2.text-light,
.bg-sage h3.text-light,
.bg-sage h4.text-light,
.bg-sage h5.text-light,
.bg-sage h6.text-light,
.bg-sage .h1.text-light,
.bg-sage .h2.text-light,
.bg-sage .h3.text-light,
.bg-sage .h4.text-light,
.bg-sage .h5.text-light,
.bg-sage .h6.text-light {
  color: #dee2e6;
}

.bg-sage p.text-dark,
.bg-sage h1.text-dark,
.bg-sage h2.text-dark,
.bg-sage h3.text-dark,
.bg-sage h4.text-dark,
.bg-sage h5.text-dark,
.bg-sage h6.text-dark,
.bg-sage .h1.text-dark,
.bg-sage .h2.text-dark,
.bg-sage .h3.text-dark,
.bg-sage .h4.text-dark,
.bg-sage .h5.text-dark,
.bg-sage .h6.text-dark {
  color: #343a40;
}

.bg-sage p.text-primary-dark,
.bg-sage h1.text-primary-dark,
.bg-sage h2.text-primary-dark,
.bg-sage h3.text-primary-dark,
.bg-sage h4.text-primary-dark,
.bg-sage h5.text-primary-dark,
.bg-sage h6.text-primary-dark,
.bg-sage .h1.text-primary-dark,
.bg-sage .h2.text-primary-dark,
.bg-sage .h3.text-primary-dark,
.bg-sage .h4.text-primary-dark,
.bg-sage .h5.text-primary-dark,
.bg-sage .h6.text-primary-dark {
  color: #0c234b;
}

.bg-sage p.text-primary-light,
.bg-sage h1.text-primary-light,
.bg-sage h2.text-primary-light,
.bg-sage h3.text-primary-light,
.bg-sage h4.text-primary-light,
.bg-sage h5.text-primary-light,
.bg-sage h6.text-primary-light,
.bg-sage .h1.text-primary-light,
.bg-sage .h2.text-primary-light,
.bg-sage .h3.text-primary-light,
.bg-sage .h4.text-primary-light,
.bg-sage .h5.text-primary-light,
.bg-sage .h6.text-primary-light {
  color: #378dbd;
}

.bg-sage p.text-accent,
.bg-sage h1.text-accent,
.bg-sage h2.text-accent,
.bg-sage h3.text-accent,
.bg-sage h4.text-accent,
.bg-sage h5.text-accent,
.bg-sage h6.text-accent,
.bg-sage .h1.text-accent,
.bg-sage .h2.text-accent,
.bg-sage .h3.text-accent,
.bg-sage .h4.text-accent,
.bg-sage .h5.text-accent,
.bg-sage .h6.text-accent {
  color: #ab0520;
}

.bg-black {
  color: #fff;
  background-color: #000;
  color: #fff;
}

.bg-black p,
.bg-black h1,
.bg-black h2,
.bg-black h3,
.bg-black h4,
.bg-black h5,
.bg-black h6,
.bg-black .h1,
.bg-black .h2,
.bg-black .h3,
.bg-black .h4,
.bg-black .h5,
.bg-black .h6 {
  color: #fff;
}

.bg-black p.text-blue,
.bg-black h1.text-blue,
.bg-black h2.text-blue,
.bg-black h3.text-blue,
.bg-black h4.text-blue,
.bg-black h5.text-blue,
.bg-black h6.text-blue,
.bg-black .h1.text-blue,
.bg-black .h2.text-blue,
.bg-black .h3.text-blue,
.bg-black .h4.text-blue,
.bg-black .h5.text-blue,
.bg-black .h6.text-blue {
  color: #0c234b;
}

.bg-black p.text-indigo,
.bg-black h1.text-indigo,
.bg-black h2.text-indigo,
.bg-black h3.text-indigo,
.bg-black h4.text-indigo,
.bg-black h5.text-indigo,
.bg-black h6.text-indigo,
.bg-black .h1.text-indigo,
.bg-black .h2.text-indigo,
.bg-black .h3.text-indigo,
.bg-black .h4.text-indigo,
.bg-black .h5.text-indigo,
.bg-black .h6.text-indigo {
  color: #6610f2;
}

.bg-black p.text-purple,
.bg-black h1.text-purple,
.bg-black h2.text-purple,
.bg-black h3.text-purple,
.bg-black h4.text-purple,
.bg-black h5.text-purple,
.bg-black h6.text-purple,
.bg-black .h1.text-purple,
.bg-black .h2.text-purple,
.bg-black .h3.text-purple,
.bg-black .h4.text-purple,
.bg-black .h5.text-purple,
.bg-black .h6.text-purple {
  color: #6f42c1;
}

.bg-black p.text-pink,
.bg-black h1.text-pink,
.bg-black h2.text-pink,
.bg-black h3.text-pink,
.bg-black h4.text-pink,
.bg-black h5.text-pink,
.bg-black h6.text-pink,
.bg-black .h1.text-pink,
.bg-black .h2.text-pink,
.bg-black .h3.text-pink,
.bg-black .h4.text-pink,
.bg-black .h5.text-pink,
.bg-black .h6.text-pink {
  color: #e83e8c;
}

.bg-black p.text-red,
.bg-black h1.text-red,
.bg-black h2.text-red,
.bg-black h3.text-red,
.bg-black h4.text-red,
.bg-black h5.text-red,
.bg-black h6.text-red,
.bg-black .h1.text-red,
.bg-black .h2.text-red,
.bg-black .h3.text-red,
.bg-black .h4.text-red,
.bg-black .h5.text-red,
.bg-black .h6.text-red {
  color: #ab0520;
}

.bg-black p.text-orange,
.bg-black h1.text-orange,
.bg-black h2.text-orange,
.bg-black h3.text-orange,
.bg-black h4.text-orange,
.bg-black h5.text-orange,
.bg-black h6.text-orange,
.bg-black .h1.text-orange,
.bg-black .h2.text-orange,
.bg-black .h3.text-orange,
.bg-black .h4.text-orange,
.bg-black .h5.text-orange,
.bg-black .h6.text-orange {
  color: #fd7e14;
}

.bg-black p.text-yellow,
.bg-black h1.text-yellow,
.bg-black h2.text-yellow,
.bg-black h3.text-yellow,
.bg-black h4.text-yellow,
.bg-black h5.text-yellow,
.bg-black h6.text-yellow,
.bg-black .h1.text-yellow,
.bg-black .h2.text-yellow,
.bg-black .h3.text-yellow,
.bg-black .h4.text-yellow,
.bg-black .h5.text-yellow,
.bg-black .h6.text-yellow {
  color: #ffc107;
}

.bg-black p.text-green,
.bg-black h1.text-green,
.bg-black h2.text-green,
.bg-black h3.text-green,
.bg-black h4.text-green,
.bg-black h5.text-green,
.bg-black h6.text-green,
.bg-black .h1.text-green,
.bg-black .h2.text-green,
.bg-black .h3.text-green,
.bg-black .h4.text-green,
.bg-black .h5.text-green,
.bg-black .h6.text-green {
  color: #28a745;
}

.bg-black p.text-teal,
.bg-black h1.text-teal,
.bg-black h2.text-teal,
.bg-black h3.text-teal,
.bg-black h4.text-teal,
.bg-black h5.text-teal,
.bg-black h6.text-teal,
.bg-black .h1.text-teal,
.bg-black .h2.text-teal,
.bg-black .h3.text-teal,
.bg-black .h4.text-teal,
.bg-black .h5.text-teal,
.bg-black .h6.text-teal {
  color: #20c997;
}

.bg-black p.text-cyan,
.bg-black h1.text-cyan,
.bg-black h2.text-cyan,
.bg-black h3.text-cyan,
.bg-black h4.text-cyan,
.bg-black h5.text-cyan,
.bg-black h6.text-cyan,
.bg-black .h1.text-cyan,
.bg-black .h2.text-cyan,
.bg-black .h3.text-cyan,
.bg-black .h4.text-cyan,
.bg-black .h5.text-cyan,
.bg-black .h6.text-cyan {
  color: #17a2b8;
}

.bg-black p.text-white,
.bg-black h1.text-white,
.bg-black h2.text-white,
.bg-black h3.text-white,
.bg-black h4.text-white,
.bg-black h5.text-white,
.bg-black h6.text-white,
.bg-black .h1.text-white,
.bg-black .h2.text-white,
.bg-black .h3.text-white,
.bg-black .h4.text-white,
.bg-black .h5.text-white,
.bg-black .h6.text-white {
  color: #fff;
}

.bg-black p.text-gray,
.bg-black h1.text-gray,
.bg-black h2.text-gray,
.bg-black h3.text-gray,
.bg-black h4.text-gray,
.bg-black h5.text-gray,
.bg-black h6.text-gray,
.bg-black .h1.text-gray,
.bg-black .h2.text-gray,
.bg-black .h3.text-gray,
.bg-black .h4.text-gray,
.bg-black .h5.text-gray,
.bg-black .h6.text-gray {
  color: #6c757d;
}

.bg-black p.text-gray-dark,
.bg-black h1.text-gray-dark,
.bg-black h2.text-gray-dark,
.bg-black h3.text-gray-dark,
.bg-black h4.text-gray-dark,
.bg-black h5.text-gray-dark,
.bg-black h6.text-gray-dark,
.bg-black .h1.text-gray-dark,
.bg-black .h2.text-gray-dark,
.bg-black .h3.text-gray-dark,
.bg-black .h4.text-gray-dark,
.bg-black .h5.text-gray-dark,
.bg-black .h6.text-gray-dark {
  color: #343a40;
}

.bg-black p.text-bloom,
.bg-black h1.text-bloom,
.bg-black h2.text-bloom,
.bg-black h3.text-bloom,
.bg-black h4.text-bloom,
.bg-black h5.text-bloom,
.bg-black h6.text-bloom,
.bg-black .h1.text-bloom,
.bg-black .h2.text-bloom,
.bg-black .h3.text-bloom,
.bg-black .h4.text-bloom,
.bg-black .h5.text-bloom,
.bg-black .h6.text-bloom {
  color: #ef4056;
}

.bg-black p.text-chili,
.bg-black h1.text-chili,
.bg-black h2.text-chili,
.bg-black h3.text-chili,
.bg-black h4.text-chili,
.bg-black h5.text-chili,
.bg-black h6.text-chili,
.bg-black .h1.text-chili,
.bg-black .h2.text-chili,
.bg-black .h3.text-chili,
.bg-black .h4.text-chili,
.bg-black .h5.text-chili,
.bg-black .h6.text-chili {
  color: #8b0015;
}

.bg-black p.text-sky,
.bg-black h1.text-sky,
.bg-black h2.text-sky,
.bg-black h3.text-sky,
.bg-black h4.text-sky,
.bg-black h5.text-sky,
.bg-black h6.text-sky,
.bg-black .h1.text-sky,
.bg-black .h2.text-sky,
.bg-black .h3.text-sky,
.bg-black .h4.text-sky,
.bg-black .h5.text-sky,
.bg-black .h6.text-sky {
  color: #81d3eb;
}

.bg-black p.text-oasis,
.bg-black h1.text-oasis,
.bg-black h2.text-oasis,
.bg-black h3.text-oasis,
.bg-black h4.text-oasis,
.bg-black h5.text-oasis,
.bg-black h6.text-oasis,
.bg-black .h1.text-oasis,
.bg-black .h2.text-oasis,
.bg-black .h3.text-oasis,
.bg-black .h4.text-oasis,
.bg-black .h5.text-oasis,
.bg-black .h6.text-oasis {
  color: #378dbd;
}

.bg-black p.text-azurite,
.bg-black h1.text-azurite,
.bg-black h2.text-azurite,
.bg-black h3.text-azurite,
.bg-black h4.text-azurite,
.bg-black h5.text-azurite,
.bg-black h6.text-azurite,
.bg-black .h1.text-azurite,
.bg-black .h2.text-azurite,
.bg-black .h3.text-azurite,
.bg-black .h4.text-azurite,
.bg-black .h5.text-azurite,
.bg-black .h6.text-azurite {
  color: #1e5288;
}

.bg-black p.text-midnight,
.bg-black h1.text-midnight,
.bg-black h2.text-midnight,
.bg-black h3.text-midnight,
.bg-black h4.text-midnight,
.bg-black h5.text-midnight,
.bg-black h6.text-midnight,
.bg-black .h1.text-midnight,
.bg-black .h2.text-midnight,
.bg-black .h3.text-midnight,
.bg-black .h4.text-midnight,
.bg-black .h5.text-midnight,
.bg-black .h6.text-midnight {
  color: #001c48;
}

.bg-black p.text-cool-gray,
.bg-black h1.text-cool-gray,
.bg-black h2.text-cool-gray,
.bg-black h3.text-cool-gray,
.bg-black h4.text-cool-gray,
.bg-black h5.text-cool-gray,
.bg-black h6.text-cool-gray,
.bg-black .h1.text-cool-gray,
.bg-black .h2.text-cool-gray,
.bg-black .h3.text-cool-gray,
.bg-black .h4.text-cool-gray,
.bg-black .h5.text-cool-gray,
.bg-black .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-black p.text-warm-gray,
.bg-black h1.text-warm-gray,
.bg-black h2.text-warm-gray,
.bg-black h3.text-warm-gray,
.bg-black h4.text-warm-gray,
.bg-black h5.text-warm-gray,
.bg-black h6.text-warm-gray,
.bg-black .h1.text-warm-gray,
.bg-black .h2.text-warm-gray,
.bg-black .h3.text-warm-gray,
.bg-black .h4.text-warm-gray,
.bg-black .h5.text-warm-gray,
.bg-black .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-black p.text-leaf,
.bg-black h1.text-leaf,
.bg-black h2.text-leaf,
.bg-black h3.text-leaf,
.bg-black h4.text-leaf,
.bg-black h5.text-leaf,
.bg-black h6.text-leaf,
.bg-black .h1.text-leaf,
.bg-black .h2.text-leaf,
.bg-black .h3.text-leaf,
.bg-black .h4.text-leaf,
.bg-black .h5.text-leaf,
.bg-black .h6.text-leaf {
  color: #70b865;
}

.bg-black p.text-river,
.bg-black h1.text-river,
.bg-black h2.text-river,
.bg-black h3.text-river,
.bg-black h4.text-river,
.bg-black h5.text-river,
.bg-black h6.text-river,
.bg-black .h1.text-river,
.bg-black .h2.text-river,
.bg-black .h3.text-river,
.bg-black .h4.text-river,
.bg-black .h5.text-river,
.bg-black .h6.text-river {
  color: #007d84;
}

.bg-black p.text-silver,
.bg-black h1.text-silver,
.bg-black h2.text-silver,
.bg-black h3.text-silver,
.bg-black h4.text-silver,
.bg-black h5.text-silver,
.bg-black h6.text-silver,
.bg-black .h1.text-silver,
.bg-black .h2.text-silver,
.bg-black .h3.text-silver,
.bg-black .h4.text-silver,
.bg-black .h5.text-silver,
.bg-black .h6.text-silver {
  color: #9eabae;
}

.bg-black p.text-mesa,
.bg-black h1.text-mesa,
.bg-black h2.text-mesa,
.bg-black h3.text-mesa,
.bg-black h4.text-mesa,
.bg-black h5.text-mesa,
.bg-black h6.text-mesa,
.bg-black .h1.text-mesa,
.bg-black .h2.text-mesa,
.bg-black .h3.text-mesa,
.bg-black .h4.text-mesa,
.bg-black .h5.text-mesa,
.bg-black .h6.text-mesa {
  color: #a95c42;
}

.bg-black p.text-ash,
.bg-black h1.text-ash,
.bg-black h2.text-ash,
.bg-black h3.text-ash,
.bg-black h4.text-ash,
.bg-black h5.text-ash,
.bg-black h6.text-ash,
.bg-black .h1.text-ash,
.bg-black .h2.text-ash,
.bg-black .h3.text-ash,
.bg-black .h4.text-ash,
.bg-black .h5.text-ash,
.bg-black .h6.text-ash {
  color: #403635;
}

.bg-black p.text-sage,
.bg-black h1.text-sage,
.bg-black h2.text-sage,
.bg-black h3.text-sage,
.bg-black h4.text-sage,
.bg-black h5.text-sage,
.bg-black h6.text-sage,
.bg-black .h1.text-sage,
.bg-black .h2.text-sage,
.bg-black .h3.text-sage,
.bg-black .h4.text-sage,
.bg-black .h5.text-sage,
.bg-black .h6.text-sage {
  color: #4a634e;
}

.bg-black p.text-black,
.bg-black h1.text-black,
.bg-black h2.text-black,
.bg-black h3.text-black,
.bg-black h4.text-black,
.bg-black h5.text-black,
.bg-black h6.text-black,
.bg-black .h1.text-black,
.bg-black .h2.text-black,
.bg-black .h3.text-black,
.bg-black .h4.text-black,
.bg-black .h5.text-black,
.bg-black .h6.text-black {
  color: #000;
}

.bg-black p.text-primary,
.bg-black h1.text-primary,
.bg-black h2.text-primary,
.bg-black h3.text-primary,
.bg-black h4.text-primary,
.bg-black h5.text-primary,
.bg-black h6.text-primary,
.bg-black .h1.text-primary,
.bg-black .h2.text-primary,
.bg-black .h3.text-primary,
.bg-black .h4.text-primary,
.bg-black .h5.text-primary,
.bg-black .h6.text-primary {
  color: #1e5288;
}

.bg-black p.text-secondary,
.bg-black h1.text-secondary,
.bg-black h2.text-secondary,
.bg-black h3.text-secondary,
.bg-black h4.text-secondary,
.bg-black h5.text-secondary,
.bg-black h6.text-secondary,
.bg-black .h1.text-secondary,
.bg-black .h2.text-secondary,
.bg-black .h3.text-secondary,
.bg-black .h4.text-secondary,
.bg-black .h5.text-secondary,
.bg-black .h6.text-secondary {
  color: #9eabae;
}

.bg-black p.text-success,
.bg-black h1.text-success,
.bg-black h2.text-success,
.bg-black h3.text-success,
.bg-black h4.text-success,
.bg-black h5.text-success,
.bg-black h6.text-success,
.bg-black .h1.text-success,
.bg-black .h2.text-success,
.bg-black .h3.text-success,
.bg-black .h4.text-success,
.bg-black .h5.text-success,
.bg-black .h6.text-success {
  color: #70b865;
}

.bg-black p.text-info,
.bg-black h1.text-info,
.bg-black h2.text-info,
.bg-black h3.text-info,
.bg-black h4.text-info,
.bg-black h5.text-info,
.bg-black h6.text-info,
.bg-black .h1.text-info,
.bg-black .h2.text-info,
.bg-black .h3.text-info,
.bg-black .h4.text-info,
.bg-black .h5.text-info,
.bg-black .h6.text-info {
  color: #81d3eb;
}

.bg-black p.text-warning,
.bg-black h1.text-warning,
.bg-black h2.text-warning,
.bg-black h3.text-warning,
.bg-black h4.text-warning,
.bg-black h5.text-warning,
.bg-black h6.text-warning,
.bg-black .h1.text-warning,
.bg-black .h2.text-warning,
.bg-black .h3.text-warning,
.bg-black .h4.text-warning,
.bg-black .h5.text-warning,
.bg-black .h6.text-warning {
  color: #f19e1f;
}

.bg-black p.text-danger,
.bg-black h1.text-danger,
.bg-black h2.text-danger,
.bg-black h3.text-danger,
.bg-black h4.text-danger,
.bg-black h5.text-danger,
.bg-black h6.text-danger,
.bg-black .h1.text-danger,
.bg-black .h2.text-danger,
.bg-black .h3.text-danger,
.bg-black .h4.text-danger,
.bg-black .h5.text-danger,
.bg-black .h6.text-danger {
  color: #ef4056;
}

.bg-black p.text-light,
.bg-black h1.text-light,
.bg-black h2.text-light,
.bg-black h3.text-light,
.bg-black h4.text-light,
.bg-black h5.text-light,
.bg-black h6.text-light,
.bg-black .h1.text-light,
.bg-black .h2.text-light,
.bg-black .h3.text-light,
.bg-black .h4.text-light,
.bg-black .h5.text-light,
.bg-black .h6.text-light {
  color: #dee2e6;
}

.bg-black p.text-dark,
.bg-black h1.text-dark,
.bg-black h2.text-dark,
.bg-black h3.text-dark,
.bg-black h4.text-dark,
.bg-black h5.text-dark,
.bg-black h6.text-dark,
.bg-black .h1.text-dark,
.bg-black .h2.text-dark,
.bg-black .h3.text-dark,
.bg-black .h4.text-dark,
.bg-black .h5.text-dark,
.bg-black .h6.text-dark {
  color: #343a40;
}

.bg-black p.text-primary-dark,
.bg-black h1.text-primary-dark,
.bg-black h2.text-primary-dark,
.bg-black h3.text-primary-dark,
.bg-black h4.text-primary-dark,
.bg-black h5.text-primary-dark,
.bg-black h6.text-primary-dark,
.bg-black .h1.text-primary-dark,
.bg-black .h2.text-primary-dark,
.bg-black .h3.text-primary-dark,
.bg-black .h4.text-primary-dark,
.bg-black .h5.text-primary-dark,
.bg-black .h6.text-primary-dark {
  color: #0c234b;
}

.bg-black p.text-primary-light,
.bg-black h1.text-primary-light,
.bg-black h2.text-primary-light,
.bg-black h3.text-primary-light,
.bg-black h4.text-primary-light,
.bg-black h5.text-primary-light,
.bg-black h6.text-primary-light,
.bg-black .h1.text-primary-light,
.bg-black .h2.text-primary-light,
.bg-black .h3.text-primary-light,
.bg-black .h4.text-primary-light,
.bg-black .h5.text-primary-light,
.bg-black .h6.text-primary-light {
  color: #378dbd;
}

.bg-black p.text-accent,
.bg-black h1.text-accent,
.bg-black h2.text-accent,
.bg-black h3.text-accent,
.bg-black h4.text-accent,
.bg-black h5.text-accent,
.bg-black h6.text-accent,
.bg-black .h1.text-accent,
.bg-black .h2.text-accent,
.bg-black .h3.text-accent,
.bg-black .h4.text-accent,
.bg-black .h5.text-accent,
.bg-black .h6.text-accent {
  color: #ab0520;
}

.bg-transparent-red {
  background-color: rgba(171, 5, 32, 0.7);
}

.bg-transparent-bloom {
  background-color: rgba(239, 64, 86, 0.7);
}

.bg-transparent-chili {
  background-color: rgba(139, 0, 21, 0.7);
}

.bg-transparent-blue {
  background-color: rgba(12, 35, 75, 0.7);
}

.bg-transparent-sky {
  background-color: rgba(129, 211, 235, 0.7);
}

.bg-transparent-oasis {
  background-color: rgba(55, 141, 189, 0.7);
}

.bg-transparent-azurite {
  background-color: rgba(30, 82, 136, 0.7);
}

.bg-transparent-midnight {
  background-color: rgba(0, 28, 72, 0.7);
}

.bg-transparent-cool-gray {
  background-color: rgba(226, 233, 235, 0.7);
}

.bg-transparent-warm-gray {
  background-color: rgba(244, 237, 229, 0.7);
}

.bg-transparent-leaf {
  background-color: rgba(112, 184, 101, 0.7);
}

.bg-transparent-river {
  background-color: rgba(0, 125, 132, 0.7);
}

.bg-transparent-silver {
  background-color: rgba(158, 171, 174, 0.7);
}

.bg-transparent-mesa {
  background-color: rgba(169, 92, 66, 0.7);
}

.bg-transparent-ash {
  background-color: rgba(64, 54, 53, 0.7);
}

.bg-transparent-sage {
  background-color: rgba(74, 99, 78, 0.7);
}

.bg-transparent-white {
  background-color: rgba(255, 255, 255, 0.7);
}

.bg-transparent-black {
  background-color: rgba(0, 0, 0, 0.7);
}

.bg-primary {
  color: #fff;
  background-color: #1e5288;
}

.bg-primary p,
.bg-primary h1,
.bg-primary h2,
.bg-primary h3,
.bg-primary h4,
.bg-primary h5,
.bg-primary h6,
.bg-primary .h1,
.bg-primary .h2,
.bg-primary .h3,
.bg-primary .h4,
.bg-primary .h5,
.bg-primary .h6 {
  color: #fff;
}

.bg-primary p.text-blue,
.bg-primary h1.text-blue,
.bg-primary h2.text-blue,
.bg-primary h3.text-blue,
.bg-primary h4.text-blue,
.bg-primary h5.text-blue,
.bg-primary h6.text-blue,
.bg-primary .h1.text-blue,
.bg-primary .h2.text-blue,
.bg-primary .h3.text-blue,
.bg-primary .h4.text-blue,
.bg-primary .h5.text-blue,
.bg-primary .h6.text-blue {
  color: #0c234b;
}

.bg-primary p.text-indigo,
.bg-primary h1.text-indigo,
.bg-primary h2.text-indigo,
.bg-primary h3.text-indigo,
.bg-primary h4.text-indigo,
.bg-primary h5.text-indigo,
.bg-primary h6.text-indigo,
.bg-primary .h1.text-indigo,
.bg-primary .h2.text-indigo,
.bg-primary .h3.text-indigo,
.bg-primary .h4.text-indigo,
.bg-primary .h5.text-indigo,
.bg-primary .h6.text-indigo {
  color: #6610f2;
}

.bg-primary p.text-purple,
.bg-primary h1.text-purple,
.bg-primary h2.text-purple,
.bg-primary h3.text-purple,
.bg-primary h4.text-purple,
.bg-primary h5.text-purple,
.bg-primary h6.text-purple,
.bg-primary .h1.text-purple,
.bg-primary .h2.text-purple,
.bg-primary .h3.text-purple,
.bg-primary .h4.text-purple,
.bg-primary .h5.text-purple,
.bg-primary .h6.text-purple {
  color: #6f42c1;
}

.bg-primary p.text-pink,
.bg-primary h1.text-pink,
.bg-primary h2.text-pink,
.bg-primary h3.text-pink,
.bg-primary h4.text-pink,
.bg-primary h5.text-pink,
.bg-primary h6.text-pink,
.bg-primary .h1.text-pink,
.bg-primary .h2.text-pink,
.bg-primary .h3.text-pink,
.bg-primary .h4.text-pink,
.bg-primary .h5.text-pink,
.bg-primary .h6.text-pink {
  color: #e83e8c;
}

.bg-primary p.text-red,
.bg-primary h1.text-red,
.bg-primary h2.text-red,
.bg-primary h3.text-red,
.bg-primary h4.text-red,
.bg-primary h5.text-red,
.bg-primary h6.text-red,
.bg-primary .h1.text-red,
.bg-primary .h2.text-red,
.bg-primary .h3.text-red,
.bg-primary .h4.text-red,
.bg-primary .h5.text-red,
.bg-primary .h6.text-red {
  color: #ab0520;
}

.bg-primary p.text-orange,
.bg-primary h1.text-orange,
.bg-primary h2.text-orange,
.bg-primary h3.text-orange,
.bg-primary h4.text-orange,
.bg-primary h5.text-orange,
.bg-primary h6.text-orange,
.bg-primary .h1.text-orange,
.bg-primary .h2.text-orange,
.bg-primary .h3.text-orange,
.bg-primary .h4.text-orange,
.bg-primary .h5.text-orange,
.bg-primary .h6.text-orange {
  color: #fd7e14;
}

.bg-primary p.text-yellow,
.bg-primary h1.text-yellow,
.bg-primary h2.text-yellow,
.bg-primary h3.text-yellow,
.bg-primary h4.text-yellow,
.bg-primary h5.text-yellow,
.bg-primary h6.text-yellow,
.bg-primary .h1.text-yellow,
.bg-primary .h2.text-yellow,
.bg-primary .h3.text-yellow,
.bg-primary .h4.text-yellow,
.bg-primary .h5.text-yellow,
.bg-primary .h6.text-yellow {
  color: #ffc107;
}

.bg-primary p.text-green,
.bg-primary h1.text-green,
.bg-primary h2.text-green,
.bg-primary h3.text-green,
.bg-primary h4.text-green,
.bg-primary h5.text-green,
.bg-primary h6.text-green,
.bg-primary .h1.text-green,
.bg-primary .h2.text-green,
.bg-primary .h3.text-green,
.bg-primary .h4.text-green,
.bg-primary .h5.text-green,
.bg-primary .h6.text-green {
  color: #28a745;
}

.bg-primary p.text-teal,
.bg-primary h1.text-teal,
.bg-primary h2.text-teal,
.bg-primary h3.text-teal,
.bg-primary h4.text-teal,
.bg-primary h5.text-teal,
.bg-primary h6.text-teal,
.bg-primary .h1.text-teal,
.bg-primary .h2.text-teal,
.bg-primary .h3.text-teal,
.bg-primary .h4.text-teal,
.bg-primary .h5.text-teal,
.bg-primary .h6.text-teal {
  color: #20c997;
}

.bg-primary p.text-cyan,
.bg-primary h1.text-cyan,
.bg-primary h2.text-cyan,
.bg-primary h3.text-cyan,
.bg-primary h4.text-cyan,
.bg-primary h5.text-cyan,
.bg-primary h6.text-cyan,
.bg-primary .h1.text-cyan,
.bg-primary .h2.text-cyan,
.bg-primary .h3.text-cyan,
.bg-primary .h4.text-cyan,
.bg-primary .h5.text-cyan,
.bg-primary .h6.text-cyan {
  color: #17a2b8;
}

.bg-primary p.text-white,
.bg-primary h1.text-white,
.bg-primary h2.text-white,
.bg-primary h3.text-white,
.bg-primary h4.text-white,
.bg-primary h5.text-white,
.bg-primary h6.text-white,
.bg-primary .h1.text-white,
.bg-primary .h2.text-white,
.bg-primary .h3.text-white,
.bg-primary .h4.text-white,
.bg-primary .h5.text-white,
.bg-primary .h6.text-white {
  color: #fff;
}

.bg-primary p.text-gray,
.bg-primary h1.text-gray,
.bg-primary h2.text-gray,
.bg-primary h3.text-gray,
.bg-primary h4.text-gray,
.bg-primary h5.text-gray,
.bg-primary h6.text-gray,
.bg-primary .h1.text-gray,
.bg-primary .h2.text-gray,
.bg-primary .h3.text-gray,
.bg-primary .h4.text-gray,
.bg-primary .h5.text-gray,
.bg-primary .h6.text-gray {
  color: #6c757d;
}

.bg-primary p.text-gray-dark,
.bg-primary h1.text-gray-dark,
.bg-primary h2.text-gray-dark,
.bg-primary h3.text-gray-dark,
.bg-primary h4.text-gray-dark,
.bg-primary h5.text-gray-dark,
.bg-primary h6.text-gray-dark,
.bg-primary .h1.text-gray-dark,
.bg-primary .h2.text-gray-dark,
.bg-primary .h3.text-gray-dark,
.bg-primary .h4.text-gray-dark,
.bg-primary .h5.text-gray-dark,
.bg-primary .h6.text-gray-dark {
  color: #343a40;
}

.bg-primary p.text-bloom,
.bg-primary h1.text-bloom,
.bg-primary h2.text-bloom,
.bg-primary h3.text-bloom,
.bg-primary h4.text-bloom,
.bg-primary h5.text-bloom,
.bg-primary h6.text-bloom,
.bg-primary .h1.text-bloom,
.bg-primary .h2.text-bloom,
.bg-primary .h3.text-bloom,
.bg-primary .h4.text-bloom,
.bg-primary .h5.text-bloom,
.bg-primary .h6.text-bloom {
  color: #ef4056;
}

.bg-primary p.text-chili,
.bg-primary h1.text-chili,
.bg-primary h2.text-chili,
.bg-primary h3.text-chili,
.bg-primary h4.text-chili,
.bg-primary h5.text-chili,
.bg-primary h6.text-chili,
.bg-primary .h1.text-chili,
.bg-primary .h2.text-chili,
.bg-primary .h3.text-chili,
.bg-primary .h4.text-chili,
.bg-primary .h5.text-chili,
.bg-primary .h6.text-chili {
  color: #8b0015;
}

.bg-primary p.text-sky,
.bg-primary h1.text-sky,
.bg-primary h2.text-sky,
.bg-primary h3.text-sky,
.bg-primary h4.text-sky,
.bg-primary h5.text-sky,
.bg-primary h6.text-sky,
.bg-primary .h1.text-sky,
.bg-primary .h2.text-sky,
.bg-primary .h3.text-sky,
.bg-primary .h4.text-sky,
.bg-primary .h5.text-sky,
.bg-primary .h6.text-sky {
  color: #81d3eb;
}

.bg-primary p.text-oasis,
.bg-primary h1.text-oasis,
.bg-primary h2.text-oasis,
.bg-primary h3.text-oasis,
.bg-primary h4.text-oasis,
.bg-primary h5.text-oasis,
.bg-primary h6.text-oasis,
.bg-primary .h1.text-oasis,
.bg-primary .h2.text-oasis,
.bg-primary .h3.text-oasis,
.bg-primary .h4.text-oasis,
.bg-primary .h5.text-oasis,
.bg-primary .h6.text-oasis {
  color: #378dbd;
}

.bg-primary p.text-azurite,
.bg-primary h1.text-azurite,
.bg-primary h2.text-azurite,
.bg-primary h3.text-azurite,
.bg-primary h4.text-azurite,
.bg-primary h5.text-azurite,
.bg-primary h6.text-azurite,
.bg-primary .h1.text-azurite,
.bg-primary .h2.text-azurite,
.bg-primary .h3.text-azurite,
.bg-primary .h4.text-azurite,
.bg-primary .h5.text-azurite,
.bg-primary .h6.text-azurite {
  color: #1e5288;
}

.bg-primary p.text-midnight,
.bg-primary h1.text-midnight,
.bg-primary h2.text-midnight,
.bg-primary h3.text-midnight,
.bg-primary h4.text-midnight,
.bg-primary h5.text-midnight,
.bg-primary h6.text-midnight,
.bg-primary .h1.text-midnight,
.bg-primary .h2.text-midnight,
.bg-primary .h3.text-midnight,
.bg-primary .h4.text-midnight,
.bg-primary .h5.text-midnight,
.bg-primary .h6.text-midnight {
  color: #001c48;
}

.bg-primary p.text-cool-gray,
.bg-primary h1.text-cool-gray,
.bg-primary h2.text-cool-gray,
.bg-primary h3.text-cool-gray,
.bg-primary h4.text-cool-gray,
.bg-primary h5.text-cool-gray,
.bg-primary h6.text-cool-gray,
.bg-primary .h1.text-cool-gray,
.bg-primary .h2.text-cool-gray,
.bg-primary .h3.text-cool-gray,
.bg-primary .h4.text-cool-gray,
.bg-primary .h5.text-cool-gray,
.bg-primary .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-primary p.text-warm-gray,
.bg-primary h1.text-warm-gray,
.bg-primary h2.text-warm-gray,
.bg-primary h3.text-warm-gray,
.bg-primary h4.text-warm-gray,
.bg-primary h5.text-warm-gray,
.bg-primary h6.text-warm-gray,
.bg-primary .h1.text-warm-gray,
.bg-primary .h2.text-warm-gray,
.bg-primary .h3.text-warm-gray,
.bg-primary .h4.text-warm-gray,
.bg-primary .h5.text-warm-gray,
.bg-primary .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-primary p.text-leaf,
.bg-primary h1.text-leaf,
.bg-primary h2.text-leaf,
.bg-primary h3.text-leaf,
.bg-primary h4.text-leaf,
.bg-primary h5.text-leaf,
.bg-primary h6.text-leaf,
.bg-primary .h1.text-leaf,
.bg-primary .h2.text-leaf,
.bg-primary .h3.text-leaf,
.bg-primary .h4.text-leaf,
.bg-primary .h5.text-leaf,
.bg-primary .h6.text-leaf {
  color: #70b865;
}

.bg-primary p.text-river,
.bg-primary h1.text-river,
.bg-primary h2.text-river,
.bg-primary h3.text-river,
.bg-primary h4.text-river,
.bg-primary h5.text-river,
.bg-primary h6.text-river,
.bg-primary .h1.text-river,
.bg-primary .h2.text-river,
.bg-primary .h3.text-river,
.bg-primary .h4.text-river,
.bg-primary .h5.text-river,
.bg-primary .h6.text-river {
  color: #007d84;
}

.bg-primary p.text-silver,
.bg-primary h1.text-silver,
.bg-primary h2.text-silver,
.bg-primary h3.text-silver,
.bg-primary h4.text-silver,
.bg-primary h5.text-silver,
.bg-primary h6.text-silver,
.bg-primary .h1.text-silver,
.bg-primary .h2.text-silver,
.bg-primary .h3.text-silver,
.bg-primary .h4.text-silver,
.bg-primary .h5.text-silver,
.bg-primary .h6.text-silver {
  color: #9eabae;
}

.bg-primary p.text-mesa,
.bg-primary h1.text-mesa,
.bg-primary h2.text-mesa,
.bg-primary h3.text-mesa,
.bg-primary h4.text-mesa,
.bg-primary h5.text-mesa,
.bg-primary h6.text-mesa,
.bg-primary .h1.text-mesa,
.bg-primary .h2.text-mesa,
.bg-primary .h3.text-mesa,
.bg-primary .h4.text-mesa,
.bg-primary .h5.text-mesa,
.bg-primary .h6.text-mesa {
  color: #a95c42;
}

.bg-primary p.text-ash,
.bg-primary h1.text-ash,
.bg-primary h2.text-ash,
.bg-primary h3.text-ash,
.bg-primary h4.text-ash,
.bg-primary h5.text-ash,
.bg-primary h6.text-ash,
.bg-primary .h1.text-ash,
.bg-primary .h2.text-ash,
.bg-primary .h3.text-ash,
.bg-primary .h4.text-ash,
.bg-primary .h5.text-ash,
.bg-primary .h6.text-ash {
  color: #403635;
}

.bg-primary p.text-sage,
.bg-primary h1.text-sage,
.bg-primary h2.text-sage,
.bg-primary h3.text-sage,
.bg-primary h4.text-sage,
.bg-primary h5.text-sage,
.bg-primary h6.text-sage,
.bg-primary .h1.text-sage,
.bg-primary .h2.text-sage,
.bg-primary .h3.text-sage,
.bg-primary .h4.text-sage,
.bg-primary .h5.text-sage,
.bg-primary .h6.text-sage {
  color: #4a634e;
}

.bg-primary p.text-black,
.bg-primary h1.text-black,
.bg-primary h2.text-black,
.bg-primary h3.text-black,
.bg-primary h4.text-black,
.bg-primary h5.text-black,
.bg-primary h6.text-black,
.bg-primary .h1.text-black,
.bg-primary .h2.text-black,
.bg-primary .h3.text-black,
.bg-primary .h4.text-black,
.bg-primary .h5.text-black,
.bg-primary .h6.text-black {
  color: #000;
}

.bg-primary p.text-primary,
.bg-primary h1.text-primary,
.bg-primary h2.text-primary,
.bg-primary h3.text-primary,
.bg-primary h4.text-primary,
.bg-primary h5.text-primary,
.bg-primary h6.text-primary,
.bg-primary .h1.text-primary,
.bg-primary .h2.text-primary,
.bg-primary .h3.text-primary,
.bg-primary .h4.text-primary,
.bg-primary .h5.text-primary,
.bg-primary .h6.text-primary {
  color: #1e5288;
}

.bg-primary p.text-secondary,
.bg-primary h1.text-secondary,
.bg-primary h2.text-secondary,
.bg-primary h3.text-secondary,
.bg-primary h4.text-secondary,
.bg-primary h5.text-secondary,
.bg-primary h6.text-secondary,
.bg-primary .h1.text-secondary,
.bg-primary .h2.text-secondary,
.bg-primary .h3.text-secondary,
.bg-primary .h4.text-secondary,
.bg-primary .h5.text-secondary,
.bg-primary .h6.text-secondary {
  color: #9eabae;
}

.bg-primary p.text-success,
.bg-primary h1.text-success,
.bg-primary h2.text-success,
.bg-primary h3.text-success,
.bg-primary h4.text-success,
.bg-primary h5.text-success,
.bg-primary h6.text-success,
.bg-primary .h1.text-success,
.bg-primary .h2.text-success,
.bg-primary .h3.text-success,
.bg-primary .h4.text-success,
.bg-primary .h5.text-success,
.bg-primary .h6.text-success {
  color: #70b865;
}

.bg-primary p.text-info,
.bg-primary h1.text-info,
.bg-primary h2.text-info,
.bg-primary h3.text-info,
.bg-primary h4.text-info,
.bg-primary h5.text-info,
.bg-primary h6.text-info,
.bg-primary .h1.text-info,
.bg-primary .h2.text-info,
.bg-primary .h3.text-info,
.bg-primary .h4.text-info,
.bg-primary .h5.text-info,
.bg-primary .h6.text-info {
  color: #81d3eb;
}

.bg-primary p.text-warning,
.bg-primary h1.text-warning,
.bg-primary h2.text-warning,
.bg-primary h3.text-warning,
.bg-primary h4.text-warning,
.bg-primary h5.text-warning,
.bg-primary h6.text-warning,
.bg-primary .h1.text-warning,
.bg-primary .h2.text-warning,
.bg-primary .h3.text-warning,
.bg-primary .h4.text-warning,
.bg-primary .h5.text-warning,
.bg-primary .h6.text-warning {
  color: #f19e1f;
}

.bg-primary p.text-danger,
.bg-primary h1.text-danger,
.bg-primary h2.text-danger,
.bg-primary h3.text-danger,
.bg-primary h4.text-danger,
.bg-primary h5.text-danger,
.bg-primary h6.text-danger,
.bg-primary .h1.text-danger,
.bg-primary .h2.text-danger,
.bg-primary .h3.text-danger,
.bg-primary .h4.text-danger,
.bg-primary .h5.text-danger,
.bg-primary .h6.text-danger {
  color: #ef4056;
}

.bg-primary p.text-light,
.bg-primary h1.text-light,
.bg-primary h2.text-light,
.bg-primary h3.text-light,
.bg-primary h4.text-light,
.bg-primary h5.text-light,
.bg-primary h6.text-light,
.bg-primary .h1.text-light,
.bg-primary .h2.text-light,
.bg-primary .h3.text-light,
.bg-primary .h4.text-light,
.bg-primary .h5.text-light,
.bg-primary .h6.text-light {
  color: #dee2e6;
}

.bg-primary p.text-dark,
.bg-primary h1.text-dark,
.bg-primary h2.text-dark,
.bg-primary h3.text-dark,
.bg-primary h4.text-dark,
.bg-primary h5.text-dark,
.bg-primary h6.text-dark,
.bg-primary .h1.text-dark,
.bg-primary .h2.text-dark,
.bg-primary .h3.text-dark,
.bg-primary .h4.text-dark,
.bg-primary .h5.text-dark,
.bg-primary .h6.text-dark {
  color: #343a40;
}

.bg-primary p.text-primary-dark,
.bg-primary h1.text-primary-dark,
.bg-primary h2.text-primary-dark,
.bg-primary h3.text-primary-dark,
.bg-primary h4.text-primary-dark,
.bg-primary h5.text-primary-dark,
.bg-primary h6.text-primary-dark,
.bg-primary .h1.text-primary-dark,
.bg-primary .h2.text-primary-dark,
.bg-primary .h3.text-primary-dark,
.bg-primary .h4.text-primary-dark,
.bg-primary .h5.text-primary-dark,
.bg-primary .h6.text-primary-dark {
  color: #0c234b;
}

.bg-primary p.text-primary-light,
.bg-primary h1.text-primary-light,
.bg-primary h2.text-primary-light,
.bg-primary h3.text-primary-light,
.bg-primary h4.text-primary-light,
.bg-primary h5.text-primary-light,
.bg-primary h6.text-primary-light,
.bg-primary .h1.text-primary-light,
.bg-primary .h2.text-primary-light,
.bg-primary .h3.text-primary-light,
.bg-primary .h4.text-primary-light,
.bg-primary .h5.text-primary-light,
.bg-primary .h6.text-primary-light {
  color: #378dbd;
}

.bg-primary p.text-accent,
.bg-primary h1.text-accent,
.bg-primary h2.text-accent,
.bg-primary h3.text-accent,
.bg-primary h4.text-accent,
.bg-primary h5.text-accent,
.bg-primary h6.text-accent,
.bg-primary .h1.text-accent,
.bg-primary .h2.text-accent,
.bg-primary .h3.text-accent,
.bg-primary .h4.text-accent,
.bg-primary .h5.text-accent,
.bg-primary .h6.text-accent {
  color: #ab0520;
}

.bg-secondary {
  color: #001c48;
  background-color: #9eabae;
}

.bg-secondary p,
.bg-secondary h1,
.bg-secondary h2,
.bg-secondary h3,
.bg-secondary h4,
.bg-secondary h5,
.bg-secondary h6,
.bg-secondary .h1,
.bg-secondary .h2,
.bg-secondary .h3,
.bg-secondary .h4,
.bg-secondary .h5,
.bg-secondary .h6 {
  color: #001c48;
}

.bg-secondary p.text-blue,
.bg-secondary h1.text-blue,
.bg-secondary h2.text-blue,
.bg-secondary h3.text-blue,
.bg-secondary h4.text-blue,
.bg-secondary h5.text-blue,
.bg-secondary h6.text-blue,
.bg-secondary .h1.text-blue,
.bg-secondary .h2.text-blue,
.bg-secondary .h3.text-blue,
.bg-secondary .h4.text-blue,
.bg-secondary .h5.text-blue,
.bg-secondary .h6.text-blue {
  color: #0c234b;
}

.bg-secondary p.text-indigo,
.bg-secondary h1.text-indigo,
.bg-secondary h2.text-indigo,
.bg-secondary h3.text-indigo,
.bg-secondary h4.text-indigo,
.bg-secondary h5.text-indigo,
.bg-secondary h6.text-indigo,
.bg-secondary .h1.text-indigo,
.bg-secondary .h2.text-indigo,
.bg-secondary .h3.text-indigo,
.bg-secondary .h4.text-indigo,
.bg-secondary .h5.text-indigo,
.bg-secondary .h6.text-indigo {
  color: #6610f2;
}

.bg-secondary p.text-purple,
.bg-secondary h1.text-purple,
.bg-secondary h2.text-purple,
.bg-secondary h3.text-purple,
.bg-secondary h4.text-purple,
.bg-secondary h5.text-purple,
.bg-secondary h6.text-purple,
.bg-secondary .h1.text-purple,
.bg-secondary .h2.text-purple,
.bg-secondary .h3.text-purple,
.bg-secondary .h4.text-purple,
.bg-secondary .h5.text-purple,
.bg-secondary .h6.text-purple {
  color: #6f42c1;
}

.bg-secondary p.text-pink,
.bg-secondary h1.text-pink,
.bg-secondary h2.text-pink,
.bg-secondary h3.text-pink,
.bg-secondary h4.text-pink,
.bg-secondary h5.text-pink,
.bg-secondary h6.text-pink,
.bg-secondary .h1.text-pink,
.bg-secondary .h2.text-pink,
.bg-secondary .h3.text-pink,
.bg-secondary .h4.text-pink,
.bg-secondary .h5.text-pink,
.bg-secondary .h6.text-pink {
  color: #e83e8c;
}

.bg-secondary p.text-red,
.bg-secondary h1.text-red,
.bg-secondary h2.text-red,
.bg-secondary h3.text-red,
.bg-secondary h4.text-red,
.bg-secondary h5.text-red,
.bg-secondary h6.text-red,
.bg-secondary .h1.text-red,
.bg-secondary .h2.text-red,
.bg-secondary .h3.text-red,
.bg-secondary .h4.text-red,
.bg-secondary .h5.text-red,
.bg-secondary .h6.text-red {
  color: #ab0520;
}

.bg-secondary p.text-orange,
.bg-secondary h1.text-orange,
.bg-secondary h2.text-orange,
.bg-secondary h3.text-orange,
.bg-secondary h4.text-orange,
.bg-secondary h5.text-orange,
.bg-secondary h6.text-orange,
.bg-secondary .h1.text-orange,
.bg-secondary .h2.text-orange,
.bg-secondary .h3.text-orange,
.bg-secondary .h4.text-orange,
.bg-secondary .h5.text-orange,
.bg-secondary .h6.text-orange {
  color: #fd7e14;
}

.bg-secondary p.text-yellow,
.bg-secondary h1.text-yellow,
.bg-secondary h2.text-yellow,
.bg-secondary h3.text-yellow,
.bg-secondary h4.text-yellow,
.bg-secondary h5.text-yellow,
.bg-secondary h6.text-yellow,
.bg-secondary .h1.text-yellow,
.bg-secondary .h2.text-yellow,
.bg-secondary .h3.text-yellow,
.bg-secondary .h4.text-yellow,
.bg-secondary .h5.text-yellow,
.bg-secondary .h6.text-yellow {
  color: #ffc107;
}

.bg-secondary p.text-green,
.bg-secondary h1.text-green,
.bg-secondary h2.text-green,
.bg-secondary h3.text-green,
.bg-secondary h4.text-green,
.bg-secondary h5.text-green,
.bg-secondary h6.text-green,
.bg-secondary .h1.text-green,
.bg-secondary .h2.text-green,
.bg-secondary .h3.text-green,
.bg-secondary .h4.text-green,
.bg-secondary .h5.text-green,
.bg-secondary .h6.text-green {
  color: #28a745;
}

.bg-secondary p.text-teal,
.bg-secondary h1.text-teal,
.bg-secondary h2.text-teal,
.bg-secondary h3.text-teal,
.bg-secondary h4.text-teal,
.bg-secondary h5.text-teal,
.bg-secondary h6.text-teal,
.bg-secondary .h1.text-teal,
.bg-secondary .h2.text-teal,
.bg-secondary .h3.text-teal,
.bg-secondary .h4.text-teal,
.bg-secondary .h5.text-teal,
.bg-secondary .h6.text-teal {
  color: #20c997;
}

.bg-secondary p.text-cyan,
.bg-secondary h1.text-cyan,
.bg-secondary h2.text-cyan,
.bg-secondary h3.text-cyan,
.bg-secondary h4.text-cyan,
.bg-secondary h5.text-cyan,
.bg-secondary h6.text-cyan,
.bg-secondary .h1.text-cyan,
.bg-secondary .h2.text-cyan,
.bg-secondary .h3.text-cyan,
.bg-secondary .h4.text-cyan,
.bg-secondary .h5.text-cyan,
.bg-secondary .h6.text-cyan {
  color: #17a2b8;
}

.bg-secondary p.text-white,
.bg-secondary h1.text-white,
.bg-secondary h2.text-white,
.bg-secondary h3.text-white,
.bg-secondary h4.text-white,
.bg-secondary h5.text-white,
.bg-secondary h6.text-white,
.bg-secondary .h1.text-white,
.bg-secondary .h2.text-white,
.bg-secondary .h3.text-white,
.bg-secondary .h4.text-white,
.bg-secondary .h5.text-white,
.bg-secondary .h6.text-white {
  color: #fff;
}

.bg-secondary p.text-gray,
.bg-secondary h1.text-gray,
.bg-secondary h2.text-gray,
.bg-secondary h3.text-gray,
.bg-secondary h4.text-gray,
.bg-secondary h5.text-gray,
.bg-secondary h6.text-gray,
.bg-secondary .h1.text-gray,
.bg-secondary .h2.text-gray,
.bg-secondary .h3.text-gray,
.bg-secondary .h4.text-gray,
.bg-secondary .h5.text-gray,
.bg-secondary .h6.text-gray {
  color: #6c757d;
}

.bg-secondary p.text-gray-dark,
.bg-secondary h1.text-gray-dark,
.bg-secondary h2.text-gray-dark,
.bg-secondary h3.text-gray-dark,
.bg-secondary h4.text-gray-dark,
.bg-secondary h5.text-gray-dark,
.bg-secondary h6.text-gray-dark,
.bg-secondary .h1.text-gray-dark,
.bg-secondary .h2.text-gray-dark,
.bg-secondary .h3.text-gray-dark,
.bg-secondary .h4.text-gray-dark,
.bg-secondary .h5.text-gray-dark,
.bg-secondary .h6.text-gray-dark {
  color: #343a40;
}

.bg-secondary p.text-bloom,
.bg-secondary h1.text-bloom,
.bg-secondary h2.text-bloom,
.bg-secondary h3.text-bloom,
.bg-secondary h4.text-bloom,
.bg-secondary h5.text-bloom,
.bg-secondary h6.text-bloom,
.bg-secondary .h1.text-bloom,
.bg-secondary .h2.text-bloom,
.bg-secondary .h3.text-bloom,
.bg-secondary .h4.text-bloom,
.bg-secondary .h5.text-bloom,
.bg-secondary .h6.text-bloom {
  color: #ef4056;
}

.bg-secondary p.text-chili,
.bg-secondary h1.text-chili,
.bg-secondary h2.text-chili,
.bg-secondary h3.text-chili,
.bg-secondary h4.text-chili,
.bg-secondary h5.text-chili,
.bg-secondary h6.text-chili,
.bg-secondary .h1.text-chili,
.bg-secondary .h2.text-chili,
.bg-secondary .h3.text-chili,
.bg-secondary .h4.text-chili,
.bg-secondary .h5.text-chili,
.bg-secondary .h6.text-chili {
  color: #8b0015;
}

.bg-secondary p.text-sky,
.bg-secondary h1.text-sky,
.bg-secondary h2.text-sky,
.bg-secondary h3.text-sky,
.bg-secondary h4.text-sky,
.bg-secondary h5.text-sky,
.bg-secondary h6.text-sky,
.bg-secondary .h1.text-sky,
.bg-secondary .h2.text-sky,
.bg-secondary .h3.text-sky,
.bg-secondary .h4.text-sky,
.bg-secondary .h5.text-sky,
.bg-secondary .h6.text-sky {
  color: #81d3eb;
}

.bg-secondary p.text-oasis,
.bg-secondary h1.text-oasis,
.bg-secondary h2.text-oasis,
.bg-secondary h3.text-oasis,
.bg-secondary h4.text-oasis,
.bg-secondary h5.text-oasis,
.bg-secondary h6.text-oasis,
.bg-secondary .h1.text-oasis,
.bg-secondary .h2.text-oasis,
.bg-secondary .h3.text-oasis,
.bg-secondary .h4.text-oasis,
.bg-secondary .h5.text-oasis,
.bg-secondary .h6.text-oasis {
  color: #378dbd;
}

.bg-secondary p.text-azurite,
.bg-secondary h1.text-azurite,
.bg-secondary h2.text-azurite,
.bg-secondary h3.text-azurite,
.bg-secondary h4.text-azurite,
.bg-secondary h5.text-azurite,
.bg-secondary h6.text-azurite,
.bg-secondary .h1.text-azurite,
.bg-secondary .h2.text-azurite,
.bg-secondary .h3.text-azurite,
.bg-secondary .h4.text-azurite,
.bg-secondary .h5.text-azurite,
.bg-secondary .h6.text-azurite {
  color: #1e5288;
}

.bg-secondary p.text-midnight,
.bg-secondary h1.text-midnight,
.bg-secondary h2.text-midnight,
.bg-secondary h3.text-midnight,
.bg-secondary h4.text-midnight,
.bg-secondary h5.text-midnight,
.bg-secondary h6.text-midnight,
.bg-secondary .h1.text-midnight,
.bg-secondary .h2.text-midnight,
.bg-secondary .h3.text-midnight,
.bg-secondary .h4.text-midnight,
.bg-secondary .h5.text-midnight,
.bg-secondary .h6.text-midnight {
  color: #001c48;
}

.bg-secondary p.text-cool-gray,
.bg-secondary h1.text-cool-gray,
.bg-secondary h2.text-cool-gray,
.bg-secondary h3.text-cool-gray,
.bg-secondary h4.text-cool-gray,
.bg-secondary h5.text-cool-gray,
.bg-secondary h6.text-cool-gray,
.bg-secondary .h1.text-cool-gray,
.bg-secondary .h2.text-cool-gray,
.bg-secondary .h3.text-cool-gray,
.bg-secondary .h4.text-cool-gray,
.bg-secondary .h5.text-cool-gray,
.bg-secondary .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-secondary p.text-warm-gray,
.bg-secondary h1.text-warm-gray,
.bg-secondary h2.text-warm-gray,
.bg-secondary h3.text-warm-gray,
.bg-secondary h4.text-warm-gray,
.bg-secondary h5.text-warm-gray,
.bg-secondary h6.text-warm-gray,
.bg-secondary .h1.text-warm-gray,
.bg-secondary .h2.text-warm-gray,
.bg-secondary .h3.text-warm-gray,
.bg-secondary .h4.text-warm-gray,
.bg-secondary .h5.text-warm-gray,
.bg-secondary .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-secondary p.text-leaf,
.bg-secondary h1.text-leaf,
.bg-secondary h2.text-leaf,
.bg-secondary h3.text-leaf,
.bg-secondary h4.text-leaf,
.bg-secondary h5.text-leaf,
.bg-secondary h6.text-leaf,
.bg-secondary .h1.text-leaf,
.bg-secondary .h2.text-leaf,
.bg-secondary .h3.text-leaf,
.bg-secondary .h4.text-leaf,
.bg-secondary .h5.text-leaf,
.bg-secondary .h6.text-leaf {
  color: #70b865;
}

.bg-secondary p.text-river,
.bg-secondary h1.text-river,
.bg-secondary h2.text-river,
.bg-secondary h3.text-river,
.bg-secondary h4.text-river,
.bg-secondary h5.text-river,
.bg-secondary h6.text-river,
.bg-secondary .h1.text-river,
.bg-secondary .h2.text-river,
.bg-secondary .h3.text-river,
.bg-secondary .h4.text-river,
.bg-secondary .h5.text-river,
.bg-secondary .h6.text-river {
  color: #007d84;
}

.bg-secondary p.text-silver,
.bg-secondary h1.text-silver,
.bg-secondary h2.text-silver,
.bg-secondary h3.text-silver,
.bg-secondary h4.text-silver,
.bg-secondary h5.text-silver,
.bg-secondary h6.text-silver,
.bg-secondary .h1.text-silver,
.bg-secondary .h2.text-silver,
.bg-secondary .h3.text-silver,
.bg-secondary .h4.text-silver,
.bg-secondary .h5.text-silver,
.bg-secondary .h6.text-silver {
  color: #9eabae;
}

.bg-secondary p.text-mesa,
.bg-secondary h1.text-mesa,
.bg-secondary h2.text-mesa,
.bg-secondary h3.text-mesa,
.bg-secondary h4.text-mesa,
.bg-secondary h5.text-mesa,
.bg-secondary h6.text-mesa,
.bg-secondary .h1.text-mesa,
.bg-secondary .h2.text-mesa,
.bg-secondary .h3.text-mesa,
.bg-secondary .h4.text-mesa,
.bg-secondary .h5.text-mesa,
.bg-secondary .h6.text-mesa {
  color: #a95c42;
}

.bg-secondary p.text-ash,
.bg-secondary h1.text-ash,
.bg-secondary h2.text-ash,
.bg-secondary h3.text-ash,
.bg-secondary h4.text-ash,
.bg-secondary h5.text-ash,
.bg-secondary h6.text-ash,
.bg-secondary .h1.text-ash,
.bg-secondary .h2.text-ash,
.bg-secondary .h3.text-ash,
.bg-secondary .h4.text-ash,
.bg-secondary .h5.text-ash,
.bg-secondary .h6.text-ash {
  color: #403635;
}

.bg-secondary p.text-sage,
.bg-secondary h1.text-sage,
.bg-secondary h2.text-sage,
.bg-secondary h3.text-sage,
.bg-secondary h4.text-sage,
.bg-secondary h5.text-sage,
.bg-secondary h6.text-sage,
.bg-secondary .h1.text-sage,
.bg-secondary .h2.text-sage,
.bg-secondary .h3.text-sage,
.bg-secondary .h4.text-sage,
.bg-secondary .h5.text-sage,
.bg-secondary .h6.text-sage {
  color: #4a634e;
}

.bg-secondary p.text-black,
.bg-secondary h1.text-black,
.bg-secondary h2.text-black,
.bg-secondary h3.text-black,
.bg-secondary h4.text-black,
.bg-secondary h5.text-black,
.bg-secondary h6.text-black,
.bg-secondary .h1.text-black,
.bg-secondary .h2.text-black,
.bg-secondary .h3.text-black,
.bg-secondary .h4.text-black,
.bg-secondary .h5.text-black,
.bg-secondary .h6.text-black {
  color: #000;
}

.bg-secondary p.text-primary,
.bg-secondary h1.text-primary,
.bg-secondary h2.text-primary,
.bg-secondary h3.text-primary,
.bg-secondary h4.text-primary,
.bg-secondary h5.text-primary,
.bg-secondary h6.text-primary,
.bg-secondary .h1.text-primary,
.bg-secondary .h2.text-primary,
.bg-secondary .h3.text-primary,
.bg-secondary .h4.text-primary,
.bg-secondary .h5.text-primary,
.bg-secondary .h6.text-primary {
  color: #1e5288;
}

.bg-secondary p.text-secondary,
.bg-secondary h1.text-secondary,
.bg-secondary h2.text-secondary,
.bg-secondary h3.text-secondary,
.bg-secondary h4.text-secondary,
.bg-secondary h5.text-secondary,
.bg-secondary h6.text-secondary,
.bg-secondary .h1.text-secondary,
.bg-secondary .h2.text-secondary,
.bg-secondary .h3.text-secondary,
.bg-secondary .h4.text-secondary,
.bg-secondary .h5.text-secondary,
.bg-secondary .h6.text-secondary {
  color: #9eabae;
}

.bg-secondary p.text-success,
.bg-secondary h1.text-success,
.bg-secondary h2.text-success,
.bg-secondary h3.text-success,
.bg-secondary h4.text-success,
.bg-secondary h5.text-success,
.bg-secondary h6.text-success,
.bg-secondary .h1.text-success,
.bg-secondary .h2.text-success,
.bg-secondary .h3.text-success,
.bg-secondary .h4.text-success,
.bg-secondary .h5.text-success,
.bg-secondary .h6.text-success {
  color: #70b865;
}

.bg-secondary p.text-info,
.bg-secondary h1.text-info,
.bg-secondary h2.text-info,
.bg-secondary h3.text-info,
.bg-secondary h4.text-info,
.bg-secondary h5.text-info,
.bg-secondary h6.text-info,
.bg-secondary .h1.text-info,
.bg-secondary .h2.text-info,
.bg-secondary .h3.text-info,
.bg-secondary .h4.text-info,
.bg-secondary .h5.text-info,
.bg-secondary .h6.text-info {
  color: #81d3eb;
}

.bg-secondary p.text-warning,
.bg-secondary h1.text-warning,
.bg-secondary h2.text-warning,
.bg-secondary h3.text-warning,
.bg-secondary h4.text-warning,
.bg-secondary h5.text-warning,
.bg-secondary h6.text-warning,
.bg-secondary .h1.text-warning,
.bg-secondary .h2.text-warning,
.bg-secondary .h3.text-warning,
.bg-secondary .h4.text-warning,
.bg-secondary .h5.text-warning,
.bg-secondary .h6.text-warning {
  color: #f19e1f;
}

.bg-secondary p.text-danger,
.bg-secondary h1.text-danger,
.bg-secondary h2.text-danger,
.bg-secondary h3.text-danger,
.bg-secondary h4.text-danger,
.bg-secondary h5.text-danger,
.bg-secondary h6.text-danger,
.bg-secondary .h1.text-danger,
.bg-secondary .h2.text-danger,
.bg-secondary .h3.text-danger,
.bg-secondary .h4.text-danger,
.bg-secondary .h5.text-danger,
.bg-secondary .h6.text-danger {
  color: #ef4056;
}

.bg-secondary p.text-light,
.bg-secondary h1.text-light,
.bg-secondary h2.text-light,
.bg-secondary h3.text-light,
.bg-secondary h4.text-light,
.bg-secondary h5.text-light,
.bg-secondary h6.text-light,
.bg-secondary .h1.text-light,
.bg-secondary .h2.text-light,
.bg-secondary .h3.text-light,
.bg-secondary .h4.text-light,
.bg-secondary .h5.text-light,
.bg-secondary .h6.text-light {
  color: #dee2e6;
}

.bg-secondary p.text-dark,
.bg-secondary h1.text-dark,
.bg-secondary h2.text-dark,
.bg-secondary h3.text-dark,
.bg-secondary h4.text-dark,
.bg-secondary h5.text-dark,
.bg-secondary h6.text-dark,
.bg-secondary .h1.text-dark,
.bg-secondary .h2.text-dark,
.bg-secondary .h3.text-dark,
.bg-secondary .h4.text-dark,
.bg-secondary .h5.text-dark,
.bg-secondary .h6.text-dark {
  color: #343a40;
}

.bg-secondary p.text-primary-dark,
.bg-secondary h1.text-primary-dark,
.bg-secondary h2.text-primary-dark,
.bg-secondary h3.text-primary-dark,
.bg-secondary h4.text-primary-dark,
.bg-secondary h5.text-primary-dark,
.bg-secondary h6.text-primary-dark,
.bg-secondary .h1.text-primary-dark,
.bg-secondary .h2.text-primary-dark,
.bg-secondary .h3.text-primary-dark,
.bg-secondary .h4.text-primary-dark,
.bg-secondary .h5.text-primary-dark,
.bg-secondary .h6.text-primary-dark {
  color: #0c234b;
}

.bg-secondary p.text-primary-light,
.bg-secondary h1.text-primary-light,
.bg-secondary h2.text-primary-light,
.bg-secondary h3.text-primary-light,
.bg-secondary h4.text-primary-light,
.bg-secondary h5.text-primary-light,
.bg-secondary h6.text-primary-light,
.bg-secondary .h1.text-primary-light,
.bg-secondary .h2.text-primary-light,
.bg-secondary .h3.text-primary-light,
.bg-secondary .h4.text-primary-light,
.bg-secondary .h5.text-primary-light,
.bg-secondary .h6.text-primary-light {
  color: #378dbd;
}

.bg-secondary p.text-accent,
.bg-secondary h1.text-accent,
.bg-secondary h2.text-accent,
.bg-secondary h3.text-accent,
.bg-secondary h4.text-accent,
.bg-secondary h5.text-accent,
.bg-secondary h6.text-accent,
.bg-secondary .h1.text-accent,
.bg-secondary .h2.text-accent,
.bg-secondary .h3.text-accent,
.bg-secondary .h4.text-accent,
.bg-secondary .h5.text-accent,
.bg-secondary .h6.text-accent {
  color: #ab0520;
}

.bg-success {
  color: #001c48;
  background-color: #70b865;
}

.bg-success p,
.bg-success h1,
.bg-success h2,
.bg-success h3,
.bg-success h4,
.bg-success h5,
.bg-success h6,
.bg-success .h1,
.bg-success .h2,
.bg-success .h3,
.bg-success .h4,
.bg-success .h5,
.bg-success .h6 {
  color: #001c48;
}

.bg-success p.text-blue,
.bg-success h1.text-blue,
.bg-success h2.text-blue,
.bg-success h3.text-blue,
.bg-success h4.text-blue,
.bg-success h5.text-blue,
.bg-success h6.text-blue,
.bg-success .h1.text-blue,
.bg-success .h2.text-blue,
.bg-success .h3.text-blue,
.bg-success .h4.text-blue,
.bg-success .h5.text-blue,
.bg-success .h6.text-blue {
  color: #0c234b;
}

.bg-success p.text-indigo,
.bg-success h1.text-indigo,
.bg-success h2.text-indigo,
.bg-success h3.text-indigo,
.bg-success h4.text-indigo,
.bg-success h5.text-indigo,
.bg-success h6.text-indigo,
.bg-success .h1.text-indigo,
.bg-success .h2.text-indigo,
.bg-success .h3.text-indigo,
.bg-success .h4.text-indigo,
.bg-success .h5.text-indigo,
.bg-success .h6.text-indigo {
  color: #6610f2;
}

.bg-success p.text-purple,
.bg-success h1.text-purple,
.bg-success h2.text-purple,
.bg-success h3.text-purple,
.bg-success h4.text-purple,
.bg-success h5.text-purple,
.bg-success h6.text-purple,
.bg-success .h1.text-purple,
.bg-success .h2.text-purple,
.bg-success .h3.text-purple,
.bg-success .h4.text-purple,
.bg-success .h5.text-purple,
.bg-success .h6.text-purple {
  color: #6f42c1;
}

.bg-success p.text-pink,
.bg-success h1.text-pink,
.bg-success h2.text-pink,
.bg-success h3.text-pink,
.bg-success h4.text-pink,
.bg-success h5.text-pink,
.bg-success h6.text-pink,
.bg-success .h1.text-pink,
.bg-success .h2.text-pink,
.bg-success .h3.text-pink,
.bg-success .h4.text-pink,
.bg-success .h5.text-pink,
.bg-success .h6.text-pink {
  color: #e83e8c;
}

.bg-success p.text-red,
.bg-success h1.text-red,
.bg-success h2.text-red,
.bg-success h3.text-red,
.bg-success h4.text-red,
.bg-success h5.text-red,
.bg-success h6.text-red,
.bg-success .h1.text-red,
.bg-success .h2.text-red,
.bg-success .h3.text-red,
.bg-success .h4.text-red,
.bg-success .h5.text-red,
.bg-success .h6.text-red {
  color: #ab0520;
}

.bg-success p.text-orange,
.bg-success h1.text-orange,
.bg-success h2.text-orange,
.bg-success h3.text-orange,
.bg-success h4.text-orange,
.bg-success h5.text-orange,
.bg-success h6.text-orange,
.bg-success .h1.text-orange,
.bg-success .h2.text-orange,
.bg-success .h3.text-orange,
.bg-success .h4.text-orange,
.bg-success .h5.text-orange,
.bg-success .h6.text-orange {
  color: #fd7e14;
}

.bg-success p.text-yellow,
.bg-success h1.text-yellow,
.bg-success h2.text-yellow,
.bg-success h3.text-yellow,
.bg-success h4.text-yellow,
.bg-success h5.text-yellow,
.bg-success h6.text-yellow,
.bg-success .h1.text-yellow,
.bg-success .h2.text-yellow,
.bg-success .h3.text-yellow,
.bg-success .h4.text-yellow,
.bg-success .h5.text-yellow,
.bg-success .h6.text-yellow {
  color: #ffc107;
}

.bg-success p.text-green,
.bg-success h1.text-green,
.bg-success h2.text-green,
.bg-success h3.text-green,
.bg-success h4.text-green,
.bg-success h5.text-green,
.bg-success h6.text-green,
.bg-success .h1.text-green,
.bg-success .h2.text-green,
.bg-success .h3.text-green,
.bg-success .h4.text-green,
.bg-success .h5.text-green,
.bg-success .h6.text-green {
  color: #28a745;
}

.bg-success p.text-teal,
.bg-success h1.text-teal,
.bg-success h2.text-teal,
.bg-success h3.text-teal,
.bg-success h4.text-teal,
.bg-success h5.text-teal,
.bg-success h6.text-teal,
.bg-success .h1.text-teal,
.bg-success .h2.text-teal,
.bg-success .h3.text-teal,
.bg-success .h4.text-teal,
.bg-success .h5.text-teal,
.bg-success .h6.text-teal {
  color: #20c997;
}

.bg-success p.text-cyan,
.bg-success h1.text-cyan,
.bg-success h2.text-cyan,
.bg-success h3.text-cyan,
.bg-success h4.text-cyan,
.bg-success h5.text-cyan,
.bg-success h6.text-cyan,
.bg-success .h1.text-cyan,
.bg-success .h2.text-cyan,
.bg-success .h3.text-cyan,
.bg-success .h4.text-cyan,
.bg-success .h5.text-cyan,
.bg-success .h6.text-cyan {
  color: #17a2b8;
}

.bg-success p.text-white,
.bg-success h1.text-white,
.bg-success h2.text-white,
.bg-success h3.text-white,
.bg-success h4.text-white,
.bg-success h5.text-white,
.bg-success h6.text-white,
.bg-success .h1.text-white,
.bg-success .h2.text-white,
.bg-success .h3.text-white,
.bg-success .h4.text-white,
.bg-success .h5.text-white,
.bg-success .h6.text-white {
  color: #fff;
}

.bg-success p.text-gray,
.bg-success h1.text-gray,
.bg-success h2.text-gray,
.bg-success h3.text-gray,
.bg-success h4.text-gray,
.bg-success h5.text-gray,
.bg-success h6.text-gray,
.bg-success .h1.text-gray,
.bg-success .h2.text-gray,
.bg-success .h3.text-gray,
.bg-success .h4.text-gray,
.bg-success .h5.text-gray,
.bg-success .h6.text-gray {
  color: #6c757d;
}

.bg-success p.text-gray-dark,
.bg-success h1.text-gray-dark,
.bg-success h2.text-gray-dark,
.bg-success h3.text-gray-dark,
.bg-success h4.text-gray-dark,
.bg-success h5.text-gray-dark,
.bg-success h6.text-gray-dark,
.bg-success .h1.text-gray-dark,
.bg-success .h2.text-gray-dark,
.bg-success .h3.text-gray-dark,
.bg-success .h4.text-gray-dark,
.bg-success .h5.text-gray-dark,
.bg-success .h6.text-gray-dark {
  color: #343a40;
}

.bg-success p.text-bloom,
.bg-success h1.text-bloom,
.bg-success h2.text-bloom,
.bg-success h3.text-bloom,
.bg-success h4.text-bloom,
.bg-success h5.text-bloom,
.bg-success h6.text-bloom,
.bg-success .h1.text-bloom,
.bg-success .h2.text-bloom,
.bg-success .h3.text-bloom,
.bg-success .h4.text-bloom,
.bg-success .h5.text-bloom,
.bg-success .h6.text-bloom {
  color: #ef4056;
}

.bg-success p.text-chili,
.bg-success h1.text-chili,
.bg-success h2.text-chili,
.bg-success h3.text-chili,
.bg-success h4.text-chili,
.bg-success h5.text-chili,
.bg-success h6.text-chili,
.bg-success .h1.text-chili,
.bg-success .h2.text-chili,
.bg-success .h3.text-chili,
.bg-success .h4.text-chili,
.bg-success .h5.text-chili,
.bg-success .h6.text-chili {
  color: #8b0015;
}

.bg-success p.text-sky,
.bg-success h1.text-sky,
.bg-success h2.text-sky,
.bg-success h3.text-sky,
.bg-success h4.text-sky,
.bg-success h5.text-sky,
.bg-success h6.text-sky,
.bg-success .h1.text-sky,
.bg-success .h2.text-sky,
.bg-success .h3.text-sky,
.bg-success .h4.text-sky,
.bg-success .h5.text-sky,
.bg-success .h6.text-sky {
  color: #81d3eb;
}

.bg-success p.text-oasis,
.bg-success h1.text-oasis,
.bg-success h2.text-oasis,
.bg-success h3.text-oasis,
.bg-success h4.text-oasis,
.bg-success h5.text-oasis,
.bg-success h6.text-oasis,
.bg-success .h1.text-oasis,
.bg-success .h2.text-oasis,
.bg-success .h3.text-oasis,
.bg-success .h4.text-oasis,
.bg-success .h5.text-oasis,
.bg-success .h6.text-oasis {
  color: #378dbd;
}

.bg-success p.text-azurite,
.bg-success h1.text-azurite,
.bg-success h2.text-azurite,
.bg-success h3.text-azurite,
.bg-success h4.text-azurite,
.bg-success h5.text-azurite,
.bg-success h6.text-azurite,
.bg-success .h1.text-azurite,
.bg-success .h2.text-azurite,
.bg-success .h3.text-azurite,
.bg-success .h4.text-azurite,
.bg-success .h5.text-azurite,
.bg-success .h6.text-azurite {
  color: #1e5288;
}

.bg-success p.text-midnight,
.bg-success h1.text-midnight,
.bg-success h2.text-midnight,
.bg-success h3.text-midnight,
.bg-success h4.text-midnight,
.bg-success h5.text-midnight,
.bg-success h6.text-midnight,
.bg-success .h1.text-midnight,
.bg-success .h2.text-midnight,
.bg-success .h3.text-midnight,
.bg-success .h4.text-midnight,
.bg-success .h5.text-midnight,
.bg-success .h6.text-midnight {
  color: #001c48;
}

.bg-success p.text-cool-gray,
.bg-success h1.text-cool-gray,
.bg-success h2.text-cool-gray,
.bg-success h3.text-cool-gray,
.bg-success h4.text-cool-gray,
.bg-success h5.text-cool-gray,
.bg-success h6.text-cool-gray,
.bg-success .h1.text-cool-gray,
.bg-success .h2.text-cool-gray,
.bg-success .h3.text-cool-gray,
.bg-success .h4.text-cool-gray,
.bg-success .h5.text-cool-gray,
.bg-success .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-success p.text-warm-gray,
.bg-success h1.text-warm-gray,
.bg-success h2.text-warm-gray,
.bg-success h3.text-warm-gray,
.bg-success h4.text-warm-gray,
.bg-success h5.text-warm-gray,
.bg-success h6.text-warm-gray,
.bg-success .h1.text-warm-gray,
.bg-success .h2.text-warm-gray,
.bg-success .h3.text-warm-gray,
.bg-success .h4.text-warm-gray,
.bg-success .h5.text-warm-gray,
.bg-success .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-success p.text-leaf,
.bg-success h1.text-leaf,
.bg-success h2.text-leaf,
.bg-success h3.text-leaf,
.bg-success h4.text-leaf,
.bg-success h5.text-leaf,
.bg-success h6.text-leaf,
.bg-success .h1.text-leaf,
.bg-success .h2.text-leaf,
.bg-success .h3.text-leaf,
.bg-success .h4.text-leaf,
.bg-success .h5.text-leaf,
.bg-success .h6.text-leaf {
  color: #70b865;
}

.bg-success p.text-river,
.bg-success h1.text-river,
.bg-success h2.text-river,
.bg-success h3.text-river,
.bg-success h4.text-river,
.bg-success h5.text-river,
.bg-success h6.text-river,
.bg-success .h1.text-river,
.bg-success .h2.text-river,
.bg-success .h3.text-river,
.bg-success .h4.text-river,
.bg-success .h5.text-river,
.bg-success .h6.text-river {
  color: #007d84;
}

.bg-success p.text-silver,
.bg-success h1.text-silver,
.bg-success h2.text-silver,
.bg-success h3.text-silver,
.bg-success h4.text-silver,
.bg-success h5.text-silver,
.bg-success h6.text-silver,
.bg-success .h1.text-silver,
.bg-success .h2.text-silver,
.bg-success .h3.text-silver,
.bg-success .h4.text-silver,
.bg-success .h5.text-silver,
.bg-success .h6.text-silver {
  color: #9eabae;
}

.bg-success p.text-mesa,
.bg-success h1.text-mesa,
.bg-success h2.text-mesa,
.bg-success h3.text-mesa,
.bg-success h4.text-mesa,
.bg-success h5.text-mesa,
.bg-success h6.text-mesa,
.bg-success .h1.text-mesa,
.bg-success .h2.text-mesa,
.bg-success .h3.text-mesa,
.bg-success .h4.text-mesa,
.bg-success .h5.text-mesa,
.bg-success .h6.text-mesa {
  color: #a95c42;
}

.bg-success p.text-ash,
.bg-success h1.text-ash,
.bg-success h2.text-ash,
.bg-success h3.text-ash,
.bg-success h4.text-ash,
.bg-success h5.text-ash,
.bg-success h6.text-ash,
.bg-success .h1.text-ash,
.bg-success .h2.text-ash,
.bg-success .h3.text-ash,
.bg-success .h4.text-ash,
.bg-success .h5.text-ash,
.bg-success .h6.text-ash {
  color: #403635;
}

.bg-success p.text-sage,
.bg-success h1.text-sage,
.bg-success h2.text-sage,
.bg-success h3.text-sage,
.bg-success h4.text-sage,
.bg-success h5.text-sage,
.bg-success h6.text-sage,
.bg-success .h1.text-sage,
.bg-success .h2.text-sage,
.bg-success .h3.text-sage,
.bg-success .h4.text-sage,
.bg-success .h5.text-sage,
.bg-success .h6.text-sage {
  color: #4a634e;
}

.bg-success p.text-black,
.bg-success h1.text-black,
.bg-success h2.text-black,
.bg-success h3.text-black,
.bg-success h4.text-black,
.bg-success h5.text-black,
.bg-success h6.text-black,
.bg-success .h1.text-black,
.bg-success .h2.text-black,
.bg-success .h3.text-black,
.bg-success .h4.text-black,
.bg-success .h5.text-black,
.bg-success .h6.text-black {
  color: #000;
}

.bg-success p.text-primary,
.bg-success h1.text-primary,
.bg-success h2.text-primary,
.bg-success h3.text-primary,
.bg-success h4.text-primary,
.bg-success h5.text-primary,
.bg-success h6.text-primary,
.bg-success .h1.text-primary,
.bg-success .h2.text-primary,
.bg-success .h3.text-primary,
.bg-success .h4.text-primary,
.bg-success .h5.text-primary,
.bg-success .h6.text-primary {
  color: #1e5288;
}

.bg-success p.text-secondary,
.bg-success h1.text-secondary,
.bg-success h2.text-secondary,
.bg-success h3.text-secondary,
.bg-success h4.text-secondary,
.bg-success h5.text-secondary,
.bg-success h6.text-secondary,
.bg-success .h1.text-secondary,
.bg-success .h2.text-secondary,
.bg-success .h3.text-secondary,
.bg-success .h4.text-secondary,
.bg-success .h5.text-secondary,
.bg-success .h6.text-secondary {
  color: #9eabae;
}

.bg-success p.text-success,
.bg-success h1.text-success,
.bg-success h2.text-success,
.bg-success h3.text-success,
.bg-success h4.text-success,
.bg-success h5.text-success,
.bg-success h6.text-success,
.bg-success .h1.text-success,
.bg-success .h2.text-success,
.bg-success .h3.text-success,
.bg-success .h4.text-success,
.bg-success .h5.text-success,
.bg-success .h6.text-success {
  color: #70b865;
}

.bg-success p.text-info,
.bg-success h1.text-info,
.bg-success h2.text-info,
.bg-success h3.text-info,
.bg-success h4.text-info,
.bg-success h5.text-info,
.bg-success h6.text-info,
.bg-success .h1.text-info,
.bg-success .h2.text-info,
.bg-success .h3.text-info,
.bg-success .h4.text-info,
.bg-success .h5.text-info,
.bg-success .h6.text-info {
  color: #81d3eb;
}

.bg-success p.text-warning,
.bg-success h1.text-warning,
.bg-success h2.text-warning,
.bg-success h3.text-warning,
.bg-success h4.text-warning,
.bg-success h5.text-warning,
.bg-success h6.text-warning,
.bg-success .h1.text-warning,
.bg-success .h2.text-warning,
.bg-success .h3.text-warning,
.bg-success .h4.text-warning,
.bg-success .h5.text-warning,
.bg-success .h6.text-warning {
  color: #f19e1f;
}

.bg-success p.text-danger,
.bg-success h1.text-danger,
.bg-success h2.text-danger,
.bg-success h3.text-danger,
.bg-success h4.text-danger,
.bg-success h5.text-danger,
.bg-success h6.text-danger,
.bg-success .h1.text-danger,
.bg-success .h2.text-danger,
.bg-success .h3.text-danger,
.bg-success .h4.text-danger,
.bg-success .h5.text-danger,
.bg-success .h6.text-danger {
  color: #ef4056;
}

.bg-success p.text-light,
.bg-success h1.text-light,
.bg-success h2.text-light,
.bg-success h3.text-light,
.bg-success h4.text-light,
.bg-success h5.text-light,
.bg-success h6.text-light,
.bg-success .h1.text-light,
.bg-success .h2.text-light,
.bg-success .h3.text-light,
.bg-success .h4.text-light,
.bg-success .h5.text-light,
.bg-success .h6.text-light {
  color: #dee2e6;
}

.bg-success p.text-dark,
.bg-success h1.text-dark,
.bg-success h2.text-dark,
.bg-success h3.text-dark,
.bg-success h4.text-dark,
.bg-success h5.text-dark,
.bg-success h6.text-dark,
.bg-success .h1.text-dark,
.bg-success .h2.text-dark,
.bg-success .h3.text-dark,
.bg-success .h4.text-dark,
.bg-success .h5.text-dark,
.bg-success .h6.text-dark {
  color: #343a40;
}

.bg-success p.text-primary-dark,
.bg-success h1.text-primary-dark,
.bg-success h2.text-primary-dark,
.bg-success h3.text-primary-dark,
.bg-success h4.text-primary-dark,
.bg-success h5.text-primary-dark,
.bg-success h6.text-primary-dark,
.bg-success .h1.text-primary-dark,
.bg-success .h2.text-primary-dark,
.bg-success .h3.text-primary-dark,
.bg-success .h4.text-primary-dark,
.bg-success .h5.text-primary-dark,
.bg-success .h6.text-primary-dark {
  color: #0c234b;
}

.bg-success p.text-primary-light,
.bg-success h1.text-primary-light,
.bg-success h2.text-primary-light,
.bg-success h3.text-primary-light,
.bg-success h4.text-primary-light,
.bg-success h5.text-primary-light,
.bg-success h6.text-primary-light,
.bg-success .h1.text-primary-light,
.bg-success .h2.text-primary-light,
.bg-success .h3.text-primary-light,
.bg-success .h4.text-primary-light,
.bg-success .h5.text-primary-light,
.bg-success .h6.text-primary-light {
  color: #378dbd;
}

.bg-success p.text-accent,
.bg-success h1.text-accent,
.bg-success h2.text-accent,
.bg-success h3.text-accent,
.bg-success h4.text-accent,
.bg-success h5.text-accent,
.bg-success h6.text-accent,
.bg-success .h1.text-accent,
.bg-success .h2.text-accent,
.bg-success .h3.text-accent,
.bg-success .h4.text-accent,
.bg-success .h5.text-accent,
.bg-success .h6.text-accent {
  color: #ab0520;
}

.bg-info {
  color: #001c48;
  background-color: #81d3eb;
}

.bg-info p,
.bg-info h1,
.bg-info h2,
.bg-info h3,
.bg-info h4,
.bg-info h5,
.bg-info h6,
.bg-info .h1,
.bg-info .h2,
.bg-info .h3,
.bg-info .h4,
.bg-info .h5,
.bg-info .h6 {
  color: #001c48;
}

.bg-info p.text-blue,
.bg-info h1.text-blue,
.bg-info h2.text-blue,
.bg-info h3.text-blue,
.bg-info h4.text-blue,
.bg-info h5.text-blue,
.bg-info h6.text-blue,
.bg-info .h1.text-blue,
.bg-info .h2.text-blue,
.bg-info .h3.text-blue,
.bg-info .h4.text-blue,
.bg-info .h5.text-blue,
.bg-info .h6.text-blue {
  color: #0c234b;
}

.bg-info p.text-indigo,
.bg-info h1.text-indigo,
.bg-info h2.text-indigo,
.bg-info h3.text-indigo,
.bg-info h4.text-indigo,
.bg-info h5.text-indigo,
.bg-info h6.text-indigo,
.bg-info .h1.text-indigo,
.bg-info .h2.text-indigo,
.bg-info .h3.text-indigo,
.bg-info .h4.text-indigo,
.bg-info .h5.text-indigo,
.bg-info .h6.text-indigo {
  color: #6610f2;
}

.bg-info p.text-purple,
.bg-info h1.text-purple,
.bg-info h2.text-purple,
.bg-info h3.text-purple,
.bg-info h4.text-purple,
.bg-info h5.text-purple,
.bg-info h6.text-purple,
.bg-info .h1.text-purple,
.bg-info .h2.text-purple,
.bg-info .h3.text-purple,
.bg-info .h4.text-purple,
.bg-info .h5.text-purple,
.bg-info .h6.text-purple {
  color: #6f42c1;
}

.bg-info p.text-pink,
.bg-info h1.text-pink,
.bg-info h2.text-pink,
.bg-info h3.text-pink,
.bg-info h4.text-pink,
.bg-info h5.text-pink,
.bg-info h6.text-pink,
.bg-info .h1.text-pink,
.bg-info .h2.text-pink,
.bg-info .h3.text-pink,
.bg-info .h4.text-pink,
.bg-info .h5.text-pink,
.bg-info .h6.text-pink {
  color: #e83e8c;
}

.bg-info p.text-red,
.bg-info h1.text-red,
.bg-info h2.text-red,
.bg-info h3.text-red,
.bg-info h4.text-red,
.bg-info h5.text-red,
.bg-info h6.text-red,
.bg-info .h1.text-red,
.bg-info .h2.text-red,
.bg-info .h3.text-red,
.bg-info .h4.text-red,
.bg-info .h5.text-red,
.bg-info .h6.text-red {
  color: #ab0520;
}

.bg-info p.text-orange,
.bg-info h1.text-orange,
.bg-info h2.text-orange,
.bg-info h3.text-orange,
.bg-info h4.text-orange,
.bg-info h5.text-orange,
.bg-info h6.text-orange,
.bg-info .h1.text-orange,
.bg-info .h2.text-orange,
.bg-info .h3.text-orange,
.bg-info .h4.text-orange,
.bg-info .h5.text-orange,
.bg-info .h6.text-orange {
  color: #fd7e14;
}

.bg-info p.text-yellow,
.bg-info h1.text-yellow,
.bg-info h2.text-yellow,
.bg-info h3.text-yellow,
.bg-info h4.text-yellow,
.bg-info h5.text-yellow,
.bg-info h6.text-yellow,
.bg-info .h1.text-yellow,
.bg-info .h2.text-yellow,
.bg-info .h3.text-yellow,
.bg-info .h4.text-yellow,
.bg-info .h5.text-yellow,
.bg-info .h6.text-yellow {
  color: #ffc107;
}

.bg-info p.text-green,
.bg-info h1.text-green,
.bg-info h2.text-green,
.bg-info h3.text-green,
.bg-info h4.text-green,
.bg-info h5.text-green,
.bg-info h6.text-green,
.bg-info .h1.text-green,
.bg-info .h2.text-green,
.bg-info .h3.text-green,
.bg-info .h4.text-green,
.bg-info .h5.text-green,
.bg-info .h6.text-green {
  color: #28a745;
}

.bg-info p.text-teal,
.bg-info h1.text-teal,
.bg-info h2.text-teal,
.bg-info h3.text-teal,
.bg-info h4.text-teal,
.bg-info h5.text-teal,
.bg-info h6.text-teal,
.bg-info .h1.text-teal,
.bg-info .h2.text-teal,
.bg-info .h3.text-teal,
.bg-info .h4.text-teal,
.bg-info .h5.text-teal,
.bg-info .h6.text-teal {
  color: #20c997;
}

.bg-info p.text-cyan,
.bg-info h1.text-cyan,
.bg-info h2.text-cyan,
.bg-info h3.text-cyan,
.bg-info h4.text-cyan,
.bg-info h5.text-cyan,
.bg-info h6.text-cyan,
.bg-info .h1.text-cyan,
.bg-info .h2.text-cyan,
.bg-info .h3.text-cyan,
.bg-info .h4.text-cyan,
.bg-info .h5.text-cyan,
.bg-info .h6.text-cyan {
  color: #17a2b8;
}

.bg-info p.text-white,
.bg-info h1.text-white,
.bg-info h2.text-white,
.bg-info h3.text-white,
.bg-info h4.text-white,
.bg-info h5.text-white,
.bg-info h6.text-white,
.bg-info .h1.text-white,
.bg-info .h2.text-white,
.bg-info .h3.text-white,
.bg-info .h4.text-white,
.bg-info .h5.text-white,
.bg-info .h6.text-white {
  color: #fff;
}

.bg-info p.text-gray,
.bg-info h1.text-gray,
.bg-info h2.text-gray,
.bg-info h3.text-gray,
.bg-info h4.text-gray,
.bg-info h5.text-gray,
.bg-info h6.text-gray,
.bg-info .h1.text-gray,
.bg-info .h2.text-gray,
.bg-info .h3.text-gray,
.bg-info .h4.text-gray,
.bg-info .h5.text-gray,
.bg-info .h6.text-gray {
  color: #6c757d;
}

.bg-info p.text-gray-dark,
.bg-info h1.text-gray-dark,
.bg-info h2.text-gray-dark,
.bg-info h3.text-gray-dark,
.bg-info h4.text-gray-dark,
.bg-info h5.text-gray-dark,
.bg-info h6.text-gray-dark,
.bg-info .h1.text-gray-dark,
.bg-info .h2.text-gray-dark,
.bg-info .h3.text-gray-dark,
.bg-info .h4.text-gray-dark,
.bg-info .h5.text-gray-dark,
.bg-info .h6.text-gray-dark {
  color: #343a40;
}

.bg-info p.text-bloom,
.bg-info h1.text-bloom,
.bg-info h2.text-bloom,
.bg-info h3.text-bloom,
.bg-info h4.text-bloom,
.bg-info h5.text-bloom,
.bg-info h6.text-bloom,
.bg-info .h1.text-bloom,
.bg-info .h2.text-bloom,
.bg-info .h3.text-bloom,
.bg-info .h4.text-bloom,
.bg-info .h5.text-bloom,
.bg-info .h6.text-bloom {
  color: #ef4056;
}

.bg-info p.text-chili,
.bg-info h1.text-chili,
.bg-info h2.text-chili,
.bg-info h3.text-chili,
.bg-info h4.text-chili,
.bg-info h5.text-chili,
.bg-info h6.text-chili,
.bg-info .h1.text-chili,
.bg-info .h2.text-chili,
.bg-info .h3.text-chili,
.bg-info .h4.text-chili,
.bg-info .h5.text-chili,
.bg-info .h6.text-chili {
  color: #8b0015;
}

.bg-info p.text-sky,
.bg-info h1.text-sky,
.bg-info h2.text-sky,
.bg-info h3.text-sky,
.bg-info h4.text-sky,
.bg-info h5.text-sky,
.bg-info h6.text-sky,
.bg-info .h1.text-sky,
.bg-info .h2.text-sky,
.bg-info .h3.text-sky,
.bg-info .h4.text-sky,
.bg-info .h5.text-sky,
.bg-info .h6.text-sky {
  color: #81d3eb;
}

.bg-info p.text-oasis,
.bg-info h1.text-oasis,
.bg-info h2.text-oasis,
.bg-info h3.text-oasis,
.bg-info h4.text-oasis,
.bg-info h5.text-oasis,
.bg-info h6.text-oasis,
.bg-info .h1.text-oasis,
.bg-info .h2.text-oasis,
.bg-info .h3.text-oasis,
.bg-info .h4.text-oasis,
.bg-info .h5.text-oasis,
.bg-info .h6.text-oasis {
  color: #378dbd;
}

.bg-info p.text-azurite,
.bg-info h1.text-azurite,
.bg-info h2.text-azurite,
.bg-info h3.text-azurite,
.bg-info h4.text-azurite,
.bg-info h5.text-azurite,
.bg-info h6.text-azurite,
.bg-info .h1.text-azurite,
.bg-info .h2.text-azurite,
.bg-info .h3.text-azurite,
.bg-info .h4.text-azurite,
.bg-info .h5.text-azurite,
.bg-info .h6.text-azurite {
  color: #1e5288;
}

.bg-info p.text-midnight,
.bg-info h1.text-midnight,
.bg-info h2.text-midnight,
.bg-info h3.text-midnight,
.bg-info h4.text-midnight,
.bg-info h5.text-midnight,
.bg-info h6.text-midnight,
.bg-info .h1.text-midnight,
.bg-info .h2.text-midnight,
.bg-info .h3.text-midnight,
.bg-info .h4.text-midnight,
.bg-info .h5.text-midnight,
.bg-info .h6.text-midnight {
  color: #001c48;
}

.bg-info p.text-cool-gray,
.bg-info h1.text-cool-gray,
.bg-info h2.text-cool-gray,
.bg-info h3.text-cool-gray,
.bg-info h4.text-cool-gray,
.bg-info h5.text-cool-gray,
.bg-info h6.text-cool-gray,
.bg-info .h1.text-cool-gray,
.bg-info .h2.text-cool-gray,
.bg-info .h3.text-cool-gray,
.bg-info .h4.text-cool-gray,
.bg-info .h5.text-cool-gray,
.bg-info .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-info p.text-warm-gray,
.bg-info h1.text-warm-gray,
.bg-info h2.text-warm-gray,
.bg-info h3.text-warm-gray,
.bg-info h4.text-warm-gray,
.bg-info h5.text-warm-gray,
.bg-info h6.text-warm-gray,
.bg-info .h1.text-warm-gray,
.bg-info .h2.text-warm-gray,
.bg-info .h3.text-warm-gray,
.bg-info .h4.text-warm-gray,
.bg-info .h5.text-warm-gray,
.bg-info .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-info p.text-leaf,
.bg-info h1.text-leaf,
.bg-info h2.text-leaf,
.bg-info h3.text-leaf,
.bg-info h4.text-leaf,
.bg-info h5.text-leaf,
.bg-info h6.text-leaf,
.bg-info .h1.text-leaf,
.bg-info .h2.text-leaf,
.bg-info .h3.text-leaf,
.bg-info .h4.text-leaf,
.bg-info .h5.text-leaf,
.bg-info .h6.text-leaf {
  color: #70b865;
}

.bg-info p.text-river,
.bg-info h1.text-river,
.bg-info h2.text-river,
.bg-info h3.text-river,
.bg-info h4.text-river,
.bg-info h5.text-river,
.bg-info h6.text-river,
.bg-info .h1.text-river,
.bg-info .h2.text-river,
.bg-info .h3.text-river,
.bg-info .h4.text-river,
.bg-info .h5.text-river,
.bg-info .h6.text-river {
  color: #007d84;
}

.bg-info p.text-silver,
.bg-info h1.text-silver,
.bg-info h2.text-silver,
.bg-info h3.text-silver,
.bg-info h4.text-silver,
.bg-info h5.text-silver,
.bg-info h6.text-silver,
.bg-info .h1.text-silver,
.bg-info .h2.text-silver,
.bg-info .h3.text-silver,
.bg-info .h4.text-silver,
.bg-info .h5.text-silver,
.bg-info .h6.text-silver {
  color: #9eabae;
}

.bg-info p.text-mesa,
.bg-info h1.text-mesa,
.bg-info h2.text-mesa,
.bg-info h3.text-mesa,
.bg-info h4.text-mesa,
.bg-info h5.text-mesa,
.bg-info h6.text-mesa,
.bg-info .h1.text-mesa,
.bg-info .h2.text-mesa,
.bg-info .h3.text-mesa,
.bg-info .h4.text-mesa,
.bg-info .h5.text-mesa,
.bg-info .h6.text-mesa {
  color: #a95c42;
}

.bg-info p.text-ash,
.bg-info h1.text-ash,
.bg-info h2.text-ash,
.bg-info h3.text-ash,
.bg-info h4.text-ash,
.bg-info h5.text-ash,
.bg-info h6.text-ash,
.bg-info .h1.text-ash,
.bg-info .h2.text-ash,
.bg-info .h3.text-ash,
.bg-info .h4.text-ash,
.bg-info .h5.text-ash,
.bg-info .h6.text-ash {
  color: #403635;
}

.bg-info p.text-sage,
.bg-info h1.text-sage,
.bg-info h2.text-sage,
.bg-info h3.text-sage,
.bg-info h4.text-sage,
.bg-info h5.text-sage,
.bg-info h6.text-sage,
.bg-info .h1.text-sage,
.bg-info .h2.text-sage,
.bg-info .h3.text-sage,
.bg-info .h4.text-sage,
.bg-info .h5.text-sage,
.bg-info .h6.text-sage {
  color: #4a634e;
}

.bg-info p.text-black,
.bg-info h1.text-black,
.bg-info h2.text-black,
.bg-info h3.text-black,
.bg-info h4.text-black,
.bg-info h5.text-black,
.bg-info h6.text-black,
.bg-info .h1.text-black,
.bg-info .h2.text-black,
.bg-info .h3.text-black,
.bg-info .h4.text-black,
.bg-info .h5.text-black,
.bg-info .h6.text-black {
  color: #000;
}

.bg-info p.text-primary,
.bg-info h1.text-primary,
.bg-info h2.text-primary,
.bg-info h3.text-primary,
.bg-info h4.text-primary,
.bg-info h5.text-primary,
.bg-info h6.text-primary,
.bg-info .h1.text-primary,
.bg-info .h2.text-primary,
.bg-info .h3.text-primary,
.bg-info .h4.text-primary,
.bg-info .h5.text-primary,
.bg-info .h6.text-primary {
  color: #1e5288;
}

.bg-info p.text-secondary,
.bg-info h1.text-secondary,
.bg-info h2.text-secondary,
.bg-info h3.text-secondary,
.bg-info h4.text-secondary,
.bg-info h5.text-secondary,
.bg-info h6.text-secondary,
.bg-info .h1.text-secondary,
.bg-info .h2.text-secondary,
.bg-info .h3.text-secondary,
.bg-info .h4.text-secondary,
.bg-info .h5.text-secondary,
.bg-info .h6.text-secondary {
  color: #9eabae;
}

.bg-info p.text-success,
.bg-info h1.text-success,
.bg-info h2.text-success,
.bg-info h3.text-success,
.bg-info h4.text-success,
.bg-info h5.text-success,
.bg-info h6.text-success,
.bg-info .h1.text-success,
.bg-info .h2.text-success,
.bg-info .h3.text-success,
.bg-info .h4.text-success,
.bg-info .h5.text-success,
.bg-info .h6.text-success {
  color: #70b865;
}

.bg-info p.text-info,
.bg-info h1.text-info,
.bg-info h2.text-info,
.bg-info h3.text-info,
.bg-info h4.text-info,
.bg-info h5.text-info,
.bg-info h6.text-info,
.bg-info .h1.text-info,
.bg-info .h2.text-info,
.bg-info .h3.text-info,
.bg-info .h4.text-info,
.bg-info .h5.text-info,
.bg-info .h6.text-info {
  color: #81d3eb;
}

.bg-info p.text-warning,
.bg-info h1.text-warning,
.bg-info h2.text-warning,
.bg-info h3.text-warning,
.bg-info h4.text-warning,
.bg-info h5.text-warning,
.bg-info h6.text-warning,
.bg-info .h1.text-warning,
.bg-info .h2.text-warning,
.bg-info .h3.text-warning,
.bg-info .h4.text-warning,
.bg-info .h5.text-warning,
.bg-info .h6.text-warning {
  color: #f19e1f;
}

.bg-info p.text-danger,
.bg-info h1.text-danger,
.bg-info h2.text-danger,
.bg-info h3.text-danger,
.bg-info h4.text-danger,
.bg-info h5.text-danger,
.bg-info h6.text-danger,
.bg-info .h1.text-danger,
.bg-info .h2.text-danger,
.bg-info .h3.text-danger,
.bg-info .h4.text-danger,
.bg-info .h5.text-danger,
.bg-info .h6.text-danger {
  color: #ef4056;
}

.bg-info p.text-light,
.bg-info h1.text-light,
.bg-info h2.text-light,
.bg-info h3.text-light,
.bg-info h4.text-light,
.bg-info h5.text-light,
.bg-info h6.text-light,
.bg-info .h1.text-light,
.bg-info .h2.text-light,
.bg-info .h3.text-light,
.bg-info .h4.text-light,
.bg-info .h5.text-light,
.bg-info .h6.text-light {
  color: #dee2e6;
}

.bg-info p.text-dark,
.bg-info h1.text-dark,
.bg-info h2.text-dark,
.bg-info h3.text-dark,
.bg-info h4.text-dark,
.bg-info h5.text-dark,
.bg-info h6.text-dark,
.bg-info .h1.text-dark,
.bg-info .h2.text-dark,
.bg-info .h3.text-dark,
.bg-info .h4.text-dark,
.bg-info .h5.text-dark,
.bg-info .h6.text-dark {
  color: #343a40;
}

.bg-info p.text-primary-dark,
.bg-info h1.text-primary-dark,
.bg-info h2.text-primary-dark,
.bg-info h3.text-primary-dark,
.bg-info h4.text-primary-dark,
.bg-info h5.text-primary-dark,
.bg-info h6.text-primary-dark,
.bg-info .h1.text-primary-dark,
.bg-info .h2.text-primary-dark,
.bg-info .h3.text-primary-dark,
.bg-info .h4.text-primary-dark,
.bg-info .h5.text-primary-dark,
.bg-info .h6.text-primary-dark {
  color: #0c234b;
}

.bg-info p.text-primary-light,
.bg-info h1.text-primary-light,
.bg-info h2.text-primary-light,
.bg-info h3.text-primary-light,
.bg-info h4.text-primary-light,
.bg-info h5.text-primary-light,
.bg-info h6.text-primary-light,
.bg-info .h1.text-primary-light,
.bg-info .h2.text-primary-light,
.bg-info .h3.text-primary-light,
.bg-info .h4.text-primary-light,
.bg-info .h5.text-primary-light,
.bg-info .h6.text-primary-light {
  color: #378dbd;
}

.bg-info p.text-accent,
.bg-info h1.text-accent,
.bg-info h2.text-accent,
.bg-info h3.text-accent,
.bg-info h4.text-accent,
.bg-info h5.text-accent,
.bg-info h6.text-accent,
.bg-info .h1.text-accent,
.bg-info .h2.text-accent,
.bg-info .h3.text-accent,
.bg-info .h4.text-accent,
.bg-info .h5.text-accent,
.bg-info .h6.text-accent {
  color: #ab0520;
}

.bg-warning {
  color: #000;
  background-color: #f19e1f;
}

.bg-warning p,
.bg-warning h1,
.bg-warning h2,
.bg-warning h3,
.bg-warning h4,
.bg-warning h5,
.bg-warning h6,
.bg-warning .h1,
.bg-warning .h2,
.bg-warning .h3,
.bg-warning .h4,
.bg-warning .h5,
.bg-warning .h6 {
  color: #000;
}

.bg-warning p.text-blue,
.bg-warning h1.text-blue,
.bg-warning h2.text-blue,
.bg-warning h3.text-blue,
.bg-warning h4.text-blue,
.bg-warning h5.text-blue,
.bg-warning h6.text-blue,
.bg-warning .h1.text-blue,
.bg-warning .h2.text-blue,
.bg-warning .h3.text-blue,
.bg-warning .h4.text-blue,
.bg-warning .h5.text-blue,
.bg-warning .h6.text-blue {
  color: #0c234b;
}

.bg-warning p.text-indigo,
.bg-warning h1.text-indigo,
.bg-warning h2.text-indigo,
.bg-warning h3.text-indigo,
.bg-warning h4.text-indigo,
.bg-warning h5.text-indigo,
.bg-warning h6.text-indigo,
.bg-warning .h1.text-indigo,
.bg-warning .h2.text-indigo,
.bg-warning .h3.text-indigo,
.bg-warning .h4.text-indigo,
.bg-warning .h5.text-indigo,
.bg-warning .h6.text-indigo {
  color: #6610f2;
}

.bg-warning p.text-purple,
.bg-warning h1.text-purple,
.bg-warning h2.text-purple,
.bg-warning h3.text-purple,
.bg-warning h4.text-purple,
.bg-warning h5.text-purple,
.bg-warning h6.text-purple,
.bg-warning .h1.text-purple,
.bg-warning .h2.text-purple,
.bg-warning .h3.text-purple,
.bg-warning .h4.text-purple,
.bg-warning .h5.text-purple,
.bg-warning .h6.text-purple {
  color: #6f42c1;
}

.bg-warning p.text-pink,
.bg-warning h1.text-pink,
.bg-warning h2.text-pink,
.bg-warning h3.text-pink,
.bg-warning h4.text-pink,
.bg-warning h5.text-pink,
.bg-warning h6.text-pink,
.bg-warning .h1.text-pink,
.bg-warning .h2.text-pink,
.bg-warning .h3.text-pink,
.bg-warning .h4.text-pink,
.bg-warning .h5.text-pink,
.bg-warning .h6.text-pink {
  color: #e83e8c;
}

.bg-warning p.text-red,
.bg-warning h1.text-red,
.bg-warning h2.text-red,
.bg-warning h3.text-red,
.bg-warning h4.text-red,
.bg-warning h5.text-red,
.bg-warning h6.text-red,
.bg-warning .h1.text-red,
.bg-warning .h2.text-red,
.bg-warning .h3.text-red,
.bg-warning .h4.text-red,
.bg-warning .h5.text-red,
.bg-warning .h6.text-red {
  color: #ab0520;
}

.bg-warning p.text-orange,
.bg-warning h1.text-orange,
.bg-warning h2.text-orange,
.bg-warning h3.text-orange,
.bg-warning h4.text-orange,
.bg-warning h5.text-orange,
.bg-warning h6.text-orange,
.bg-warning .h1.text-orange,
.bg-warning .h2.text-orange,
.bg-warning .h3.text-orange,
.bg-warning .h4.text-orange,
.bg-warning .h5.text-orange,
.bg-warning .h6.text-orange {
  color: #fd7e14;
}

.bg-warning p.text-yellow,
.bg-warning h1.text-yellow,
.bg-warning h2.text-yellow,
.bg-warning h3.text-yellow,
.bg-warning h4.text-yellow,
.bg-warning h5.text-yellow,
.bg-warning h6.text-yellow,
.bg-warning .h1.text-yellow,
.bg-warning .h2.text-yellow,
.bg-warning .h3.text-yellow,
.bg-warning .h4.text-yellow,
.bg-warning .h5.text-yellow,
.bg-warning .h6.text-yellow {
  color: #ffc107;
}

.bg-warning p.text-green,
.bg-warning h1.text-green,
.bg-warning h2.text-green,
.bg-warning h3.text-green,
.bg-warning h4.text-green,
.bg-warning h5.text-green,
.bg-warning h6.text-green,
.bg-warning .h1.text-green,
.bg-warning .h2.text-green,
.bg-warning .h3.text-green,
.bg-warning .h4.text-green,
.bg-warning .h5.text-green,
.bg-warning .h6.text-green {
  color: #28a745;
}

.bg-warning p.text-teal,
.bg-warning h1.text-teal,
.bg-warning h2.text-teal,
.bg-warning h3.text-teal,
.bg-warning h4.text-teal,
.bg-warning h5.text-teal,
.bg-warning h6.text-teal,
.bg-warning .h1.text-teal,
.bg-warning .h2.text-teal,
.bg-warning .h3.text-teal,
.bg-warning .h4.text-teal,
.bg-warning .h5.text-teal,
.bg-warning .h6.text-teal {
  color: #20c997;
}

.bg-warning p.text-cyan,
.bg-warning h1.text-cyan,
.bg-warning h2.text-cyan,
.bg-warning h3.text-cyan,
.bg-warning h4.text-cyan,
.bg-warning h5.text-cyan,
.bg-warning h6.text-cyan,
.bg-warning .h1.text-cyan,
.bg-warning .h2.text-cyan,
.bg-warning .h3.text-cyan,
.bg-warning .h4.text-cyan,
.bg-warning .h5.text-cyan,
.bg-warning .h6.text-cyan {
  color: #17a2b8;
}

.bg-warning p.text-white,
.bg-warning h1.text-white,
.bg-warning h2.text-white,
.bg-warning h3.text-white,
.bg-warning h4.text-white,
.bg-warning h5.text-white,
.bg-warning h6.text-white,
.bg-warning .h1.text-white,
.bg-warning .h2.text-white,
.bg-warning .h3.text-white,
.bg-warning .h4.text-white,
.bg-warning .h5.text-white,
.bg-warning .h6.text-white {
  color: #fff;
}

.bg-warning p.text-gray,
.bg-warning h1.text-gray,
.bg-warning h2.text-gray,
.bg-warning h3.text-gray,
.bg-warning h4.text-gray,
.bg-warning h5.text-gray,
.bg-warning h6.text-gray,
.bg-warning .h1.text-gray,
.bg-warning .h2.text-gray,
.bg-warning .h3.text-gray,
.bg-warning .h4.text-gray,
.bg-warning .h5.text-gray,
.bg-warning .h6.text-gray {
  color: #6c757d;
}

.bg-warning p.text-gray-dark,
.bg-warning h1.text-gray-dark,
.bg-warning h2.text-gray-dark,
.bg-warning h3.text-gray-dark,
.bg-warning h4.text-gray-dark,
.bg-warning h5.text-gray-dark,
.bg-warning h6.text-gray-dark,
.bg-warning .h1.text-gray-dark,
.bg-warning .h2.text-gray-dark,
.bg-warning .h3.text-gray-dark,
.bg-warning .h4.text-gray-dark,
.bg-warning .h5.text-gray-dark,
.bg-warning .h6.text-gray-dark {
  color: #343a40;
}

.bg-warning p.text-bloom,
.bg-warning h1.text-bloom,
.bg-warning h2.text-bloom,
.bg-warning h3.text-bloom,
.bg-warning h4.text-bloom,
.bg-warning h5.text-bloom,
.bg-warning h6.text-bloom,
.bg-warning .h1.text-bloom,
.bg-warning .h2.text-bloom,
.bg-warning .h3.text-bloom,
.bg-warning .h4.text-bloom,
.bg-warning .h5.text-bloom,
.bg-warning .h6.text-bloom {
  color: #ef4056;
}

.bg-warning p.text-chili,
.bg-warning h1.text-chili,
.bg-warning h2.text-chili,
.bg-warning h3.text-chili,
.bg-warning h4.text-chili,
.bg-warning h5.text-chili,
.bg-warning h6.text-chili,
.bg-warning .h1.text-chili,
.bg-warning .h2.text-chili,
.bg-warning .h3.text-chili,
.bg-warning .h4.text-chili,
.bg-warning .h5.text-chili,
.bg-warning .h6.text-chili {
  color: #8b0015;
}

.bg-warning p.text-sky,
.bg-warning h1.text-sky,
.bg-warning h2.text-sky,
.bg-warning h3.text-sky,
.bg-warning h4.text-sky,
.bg-warning h5.text-sky,
.bg-warning h6.text-sky,
.bg-warning .h1.text-sky,
.bg-warning .h2.text-sky,
.bg-warning .h3.text-sky,
.bg-warning .h4.text-sky,
.bg-warning .h5.text-sky,
.bg-warning .h6.text-sky {
  color: #81d3eb;
}

.bg-warning p.text-oasis,
.bg-warning h1.text-oasis,
.bg-warning h2.text-oasis,
.bg-warning h3.text-oasis,
.bg-warning h4.text-oasis,
.bg-warning h5.text-oasis,
.bg-warning h6.text-oasis,
.bg-warning .h1.text-oasis,
.bg-warning .h2.text-oasis,
.bg-warning .h3.text-oasis,
.bg-warning .h4.text-oasis,
.bg-warning .h5.text-oasis,
.bg-warning .h6.text-oasis {
  color: #378dbd;
}

.bg-warning p.text-azurite,
.bg-warning h1.text-azurite,
.bg-warning h2.text-azurite,
.bg-warning h3.text-azurite,
.bg-warning h4.text-azurite,
.bg-warning h5.text-azurite,
.bg-warning h6.text-azurite,
.bg-warning .h1.text-azurite,
.bg-warning .h2.text-azurite,
.bg-warning .h3.text-azurite,
.bg-warning .h4.text-azurite,
.bg-warning .h5.text-azurite,
.bg-warning .h6.text-azurite {
  color: #1e5288;
}

.bg-warning p.text-midnight,
.bg-warning h1.text-midnight,
.bg-warning h2.text-midnight,
.bg-warning h3.text-midnight,
.bg-warning h4.text-midnight,
.bg-warning h5.text-midnight,
.bg-warning h6.text-midnight,
.bg-warning .h1.text-midnight,
.bg-warning .h2.text-midnight,
.bg-warning .h3.text-midnight,
.bg-warning .h4.text-midnight,
.bg-warning .h5.text-midnight,
.bg-warning .h6.text-midnight {
  color: #001c48;
}

.bg-warning p.text-cool-gray,
.bg-warning h1.text-cool-gray,
.bg-warning h2.text-cool-gray,
.bg-warning h3.text-cool-gray,
.bg-warning h4.text-cool-gray,
.bg-warning h5.text-cool-gray,
.bg-warning h6.text-cool-gray,
.bg-warning .h1.text-cool-gray,
.bg-warning .h2.text-cool-gray,
.bg-warning .h3.text-cool-gray,
.bg-warning .h4.text-cool-gray,
.bg-warning .h5.text-cool-gray,
.bg-warning .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-warning p.text-warm-gray,
.bg-warning h1.text-warm-gray,
.bg-warning h2.text-warm-gray,
.bg-warning h3.text-warm-gray,
.bg-warning h4.text-warm-gray,
.bg-warning h5.text-warm-gray,
.bg-warning h6.text-warm-gray,
.bg-warning .h1.text-warm-gray,
.bg-warning .h2.text-warm-gray,
.bg-warning .h3.text-warm-gray,
.bg-warning .h4.text-warm-gray,
.bg-warning .h5.text-warm-gray,
.bg-warning .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-warning p.text-leaf,
.bg-warning h1.text-leaf,
.bg-warning h2.text-leaf,
.bg-warning h3.text-leaf,
.bg-warning h4.text-leaf,
.bg-warning h5.text-leaf,
.bg-warning h6.text-leaf,
.bg-warning .h1.text-leaf,
.bg-warning .h2.text-leaf,
.bg-warning .h3.text-leaf,
.bg-warning .h4.text-leaf,
.bg-warning .h5.text-leaf,
.bg-warning .h6.text-leaf {
  color: #70b865;
}

.bg-warning p.text-river,
.bg-warning h1.text-river,
.bg-warning h2.text-river,
.bg-warning h3.text-river,
.bg-warning h4.text-river,
.bg-warning h5.text-river,
.bg-warning h6.text-river,
.bg-warning .h1.text-river,
.bg-warning .h2.text-river,
.bg-warning .h3.text-river,
.bg-warning .h4.text-river,
.bg-warning .h5.text-river,
.bg-warning .h6.text-river {
  color: #007d84;
}

.bg-warning p.text-silver,
.bg-warning h1.text-silver,
.bg-warning h2.text-silver,
.bg-warning h3.text-silver,
.bg-warning h4.text-silver,
.bg-warning h5.text-silver,
.bg-warning h6.text-silver,
.bg-warning .h1.text-silver,
.bg-warning .h2.text-silver,
.bg-warning .h3.text-silver,
.bg-warning .h4.text-silver,
.bg-warning .h5.text-silver,
.bg-warning .h6.text-silver {
  color: #9eabae;
}

.bg-warning p.text-mesa,
.bg-warning h1.text-mesa,
.bg-warning h2.text-mesa,
.bg-warning h3.text-mesa,
.bg-warning h4.text-mesa,
.bg-warning h5.text-mesa,
.bg-warning h6.text-mesa,
.bg-warning .h1.text-mesa,
.bg-warning .h2.text-mesa,
.bg-warning .h3.text-mesa,
.bg-warning .h4.text-mesa,
.bg-warning .h5.text-mesa,
.bg-warning .h6.text-mesa {
  color: #a95c42;
}

.bg-warning p.text-ash,
.bg-warning h1.text-ash,
.bg-warning h2.text-ash,
.bg-warning h3.text-ash,
.bg-warning h4.text-ash,
.bg-warning h5.text-ash,
.bg-warning h6.text-ash,
.bg-warning .h1.text-ash,
.bg-warning .h2.text-ash,
.bg-warning .h3.text-ash,
.bg-warning .h4.text-ash,
.bg-warning .h5.text-ash,
.bg-warning .h6.text-ash {
  color: #403635;
}

.bg-warning p.text-sage,
.bg-warning h1.text-sage,
.bg-warning h2.text-sage,
.bg-warning h3.text-sage,
.bg-warning h4.text-sage,
.bg-warning h5.text-sage,
.bg-warning h6.text-sage,
.bg-warning .h1.text-sage,
.bg-warning .h2.text-sage,
.bg-warning .h3.text-sage,
.bg-warning .h4.text-sage,
.bg-warning .h5.text-sage,
.bg-warning .h6.text-sage {
  color: #4a634e;
}

.bg-warning p.text-black,
.bg-warning h1.text-black,
.bg-warning h2.text-black,
.bg-warning h3.text-black,
.bg-warning h4.text-black,
.bg-warning h5.text-black,
.bg-warning h6.text-black,
.bg-warning .h1.text-black,
.bg-warning .h2.text-black,
.bg-warning .h3.text-black,
.bg-warning .h4.text-black,
.bg-warning .h5.text-black,
.bg-warning .h6.text-black {
  color: #000;
}

.bg-warning p.text-primary,
.bg-warning h1.text-primary,
.bg-warning h2.text-primary,
.bg-warning h3.text-primary,
.bg-warning h4.text-primary,
.bg-warning h5.text-primary,
.bg-warning h6.text-primary,
.bg-warning .h1.text-primary,
.bg-warning .h2.text-primary,
.bg-warning .h3.text-primary,
.bg-warning .h4.text-primary,
.bg-warning .h5.text-primary,
.bg-warning .h6.text-primary {
  color: #1e5288;
}

.bg-warning p.text-secondary,
.bg-warning h1.text-secondary,
.bg-warning h2.text-secondary,
.bg-warning h3.text-secondary,
.bg-warning h4.text-secondary,
.bg-warning h5.text-secondary,
.bg-warning h6.text-secondary,
.bg-warning .h1.text-secondary,
.bg-warning .h2.text-secondary,
.bg-warning .h3.text-secondary,
.bg-warning .h4.text-secondary,
.bg-warning .h5.text-secondary,
.bg-warning .h6.text-secondary {
  color: #9eabae;
}

.bg-warning p.text-success,
.bg-warning h1.text-success,
.bg-warning h2.text-success,
.bg-warning h3.text-success,
.bg-warning h4.text-success,
.bg-warning h5.text-success,
.bg-warning h6.text-success,
.bg-warning .h1.text-success,
.bg-warning .h2.text-success,
.bg-warning .h3.text-success,
.bg-warning .h4.text-success,
.bg-warning .h5.text-success,
.bg-warning .h6.text-success {
  color: #70b865;
}

.bg-warning p.text-info,
.bg-warning h1.text-info,
.bg-warning h2.text-info,
.bg-warning h3.text-info,
.bg-warning h4.text-info,
.bg-warning h5.text-info,
.bg-warning h6.text-info,
.bg-warning .h1.text-info,
.bg-warning .h2.text-info,
.bg-warning .h3.text-info,
.bg-warning .h4.text-info,
.bg-warning .h5.text-info,
.bg-warning .h6.text-info {
  color: #81d3eb;
}

.bg-warning p.text-warning,
.bg-warning h1.text-warning,
.bg-warning h2.text-warning,
.bg-warning h3.text-warning,
.bg-warning h4.text-warning,
.bg-warning h5.text-warning,
.bg-warning h6.text-warning,
.bg-warning .h1.text-warning,
.bg-warning .h2.text-warning,
.bg-warning .h3.text-warning,
.bg-warning .h4.text-warning,
.bg-warning .h5.text-warning,
.bg-warning .h6.text-warning {
  color: #f19e1f;
}

.bg-warning p.text-danger,
.bg-warning h1.text-danger,
.bg-warning h2.text-danger,
.bg-warning h3.text-danger,
.bg-warning h4.text-danger,
.bg-warning h5.text-danger,
.bg-warning h6.text-danger,
.bg-warning .h1.text-danger,
.bg-warning .h2.text-danger,
.bg-warning .h3.text-danger,
.bg-warning .h4.text-danger,
.bg-warning .h5.text-danger,
.bg-warning .h6.text-danger {
  color: #ef4056;
}

.bg-warning p.text-light,
.bg-warning h1.text-light,
.bg-warning h2.text-light,
.bg-warning h3.text-light,
.bg-warning h4.text-light,
.bg-warning h5.text-light,
.bg-warning h6.text-light,
.bg-warning .h1.text-light,
.bg-warning .h2.text-light,
.bg-warning .h3.text-light,
.bg-warning .h4.text-light,
.bg-warning .h5.text-light,
.bg-warning .h6.text-light {
  color: #dee2e6;
}

.bg-warning p.text-dark,
.bg-warning h1.text-dark,
.bg-warning h2.text-dark,
.bg-warning h3.text-dark,
.bg-warning h4.text-dark,
.bg-warning h5.text-dark,
.bg-warning h6.text-dark,
.bg-warning .h1.text-dark,
.bg-warning .h2.text-dark,
.bg-warning .h3.text-dark,
.bg-warning .h4.text-dark,
.bg-warning .h5.text-dark,
.bg-warning .h6.text-dark {
  color: #343a40;
}

.bg-warning p.text-primary-dark,
.bg-warning h1.text-primary-dark,
.bg-warning h2.text-primary-dark,
.bg-warning h3.text-primary-dark,
.bg-warning h4.text-primary-dark,
.bg-warning h5.text-primary-dark,
.bg-warning h6.text-primary-dark,
.bg-warning .h1.text-primary-dark,
.bg-warning .h2.text-primary-dark,
.bg-warning .h3.text-primary-dark,
.bg-warning .h4.text-primary-dark,
.bg-warning .h5.text-primary-dark,
.bg-warning .h6.text-primary-dark {
  color: #0c234b;
}

.bg-warning p.text-primary-light,
.bg-warning h1.text-primary-light,
.bg-warning h2.text-primary-light,
.bg-warning h3.text-primary-light,
.bg-warning h4.text-primary-light,
.bg-warning h5.text-primary-light,
.bg-warning h6.text-primary-light,
.bg-warning .h1.text-primary-light,
.bg-warning .h2.text-primary-light,
.bg-warning .h3.text-primary-light,
.bg-warning .h4.text-primary-light,
.bg-warning .h5.text-primary-light,
.bg-warning .h6.text-primary-light {
  color: #378dbd;
}

.bg-warning p.text-accent,
.bg-warning h1.text-accent,
.bg-warning h2.text-accent,
.bg-warning h3.text-accent,
.bg-warning h4.text-accent,
.bg-warning h5.text-accent,
.bg-warning h6.text-accent,
.bg-warning .h1.text-accent,
.bg-warning .h2.text-accent,
.bg-warning .h3.text-accent,
.bg-warning .h4.text-accent,
.bg-warning .h5.text-accent,
.bg-warning .h6.text-accent {
  color: #ab0520;
}

.bg-danger {
  color: #000;
  background-color: #ef4056;
}

.bg-danger p,
.bg-danger h1,
.bg-danger h2,
.bg-danger h3,
.bg-danger h4,
.bg-danger h5,
.bg-danger h6,
.bg-danger .h1,
.bg-danger .h2,
.bg-danger .h3,
.bg-danger .h4,
.bg-danger .h5,
.bg-danger .h6 {
  color: #000;
}

.bg-danger p.text-blue,
.bg-danger h1.text-blue,
.bg-danger h2.text-blue,
.bg-danger h3.text-blue,
.bg-danger h4.text-blue,
.bg-danger h5.text-blue,
.bg-danger h6.text-blue,
.bg-danger .h1.text-blue,
.bg-danger .h2.text-blue,
.bg-danger .h3.text-blue,
.bg-danger .h4.text-blue,
.bg-danger .h5.text-blue,
.bg-danger .h6.text-blue {
  color: #0c234b;
}

.bg-danger p.text-indigo,
.bg-danger h1.text-indigo,
.bg-danger h2.text-indigo,
.bg-danger h3.text-indigo,
.bg-danger h4.text-indigo,
.bg-danger h5.text-indigo,
.bg-danger h6.text-indigo,
.bg-danger .h1.text-indigo,
.bg-danger .h2.text-indigo,
.bg-danger .h3.text-indigo,
.bg-danger .h4.text-indigo,
.bg-danger .h5.text-indigo,
.bg-danger .h6.text-indigo {
  color: #6610f2;
}

.bg-danger p.text-purple,
.bg-danger h1.text-purple,
.bg-danger h2.text-purple,
.bg-danger h3.text-purple,
.bg-danger h4.text-purple,
.bg-danger h5.text-purple,
.bg-danger h6.text-purple,
.bg-danger .h1.text-purple,
.bg-danger .h2.text-purple,
.bg-danger .h3.text-purple,
.bg-danger .h4.text-purple,
.bg-danger .h5.text-purple,
.bg-danger .h6.text-purple {
  color: #6f42c1;
}

.bg-danger p.text-pink,
.bg-danger h1.text-pink,
.bg-danger h2.text-pink,
.bg-danger h3.text-pink,
.bg-danger h4.text-pink,
.bg-danger h5.text-pink,
.bg-danger h6.text-pink,
.bg-danger .h1.text-pink,
.bg-danger .h2.text-pink,
.bg-danger .h3.text-pink,
.bg-danger .h4.text-pink,
.bg-danger .h5.text-pink,
.bg-danger .h6.text-pink {
  color: #e83e8c;
}

.bg-danger p.text-red,
.bg-danger h1.text-red,
.bg-danger h2.text-red,
.bg-danger h3.text-red,
.bg-danger h4.text-red,
.bg-danger h5.text-red,
.bg-danger h6.text-red,
.bg-danger .h1.text-red,
.bg-danger .h2.text-red,
.bg-danger .h3.text-red,
.bg-danger .h4.text-red,
.bg-danger .h5.text-red,
.bg-danger .h6.text-red {
  color: #ab0520;
}

.bg-danger p.text-orange,
.bg-danger h1.text-orange,
.bg-danger h2.text-orange,
.bg-danger h3.text-orange,
.bg-danger h4.text-orange,
.bg-danger h5.text-orange,
.bg-danger h6.text-orange,
.bg-danger .h1.text-orange,
.bg-danger .h2.text-orange,
.bg-danger .h3.text-orange,
.bg-danger .h4.text-orange,
.bg-danger .h5.text-orange,
.bg-danger .h6.text-orange {
  color: #fd7e14;
}

.bg-danger p.text-yellow,
.bg-danger h1.text-yellow,
.bg-danger h2.text-yellow,
.bg-danger h3.text-yellow,
.bg-danger h4.text-yellow,
.bg-danger h5.text-yellow,
.bg-danger h6.text-yellow,
.bg-danger .h1.text-yellow,
.bg-danger .h2.text-yellow,
.bg-danger .h3.text-yellow,
.bg-danger .h4.text-yellow,
.bg-danger .h5.text-yellow,
.bg-danger .h6.text-yellow {
  color: #ffc107;
}

.bg-danger p.text-green,
.bg-danger h1.text-green,
.bg-danger h2.text-green,
.bg-danger h3.text-green,
.bg-danger h4.text-green,
.bg-danger h5.text-green,
.bg-danger h6.text-green,
.bg-danger .h1.text-green,
.bg-danger .h2.text-green,
.bg-danger .h3.text-green,
.bg-danger .h4.text-green,
.bg-danger .h5.text-green,
.bg-danger .h6.text-green {
  color: #28a745;
}

.bg-danger p.text-teal,
.bg-danger h1.text-teal,
.bg-danger h2.text-teal,
.bg-danger h3.text-teal,
.bg-danger h4.text-teal,
.bg-danger h5.text-teal,
.bg-danger h6.text-teal,
.bg-danger .h1.text-teal,
.bg-danger .h2.text-teal,
.bg-danger .h3.text-teal,
.bg-danger .h4.text-teal,
.bg-danger .h5.text-teal,
.bg-danger .h6.text-teal {
  color: #20c997;
}

.bg-danger p.text-cyan,
.bg-danger h1.text-cyan,
.bg-danger h2.text-cyan,
.bg-danger h3.text-cyan,
.bg-danger h4.text-cyan,
.bg-danger h5.text-cyan,
.bg-danger h6.text-cyan,
.bg-danger .h1.text-cyan,
.bg-danger .h2.text-cyan,
.bg-danger .h3.text-cyan,
.bg-danger .h4.text-cyan,
.bg-danger .h5.text-cyan,
.bg-danger .h6.text-cyan {
  color: #17a2b8;
}

.bg-danger p.text-white,
.bg-danger h1.text-white,
.bg-danger h2.text-white,
.bg-danger h3.text-white,
.bg-danger h4.text-white,
.bg-danger h5.text-white,
.bg-danger h6.text-white,
.bg-danger .h1.text-white,
.bg-danger .h2.text-white,
.bg-danger .h3.text-white,
.bg-danger .h4.text-white,
.bg-danger .h5.text-white,
.bg-danger .h6.text-white {
  color: #fff;
}

.bg-danger p.text-gray,
.bg-danger h1.text-gray,
.bg-danger h2.text-gray,
.bg-danger h3.text-gray,
.bg-danger h4.text-gray,
.bg-danger h5.text-gray,
.bg-danger h6.text-gray,
.bg-danger .h1.text-gray,
.bg-danger .h2.text-gray,
.bg-danger .h3.text-gray,
.bg-danger .h4.text-gray,
.bg-danger .h5.text-gray,
.bg-danger .h6.text-gray {
  color: #6c757d;
}

.bg-danger p.text-gray-dark,
.bg-danger h1.text-gray-dark,
.bg-danger h2.text-gray-dark,
.bg-danger h3.text-gray-dark,
.bg-danger h4.text-gray-dark,
.bg-danger h5.text-gray-dark,
.bg-danger h6.text-gray-dark,
.bg-danger .h1.text-gray-dark,
.bg-danger .h2.text-gray-dark,
.bg-danger .h3.text-gray-dark,
.bg-danger .h4.text-gray-dark,
.bg-danger .h5.text-gray-dark,
.bg-danger .h6.text-gray-dark {
  color: #343a40;
}

.bg-danger p.text-bloom,
.bg-danger h1.text-bloom,
.bg-danger h2.text-bloom,
.bg-danger h3.text-bloom,
.bg-danger h4.text-bloom,
.bg-danger h5.text-bloom,
.bg-danger h6.text-bloom,
.bg-danger .h1.text-bloom,
.bg-danger .h2.text-bloom,
.bg-danger .h3.text-bloom,
.bg-danger .h4.text-bloom,
.bg-danger .h5.text-bloom,
.bg-danger .h6.text-bloom {
  color: #ef4056;
}

.bg-danger p.text-chili,
.bg-danger h1.text-chili,
.bg-danger h2.text-chili,
.bg-danger h3.text-chili,
.bg-danger h4.text-chili,
.bg-danger h5.text-chili,
.bg-danger h6.text-chili,
.bg-danger .h1.text-chili,
.bg-danger .h2.text-chili,
.bg-danger .h3.text-chili,
.bg-danger .h4.text-chili,
.bg-danger .h5.text-chili,
.bg-danger .h6.text-chili {
  color: #8b0015;
}

.bg-danger p.text-sky,
.bg-danger h1.text-sky,
.bg-danger h2.text-sky,
.bg-danger h3.text-sky,
.bg-danger h4.text-sky,
.bg-danger h5.text-sky,
.bg-danger h6.text-sky,
.bg-danger .h1.text-sky,
.bg-danger .h2.text-sky,
.bg-danger .h3.text-sky,
.bg-danger .h4.text-sky,
.bg-danger .h5.text-sky,
.bg-danger .h6.text-sky {
  color: #81d3eb;
}

.bg-danger p.text-oasis,
.bg-danger h1.text-oasis,
.bg-danger h2.text-oasis,
.bg-danger h3.text-oasis,
.bg-danger h4.text-oasis,
.bg-danger h5.text-oasis,
.bg-danger h6.text-oasis,
.bg-danger .h1.text-oasis,
.bg-danger .h2.text-oasis,
.bg-danger .h3.text-oasis,
.bg-danger .h4.text-oasis,
.bg-danger .h5.text-oasis,
.bg-danger .h6.text-oasis {
  color: #378dbd;
}

.bg-danger p.text-azurite,
.bg-danger h1.text-azurite,
.bg-danger h2.text-azurite,
.bg-danger h3.text-azurite,
.bg-danger h4.text-azurite,
.bg-danger h5.text-azurite,
.bg-danger h6.text-azurite,
.bg-danger .h1.text-azurite,
.bg-danger .h2.text-azurite,
.bg-danger .h3.text-azurite,
.bg-danger .h4.text-azurite,
.bg-danger .h5.text-azurite,
.bg-danger .h6.text-azurite {
  color: #1e5288;
}

.bg-danger p.text-midnight,
.bg-danger h1.text-midnight,
.bg-danger h2.text-midnight,
.bg-danger h3.text-midnight,
.bg-danger h4.text-midnight,
.bg-danger h5.text-midnight,
.bg-danger h6.text-midnight,
.bg-danger .h1.text-midnight,
.bg-danger .h2.text-midnight,
.bg-danger .h3.text-midnight,
.bg-danger .h4.text-midnight,
.bg-danger .h5.text-midnight,
.bg-danger .h6.text-midnight {
  color: #001c48;
}

.bg-danger p.text-cool-gray,
.bg-danger h1.text-cool-gray,
.bg-danger h2.text-cool-gray,
.bg-danger h3.text-cool-gray,
.bg-danger h4.text-cool-gray,
.bg-danger h5.text-cool-gray,
.bg-danger h6.text-cool-gray,
.bg-danger .h1.text-cool-gray,
.bg-danger .h2.text-cool-gray,
.bg-danger .h3.text-cool-gray,
.bg-danger .h4.text-cool-gray,
.bg-danger .h5.text-cool-gray,
.bg-danger .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-danger p.text-warm-gray,
.bg-danger h1.text-warm-gray,
.bg-danger h2.text-warm-gray,
.bg-danger h3.text-warm-gray,
.bg-danger h4.text-warm-gray,
.bg-danger h5.text-warm-gray,
.bg-danger h6.text-warm-gray,
.bg-danger .h1.text-warm-gray,
.bg-danger .h2.text-warm-gray,
.bg-danger .h3.text-warm-gray,
.bg-danger .h4.text-warm-gray,
.bg-danger .h5.text-warm-gray,
.bg-danger .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-danger p.text-leaf,
.bg-danger h1.text-leaf,
.bg-danger h2.text-leaf,
.bg-danger h3.text-leaf,
.bg-danger h4.text-leaf,
.bg-danger h5.text-leaf,
.bg-danger h6.text-leaf,
.bg-danger .h1.text-leaf,
.bg-danger .h2.text-leaf,
.bg-danger .h3.text-leaf,
.bg-danger .h4.text-leaf,
.bg-danger .h5.text-leaf,
.bg-danger .h6.text-leaf {
  color: #70b865;
}

.bg-danger p.text-river,
.bg-danger h1.text-river,
.bg-danger h2.text-river,
.bg-danger h3.text-river,
.bg-danger h4.text-river,
.bg-danger h5.text-river,
.bg-danger h6.text-river,
.bg-danger .h1.text-river,
.bg-danger .h2.text-river,
.bg-danger .h3.text-river,
.bg-danger .h4.text-river,
.bg-danger .h5.text-river,
.bg-danger .h6.text-river {
  color: #007d84;
}

.bg-danger p.text-silver,
.bg-danger h1.text-silver,
.bg-danger h2.text-silver,
.bg-danger h3.text-silver,
.bg-danger h4.text-silver,
.bg-danger h5.text-silver,
.bg-danger h6.text-silver,
.bg-danger .h1.text-silver,
.bg-danger .h2.text-silver,
.bg-danger .h3.text-silver,
.bg-danger .h4.text-silver,
.bg-danger .h5.text-silver,
.bg-danger .h6.text-silver {
  color: #9eabae;
}

.bg-danger p.text-mesa,
.bg-danger h1.text-mesa,
.bg-danger h2.text-mesa,
.bg-danger h3.text-mesa,
.bg-danger h4.text-mesa,
.bg-danger h5.text-mesa,
.bg-danger h6.text-mesa,
.bg-danger .h1.text-mesa,
.bg-danger .h2.text-mesa,
.bg-danger .h3.text-mesa,
.bg-danger .h4.text-mesa,
.bg-danger .h5.text-mesa,
.bg-danger .h6.text-mesa {
  color: #a95c42;
}

.bg-danger p.text-ash,
.bg-danger h1.text-ash,
.bg-danger h2.text-ash,
.bg-danger h3.text-ash,
.bg-danger h4.text-ash,
.bg-danger h5.text-ash,
.bg-danger h6.text-ash,
.bg-danger .h1.text-ash,
.bg-danger .h2.text-ash,
.bg-danger .h3.text-ash,
.bg-danger .h4.text-ash,
.bg-danger .h5.text-ash,
.bg-danger .h6.text-ash {
  color: #403635;
}

.bg-danger p.text-sage,
.bg-danger h1.text-sage,
.bg-danger h2.text-sage,
.bg-danger h3.text-sage,
.bg-danger h4.text-sage,
.bg-danger h5.text-sage,
.bg-danger h6.text-sage,
.bg-danger .h1.text-sage,
.bg-danger .h2.text-sage,
.bg-danger .h3.text-sage,
.bg-danger .h4.text-sage,
.bg-danger .h5.text-sage,
.bg-danger .h6.text-sage {
  color: #4a634e;
}

.bg-danger p.text-black,
.bg-danger h1.text-black,
.bg-danger h2.text-black,
.bg-danger h3.text-black,
.bg-danger h4.text-black,
.bg-danger h5.text-black,
.bg-danger h6.text-black,
.bg-danger .h1.text-black,
.bg-danger .h2.text-black,
.bg-danger .h3.text-black,
.bg-danger .h4.text-black,
.bg-danger .h5.text-black,
.bg-danger .h6.text-black {
  color: #000;
}

.bg-danger p.text-primary,
.bg-danger h1.text-primary,
.bg-danger h2.text-primary,
.bg-danger h3.text-primary,
.bg-danger h4.text-primary,
.bg-danger h5.text-primary,
.bg-danger h6.text-primary,
.bg-danger .h1.text-primary,
.bg-danger .h2.text-primary,
.bg-danger .h3.text-primary,
.bg-danger .h4.text-primary,
.bg-danger .h5.text-primary,
.bg-danger .h6.text-primary {
  color: #1e5288;
}

.bg-danger p.text-secondary,
.bg-danger h1.text-secondary,
.bg-danger h2.text-secondary,
.bg-danger h3.text-secondary,
.bg-danger h4.text-secondary,
.bg-danger h5.text-secondary,
.bg-danger h6.text-secondary,
.bg-danger .h1.text-secondary,
.bg-danger .h2.text-secondary,
.bg-danger .h3.text-secondary,
.bg-danger .h4.text-secondary,
.bg-danger .h5.text-secondary,
.bg-danger .h6.text-secondary {
  color: #9eabae;
}

.bg-danger p.text-success,
.bg-danger h1.text-success,
.bg-danger h2.text-success,
.bg-danger h3.text-success,
.bg-danger h4.text-success,
.bg-danger h5.text-success,
.bg-danger h6.text-success,
.bg-danger .h1.text-success,
.bg-danger .h2.text-success,
.bg-danger .h3.text-success,
.bg-danger .h4.text-success,
.bg-danger .h5.text-success,
.bg-danger .h6.text-success {
  color: #70b865;
}

.bg-danger p.text-info,
.bg-danger h1.text-info,
.bg-danger h2.text-info,
.bg-danger h3.text-info,
.bg-danger h4.text-info,
.bg-danger h5.text-info,
.bg-danger h6.text-info,
.bg-danger .h1.text-info,
.bg-danger .h2.text-info,
.bg-danger .h3.text-info,
.bg-danger .h4.text-info,
.bg-danger .h5.text-info,
.bg-danger .h6.text-info {
  color: #81d3eb;
}

.bg-danger p.text-warning,
.bg-danger h1.text-warning,
.bg-danger h2.text-warning,
.bg-danger h3.text-warning,
.bg-danger h4.text-warning,
.bg-danger h5.text-warning,
.bg-danger h6.text-warning,
.bg-danger .h1.text-warning,
.bg-danger .h2.text-warning,
.bg-danger .h3.text-warning,
.bg-danger .h4.text-warning,
.bg-danger .h5.text-warning,
.bg-danger .h6.text-warning {
  color: #f19e1f;
}

.bg-danger p.text-danger,
.bg-danger h1.text-danger,
.bg-danger h2.text-danger,
.bg-danger h3.text-danger,
.bg-danger h4.text-danger,
.bg-danger h5.text-danger,
.bg-danger h6.text-danger,
.bg-danger .h1.text-danger,
.bg-danger .h2.text-danger,
.bg-danger .h3.text-danger,
.bg-danger .h4.text-danger,
.bg-danger .h5.text-danger,
.bg-danger .h6.text-danger {
  color: #ef4056;
}

.bg-danger p.text-light,
.bg-danger h1.text-light,
.bg-danger h2.text-light,
.bg-danger h3.text-light,
.bg-danger h4.text-light,
.bg-danger h5.text-light,
.bg-danger h6.text-light,
.bg-danger .h1.text-light,
.bg-danger .h2.text-light,
.bg-danger .h3.text-light,
.bg-danger .h4.text-light,
.bg-danger .h5.text-light,
.bg-danger .h6.text-light {
  color: #dee2e6;
}

.bg-danger p.text-dark,
.bg-danger h1.text-dark,
.bg-danger h2.text-dark,
.bg-danger h3.text-dark,
.bg-danger h4.text-dark,
.bg-danger h5.text-dark,
.bg-danger h6.text-dark,
.bg-danger .h1.text-dark,
.bg-danger .h2.text-dark,
.bg-danger .h3.text-dark,
.bg-danger .h4.text-dark,
.bg-danger .h5.text-dark,
.bg-danger .h6.text-dark {
  color: #343a40;
}

.bg-danger p.text-primary-dark,
.bg-danger h1.text-primary-dark,
.bg-danger h2.text-primary-dark,
.bg-danger h3.text-primary-dark,
.bg-danger h4.text-primary-dark,
.bg-danger h5.text-primary-dark,
.bg-danger h6.text-primary-dark,
.bg-danger .h1.text-primary-dark,
.bg-danger .h2.text-primary-dark,
.bg-danger .h3.text-primary-dark,
.bg-danger .h4.text-primary-dark,
.bg-danger .h5.text-primary-dark,
.bg-danger .h6.text-primary-dark {
  color: #0c234b;
}

.bg-danger p.text-primary-light,
.bg-danger h1.text-primary-light,
.bg-danger h2.text-primary-light,
.bg-danger h3.text-primary-light,
.bg-danger h4.text-primary-light,
.bg-danger h5.text-primary-light,
.bg-danger h6.text-primary-light,
.bg-danger .h1.text-primary-light,
.bg-danger .h2.text-primary-light,
.bg-danger .h3.text-primary-light,
.bg-danger .h4.text-primary-light,
.bg-danger .h5.text-primary-light,
.bg-danger .h6.text-primary-light {
  color: #378dbd;
}

.bg-danger p.text-accent,
.bg-danger h1.text-accent,
.bg-danger h2.text-accent,
.bg-danger h3.text-accent,
.bg-danger h4.text-accent,
.bg-danger h5.text-accent,
.bg-danger h6.text-accent,
.bg-danger .h1.text-accent,
.bg-danger .h2.text-accent,
.bg-danger .h3.text-accent,
.bg-danger .h4.text-accent,
.bg-danger .h5.text-accent,
.bg-danger .h6.text-accent {
  color: #ab0520;
}

.bg-light {
  color: #fff;
  background-color: #dee2e6;
}

.bg-light p,
.bg-light h1,
.bg-light h2,
.bg-light h3,
.bg-light h4,
.bg-light h5,
.bg-light h6,
.bg-light .h1,
.bg-light .h2,
.bg-light .h3,
.bg-light .h4,
.bg-light .h5,
.bg-light .h6 {
  color: #fff;
}

.bg-light p.text-blue,
.bg-light h1.text-blue,
.bg-light h2.text-blue,
.bg-light h3.text-blue,
.bg-light h4.text-blue,
.bg-light h5.text-blue,
.bg-light h6.text-blue,
.bg-light .h1.text-blue,
.bg-light .h2.text-blue,
.bg-light .h3.text-blue,
.bg-light .h4.text-blue,
.bg-light .h5.text-blue,
.bg-light .h6.text-blue {
  color: #0c234b;
}

.bg-light p.text-indigo,
.bg-light h1.text-indigo,
.bg-light h2.text-indigo,
.bg-light h3.text-indigo,
.bg-light h4.text-indigo,
.bg-light h5.text-indigo,
.bg-light h6.text-indigo,
.bg-light .h1.text-indigo,
.bg-light .h2.text-indigo,
.bg-light .h3.text-indigo,
.bg-light .h4.text-indigo,
.bg-light .h5.text-indigo,
.bg-light .h6.text-indigo {
  color: #6610f2;
}

.bg-light p.text-purple,
.bg-light h1.text-purple,
.bg-light h2.text-purple,
.bg-light h3.text-purple,
.bg-light h4.text-purple,
.bg-light h5.text-purple,
.bg-light h6.text-purple,
.bg-light .h1.text-purple,
.bg-light .h2.text-purple,
.bg-light .h3.text-purple,
.bg-light .h4.text-purple,
.bg-light .h5.text-purple,
.bg-light .h6.text-purple {
  color: #6f42c1;
}

.bg-light p.text-pink,
.bg-light h1.text-pink,
.bg-light h2.text-pink,
.bg-light h3.text-pink,
.bg-light h4.text-pink,
.bg-light h5.text-pink,
.bg-light h6.text-pink,
.bg-light .h1.text-pink,
.bg-light .h2.text-pink,
.bg-light .h3.text-pink,
.bg-light .h4.text-pink,
.bg-light .h5.text-pink,
.bg-light .h6.text-pink {
  color: #e83e8c;
}

.bg-light p.text-red,
.bg-light h1.text-red,
.bg-light h2.text-red,
.bg-light h3.text-red,
.bg-light h4.text-red,
.bg-light h5.text-red,
.bg-light h6.text-red,
.bg-light .h1.text-red,
.bg-light .h2.text-red,
.bg-light .h3.text-red,
.bg-light .h4.text-red,
.bg-light .h5.text-red,
.bg-light .h6.text-red {
  color: #ab0520;
}

.bg-light p.text-orange,
.bg-light h1.text-orange,
.bg-light h2.text-orange,
.bg-light h3.text-orange,
.bg-light h4.text-orange,
.bg-light h5.text-orange,
.bg-light h6.text-orange,
.bg-light .h1.text-orange,
.bg-light .h2.text-orange,
.bg-light .h3.text-orange,
.bg-light .h4.text-orange,
.bg-light .h5.text-orange,
.bg-light .h6.text-orange {
  color: #fd7e14;
}

.bg-light p.text-yellow,
.bg-light h1.text-yellow,
.bg-light h2.text-yellow,
.bg-light h3.text-yellow,
.bg-light h4.text-yellow,
.bg-light h5.text-yellow,
.bg-light h6.text-yellow,
.bg-light .h1.text-yellow,
.bg-light .h2.text-yellow,
.bg-light .h3.text-yellow,
.bg-light .h4.text-yellow,
.bg-light .h5.text-yellow,
.bg-light .h6.text-yellow {
  color: #ffc107;
}

.bg-light p.text-green,
.bg-light h1.text-green,
.bg-light h2.text-green,
.bg-light h3.text-green,
.bg-light h4.text-green,
.bg-light h5.text-green,
.bg-light h6.text-green,
.bg-light .h1.text-green,
.bg-light .h2.text-green,
.bg-light .h3.text-green,
.bg-light .h4.text-green,
.bg-light .h5.text-green,
.bg-light .h6.text-green {
  color: #28a745;
}

.bg-light p.text-teal,
.bg-light h1.text-teal,
.bg-light h2.text-teal,
.bg-light h3.text-teal,
.bg-light h4.text-teal,
.bg-light h5.text-teal,
.bg-light h6.text-teal,
.bg-light .h1.text-teal,
.bg-light .h2.text-teal,
.bg-light .h3.text-teal,
.bg-light .h4.text-teal,
.bg-light .h5.text-teal,
.bg-light .h6.text-teal {
  color: #20c997;
}

.bg-light p.text-cyan,
.bg-light h1.text-cyan,
.bg-light h2.text-cyan,
.bg-light h3.text-cyan,
.bg-light h4.text-cyan,
.bg-light h5.text-cyan,
.bg-light h6.text-cyan,
.bg-light .h1.text-cyan,
.bg-light .h2.text-cyan,
.bg-light .h3.text-cyan,
.bg-light .h4.text-cyan,
.bg-light .h5.text-cyan,
.bg-light .h6.text-cyan {
  color: #17a2b8;
}

.bg-light p.text-white,
.bg-light h1.text-white,
.bg-light h2.text-white,
.bg-light h3.text-white,
.bg-light h4.text-white,
.bg-light h5.text-white,
.bg-light h6.text-white,
.bg-light .h1.text-white,
.bg-light .h2.text-white,
.bg-light .h3.text-white,
.bg-light .h4.text-white,
.bg-light .h5.text-white,
.bg-light .h6.text-white {
  color: #fff;
}

.bg-light p.text-gray,
.bg-light h1.text-gray,
.bg-light h2.text-gray,
.bg-light h3.text-gray,
.bg-light h4.text-gray,
.bg-light h5.text-gray,
.bg-light h6.text-gray,
.bg-light .h1.text-gray,
.bg-light .h2.text-gray,
.bg-light .h3.text-gray,
.bg-light .h4.text-gray,
.bg-light .h5.text-gray,
.bg-light .h6.text-gray {
  color: #6c757d;
}

.bg-light p.text-gray-dark,
.bg-light h1.text-gray-dark,
.bg-light h2.text-gray-dark,
.bg-light h3.text-gray-dark,
.bg-light h4.text-gray-dark,
.bg-light h5.text-gray-dark,
.bg-light h6.text-gray-dark,
.bg-light .h1.text-gray-dark,
.bg-light .h2.text-gray-dark,
.bg-light .h3.text-gray-dark,
.bg-light .h4.text-gray-dark,
.bg-light .h5.text-gray-dark,
.bg-light .h6.text-gray-dark {
  color: #343a40;
}

.bg-light p.text-bloom,
.bg-light h1.text-bloom,
.bg-light h2.text-bloom,
.bg-light h3.text-bloom,
.bg-light h4.text-bloom,
.bg-light h5.text-bloom,
.bg-light h6.text-bloom,
.bg-light .h1.text-bloom,
.bg-light .h2.text-bloom,
.bg-light .h3.text-bloom,
.bg-light .h4.text-bloom,
.bg-light .h5.text-bloom,
.bg-light .h6.text-bloom {
  color: #ef4056;
}

.bg-light p.text-chili,
.bg-light h1.text-chili,
.bg-light h2.text-chili,
.bg-light h3.text-chili,
.bg-light h4.text-chili,
.bg-light h5.text-chili,
.bg-light h6.text-chili,
.bg-light .h1.text-chili,
.bg-light .h2.text-chili,
.bg-light .h3.text-chili,
.bg-light .h4.text-chili,
.bg-light .h5.text-chili,
.bg-light .h6.text-chili {
  color: #8b0015;
}

.bg-light p.text-sky,
.bg-light h1.text-sky,
.bg-light h2.text-sky,
.bg-light h3.text-sky,
.bg-light h4.text-sky,
.bg-light h5.text-sky,
.bg-light h6.text-sky,
.bg-light .h1.text-sky,
.bg-light .h2.text-sky,
.bg-light .h3.text-sky,
.bg-light .h4.text-sky,
.bg-light .h5.text-sky,
.bg-light .h6.text-sky {
  color: #81d3eb;
}

.bg-light p.text-oasis,
.bg-light h1.text-oasis,
.bg-light h2.text-oasis,
.bg-light h3.text-oasis,
.bg-light h4.text-oasis,
.bg-light h5.text-oasis,
.bg-light h6.text-oasis,
.bg-light .h1.text-oasis,
.bg-light .h2.text-oasis,
.bg-light .h3.text-oasis,
.bg-light .h4.text-oasis,
.bg-light .h5.text-oasis,
.bg-light .h6.text-oasis {
  color: #378dbd;
}

.bg-light p.text-azurite,
.bg-light h1.text-azurite,
.bg-light h2.text-azurite,
.bg-light h3.text-azurite,
.bg-light h4.text-azurite,
.bg-light h5.text-azurite,
.bg-light h6.text-azurite,
.bg-light .h1.text-azurite,
.bg-light .h2.text-azurite,
.bg-light .h3.text-azurite,
.bg-light .h4.text-azurite,
.bg-light .h5.text-azurite,
.bg-light .h6.text-azurite {
  color: #1e5288;
}

.bg-light p.text-midnight,
.bg-light h1.text-midnight,
.bg-light h2.text-midnight,
.bg-light h3.text-midnight,
.bg-light h4.text-midnight,
.bg-light h5.text-midnight,
.bg-light h6.text-midnight,
.bg-light .h1.text-midnight,
.bg-light .h2.text-midnight,
.bg-light .h3.text-midnight,
.bg-light .h4.text-midnight,
.bg-light .h5.text-midnight,
.bg-light .h6.text-midnight {
  color: #001c48;
}

.bg-light p.text-cool-gray,
.bg-light h1.text-cool-gray,
.bg-light h2.text-cool-gray,
.bg-light h3.text-cool-gray,
.bg-light h4.text-cool-gray,
.bg-light h5.text-cool-gray,
.bg-light h6.text-cool-gray,
.bg-light .h1.text-cool-gray,
.bg-light .h2.text-cool-gray,
.bg-light .h3.text-cool-gray,
.bg-light .h4.text-cool-gray,
.bg-light .h5.text-cool-gray,
.bg-light .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-light p.text-warm-gray,
.bg-light h1.text-warm-gray,
.bg-light h2.text-warm-gray,
.bg-light h3.text-warm-gray,
.bg-light h4.text-warm-gray,
.bg-light h5.text-warm-gray,
.bg-light h6.text-warm-gray,
.bg-light .h1.text-warm-gray,
.bg-light .h2.text-warm-gray,
.bg-light .h3.text-warm-gray,
.bg-light .h4.text-warm-gray,
.bg-light .h5.text-warm-gray,
.bg-light .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-light p.text-leaf,
.bg-light h1.text-leaf,
.bg-light h2.text-leaf,
.bg-light h3.text-leaf,
.bg-light h4.text-leaf,
.bg-light h5.text-leaf,
.bg-light h6.text-leaf,
.bg-light .h1.text-leaf,
.bg-light .h2.text-leaf,
.bg-light .h3.text-leaf,
.bg-light .h4.text-leaf,
.bg-light .h5.text-leaf,
.bg-light .h6.text-leaf {
  color: #70b865;
}

.bg-light p.text-river,
.bg-light h1.text-river,
.bg-light h2.text-river,
.bg-light h3.text-river,
.bg-light h4.text-river,
.bg-light h5.text-river,
.bg-light h6.text-river,
.bg-light .h1.text-river,
.bg-light .h2.text-river,
.bg-light .h3.text-river,
.bg-light .h4.text-river,
.bg-light .h5.text-river,
.bg-light .h6.text-river {
  color: #007d84;
}

.bg-light p.text-silver,
.bg-light h1.text-silver,
.bg-light h2.text-silver,
.bg-light h3.text-silver,
.bg-light h4.text-silver,
.bg-light h5.text-silver,
.bg-light h6.text-silver,
.bg-light .h1.text-silver,
.bg-light .h2.text-silver,
.bg-light .h3.text-silver,
.bg-light .h4.text-silver,
.bg-light .h5.text-silver,
.bg-light .h6.text-silver {
  color: #9eabae;
}

.bg-light p.text-mesa,
.bg-light h1.text-mesa,
.bg-light h2.text-mesa,
.bg-light h3.text-mesa,
.bg-light h4.text-mesa,
.bg-light h5.text-mesa,
.bg-light h6.text-mesa,
.bg-light .h1.text-mesa,
.bg-light .h2.text-mesa,
.bg-light .h3.text-mesa,
.bg-light .h4.text-mesa,
.bg-light .h5.text-mesa,
.bg-light .h6.text-mesa {
  color: #a95c42;
}

.bg-light p.text-ash,
.bg-light h1.text-ash,
.bg-light h2.text-ash,
.bg-light h3.text-ash,
.bg-light h4.text-ash,
.bg-light h5.text-ash,
.bg-light h6.text-ash,
.bg-light .h1.text-ash,
.bg-light .h2.text-ash,
.bg-light .h3.text-ash,
.bg-light .h4.text-ash,
.bg-light .h5.text-ash,
.bg-light .h6.text-ash {
  color: #403635;
}

.bg-light p.text-sage,
.bg-light h1.text-sage,
.bg-light h2.text-sage,
.bg-light h3.text-sage,
.bg-light h4.text-sage,
.bg-light h5.text-sage,
.bg-light h6.text-sage,
.bg-light .h1.text-sage,
.bg-light .h2.text-sage,
.bg-light .h3.text-sage,
.bg-light .h4.text-sage,
.bg-light .h5.text-sage,
.bg-light .h6.text-sage {
  color: #4a634e;
}

.bg-light p.text-black,
.bg-light h1.text-black,
.bg-light h2.text-black,
.bg-light h3.text-black,
.bg-light h4.text-black,
.bg-light h5.text-black,
.bg-light h6.text-black,
.bg-light .h1.text-black,
.bg-light .h2.text-black,
.bg-light .h3.text-black,
.bg-light .h4.text-black,
.bg-light .h5.text-black,
.bg-light .h6.text-black {
  color: #000;
}

.bg-light p.text-primary,
.bg-light h1.text-primary,
.bg-light h2.text-primary,
.bg-light h3.text-primary,
.bg-light h4.text-primary,
.bg-light h5.text-primary,
.bg-light h6.text-primary,
.bg-light .h1.text-primary,
.bg-light .h2.text-primary,
.bg-light .h3.text-primary,
.bg-light .h4.text-primary,
.bg-light .h5.text-primary,
.bg-light .h6.text-primary {
  color: #1e5288;
}

.bg-light p.text-secondary,
.bg-light h1.text-secondary,
.bg-light h2.text-secondary,
.bg-light h3.text-secondary,
.bg-light h4.text-secondary,
.bg-light h5.text-secondary,
.bg-light h6.text-secondary,
.bg-light .h1.text-secondary,
.bg-light .h2.text-secondary,
.bg-light .h3.text-secondary,
.bg-light .h4.text-secondary,
.bg-light .h5.text-secondary,
.bg-light .h6.text-secondary {
  color: #9eabae;
}

.bg-light p.text-success,
.bg-light h1.text-success,
.bg-light h2.text-success,
.bg-light h3.text-success,
.bg-light h4.text-success,
.bg-light h5.text-success,
.bg-light h6.text-success,
.bg-light .h1.text-success,
.bg-light .h2.text-success,
.bg-light .h3.text-success,
.bg-light .h4.text-success,
.bg-light .h5.text-success,
.bg-light .h6.text-success {
  color: #70b865;
}

.bg-light p.text-info,
.bg-light h1.text-info,
.bg-light h2.text-info,
.bg-light h3.text-info,
.bg-light h4.text-info,
.bg-light h5.text-info,
.bg-light h6.text-info,
.bg-light .h1.text-info,
.bg-light .h2.text-info,
.bg-light .h3.text-info,
.bg-light .h4.text-info,
.bg-light .h5.text-info,
.bg-light .h6.text-info {
  color: #81d3eb;
}

.bg-light p.text-warning,
.bg-light h1.text-warning,
.bg-light h2.text-warning,
.bg-light h3.text-warning,
.bg-light h4.text-warning,
.bg-light h5.text-warning,
.bg-light h6.text-warning,
.bg-light .h1.text-warning,
.bg-light .h2.text-warning,
.bg-light .h3.text-warning,
.bg-light .h4.text-warning,
.bg-light .h5.text-warning,
.bg-light .h6.text-warning {
  color: #f19e1f;
}

.bg-light p.text-danger,
.bg-light h1.text-danger,
.bg-light h2.text-danger,
.bg-light h3.text-danger,
.bg-light h4.text-danger,
.bg-light h5.text-danger,
.bg-light h6.text-danger,
.bg-light .h1.text-danger,
.bg-light .h2.text-danger,
.bg-light .h3.text-danger,
.bg-light .h4.text-danger,
.bg-light .h5.text-danger,
.bg-light .h6.text-danger {
  color: #ef4056;
}

.bg-light p.text-light,
.bg-light h1.text-light,
.bg-light h2.text-light,
.bg-light h3.text-light,
.bg-light h4.text-light,
.bg-light h5.text-light,
.bg-light h6.text-light,
.bg-light .h1.text-light,
.bg-light .h2.text-light,
.bg-light .h3.text-light,
.bg-light .h4.text-light,
.bg-light .h5.text-light,
.bg-light .h6.text-light {
  color: #dee2e6;
}

.bg-light p.text-dark,
.bg-light h1.text-dark,
.bg-light h2.text-dark,
.bg-light h3.text-dark,
.bg-light h4.text-dark,
.bg-light h5.text-dark,
.bg-light h6.text-dark,
.bg-light .h1.text-dark,
.bg-light .h2.text-dark,
.bg-light .h3.text-dark,
.bg-light .h4.text-dark,
.bg-light .h5.text-dark,
.bg-light .h6.text-dark {
  color: #343a40;
}

.bg-light p.text-primary-dark,
.bg-light h1.text-primary-dark,
.bg-light h2.text-primary-dark,
.bg-light h3.text-primary-dark,
.bg-light h4.text-primary-dark,
.bg-light h5.text-primary-dark,
.bg-light h6.text-primary-dark,
.bg-light .h1.text-primary-dark,
.bg-light .h2.text-primary-dark,
.bg-light .h3.text-primary-dark,
.bg-light .h4.text-primary-dark,
.bg-light .h5.text-primary-dark,
.bg-light .h6.text-primary-dark {
  color: #0c234b;
}

.bg-light p.text-primary-light,
.bg-light h1.text-primary-light,
.bg-light h2.text-primary-light,
.bg-light h3.text-primary-light,
.bg-light h4.text-primary-light,
.bg-light h5.text-primary-light,
.bg-light h6.text-primary-light,
.bg-light .h1.text-primary-light,
.bg-light .h2.text-primary-light,
.bg-light .h3.text-primary-light,
.bg-light .h4.text-primary-light,
.bg-light .h5.text-primary-light,
.bg-light .h6.text-primary-light {
  color: #378dbd;
}

.bg-light p.text-accent,
.bg-light h1.text-accent,
.bg-light h2.text-accent,
.bg-light h3.text-accent,
.bg-light h4.text-accent,
.bg-light h5.text-accent,
.bg-light h6.text-accent,
.bg-light .h1.text-accent,
.bg-light .h2.text-accent,
.bg-light .h3.text-accent,
.bg-light .h4.text-accent,
.bg-light .h5.text-accent,
.bg-light .h6.text-accent {
  color: #ab0520;
}

.bg-dark {
  color: #fff;
  background-color: #343a40;
}

.bg-dark p,
.bg-dark h1,
.bg-dark h2,
.bg-dark h3,
.bg-dark h4,
.bg-dark h5,
.bg-dark h6,
.bg-dark .h1,
.bg-dark .h2,
.bg-dark .h3,
.bg-dark .h4,
.bg-dark .h5,
.bg-dark .h6 {
  color: #fff;
}

.bg-dark p.text-blue,
.bg-dark h1.text-blue,
.bg-dark h2.text-blue,
.bg-dark h3.text-blue,
.bg-dark h4.text-blue,
.bg-dark h5.text-blue,
.bg-dark h6.text-blue,
.bg-dark .h1.text-blue,
.bg-dark .h2.text-blue,
.bg-dark .h3.text-blue,
.bg-dark .h4.text-blue,
.bg-dark .h5.text-blue,
.bg-dark .h6.text-blue {
  color: #0c234b;
}

.bg-dark p.text-indigo,
.bg-dark h1.text-indigo,
.bg-dark h2.text-indigo,
.bg-dark h3.text-indigo,
.bg-dark h4.text-indigo,
.bg-dark h5.text-indigo,
.bg-dark h6.text-indigo,
.bg-dark .h1.text-indigo,
.bg-dark .h2.text-indigo,
.bg-dark .h3.text-indigo,
.bg-dark .h4.text-indigo,
.bg-dark .h5.text-indigo,
.bg-dark .h6.text-indigo {
  color: #6610f2;
}

.bg-dark p.text-purple,
.bg-dark h1.text-purple,
.bg-dark h2.text-purple,
.bg-dark h3.text-purple,
.bg-dark h4.text-purple,
.bg-dark h5.text-purple,
.bg-dark h6.text-purple,
.bg-dark .h1.text-purple,
.bg-dark .h2.text-purple,
.bg-dark .h3.text-purple,
.bg-dark .h4.text-purple,
.bg-dark .h5.text-purple,
.bg-dark .h6.text-purple {
  color: #6f42c1;
}

.bg-dark p.text-pink,
.bg-dark h1.text-pink,
.bg-dark h2.text-pink,
.bg-dark h3.text-pink,
.bg-dark h4.text-pink,
.bg-dark h5.text-pink,
.bg-dark h6.text-pink,
.bg-dark .h1.text-pink,
.bg-dark .h2.text-pink,
.bg-dark .h3.text-pink,
.bg-dark .h4.text-pink,
.bg-dark .h5.text-pink,
.bg-dark .h6.text-pink {
  color: #e83e8c;
}

.bg-dark p.text-red,
.bg-dark h1.text-red,
.bg-dark h2.text-red,
.bg-dark h3.text-red,
.bg-dark h4.text-red,
.bg-dark h5.text-red,
.bg-dark h6.text-red,
.bg-dark .h1.text-red,
.bg-dark .h2.text-red,
.bg-dark .h3.text-red,
.bg-dark .h4.text-red,
.bg-dark .h5.text-red,
.bg-dark .h6.text-red {
  color: #ab0520;
}

.bg-dark p.text-orange,
.bg-dark h1.text-orange,
.bg-dark h2.text-orange,
.bg-dark h3.text-orange,
.bg-dark h4.text-orange,
.bg-dark h5.text-orange,
.bg-dark h6.text-orange,
.bg-dark .h1.text-orange,
.bg-dark .h2.text-orange,
.bg-dark .h3.text-orange,
.bg-dark .h4.text-orange,
.bg-dark .h5.text-orange,
.bg-dark .h6.text-orange {
  color: #fd7e14;
}

.bg-dark p.text-yellow,
.bg-dark h1.text-yellow,
.bg-dark h2.text-yellow,
.bg-dark h3.text-yellow,
.bg-dark h4.text-yellow,
.bg-dark h5.text-yellow,
.bg-dark h6.text-yellow,
.bg-dark .h1.text-yellow,
.bg-dark .h2.text-yellow,
.bg-dark .h3.text-yellow,
.bg-dark .h4.text-yellow,
.bg-dark .h5.text-yellow,
.bg-dark .h6.text-yellow {
  color: #ffc107;
}

.bg-dark p.text-green,
.bg-dark h1.text-green,
.bg-dark h2.text-green,
.bg-dark h3.text-green,
.bg-dark h4.text-green,
.bg-dark h5.text-green,
.bg-dark h6.text-green,
.bg-dark .h1.text-green,
.bg-dark .h2.text-green,
.bg-dark .h3.text-green,
.bg-dark .h4.text-green,
.bg-dark .h5.text-green,
.bg-dark .h6.text-green {
  color: #28a745;
}

.bg-dark p.text-teal,
.bg-dark h1.text-teal,
.bg-dark h2.text-teal,
.bg-dark h3.text-teal,
.bg-dark h4.text-teal,
.bg-dark h5.text-teal,
.bg-dark h6.text-teal,
.bg-dark .h1.text-teal,
.bg-dark .h2.text-teal,
.bg-dark .h3.text-teal,
.bg-dark .h4.text-teal,
.bg-dark .h5.text-teal,
.bg-dark .h6.text-teal {
  color: #20c997;
}

.bg-dark p.text-cyan,
.bg-dark h1.text-cyan,
.bg-dark h2.text-cyan,
.bg-dark h3.text-cyan,
.bg-dark h4.text-cyan,
.bg-dark h5.text-cyan,
.bg-dark h6.text-cyan,
.bg-dark .h1.text-cyan,
.bg-dark .h2.text-cyan,
.bg-dark .h3.text-cyan,
.bg-dark .h4.text-cyan,
.bg-dark .h5.text-cyan,
.bg-dark .h6.text-cyan {
  color: #17a2b8;
}

.bg-dark p.text-white,
.bg-dark h1.text-white,
.bg-dark h2.text-white,
.bg-dark h3.text-white,
.bg-dark h4.text-white,
.bg-dark h5.text-white,
.bg-dark h6.text-white,
.bg-dark .h1.text-white,
.bg-dark .h2.text-white,
.bg-dark .h3.text-white,
.bg-dark .h4.text-white,
.bg-dark .h5.text-white,
.bg-dark .h6.text-white {
  color: #fff;
}

.bg-dark p.text-gray,
.bg-dark h1.text-gray,
.bg-dark h2.text-gray,
.bg-dark h3.text-gray,
.bg-dark h4.text-gray,
.bg-dark h5.text-gray,
.bg-dark h6.text-gray,
.bg-dark .h1.text-gray,
.bg-dark .h2.text-gray,
.bg-dark .h3.text-gray,
.bg-dark .h4.text-gray,
.bg-dark .h5.text-gray,
.bg-dark .h6.text-gray {
  color: #6c757d;
}

.bg-dark p.text-gray-dark,
.bg-dark h1.text-gray-dark,
.bg-dark h2.text-gray-dark,
.bg-dark h3.text-gray-dark,
.bg-dark h4.text-gray-dark,
.bg-dark h5.text-gray-dark,
.bg-dark h6.text-gray-dark,
.bg-dark .h1.text-gray-dark,
.bg-dark .h2.text-gray-dark,
.bg-dark .h3.text-gray-dark,
.bg-dark .h4.text-gray-dark,
.bg-dark .h5.text-gray-dark,
.bg-dark .h6.text-gray-dark {
  color: #343a40;
}

.bg-dark p.text-bloom,
.bg-dark h1.text-bloom,
.bg-dark h2.text-bloom,
.bg-dark h3.text-bloom,
.bg-dark h4.text-bloom,
.bg-dark h5.text-bloom,
.bg-dark h6.text-bloom,
.bg-dark .h1.text-bloom,
.bg-dark .h2.text-bloom,
.bg-dark .h3.text-bloom,
.bg-dark .h4.text-bloom,
.bg-dark .h5.text-bloom,
.bg-dark .h6.text-bloom {
  color: #ef4056;
}

.bg-dark p.text-chili,
.bg-dark h1.text-chili,
.bg-dark h2.text-chili,
.bg-dark h3.text-chili,
.bg-dark h4.text-chili,
.bg-dark h5.text-chili,
.bg-dark h6.text-chili,
.bg-dark .h1.text-chili,
.bg-dark .h2.text-chili,
.bg-dark .h3.text-chili,
.bg-dark .h4.text-chili,
.bg-dark .h5.text-chili,
.bg-dark .h6.text-chili {
  color: #8b0015;
}

.bg-dark p.text-sky,
.bg-dark h1.text-sky,
.bg-dark h2.text-sky,
.bg-dark h3.text-sky,
.bg-dark h4.text-sky,
.bg-dark h5.text-sky,
.bg-dark h6.text-sky,
.bg-dark .h1.text-sky,
.bg-dark .h2.text-sky,
.bg-dark .h3.text-sky,
.bg-dark .h4.text-sky,
.bg-dark .h5.text-sky,
.bg-dark .h6.text-sky {
  color: #81d3eb;
}

.bg-dark p.text-oasis,
.bg-dark h1.text-oasis,
.bg-dark h2.text-oasis,
.bg-dark h3.text-oasis,
.bg-dark h4.text-oasis,
.bg-dark h5.text-oasis,
.bg-dark h6.text-oasis,
.bg-dark .h1.text-oasis,
.bg-dark .h2.text-oasis,
.bg-dark .h3.text-oasis,
.bg-dark .h4.text-oasis,
.bg-dark .h5.text-oasis,
.bg-dark .h6.text-oasis {
  color: #378dbd;
}

.bg-dark p.text-azurite,
.bg-dark h1.text-azurite,
.bg-dark h2.text-azurite,
.bg-dark h3.text-azurite,
.bg-dark h4.text-azurite,
.bg-dark h5.text-azurite,
.bg-dark h6.text-azurite,
.bg-dark .h1.text-azurite,
.bg-dark .h2.text-azurite,
.bg-dark .h3.text-azurite,
.bg-dark .h4.text-azurite,
.bg-dark .h5.text-azurite,
.bg-dark .h6.text-azurite {
  color: #1e5288;
}

.bg-dark p.text-midnight,
.bg-dark h1.text-midnight,
.bg-dark h2.text-midnight,
.bg-dark h3.text-midnight,
.bg-dark h4.text-midnight,
.bg-dark h5.text-midnight,
.bg-dark h6.text-midnight,
.bg-dark .h1.text-midnight,
.bg-dark .h2.text-midnight,
.bg-dark .h3.text-midnight,
.bg-dark .h4.text-midnight,
.bg-dark .h5.text-midnight,
.bg-dark .h6.text-midnight {
  color: #001c48;
}

.bg-dark p.text-cool-gray,
.bg-dark h1.text-cool-gray,
.bg-dark h2.text-cool-gray,
.bg-dark h3.text-cool-gray,
.bg-dark h4.text-cool-gray,
.bg-dark h5.text-cool-gray,
.bg-dark h6.text-cool-gray,
.bg-dark .h1.text-cool-gray,
.bg-dark .h2.text-cool-gray,
.bg-dark .h3.text-cool-gray,
.bg-dark .h4.text-cool-gray,
.bg-dark .h5.text-cool-gray,
.bg-dark .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-dark p.text-warm-gray,
.bg-dark h1.text-warm-gray,
.bg-dark h2.text-warm-gray,
.bg-dark h3.text-warm-gray,
.bg-dark h4.text-warm-gray,
.bg-dark h5.text-warm-gray,
.bg-dark h6.text-warm-gray,
.bg-dark .h1.text-warm-gray,
.bg-dark .h2.text-warm-gray,
.bg-dark .h3.text-warm-gray,
.bg-dark .h4.text-warm-gray,
.bg-dark .h5.text-warm-gray,
.bg-dark .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-dark p.text-leaf,
.bg-dark h1.text-leaf,
.bg-dark h2.text-leaf,
.bg-dark h3.text-leaf,
.bg-dark h4.text-leaf,
.bg-dark h5.text-leaf,
.bg-dark h6.text-leaf,
.bg-dark .h1.text-leaf,
.bg-dark .h2.text-leaf,
.bg-dark .h3.text-leaf,
.bg-dark .h4.text-leaf,
.bg-dark .h5.text-leaf,
.bg-dark .h6.text-leaf {
  color: #70b865;
}

.bg-dark p.text-river,
.bg-dark h1.text-river,
.bg-dark h2.text-river,
.bg-dark h3.text-river,
.bg-dark h4.text-river,
.bg-dark h5.text-river,
.bg-dark h6.text-river,
.bg-dark .h1.text-river,
.bg-dark .h2.text-river,
.bg-dark .h3.text-river,
.bg-dark .h4.text-river,
.bg-dark .h5.text-river,
.bg-dark .h6.text-river {
  color: #007d84;
}

.bg-dark p.text-silver,
.bg-dark h1.text-silver,
.bg-dark h2.text-silver,
.bg-dark h3.text-silver,
.bg-dark h4.text-silver,
.bg-dark h5.text-silver,
.bg-dark h6.text-silver,
.bg-dark .h1.text-silver,
.bg-dark .h2.text-silver,
.bg-dark .h3.text-silver,
.bg-dark .h4.text-silver,
.bg-dark .h5.text-silver,
.bg-dark .h6.text-silver {
  color: #9eabae;
}

.bg-dark p.text-mesa,
.bg-dark h1.text-mesa,
.bg-dark h2.text-mesa,
.bg-dark h3.text-mesa,
.bg-dark h4.text-mesa,
.bg-dark h5.text-mesa,
.bg-dark h6.text-mesa,
.bg-dark .h1.text-mesa,
.bg-dark .h2.text-mesa,
.bg-dark .h3.text-mesa,
.bg-dark .h4.text-mesa,
.bg-dark .h5.text-mesa,
.bg-dark .h6.text-mesa {
  color: #a95c42;
}

.bg-dark p.text-ash,
.bg-dark h1.text-ash,
.bg-dark h2.text-ash,
.bg-dark h3.text-ash,
.bg-dark h4.text-ash,
.bg-dark h5.text-ash,
.bg-dark h6.text-ash,
.bg-dark .h1.text-ash,
.bg-dark .h2.text-ash,
.bg-dark .h3.text-ash,
.bg-dark .h4.text-ash,
.bg-dark .h5.text-ash,
.bg-dark .h6.text-ash {
  color: #403635;
}

.bg-dark p.text-sage,
.bg-dark h1.text-sage,
.bg-dark h2.text-sage,
.bg-dark h3.text-sage,
.bg-dark h4.text-sage,
.bg-dark h5.text-sage,
.bg-dark h6.text-sage,
.bg-dark .h1.text-sage,
.bg-dark .h2.text-sage,
.bg-dark .h3.text-sage,
.bg-dark .h4.text-sage,
.bg-dark .h5.text-sage,
.bg-dark .h6.text-sage {
  color: #4a634e;
}

.bg-dark p.text-black,
.bg-dark h1.text-black,
.bg-dark h2.text-black,
.bg-dark h3.text-black,
.bg-dark h4.text-black,
.bg-dark h5.text-black,
.bg-dark h6.text-black,
.bg-dark .h1.text-black,
.bg-dark .h2.text-black,
.bg-dark .h3.text-black,
.bg-dark .h4.text-black,
.bg-dark .h5.text-black,
.bg-dark .h6.text-black {
  color: #000;
}

.bg-dark p.text-primary,
.bg-dark h1.text-primary,
.bg-dark h2.text-primary,
.bg-dark h3.text-primary,
.bg-dark h4.text-primary,
.bg-dark h5.text-primary,
.bg-dark h6.text-primary,
.bg-dark .h1.text-primary,
.bg-dark .h2.text-primary,
.bg-dark .h3.text-primary,
.bg-dark .h4.text-primary,
.bg-dark .h5.text-primary,
.bg-dark .h6.text-primary {
  color: #1e5288;
}

.bg-dark p.text-secondary,
.bg-dark h1.text-secondary,
.bg-dark h2.text-secondary,
.bg-dark h3.text-secondary,
.bg-dark h4.text-secondary,
.bg-dark h5.text-secondary,
.bg-dark h6.text-secondary,
.bg-dark .h1.text-secondary,
.bg-dark .h2.text-secondary,
.bg-dark .h3.text-secondary,
.bg-dark .h4.text-secondary,
.bg-dark .h5.text-secondary,
.bg-dark .h6.text-secondary {
  color: #9eabae;
}

.bg-dark p.text-success,
.bg-dark h1.text-success,
.bg-dark h2.text-success,
.bg-dark h3.text-success,
.bg-dark h4.text-success,
.bg-dark h5.text-success,
.bg-dark h6.text-success,
.bg-dark .h1.text-success,
.bg-dark .h2.text-success,
.bg-dark .h3.text-success,
.bg-dark .h4.text-success,
.bg-dark .h5.text-success,
.bg-dark .h6.text-success {
  color: #70b865;
}

.bg-dark p.text-info,
.bg-dark h1.text-info,
.bg-dark h2.text-info,
.bg-dark h3.text-info,
.bg-dark h4.text-info,
.bg-dark h5.text-info,
.bg-dark h6.text-info,
.bg-dark .h1.text-info,
.bg-dark .h2.text-info,
.bg-dark .h3.text-info,
.bg-dark .h4.text-info,
.bg-dark .h5.text-info,
.bg-dark .h6.text-info {
  color: #81d3eb;
}

.bg-dark p.text-warning,
.bg-dark h1.text-warning,
.bg-dark h2.text-warning,
.bg-dark h3.text-warning,
.bg-dark h4.text-warning,
.bg-dark h5.text-warning,
.bg-dark h6.text-warning,
.bg-dark .h1.text-warning,
.bg-dark .h2.text-warning,
.bg-dark .h3.text-warning,
.bg-dark .h4.text-warning,
.bg-dark .h5.text-warning,
.bg-dark .h6.text-warning {
  color: #f19e1f;
}

.bg-dark p.text-danger,
.bg-dark h1.text-danger,
.bg-dark h2.text-danger,
.bg-dark h3.text-danger,
.bg-dark h4.text-danger,
.bg-dark h5.text-danger,
.bg-dark h6.text-danger,
.bg-dark .h1.text-danger,
.bg-dark .h2.text-danger,
.bg-dark .h3.text-danger,
.bg-dark .h4.text-danger,
.bg-dark .h5.text-danger,
.bg-dark .h6.text-danger {
  color: #ef4056;
}

.bg-dark p.text-light,
.bg-dark h1.text-light,
.bg-dark h2.text-light,
.bg-dark h3.text-light,
.bg-dark h4.text-light,
.bg-dark h5.text-light,
.bg-dark h6.text-light,
.bg-dark .h1.text-light,
.bg-dark .h2.text-light,
.bg-dark .h3.text-light,
.bg-dark .h4.text-light,
.bg-dark .h5.text-light,
.bg-dark .h6.text-light {
  color: #dee2e6;
}

.bg-dark p.text-dark,
.bg-dark h1.text-dark,
.bg-dark h2.text-dark,
.bg-dark h3.text-dark,
.bg-dark h4.text-dark,
.bg-dark h5.text-dark,
.bg-dark h6.text-dark,
.bg-dark .h1.text-dark,
.bg-dark .h2.text-dark,
.bg-dark .h3.text-dark,
.bg-dark .h4.text-dark,
.bg-dark .h5.text-dark,
.bg-dark .h6.text-dark {
  color: #343a40;
}

.bg-dark p.text-primary-dark,
.bg-dark h1.text-primary-dark,
.bg-dark h2.text-primary-dark,
.bg-dark h3.text-primary-dark,
.bg-dark h4.text-primary-dark,
.bg-dark h5.text-primary-dark,
.bg-dark h6.text-primary-dark,
.bg-dark .h1.text-primary-dark,
.bg-dark .h2.text-primary-dark,
.bg-dark .h3.text-primary-dark,
.bg-dark .h4.text-primary-dark,
.bg-dark .h5.text-primary-dark,
.bg-dark .h6.text-primary-dark {
  color: #0c234b;
}

.bg-dark p.text-primary-light,
.bg-dark h1.text-primary-light,
.bg-dark h2.text-primary-light,
.bg-dark h3.text-primary-light,
.bg-dark h4.text-primary-light,
.bg-dark h5.text-primary-light,
.bg-dark h6.text-primary-light,
.bg-dark .h1.text-primary-light,
.bg-dark .h2.text-primary-light,
.bg-dark .h3.text-primary-light,
.bg-dark .h4.text-primary-light,
.bg-dark .h5.text-primary-light,
.bg-dark .h6.text-primary-light {
  color: #378dbd;
}

.bg-dark p.text-accent,
.bg-dark h1.text-accent,
.bg-dark h2.text-accent,
.bg-dark h3.text-accent,
.bg-dark h4.text-accent,
.bg-dark h5.text-accent,
.bg-dark h6.text-accent,
.bg-dark .h1.text-accent,
.bg-dark .h2.text-accent,
.bg-dark .h3.text-accent,
.bg-dark .h4.text-accent,
.bg-dark .h5.text-accent,
.bg-dark .h6.text-accent {
  color: #ab0520;
}

.bg-primary-dark {
  color: #fff;
  background-color: #0c234b;
}

.bg-primary-dark p,
.bg-primary-dark h1,
.bg-primary-dark h2,
.bg-primary-dark h3,
.bg-primary-dark h4,
.bg-primary-dark h5,
.bg-primary-dark h6,
.bg-primary-dark .h1,
.bg-primary-dark .h2,
.bg-primary-dark .h3,
.bg-primary-dark .h4,
.bg-primary-dark .h5,
.bg-primary-dark .h6 {
  color: #fff;
}

.bg-primary-dark p.text-blue,
.bg-primary-dark h1.text-blue,
.bg-primary-dark h2.text-blue,
.bg-primary-dark h3.text-blue,
.bg-primary-dark h4.text-blue,
.bg-primary-dark h5.text-blue,
.bg-primary-dark h6.text-blue,
.bg-primary-dark .h1.text-blue,
.bg-primary-dark .h2.text-blue,
.bg-primary-dark .h3.text-blue,
.bg-primary-dark .h4.text-blue,
.bg-primary-dark .h5.text-blue,
.bg-primary-dark .h6.text-blue {
  color: #0c234b;
}

.bg-primary-dark p.text-indigo,
.bg-primary-dark h1.text-indigo,
.bg-primary-dark h2.text-indigo,
.bg-primary-dark h3.text-indigo,
.bg-primary-dark h4.text-indigo,
.bg-primary-dark h5.text-indigo,
.bg-primary-dark h6.text-indigo,
.bg-primary-dark .h1.text-indigo,
.bg-primary-dark .h2.text-indigo,
.bg-primary-dark .h3.text-indigo,
.bg-primary-dark .h4.text-indigo,
.bg-primary-dark .h5.text-indigo,
.bg-primary-dark .h6.text-indigo {
  color: #6610f2;
}

.bg-primary-dark p.text-purple,
.bg-primary-dark h1.text-purple,
.bg-primary-dark h2.text-purple,
.bg-primary-dark h3.text-purple,
.bg-primary-dark h4.text-purple,
.bg-primary-dark h5.text-purple,
.bg-primary-dark h6.text-purple,
.bg-primary-dark .h1.text-purple,
.bg-primary-dark .h2.text-purple,
.bg-primary-dark .h3.text-purple,
.bg-primary-dark .h4.text-purple,
.bg-primary-dark .h5.text-purple,
.bg-primary-dark .h6.text-purple {
  color: #6f42c1;
}

.bg-primary-dark p.text-pink,
.bg-primary-dark h1.text-pink,
.bg-primary-dark h2.text-pink,
.bg-primary-dark h3.text-pink,
.bg-primary-dark h4.text-pink,
.bg-primary-dark h5.text-pink,
.bg-primary-dark h6.text-pink,
.bg-primary-dark .h1.text-pink,
.bg-primary-dark .h2.text-pink,
.bg-primary-dark .h3.text-pink,
.bg-primary-dark .h4.text-pink,
.bg-primary-dark .h5.text-pink,
.bg-primary-dark .h6.text-pink {
  color: #e83e8c;
}

.bg-primary-dark p.text-red,
.bg-primary-dark h1.text-red,
.bg-primary-dark h2.text-red,
.bg-primary-dark h3.text-red,
.bg-primary-dark h4.text-red,
.bg-primary-dark h5.text-red,
.bg-primary-dark h6.text-red,
.bg-primary-dark .h1.text-red,
.bg-primary-dark .h2.text-red,
.bg-primary-dark .h3.text-red,
.bg-primary-dark .h4.text-red,
.bg-primary-dark .h5.text-red,
.bg-primary-dark .h6.text-red {
  color: #ab0520;
}

.bg-primary-dark p.text-orange,
.bg-primary-dark h1.text-orange,
.bg-primary-dark h2.text-orange,
.bg-primary-dark h3.text-orange,
.bg-primary-dark h4.text-orange,
.bg-primary-dark h5.text-orange,
.bg-primary-dark h6.text-orange,
.bg-primary-dark .h1.text-orange,
.bg-primary-dark .h2.text-orange,
.bg-primary-dark .h3.text-orange,
.bg-primary-dark .h4.text-orange,
.bg-primary-dark .h5.text-orange,
.bg-primary-dark .h6.text-orange {
  color: #fd7e14;
}

.bg-primary-dark p.text-yellow,
.bg-primary-dark h1.text-yellow,
.bg-primary-dark h2.text-yellow,
.bg-primary-dark h3.text-yellow,
.bg-primary-dark h4.text-yellow,
.bg-primary-dark h5.text-yellow,
.bg-primary-dark h6.text-yellow,
.bg-primary-dark .h1.text-yellow,
.bg-primary-dark .h2.text-yellow,
.bg-primary-dark .h3.text-yellow,
.bg-primary-dark .h4.text-yellow,
.bg-primary-dark .h5.text-yellow,
.bg-primary-dark .h6.text-yellow {
  color: #ffc107;
}

.bg-primary-dark p.text-green,
.bg-primary-dark h1.text-green,
.bg-primary-dark h2.text-green,
.bg-primary-dark h3.text-green,
.bg-primary-dark h4.text-green,
.bg-primary-dark h5.text-green,
.bg-primary-dark h6.text-green,
.bg-primary-dark .h1.text-green,
.bg-primary-dark .h2.text-green,
.bg-primary-dark .h3.text-green,
.bg-primary-dark .h4.text-green,
.bg-primary-dark .h5.text-green,
.bg-primary-dark .h6.text-green {
  color: #28a745;
}

.bg-primary-dark p.text-teal,
.bg-primary-dark h1.text-teal,
.bg-primary-dark h2.text-teal,
.bg-primary-dark h3.text-teal,
.bg-primary-dark h4.text-teal,
.bg-primary-dark h5.text-teal,
.bg-primary-dark h6.text-teal,
.bg-primary-dark .h1.text-teal,
.bg-primary-dark .h2.text-teal,
.bg-primary-dark .h3.text-teal,
.bg-primary-dark .h4.text-teal,
.bg-primary-dark .h5.text-teal,
.bg-primary-dark .h6.text-teal {
  color: #20c997;
}

.bg-primary-dark p.text-cyan,
.bg-primary-dark h1.text-cyan,
.bg-primary-dark h2.text-cyan,
.bg-primary-dark h3.text-cyan,
.bg-primary-dark h4.text-cyan,
.bg-primary-dark h5.text-cyan,
.bg-primary-dark h6.text-cyan,
.bg-primary-dark .h1.text-cyan,
.bg-primary-dark .h2.text-cyan,
.bg-primary-dark .h3.text-cyan,
.bg-primary-dark .h4.text-cyan,
.bg-primary-dark .h5.text-cyan,
.bg-primary-dark .h6.text-cyan {
  color: #17a2b8;
}

.bg-primary-dark p.text-white,
.bg-primary-dark h1.text-white,
.bg-primary-dark h2.text-white,
.bg-primary-dark h3.text-white,
.bg-primary-dark h4.text-white,
.bg-primary-dark h5.text-white,
.bg-primary-dark h6.text-white,
.bg-primary-dark .h1.text-white,
.bg-primary-dark .h2.text-white,
.bg-primary-dark .h3.text-white,
.bg-primary-dark .h4.text-white,
.bg-primary-dark .h5.text-white,
.bg-primary-dark .h6.text-white {
  color: #fff;
}

.bg-primary-dark p.text-gray,
.bg-primary-dark h1.text-gray,
.bg-primary-dark h2.text-gray,
.bg-primary-dark h3.text-gray,
.bg-primary-dark h4.text-gray,
.bg-primary-dark h5.text-gray,
.bg-primary-dark h6.text-gray,
.bg-primary-dark .h1.text-gray,
.bg-primary-dark .h2.text-gray,
.bg-primary-dark .h3.text-gray,
.bg-primary-dark .h4.text-gray,
.bg-primary-dark .h5.text-gray,
.bg-primary-dark .h6.text-gray {
  color: #6c757d;
}

.bg-primary-dark p.text-gray-dark,
.bg-primary-dark h1.text-gray-dark,
.bg-primary-dark h2.text-gray-dark,
.bg-primary-dark h3.text-gray-dark,
.bg-primary-dark h4.text-gray-dark,
.bg-primary-dark h5.text-gray-dark,
.bg-primary-dark h6.text-gray-dark,
.bg-primary-dark .h1.text-gray-dark,
.bg-primary-dark .h2.text-gray-dark,
.bg-primary-dark .h3.text-gray-dark,
.bg-primary-dark .h4.text-gray-dark,
.bg-primary-dark .h5.text-gray-dark,
.bg-primary-dark .h6.text-gray-dark {
  color: #343a40;
}

.bg-primary-dark p.text-bloom,
.bg-primary-dark h1.text-bloom,
.bg-primary-dark h2.text-bloom,
.bg-primary-dark h3.text-bloom,
.bg-primary-dark h4.text-bloom,
.bg-primary-dark h5.text-bloom,
.bg-primary-dark h6.text-bloom,
.bg-primary-dark .h1.text-bloom,
.bg-primary-dark .h2.text-bloom,
.bg-primary-dark .h3.text-bloom,
.bg-primary-dark .h4.text-bloom,
.bg-primary-dark .h5.text-bloom,
.bg-primary-dark .h6.text-bloom {
  color: #ef4056;
}

.bg-primary-dark p.text-chili,
.bg-primary-dark h1.text-chili,
.bg-primary-dark h2.text-chili,
.bg-primary-dark h3.text-chili,
.bg-primary-dark h4.text-chili,
.bg-primary-dark h5.text-chili,
.bg-primary-dark h6.text-chili,
.bg-primary-dark .h1.text-chili,
.bg-primary-dark .h2.text-chili,
.bg-primary-dark .h3.text-chili,
.bg-primary-dark .h4.text-chili,
.bg-primary-dark .h5.text-chili,
.bg-primary-dark .h6.text-chili {
  color: #8b0015;
}

.bg-primary-dark p.text-sky,
.bg-primary-dark h1.text-sky,
.bg-primary-dark h2.text-sky,
.bg-primary-dark h3.text-sky,
.bg-primary-dark h4.text-sky,
.bg-primary-dark h5.text-sky,
.bg-primary-dark h6.text-sky,
.bg-primary-dark .h1.text-sky,
.bg-primary-dark .h2.text-sky,
.bg-primary-dark .h3.text-sky,
.bg-primary-dark .h4.text-sky,
.bg-primary-dark .h5.text-sky,
.bg-primary-dark .h6.text-sky {
  color: #81d3eb;
}

.bg-primary-dark p.text-oasis,
.bg-primary-dark h1.text-oasis,
.bg-primary-dark h2.text-oasis,
.bg-primary-dark h3.text-oasis,
.bg-primary-dark h4.text-oasis,
.bg-primary-dark h5.text-oasis,
.bg-primary-dark h6.text-oasis,
.bg-primary-dark .h1.text-oasis,
.bg-primary-dark .h2.text-oasis,
.bg-primary-dark .h3.text-oasis,
.bg-primary-dark .h4.text-oasis,
.bg-primary-dark .h5.text-oasis,
.bg-primary-dark .h6.text-oasis {
  color: #378dbd;
}

.bg-primary-dark p.text-azurite,
.bg-primary-dark h1.text-azurite,
.bg-primary-dark h2.text-azurite,
.bg-primary-dark h3.text-azurite,
.bg-primary-dark h4.text-azurite,
.bg-primary-dark h5.text-azurite,
.bg-primary-dark h6.text-azurite,
.bg-primary-dark .h1.text-azurite,
.bg-primary-dark .h2.text-azurite,
.bg-primary-dark .h3.text-azurite,
.bg-primary-dark .h4.text-azurite,
.bg-primary-dark .h5.text-azurite,
.bg-primary-dark .h6.text-azurite {
  color: #1e5288;
}

.bg-primary-dark p.text-midnight,
.bg-primary-dark h1.text-midnight,
.bg-primary-dark h2.text-midnight,
.bg-primary-dark h3.text-midnight,
.bg-primary-dark h4.text-midnight,
.bg-primary-dark h5.text-midnight,
.bg-primary-dark h6.text-midnight,
.bg-primary-dark .h1.text-midnight,
.bg-primary-dark .h2.text-midnight,
.bg-primary-dark .h3.text-midnight,
.bg-primary-dark .h4.text-midnight,
.bg-primary-dark .h5.text-midnight,
.bg-primary-dark .h6.text-midnight {
  color: #001c48;
}

.bg-primary-dark p.text-cool-gray,
.bg-primary-dark h1.text-cool-gray,
.bg-primary-dark h2.text-cool-gray,
.bg-primary-dark h3.text-cool-gray,
.bg-primary-dark h4.text-cool-gray,
.bg-primary-dark h5.text-cool-gray,
.bg-primary-dark h6.text-cool-gray,
.bg-primary-dark .h1.text-cool-gray,
.bg-primary-dark .h2.text-cool-gray,
.bg-primary-dark .h3.text-cool-gray,
.bg-primary-dark .h4.text-cool-gray,
.bg-primary-dark .h5.text-cool-gray,
.bg-primary-dark .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-primary-dark p.text-warm-gray,
.bg-primary-dark h1.text-warm-gray,
.bg-primary-dark h2.text-warm-gray,
.bg-primary-dark h3.text-warm-gray,
.bg-primary-dark h4.text-warm-gray,
.bg-primary-dark h5.text-warm-gray,
.bg-primary-dark h6.text-warm-gray,
.bg-primary-dark .h1.text-warm-gray,
.bg-primary-dark .h2.text-warm-gray,
.bg-primary-dark .h3.text-warm-gray,
.bg-primary-dark .h4.text-warm-gray,
.bg-primary-dark .h5.text-warm-gray,
.bg-primary-dark .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-primary-dark p.text-leaf,
.bg-primary-dark h1.text-leaf,
.bg-primary-dark h2.text-leaf,
.bg-primary-dark h3.text-leaf,
.bg-primary-dark h4.text-leaf,
.bg-primary-dark h5.text-leaf,
.bg-primary-dark h6.text-leaf,
.bg-primary-dark .h1.text-leaf,
.bg-primary-dark .h2.text-leaf,
.bg-primary-dark .h3.text-leaf,
.bg-primary-dark .h4.text-leaf,
.bg-primary-dark .h5.text-leaf,
.bg-primary-dark .h6.text-leaf {
  color: #70b865;
}

.bg-primary-dark p.text-river,
.bg-primary-dark h1.text-river,
.bg-primary-dark h2.text-river,
.bg-primary-dark h3.text-river,
.bg-primary-dark h4.text-river,
.bg-primary-dark h5.text-river,
.bg-primary-dark h6.text-river,
.bg-primary-dark .h1.text-river,
.bg-primary-dark .h2.text-river,
.bg-primary-dark .h3.text-river,
.bg-primary-dark .h4.text-river,
.bg-primary-dark .h5.text-river,
.bg-primary-dark .h6.text-river {
  color: #007d84;
}

.bg-primary-dark p.text-silver,
.bg-primary-dark h1.text-silver,
.bg-primary-dark h2.text-silver,
.bg-primary-dark h3.text-silver,
.bg-primary-dark h4.text-silver,
.bg-primary-dark h5.text-silver,
.bg-primary-dark h6.text-silver,
.bg-primary-dark .h1.text-silver,
.bg-primary-dark .h2.text-silver,
.bg-primary-dark .h3.text-silver,
.bg-primary-dark .h4.text-silver,
.bg-primary-dark .h5.text-silver,
.bg-primary-dark .h6.text-silver {
  color: #9eabae;
}

.bg-primary-dark p.text-mesa,
.bg-primary-dark h1.text-mesa,
.bg-primary-dark h2.text-mesa,
.bg-primary-dark h3.text-mesa,
.bg-primary-dark h4.text-mesa,
.bg-primary-dark h5.text-mesa,
.bg-primary-dark h6.text-mesa,
.bg-primary-dark .h1.text-mesa,
.bg-primary-dark .h2.text-mesa,
.bg-primary-dark .h3.text-mesa,
.bg-primary-dark .h4.text-mesa,
.bg-primary-dark .h5.text-mesa,
.bg-primary-dark .h6.text-mesa {
  color: #a95c42;
}

.bg-primary-dark p.text-ash,
.bg-primary-dark h1.text-ash,
.bg-primary-dark h2.text-ash,
.bg-primary-dark h3.text-ash,
.bg-primary-dark h4.text-ash,
.bg-primary-dark h5.text-ash,
.bg-primary-dark h6.text-ash,
.bg-primary-dark .h1.text-ash,
.bg-primary-dark .h2.text-ash,
.bg-primary-dark .h3.text-ash,
.bg-primary-dark .h4.text-ash,
.bg-primary-dark .h5.text-ash,
.bg-primary-dark .h6.text-ash {
  color: #403635;
}

.bg-primary-dark p.text-sage,
.bg-primary-dark h1.text-sage,
.bg-primary-dark h2.text-sage,
.bg-primary-dark h3.text-sage,
.bg-primary-dark h4.text-sage,
.bg-primary-dark h5.text-sage,
.bg-primary-dark h6.text-sage,
.bg-primary-dark .h1.text-sage,
.bg-primary-dark .h2.text-sage,
.bg-primary-dark .h3.text-sage,
.bg-primary-dark .h4.text-sage,
.bg-primary-dark .h5.text-sage,
.bg-primary-dark .h6.text-sage {
  color: #4a634e;
}

.bg-primary-dark p.text-black,
.bg-primary-dark h1.text-black,
.bg-primary-dark h2.text-black,
.bg-primary-dark h3.text-black,
.bg-primary-dark h4.text-black,
.bg-primary-dark h5.text-black,
.bg-primary-dark h6.text-black,
.bg-primary-dark .h1.text-black,
.bg-primary-dark .h2.text-black,
.bg-primary-dark .h3.text-black,
.bg-primary-dark .h4.text-black,
.bg-primary-dark .h5.text-black,
.bg-primary-dark .h6.text-black {
  color: #000;
}

.bg-primary-dark p.text-primary,
.bg-primary-dark h1.text-primary,
.bg-primary-dark h2.text-primary,
.bg-primary-dark h3.text-primary,
.bg-primary-dark h4.text-primary,
.bg-primary-dark h5.text-primary,
.bg-primary-dark h6.text-primary,
.bg-primary-dark .h1.text-primary,
.bg-primary-dark .h2.text-primary,
.bg-primary-dark .h3.text-primary,
.bg-primary-dark .h4.text-primary,
.bg-primary-dark .h5.text-primary,
.bg-primary-dark .h6.text-primary {
  color: #1e5288;
}

.bg-primary-dark p.text-secondary,
.bg-primary-dark h1.text-secondary,
.bg-primary-dark h2.text-secondary,
.bg-primary-dark h3.text-secondary,
.bg-primary-dark h4.text-secondary,
.bg-primary-dark h5.text-secondary,
.bg-primary-dark h6.text-secondary,
.bg-primary-dark .h1.text-secondary,
.bg-primary-dark .h2.text-secondary,
.bg-primary-dark .h3.text-secondary,
.bg-primary-dark .h4.text-secondary,
.bg-primary-dark .h5.text-secondary,
.bg-primary-dark .h6.text-secondary {
  color: #9eabae;
}

.bg-primary-dark p.text-success,
.bg-primary-dark h1.text-success,
.bg-primary-dark h2.text-success,
.bg-primary-dark h3.text-success,
.bg-primary-dark h4.text-success,
.bg-primary-dark h5.text-success,
.bg-primary-dark h6.text-success,
.bg-primary-dark .h1.text-success,
.bg-primary-dark .h2.text-success,
.bg-primary-dark .h3.text-success,
.bg-primary-dark .h4.text-success,
.bg-primary-dark .h5.text-success,
.bg-primary-dark .h6.text-success {
  color: #70b865;
}

.bg-primary-dark p.text-info,
.bg-primary-dark h1.text-info,
.bg-primary-dark h2.text-info,
.bg-primary-dark h3.text-info,
.bg-primary-dark h4.text-info,
.bg-primary-dark h5.text-info,
.bg-primary-dark h6.text-info,
.bg-primary-dark .h1.text-info,
.bg-primary-dark .h2.text-info,
.bg-primary-dark .h3.text-info,
.bg-primary-dark .h4.text-info,
.bg-primary-dark .h5.text-info,
.bg-primary-dark .h6.text-info {
  color: #81d3eb;
}

.bg-primary-dark p.text-warning,
.bg-primary-dark h1.text-warning,
.bg-primary-dark h2.text-warning,
.bg-primary-dark h3.text-warning,
.bg-primary-dark h4.text-warning,
.bg-primary-dark h5.text-warning,
.bg-primary-dark h6.text-warning,
.bg-primary-dark .h1.text-warning,
.bg-primary-dark .h2.text-warning,
.bg-primary-dark .h3.text-warning,
.bg-primary-dark .h4.text-warning,
.bg-primary-dark .h5.text-warning,
.bg-primary-dark .h6.text-warning {
  color: #f19e1f;
}

.bg-primary-dark p.text-danger,
.bg-primary-dark h1.text-danger,
.bg-primary-dark h2.text-danger,
.bg-primary-dark h3.text-danger,
.bg-primary-dark h4.text-danger,
.bg-primary-dark h5.text-danger,
.bg-primary-dark h6.text-danger,
.bg-primary-dark .h1.text-danger,
.bg-primary-dark .h2.text-danger,
.bg-primary-dark .h3.text-danger,
.bg-primary-dark .h4.text-danger,
.bg-primary-dark .h5.text-danger,
.bg-primary-dark .h6.text-danger {
  color: #ef4056;
}

.bg-primary-dark p.text-light,
.bg-primary-dark h1.text-light,
.bg-primary-dark h2.text-light,
.bg-primary-dark h3.text-light,
.bg-primary-dark h4.text-light,
.bg-primary-dark h5.text-light,
.bg-primary-dark h6.text-light,
.bg-primary-dark .h1.text-light,
.bg-primary-dark .h2.text-light,
.bg-primary-dark .h3.text-light,
.bg-primary-dark .h4.text-light,
.bg-primary-dark .h5.text-light,
.bg-primary-dark .h6.text-light {
  color: #dee2e6;
}

.bg-primary-dark p.text-dark,
.bg-primary-dark h1.text-dark,
.bg-primary-dark h2.text-dark,
.bg-primary-dark h3.text-dark,
.bg-primary-dark h4.text-dark,
.bg-primary-dark h5.text-dark,
.bg-primary-dark h6.text-dark,
.bg-primary-dark .h1.text-dark,
.bg-primary-dark .h2.text-dark,
.bg-primary-dark .h3.text-dark,
.bg-primary-dark .h4.text-dark,
.bg-primary-dark .h5.text-dark,
.bg-primary-dark .h6.text-dark {
  color: #343a40;
}

.bg-primary-dark p.text-primary-dark,
.bg-primary-dark h1.text-primary-dark,
.bg-primary-dark h2.text-primary-dark,
.bg-primary-dark h3.text-primary-dark,
.bg-primary-dark h4.text-primary-dark,
.bg-primary-dark h5.text-primary-dark,
.bg-primary-dark h6.text-primary-dark,
.bg-primary-dark .h1.text-primary-dark,
.bg-primary-dark .h2.text-primary-dark,
.bg-primary-dark .h3.text-primary-dark,
.bg-primary-dark .h4.text-primary-dark,
.bg-primary-dark .h5.text-primary-dark,
.bg-primary-dark .h6.text-primary-dark {
  color: #0c234b;
}

.bg-primary-dark p.text-primary-light,
.bg-primary-dark h1.text-primary-light,
.bg-primary-dark h2.text-primary-light,
.bg-primary-dark h3.text-primary-light,
.bg-primary-dark h4.text-primary-light,
.bg-primary-dark h5.text-primary-light,
.bg-primary-dark h6.text-primary-light,
.bg-primary-dark .h1.text-primary-light,
.bg-primary-dark .h2.text-primary-light,
.bg-primary-dark .h3.text-primary-light,
.bg-primary-dark .h4.text-primary-light,
.bg-primary-dark .h5.text-primary-light,
.bg-primary-dark .h6.text-primary-light {
  color: #378dbd;
}

.bg-primary-dark p.text-accent,
.bg-primary-dark h1.text-accent,
.bg-primary-dark h2.text-accent,
.bg-primary-dark h3.text-accent,
.bg-primary-dark h4.text-accent,
.bg-primary-dark h5.text-accent,
.bg-primary-dark h6.text-accent,
.bg-primary-dark .h1.text-accent,
.bg-primary-dark .h2.text-accent,
.bg-primary-dark .h3.text-accent,
.bg-primary-dark .h4.text-accent,
.bg-primary-dark .h5.text-accent,
.bg-primary-dark .h6.text-accent {
  color: #ab0520;
}

.bg-primary-light {
  color: #fff;
  background-color: #378dbd;
}

.bg-primary-light p,
.bg-primary-light h1,
.bg-primary-light h2,
.bg-primary-light h3,
.bg-primary-light h4,
.bg-primary-light h5,
.bg-primary-light h6,
.bg-primary-light .h1,
.bg-primary-light .h2,
.bg-primary-light .h3,
.bg-primary-light .h4,
.bg-primary-light .h5,
.bg-primary-light .h6 {
  color: #fff;
}

.bg-primary-light p.text-blue,
.bg-primary-light h1.text-blue,
.bg-primary-light h2.text-blue,
.bg-primary-light h3.text-blue,
.bg-primary-light h4.text-blue,
.bg-primary-light h5.text-blue,
.bg-primary-light h6.text-blue,
.bg-primary-light .h1.text-blue,
.bg-primary-light .h2.text-blue,
.bg-primary-light .h3.text-blue,
.bg-primary-light .h4.text-blue,
.bg-primary-light .h5.text-blue,
.bg-primary-light .h6.text-blue {
  color: #0c234b;
}

.bg-primary-light p.text-indigo,
.bg-primary-light h1.text-indigo,
.bg-primary-light h2.text-indigo,
.bg-primary-light h3.text-indigo,
.bg-primary-light h4.text-indigo,
.bg-primary-light h5.text-indigo,
.bg-primary-light h6.text-indigo,
.bg-primary-light .h1.text-indigo,
.bg-primary-light .h2.text-indigo,
.bg-primary-light .h3.text-indigo,
.bg-primary-light .h4.text-indigo,
.bg-primary-light .h5.text-indigo,
.bg-primary-light .h6.text-indigo {
  color: #6610f2;
}

.bg-primary-light p.text-purple,
.bg-primary-light h1.text-purple,
.bg-primary-light h2.text-purple,
.bg-primary-light h3.text-purple,
.bg-primary-light h4.text-purple,
.bg-primary-light h5.text-purple,
.bg-primary-light h6.text-purple,
.bg-primary-light .h1.text-purple,
.bg-primary-light .h2.text-purple,
.bg-primary-light .h3.text-purple,
.bg-primary-light .h4.text-purple,
.bg-primary-light .h5.text-purple,
.bg-primary-light .h6.text-purple {
  color: #6f42c1;
}

.bg-primary-light p.text-pink,
.bg-primary-light h1.text-pink,
.bg-primary-light h2.text-pink,
.bg-primary-light h3.text-pink,
.bg-primary-light h4.text-pink,
.bg-primary-light h5.text-pink,
.bg-primary-light h6.text-pink,
.bg-primary-light .h1.text-pink,
.bg-primary-light .h2.text-pink,
.bg-primary-light .h3.text-pink,
.bg-primary-light .h4.text-pink,
.bg-primary-light .h5.text-pink,
.bg-primary-light .h6.text-pink {
  color: #e83e8c;
}

.bg-primary-light p.text-red,
.bg-primary-light h1.text-red,
.bg-primary-light h2.text-red,
.bg-primary-light h3.text-red,
.bg-primary-light h4.text-red,
.bg-primary-light h5.text-red,
.bg-primary-light h6.text-red,
.bg-primary-light .h1.text-red,
.bg-primary-light .h2.text-red,
.bg-primary-light .h3.text-red,
.bg-primary-light .h4.text-red,
.bg-primary-light .h5.text-red,
.bg-primary-light .h6.text-red {
  color: #ab0520;
}

.bg-primary-light p.text-orange,
.bg-primary-light h1.text-orange,
.bg-primary-light h2.text-orange,
.bg-primary-light h3.text-orange,
.bg-primary-light h4.text-orange,
.bg-primary-light h5.text-orange,
.bg-primary-light h6.text-orange,
.bg-primary-light .h1.text-orange,
.bg-primary-light .h2.text-orange,
.bg-primary-light .h3.text-orange,
.bg-primary-light .h4.text-orange,
.bg-primary-light .h5.text-orange,
.bg-primary-light .h6.text-orange {
  color: #fd7e14;
}

.bg-primary-light p.text-yellow,
.bg-primary-light h1.text-yellow,
.bg-primary-light h2.text-yellow,
.bg-primary-light h3.text-yellow,
.bg-primary-light h4.text-yellow,
.bg-primary-light h5.text-yellow,
.bg-primary-light h6.text-yellow,
.bg-primary-light .h1.text-yellow,
.bg-primary-light .h2.text-yellow,
.bg-primary-light .h3.text-yellow,
.bg-primary-light .h4.text-yellow,
.bg-primary-light .h5.text-yellow,
.bg-primary-light .h6.text-yellow {
  color: #ffc107;
}

.bg-primary-light p.text-green,
.bg-primary-light h1.text-green,
.bg-primary-light h2.text-green,
.bg-primary-light h3.text-green,
.bg-primary-light h4.text-green,
.bg-primary-light h5.text-green,
.bg-primary-light h6.text-green,
.bg-primary-light .h1.text-green,
.bg-primary-light .h2.text-green,
.bg-primary-light .h3.text-green,
.bg-primary-light .h4.text-green,
.bg-primary-light .h5.text-green,
.bg-primary-light .h6.text-green {
  color: #28a745;
}

.bg-primary-light p.text-teal,
.bg-primary-light h1.text-teal,
.bg-primary-light h2.text-teal,
.bg-primary-light h3.text-teal,
.bg-primary-light h4.text-teal,
.bg-primary-light h5.text-teal,
.bg-primary-light h6.text-teal,
.bg-primary-light .h1.text-teal,
.bg-primary-light .h2.text-teal,
.bg-primary-light .h3.text-teal,
.bg-primary-light .h4.text-teal,
.bg-primary-light .h5.text-teal,
.bg-primary-light .h6.text-teal {
  color: #20c997;
}

.bg-primary-light p.text-cyan,
.bg-primary-light h1.text-cyan,
.bg-primary-light h2.text-cyan,
.bg-primary-light h3.text-cyan,
.bg-primary-light h4.text-cyan,
.bg-primary-light h5.text-cyan,
.bg-primary-light h6.text-cyan,
.bg-primary-light .h1.text-cyan,
.bg-primary-light .h2.text-cyan,
.bg-primary-light .h3.text-cyan,
.bg-primary-light .h4.text-cyan,
.bg-primary-light .h5.text-cyan,
.bg-primary-light .h6.text-cyan {
  color: #17a2b8;
}

.bg-primary-light p.text-white,
.bg-primary-light h1.text-white,
.bg-primary-light h2.text-white,
.bg-primary-light h3.text-white,
.bg-primary-light h4.text-white,
.bg-primary-light h5.text-white,
.bg-primary-light h6.text-white,
.bg-primary-light .h1.text-white,
.bg-primary-light .h2.text-white,
.bg-primary-light .h3.text-white,
.bg-primary-light .h4.text-white,
.bg-primary-light .h5.text-white,
.bg-primary-light .h6.text-white {
  color: #fff;
}

.bg-primary-light p.text-gray,
.bg-primary-light h1.text-gray,
.bg-primary-light h2.text-gray,
.bg-primary-light h3.text-gray,
.bg-primary-light h4.text-gray,
.bg-primary-light h5.text-gray,
.bg-primary-light h6.text-gray,
.bg-primary-light .h1.text-gray,
.bg-primary-light .h2.text-gray,
.bg-primary-light .h3.text-gray,
.bg-primary-light .h4.text-gray,
.bg-primary-light .h5.text-gray,
.bg-primary-light .h6.text-gray {
  color: #6c757d;
}

.bg-primary-light p.text-gray-dark,
.bg-primary-light h1.text-gray-dark,
.bg-primary-light h2.text-gray-dark,
.bg-primary-light h3.text-gray-dark,
.bg-primary-light h4.text-gray-dark,
.bg-primary-light h5.text-gray-dark,
.bg-primary-light h6.text-gray-dark,
.bg-primary-light .h1.text-gray-dark,
.bg-primary-light .h2.text-gray-dark,
.bg-primary-light .h3.text-gray-dark,
.bg-primary-light .h4.text-gray-dark,
.bg-primary-light .h5.text-gray-dark,
.bg-primary-light .h6.text-gray-dark {
  color: #343a40;
}

.bg-primary-light p.text-bloom,
.bg-primary-light h1.text-bloom,
.bg-primary-light h2.text-bloom,
.bg-primary-light h3.text-bloom,
.bg-primary-light h4.text-bloom,
.bg-primary-light h5.text-bloom,
.bg-primary-light h6.text-bloom,
.bg-primary-light .h1.text-bloom,
.bg-primary-light .h2.text-bloom,
.bg-primary-light .h3.text-bloom,
.bg-primary-light .h4.text-bloom,
.bg-primary-light .h5.text-bloom,
.bg-primary-light .h6.text-bloom {
  color: #ef4056;
}

.bg-primary-light p.text-chili,
.bg-primary-light h1.text-chili,
.bg-primary-light h2.text-chili,
.bg-primary-light h3.text-chili,
.bg-primary-light h4.text-chili,
.bg-primary-light h5.text-chili,
.bg-primary-light h6.text-chili,
.bg-primary-light .h1.text-chili,
.bg-primary-light .h2.text-chili,
.bg-primary-light .h3.text-chili,
.bg-primary-light .h4.text-chili,
.bg-primary-light .h5.text-chili,
.bg-primary-light .h6.text-chili {
  color: #8b0015;
}

.bg-primary-light p.text-sky,
.bg-primary-light h1.text-sky,
.bg-primary-light h2.text-sky,
.bg-primary-light h3.text-sky,
.bg-primary-light h4.text-sky,
.bg-primary-light h5.text-sky,
.bg-primary-light h6.text-sky,
.bg-primary-light .h1.text-sky,
.bg-primary-light .h2.text-sky,
.bg-primary-light .h3.text-sky,
.bg-primary-light .h4.text-sky,
.bg-primary-light .h5.text-sky,
.bg-primary-light .h6.text-sky {
  color: #81d3eb;
}

.bg-primary-light p.text-oasis,
.bg-primary-light h1.text-oasis,
.bg-primary-light h2.text-oasis,
.bg-primary-light h3.text-oasis,
.bg-primary-light h4.text-oasis,
.bg-primary-light h5.text-oasis,
.bg-primary-light h6.text-oasis,
.bg-primary-light .h1.text-oasis,
.bg-primary-light .h2.text-oasis,
.bg-primary-light .h3.text-oasis,
.bg-primary-light .h4.text-oasis,
.bg-primary-light .h5.text-oasis,
.bg-primary-light .h6.text-oasis {
  color: #378dbd;
}

.bg-primary-light p.text-azurite,
.bg-primary-light h1.text-azurite,
.bg-primary-light h2.text-azurite,
.bg-primary-light h3.text-azurite,
.bg-primary-light h4.text-azurite,
.bg-primary-light h5.text-azurite,
.bg-primary-light h6.text-azurite,
.bg-primary-light .h1.text-azurite,
.bg-primary-light .h2.text-azurite,
.bg-primary-light .h3.text-azurite,
.bg-primary-light .h4.text-azurite,
.bg-primary-light .h5.text-azurite,
.bg-primary-light .h6.text-azurite {
  color: #1e5288;
}

.bg-primary-light p.text-midnight,
.bg-primary-light h1.text-midnight,
.bg-primary-light h2.text-midnight,
.bg-primary-light h3.text-midnight,
.bg-primary-light h4.text-midnight,
.bg-primary-light h5.text-midnight,
.bg-primary-light h6.text-midnight,
.bg-primary-light .h1.text-midnight,
.bg-primary-light .h2.text-midnight,
.bg-primary-light .h3.text-midnight,
.bg-primary-light .h4.text-midnight,
.bg-primary-light .h5.text-midnight,
.bg-primary-light .h6.text-midnight {
  color: #001c48;
}

.bg-primary-light p.text-cool-gray,
.bg-primary-light h1.text-cool-gray,
.bg-primary-light h2.text-cool-gray,
.bg-primary-light h3.text-cool-gray,
.bg-primary-light h4.text-cool-gray,
.bg-primary-light h5.text-cool-gray,
.bg-primary-light h6.text-cool-gray,
.bg-primary-light .h1.text-cool-gray,
.bg-primary-light .h2.text-cool-gray,
.bg-primary-light .h3.text-cool-gray,
.bg-primary-light .h4.text-cool-gray,
.bg-primary-light .h5.text-cool-gray,
.bg-primary-light .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-primary-light p.text-warm-gray,
.bg-primary-light h1.text-warm-gray,
.bg-primary-light h2.text-warm-gray,
.bg-primary-light h3.text-warm-gray,
.bg-primary-light h4.text-warm-gray,
.bg-primary-light h5.text-warm-gray,
.bg-primary-light h6.text-warm-gray,
.bg-primary-light .h1.text-warm-gray,
.bg-primary-light .h2.text-warm-gray,
.bg-primary-light .h3.text-warm-gray,
.bg-primary-light .h4.text-warm-gray,
.bg-primary-light .h5.text-warm-gray,
.bg-primary-light .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-primary-light p.text-leaf,
.bg-primary-light h1.text-leaf,
.bg-primary-light h2.text-leaf,
.bg-primary-light h3.text-leaf,
.bg-primary-light h4.text-leaf,
.bg-primary-light h5.text-leaf,
.bg-primary-light h6.text-leaf,
.bg-primary-light .h1.text-leaf,
.bg-primary-light .h2.text-leaf,
.bg-primary-light .h3.text-leaf,
.bg-primary-light .h4.text-leaf,
.bg-primary-light .h5.text-leaf,
.bg-primary-light .h6.text-leaf {
  color: #70b865;
}

.bg-primary-light p.text-river,
.bg-primary-light h1.text-river,
.bg-primary-light h2.text-river,
.bg-primary-light h3.text-river,
.bg-primary-light h4.text-river,
.bg-primary-light h5.text-river,
.bg-primary-light h6.text-river,
.bg-primary-light .h1.text-river,
.bg-primary-light .h2.text-river,
.bg-primary-light .h3.text-river,
.bg-primary-light .h4.text-river,
.bg-primary-light .h5.text-river,
.bg-primary-light .h6.text-river {
  color: #007d84;
}

.bg-primary-light p.text-silver,
.bg-primary-light h1.text-silver,
.bg-primary-light h2.text-silver,
.bg-primary-light h3.text-silver,
.bg-primary-light h4.text-silver,
.bg-primary-light h5.text-silver,
.bg-primary-light h6.text-silver,
.bg-primary-light .h1.text-silver,
.bg-primary-light .h2.text-silver,
.bg-primary-light .h3.text-silver,
.bg-primary-light .h4.text-silver,
.bg-primary-light .h5.text-silver,
.bg-primary-light .h6.text-silver {
  color: #9eabae;
}

.bg-primary-light p.text-mesa,
.bg-primary-light h1.text-mesa,
.bg-primary-light h2.text-mesa,
.bg-primary-light h3.text-mesa,
.bg-primary-light h4.text-mesa,
.bg-primary-light h5.text-mesa,
.bg-primary-light h6.text-mesa,
.bg-primary-light .h1.text-mesa,
.bg-primary-light .h2.text-mesa,
.bg-primary-light .h3.text-mesa,
.bg-primary-light .h4.text-mesa,
.bg-primary-light .h5.text-mesa,
.bg-primary-light .h6.text-mesa {
  color: #a95c42;
}

.bg-primary-light p.text-ash,
.bg-primary-light h1.text-ash,
.bg-primary-light h2.text-ash,
.bg-primary-light h3.text-ash,
.bg-primary-light h4.text-ash,
.bg-primary-light h5.text-ash,
.bg-primary-light h6.text-ash,
.bg-primary-light .h1.text-ash,
.bg-primary-light .h2.text-ash,
.bg-primary-light .h3.text-ash,
.bg-primary-light .h4.text-ash,
.bg-primary-light .h5.text-ash,
.bg-primary-light .h6.text-ash {
  color: #403635;
}

.bg-primary-light p.text-sage,
.bg-primary-light h1.text-sage,
.bg-primary-light h2.text-sage,
.bg-primary-light h3.text-sage,
.bg-primary-light h4.text-sage,
.bg-primary-light h5.text-sage,
.bg-primary-light h6.text-sage,
.bg-primary-light .h1.text-sage,
.bg-primary-light .h2.text-sage,
.bg-primary-light .h3.text-sage,
.bg-primary-light .h4.text-sage,
.bg-primary-light .h5.text-sage,
.bg-primary-light .h6.text-sage {
  color: #4a634e;
}

.bg-primary-light p.text-black,
.bg-primary-light h1.text-black,
.bg-primary-light h2.text-black,
.bg-primary-light h3.text-black,
.bg-primary-light h4.text-black,
.bg-primary-light h5.text-black,
.bg-primary-light h6.text-black,
.bg-primary-light .h1.text-black,
.bg-primary-light .h2.text-black,
.bg-primary-light .h3.text-black,
.bg-primary-light .h4.text-black,
.bg-primary-light .h5.text-black,
.bg-primary-light .h6.text-black {
  color: #000;
}

.bg-primary-light p.text-primary,
.bg-primary-light h1.text-primary,
.bg-primary-light h2.text-primary,
.bg-primary-light h3.text-primary,
.bg-primary-light h4.text-primary,
.bg-primary-light h5.text-primary,
.bg-primary-light h6.text-primary,
.bg-primary-light .h1.text-primary,
.bg-primary-light .h2.text-primary,
.bg-primary-light .h3.text-primary,
.bg-primary-light .h4.text-primary,
.bg-primary-light .h5.text-primary,
.bg-primary-light .h6.text-primary {
  color: #1e5288;
}

.bg-primary-light p.text-secondary,
.bg-primary-light h1.text-secondary,
.bg-primary-light h2.text-secondary,
.bg-primary-light h3.text-secondary,
.bg-primary-light h4.text-secondary,
.bg-primary-light h5.text-secondary,
.bg-primary-light h6.text-secondary,
.bg-primary-light .h1.text-secondary,
.bg-primary-light .h2.text-secondary,
.bg-primary-light .h3.text-secondary,
.bg-primary-light .h4.text-secondary,
.bg-primary-light .h5.text-secondary,
.bg-primary-light .h6.text-secondary {
  color: #9eabae;
}

.bg-primary-light p.text-success,
.bg-primary-light h1.text-success,
.bg-primary-light h2.text-success,
.bg-primary-light h3.text-success,
.bg-primary-light h4.text-success,
.bg-primary-light h5.text-success,
.bg-primary-light h6.text-success,
.bg-primary-light .h1.text-success,
.bg-primary-light .h2.text-success,
.bg-primary-light .h3.text-success,
.bg-primary-light .h4.text-success,
.bg-primary-light .h5.text-success,
.bg-primary-light .h6.text-success {
  color: #70b865;
}

.bg-primary-light p.text-info,
.bg-primary-light h1.text-info,
.bg-primary-light h2.text-info,
.bg-primary-light h3.text-info,
.bg-primary-light h4.text-info,
.bg-primary-light h5.text-info,
.bg-primary-light h6.text-info,
.bg-primary-light .h1.text-info,
.bg-primary-light .h2.text-info,
.bg-primary-light .h3.text-info,
.bg-primary-light .h4.text-info,
.bg-primary-light .h5.text-info,
.bg-primary-light .h6.text-info {
  color: #81d3eb;
}

.bg-primary-light p.text-warning,
.bg-primary-light h1.text-warning,
.bg-primary-light h2.text-warning,
.bg-primary-light h3.text-warning,
.bg-primary-light h4.text-warning,
.bg-primary-light h5.text-warning,
.bg-primary-light h6.text-warning,
.bg-primary-light .h1.text-warning,
.bg-primary-light .h2.text-warning,
.bg-primary-light .h3.text-warning,
.bg-primary-light .h4.text-warning,
.bg-primary-light .h5.text-warning,
.bg-primary-light .h6.text-warning {
  color: #f19e1f;
}

.bg-primary-light p.text-danger,
.bg-primary-light h1.text-danger,
.bg-primary-light h2.text-danger,
.bg-primary-light h3.text-danger,
.bg-primary-light h4.text-danger,
.bg-primary-light h5.text-danger,
.bg-primary-light h6.text-danger,
.bg-primary-light .h1.text-danger,
.bg-primary-light .h2.text-danger,
.bg-primary-light .h3.text-danger,
.bg-primary-light .h4.text-danger,
.bg-primary-light .h5.text-danger,
.bg-primary-light .h6.text-danger {
  color: #ef4056;
}

.bg-primary-light p.text-light,
.bg-primary-light h1.text-light,
.bg-primary-light h2.text-light,
.bg-primary-light h3.text-light,
.bg-primary-light h4.text-light,
.bg-primary-light h5.text-light,
.bg-primary-light h6.text-light,
.bg-primary-light .h1.text-light,
.bg-primary-light .h2.text-light,
.bg-primary-light .h3.text-light,
.bg-primary-light .h4.text-light,
.bg-primary-light .h5.text-light,
.bg-primary-light .h6.text-light {
  color: #dee2e6;
}

.bg-primary-light p.text-dark,
.bg-primary-light h1.text-dark,
.bg-primary-light h2.text-dark,
.bg-primary-light h3.text-dark,
.bg-primary-light h4.text-dark,
.bg-primary-light h5.text-dark,
.bg-primary-light h6.text-dark,
.bg-primary-light .h1.text-dark,
.bg-primary-light .h2.text-dark,
.bg-primary-light .h3.text-dark,
.bg-primary-light .h4.text-dark,
.bg-primary-light .h5.text-dark,
.bg-primary-light .h6.text-dark {
  color: #343a40;
}

.bg-primary-light p.text-primary-dark,
.bg-primary-light h1.text-primary-dark,
.bg-primary-light h2.text-primary-dark,
.bg-primary-light h3.text-primary-dark,
.bg-primary-light h4.text-primary-dark,
.bg-primary-light h5.text-primary-dark,
.bg-primary-light h6.text-primary-dark,
.bg-primary-light .h1.text-primary-dark,
.bg-primary-light .h2.text-primary-dark,
.bg-primary-light .h3.text-primary-dark,
.bg-primary-light .h4.text-primary-dark,
.bg-primary-light .h5.text-primary-dark,
.bg-primary-light .h6.text-primary-dark {
  color: #0c234b;
}

.bg-primary-light p.text-primary-light,
.bg-primary-light h1.text-primary-light,
.bg-primary-light h2.text-primary-light,
.bg-primary-light h3.text-primary-light,
.bg-primary-light h4.text-primary-light,
.bg-primary-light h5.text-primary-light,
.bg-primary-light h6.text-primary-light,
.bg-primary-light .h1.text-primary-light,
.bg-primary-light .h2.text-primary-light,
.bg-primary-light .h3.text-primary-light,
.bg-primary-light .h4.text-primary-light,
.bg-primary-light .h5.text-primary-light,
.bg-primary-light .h6.text-primary-light {
  color: #378dbd;
}

.bg-primary-light p.text-accent,
.bg-primary-light h1.text-accent,
.bg-primary-light h2.text-accent,
.bg-primary-light h3.text-accent,
.bg-primary-light h4.text-accent,
.bg-primary-light h5.text-accent,
.bg-primary-light h6.text-accent,
.bg-primary-light .h1.text-accent,
.bg-primary-light .h2.text-accent,
.bg-primary-light .h3.text-accent,
.bg-primary-light .h4.text-accent,
.bg-primary-light .h5.text-accent,
.bg-primary-light .h6.text-accent {
  color: #ab0520;
}

.bg-accent {
  color: #fff;
  background-color: #ab0520;
}

.bg-accent p,
.bg-accent h1,
.bg-accent h2,
.bg-accent h3,
.bg-accent h4,
.bg-accent h5,
.bg-accent h6,
.bg-accent .h1,
.bg-accent .h2,
.bg-accent .h3,
.bg-accent .h4,
.bg-accent .h5,
.bg-accent .h6 {
  color: #fff;
}

.bg-accent p.text-blue,
.bg-accent h1.text-blue,
.bg-accent h2.text-blue,
.bg-accent h3.text-blue,
.bg-accent h4.text-blue,
.bg-accent h5.text-blue,
.bg-accent h6.text-blue,
.bg-accent .h1.text-blue,
.bg-accent .h2.text-blue,
.bg-accent .h3.text-blue,
.bg-accent .h4.text-blue,
.bg-accent .h5.text-blue,
.bg-accent .h6.text-blue {
  color: #0c234b;
}

.bg-accent p.text-indigo,
.bg-accent h1.text-indigo,
.bg-accent h2.text-indigo,
.bg-accent h3.text-indigo,
.bg-accent h4.text-indigo,
.bg-accent h5.text-indigo,
.bg-accent h6.text-indigo,
.bg-accent .h1.text-indigo,
.bg-accent .h2.text-indigo,
.bg-accent .h3.text-indigo,
.bg-accent .h4.text-indigo,
.bg-accent .h5.text-indigo,
.bg-accent .h6.text-indigo {
  color: #6610f2;
}

.bg-accent p.text-purple,
.bg-accent h1.text-purple,
.bg-accent h2.text-purple,
.bg-accent h3.text-purple,
.bg-accent h4.text-purple,
.bg-accent h5.text-purple,
.bg-accent h6.text-purple,
.bg-accent .h1.text-purple,
.bg-accent .h2.text-purple,
.bg-accent .h3.text-purple,
.bg-accent .h4.text-purple,
.bg-accent .h5.text-purple,
.bg-accent .h6.text-purple {
  color: #6f42c1;
}

.bg-accent p.text-pink,
.bg-accent h1.text-pink,
.bg-accent h2.text-pink,
.bg-accent h3.text-pink,
.bg-accent h4.text-pink,
.bg-accent h5.text-pink,
.bg-accent h6.text-pink,
.bg-accent .h1.text-pink,
.bg-accent .h2.text-pink,
.bg-accent .h3.text-pink,
.bg-accent .h4.text-pink,
.bg-accent .h5.text-pink,
.bg-accent .h6.text-pink {
  color: #e83e8c;
}

.bg-accent p.text-red,
.bg-accent h1.text-red,
.bg-accent h2.text-red,
.bg-accent h3.text-red,
.bg-accent h4.text-red,
.bg-accent h5.text-red,
.bg-accent h6.text-red,
.bg-accent .h1.text-red,
.bg-accent .h2.text-red,
.bg-accent .h3.text-red,
.bg-accent .h4.text-red,
.bg-accent .h5.text-red,
.bg-accent .h6.text-red {
  color: #ab0520;
}

.bg-accent p.text-orange,
.bg-accent h1.text-orange,
.bg-accent h2.text-orange,
.bg-accent h3.text-orange,
.bg-accent h4.text-orange,
.bg-accent h5.text-orange,
.bg-accent h6.text-orange,
.bg-accent .h1.text-orange,
.bg-accent .h2.text-orange,
.bg-accent .h3.text-orange,
.bg-accent .h4.text-orange,
.bg-accent .h5.text-orange,
.bg-accent .h6.text-orange {
  color: #fd7e14;
}

.bg-accent p.text-yellow,
.bg-accent h1.text-yellow,
.bg-accent h2.text-yellow,
.bg-accent h3.text-yellow,
.bg-accent h4.text-yellow,
.bg-accent h5.text-yellow,
.bg-accent h6.text-yellow,
.bg-accent .h1.text-yellow,
.bg-accent .h2.text-yellow,
.bg-accent .h3.text-yellow,
.bg-accent .h4.text-yellow,
.bg-accent .h5.text-yellow,
.bg-accent .h6.text-yellow {
  color: #ffc107;
}

.bg-accent p.text-green,
.bg-accent h1.text-green,
.bg-accent h2.text-green,
.bg-accent h3.text-green,
.bg-accent h4.text-green,
.bg-accent h5.text-green,
.bg-accent h6.text-green,
.bg-accent .h1.text-green,
.bg-accent .h2.text-green,
.bg-accent .h3.text-green,
.bg-accent .h4.text-green,
.bg-accent .h5.text-green,
.bg-accent .h6.text-green {
  color: #28a745;
}

.bg-accent p.text-teal,
.bg-accent h1.text-teal,
.bg-accent h2.text-teal,
.bg-accent h3.text-teal,
.bg-accent h4.text-teal,
.bg-accent h5.text-teal,
.bg-accent h6.text-teal,
.bg-accent .h1.text-teal,
.bg-accent .h2.text-teal,
.bg-accent .h3.text-teal,
.bg-accent .h4.text-teal,
.bg-accent .h5.text-teal,
.bg-accent .h6.text-teal {
  color: #20c997;
}

.bg-accent p.text-cyan,
.bg-accent h1.text-cyan,
.bg-accent h2.text-cyan,
.bg-accent h3.text-cyan,
.bg-accent h4.text-cyan,
.bg-accent h5.text-cyan,
.bg-accent h6.text-cyan,
.bg-accent .h1.text-cyan,
.bg-accent .h2.text-cyan,
.bg-accent .h3.text-cyan,
.bg-accent .h4.text-cyan,
.bg-accent .h5.text-cyan,
.bg-accent .h6.text-cyan {
  color: #17a2b8;
}

.bg-accent p.text-white,
.bg-accent h1.text-white,
.bg-accent h2.text-white,
.bg-accent h3.text-white,
.bg-accent h4.text-white,
.bg-accent h5.text-white,
.bg-accent h6.text-white,
.bg-accent .h1.text-white,
.bg-accent .h2.text-white,
.bg-accent .h3.text-white,
.bg-accent .h4.text-white,
.bg-accent .h5.text-white,
.bg-accent .h6.text-white {
  color: #fff;
}

.bg-accent p.text-gray,
.bg-accent h1.text-gray,
.bg-accent h2.text-gray,
.bg-accent h3.text-gray,
.bg-accent h4.text-gray,
.bg-accent h5.text-gray,
.bg-accent h6.text-gray,
.bg-accent .h1.text-gray,
.bg-accent .h2.text-gray,
.bg-accent .h3.text-gray,
.bg-accent .h4.text-gray,
.bg-accent .h5.text-gray,
.bg-accent .h6.text-gray {
  color: #6c757d;
}

.bg-accent p.text-gray-dark,
.bg-accent h1.text-gray-dark,
.bg-accent h2.text-gray-dark,
.bg-accent h3.text-gray-dark,
.bg-accent h4.text-gray-dark,
.bg-accent h5.text-gray-dark,
.bg-accent h6.text-gray-dark,
.bg-accent .h1.text-gray-dark,
.bg-accent .h2.text-gray-dark,
.bg-accent .h3.text-gray-dark,
.bg-accent .h4.text-gray-dark,
.bg-accent .h5.text-gray-dark,
.bg-accent .h6.text-gray-dark {
  color: #343a40;
}

.bg-accent p.text-bloom,
.bg-accent h1.text-bloom,
.bg-accent h2.text-bloom,
.bg-accent h3.text-bloom,
.bg-accent h4.text-bloom,
.bg-accent h5.text-bloom,
.bg-accent h6.text-bloom,
.bg-accent .h1.text-bloom,
.bg-accent .h2.text-bloom,
.bg-accent .h3.text-bloom,
.bg-accent .h4.text-bloom,
.bg-accent .h5.text-bloom,
.bg-accent .h6.text-bloom {
  color: #ef4056;
}

.bg-accent p.text-chili,
.bg-accent h1.text-chili,
.bg-accent h2.text-chili,
.bg-accent h3.text-chili,
.bg-accent h4.text-chili,
.bg-accent h5.text-chili,
.bg-accent h6.text-chili,
.bg-accent .h1.text-chili,
.bg-accent .h2.text-chili,
.bg-accent .h3.text-chili,
.bg-accent .h4.text-chili,
.bg-accent .h5.text-chili,
.bg-accent .h6.text-chili {
  color: #8b0015;
}

.bg-accent p.text-sky,
.bg-accent h1.text-sky,
.bg-accent h2.text-sky,
.bg-accent h3.text-sky,
.bg-accent h4.text-sky,
.bg-accent h5.text-sky,
.bg-accent h6.text-sky,
.bg-accent .h1.text-sky,
.bg-accent .h2.text-sky,
.bg-accent .h3.text-sky,
.bg-accent .h4.text-sky,
.bg-accent .h5.text-sky,
.bg-accent .h6.text-sky {
  color: #81d3eb;
}

.bg-accent p.text-oasis,
.bg-accent h1.text-oasis,
.bg-accent h2.text-oasis,
.bg-accent h3.text-oasis,
.bg-accent h4.text-oasis,
.bg-accent h5.text-oasis,
.bg-accent h6.text-oasis,
.bg-accent .h1.text-oasis,
.bg-accent .h2.text-oasis,
.bg-accent .h3.text-oasis,
.bg-accent .h4.text-oasis,
.bg-accent .h5.text-oasis,
.bg-accent .h6.text-oasis {
  color: #378dbd;
}

.bg-accent p.text-azurite,
.bg-accent h1.text-azurite,
.bg-accent h2.text-azurite,
.bg-accent h3.text-azurite,
.bg-accent h4.text-azurite,
.bg-accent h5.text-azurite,
.bg-accent h6.text-azurite,
.bg-accent .h1.text-azurite,
.bg-accent .h2.text-azurite,
.bg-accent .h3.text-azurite,
.bg-accent .h4.text-azurite,
.bg-accent .h5.text-azurite,
.bg-accent .h6.text-azurite {
  color: #1e5288;
}

.bg-accent p.text-midnight,
.bg-accent h1.text-midnight,
.bg-accent h2.text-midnight,
.bg-accent h3.text-midnight,
.bg-accent h4.text-midnight,
.bg-accent h5.text-midnight,
.bg-accent h6.text-midnight,
.bg-accent .h1.text-midnight,
.bg-accent .h2.text-midnight,
.bg-accent .h3.text-midnight,
.bg-accent .h4.text-midnight,
.bg-accent .h5.text-midnight,
.bg-accent .h6.text-midnight {
  color: #001c48;
}

.bg-accent p.text-cool-gray,
.bg-accent h1.text-cool-gray,
.bg-accent h2.text-cool-gray,
.bg-accent h3.text-cool-gray,
.bg-accent h4.text-cool-gray,
.bg-accent h5.text-cool-gray,
.bg-accent h6.text-cool-gray,
.bg-accent .h1.text-cool-gray,
.bg-accent .h2.text-cool-gray,
.bg-accent .h3.text-cool-gray,
.bg-accent .h4.text-cool-gray,
.bg-accent .h5.text-cool-gray,
.bg-accent .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-accent p.text-warm-gray,
.bg-accent h1.text-warm-gray,
.bg-accent h2.text-warm-gray,
.bg-accent h3.text-warm-gray,
.bg-accent h4.text-warm-gray,
.bg-accent h5.text-warm-gray,
.bg-accent h6.text-warm-gray,
.bg-accent .h1.text-warm-gray,
.bg-accent .h2.text-warm-gray,
.bg-accent .h3.text-warm-gray,
.bg-accent .h4.text-warm-gray,
.bg-accent .h5.text-warm-gray,
.bg-accent .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-accent p.text-leaf,
.bg-accent h1.text-leaf,
.bg-accent h2.text-leaf,
.bg-accent h3.text-leaf,
.bg-accent h4.text-leaf,
.bg-accent h5.text-leaf,
.bg-accent h6.text-leaf,
.bg-accent .h1.text-leaf,
.bg-accent .h2.text-leaf,
.bg-accent .h3.text-leaf,
.bg-accent .h4.text-leaf,
.bg-accent .h5.text-leaf,
.bg-accent .h6.text-leaf {
  color: #70b865;
}

.bg-accent p.text-river,
.bg-accent h1.text-river,
.bg-accent h2.text-river,
.bg-accent h3.text-river,
.bg-accent h4.text-river,
.bg-accent h5.text-river,
.bg-accent h6.text-river,
.bg-accent .h1.text-river,
.bg-accent .h2.text-river,
.bg-accent .h3.text-river,
.bg-accent .h4.text-river,
.bg-accent .h5.text-river,
.bg-accent .h6.text-river {
  color: #007d84;
}

.bg-accent p.text-silver,
.bg-accent h1.text-silver,
.bg-accent h2.text-silver,
.bg-accent h3.text-silver,
.bg-accent h4.text-silver,
.bg-accent h5.text-silver,
.bg-accent h6.text-silver,
.bg-accent .h1.text-silver,
.bg-accent .h2.text-silver,
.bg-accent .h3.text-silver,
.bg-accent .h4.text-silver,
.bg-accent .h5.text-silver,
.bg-accent .h6.text-silver {
  color: #9eabae;
}

.bg-accent p.text-mesa,
.bg-accent h1.text-mesa,
.bg-accent h2.text-mesa,
.bg-accent h3.text-mesa,
.bg-accent h4.text-mesa,
.bg-accent h5.text-mesa,
.bg-accent h6.text-mesa,
.bg-accent .h1.text-mesa,
.bg-accent .h2.text-mesa,
.bg-accent .h3.text-mesa,
.bg-accent .h4.text-mesa,
.bg-accent .h5.text-mesa,
.bg-accent .h6.text-mesa {
  color: #a95c42;
}

.bg-accent p.text-ash,
.bg-accent h1.text-ash,
.bg-accent h2.text-ash,
.bg-accent h3.text-ash,
.bg-accent h4.text-ash,
.bg-accent h5.text-ash,
.bg-accent h6.text-ash,
.bg-accent .h1.text-ash,
.bg-accent .h2.text-ash,
.bg-accent .h3.text-ash,
.bg-accent .h4.text-ash,
.bg-accent .h5.text-ash,
.bg-accent .h6.text-ash {
  color: #403635;
}

.bg-accent p.text-sage,
.bg-accent h1.text-sage,
.bg-accent h2.text-sage,
.bg-accent h3.text-sage,
.bg-accent h4.text-sage,
.bg-accent h5.text-sage,
.bg-accent h6.text-sage,
.bg-accent .h1.text-sage,
.bg-accent .h2.text-sage,
.bg-accent .h3.text-sage,
.bg-accent .h4.text-sage,
.bg-accent .h5.text-sage,
.bg-accent .h6.text-sage {
  color: #4a634e;
}

.bg-accent p.text-black,
.bg-accent h1.text-black,
.bg-accent h2.text-black,
.bg-accent h3.text-black,
.bg-accent h4.text-black,
.bg-accent h5.text-black,
.bg-accent h6.text-black,
.bg-accent .h1.text-black,
.bg-accent .h2.text-black,
.bg-accent .h3.text-black,
.bg-accent .h4.text-black,
.bg-accent .h5.text-black,
.bg-accent .h6.text-black {
  color: #000;
}

.bg-accent p.text-primary,
.bg-accent h1.text-primary,
.bg-accent h2.text-primary,
.bg-accent h3.text-primary,
.bg-accent h4.text-primary,
.bg-accent h5.text-primary,
.bg-accent h6.text-primary,
.bg-accent .h1.text-primary,
.bg-accent .h2.text-primary,
.bg-accent .h3.text-primary,
.bg-accent .h4.text-primary,
.bg-accent .h5.text-primary,
.bg-accent .h6.text-primary {
  color: #1e5288;
}

.bg-accent p.text-secondary,
.bg-accent h1.text-secondary,
.bg-accent h2.text-secondary,
.bg-accent h3.text-secondary,
.bg-accent h4.text-secondary,
.bg-accent h5.text-secondary,
.bg-accent h6.text-secondary,
.bg-accent .h1.text-secondary,
.bg-accent .h2.text-secondary,
.bg-accent .h3.text-secondary,
.bg-accent .h4.text-secondary,
.bg-accent .h5.text-secondary,
.bg-accent .h6.text-secondary {
  color: #9eabae;
}

.bg-accent p.text-success,
.bg-accent h1.text-success,
.bg-accent h2.text-success,
.bg-accent h3.text-success,
.bg-accent h4.text-success,
.bg-accent h5.text-success,
.bg-accent h6.text-success,
.bg-accent .h1.text-success,
.bg-accent .h2.text-success,
.bg-accent .h3.text-success,
.bg-accent .h4.text-success,
.bg-accent .h5.text-success,
.bg-accent .h6.text-success {
  color: #70b865;
}

.bg-accent p.text-info,
.bg-accent h1.text-info,
.bg-accent h2.text-info,
.bg-accent h3.text-info,
.bg-accent h4.text-info,
.bg-accent h5.text-info,
.bg-accent h6.text-info,
.bg-accent .h1.text-info,
.bg-accent .h2.text-info,
.bg-accent .h3.text-info,
.bg-accent .h4.text-info,
.bg-accent .h5.text-info,
.bg-accent .h6.text-info {
  color: #81d3eb;
}

.bg-accent p.text-warning,
.bg-accent h1.text-warning,
.bg-accent h2.text-warning,
.bg-accent h3.text-warning,
.bg-accent h4.text-warning,
.bg-accent h5.text-warning,
.bg-accent h6.text-warning,
.bg-accent .h1.text-warning,
.bg-accent .h2.text-warning,
.bg-accent .h3.text-warning,
.bg-accent .h4.text-warning,
.bg-accent .h5.text-warning,
.bg-accent .h6.text-warning {
  color: #f19e1f;
}

.bg-accent p.text-danger,
.bg-accent h1.text-danger,
.bg-accent h2.text-danger,
.bg-accent h3.text-danger,
.bg-accent h4.text-danger,
.bg-accent h5.text-danger,
.bg-accent h6.text-danger,
.bg-accent .h1.text-danger,
.bg-accent .h2.text-danger,
.bg-accent .h3.text-danger,
.bg-accent .h4.text-danger,
.bg-accent .h5.text-danger,
.bg-accent .h6.text-danger {
  color: #ef4056;
}

.bg-accent p.text-light,
.bg-accent h1.text-light,
.bg-accent h2.text-light,
.bg-accent h3.text-light,
.bg-accent h4.text-light,
.bg-accent h5.text-light,
.bg-accent h6.text-light,
.bg-accent .h1.text-light,
.bg-accent .h2.text-light,
.bg-accent .h3.text-light,
.bg-accent .h4.text-light,
.bg-accent .h5.text-light,
.bg-accent .h6.text-light {
  color: #dee2e6;
}

.bg-accent p.text-dark,
.bg-accent h1.text-dark,
.bg-accent h2.text-dark,
.bg-accent h3.text-dark,
.bg-accent h4.text-dark,
.bg-accent h5.text-dark,
.bg-accent h6.text-dark,
.bg-accent .h1.text-dark,
.bg-accent .h2.text-dark,
.bg-accent .h3.text-dark,
.bg-accent .h4.text-dark,
.bg-accent .h5.text-dark,
.bg-accent .h6.text-dark {
  color: #343a40;
}

.bg-accent p.text-primary-dark,
.bg-accent h1.text-primary-dark,
.bg-accent h2.text-primary-dark,
.bg-accent h3.text-primary-dark,
.bg-accent h4.text-primary-dark,
.bg-accent h5.text-primary-dark,
.bg-accent h6.text-primary-dark,
.bg-accent .h1.text-primary-dark,
.bg-accent .h2.text-primary-dark,
.bg-accent .h3.text-primary-dark,
.bg-accent .h4.text-primary-dark,
.bg-accent .h5.text-primary-dark,
.bg-accent .h6.text-primary-dark {
  color: #0c234b;
}

.bg-accent p.text-primary-light,
.bg-accent h1.text-primary-light,
.bg-accent h2.text-primary-light,
.bg-accent h3.text-primary-light,
.bg-accent h4.text-primary-light,
.bg-accent h5.text-primary-light,
.bg-accent h6.text-primary-light,
.bg-accent .h1.text-primary-light,
.bg-accent .h2.text-primary-light,
.bg-accent .h3.text-primary-light,
.bg-accent .h4.text-primary-light,
.bg-accent .h5.text-primary-light,
.bg-accent .h6.text-primary-light {
  color: #378dbd;
}

.bg-accent p.text-accent,
.bg-accent h1.text-accent,
.bg-accent h2.text-accent,
.bg-accent h3.text-accent,
.bg-accent h4.text-accent,
.bg-accent h5.text-accent,
.bg-accent h6.text-accent,
.bg-accent .h1.text-accent,
.bg-accent .h2.text-accent,
.bg-accent .h3.text-accent,
.bg-accent .h4.text-accent,
.bg-accent .h5.text-accent,
.bg-accent .h6.text-accent {
  color: #ab0520;
}

.bg-gray-100 {
  color: #000;
  background-color: #f8f9fa;
}

.bg-gray-100 p,
.bg-gray-100 h1,
.bg-gray-100 h2,
.bg-gray-100 h3,
.bg-gray-100 h4,
.bg-gray-100 h5,
.bg-gray-100 h6,
.bg-gray-100 .h1,
.bg-gray-100 .h2,
.bg-gray-100 .h3,
.bg-gray-100 .h4,
.bg-gray-100 .h5,
.bg-gray-100 .h6 {
  color: #000;
}

.bg-gray-100 p.text-blue,
.bg-gray-100 h1.text-blue,
.bg-gray-100 h2.text-blue,
.bg-gray-100 h3.text-blue,
.bg-gray-100 h4.text-blue,
.bg-gray-100 h5.text-blue,
.bg-gray-100 h6.text-blue,
.bg-gray-100 .h1.text-blue,
.bg-gray-100 .h2.text-blue,
.bg-gray-100 .h3.text-blue,
.bg-gray-100 .h4.text-blue,
.bg-gray-100 .h5.text-blue,
.bg-gray-100 .h6.text-blue {
  color: #0c234b;
}

.bg-gray-100 p.text-indigo,
.bg-gray-100 h1.text-indigo,
.bg-gray-100 h2.text-indigo,
.bg-gray-100 h3.text-indigo,
.bg-gray-100 h4.text-indigo,
.bg-gray-100 h5.text-indigo,
.bg-gray-100 h6.text-indigo,
.bg-gray-100 .h1.text-indigo,
.bg-gray-100 .h2.text-indigo,
.bg-gray-100 .h3.text-indigo,
.bg-gray-100 .h4.text-indigo,
.bg-gray-100 .h5.text-indigo,
.bg-gray-100 .h6.text-indigo {
  color: #6610f2;
}

.bg-gray-100 p.text-purple,
.bg-gray-100 h1.text-purple,
.bg-gray-100 h2.text-purple,
.bg-gray-100 h3.text-purple,
.bg-gray-100 h4.text-purple,
.bg-gray-100 h5.text-purple,
.bg-gray-100 h6.text-purple,
.bg-gray-100 .h1.text-purple,
.bg-gray-100 .h2.text-purple,
.bg-gray-100 .h3.text-purple,
.bg-gray-100 .h4.text-purple,
.bg-gray-100 .h5.text-purple,
.bg-gray-100 .h6.text-purple {
  color: #6f42c1;
}

.bg-gray-100 p.text-pink,
.bg-gray-100 h1.text-pink,
.bg-gray-100 h2.text-pink,
.bg-gray-100 h3.text-pink,
.bg-gray-100 h4.text-pink,
.bg-gray-100 h5.text-pink,
.bg-gray-100 h6.text-pink,
.bg-gray-100 .h1.text-pink,
.bg-gray-100 .h2.text-pink,
.bg-gray-100 .h3.text-pink,
.bg-gray-100 .h4.text-pink,
.bg-gray-100 .h5.text-pink,
.bg-gray-100 .h6.text-pink {
  color: #e83e8c;
}

.bg-gray-100 p.text-red,
.bg-gray-100 h1.text-red,
.bg-gray-100 h2.text-red,
.bg-gray-100 h3.text-red,
.bg-gray-100 h4.text-red,
.bg-gray-100 h5.text-red,
.bg-gray-100 h6.text-red,
.bg-gray-100 .h1.text-red,
.bg-gray-100 .h2.text-red,
.bg-gray-100 .h3.text-red,
.bg-gray-100 .h4.text-red,
.bg-gray-100 .h5.text-red,
.bg-gray-100 .h6.text-red {
  color: #ab0520;
}

.bg-gray-100 p.text-orange,
.bg-gray-100 h1.text-orange,
.bg-gray-100 h2.text-orange,
.bg-gray-100 h3.text-orange,
.bg-gray-100 h4.text-orange,
.bg-gray-100 h5.text-orange,
.bg-gray-100 h6.text-orange,
.bg-gray-100 .h1.text-orange,
.bg-gray-100 .h2.text-orange,
.bg-gray-100 .h3.text-orange,
.bg-gray-100 .h4.text-orange,
.bg-gray-100 .h5.text-orange,
.bg-gray-100 .h6.text-orange {
  color: #fd7e14;
}

.bg-gray-100 p.text-yellow,
.bg-gray-100 h1.text-yellow,
.bg-gray-100 h2.text-yellow,
.bg-gray-100 h3.text-yellow,
.bg-gray-100 h4.text-yellow,
.bg-gray-100 h5.text-yellow,
.bg-gray-100 h6.text-yellow,
.bg-gray-100 .h1.text-yellow,
.bg-gray-100 .h2.text-yellow,
.bg-gray-100 .h3.text-yellow,
.bg-gray-100 .h4.text-yellow,
.bg-gray-100 .h5.text-yellow,
.bg-gray-100 .h6.text-yellow {
  color: #ffc107;
}

.bg-gray-100 p.text-green,
.bg-gray-100 h1.text-green,
.bg-gray-100 h2.text-green,
.bg-gray-100 h3.text-green,
.bg-gray-100 h4.text-green,
.bg-gray-100 h5.text-green,
.bg-gray-100 h6.text-green,
.bg-gray-100 .h1.text-green,
.bg-gray-100 .h2.text-green,
.bg-gray-100 .h3.text-green,
.bg-gray-100 .h4.text-green,
.bg-gray-100 .h5.text-green,
.bg-gray-100 .h6.text-green {
  color: #28a745;
}

.bg-gray-100 p.text-teal,
.bg-gray-100 h1.text-teal,
.bg-gray-100 h2.text-teal,
.bg-gray-100 h3.text-teal,
.bg-gray-100 h4.text-teal,
.bg-gray-100 h5.text-teal,
.bg-gray-100 h6.text-teal,
.bg-gray-100 .h1.text-teal,
.bg-gray-100 .h2.text-teal,
.bg-gray-100 .h3.text-teal,
.bg-gray-100 .h4.text-teal,
.bg-gray-100 .h5.text-teal,
.bg-gray-100 .h6.text-teal {
  color: #20c997;
}

.bg-gray-100 p.text-cyan,
.bg-gray-100 h1.text-cyan,
.bg-gray-100 h2.text-cyan,
.bg-gray-100 h3.text-cyan,
.bg-gray-100 h4.text-cyan,
.bg-gray-100 h5.text-cyan,
.bg-gray-100 h6.text-cyan,
.bg-gray-100 .h1.text-cyan,
.bg-gray-100 .h2.text-cyan,
.bg-gray-100 .h3.text-cyan,
.bg-gray-100 .h4.text-cyan,
.bg-gray-100 .h5.text-cyan,
.bg-gray-100 .h6.text-cyan {
  color: #17a2b8;
}

.bg-gray-100 p.text-white,
.bg-gray-100 h1.text-white,
.bg-gray-100 h2.text-white,
.bg-gray-100 h3.text-white,
.bg-gray-100 h4.text-white,
.bg-gray-100 h5.text-white,
.bg-gray-100 h6.text-white,
.bg-gray-100 .h1.text-white,
.bg-gray-100 .h2.text-white,
.bg-gray-100 .h3.text-white,
.bg-gray-100 .h4.text-white,
.bg-gray-100 .h5.text-white,
.bg-gray-100 .h6.text-white {
  color: #fff;
}

.bg-gray-100 p.text-gray,
.bg-gray-100 h1.text-gray,
.bg-gray-100 h2.text-gray,
.bg-gray-100 h3.text-gray,
.bg-gray-100 h4.text-gray,
.bg-gray-100 h5.text-gray,
.bg-gray-100 h6.text-gray,
.bg-gray-100 .h1.text-gray,
.bg-gray-100 .h2.text-gray,
.bg-gray-100 .h3.text-gray,
.bg-gray-100 .h4.text-gray,
.bg-gray-100 .h5.text-gray,
.bg-gray-100 .h6.text-gray {
  color: #6c757d;
}

.bg-gray-100 p.text-gray-dark,
.bg-gray-100 h1.text-gray-dark,
.bg-gray-100 h2.text-gray-dark,
.bg-gray-100 h3.text-gray-dark,
.bg-gray-100 h4.text-gray-dark,
.bg-gray-100 h5.text-gray-dark,
.bg-gray-100 h6.text-gray-dark,
.bg-gray-100 .h1.text-gray-dark,
.bg-gray-100 .h2.text-gray-dark,
.bg-gray-100 .h3.text-gray-dark,
.bg-gray-100 .h4.text-gray-dark,
.bg-gray-100 .h5.text-gray-dark,
.bg-gray-100 .h6.text-gray-dark {
  color: #343a40;
}

.bg-gray-100 p.text-bloom,
.bg-gray-100 h1.text-bloom,
.bg-gray-100 h2.text-bloom,
.bg-gray-100 h3.text-bloom,
.bg-gray-100 h4.text-bloom,
.bg-gray-100 h5.text-bloom,
.bg-gray-100 h6.text-bloom,
.bg-gray-100 .h1.text-bloom,
.bg-gray-100 .h2.text-bloom,
.bg-gray-100 .h3.text-bloom,
.bg-gray-100 .h4.text-bloom,
.bg-gray-100 .h5.text-bloom,
.bg-gray-100 .h6.text-bloom {
  color: #ef4056;
}

.bg-gray-100 p.text-chili,
.bg-gray-100 h1.text-chili,
.bg-gray-100 h2.text-chili,
.bg-gray-100 h3.text-chili,
.bg-gray-100 h4.text-chili,
.bg-gray-100 h5.text-chili,
.bg-gray-100 h6.text-chili,
.bg-gray-100 .h1.text-chili,
.bg-gray-100 .h2.text-chili,
.bg-gray-100 .h3.text-chili,
.bg-gray-100 .h4.text-chili,
.bg-gray-100 .h5.text-chili,
.bg-gray-100 .h6.text-chili {
  color: #8b0015;
}

.bg-gray-100 p.text-sky,
.bg-gray-100 h1.text-sky,
.bg-gray-100 h2.text-sky,
.bg-gray-100 h3.text-sky,
.bg-gray-100 h4.text-sky,
.bg-gray-100 h5.text-sky,
.bg-gray-100 h6.text-sky,
.bg-gray-100 .h1.text-sky,
.bg-gray-100 .h2.text-sky,
.bg-gray-100 .h3.text-sky,
.bg-gray-100 .h4.text-sky,
.bg-gray-100 .h5.text-sky,
.bg-gray-100 .h6.text-sky {
  color: #81d3eb;
}

.bg-gray-100 p.text-oasis,
.bg-gray-100 h1.text-oasis,
.bg-gray-100 h2.text-oasis,
.bg-gray-100 h3.text-oasis,
.bg-gray-100 h4.text-oasis,
.bg-gray-100 h5.text-oasis,
.bg-gray-100 h6.text-oasis,
.bg-gray-100 .h1.text-oasis,
.bg-gray-100 .h2.text-oasis,
.bg-gray-100 .h3.text-oasis,
.bg-gray-100 .h4.text-oasis,
.bg-gray-100 .h5.text-oasis,
.bg-gray-100 .h6.text-oasis {
  color: #378dbd;
}

.bg-gray-100 p.text-azurite,
.bg-gray-100 h1.text-azurite,
.bg-gray-100 h2.text-azurite,
.bg-gray-100 h3.text-azurite,
.bg-gray-100 h4.text-azurite,
.bg-gray-100 h5.text-azurite,
.bg-gray-100 h6.text-azurite,
.bg-gray-100 .h1.text-azurite,
.bg-gray-100 .h2.text-azurite,
.bg-gray-100 .h3.text-azurite,
.bg-gray-100 .h4.text-azurite,
.bg-gray-100 .h5.text-azurite,
.bg-gray-100 .h6.text-azurite {
  color: #1e5288;
}

.bg-gray-100 p.text-midnight,
.bg-gray-100 h1.text-midnight,
.bg-gray-100 h2.text-midnight,
.bg-gray-100 h3.text-midnight,
.bg-gray-100 h4.text-midnight,
.bg-gray-100 h5.text-midnight,
.bg-gray-100 h6.text-midnight,
.bg-gray-100 .h1.text-midnight,
.bg-gray-100 .h2.text-midnight,
.bg-gray-100 .h3.text-midnight,
.bg-gray-100 .h4.text-midnight,
.bg-gray-100 .h5.text-midnight,
.bg-gray-100 .h6.text-midnight {
  color: #001c48;
}

.bg-gray-100 p.text-cool-gray,
.bg-gray-100 h1.text-cool-gray,
.bg-gray-100 h2.text-cool-gray,
.bg-gray-100 h3.text-cool-gray,
.bg-gray-100 h4.text-cool-gray,
.bg-gray-100 h5.text-cool-gray,
.bg-gray-100 h6.text-cool-gray,
.bg-gray-100 .h1.text-cool-gray,
.bg-gray-100 .h2.text-cool-gray,
.bg-gray-100 .h3.text-cool-gray,
.bg-gray-100 .h4.text-cool-gray,
.bg-gray-100 .h5.text-cool-gray,
.bg-gray-100 .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-gray-100 p.text-warm-gray,
.bg-gray-100 h1.text-warm-gray,
.bg-gray-100 h2.text-warm-gray,
.bg-gray-100 h3.text-warm-gray,
.bg-gray-100 h4.text-warm-gray,
.bg-gray-100 h5.text-warm-gray,
.bg-gray-100 h6.text-warm-gray,
.bg-gray-100 .h1.text-warm-gray,
.bg-gray-100 .h2.text-warm-gray,
.bg-gray-100 .h3.text-warm-gray,
.bg-gray-100 .h4.text-warm-gray,
.bg-gray-100 .h5.text-warm-gray,
.bg-gray-100 .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-gray-100 p.text-leaf,
.bg-gray-100 h1.text-leaf,
.bg-gray-100 h2.text-leaf,
.bg-gray-100 h3.text-leaf,
.bg-gray-100 h4.text-leaf,
.bg-gray-100 h5.text-leaf,
.bg-gray-100 h6.text-leaf,
.bg-gray-100 .h1.text-leaf,
.bg-gray-100 .h2.text-leaf,
.bg-gray-100 .h3.text-leaf,
.bg-gray-100 .h4.text-leaf,
.bg-gray-100 .h5.text-leaf,
.bg-gray-100 .h6.text-leaf {
  color: #70b865;
}

.bg-gray-100 p.text-river,
.bg-gray-100 h1.text-river,
.bg-gray-100 h2.text-river,
.bg-gray-100 h3.text-river,
.bg-gray-100 h4.text-river,
.bg-gray-100 h5.text-river,
.bg-gray-100 h6.text-river,
.bg-gray-100 .h1.text-river,
.bg-gray-100 .h2.text-river,
.bg-gray-100 .h3.text-river,
.bg-gray-100 .h4.text-river,
.bg-gray-100 .h5.text-river,
.bg-gray-100 .h6.text-river {
  color: #007d84;
}

.bg-gray-100 p.text-silver,
.bg-gray-100 h1.text-silver,
.bg-gray-100 h2.text-silver,
.bg-gray-100 h3.text-silver,
.bg-gray-100 h4.text-silver,
.bg-gray-100 h5.text-silver,
.bg-gray-100 h6.text-silver,
.bg-gray-100 .h1.text-silver,
.bg-gray-100 .h2.text-silver,
.bg-gray-100 .h3.text-silver,
.bg-gray-100 .h4.text-silver,
.bg-gray-100 .h5.text-silver,
.bg-gray-100 .h6.text-silver {
  color: #9eabae;
}

.bg-gray-100 p.text-mesa,
.bg-gray-100 h1.text-mesa,
.bg-gray-100 h2.text-mesa,
.bg-gray-100 h3.text-mesa,
.bg-gray-100 h4.text-mesa,
.bg-gray-100 h5.text-mesa,
.bg-gray-100 h6.text-mesa,
.bg-gray-100 .h1.text-mesa,
.bg-gray-100 .h2.text-mesa,
.bg-gray-100 .h3.text-mesa,
.bg-gray-100 .h4.text-mesa,
.bg-gray-100 .h5.text-mesa,
.bg-gray-100 .h6.text-mesa {
  color: #a95c42;
}

.bg-gray-100 p.text-ash,
.bg-gray-100 h1.text-ash,
.bg-gray-100 h2.text-ash,
.bg-gray-100 h3.text-ash,
.bg-gray-100 h4.text-ash,
.bg-gray-100 h5.text-ash,
.bg-gray-100 h6.text-ash,
.bg-gray-100 .h1.text-ash,
.bg-gray-100 .h2.text-ash,
.bg-gray-100 .h3.text-ash,
.bg-gray-100 .h4.text-ash,
.bg-gray-100 .h5.text-ash,
.bg-gray-100 .h6.text-ash {
  color: #403635;
}

.bg-gray-100 p.text-sage,
.bg-gray-100 h1.text-sage,
.bg-gray-100 h2.text-sage,
.bg-gray-100 h3.text-sage,
.bg-gray-100 h4.text-sage,
.bg-gray-100 h5.text-sage,
.bg-gray-100 h6.text-sage,
.bg-gray-100 .h1.text-sage,
.bg-gray-100 .h2.text-sage,
.bg-gray-100 .h3.text-sage,
.bg-gray-100 .h4.text-sage,
.bg-gray-100 .h5.text-sage,
.bg-gray-100 .h6.text-sage {
  color: #4a634e;
}

.bg-gray-100 p.text-black,
.bg-gray-100 h1.text-black,
.bg-gray-100 h2.text-black,
.bg-gray-100 h3.text-black,
.bg-gray-100 h4.text-black,
.bg-gray-100 h5.text-black,
.bg-gray-100 h6.text-black,
.bg-gray-100 .h1.text-black,
.bg-gray-100 .h2.text-black,
.bg-gray-100 .h3.text-black,
.bg-gray-100 .h4.text-black,
.bg-gray-100 .h5.text-black,
.bg-gray-100 .h6.text-black {
  color: #000;
}

.bg-gray-100 p.text-primary,
.bg-gray-100 h1.text-primary,
.bg-gray-100 h2.text-primary,
.bg-gray-100 h3.text-primary,
.bg-gray-100 h4.text-primary,
.bg-gray-100 h5.text-primary,
.bg-gray-100 h6.text-primary,
.bg-gray-100 .h1.text-primary,
.bg-gray-100 .h2.text-primary,
.bg-gray-100 .h3.text-primary,
.bg-gray-100 .h4.text-primary,
.bg-gray-100 .h5.text-primary,
.bg-gray-100 .h6.text-primary {
  color: #1e5288;
}

.bg-gray-100 p.text-secondary,
.bg-gray-100 h1.text-secondary,
.bg-gray-100 h2.text-secondary,
.bg-gray-100 h3.text-secondary,
.bg-gray-100 h4.text-secondary,
.bg-gray-100 h5.text-secondary,
.bg-gray-100 h6.text-secondary,
.bg-gray-100 .h1.text-secondary,
.bg-gray-100 .h2.text-secondary,
.bg-gray-100 .h3.text-secondary,
.bg-gray-100 .h4.text-secondary,
.bg-gray-100 .h5.text-secondary,
.bg-gray-100 .h6.text-secondary {
  color: #9eabae;
}

.bg-gray-100 p.text-success,
.bg-gray-100 h1.text-success,
.bg-gray-100 h2.text-success,
.bg-gray-100 h3.text-success,
.bg-gray-100 h4.text-success,
.bg-gray-100 h5.text-success,
.bg-gray-100 h6.text-success,
.bg-gray-100 .h1.text-success,
.bg-gray-100 .h2.text-success,
.bg-gray-100 .h3.text-success,
.bg-gray-100 .h4.text-success,
.bg-gray-100 .h5.text-success,
.bg-gray-100 .h6.text-success {
  color: #70b865;
}

.bg-gray-100 p.text-info,
.bg-gray-100 h1.text-info,
.bg-gray-100 h2.text-info,
.bg-gray-100 h3.text-info,
.bg-gray-100 h4.text-info,
.bg-gray-100 h5.text-info,
.bg-gray-100 h6.text-info,
.bg-gray-100 .h1.text-info,
.bg-gray-100 .h2.text-info,
.bg-gray-100 .h3.text-info,
.bg-gray-100 .h4.text-info,
.bg-gray-100 .h5.text-info,
.bg-gray-100 .h6.text-info {
  color: #81d3eb;
}

.bg-gray-100 p.text-warning,
.bg-gray-100 h1.text-warning,
.bg-gray-100 h2.text-warning,
.bg-gray-100 h3.text-warning,
.bg-gray-100 h4.text-warning,
.bg-gray-100 h5.text-warning,
.bg-gray-100 h6.text-warning,
.bg-gray-100 .h1.text-warning,
.bg-gray-100 .h2.text-warning,
.bg-gray-100 .h3.text-warning,
.bg-gray-100 .h4.text-warning,
.bg-gray-100 .h5.text-warning,
.bg-gray-100 .h6.text-warning {
  color: #f19e1f;
}

.bg-gray-100 p.text-danger,
.bg-gray-100 h1.text-danger,
.bg-gray-100 h2.text-danger,
.bg-gray-100 h3.text-danger,
.bg-gray-100 h4.text-danger,
.bg-gray-100 h5.text-danger,
.bg-gray-100 h6.text-danger,
.bg-gray-100 .h1.text-danger,
.bg-gray-100 .h2.text-danger,
.bg-gray-100 .h3.text-danger,
.bg-gray-100 .h4.text-danger,
.bg-gray-100 .h5.text-danger,
.bg-gray-100 .h6.text-danger {
  color: #ef4056;
}

.bg-gray-100 p.text-light,
.bg-gray-100 h1.text-light,
.bg-gray-100 h2.text-light,
.bg-gray-100 h3.text-light,
.bg-gray-100 h4.text-light,
.bg-gray-100 h5.text-light,
.bg-gray-100 h6.text-light,
.bg-gray-100 .h1.text-light,
.bg-gray-100 .h2.text-light,
.bg-gray-100 .h3.text-light,
.bg-gray-100 .h4.text-light,
.bg-gray-100 .h5.text-light,
.bg-gray-100 .h6.text-light {
  color: #dee2e6;
}

.bg-gray-100 p.text-dark,
.bg-gray-100 h1.text-dark,
.bg-gray-100 h2.text-dark,
.bg-gray-100 h3.text-dark,
.bg-gray-100 h4.text-dark,
.bg-gray-100 h5.text-dark,
.bg-gray-100 h6.text-dark,
.bg-gray-100 .h1.text-dark,
.bg-gray-100 .h2.text-dark,
.bg-gray-100 .h3.text-dark,
.bg-gray-100 .h4.text-dark,
.bg-gray-100 .h5.text-dark,
.bg-gray-100 .h6.text-dark {
  color: #343a40;
}

.bg-gray-100 p.text-primary-dark,
.bg-gray-100 h1.text-primary-dark,
.bg-gray-100 h2.text-primary-dark,
.bg-gray-100 h3.text-primary-dark,
.bg-gray-100 h4.text-primary-dark,
.bg-gray-100 h5.text-primary-dark,
.bg-gray-100 h6.text-primary-dark,
.bg-gray-100 .h1.text-primary-dark,
.bg-gray-100 .h2.text-primary-dark,
.bg-gray-100 .h3.text-primary-dark,
.bg-gray-100 .h4.text-primary-dark,
.bg-gray-100 .h5.text-primary-dark,
.bg-gray-100 .h6.text-primary-dark {
  color: #0c234b;
}

.bg-gray-100 p.text-primary-light,
.bg-gray-100 h1.text-primary-light,
.bg-gray-100 h2.text-primary-light,
.bg-gray-100 h3.text-primary-light,
.bg-gray-100 h4.text-primary-light,
.bg-gray-100 h5.text-primary-light,
.bg-gray-100 h6.text-primary-light,
.bg-gray-100 .h1.text-primary-light,
.bg-gray-100 .h2.text-primary-light,
.bg-gray-100 .h3.text-primary-light,
.bg-gray-100 .h4.text-primary-light,
.bg-gray-100 .h5.text-primary-light,
.bg-gray-100 .h6.text-primary-light {
  color: #378dbd;
}

.bg-gray-100 p.text-accent,
.bg-gray-100 h1.text-accent,
.bg-gray-100 h2.text-accent,
.bg-gray-100 h3.text-accent,
.bg-gray-100 h4.text-accent,
.bg-gray-100 h5.text-accent,
.bg-gray-100 h6.text-accent,
.bg-gray-100 .h1.text-accent,
.bg-gray-100 .h2.text-accent,
.bg-gray-100 .h3.text-accent,
.bg-gray-100 .h4.text-accent,
.bg-gray-100 .h5.text-accent,
.bg-gray-100 .h6.text-accent {
  color: #ab0520;
}

.bg-gray-200 {
  color: #000;
  background-color: #e9ecef;
}

.bg-gray-200 p,
.bg-gray-200 h1,
.bg-gray-200 h2,
.bg-gray-200 h3,
.bg-gray-200 h4,
.bg-gray-200 h5,
.bg-gray-200 h6,
.bg-gray-200 .h1,
.bg-gray-200 .h2,
.bg-gray-200 .h3,
.bg-gray-200 .h4,
.bg-gray-200 .h5,
.bg-gray-200 .h6 {
  color: #000;
}

.bg-gray-200 p.text-blue,
.bg-gray-200 h1.text-blue,
.bg-gray-200 h2.text-blue,
.bg-gray-200 h3.text-blue,
.bg-gray-200 h4.text-blue,
.bg-gray-200 h5.text-blue,
.bg-gray-200 h6.text-blue,
.bg-gray-200 .h1.text-blue,
.bg-gray-200 .h2.text-blue,
.bg-gray-200 .h3.text-blue,
.bg-gray-200 .h4.text-blue,
.bg-gray-200 .h5.text-blue,
.bg-gray-200 .h6.text-blue {
  color: #0c234b;
}

.bg-gray-200 p.text-indigo,
.bg-gray-200 h1.text-indigo,
.bg-gray-200 h2.text-indigo,
.bg-gray-200 h3.text-indigo,
.bg-gray-200 h4.text-indigo,
.bg-gray-200 h5.text-indigo,
.bg-gray-200 h6.text-indigo,
.bg-gray-200 .h1.text-indigo,
.bg-gray-200 .h2.text-indigo,
.bg-gray-200 .h3.text-indigo,
.bg-gray-200 .h4.text-indigo,
.bg-gray-200 .h5.text-indigo,
.bg-gray-200 .h6.text-indigo {
  color: #6610f2;
}

.bg-gray-200 p.text-purple,
.bg-gray-200 h1.text-purple,
.bg-gray-200 h2.text-purple,
.bg-gray-200 h3.text-purple,
.bg-gray-200 h4.text-purple,
.bg-gray-200 h5.text-purple,
.bg-gray-200 h6.text-purple,
.bg-gray-200 .h1.text-purple,
.bg-gray-200 .h2.text-purple,
.bg-gray-200 .h3.text-purple,
.bg-gray-200 .h4.text-purple,
.bg-gray-200 .h5.text-purple,
.bg-gray-200 .h6.text-purple {
  color: #6f42c1;
}

.bg-gray-200 p.text-pink,
.bg-gray-200 h1.text-pink,
.bg-gray-200 h2.text-pink,
.bg-gray-200 h3.text-pink,
.bg-gray-200 h4.text-pink,
.bg-gray-200 h5.text-pink,
.bg-gray-200 h6.text-pink,
.bg-gray-200 .h1.text-pink,
.bg-gray-200 .h2.text-pink,
.bg-gray-200 .h3.text-pink,
.bg-gray-200 .h4.text-pink,
.bg-gray-200 .h5.text-pink,
.bg-gray-200 .h6.text-pink {
  color: #e83e8c;
}

.bg-gray-200 p.text-red,
.bg-gray-200 h1.text-red,
.bg-gray-200 h2.text-red,
.bg-gray-200 h3.text-red,
.bg-gray-200 h4.text-red,
.bg-gray-200 h5.text-red,
.bg-gray-200 h6.text-red,
.bg-gray-200 .h1.text-red,
.bg-gray-200 .h2.text-red,
.bg-gray-200 .h3.text-red,
.bg-gray-200 .h4.text-red,
.bg-gray-200 .h5.text-red,
.bg-gray-200 .h6.text-red {
  color: #ab0520;
}

.bg-gray-200 p.text-orange,
.bg-gray-200 h1.text-orange,
.bg-gray-200 h2.text-orange,
.bg-gray-200 h3.text-orange,
.bg-gray-200 h4.text-orange,
.bg-gray-200 h5.text-orange,
.bg-gray-200 h6.text-orange,
.bg-gray-200 .h1.text-orange,
.bg-gray-200 .h2.text-orange,
.bg-gray-200 .h3.text-orange,
.bg-gray-200 .h4.text-orange,
.bg-gray-200 .h5.text-orange,
.bg-gray-200 .h6.text-orange {
  color: #fd7e14;
}

.bg-gray-200 p.text-yellow,
.bg-gray-200 h1.text-yellow,
.bg-gray-200 h2.text-yellow,
.bg-gray-200 h3.text-yellow,
.bg-gray-200 h4.text-yellow,
.bg-gray-200 h5.text-yellow,
.bg-gray-200 h6.text-yellow,
.bg-gray-200 .h1.text-yellow,
.bg-gray-200 .h2.text-yellow,
.bg-gray-200 .h3.text-yellow,
.bg-gray-200 .h4.text-yellow,
.bg-gray-200 .h5.text-yellow,
.bg-gray-200 .h6.text-yellow {
  color: #ffc107;
}

.bg-gray-200 p.text-green,
.bg-gray-200 h1.text-green,
.bg-gray-200 h2.text-green,
.bg-gray-200 h3.text-green,
.bg-gray-200 h4.text-green,
.bg-gray-200 h5.text-green,
.bg-gray-200 h6.text-green,
.bg-gray-200 .h1.text-green,
.bg-gray-200 .h2.text-green,
.bg-gray-200 .h3.text-green,
.bg-gray-200 .h4.text-green,
.bg-gray-200 .h5.text-green,
.bg-gray-200 .h6.text-green {
  color: #28a745;
}

.bg-gray-200 p.text-teal,
.bg-gray-200 h1.text-teal,
.bg-gray-200 h2.text-teal,
.bg-gray-200 h3.text-teal,
.bg-gray-200 h4.text-teal,
.bg-gray-200 h5.text-teal,
.bg-gray-200 h6.text-teal,
.bg-gray-200 .h1.text-teal,
.bg-gray-200 .h2.text-teal,
.bg-gray-200 .h3.text-teal,
.bg-gray-200 .h4.text-teal,
.bg-gray-200 .h5.text-teal,
.bg-gray-200 .h6.text-teal {
  color: #20c997;
}

.bg-gray-200 p.text-cyan,
.bg-gray-200 h1.text-cyan,
.bg-gray-200 h2.text-cyan,
.bg-gray-200 h3.text-cyan,
.bg-gray-200 h4.text-cyan,
.bg-gray-200 h5.text-cyan,
.bg-gray-200 h6.text-cyan,
.bg-gray-200 .h1.text-cyan,
.bg-gray-200 .h2.text-cyan,
.bg-gray-200 .h3.text-cyan,
.bg-gray-200 .h4.text-cyan,
.bg-gray-200 .h5.text-cyan,
.bg-gray-200 .h6.text-cyan {
  color: #17a2b8;
}

.bg-gray-200 p.text-white,
.bg-gray-200 h1.text-white,
.bg-gray-200 h2.text-white,
.bg-gray-200 h3.text-white,
.bg-gray-200 h4.text-white,
.bg-gray-200 h5.text-white,
.bg-gray-200 h6.text-white,
.bg-gray-200 .h1.text-white,
.bg-gray-200 .h2.text-white,
.bg-gray-200 .h3.text-white,
.bg-gray-200 .h4.text-white,
.bg-gray-200 .h5.text-white,
.bg-gray-200 .h6.text-white {
  color: #fff;
}

.bg-gray-200 p.text-gray,
.bg-gray-200 h1.text-gray,
.bg-gray-200 h2.text-gray,
.bg-gray-200 h3.text-gray,
.bg-gray-200 h4.text-gray,
.bg-gray-200 h5.text-gray,
.bg-gray-200 h6.text-gray,
.bg-gray-200 .h1.text-gray,
.bg-gray-200 .h2.text-gray,
.bg-gray-200 .h3.text-gray,
.bg-gray-200 .h4.text-gray,
.bg-gray-200 .h5.text-gray,
.bg-gray-200 .h6.text-gray {
  color: #6c757d;
}

.bg-gray-200 p.text-gray-dark,
.bg-gray-200 h1.text-gray-dark,
.bg-gray-200 h2.text-gray-dark,
.bg-gray-200 h3.text-gray-dark,
.bg-gray-200 h4.text-gray-dark,
.bg-gray-200 h5.text-gray-dark,
.bg-gray-200 h6.text-gray-dark,
.bg-gray-200 .h1.text-gray-dark,
.bg-gray-200 .h2.text-gray-dark,
.bg-gray-200 .h3.text-gray-dark,
.bg-gray-200 .h4.text-gray-dark,
.bg-gray-200 .h5.text-gray-dark,
.bg-gray-200 .h6.text-gray-dark {
  color: #343a40;
}

.bg-gray-200 p.text-bloom,
.bg-gray-200 h1.text-bloom,
.bg-gray-200 h2.text-bloom,
.bg-gray-200 h3.text-bloom,
.bg-gray-200 h4.text-bloom,
.bg-gray-200 h5.text-bloom,
.bg-gray-200 h6.text-bloom,
.bg-gray-200 .h1.text-bloom,
.bg-gray-200 .h2.text-bloom,
.bg-gray-200 .h3.text-bloom,
.bg-gray-200 .h4.text-bloom,
.bg-gray-200 .h5.text-bloom,
.bg-gray-200 .h6.text-bloom {
  color: #ef4056;
}

.bg-gray-200 p.text-chili,
.bg-gray-200 h1.text-chili,
.bg-gray-200 h2.text-chili,
.bg-gray-200 h3.text-chili,
.bg-gray-200 h4.text-chili,
.bg-gray-200 h5.text-chili,
.bg-gray-200 h6.text-chili,
.bg-gray-200 .h1.text-chili,
.bg-gray-200 .h2.text-chili,
.bg-gray-200 .h3.text-chili,
.bg-gray-200 .h4.text-chili,
.bg-gray-200 .h5.text-chili,
.bg-gray-200 .h6.text-chili {
  color: #8b0015;
}

.bg-gray-200 p.text-sky,
.bg-gray-200 h1.text-sky,
.bg-gray-200 h2.text-sky,
.bg-gray-200 h3.text-sky,
.bg-gray-200 h4.text-sky,
.bg-gray-200 h5.text-sky,
.bg-gray-200 h6.text-sky,
.bg-gray-200 .h1.text-sky,
.bg-gray-200 .h2.text-sky,
.bg-gray-200 .h3.text-sky,
.bg-gray-200 .h4.text-sky,
.bg-gray-200 .h5.text-sky,
.bg-gray-200 .h6.text-sky {
  color: #81d3eb;
}

.bg-gray-200 p.text-oasis,
.bg-gray-200 h1.text-oasis,
.bg-gray-200 h2.text-oasis,
.bg-gray-200 h3.text-oasis,
.bg-gray-200 h4.text-oasis,
.bg-gray-200 h5.text-oasis,
.bg-gray-200 h6.text-oasis,
.bg-gray-200 .h1.text-oasis,
.bg-gray-200 .h2.text-oasis,
.bg-gray-200 .h3.text-oasis,
.bg-gray-200 .h4.text-oasis,
.bg-gray-200 .h5.text-oasis,
.bg-gray-200 .h6.text-oasis {
  color: #378dbd;
}

.bg-gray-200 p.text-azurite,
.bg-gray-200 h1.text-azurite,
.bg-gray-200 h2.text-azurite,
.bg-gray-200 h3.text-azurite,
.bg-gray-200 h4.text-azurite,
.bg-gray-200 h5.text-azurite,
.bg-gray-200 h6.text-azurite,
.bg-gray-200 .h1.text-azurite,
.bg-gray-200 .h2.text-azurite,
.bg-gray-200 .h3.text-azurite,
.bg-gray-200 .h4.text-azurite,
.bg-gray-200 .h5.text-azurite,
.bg-gray-200 .h6.text-azurite {
  color: #1e5288;
}

.bg-gray-200 p.text-midnight,
.bg-gray-200 h1.text-midnight,
.bg-gray-200 h2.text-midnight,
.bg-gray-200 h3.text-midnight,
.bg-gray-200 h4.text-midnight,
.bg-gray-200 h5.text-midnight,
.bg-gray-200 h6.text-midnight,
.bg-gray-200 .h1.text-midnight,
.bg-gray-200 .h2.text-midnight,
.bg-gray-200 .h3.text-midnight,
.bg-gray-200 .h4.text-midnight,
.bg-gray-200 .h5.text-midnight,
.bg-gray-200 .h6.text-midnight {
  color: #001c48;
}

.bg-gray-200 p.text-cool-gray,
.bg-gray-200 h1.text-cool-gray,
.bg-gray-200 h2.text-cool-gray,
.bg-gray-200 h3.text-cool-gray,
.bg-gray-200 h4.text-cool-gray,
.bg-gray-200 h5.text-cool-gray,
.bg-gray-200 h6.text-cool-gray,
.bg-gray-200 .h1.text-cool-gray,
.bg-gray-200 .h2.text-cool-gray,
.bg-gray-200 .h3.text-cool-gray,
.bg-gray-200 .h4.text-cool-gray,
.bg-gray-200 .h5.text-cool-gray,
.bg-gray-200 .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-gray-200 p.text-warm-gray,
.bg-gray-200 h1.text-warm-gray,
.bg-gray-200 h2.text-warm-gray,
.bg-gray-200 h3.text-warm-gray,
.bg-gray-200 h4.text-warm-gray,
.bg-gray-200 h5.text-warm-gray,
.bg-gray-200 h6.text-warm-gray,
.bg-gray-200 .h1.text-warm-gray,
.bg-gray-200 .h2.text-warm-gray,
.bg-gray-200 .h3.text-warm-gray,
.bg-gray-200 .h4.text-warm-gray,
.bg-gray-200 .h5.text-warm-gray,
.bg-gray-200 .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-gray-200 p.text-leaf,
.bg-gray-200 h1.text-leaf,
.bg-gray-200 h2.text-leaf,
.bg-gray-200 h3.text-leaf,
.bg-gray-200 h4.text-leaf,
.bg-gray-200 h5.text-leaf,
.bg-gray-200 h6.text-leaf,
.bg-gray-200 .h1.text-leaf,
.bg-gray-200 .h2.text-leaf,
.bg-gray-200 .h3.text-leaf,
.bg-gray-200 .h4.text-leaf,
.bg-gray-200 .h5.text-leaf,
.bg-gray-200 .h6.text-leaf {
  color: #70b865;
}

.bg-gray-200 p.text-river,
.bg-gray-200 h1.text-river,
.bg-gray-200 h2.text-river,
.bg-gray-200 h3.text-river,
.bg-gray-200 h4.text-river,
.bg-gray-200 h5.text-river,
.bg-gray-200 h6.text-river,
.bg-gray-200 .h1.text-river,
.bg-gray-200 .h2.text-river,
.bg-gray-200 .h3.text-river,
.bg-gray-200 .h4.text-river,
.bg-gray-200 .h5.text-river,
.bg-gray-200 .h6.text-river {
  color: #007d84;
}

.bg-gray-200 p.text-silver,
.bg-gray-200 h1.text-silver,
.bg-gray-200 h2.text-silver,
.bg-gray-200 h3.text-silver,
.bg-gray-200 h4.text-silver,
.bg-gray-200 h5.text-silver,
.bg-gray-200 h6.text-silver,
.bg-gray-200 .h1.text-silver,
.bg-gray-200 .h2.text-silver,
.bg-gray-200 .h3.text-silver,
.bg-gray-200 .h4.text-silver,
.bg-gray-200 .h5.text-silver,
.bg-gray-200 .h6.text-silver {
  color: #9eabae;
}

.bg-gray-200 p.text-mesa,
.bg-gray-200 h1.text-mesa,
.bg-gray-200 h2.text-mesa,
.bg-gray-200 h3.text-mesa,
.bg-gray-200 h4.text-mesa,
.bg-gray-200 h5.text-mesa,
.bg-gray-200 h6.text-mesa,
.bg-gray-200 .h1.text-mesa,
.bg-gray-200 .h2.text-mesa,
.bg-gray-200 .h3.text-mesa,
.bg-gray-200 .h4.text-mesa,
.bg-gray-200 .h5.text-mesa,
.bg-gray-200 .h6.text-mesa {
  color: #a95c42;
}

.bg-gray-200 p.text-ash,
.bg-gray-200 h1.text-ash,
.bg-gray-200 h2.text-ash,
.bg-gray-200 h3.text-ash,
.bg-gray-200 h4.text-ash,
.bg-gray-200 h5.text-ash,
.bg-gray-200 h6.text-ash,
.bg-gray-200 .h1.text-ash,
.bg-gray-200 .h2.text-ash,
.bg-gray-200 .h3.text-ash,
.bg-gray-200 .h4.text-ash,
.bg-gray-200 .h5.text-ash,
.bg-gray-200 .h6.text-ash {
  color: #403635;
}

.bg-gray-200 p.text-sage,
.bg-gray-200 h1.text-sage,
.bg-gray-200 h2.text-sage,
.bg-gray-200 h3.text-sage,
.bg-gray-200 h4.text-sage,
.bg-gray-200 h5.text-sage,
.bg-gray-200 h6.text-sage,
.bg-gray-200 .h1.text-sage,
.bg-gray-200 .h2.text-sage,
.bg-gray-200 .h3.text-sage,
.bg-gray-200 .h4.text-sage,
.bg-gray-200 .h5.text-sage,
.bg-gray-200 .h6.text-sage {
  color: #4a634e;
}

.bg-gray-200 p.text-black,
.bg-gray-200 h1.text-black,
.bg-gray-200 h2.text-black,
.bg-gray-200 h3.text-black,
.bg-gray-200 h4.text-black,
.bg-gray-200 h5.text-black,
.bg-gray-200 h6.text-black,
.bg-gray-200 .h1.text-black,
.bg-gray-200 .h2.text-black,
.bg-gray-200 .h3.text-black,
.bg-gray-200 .h4.text-black,
.bg-gray-200 .h5.text-black,
.bg-gray-200 .h6.text-black {
  color: #000;
}

.bg-gray-200 p.text-primary,
.bg-gray-200 h1.text-primary,
.bg-gray-200 h2.text-primary,
.bg-gray-200 h3.text-primary,
.bg-gray-200 h4.text-primary,
.bg-gray-200 h5.text-primary,
.bg-gray-200 h6.text-primary,
.bg-gray-200 .h1.text-primary,
.bg-gray-200 .h2.text-primary,
.bg-gray-200 .h3.text-primary,
.bg-gray-200 .h4.text-primary,
.bg-gray-200 .h5.text-primary,
.bg-gray-200 .h6.text-primary {
  color: #1e5288;
}

.bg-gray-200 p.text-secondary,
.bg-gray-200 h1.text-secondary,
.bg-gray-200 h2.text-secondary,
.bg-gray-200 h3.text-secondary,
.bg-gray-200 h4.text-secondary,
.bg-gray-200 h5.text-secondary,
.bg-gray-200 h6.text-secondary,
.bg-gray-200 .h1.text-secondary,
.bg-gray-200 .h2.text-secondary,
.bg-gray-200 .h3.text-secondary,
.bg-gray-200 .h4.text-secondary,
.bg-gray-200 .h5.text-secondary,
.bg-gray-200 .h6.text-secondary {
  color: #9eabae;
}

.bg-gray-200 p.text-success,
.bg-gray-200 h1.text-success,
.bg-gray-200 h2.text-success,
.bg-gray-200 h3.text-success,
.bg-gray-200 h4.text-success,
.bg-gray-200 h5.text-success,
.bg-gray-200 h6.text-success,
.bg-gray-200 .h1.text-success,
.bg-gray-200 .h2.text-success,
.bg-gray-200 .h3.text-success,
.bg-gray-200 .h4.text-success,
.bg-gray-200 .h5.text-success,
.bg-gray-200 .h6.text-success {
  color: #70b865;
}

.bg-gray-200 p.text-info,
.bg-gray-200 h1.text-info,
.bg-gray-200 h2.text-info,
.bg-gray-200 h3.text-info,
.bg-gray-200 h4.text-info,
.bg-gray-200 h5.text-info,
.bg-gray-200 h6.text-info,
.bg-gray-200 .h1.text-info,
.bg-gray-200 .h2.text-info,
.bg-gray-200 .h3.text-info,
.bg-gray-200 .h4.text-info,
.bg-gray-200 .h5.text-info,
.bg-gray-200 .h6.text-info {
  color: #81d3eb;
}

.bg-gray-200 p.text-warning,
.bg-gray-200 h1.text-warning,
.bg-gray-200 h2.text-warning,
.bg-gray-200 h3.text-warning,
.bg-gray-200 h4.text-warning,
.bg-gray-200 h5.text-warning,
.bg-gray-200 h6.text-warning,
.bg-gray-200 .h1.text-warning,
.bg-gray-200 .h2.text-warning,
.bg-gray-200 .h3.text-warning,
.bg-gray-200 .h4.text-warning,
.bg-gray-200 .h5.text-warning,
.bg-gray-200 .h6.text-warning {
  color: #f19e1f;
}

.bg-gray-200 p.text-danger,
.bg-gray-200 h1.text-danger,
.bg-gray-200 h2.text-danger,
.bg-gray-200 h3.text-danger,
.bg-gray-200 h4.text-danger,
.bg-gray-200 h5.text-danger,
.bg-gray-200 h6.text-danger,
.bg-gray-200 .h1.text-danger,
.bg-gray-200 .h2.text-danger,
.bg-gray-200 .h3.text-danger,
.bg-gray-200 .h4.text-danger,
.bg-gray-200 .h5.text-danger,
.bg-gray-200 .h6.text-danger {
  color: #ef4056;
}

.bg-gray-200 p.text-light,
.bg-gray-200 h1.text-light,
.bg-gray-200 h2.text-light,
.bg-gray-200 h3.text-light,
.bg-gray-200 h4.text-light,
.bg-gray-200 h5.text-light,
.bg-gray-200 h6.text-light,
.bg-gray-200 .h1.text-light,
.bg-gray-200 .h2.text-light,
.bg-gray-200 .h3.text-light,
.bg-gray-200 .h4.text-light,
.bg-gray-200 .h5.text-light,
.bg-gray-200 .h6.text-light {
  color: #dee2e6;
}

.bg-gray-200 p.text-dark,
.bg-gray-200 h1.text-dark,
.bg-gray-200 h2.text-dark,
.bg-gray-200 h3.text-dark,
.bg-gray-200 h4.text-dark,
.bg-gray-200 h5.text-dark,
.bg-gray-200 h6.text-dark,
.bg-gray-200 .h1.text-dark,
.bg-gray-200 .h2.text-dark,
.bg-gray-200 .h3.text-dark,
.bg-gray-200 .h4.text-dark,
.bg-gray-200 .h5.text-dark,
.bg-gray-200 .h6.text-dark {
  color: #343a40;
}

.bg-gray-200 p.text-primary-dark,
.bg-gray-200 h1.text-primary-dark,
.bg-gray-200 h2.text-primary-dark,
.bg-gray-200 h3.text-primary-dark,
.bg-gray-200 h4.text-primary-dark,
.bg-gray-200 h5.text-primary-dark,
.bg-gray-200 h6.text-primary-dark,
.bg-gray-200 .h1.text-primary-dark,
.bg-gray-200 .h2.text-primary-dark,
.bg-gray-200 .h3.text-primary-dark,
.bg-gray-200 .h4.text-primary-dark,
.bg-gray-200 .h5.text-primary-dark,
.bg-gray-200 .h6.text-primary-dark {
  color: #0c234b;
}

.bg-gray-200 p.text-primary-light,
.bg-gray-200 h1.text-primary-light,
.bg-gray-200 h2.text-primary-light,
.bg-gray-200 h3.text-primary-light,
.bg-gray-200 h4.text-primary-light,
.bg-gray-200 h5.text-primary-light,
.bg-gray-200 h6.text-primary-light,
.bg-gray-200 .h1.text-primary-light,
.bg-gray-200 .h2.text-primary-light,
.bg-gray-200 .h3.text-primary-light,
.bg-gray-200 .h4.text-primary-light,
.bg-gray-200 .h5.text-primary-light,
.bg-gray-200 .h6.text-primary-light {
  color: #378dbd;
}

.bg-gray-200 p.text-accent,
.bg-gray-200 h1.text-accent,
.bg-gray-200 h2.text-accent,
.bg-gray-200 h3.text-accent,
.bg-gray-200 h4.text-accent,
.bg-gray-200 h5.text-accent,
.bg-gray-200 h6.text-accent,
.bg-gray-200 .h1.text-accent,
.bg-gray-200 .h2.text-accent,
.bg-gray-200 .h3.text-accent,
.bg-gray-200 .h4.text-accent,
.bg-gray-200 .h5.text-accent,
.bg-gray-200 .h6.text-accent {
  color: #ab0520;
}

.bg-gray-300 {
  color: #000;
  background-color: #dee2e6;
}

.bg-gray-300 p,
.bg-gray-300 h1,
.bg-gray-300 h2,
.bg-gray-300 h3,
.bg-gray-300 h4,
.bg-gray-300 h5,
.bg-gray-300 h6,
.bg-gray-300 .h1,
.bg-gray-300 .h2,
.bg-gray-300 .h3,
.bg-gray-300 .h4,
.bg-gray-300 .h5,
.bg-gray-300 .h6 {
  color: #000;
}

.bg-gray-300 p.text-blue,
.bg-gray-300 h1.text-blue,
.bg-gray-300 h2.text-blue,
.bg-gray-300 h3.text-blue,
.bg-gray-300 h4.text-blue,
.bg-gray-300 h5.text-blue,
.bg-gray-300 h6.text-blue,
.bg-gray-300 .h1.text-blue,
.bg-gray-300 .h2.text-blue,
.bg-gray-300 .h3.text-blue,
.bg-gray-300 .h4.text-blue,
.bg-gray-300 .h5.text-blue,
.bg-gray-300 .h6.text-blue {
  color: #0c234b;
}

.bg-gray-300 p.text-indigo,
.bg-gray-300 h1.text-indigo,
.bg-gray-300 h2.text-indigo,
.bg-gray-300 h3.text-indigo,
.bg-gray-300 h4.text-indigo,
.bg-gray-300 h5.text-indigo,
.bg-gray-300 h6.text-indigo,
.bg-gray-300 .h1.text-indigo,
.bg-gray-300 .h2.text-indigo,
.bg-gray-300 .h3.text-indigo,
.bg-gray-300 .h4.text-indigo,
.bg-gray-300 .h5.text-indigo,
.bg-gray-300 .h6.text-indigo {
  color: #6610f2;
}

.bg-gray-300 p.text-purple,
.bg-gray-300 h1.text-purple,
.bg-gray-300 h2.text-purple,
.bg-gray-300 h3.text-purple,
.bg-gray-300 h4.text-purple,
.bg-gray-300 h5.text-purple,
.bg-gray-300 h6.text-purple,
.bg-gray-300 .h1.text-purple,
.bg-gray-300 .h2.text-purple,
.bg-gray-300 .h3.text-purple,
.bg-gray-300 .h4.text-purple,
.bg-gray-300 .h5.text-purple,
.bg-gray-300 .h6.text-purple {
  color: #6f42c1;
}

.bg-gray-300 p.text-pink,
.bg-gray-300 h1.text-pink,
.bg-gray-300 h2.text-pink,
.bg-gray-300 h3.text-pink,
.bg-gray-300 h4.text-pink,
.bg-gray-300 h5.text-pink,
.bg-gray-300 h6.text-pink,
.bg-gray-300 .h1.text-pink,
.bg-gray-300 .h2.text-pink,
.bg-gray-300 .h3.text-pink,
.bg-gray-300 .h4.text-pink,
.bg-gray-300 .h5.text-pink,
.bg-gray-300 .h6.text-pink {
  color: #e83e8c;
}

.bg-gray-300 p.text-red,
.bg-gray-300 h1.text-red,
.bg-gray-300 h2.text-red,
.bg-gray-300 h3.text-red,
.bg-gray-300 h4.text-red,
.bg-gray-300 h5.text-red,
.bg-gray-300 h6.text-red,
.bg-gray-300 .h1.text-red,
.bg-gray-300 .h2.text-red,
.bg-gray-300 .h3.text-red,
.bg-gray-300 .h4.text-red,
.bg-gray-300 .h5.text-red,
.bg-gray-300 .h6.text-red {
  color: #ab0520;
}

.bg-gray-300 p.text-orange,
.bg-gray-300 h1.text-orange,
.bg-gray-300 h2.text-orange,
.bg-gray-300 h3.text-orange,
.bg-gray-300 h4.text-orange,
.bg-gray-300 h5.text-orange,
.bg-gray-300 h6.text-orange,
.bg-gray-300 .h1.text-orange,
.bg-gray-300 .h2.text-orange,
.bg-gray-300 .h3.text-orange,
.bg-gray-300 .h4.text-orange,
.bg-gray-300 .h5.text-orange,
.bg-gray-300 .h6.text-orange {
  color: #fd7e14;
}

.bg-gray-300 p.text-yellow,
.bg-gray-300 h1.text-yellow,
.bg-gray-300 h2.text-yellow,
.bg-gray-300 h3.text-yellow,
.bg-gray-300 h4.text-yellow,
.bg-gray-300 h5.text-yellow,
.bg-gray-300 h6.text-yellow,
.bg-gray-300 .h1.text-yellow,
.bg-gray-300 .h2.text-yellow,
.bg-gray-300 .h3.text-yellow,
.bg-gray-300 .h4.text-yellow,
.bg-gray-300 .h5.text-yellow,
.bg-gray-300 .h6.text-yellow {
  color: #ffc107;
}

.bg-gray-300 p.text-green,
.bg-gray-300 h1.text-green,
.bg-gray-300 h2.text-green,
.bg-gray-300 h3.text-green,
.bg-gray-300 h4.text-green,
.bg-gray-300 h5.text-green,
.bg-gray-300 h6.text-green,
.bg-gray-300 .h1.text-green,
.bg-gray-300 .h2.text-green,
.bg-gray-300 .h3.text-green,
.bg-gray-300 .h4.text-green,
.bg-gray-300 .h5.text-green,
.bg-gray-300 .h6.text-green {
  color: #28a745;
}

.bg-gray-300 p.text-teal,
.bg-gray-300 h1.text-teal,
.bg-gray-300 h2.text-teal,
.bg-gray-300 h3.text-teal,
.bg-gray-300 h4.text-teal,
.bg-gray-300 h5.text-teal,
.bg-gray-300 h6.text-teal,
.bg-gray-300 .h1.text-teal,
.bg-gray-300 .h2.text-teal,
.bg-gray-300 .h3.text-teal,
.bg-gray-300 .h4.text-teal,
.bg-gray-300 .h5.text-teal,
.bg-gray-300 .h6.text-teal {
  color: #20c997;
}

.bg-gray-300 p.text-cyan,
.bg-gray-300 h1.text-cyan,
.bg-gray-300 h2.text-cyan,
.bg-gray-300 h3.text-cyan,
.bg-gray-300 h4.text-cyan,
.bg-gray-300 h5.text-cyan,
.bg-gray-300 h6.text-cyan,
.bg-gray-300 .h1.text-cyan,
.bg-gray-300 .h2.text-cyan,
.bg-gray-300 .h3.text-cyan,
.bg-gray-300 .h4.text-cyan,
.bg-gray-300 .h5.text-cyan,
.bg-gray-300 .h6.text-cyan {
  color: #17a2b8;
}

.bg-gray-300 p.text-white,
.bg-gray-300 h1.text-white,
.bg-gray-300 h2.text-white,
.bg-gray-300 h3.text-white,
.bg-gray-300 h4.text-white,
.bg-gray-300 h5.text-white,
.bg-gray-300 h6.text-white,
.bg-gray-300 .h1.text-white,
.bg-gray-300 .h2.text-white,
.bg-gray-300 .h3.text-white,
.bg-gray-300 .h4.text-white,
.bg-gray-300 .h5.text-white,
.bg-gray-300 .h6.text-white {
  color: #fff;
}

.bg-gray-300 p.text-gray,
.bg-gray-300 h1.text-gray,
.bg-gray-300 h2.text-gray,
.bg-gray-300 h3.text-gray,
.bg-gray-300 h4.text-gray,
.bg-gray-300 h5.text-gray,
.bg-gray-300 h6.text-gray,
.bg-gray-300 .h1.text-gray,
.bg-gray-300 .h2.text-gray,
.bg-gray-300 .h3.text-gray,
.bg-gray-300 .h4.text-gray,
.bg-gray-300 .h5.text-gray,
.bg-gray-300 .h6.text-gray {
  color: #6c757d;
}

.bg-gray-300 p.text-gray-dark,
.bg-gray-300 h1.text-gray-dark,
.bg-gray-300 h2.text-gray-dark,
.bg-gray-300 h3.text-gray-dark,
.bg-gray-300 h4.text-gray-dark,
.bg-gray-300 h5.text-gray-dark,
.bg-gray-300 h6.text-gray-dark,
.bg-gray-300 .h1.text-gray-dark,
.bg-gray-300 .h2.text-gray-dark,
.bg-gray-300 .h3.text-gray-dark,
.bg-gray-300 .h4.text-gray-dark,
.bg-gray-300 .h5.text-gray-dark,
.bg-gray-300 .h6.text-gray-dark {
  color: #343a40;
}

.bg-gray-300 p.text-bloom,
.bg-gray-300 h1.text-bloom,
.bg-gray-300 h2.text-bloom,
.bg-gray-300 h3.text-bloom,
.bg-gray-300 h4.text-bloom,
.bg-gray-300 h5.text-bloom,
.bg-gray-300 h6.text-bloom,
.bg-gray-300 .h1.text-bloom,
.bg-gray-300 .h2.text-bloom,
.bg-gray-300 .h3.text-bloom,
.bg-gray-300 .h4.text-bloom,
.bg-gray-300 .h5.text-bloom,
.bg-gray-300 .h6.text-bloom {
  color: #ef4056;
}

.bg-gray-300 p.text-chili,
.bg-gray-300 h1.text-chili,
.bg-gray-300 h2.text-chili,
.bg-gray-300 h3.text-chili,
.bg-gray-300 h4.text-chili,
.bg-gray-300 h5.text-chili,
.bg-gray-300 h6.text-chili,
.bg-gray-300 .h1.text-chili,
.bg-gray-300 .h2.text-chili,
.bg-gray-300 .h3.text-chili,
.bg-gray-300 .h4.text-chili,
.bg-gray-300 .h5.text-chili,
.bg-gray-300 .h6.text-chili {
  color: #8b0015;
}

.bg-gray-300 p.text-sky,
.bg-gray-300 h1.text-sky,
.bg-gray-300 h2.text-sky,
.bg-gray-300 h3.text-sky,
.bg-gray-300 h4.text-sky,
.bg-gray-300 h5.text-sky,
.bg-gray-300 h6.text-sky,
.bg-gray-300 .h1.text-sky,
.bg-gray-300 .h2.text-sky,
.bg-gray-300 .h3.text-sky,
.bg-gray-300 .h4.text-sky,
.bg-gray-300 .h5.text-sky,
.bg-gray-300 .h6.text-sky {
  color: #81d3eb;
}

.bg-gray-300 p.text-oasis,
.bg-gray-300 h1.text-oasis,
.bg-gray-300 h2.text-oasis,
.bg-gray-300 h3.text-oasis,
.bg-gray-300 h4.text-oasis,
.bg-gray-300 h5.text-oasis,
.bg-gray-300 h6.text-oasis,
.bg-gray-300 .h1.text-oasis,
.bg-gray-300 .h2.text-oasis,
.bg-gray-300 .h3.text-oasis,
.bg-gray-300 .h4.text-oasis,
.bg-gray-300 .h5.text-oasis,
.bg-gray-300 .h6.text-oasis {
  color: #378dbd;
}

.bg-gray-300 p.text-azurite,
.bg-gray-300 h1.text-azurite,
.bg-gray-300 h2.text-azurite,
.bg-gray-300 h3.text-azurite,
.bg-gray-300 h4.text-azurite,
.bg-gray-300 h5.text-azurite,
.bg-gray-300 h6.text-azurite,
.bg-gray-300 .h1.text-azurite,
.bg-gray-300 .h2.text-azurite,
.bg-gray-300 .h3.text-azurite,
.bg-gray-300 .h4.text-azurite,
.bg-gray-300 .h5.text-azurite,
.bg-gray-300 .h6.text-azurite {
  color: #1e5288;
}

.bg-gray-300 p.text-midnight,
.bg-gray-300 h1.text-midnight,
.bg-gray-300 h2.text-midnight,
.bg-gray-300 h3.text-midnight,
.bg-gray-300 h4.text-midnight,
.bg-gray-300 h5.text-midnight,
.bg-gray-300 h6.text-midnight,
.bg-gray-300 .h1.text-midnight,
.bg-gray-300 .h2.text-midnight,
.bg-gray-300 .h3.text-midnight,
.bg-gray-300 .h4.text-midnight,
.bg-gray-300 .h5.text-midnight,
.bg-gray-300 .h6.text-midnight {
  color: #001c48;
}

.bg-gray-300 p.text-cool-gray,
.bg-gray-300 h1.text-cool-gray,
.bg-gray-300 h2.text-cool-gray,
.bg-gray-300 h3.text-cool-gray,
.bg-gray-300 h4.text-cool-gray,
.bg-gray-300 h5.text-cool-gray,
.bg-gray-300 h6.text-cool-gray,
.bg-gray-300 .h1.text-cool-gray,
.bg-gray-300 .h2.text-cool-gray,
.bg-gray-300 .h3.text-cool-gray,
.bg-gray-300 .h4.text-cool-gray,
.bg-gray-300 .h5.text-cool-gray,
.bg-gray-300 .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-gray-300 p.text-warm-gray,
.bg-gray-300 h1.text-warm-gray,
.bg-gray-300 h2.text-warm-gray,
.bg-gray-300 h3.text-warm-gray,
.bg-gray-300 h4.text-warm-gray,
.bg-gray-300 h5.text-warm-gray,
.bg-gray-300 h6.text-warm-gray,
.bg-gray-300 .h1.text-warm-gray,
.bg-gray-300 .h2.text-warm-gray,
.bg-gray-300 .h3.text-warm-gray,
.bg-gray-300 .h4.text-warm-gray,
.bg-gray-300 .h5.text-warm-gray,
.bg-gray-300 .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-gray-300 p.text-leaf,
.bg-gray-300 h1.text-leaf,
.bg-gray-300 h2.text-leaf,
.bg-gray-300 h3.text-leaf,
.bg-gray-300 h4.text-leaf,
.bg-gray-300 h5.text-leaf,
.bg-gray-300 h6.text-leaf,
.bg-gray-300 .h1.text-leaf,
.bg-gray-300 .h2.text-leaf,
.bg-gray-300 .h3.text-leaf,
.bg-gray-300 .h4.text-leaf,
.bg-gray-300 .h5.text-leaf,
.bg-gray-300 .h6.text-leaf {
  color: #70b865;
}

.bg-gray-300 p.text-river,
.bg-gray-300 h1.text-river,
.bg-gray-300 h2.text-river,
.bg-gray-300 h3.text-river,
.bg-gray-300 h4.text-river,
.bg-gray-300 h5.text-river,
.bg-gray-300 h6.text-river,
.bg-gray-300 .h1.text-river,
.bg-gray-300 .h2.text-river,
.bg-gray-300 .h3.text-river,
.bg-gray-300 .h4.text-river,
.bg-gray-300 .h5.text-river,
.bg-gray-300 .h6.text-river {
  color: #007d84;
}

.bg-gray-300 p.text-silver,
.bg-gray-300 h1.text-silver,
.bg-gray-300 h2.text-silver,
.bg-gray-300 h3.text-silver,
.bg-gray-300 h4.text-silver,
.bg-gray-300 h5.text-silver,
.bg-gray-300 h6.text-silver,
.bg-gray-300 .h1.text-silver,
.bg-gray-300 .h2.text-silver,
.bg-gray-300 .h3.text-silver,
.bg-gray-300 .h4.text-silver,
.bg-gray-300 .h5.text-silver,
.bg-gray-300 .h6.text-silver {
  color: #9eabae;
}

.bg-gray-300 p.text-mesa,
.bg-gray-300 h1.text-mesa,
.bg-gray-300 h2.text-mesa,
.bg-gray-300 h3.text-mesa,
.bg-gray-300 h4.text-mesa,
.bg-gray-300 h5.text-mesa,
.bg-gray-300 h6.text-mesa,
.bg-gray-300 .h1.text-mesa,
.bg-gray-300 .h2.text-mesa,
.bg-gray-300 .h3.text-mesa,
.bg-gray-300 .h4.text-mesa,
.bg-gray-300 .h5.text-mesa,
.bg-gray-300 .h6.text-mesa {
  color: #a95c42;
}

.bg-gray-300 p.text-ash,
.bg-gray-300 h1.text-ash,
.bg-gray-300 h2.text-ash,
.bg-gray-300 h3.text-ash,
.bg-gray-300 h4.text-ash,
.bg-gray-300 h5.text-ash,
.bg-gray-300 h6.text-ash,
.bg-gray-300 .h1.text-ash,
.bg-gray-300 .h2.text-ash,
.bg-gray-300 .h3.text-ash,
.bg-gray-300 .h4.text-ash,
.bg-gray-300 .h5.text-ash,
.bg-gray-300 .h6.text-ash {
  color: #403635;
}

.bg-gray-300 p.text-sage,
.bg-gray-300 h1.text-sage,
.bg-gray-300 h2.text-sage,
.bg-gray-300 h3.text-sage,
.bg-gray-300 h4.text-sage,
.bg-gray-300 h5.text-sage,
.bg-gray-300 h6.text-sage,
.bg-gray-300 .h1.text-sage,
.bg-gray-300 .h2.text-sage,
.bg-gray-300 .h3.text-sage,
.bg-gray-300 .h4.text-sage,
.bg-gray-300 .h5.text-sage,
.bg-gray-300 .h6.text-sage {
  color: #4a634e;
}

.bg-gray-300 p.text-black,
.bg-gray-300 h1.text-black,
.bg-gray-300 h2.text-black,
.bg-gray-300 h3.text-black,
.bg-gray-300 h4.text-black,
.bg-gray-300 h5.text-black,
.bg-gray-300 h6.text-black,
.bg-gray-300 .h1.text-black,
.bg-gray-300 .h2.text-black,
.bg-gray-300 .h3.text-black,
.bg-gray-300 .h4.text-black,
.bg-gray-300 .h5.text-black,
.bg-gray-300 .h6.text-black {
  color: #000;
}

.bg-gray-300 p.text-primary,
.bg-gray-300 h1.text-primary,
.bg-gray-300 h2.text-primary,
.bg-gray-300 h3.text-primary,
.bg-gray-300 h4.text-primary,
.bg-gray-300 h5.text-primary,
.bg-gray-300 h6.text-primary,
.bg-gray-300 .h1.text-primary,
.bg-gray-300 .h2.text-primary,
.bg-gray-300 .h3.text-primary,
.bg-gray-300 .h4.text-primary,
.bg-gray-300 .h5.text-primary,
.bg-gray-300 .h6.text-primary {
  color: #1e5288;
}

.bg-gray-300 p.text-secondary,
.bg-gray-300 h1.text-secondary,
.bg-gray-300 h2.text-secondary,
.bg-gray-300 h3.text-secondary,
.bg-gray-300 h4.text-secondary,
.bg-gray-300 h5.text-secondary,
.bg-gray-300 h6.text-secondary,
.bg-gray-300 .h1.text-secondary,
.bg-gray-300 .h2.text-secondary,
.bg-gray-300 .h3.text-secondary,
.bg-gray-300 .h4.text-secondary,
.bg-gray-300 .h5.text-secondary,
.bg-gray-300 .h6.text-secondary {
  color: #9eabae;
}

.bg-gray-300 p.text-success,
.bg-gray-300 h1.text-success,
.bg-gray-300 h2.text-success,
.bg-gray-300 h3.text-success,
.bg-gray-300 h4.text-success,
.bg-gray-300 h5.text-success,
.bg-gray-300 h6.text-success,
.bg-gray-300 .h1.text-success,
.bg-gray-300 .h2.text-success,
.bg-gray-300 .h3.text-success,
.bg-gray-300 .h4.text-success,
.bg-gray-300 .h5.text-success,
.bg-gray-300 .h6.text-success {
  color: #70b865;
}

.bg-gray-300 p.text-info,
.bg-gray-300 h1.text-info,
.bg-gray-300 h2.text-info,
.bg-gray-300 h3.text-info,
.bg-gray-300 h4.text-info,
.bg-gray-300 h5.text-info,
.bg-gray-300 h6.text-info,
.bg-gray-300 .h1.text-info,
.bg-gray-300 .h2.text-info,
.bg-gray-300 .h3.text-info,
.bg-gray-300 .h4.text-info,
.bg-gray-300 .h5.text-info,
.bg-gray-300 .h6.text-info {
  color: #81d3eb;
}

.bg-gray-300 p.text-warning,
.bg-gray-300 h1.text-warning,
.bg-gray-300 h2.text-warning,
.bg-gray-300 h3.text-warning,
.bg-gray-300 h4.text-warning,
.bg-gray-300 h5.text-warning,
.bg-gray-300 h6.text-warning,
.bg-gray-300 .h1.text-warning,
.bg-gray-300 .h2.text-warning,
.bg-gray-300 .h3.text-warning,
.bg-gray-300 .h4.text-warning,
.bg-gray-300 .h5.text-warning,
.bg-gray-300 .h6.text-warning {
  color: #f19e1f;
}

.bg-gray-300 p.text-danger,
.bg-gray-300 h1.text-danger,
.bg-gray-300 h2.text-danger,
.bg-gray-300 h3.text-danger,
.bg-gray-300 h4.text-danger,
.bg-gray-300 h5.text-danger,
.bg-gray-300 h6.text-danger,
.bg-gray-300 .h1.text-danger,
.bg-gray-300 .h2.text-danger,
.bg-gray-300 .h3.text-danger,
.bg-gray-300 .h4.text-danger,
.bg-gray-300 .h5.text-danger,
.bg-gray-300 .h6.text-danger {
  color: #ef4056;
}

.bg-gray-300 p.text-light,
.bg-gray-300 h1.text-light,
.bg-gray-300 h2.text-light,
.bg-gray-300 h3.text-light,
.bg-gray-300 h4.text-light,
.bg-gray-300 h5.text-light,
.bg-gray-300 h6.text-light,
.bg-gray-300 .h1.text-light,
.bg-gray-300 .h2.text-light,
.bg-gray-300 .h3.text-light,
.bg-gray-300 .h4.text-light,
.bg-gray-300 .h5.text-light,
.bg-gray-300 .h6.text-light {
  color: #dee2e6;
}

.bg-gray-300 p.text-dark,
.bg-gray-300 h1.text-dark,
.bg-gray-300 h2.text-dark,
.bg-gray-300 h3.text-dark,
.bg-gray-300 h4.text-dark,
.bg-gray-300 h5.text-dark,
.bg-gray-300 h6.text-dark,
.bg-gray-300 .h1.text-dark,
.bg-gray-300 .h2.text-dark,
.bg-gray-300 .h3.text-dark,
.bg-gray-300 .h4.text-dark,
.bg-gray-300 .h5.text-dark,
.bg-gray-300 .h6.text-dark {
  color: #343a40;
}

.bg-gray-300 p.text-primary-dark,
.bg-gray-300 h1.text-primary-dark,
.bg-gray-300 h2.text-primary-dark,
.bg-gray-300 h3.text-primary-dark,
.bg-gray-300 h4.text-primary-dark,
.bg-gray-300 h5.text-primary-dark,
.bg-gray-300 h6.text-primary-dark,
.bg-gray-300 .h1.text-primary-dark,
.bg-gray-300 .h2.text-primary-dark,
.bg-gray-300 .h3.text-primary-dark,
.bg-gray-300 .h4.text-primary-dark,
.bg-gray-300 .h5.text-primary-dark,
.bg-gray-300 .h6.text-primary-dark {
  color: #0c234b;
}

.bg-gray-300 p.text-primary-light,
.bg-gray-300 h1.text-primary-light,
.bg-gray-300 h2.text-primary-light,
.bg-gray-300 h3.text-primary-light,
.bg-gray-300 h4.text-primary-light,
.bg-gray-300 h5.text-primary-light,
.bg-gray-300 h6.text-primary-light,
.bg-gray-300 .h1.text-primary-light,
.bg-gray-300 .h2.text-primary-light,
.bg-gray-300 .h3.text-primary-light,
.bg-gray-300 .h4.text-primary-light,
.bg-gray-300 .h5.text-primary-light,
.bg-gray-300 .h6.text-primary-light {
  color: #378dbd;
}

.bg-gray-300 p.text-accent,
.bg-gray-300 h1.text-accent,
.bg-gray-300 h2.text-accent,
.bg-gray-300 h3.text-accent,
.bg-gray-300 h4.text-accent,
.bg-gray-300 h5.text-accent,
.bg-gray-300 h6.text-accent,
.bg-gray-300 .h1.text-accent,
.bg-gray-300 .h2.text-accent,
.bg-gray-300 .h3.text-accent,
.bg-gray-300 .h4.text-accent,
.bg-gray-300 .h5.text-accent,
.bg-gray-300 .h6.text-accent {
  color: #ab0520;
}

.bg-gray-400 {
  color: #000;
  background-color: #ced4da;
}

.bg-gray-400 p,
.bg-gray-400 h1,
.bg-gray-400 h2,
.bg-gray-400 h3,
.bg-gray-400 h4,
.bg-gray-400 h5,
.bg-gray-400 h6,
.bg-gray-400 .h1,
.bg-gray-400 .h2,
.bg-gray-400 .h3,
.bg-gray-400 .h4,
.bg-gray-400 .h5,
.bg-gray-400 .h6 {
  color: #000;
}

.bg-gray-400 p.text-blue,
.bg-gray-400 h1.text-blue,
.bg-gray-400 h2.text-blue,
.bg-gray-400 h3.text-blue,
.bg-gray-400 h4.text-blue,
.bg-gray-400 h5.text-blue,
.bg-gray-400 h6.text-blue,
.bg-gray-400 .h1.text-blue,
.bg-gray-400 .h2.text-blue,
.bg-gray-400 .h3.text-blue,
.bg-gray-400 .h4.text-blue,
.bg-gray-400 .h5.text-blue,
.bg-gray-400 .h6.text-blue {
  color: #0c234b;
}

.bg-gray-400 p.text-indigo,
.bg-gray-400 h1.text-indigo,
.bg-gray-400 h2.text-indigo,
.bg-gray-400 h3.text-indigo,
.bg-gray-400 h4.text-indigo,
.bg-gray-400 h5.text-indigo,
.bg-gray-400 h6.text-indigo,
.bg-gray-400 .h1.text-indigo,
.bg-gray-400 .h2.text-indigo,
.bg-gray-400 .h3.text-indigo,
.bg-gray-400 .h4.text-indigo,
.bg-gray-400 .h5.text-indigo,
.bg-gray-400 .h6.text-indigo {
  color: #6610f2;
}

.bg-gray-400 p.text-purple,
.bg-gray-400 h1.text-purple,
.bg-gray-400 h2.text-purple,
.bg-gray-400 h3.text-purple,
.bg-gray-400 h4.text-purple,
.bg-gray-400 h5.text-purple,
.bg-gray-400 h6.text-purple,
.bg-gray-400 .h1.text-purple,
.bg-gray-400 .h2.text-purple,
.bg-gray-400 .h3.text-purple,
.bg-gray-400 .h4.text-purple,
.bg-gray-400 .h5.text-purple,
.bg-gray-400 .h6.text-purple {
  color: #6f42c1;
}

.bg-gray-400 p.text-pink,
.bg-gray-400 h1.text-pink,
.bg-gray-400 h2.text-pink,
.bg-gray-400 h3.text-pink,
.bg-gray-400 h4.text-pink,
.bg-gray-400 h5.text-pink,
.bg-gray-400 h6.text-pink,
.bg-gray-400 .h1.text-pink,
.bg-gray-400 .h2.text-pink,
.bg-gray-400 .h3.text-pink,
.bg-gray-400 .h4.text-pink,
.bg-gray-400 .h5.text-pink,
.bg-gray-400 .h6.text-pink {
  color: #e83e8c;
}

.bg-gray-400 p.text-red,
.bg-gray-400 h1.text-red,
.bg-gray-400 h2.text-red,
.bg-gray-400 h3.text-red,
.bg-gray-400 h4.text-red,
.bg-gray-400 h5.text-red,
.bg-gray-400 h6.text-red,
.bg-gray-400 .h1.text-red,
.bg-gray-400 .h2.text-red,
.bg-gray-400 .h3.text-red,
.bg-gray-400 .h4.text-red,
.bg-gray-400 .h5.text-red,
.bg-gray-400 .h6.text-red {
  color: #ab0520;
}

.bg-gray-400 p.text-orange,
.bg-gray-400 h1.text-orange,
.bg-gray-400 h2.text-orange,
.bg-gray-400 h3.text-orange,
.bg-gray-400 h4.text-orange,
.bg-gray-400 h5.text-orange,
.bg-gray-400 h6.text-orange,
.bg-gray-400 .h1.text-orange,
.bg-gray-400 .h2.text-orange,
.bg-gray-400 .h3.text-orange,
.bg-gray-400 .h4.text-orange,
.bg-gray-400 .h5.text-orange,
.bg-gray-400 .h6.text-orange {
  color: #fd7e14;
}

.bg-gray-400 p.text-yellow,
.bg-gray-400 h1.text-yellow,
.bg-gray-400 h2.text-yellow,
.bg-gray-400 h3.text-yellow,
.bg-gray-400 h4.text-yellow,
.bg-gray-400 h5.text-yellow,
.bg-gray-400 h6.text-yellow,
.bg-gray-400 .h1.text-yellow,
.bg-gray-400 .h2.text-yellow,
.bg-gray-400 .h3.text-yellow,
.bg-gray-400 .h4.text-yellow,
.bg-gray-400 .h5.text-yellow,
.bg-gray-400 .h6.text-yellow {
  color: #ffc107;
}

.bg-gray-400 p.text-green,
.bg-gray-400 h1.text-green,
.bg-gray-400 h2.text-green,
.bg-gray-400 h3.text-green,
.bg-gray-400 h4.text-green,
.bg-gray-400 h5.text-green,
.bg-gray-400 h6.text-green,
.bg-gray-400 .h1.text-green,
.bg-gray-400 .h2.text-green,
.bg-gray-400 .h3.text-green,
.bg-gray-400 .h4.text-green,
.bg-gray-400 .h5.text-green,
.bg-gray-400 .h6.text-green {
  color: #28a745;
}

.bg-gray-400 p.text-teal,
.bg-gray-400 h1.text-teal,
.bg-gray-400 h2.text-teal,
.bg-gray-400 h3.text-teal,
.bg-gray-400 h4.text-teal,
.bg-gray-400 h5.text-teal,
.bg-gray-400 h6.text-teal,
.bg-gray-400 .h1.text-teal,
.bg-gray-400 .h2.text-teal,
.bg-gray-400 .h3.text-teal,
.bg-gray-400 .h4.text-teal,
.bg-gray-400 .h5.text-teal,
.bg-gray-400 .h6.text-teal {
  color: #20c997;
}

.bg-gray-400 p.text-cyan,
.bg-gray-400 h1.text-cyan,
.bg-gray-400 h2.text-cyan,
.bg-gray-400 h3.text-cyan,
.bg-gray-400 h4.text-cyan,
.bg-gray-400 h5.text-cyan,
.bg-gray-400 h6.text-cyan,
.bg-gray-400 .h1.text-cyan,
.bg-gray-400 .h2.text-cyan,
.bg-gray-400 .h3.text-cyan,
.bg-gray-400 .h4.text-cyan,
.bg-gray-400 .h5.text-cyan,
.bg-gray-400 .h6.text-cyan {
  color: #17a2b8;
}

.bg-gray-400 p.text-white,
.bg-gray-400 h1.text-white,
.bg-gray-400 h2.text-white,
.bg-gray-400 h3.text-white,
.bg-gray-400 h4.text-white,
.bg-gray-400 h5.text-white,
.bg-gray-400 h6.text-white,
.bg-gray-400 .h1.text-white,
.bg-gray-400 .h2.text-white,
.bg-gray-400 .h3.text-white,
.bg-gray-400 .h4.text-white,
.bg-gray-400 .h5.text-white,
.bg-gray-400 .h6.text-white {
  color: #fff;
}

.bg-gray-400 p.text-gray,
.bg-gray-400 h1.text-gray,
.bg-gray-400 h2.text-gray,
.bg-gray-400 h3.text-gray,
.bg-gray-400 h4.text-gray,
.bg-gray-400 h5.text-gray,
.bg-gray-400 h6.text-gray,
.bg-gray-400 .h1.text-gray,
.bg-gray-400 .h2.text-gray,
.bg-gray-400 .h3.text-gray,
.bg-gray-400 .h4.text-gray,
.bg-gray-400 .h5.text-gray,
.bg-gray-400 .h6.text-gray {
  color: #6c757d;
}

.bg-gray-400 p.text-gray-dark,
.bg-gray-400 h1.text-gray-dark,
.bg-gray-400 h2.text-gray-dark,
.bg-gray-400 h3.text-gray-dark,
.bg-gray-400 h4.text-gray-dark,
.bg-gray-400 h5.text-gray-dark,
.bg-gray-400 h6.text-gray-dark,
.bg-gray-400 .h1.text-gray-dark,
.bg-gray-400 .h2.text-gray-dark,
.bg-gray-400 .h3.text-gray-dark,
.bg-gray-400 .h4.text-gray-dark,
.bg-gray-400 .h5.text-gray-dark,
.bg-gray-400 .h6.text-gray-dark {
  color: #343a40;
}

.bg-gray-400 p.text-bloom,
.bg-gray-400 h1.text-bloom,
.bg-gray-400 h2.text-bloom,
.bg-gray-400 h3.text-bloom,
.bg-gray-400 h4.text-bloom,
.bg-gray-400 h5.text-bloom,
.bg-gray-400 h6.text-bloom,
.bg-gray-400 .h1.text-bloom,
.bg-gray-400 .h2.text-bloom,
.bg-gray-400 .h3.text-bloom,
.bg-gray-400 .h4.text-bloom,
.bg-gray-400 .h5.text-bloom,
.bg-gray-400 .h6.text-bloom {
  color: #ef4056;
}

.bg-gray-400 p.text-chili,
.bg-gray-400 h1.text-chili,
.bg-gray-400 h2.text-chili,
.bg-gray-400 h3.text-chili,
.bg-gray-400 h4.text-chili,
.bg-gray-400 h5.text-chili,
.bg-gray-400 h6.text-chili,
.bg-gray-400 .h1.text-chili,
.bg-gray-400 .h2.text-chili,
.bg-gray-400 .h3.text-chili,
.bg-gray-400 .h4.text-chili,
.bg-gray-400 .h5.text-chili,
.bg-gray-400 .h6.text-chili {
  color: #8b0015;
}

.bg-gray-400 p.text-sky,
.bg-gray-400 h1.text-sky,
.bg-gray-400 h2.text-sky,
.bg-gray-400 h3.text-sky,
.bg-gray-400 h4.text-sky,
.bg-gray-400 h5.text-sky,
.bg-gray-400 h6.text-sky,
.bg-gray-400 .h1.text-sky,
.bg-gray-400 .h2.text-sky,
.bg-gray-400 .h3.text-sky,
.bg-gray-400 .h4.text-sky,
.bg-gray-400 .h5.text-sky,
.bg-gray-400 .h6.text-sky {
  color: #81d3eb;
}

.bg-gray-400 p.text-oasis,
.bg-gray-400 h1.text-oasis,
.bg-gray-400 h2.text-oasis,
.bg-gray-400 h3.text-oasis,
.bg-gray-400 h4.text-oasis,
.bg-gray-400 h5.text-oasis,
.bg-gray-400 h6.text-oasis,
.bg-gray-400 .h1.text-oasis,
.bg-gray-400 .h2.text-oasis,
.bg-gray-400 .h3.text-oasis,
.bg-gray-400 .h4.text-oasis,
.bg-gray-400 .h5.text-oasis,
.bg-gray-400 .h6.text-oasis {
  color: #378dbd;
}

.bg-gray-400 p.text-azurite,
.bg-gray-400 h1.text-azurite,
.bg-gray-400 h2.text-azurite,
.bg-gray-400 h3.text-azurite,
.bg-gray-400 h4.text-azurite,
.bg-gray-400 h5.text-azurite,
.bg-gray-400 h6.text-azurite,
.bg-gray-400 .h1.text-azurite,
.bg-gray-400 .h2.text-azurite,
.bg-gray-400 .h3.text-azurite,
.bg-gray-400 .h4.text-azurite,
.bg-gray-400 .h5.text-azurite,
.bg-gray-400 .h6.text-azurite {
  color: #1e5288;
}

.bg-gray-400 p.text-midnight,
.bg-gray-400 h1.text-midnight,
.bg-gray-400 h2.text-midnight,
.bg-gray-400 h3.text-midnight,
.bg-gray-400 h4.text-midnight,
.bg-gray-400 h5.text-midnight,
.bg-gray-400 h6.text-midnight,
.bg-gray-400 .h1.text-midnight,
.bg-gray-400 .h2.text-midnight,
.bg-gray-400 .h3.text-midnight,
.bg-gray-400 .h4.text-midnight,
.bg-gray-400 .h5.text-midnight,
.bg-gray-400 .h6.text-midnight {
  color: #001c48;
}

.bg-gray-400 p.text-cool-gray,
.bg-gray-400 h1.text-cool-gray,
.bg-gray-400 h2.text-cool-gray,
.bg-gray-400 h3.text-cool-gray,
.bg-gray-400 h4.text-cool-gray,
.bg-gray-400 h5.text-cool-gray,
.bg-gray-400 h6.text-cool-gray,
.bg-gray-400 .h1.text-cool-gray,
.bg-gray-400 .h2.text-cool-gray,
.bg-gray-400 .h3.text-cool-gray,
.bg-gray-400 .h4.text-cool-gray,
.bg-gray-400 .h5.text-cool-gray,
.bg-gray-400 .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-gray-400 p.text-warm-gray,
.bg-gray-400 h1.text-warm-gray,
.bg-gray-400 h2.text-warm-gray,
.bg-gray-400 h3.text-warm-gray,
.bg-gray-400 h4.text-warm-gray,
.bg-gray-400 h5.text-warm-gray,
.bg-gray-400 h6.text-warm-gray,
.bg-gray-400 .h1.text-warm-gray,
.bg-gray-400 .h2.text-warm-gray,
.bg-gray-400 .h3.text-warm-gray,
.bg-gray-400 .h4.text-warm-gray,
.bg-gray-400 .h5.text-warm-gray,
.bg-gray-400 .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-gray-400 p.text-leaf,
.bg-gray-400 h1.text-leaf,
.bg-gray-400 h2.text-leaf,
.bg-gray-400 h3.text-leaf,
.bg-gray-400 h4.text-leaf,
.bg-gray-400 h5.text-leaf,
.bg-gray-400 h6.text-leaf,
.bg-gray-400 .h1.text-leaf,
.bg-gray-400 .h2.text-leaf,
.bg-gray-400 .h3.text-leaf,
.bg-gray-400 .h4.text-leaf,
.bg-gray-400 .h5.text-leaf,
.bg-gray-400 .h6.text-leaf {
  color: #70b865;
}

.bg-gray-400 p.text-river,
.bg-gray-400 h1.text-river,
.bg-gray-400 h2.text-river,
.bg-gray-400 h3.text-river,
.bg-gray-400 h4.text-river,
.bg-gray-400 h5.text-river,
.bg-gray-400 h6.text-river,
.bg-gray-400 .h1.text-river,
.bg-gray-400 .h2.text-river,
.bg-gray-400 .h3.text-river,
.bg-gray-400 .h4.text-river,
.bg-gray-400 .h5.text-river,
.bg-gray-400 .h6.text-river {
  color: #007d84;
}

.bg-gray-400 p.text-silver,
.bg-gray-400 h1.text-silver,
.bg-gray-400 h2.text-silver,
.bg-gray-400 h3.text-silver,
.bg-gray-400 h4.text-silver,
.bg-gray-400 h5.text-silver,
.bg-gray-400 h6.text-silver,
.bg-gray-400 .h1.text-silver,
.bg-gray-400 .h2.text-silver,
.bg-gray-400 .h3.text-silver,
.bg-gray-400 .h4.text-silver,
.bg-gray-400 .h5.text-silver,
.bg-gray-400 .h6.text-silver {
  color: #9eabae;
}

.bg-gray-400 p.text-mesa,
.bg-gray-400 h1.text-mesa,
.bg-gray-400 h2.text-mesa,
.bg-gray-400 h3.text-mesa,
.bg-gray-400 h4.text-mesa,
.bg-gray-400 h5.text-mesa,
.bg-gray-400 h6.text-mesa,
.bg-gray-400 .h1.text-mesa,
.bg-gray-400 .h2.text-mesa,
.bg-gray-400 .h3.text-mesa,
.bg-gray-400 .h4.text-mesa,
.bg-gray-400 .h5.text-mesa,
.bg-gray-400 .h6.text-mesa {
  color: #a95c42;
}

.bg-gray-400 p.text-ash,
.bg-gray-400 h1.text-ash,
.bg-gray-400 h2.text-ash,
.bg-gray-400 h3.text-ash,
.bg-gray-400 h4.text-ash,
.bg-gray-400 h5.text-ash,
.bg-gray-400 h6.text-ash,
.bg-gray-400 .h1.text-ash,
.bg-gray-400 .h2.text-ash,
.bg-gray-400 .h3.text-ash,
.bg-gray-400 .h4.text-ash,
.bg-gray-400 .h5.text-ash,
.bg-gray-400 .h6.text-ash {
  color: #403635;
}

.bg-gray-400 p.text-sage,
.bg-gray-400 h1.text-sage,
.bg-gray-400 h2.text-sage,
.bg-gray-400 h3.text-sage,
.bg-gray-400 h4.text-sage,
.bg-gray-400 h5.text-sage,
.bg-gray-400 h6.text-sage,
.bg-gray-400 .h1.text-sage,
.bg-gray-400 .h2.text-sage,
.bg-gray-400 .h3.text-sage,
.bg-gray-400 .h4.text-sage,
.bg-gray-400 .h5.text-sage,
.bg-gray-400 .h6.text-sage {
  color: #4a634e;
}

.bg-gray-400 p.text-black,
.bg-gray-400 h1.text-black,
.bg-gray-400 h2.text-black,
.bg-gray-400 h3.text-black,
.bg-gray-400 h4.text-black,
.bg-gray-400 h5.text-black,
.bg-gray-400 h6.text-black,
.bg-gray-400 .h1.text-black,
.bg-gray-400 .h2.text-black,
.bg-gray-400 .h3.text-black,
.bg-gray-400 .h4.text-black,
.bg-gray-400 .h5.text-black,
.bg-gray-400 .h6.text-black {
  color: #000;
}

.bg-gray-400 p.text-primary,
.bg-gray-400 h1.text-primary,
.bg-gray-400 h2.text-primary,
.bg-gray-400 h3.text-primary,
.bg-gray-400 h4.text-primary,
.bg-gray-400 h5.text-primary,
.bg-gray-400 h6.text-primary,
.bg-gray-400 .h1.text-primary,
.bg-gray-400 .h2.text-primary,
.bg-gray-400 .h3.text-primary,
.bg-gray-400 .h4.text-primary,
.bg-gray-400 .h5.text-primary,
.bg-gray-400 .h6.text-primary {
  color: #1e5288;
}

.bg-gray-400 p.text-secondary,
.bg-gray-400 h1.text-secondary,
.bg-gray-400 h2.text-secondary,
.bg-gray-400 h3.text-secondary,
.bg-gray-400 h4.text-secondary,
.bg-gray-400 h5.text-secondary,
.bg-gray-400 h6.text-secondary,
.bg-gray-400 .h1.text-secondary,
.bg-gray-400 .h2.text-secondary,
.bg-gray-400 .h3.text-secondary,
.bg-gray-400 .h4.text-secondary,
.bg-gray-400 .h5.text-secondary,
.bg-gray-400 .h6.text-secondary {
  color: #9eabae;
}

.bg-gray-400 p.text-success,
.bg-gray-400 h1.text-success,
.bg-gray-400 h2.text-success,
.bg-gray-400 h3.text-success,
.bg-gray-400 h4.text-success,
.bg-gray-400 h5.text-success,
.bg-gray-400 h6.text-success,
.bg-gray-400 .h1.text-success,
.bg-gray-400 .h2.text-success,
.bg-gray-400 .h3.text-success,
.bg-gray-400 .h4.text-success,
.bg-gray-400 .h5.text-success,
.bg-gray-400 .h6.text-success {
  color: #70b865;
}

.bg-gray-400 p.text-info,
.bg-gray-400 h1.text-info,
.bg-gray-400 h2.text-info,
.bg-gray-400 h3.text-info,
.bg-gray-400 h4.text-info,
.bg-gray-400 h5.text-info,
.bg-gray-400 h6.text-info,
.bg-gray-400 .h1.text-info,
.bg-gray-400 .h2.text-info,
.bg-gray-400 .h3.text-info,
.bg-gray-400 .h4.text-info,
.bg-gray-400 .h5.text-info,
.bg-gray-400 .h6.text-info {
  color: #81d3eb;
}

.bg-gray-400 p.text-warning,
.bg-gray-400 h1.text-warning,
.bg-gray-400 h2.text-warning,
.bg-gray-400 h3.text-warning,
.bg-gray-400 h4.text-warning,
.bg-gray-400 h5.text-warning,
.bg-gray-400 h6.text-warning,
.bg-gray-400 .h1.text-warning,
.bg-gray-400 .h2.text-warning,
.bg-gray-400 .h3.text-warning,
.bg-gray-400 .h4.text-warning,
.bg-gray-400 .h5.text-warning,
.bg-gray-400 .h6.text-warning {
  color: #f19e1f;
}

.bg-gray-400 p.text-danger,
.bg-gray-400 h1.text-danger,
.bg-gray-400 h2.text-danger,
.bg-gray-400 h3.text-danger,
.bg-gray-400 h4.text-danger,
.bg-gray-400 h5.text-danger,
.bg-gray-400 h6.text-danger,
.bg-gray-400 .h1.text-danger,
.bg-gray-400 .h2.text-danger,
.bg-gray-400 .h3.text-danger,
.bg-gray-400 .h4.text-danger,
.bg-gray-400 .h5.text-danger,
.bg-gray-400 .h6.text-danger {
  color: #ef4056;
}

.bg-gray-400 p.text-light,
.bg-gray-400 h1.text-light,
.bg-gray-400 h2.text-light,
.bg-gray-400 h3.text-light,
.bg-gray-400 h4.text-light,
.bg-gray-400 h5.text-light,
.bg-gray-400 h6.text-light,
.bg-gray-400 .h1.text-light,
.bg-gray-400 .h2.text-light,
.bg-gray-400 .h3.text-light,
.bg-gray-400 .h4.text-light,
.bg-gray-400 .h5.text-light,
.bg-gray-400 .h6.text-light {
  color: #dee2e6;
}

.bg-gray-400 p.text-dark,
.bg-gray-400 h1.text-dark,
.bg-gray-400 h2.text-dark,
.bg-gray-400 h3.text-dark,
.bg-gray-400 h4.text-dark,
.bg-gray-400 h5.text-dark,
.bg-gray-400 h6.text-dark,
.bg-gray-400 .h1.text-dark,
.bg-gray-400 .h2.text-dark,
.bg-gray-400 .h3.text-dark,
.bg-gray-400 .h4.text-dark,
.bg-gray-400 .h5.text-dark,
.bg-gray-400 .h6.text-dark {
  color: #343a40;
}

.bg-gray-400 p.text-primary-dark,
.bg-gray-400 h1.text-primary-dark,
.bg-gray-400 h2.text-primary-dark,
.bg-gray-400 h3.text-primary-dark,
.bg-gray-400 h4.text-primary-dark,
.bg-gray-400 h5.text-primary-dark,
.bg-gray-400 h6.text-primary-dark,
.bg-gray-400 .h1.text-primary-dark,
.bg-gray-400 .h2.text-primary-dark,
.bg-gray-400 .h3.text-primary-dark,
.bg-gray-400 .h4.text-primary-dark,
.bg-gray-400 .h5.text-primary-dark,
.bg-gray-400 .h6.text-primary-dark {
  color: #0c234b;
}

.bg-gray-400 p.text-primary-light,
.bg-gray-400 h1.text-primary-light,
.bg-gray-400 h2.text-primary-light,
.bg-gray-400 h3.text-primary-light,
.bg-gray-400 h4.text-primary-light,
.bg-gray-400 h5.text-primary-light,
.bg-gray-400 h6.text-primary-light,
.bg-gray-400 .h1.text-primary-light,
.bg-gray-400 .h2.text-primary-light,
.bg-gray-400 .h3.text-primary-light,
.bg-gray-400 .h4.text-primary-light,
.bg-gray-400 .h5.text-primary-light,
.bg-gray-400 .h6.text-primary-light {
  color: #378dbd;
}

.bg-gray-400 p.text-accent,
.bg-gray-400 h1.text-accent,
.bg-gray-400 h2.text-accent,
.bg-gray-400 h3.text-accent,
.bg-gray-400 h4.text-accent,
.bg-gray-400 h5.text-accent,
.bg-gray-400 h6.text-accent,
.bg-gray-400 .h1.text-accent,
.bg-gray-400 .h2.text-accent,
.bg-gray-400 .h3.text-accent,
.bg-gray-400 .h4.text-accent,
.bg-gray-400 .h5.text-accent,
.bg-gray-400 .h6.text-accent {
  color: #ab0520;
}

.bg-gray-500 {
  color: #000;
  background-color: #adb5bd;
}

.bg-gray-500 p,
.bg-gray-500 h1,
.bg-gray-500 h2,
.bg-gray-500 h3,
.bg-gray-500 h4,
.bg-gray-500 h5,
.bg-gray-500 h6,
.bg-gray-500 .h1,
.bg-gray-500 .h2,
.bg-gray-500 .h3,
.bg-gray-500 .h4,
.bg-gray-500 .h5,
.bg-gray-500 .h6 {
  color: #000;
}

.bg-gray-500 p.text-blue,
.bg-gray-500 h1.text-blue,
.bg-gray-500 h2.text-blue,
.bg-gray-500 h3.text-blue,
.bg-gray-500 h4.text-blue,
.bg-gray-500 h5.text-blue,
.bg-gray-500 h6.text-blue,
.bg-gray-500 .h1.text-blue,
.bg-gray-500 .h2.text-blue,
.bg-gray-500 .h3.text-blue,
.bg-gray-500 .h4.text-blue,
.bg-gray-500 .h5.text-blue,
.bg-gray-500 .h6.text-blue {
  color: #0c234b;
}

.bg-gray-500 p.text-indigo,
.bg-gray-500 h1.text-indigo,
.bg-gray-500 h2.text-indigo,
.bg-gray-500 h3.text-indigo,
.bg-gray-500 h4.text-indigo,
.bg-gray-500 h5.text-indigo,
.bg-gray-500 h6.text-indigo,
.bg-gray-500 .h1.text-indigo,
.bg-gray-500 .h2.text-indigo,
.bg-gray-500 .h3.text-indigo,
.bg-gray-500 .h4.text-indigo,
.bg-gray-500 .h5.text-indigo,
.bg-gray-500 .h6.text-indigo {
  color: #6610f2;
}

.bg-gray-500 p.text-purple,
.bg-gray-500 h1.text-purple,
.bg-gray-500 h2.text-purple,
.bg-gray-500 h3.text-purple,
.bg-gray-500 h4.text-purple,
.bg-gray-500 h5.text-purple,
.bg-gray-500 h6.text-purple,
.bg-gray-500 .h1.text-purple,
.bg-gray-500 .h2.text-purple,
.bg-gray-500 .h3.text-purple,
.bg-gray-500 .h4.text-purple,
.bg-gray-500 .h5.text-purple,
.bg-gray-500 .h6.text-purple {
  color: #6f42c1;
}

.bg-gray-500 p.text-pink,
.bg-gray-500 h1.text-pink,
.bg-gray-500 h2.text-pink,
.bg-gray-500 h3.text-pink,
.bg-gray-500 h4.text-pink,
.bg-gray-500 h5.text-pink,
.bg-gray-500 h6.text-pink,
.bg-gray-500 .h1.text-pink,
.bg-gray-500 .h2.text-pink,
.bg-gray-500 .h3.text-pink,
.bg-gray-500 .h4.text-pink,
.bg-gray-500 .h5.text-pink,
.bg-gray-500 .h6.text-pink {
  color: #e83e8c;
}

.bg-gray-500 p.text-red,
.bg-gray-500 h1.text-red,
.bg-gray-500 h2.text-red,
.bg-gray-500 h3.text-red,
.bg-gray-500 h4.text-red,
.bg-gray-500 h5.text-red,
.bg-gray-500 h6.text-red,
.bg-gray-500 .h1.text-red,
.bg-gray-500 .h2.text-red,
.bg-gray-500 .h3.text-red,
.bg-gray-500 .h4.text-red,
.bg-gray-500 .h5.text-red,
.bg-gray-500 .h6.text-red {
  color: #ab0520;
}

.bg-gray-500 p.text-orange,
.bg-gray-500 h1.text-orange,
.bg-gray-500 h2.text-orange,
.bg-gray-500 h3.text-orange,
.bg-gray-500 h4.text-orange,
.bg-gray-500 h5.text-orange,
.bg-gray-500 h6.text-orange,
.bg-gray-500 .h1.text-orange,
.bg-gray-500 .h2.text-orange,
.bg-gray-500 .h3.text-orange,
.bg-gray-500 .h4.text-orange,
.bg-gray-500 .h5.text-orange,
.bg-gray-500 .h6.text-orange {
  color: #fd7e14;
}

.bg-gray-500 p.text-yellow,
.bg-gray-500 h1.text-yellow,
.bg-gray-500 h2.text-yellow,
.bg-gray-500 h3.text-yellow,
.bg-gray-500 h4.text-yellow,
.bg-gray-500 h5.text-yellow,
.bg-gray-500 h6.text-yellow,
.bg-gray-500 .h1.text-yellow,
.bg-gray-500 .h2.text-yellow,
.bg-gray-500 .h3.text-yellow,
.bg-gray-500 .h4.text-yellow,
.bg-gray-500 .h5.text-yellow,
.bg-gray-500 .h6.text-yellow {
  color: #ffc107;
}

.bg-gray-500 p.text-green,
.bg-gray-500 h1.text-green,
.bg-gray-500 h2.text-green,
.bg-gray-500 h3.text-green,
.bg-gray-500 h4.text-green,
.bg-gray-500 h5.text-green,
.bg-gray-500 h6.text-green,
.bg-gray-500 .h1.text-green,
.bg-gray-500 .h2.text-green,
.bg-gray-500 .h3.text-green,
.bg-gray-500 .h4.text-green,
.bg-gray-500 .h5.text-green,
.bg-gray-500 .h6.text-green {
  color: #28a745;
}

.bg-gray-500 p.text-teal,
.bg-gray-500 h1.text-teal,
.bg-gray-500 h2.text-teal,
.bg-gray-500 h3.text-teal,
.bg-gray-500 h4.text-teal,
.bg-gray-500 h5.text-teal,
.bg-gray-500 h6.text-teal,
.bg-gray-500 .h1.text-teal,
.bg-gray-500 .h2.text-teal,
.bg-gray-500 .h3.text-teal,
.bg-gray-500 .h4.text-teal,
.bg-gray-500 .h5.text-teal,
.bg-gray-500 .h6.text-teal {
  color: #20c997;
}

.bg-gray-500 p.text-cyan,
.bg-gray-500 h1.text-cyan,
.bg-gray-500 h2.text-cyan,
.bg-gray-500 h3.text-cyan,
.bg-gray-500 h4.text-cyan,
.bg-gray-500 h5.text-cyan,
.bg-gray-500 h6.text-cyan,
.bg-gray-500 .h1.text-cyan,
.bg-gray-500 .h2.text-cyan,
.bg-gray-500 .h3.text-cyan,
.bg-gray-500 .h4.text-cyan,
.bg-gray-500 .h5.text-cyan,
.bg-gray-500 .h6.text-cyan {
  color: #17a2b8;
}

.bg-gray-500 p.text-white,
.bg-gray-500 h1.text-white,
.bg-gray-500 h2.text-white,
.bg-gray-500 h3.text-white,
.bg-gray-500 h4.text-white,
.bg-gray-500 h5.text-white,
.bg-gray-500 h6.text-white,
.bg-gray-500 .h1.text-white,
.bg-gray-500 .h2.text-white,
.bg-gray-500 .h3.text-white,
.bg-gray-500 .h4.text-white,
.bg-gray-500 .h5.text-white,
.bg-gray-500 .h6.text-white {
  color: #fff;
}

.bg-gray-500 p.text-gray,
.bg-gray-500 h1.text-gray,
.bg-gray-500 h2.text-gray,
.bg-gray-500 h3.text-gray,
.bg-gray-500 h4.text-gray,
.bg-gray-500 h5.text-gray,
.bg-gray-500 h6.text-gray,
.bg-gray-500 .h1.text-gray,
.bg-gray-500 .h2.text-gray,
.bg-gray-500 .h3.text-gray,
.bg-gray-500 .h4.text-gray,
.bg-gray-500 .h5.text-gray,
.bg-gray-500 .h6.text-gray {
  color: #6c757d;
}

.bg-gray-500 p.text-gray-dark,
.bg-gray-500 h1.text-gray-dark,
.bg-gray-500 h2.text-gray-dark,
.bg-gray-500 h3.text-gray-dark,
.bg-gray-500 h4.text-gray-dark,
.bg-gray-500 h5.text-gray-dark,
.bg-gray-500 h6.text-gray-dark,
.bg-gray-500 .h1.text-gray-dark,
.bg-gray-500 .h2.text-gray-dark,
.bg-gray-500 .h3.text-gray-dark,
.bg-gray-500 .h4.text-gray-dark,
.bg-gray-500 .h5.text-gray-dark,
.bg-gray-500 .h6.text-gray-dark {
  color: #343a40;
}

.bg-gray-500 p.text-bloom,
.bg-gray-500 h1.text-bloom,
.bg-gray-500 h2.text-bloom,
.bg-gray-500 h3.text-bloom,
.bg-gray-500 h4.text-bloom,
.bg-gray-500 h5.text-bloom,
.bg-gray-500 h6.text-bloom,
.bg-gray-500 .h1.text-bloom,
.bg-gray-500 .h2.text-bloom,
.bg-gray-500 .h3.text-bloom,
.bg-gray-500 .h4.text-bloom,
.bg-gray-500 .h5.text-bloom,
.bg-gray-500 .h6.text-bloom {
  color: #ef4056;
}

.bg-gray-500 p.text-chili,
.bg-gray-500 h1.text-chili,
.bg-gray-500 h2.text-chili,
.bg-gray-500 h3.text-chili,
.bg-gray-500 h4.text-chili,
.bg-gray-500 h5.text-chili,
.bg-gray-500 h6.text-chili,
.bg-gray-500 .h1.text-chili,
.bg-gray-500 .h2.text-chili,
.bg-gray-500 .h3.text-chili,
.bg-gray-500 .h4.text-chili,
.bg-gray-500 .h5.text-chili,
.bg-gray-500 .h6.text-chili {
  color: #8b0015;
}

.bg-gray-500 p.text-sky,
.bg-gray-500 h1.text-sky,
.bg-gray-500 h2.text-sky,
.bg-gray-500 h3.text-sky,
.bg-gray-500 h4.text-sky,
.bg-gray-500 h5.text-sky,
.bg-gray-500 h6.text-sky,
.bg-gray-500 .h1.text-sky,
.bg-gray-500 .h2.text-sky,
.bg-gray-500 .h3.text-sky,
.bg-gray-500 .h4.text-sky,
.bg-gray-500 .h5.text-sky,
.bg-gray-500 .h6.text-sky {
  color: #81d3eb;
}

.bg-gray-500 p.text-oasis,
.bg-gray-500 h1.text-oasis,
.bg-gray-500 h2.text-oasis,
.bg-gray-500 h3.text-oasis,
.bg-gray-500 h4.text-oasis,
.bg-gray-500 h5.text-oasis,
.bg-gray-500 h6.text-oasis,
.bg-gray-500 .h1.text-oasis,
.bg-gray-500 .h2.text-oasis,
.bg-gray-500 .h3.text-oasis,
.bg-gray-500 .h4.text-oasis,
.bg-gray-500 .h5.text-oasis,
.bg-gray-500 .h6.text-oasis {
  color: #378dbd;
}

.bg-gray-500 p.text-azurite,
.bg-gray-500 h1.text-azurite,
.bg-gray-500 h2.text-azurite,
.bg-gray-500 h3.text-azurite,
.bg-gray-500 h4.text-azurite,
.bg-gray-500 h5.text-azurite,
.bg-gray-500 h6.text-azurite,
.bg-gray-500 .h1.text-azurite,
.bg-gray-500 .h2.text-azurite,
.bg-gray-500 .h3.text-azurite,
.bg-gray-500 .h4.text-azurite,
.bg-gray-500 .h5.text-azurite,
.bg-gray-500 .h6.text-azurite {
  color: #1e5288;
}

.bg-gray-500 p.text-midnight,
.bg-gray-500 h1.text-midnight,
.bg-gray-500 h2.text-midnight,
.bg-gray-500 h3.text-midnight,
.bg-gray-500 h4.text-midnight,
.bg-gray-500 h5.text-midnight,
.bg-gray-500 h6.text-midnight,
.bg-gray-500 .h1.text-midnight,
.bg-gray-500 .h2.text-midnight,
.bg-gray-500 .h3.text-midnight,
.bg-gray-500 .h4.text-midnight,
.bg-gray-500 .h5.text-midnight,
.bg-gray-500 .h6.text-midnight {
  color: #001c48;
}

.bg-gray-500 p.text-cool-gray,
.bg-gray-500 h1.text-cool-gray,
.bg-gray-500 h2.text-cool-gray,
.bg-gray-500 h3.text-cool-gray,
.bg-gray-500 h4.text-cool-gray,
.bg-gray-500 h5.text-cool-gray,
.bg-gray-500 h6.text-cool-gray,
.bg-gray-500 .h1.text-cool-gray,
.bg-gray-500 .h2.text-cool-gray,
.bg-gray-500 .h3.text-cool-gray,
.bg-gray-500 .h4.text-cool-gray,
.bg-gray-500 .h5.text-cool-gray,
.bg-gray-500 .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-gray-500 p.text-warm-gray,
.bg-gray-500 h1.text-warm-gray,
.bg-gray-500 h2.text-warm-gray,
.bg-gray-500 h3.text-warm-gray,
.bg-gray-500 h4.text-warm-gray,
.bg-gray-500 h5.text-warm-gray,
.bg-gray-500 h6.text-warm-gray,
.bg-gray-500 .h1.text-warm-gray,
.bg-gray-500 .h2.text-warm-gray,
.bg-gray-500 .h3.text-warm-gray,
.bg-gray-500 .h4.text-warm-gray,
.bg-gray-500 .h5.text-warm-gray,
.bg-gray-500 .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-gray-500 p.text-leaf,
.bg-gray-500 h1.text-leaf,
.bg-gray-500 h2.text-leaf,
.bg-gray-500 h3.text-leaf,
.bg-gray-500 h4.text-leaf,
.bg-gray-500 h5.text-leaf,
.bg-gray-500 h6.text-leaf,
.bg-gray-500 .h1.text-leaf,
.bg-gray-500 .h2.text-leaf,
.bg-gray-500 .h3.text-leaf,
.bg-gray-500 .h4.text-leaf,
.bg-gray-500 .h5.text-leaf,
.bg-gray-500 .h6.text-leaf {
  color: #70b865;
}

.bg-gray-500 p.text-river,
.bg-gray-500 h1.text-river,
.bg-gray-500 h2.text-river,
.bg-gray-500 h3.text-river,
.bg-gray-500 h4.text-river,
.bg-gray-500 h5.text-river,
.bg-gray-500 h6.text-river,
.bg-gray-500 .h1.text-river,
.bg-gray-500 .h2.text-river,
.bg-gray-500 .h3.text-river,
.bg-gray-500 .h4.text-river,
.bg-gray-500 .h5.text-river,
.bg-gray-500 .h6.text-river {
  color: #007d84;
}

.bg-gray-500 p.text-silver,
.bg-gray-500 h1.text-silver,
.bg-gray-500 h2.text-silver,
.bg-gray-500 h3.text-silver,
.bg-gray-500 h4.text-silver,
.bg-gray-500 h5.text-silver,
.bg-gray-500 h6.text-silver,
.bg-gray-500 .h1.text-silver,
.bg-gray-500 .h2.text-silver,
.bg-gray-500 .h3.text-silver,
.bg-gray-500 .h4.text-silver,
.bg-gray-500 .h5.text-silver,
.bg-gray-500 .h6.text-silver {
  color: #9eabae;
}

.bg-gray-500 p.text-mesa,
.bg-gray-500 h1.text-mesa,
.bg-gray-500 h2.text-mesa,
.bg-gray-500 h3.text-mesa,
.bg-gray-500 h4.text-mesa,
.bg-gray-500 h5.text-mesa,
.bg-gray-500 h6.text-mesa,
.bg-gray-500 .h1.text-mesa,
.bg-gray-500 .h2.text-mesa,
.bg-gray-500 .h3.text-mesa,
.bg-gray-500 .h4.text-mesa,
.bg-gray-500 .h5.text-mesa,
.bg-gray-500 .h6.text-mesa {
  color: #a95c42;
}

.bg-gray-500 p.text-ash,
.bg-gray-500 h1.text-ash,
.bg-gray-500 h2.text-ash,
.bg-gray-500 h3.text-ash,
.bg-gray-500 h4.text-ash,
.bg-gray-500 h5.text-ash,
.bg-gray-500 h6.text-ash,
.bg-gray-500 .h1.text-ash,
.bg-gray-500 .h2.text-ash,
.bg-gray-500 .h3.text-ash,
.bg-gray-500 .h4.text-ash,
.bg-gray-500 .h5.text-ash,
.bg-gray-500 .h6.text-ash {
  color: #403635;
}

.bg-gray-500 p.text-sage,
.bg-gray-500 h1.text-sage,
.bg-gray-500 h2.text-sage,
.bg-gray-500 h3.text-sage,
.bg-gray-500 h4.text-sage,
.bg-gray-500 h5.text-sage,
.bg-gray-500 h6.text-sage,
.bg-gray-500 .h1.text-sage,
.bg-gray-500 .h2.text-sage,
.bg-gray-500 .h3.text-sage,
.bg-gray-500 .h4.text-sage,
.bg-gray-500 .h5.text-sage,
.bg-gray-500 .h6.text-sage {
  color: #4a634e;
}

.bg-gray-500 p.text-black,
.bg-gray-500 h1.text-black,
.bg-gray-500 h2.text-black,
.bg-gray-500 h3.text-black,
.bg-gray-500 h4.text-black,
.bg-gray-500 h5.text-black,
.bg-gray-500 h6.text-black,
.bg-gray-500 .h1.text-black,
.bg-gray-500 .h2.text-black,
.bg-gray-500 .h3.text-black,
.bg-gray-500 .h4.text-black,
.bg-gray-500 .h5.text-black,
.bg-gray-500 .h6.text-black {
  color: #000;
}

.bg-gray-500 p.text-primary,
.bg-gray-500 h1.text-primary,
.bg-gray-500 h2.text-primary,
.bg-gray-500 h3.text-primary,
.bg-gray-500 h4.text-primary,
.bg-gray-500 h5.text-primary,
.bg-gray-500 h6.text-primary,
.bg-gray-500 .h1.text-primary,
.bg-gray-500 .h2.text-primary,
.bg-gray-500 .h3.text-primary,
.bg-gray-500 .h4.text-primary,
.bg-gray-500 .h5.text-primary,
.bg-gray-500 .h6.text-primary {
  color: #1e5288;
}

.bg-gray-500 p.text-secondary,
.bg-gray-500 h1.text-secondary,
.bg-gray-500 h2.text-secondary,
.bg-gray-500 h3.text-secondary,
.bg-gray-500 h4.text-secondary,
.bg-gray-500 h5.text-secondary,
.bg-gray-500 h6.text-secondary,
.bg-gray-500 .h1.text-secondary,
.bg-gray-500 .h2.text-secondary,
.bg-gray-500 .h3.text-secondary,
.bg-gray-500 .h4.text-secondary,
.bg-gray-500 .h5.text-secondary,
.bg-gray-500 .h6.text-secondary {
  color: #9eabae;
}

.bg-gray-500 p.text-success,
.bg-gray-500 h1.text-success,
.bg-gray-500 h2.text-success,
.bg-gray-500 h3.text-success,
.bg-gray-500 h4.text-success,
.bg-gray-500 h5.text-success,
.bg-gray-500 h6.text-success,
.bg-gray-500 .h1.text-success,
.bg-gray-500 .h2.text-success,
.bg-gray-500 .h3.text-success,
.bg-gray-500 .h4.text-success,
.bg-gray-500 .h5.text-success,
.bg-gray-500 .h6.text-success {
  color: #70b865;
}

.bg-gray-500 p.text-info,
.bg-gray-500 h1.text-info,
.bg-gray-500 h2.text-info,
.bg-gray-500 h3.text-info,
.bg-gray-500 h4.text-info,
.bg-gray-500 h5.text-info,
.bg-gray-500 h6.text-info,
.bg-gray-500 .h1.text-info,
.bg-gray-500 .h2.text-info,
.bg-gray-500 .h3.text-info,
.bg-gray-500 .h4.text-info,
.bg-gray-500 .h5.text-info,
.bg-gray-500 .h6.text-info {
  color: #81d3eb;
}

.bg-gray-500 p.text-warning,
.bg-gray-500 h1.text-warning,
.bg-gray-500 h2.text-warning,
.bg-gray-500 h3.text-warning,
.bg-gray-500 h4.text-warning,
.bg-gray-500 h5.text-warning,
.bg-gray-500 h6.text-warning,
.bg-gray-500 .h1.text-warning,
.bg-gray-500 .h2.text-warning,
.bg-gray-500 .h3.text-warning,
.bg-gray-500 .h4.text-warning,
.bg-gray-500 .h5.text-warning,
.bg-gray-500 .h6.text-warning {
  color: #f19e1f;
}

.bg-gray-500 p.text-danger,
.bg-gray-500 h1.text-danger,
.bg-gray-500 h2.text-danger,
.bg-gray-500 h3.text-danger,
.bg-gray-500 h4.text-danger,
.bg-gray-500 h5.text-danger,
.bg-gray-500 h6.text-danger,
.bg-gray-500 .h1.text-danger,
.bg-gray-500 .h2.text-danger,
.bg-gray-500 .h3.text-danger,
.bg-gray-500 .h4.text-danger,
.bg-gray-500 .h5.text-danger,
.bg-gray-500 .h6.text-danger {
  color: #ef4056;
}

.bg-gray-500 p.text-light,
.bg-gray-500 h1.text-light,
.bg-gray-500 h2.text-light,
.bg-gray-500 h3.text-light,
.bg-gray-500 h4.text-light,
.bg-gray-500 h5.text-light,
.bg-gray-500 h6.text-light,
.bg-gray-500 .h1.text-light,
.bg-gray-500 .h2.text-light,
.bg-gray-500 .h3.text-light,
.bg-gray-500 .h4.text-light,
.bg-gray-500 .h5.text-light,
.bg-gray-500 .h6.text-light {
  color: #dee2e6;
}

.bg-gray-500 p.text-dark,
.bg-gray-500 h1.text-dark,
.bg-gray-500 h2.text-dark,
.bg-gray-500 h3.text-dark,
.bg-gray-500 h4.text-dark,
.bg-gray-500 h5.text-dark,
.bg-gray-500 h6.text-dark,
.bg-gray-500 .h1.text-dark,
.bg-gray-500 .h2.text-dark,
.bg-gray-500 .h3.text-dark,
.bg-gray-500 .h4.text-dark,
.bg-gray-500 .h5.text-dark,
.bg-gray-500 .h6.text-dark {
  color: #343a40;
}

.bg-gray-500 p.text-primary-dark,
.bg-gray-500 h1.text-primary-dark,
.bg-gray-500 h2.text-primary-dark,
.bg-gray-500 h3.text-primary-dark,
.bg-gray-500 h4.text-primary-dark,
.bg-gray-500 h5.text-primary-dark,
.bg-gray-500 h6.text-primary-dark,
.bg-gray-500 .h1.text-primary-dark,
.bg-gray-500 .h2.text-primary-dark,
.bg-gray-500 .h3.text-primary-dark,
.bg-gray-500 .h4.text-primary-dark,
.bg-gray-500 .h5.text-primary-dark,
.bg-gray-500 .h6.text-primary-dark {
  color: #0c234b;
}

.bg-gray-500 p.text-primary-light,
.bg-gray-500 h1.text-primary-light,
.bg-gray-500 h2.text-primary-light,
.bg-gray-500 h3.text-primary-light,
.bg-gray-500 h4.text-primary-light,
.bg-gray-500 h5.text-primary-light,
.bg-gray-500 h6.text-primary-light,
.bg-gray-500 .h1.text-primary-light,
.bg-gray-500 .h2.text-primary-light,
.bg-gray-500 .h3.text-primary-light,
.bg-gray-500 .h4.text-primary-light,
.bg-gray-500 .h5.text-primary-light,
.bg-gray-500 .h6.text-primary-light {
  color: #378dbd;
}

.bg-gray-500 p.text-accent,
.bg-gray-500 h1.text-accent,
.bg-gray-500 h2.text-accent,
.bg-gray-500 h3.text-accent,
.bg-gray-500 h4.text-accent,
.bg-gray-500 h5.text-accent,
.bg-gray-500 h6.text-accent,
.bg-gray-500 .h1.text-accent,
.bg-gray-500 .h2.text-accent,
.bg-gray-500 .h3.text-accent,
.bg-gray-500 .h4.text-accent,
.bg-gray-500 .h5.text-accent,
.bg-gray-500 .h6.text-accent {
  color: #ab0520;
}

.bg-gray-600 {
  color: #fff;
  background-color: #6c757d;
}

.bg-gray-600 p,
.bg-gray-600 h1,
.bg-gray-600 h2,
.bg-gray-600 h3,
.bg-gray-600 h4,
.bg-gray-600 h5,
.bg-gray-600 h6,
.bg-gray-600 .h1,
.bg-gray-600 .h2,
.bg-gray-600 .h3,
.bg-gray-600 .h4,
.bg-gray-600 .h5,
.bg-gray-600 .h6 {
  color: #fff;
}

.bg-gray-600 p.text-blue,
.bg-gray-600 h1.text-blue,
.bg-gray-600 h2.text-blue,
.bg-gray-600 h3.text-blue,
.bg-gray-600 h4.text-blue,
.bg-gray-600 h5.text-blue,
.bg-gray-600 h6.text-blue,
.bg-gray-600 .h1.text-blue,
.bg-gray-600 .h2.text-blue,
.bg-gray-600 .h3.text-blue,
.bg-gray-600 .h4.text-blue,
.bg-gray-600 .h5.text-blue,
.bg-gray-600 .h6.text-blue {
  color: #0c234b;
}

.bg-gray-600 p.text-indigo,
.bg-gray-600 h1.text-indigo,
.bg-gray-600 h2.text-indigo,
.bg-gray-600 h3.text-indigo,
.bg-gray-600 h4.text-indigo,
.bg-gray-600 h5.text-indigo,
.bg-gray-600 h6.text-indigo,
.bg-gray-600 .h1.text-indigo,
.bg-gray-600 .h2.text-indigo,
.bg-gray-600 .h3.text-indigo,
.bg-gray-600 .h4.text-indigo,
.bg-gray-600 .h5.text-indigo,
.bg-gray-600 .h6.text-indigo {
  color: #6610f2;
}

.bg-gray-600 p.text-purple,
.bg-gray-600 h1.text-purple,
.bg-gray-600 h2.text-purple,
.bg-gray-600 h3.text-purple,
.bg-gray-600 h4.text-purple,
.bg-gray-600 h5.text-purple,
.bg-gray-600 h6.text-purple,
.bg-gray-600 .h1.text-purple,
.bg-gray-600 .h2.text-purple,
.bg-gray-600 .h3.text-purple,
.bg-gray-600 .h4.text-purple,
.bg-gray-600 .h5.text-purple,
.bg-gray-600 .h6.text-purple {
  color: #6f42c1;
}

.bg-gray-600 p.text-pink,
.bg-gray-600 h1.text-pink,
.bg-gray-600 h2.text-pink,
.bg-gray-600 h3.text-pink,
.bg-gray-600 h4.text-pink,
.bg-gray-600 h5.text-pink,
.bg-gray-600 h6.text-pink,
.bg-gray-600 .h1.text-pink,
.bg-gray-600 .h2.text-pink,
.bg-gray-600 .h3.text-pink,
.bg-gray-600 .h4.text-pink,
.bg-gray-600 .h5.text-pink,
.bg-gray-600 .h6.text-pink {
  color: #e83e8c;
}

.bg-gray-600 p.text-red,
.bg-gray-600 h1.text-red,
.bg-gray-600 h2.text-red,
.bg-gray-600 h3.text-red,
.bg-gray-600 h4.text-red,
.bg-gray-600 h5.text-red,
.bg-gray-600 h6.text-red,
.bg-gray-600 .h1.text-red,
.bg-gray-600 .h2.text-red,
.bg-gray-600 .h3.text-red,
.bg-gray-600 .h4.text-red,
.bg-gray-600 .h5.text-red,
.bg-gray-600 .h6.text-red {
  color: #ab0520;
}

.bg-gray-600 p.text-orange,
.bg-gray-600 h1.text-orange,
.bg-gray-600 h2.text-orange,
.bg-gray-600 h3.text-orange,
.bg-gray-600 h4.text-orange,
.bg-gray-600 h5.text-orange,
.bg-gray-600 h6.text-orange,
.bg-gray-600 .h1.text-orange,
.bg-gray-600 .h2.text-orange,
.bg-gray-600 .h3.text-orange,
.bg-gray-600 .h4.text-orange,
.bg-gray-600 .h5.text-orange,
.bg-gray-600 .h6.text-orange {
  color: #fd7e14;
}

.bg-gray-600 p.text-yellow,
.bg-gray-600 h1.text-yellow,
.bg-gray-600 h2.text-yellow,
.bg-gray-600 h3.text-yellow,
.bg-gray-600 h4.text-yellow,
.bg-gray-600 h5.text-yellow,
.bg-gray-600 h6.text-yellow,
.bg-gray-600 .h1.text-yellow,
.bg-gray-600 .h2.text-yellow,
.bg-gray-600 .h3.text-yellow,
.bg-gray-600 .h4.text-yellow,
.bg-gray-600 .h5.text-yellow,
.bg-gray-600 .h6.text-yellow {
  color: #ffc107;
}

.bg-gray-600 p.text-green,
.bg-gray-600 h1.text-green,
.bg-gray-600 h2.text-green,
.bg-gray-600 h3.text-green,
.bg-gray-600 h4.text-green,
.bg-gray-600 h5.text-green,
.bg-gray-600 h6.text-green,
.bg-gray-600 .h1.text-green,
.bg-gray-600 .h2.text-green,
.bg-gray-600 .h3.text-green,
.bg-gray-600 .h4.text-green,
.bg-gray-600 .h5.text-green,
.bg-gray-600 .h6.text-green {
  color: #28a745;
}

.bg-gray-600 p.text-teal,
.bg-gray-600 h1.text-teal,
.bg-gray-600 h2.text-teal,
.bg-gray-600 h3.text-teal,
.bg-gray-600 h4.text-teal,
.bg-gray-600 h5.text-teal,
.bg-gray-600 h6.text-teal,
.bg-gray-600 .h1.text-teal,
.bg-gray-600 .h2.text-teal,
.bg-gray-600 .h3.text-teal,
.bg-gray-600 .h4.text-teal,
.bg-gray-600 .h5.text-teal,
.bg-gray-600 .h6.text-teal {
  color: #20c997;
}

.bg-gray-600 p.text-cyan,
.bg-gray-600 h1.text-cyan,
.bg-gray-600 h2.text-cyan,
.bg-gray-600 h3.text-cyan,
.bg-gray-600 h4.text-cyan,
.bg-gray-600 h5.text-cyan,
.bg-gray-600 h6.text-cyan,
.bg-gray-600 .h1.text-cyan,
.bg-gray-600 .h2.text-cyan,
.bg-gray-600 .h3.text-cyan,
.bg-gray-600 .h4.text-cyan,
.bg-gray-600 .h5.text-cyan,
.bg-gray-600 .h6.text-cyan {
  color: #17a2b8;
}

.bg-gray-600 p.text-white,
.bg-gray-600 h1.text-white,
.bg-gray-600 h2.text-white,
.bg-gray-600 h3.text-white,
.bg-gray-600 h4.text-white,
.bg-gray-600 h5.text-white,
.bg-gray-600 h6.text-white,
.bg-gray-600 .h1.text-white,
.bg-gray-600 .h2.text-white,
.bg-gray-600 .h3.text-white,
.bg-gray-600 .h4.text-white,
.bg-gray-600 .h5.text-white,
.bg-gray-600 .h6.text-white {
  color: #fff;
}

.bg-gray-600 p.text-gray,
.bg-gray-600 h1.text-gray,
.bg-gray-600 h2.text-gray,
.bg-gray-600 h3.text-gray,
.bg-gray-600 h4.text-gray,
.bg-gray-600 h5.text-gray,
.bg-gray-600 h6.text-gray,
.bg-gray-600 .h1.text-gray,
.bg-gray-600 .h2.text-gray,
.bg-gray-600 .h3.text-gray,
.bg-gray-600 .h4.text-gray,
.bg-gray-600 .h5.text-gray,
.bg-gray-600 .h6.text-gray {
  color: #6c757d;
}

.bg-gray-600 p.text-gray-dark,
.bg-gray-600 h1.text-gray-dark,
.bg-gray-600 h2.text-gray-dark,
.bg-gray-600 h3.text-gray-dark,
.bg-gray-600 h4.text-gray-dark,
.bg-gray-600 h5.text-gray-dark,
.bg-gray-600 h6.text-gray-dark,
.bg-gray-600 .h1.text-gray-dark,
.bg-gray-600 .h2.text-gray-dark,
.bg-gray-600 .h3.text-gray-dark,
.bg-gray-600 .h4.text-gray-dark,
.bg-gray-600 .h5.text-gray-dark,
.bg-gray-600 .h6.text-gray-dark {
  color: #343a40;
}

.bg-gray-600 p.text-bloom,
.bg-gray-600 h1.text-bloom,
.bg-gray-600 h2.text-bloom,
.bg-gray-600 h3.text-bloom,
.bg-gray-600 h4.text-bloom,
.bg-gray-600 h5.text-bloom,
.bg-gray-600 h6.text-bloom,
.bg-gray-600 .h1.text-bloom,
.bg-gray-600 .h2.text-bloom,
.bg-gray-600 .h3.text-bloom,
.bg-gray-600 .h4.text-bloom,
.bg-gray-600 .h5.text-bloom,
.bg-gray-600 .h6.text-bloom {
  color: #ef4056;
}

.bg-gray-600 p.text-chili,
.bg-gray-600 h1.text-chili,
.bg-gray-600 h2.text-chili,
.bg-gray-600 h3.text-chili,
.bg-gray-600 h4.text-chili,
.bg-gray-600 h5.text-chili,
.bg-gray-600 h6.text-chili,
.bg-gray-600 .h1.text-chili,
.bg-gray-600 .h2.text-chili,
.bg-gray-600 .h3.text-chili,
.bg-gray-600 .h4.text-chili,
.bg-gray-600 .h5.text-chili,
.bg-gray-600 .h6.text-chili {
  color: #8b0015;
}

.bg-gray-600 p.text-sky,
.bg-gray-600 h1.text-sky,
.bg-gray-600 h2.text-sky,
.bg-gray-600 h3.text-sky,
.bg-gray-600 h4.text-sky,
.bg-gray-600 h5.text-sky,
.bg-gray-600 h6.text-sky,
.bg-gray-600 .h1.text-sky,
.bg-gray-600 .h2.text-sky,
.bg-gray-600 .h3.text-sky,
.bg-gray-600 .h4.text-sky,
.bg-gray-600 .h5.text-sky,
.bg-gray-600 .h6.text-sky {
  color: #81d3eb;
}

.bg-gray-600 p.text-oasis,
.bg-gray-600 h1.text-oasis,
.bg-gray-600 h2.text-oasis,
.bg-gray-600 h3.text-oasis,
.bg-gray-600 h4.text-oasis,
.bg-gray-600 h5.text-oasis,
.bg-gray-600 h6.text-oasis,
.bg-gray-600 .h1.text-oasis,
.bg-gray-600 .h2.text-oasis,
.bg-gray-600 .h3.text-oasis,
.bg-gray-600 .h4.text-oasis,
.bg-gray-600 .h5.text-oasis,
.bg-gray-600 .h6.text-oasis {
  color: #378dbd;
}

.bg-gray-600 p.text-azurite,
.bg-gray-600 h1.text-azurite,
.bg-gray-600 h2.text-azurite,
.bg-gray-600 h3.text-azurite,
.bg-gray-600 h4.text-azurite,
.bg-gray-600 h5.text-azurite,
.bg-gray-600 h6.text-azurite,
.bg-gray-600 .h1.text-azurite,
.bg-gray-600 .h2.text-azurite,
.bg-gray-600 .h3.text-azurite,
.bg-gray-600 .h4.text-azurite,
.bg-gray-600 .h5.text-azurite,
.bg-gray-600 .h6.text-azurite {
  color: #1e5288;
}

.bg-gray-600 p.text-midnight,
.bg-gray-600 h1.text-midnight,
.bg-gray-600 h2.text-midnight,
.bg-gray-600 h3.text-midnight,
.bg-gray-600 h4.text-midnight,
.bg-gray-600 h5.text-midnight,
.bg-gray-600 h6.text-midnight,
.bg-gray-600 .h1.text-midnight,
.bg-gray-600 .h2.text-midnight,
.bg-gray-600 .h3.text-midnight,
.bg-gray-600 .h4.text-midnight,
.bg-gray-600 .h5.text-midnight,
.bg-gray-600 .h6.text-midnight {
  color: #001c48;
}

.bg-gray-600 p.text-cool-gray,
.bg-gray-600 h1.text-cool-gray,
.bg-gray-600 h2.text-cool-gray,
.bg-gray-600 h3.text-cool-gray,
.bg-gray-600 h4.text-cool-gray,
.bg-gray-600 h5.text-cool-gray,
.bg-gray-600 h6.text-cool-gray,
.bg-gray-600 .h1.text-cool-gray,
.bg-gray-600 .h2.text-cool-gray,
.bg-gray-600 .h3.text-cool-gray,
.bg-gray-600 .h4.text-cool-gray,
.bg-gray-600 .h5.text-cool-gray,
.bg-gray-600 .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-gray-600 p.text-warm-gray,
.bg-gray-600 h1.text-warm-gray,
.bg-gray-600 h2.text-warm-gray,
.bg-gray-600 h3.text-warm-gray,
.bg-gray-600 h4.text-warm-gray,
.bg-gray-600 h5.text-warm-gray,
.bg-gray-600 h6.text-warm-gray,
.bg-gray-600 .h1.text-warm-gray,
.bg-gray-600 .h2.text-warm-gray,
.bg-gray-600 .h3.text-warm-gray,
.bg-gray-600 .h4.text-warm-gray,
.bg-gray-600 .h5.text-warm-gray,
.bg-gray-600 .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-gray-600 p.text-leaf,
.bg-gray-600 h1.text-leaf,
.bg-gray-600 h2.text-leaf,
.bg-gray-600 h3.text-leaf,
.bg-gray-600 h4.text-leaf,
.bg-gray-600 h5.text-leaf,
.bg-gray-600 h6.text-leaf,
.bg-gray-600 .h1.text-leaf,
.bg-gray-600 .h2.text-leaf,
.bg-gray-600 .h3.text-leaf,
.bg-gray-600 .h4.text-leaf,
.bg-gray-600 .h5.text-leaf,
.bg-gray-600 .h6.text-leaf {
  color: #70b865;
}

.bg-gray-600 p.text-river,
.bg-gray-600 h1.text-river,
.bg-gray-600 h2.text-river,
.bg-gray-600 h3.text-river,
.bg-gray-600 h4.text-river,
.bg-gray-600 h5.text-river,
.bg-gray-600 h6.text-river,
.bg-gray-600 .h1.text-river,
.bg-gray-600 .h2.text-river,
.bg-gray-600 .h3.text-river,
.bg-gray-600 .h4.text-river,
.bg-gray-600 .h5.text-river,
.bg-gray-600 .h6.text-river {
  color: #007d84;
}

.bg-gray-600 p.text-silver,
.bg-gray-600 h1.text-silver,
.bg-gray-600 h2.text-silver,
.bg-gray-600 h3.text-silver,
.bg-gray-600 h4.text-silver,
.bg-gray-600 h5.text-silver,
.bg-gray-600 h6.text-silver,
.bg-gray-600 .h1.text-silver,
.bg-gray-600 .h2.text-silver,
.bg-gray-600 .h3.text-silver,
.bg-gray-600 .h4.text-silver,
.bg-gray-600 .h5.text-silver,
.bg-gray-600 .h6.text-silver {
  color: #9eabae;
}

.bg-gray-600 p.text-mesa,
.bg-gray-600 h1.text-mesa,
.bg-gray-600 h2.text-mesa,
.bg-gray-600 h3.text-mesa,
.bg-gray-600 h4.text-mesa,
.bg-gray-600 h5.text-mesa,
.bg-gray-600 h6.text-mesa,
.bg-gray-600 .h1.text-mesa,
.bg-gray-600 .h2.text-mesa,
.bg-gray-600 .h3.text-mesa,
.bg-gray-600 .h4.text-mesa,
.bg-gray-600 .h5.text-mesa,
.bg-gray-600 .h6.text-mesa {
  color: #a95c42;
}

.bg-gray-600 p.text-ash,
.bg-gray-600 h1.text-ash,
.bg-gray-600 h2.text-ash,
.bg-gray-600 h3.text-ash,
.bg-gray-600 h4.text-ash,
.bg-gray-600 h5.text-ash,
.bg-gray-600 h6.text-ash,
.bg-gray-600 .h1.text-ash,
.bg-gray-600 .h2.text-ash,
.bg-gray-600 .h3.text-ash,
.bg-gray-600 .h4.text-ash,
.bg-gray-600 .h5.text-ash,
.bg-gray-600 .h6.text-ash {
  color: #403635;
}

.bg-gray-600 p.text-sage,
.bg-gray-600 h1.text-sage,
.bg-gray-600 h2.text-sage,
.bg-gray-600 h3.text-sage,
.bg-gray-600 h4.text-sage,
.bg-gray-600 h5.text-sage,
.bg-gray-600 h6.text-sage,
.bg-gray-600 .h1.text-sage,
.bg-gray-600 .h2.text-sage,
.bg-gray-600 .h3.text-sage,
.bg-gray-600 .h4.text-sage,
.bg-gray-600 .h5.text-sage,
.bg-gray-600 .h6.text-sage {
  color: #4a634e;
}

.bg-gray-600 p.text-black,
.bg-gray-600 h1.text-black,
.bg-gray-600 h2.text-black,
.bg-gray-600 h3.text-black,
.bg-gray-600 h4.text-black,
.bg-gray-600 h5.text-black,
.bg-gray-600 h6.text-black,
.bg-gray-600 .h1.text-black,
.bg-gray-600 .h2.text-black,
.bg-gray-600 .h3.text-black,
.bg-gray-600 .h4.text-black,
.bg-gray-600 .h5.text-black,
.bg-gray-600 .h6.text-black {
  color: #000;
}

.bg-gray-600 p.text-primary,
.bg-gray-600 h1.text-primary,
.bg-gray-600 h2.text-primary,
.bg-gray-600 h3.text-primary,
.bg-gray-600 h4.text-primary,
.bg-gray-600 h5.text-primary,
.bg-gray-600 h6.text-primary,
.bg-gray-600 .h1.text-primary,
.bg-gray-600 .h2.text-primary,
.bg-gray-600 .h3.text-primary,
.bg-gray-600 .h4.text-primary,
.bg-gray-600 .h5.text-primary,
.bg-gray-600 .h6.text-primary {
  color: #1e5288;
}

.bg-gray-600 p.text-secondary,
.bg-gray-600 h1.text-secondary,
.bg-gray-600 h2.text-secondary,
.bg-gray-600 h3.text-secondary,
.bg-gray-600 h4.text-secondary,
.bg-gray-600 h5.text-secondary,
.bg-gray-600 h6.text-secondary,
.bg-gray-600 .h1.text-secondary,
.bg-gray-600 .h2.text-secondary,
.bg-gray-600 .h3.text-secondary,
.bg-gray-600 .h4.text-secondary,
.bg-gray-600 .h5.text-secondary,
.bg-gray-600 .h6.text-secondary {
  color: #9eabae;
}

.bg-gray-600 p.text-success,
.bg-gray-600 h1.text-success,
.bg-gray-600 h2.text-success,
.bg-gray-600 h3.text-success,
.bg-gray-600 h4.text-success,
.bg-gray-600 h5.text-success,
.bg-gray-600 h6.text-success,
.bg-gray-600 .h1.text-success,
.bg-gray-600 .h2.text-success,
.bg-gray-600 .h3.text-success,
.bg-gray-600 .h4.text-success,
.bg-gray-600 .h5.text-success,
.bg-gray-600 .h6.text-success {
  color: #70b865;
}

.bg-gray-600 p.text-info,
.bg-gray-600 h1.text-info,
.bg-gray-600 h2.text-info,
.bg-gray-600 h3.text-info,
.bg-gray-600 h4.text-info,
.bg-gray-600 h5.text-info,
.bg-gray-600 h6.text-info,
.bg-gray-600 .h1.text-info,
.bg-gray-600 .h2.text-info,
.bg-gray-600 .h3.text-info,
.bg-gray-600 .h4.text-info,
.bg-gray-600 .h5.text-info,
.bg-gray-600 .h6.text-info {
  color: #81d3eb;
}

.bg-gray-600 p.text-warning,
.bg-gray-600 h1.text-warning,
.bg-gray-600 h2.text-warning,
.bg-gray-600 h3.text-warning,
.bg-gray-600 h4.text-warning,
.bg-gray-600 h5.text-warning,
.bg-gray-600 h6.text-warning,
.bg-gray-600 .h1.text-warning,
.bg-gray-600 .h2.text-warning,
.bg-gray-600 .h3.text-warning,
.bg-gray-600 .h4.text-warning,
.bg-gray-600 .h5.text-warning,
.bg-gray-600 .h6.text-warning {
  color: #f19e1f;
}

.bg-gray-600 p.text-danger,
.bg-gray-600 h1.text-danger,
.bg-gray-600 h2.text-danger,
.bg-gray-600 h3.text-danger,
.bg-gray-600 h4.text-danger,
.bg-gray-600 h5.text-danger,
.bg-gray-600 h6.text-danger,
.bg-gray-600 .h1.text-danger,
.bg-gray-600 .h2.text-danger,
.bg-gray-600 .h3.text-danger,
.bg-gray-600 .h4.text-danger,
.bg-gray-600 .h5.text-danger,
.bg-gray-600 .h6.text-danger {
  color: #ef4056;
}

.bg-gray-600 p.text-light,
.bg-gray-600 h1.text-light,
.bg-gray-600 h2.text-light,
.bg-gray-600 h3.text-light,
.bg-gray-600 h4.text-light,
.bg-gray-600 h5.text-light,
.bg-gray-600 h6.text-light,
.bg-gray-600 .h1.text-light,
.bg-gray-600 .h2.text-light,
.bg-gray-600 .h3.text-light,
.bg-gray-600 .h4.text-light,
.bg-gray-600 .h5.text-light,
.bg-gray-600 .h6.text-light {
  color: #dee2e6;
}

.bg-gray-600 p.text-dark,
.bg-gray-600 h1.text-dark,
.bg-gray-600 h2.text-dark,
.bg-gray-600 h3.text-dark,
.bg-gray-600 h4.text-dark,
.bg-gray-600 h5.text-dark,
.bg-gray-600 h6.text-dark,
.bg-gray-600 .h1.text-dark,
.bg-gray-600 .h2.text-dark,
.bg-gray-600 .h3.text-dark,
.bg-gray-600 .h4.text-dark,
.bg-gray-600 .h5.text-dark,
.bg-gray-600 .h6.text-dark {
  color: #343a40;
}

.bg-gray-600 p.text-primary-dark,
.bg-gray-600 h1.text-primary-dark,
.bg-gray-600 h2.text-primary-dark,
.bg-gray-600 h3.text-primary-dark,
.bg-gray-600 h4.text-primary-dark,
.bg-gray-600 h5.text-primary-dark,
.bg-gray-600 h6.text-primary-dark,
.bg-gray-600 .h1.text-primary-dark,
.bg-gray-600 .h2.text-primary-dark,
.bg-gray-600 .h3.text-primary-dark,
.bg-gray-600 .h4.text-primary-dark,
.bg-gray-600 .h5.text-primary-dark,
.bg-gray-600 .h6.text-primary-dark {
  color: #0c234b;
}

.bg-gray-600 p.text-primary-light,
.bg-gray-600 h1.text-primary-light,
.bg-gray-600 h2.text-primary-light,
.bg-gray-600 h3.text-primary-light,
.bg-gray-600 h4.text-primary-light,
.bg-gray-600 h5.text-primary-light,
.bg-gray-600 h6.text-primary-light,
.bg-gray-600 .h1.text-primary-light,
.bg-gray-600 .h2.text-primary-light,
.bg-gray-600 .h3.text-primary-light,
.bg-gray-600 .h4.text-primary-light,
.bg-gray-600 .h5.text-primary-light,
.bg-gray-600 .h6.text-primary-light {
  color: #378dbd;
}

.bg-gray-600 p.text-accent,
.bg-gray-600 h1.text-accent,
.bg-gray-600 h2.text-accent,
.bg-gray-600 h3.text-accent,
.bg-gray-600 h4.text-accent,
.bg-gray-600 h5.text-accent,
.bg-gray-600 h6.text-accent,
.bg-gray-600 .h1.text-accent,
.bg-gray-600 .h2.text-accent,
.bg-gray-600 .h3.text-accent,
.bg-gray-600 .h4.text-accent,
.bg-gray-600 .h5.text-accent,
.bg-gray-600 .h6.text-accent {
  color: #ab0520;
}

.bg-gray-700 {
  color: #fff;
  background-color: #495057;
}

.bg-gray-700 p,
.bg-gray-700 h1,
.bg-gray-700 h2,
.bg-gray-700 h3,
.bg-gray-700 h4,
.bg-gray-700 h5,
.bg-gray-700 h6,
.bg-gray-700 .h1,
.bg-gray-700 .h2,
.bg-gray-700 .h3,
.bg-gray-700 .h4,
.bg-gray-700 .h5,
.bg-gray-700 .h6 {
  color: #fff;
}

.bg-gray-700 p.text-blue,
.bg-gray-700 h1.text-blue,
.bg-gray-700 h2.text-blue,
.bg-gray-700 h3.text-blue,
.bg-gray-700 h4.text-blue,
.bg-gray-700 h5.text-blue,
.bg-gray-700 h6.text-blue,
.bg-gray-700 .h1.text-blue,
.bg-gray-700 .h2.text-blue,
.bg-gray-700 .h3.text-blue,
.bg-gray-700 .h4.text-blue,
.bg-gray-700 .h5.text-blue,
.bg-gray-700 .h6.text-blue {
  color: #0c234b;
}

.bg-gray-700 p.text-indigo,
.bg-gray-700 h1.text-indigo,
.bg-gray-700 h2.text-indigo,
.bg-gray-700 h3.text-indigo,
.bg-gray-700 h4.text-indigo,
.bg-gray-700 h5.text-indigo,
.bg-gray-700 h6.text-indigo,
.bg-gray-700 .h1.text-indigo,
.bg-gray-700 .h2.text-indigo,
.bg-gray-700 .h3.text-indigo,
.bg-gray-700 .h4.text-indigo,
.bg-gray-700 .h5.text-indigo,
.bg-gray-700 .h6.text-indigo {
  color: #6610f2;
}

.bg-gray-700 p.text-purple,
.bg-gray-700 h1.text-purple,
.bg-gray-700 h2.text-purple,
.bg-gray-700 h3.text-purple,
.bg-gray-700 h4.text-purple,
.bg-gray-700 h5.text-purple,
.bg-gray-700 h6.text-purple,
.bg-gray-700 .h1.text-purple,
.bg-gray-700 .h2.text-purple,
.bg-gray-700 .h3.text-purple,
.bg-gray-700 .h4.text-purple,
.bg-gray-700 .h5.text-purple,
.bg-gray-700 .h6.text-purple {
  color: #6f42c1;
}

.bg-gray-700 p.text-pink,
.bg-gray-700 h1.text-pink,
.bg-gray-700 h2.text-pink,
.bg-gray-700 h3.text-pink,
.bg-gray-700 h4.text-pink,
.bg-gray-700 h5.text-pink,
.bg-gray-700 h6.text-pink,
.bg-gray-700 .h1.text-pink,
.bg-gray-700 .h2.text-pink,
.bg-gray-700 .h3.text-pink,
.bg-gray-700 .h4.text-pink,
.bg-gray-700 .h5.text-pink,
.bg-gray-700 .h6.text-pink {
  color: #e83e8c;
}

.bg-gray-700 p.text-red,
.bg-gray-700 h1.text-red,
.bg-gray-700 h2.text-red,
.bg-gray-700 h3.text-red,
.bg-gray-700 h4.text-red,
.bg-gray-700 h5.text-red,
.bg-gray-700 h6.text-red,
.bg-gray-700 .h1.text-red,
.bg-gray-700 .h2.text-red,
.bg-gray-700 .h3.text-red,
.bg-gray-700 .h4.text-red,
.bg-gray-700 .h5.text-red,
.bg-gray-700 .h6.text-red {
  color: #ab0520;
}

.bg-gray-700 p.text-orange,
.bg-gray-700 h1.text-orange,
.bg-gray-700 h2.text-orange,
.bg-gray-700 h3.text-orange,
.bg-gray-700 h4.text-orange,
.bg-gray-700 h5.text-orange,
.bg-gray-700 h6.text-orange,
.bg-gray-700 .h1.text-orange,
.bg-gray-700 .h2.text-orange,
.bg-gray-700 .h3.text-orange,
.bg-gray-700 .h4.text-orange,
.bg-gray-700 .h5.text-orange,
.bg-gray-700 .h6.text-orange {
  color: #fd7e14;
}

.bg-gray-700 p.text-yellow,
.bg-gray-700 h1.text-yellow,
.bg-gray-700 h2.text-yellow,
.bg-gray-700 h3.text-yellow,
.bg-gray-700 h4.text-yellow,
.bg-gray-700 h5.text-yellow,
.bg-gray-700 h6.text-yellow,
.bg-gray-700 .h1.text-yellow,
.bg-gray-700 .h2.text-yellow,
.bg-gray-700 .h3.text-yellow,
.bg-gray-700 .h4.text-yellow,
.bg-gray-700 .h5.text-yellow,
.bg-gray-700 .h6.text-yellow {
  color: #ffc107;
}

.bg-gray-700 p.text-green,
.bg-gray-700 h1.text-green,
.bg-gray-700 h2.text-green,
.bg-gray-700 h3.text-green,
.bg-gray-700 h4.text-green,
.bg-gray-700 h5.text-green,
.bg-gray-700 h6.text-green,
.bg-gray-700 .h1.text-green,
.bg-gray-700 .h2.text-green,
.bg-gray-700 .h3.text-green,
.bg-gray-700 .h4.text-green,
.bg-gray-700 .h5.text-green,
.bg-gray-700 .h6.text-green {
  color: #28a745;
}

.bg-gray-700 p.text-teal,
.bg-gray-700 h1.text-teal,
.bg-gray-700 h2.text-teal,
.bg-gray-700 h3.text-teal,
.bg-gray-700 h4.text-teal,
.bg-gray-700 h5.text-teal,
.bg-gray-700 h6.text-teal,
.bg-gray-700 .h1.text-teal,
.bg-gray-700 .h2.text-teal,
.bg-gray-700 .h3.text-teal,
.bg-gray-700 .h4.text-teal,
.bg-gray-700 .h5.text-teal,
.bg-gray-700 .h6.text-teal {
  color: #20c997;
}

.bg-gray-700 p.text-cyan,
.bg-gray-700 h1.text-cyan,
.bg-gray-700 h2.text-cyan,
.bg-gray-700 h3.text-cyan,
.bg-gray-700 h4.text-cyan,
.bg-gray-700 h5.text-cyan,
.bg-gray-700 h6.text-cyan,
.bg-gray-700 .h1.text-cyan,
.bg-gray-700 .h2.text-cyan,
.bg-gray-700 .h3.text-cyan,
.bg-gray-700 .h4.text-cyan,
.bg-gray-700 .h5.text-cyan,
.bg-gray-700 .h6.text-cyan {
  color: #17a2b8;
}

.bg-gray-700 p.text-white,
.bg-gray-700 h1.text-white,
.bg-gray-700 h2.text-white,
.bg-gray-700 h3.text-white,
.bg-gray-700 h4.text-white,
.bg-gray-700 h5.text-white,
.bg-gray-700 h6.text-white,
.bg-gray-700 .h1.text-white,
.bg-gray-700 .h2.text-white,
.bg-gray-700 .h3.text-white,
.bg-gray-700 .h4.text-white,
.bg-gray-700 .h5.text-white,
.bg-gray-700 .h6.text-white {
  color: #fff;
}

.bg-gray-700 p.text-gray,
.bg-gray-700 h1.text-gray,
.bg-gray-700 h2.text-gray,
.bg-gray-700 h3.text-gray,
.bg-gray-700 h4.text-gray,
.bg-gray-700 h5.text-gray,
.bg-gray-700 h6.text-gray,
.bg-gray-700 .h1.text-gray,
.bg-gray-700 .h2.text-gray,
.bg-gray-700 .h3.text-gray,
.bg-gray-700 .h4.text-gray,
.bg-gray-700 .h5.text-gray,
.bg-gray-700 .h6.text-gray {
  color: #6c757d;
}

.bg-gray-700 p.text-gray-dark,
.bg-gray-700 h1.text-gray-dark,
.bg-gray-700 h2.text-gray-dark,
.bg-gray-700 h3.text-gray-dark,
.bg-gray-700 h4.text-gray-dark,
.bg-gray-700 h5.text-gray-dark,
.bg-gray-700 h6.text-gray-dark,
.bg-gray-700 .h1.text-gray-dark,
.bg-gray-700 .h2.text-gray-dark,
.bg-gray-700 .h3.text-gray-dark,
.bg-gray-700 .h4.text-gray-dark,
.bg-gray-700 .h5.text-gray-dark,
.bg-gray-700 .h6.text-gray-dark {
  color: #343a40;
}

.bg-gray-700 p.text-bloom,
.bg-gray-700 h1.text-bloom,
.bg-gray-700 h2.text-bloom,
.bg-gray-700 h3.text-bloom,
.bg-gray-700 h4.text-bloom,
.bg-gray-700 h5.text-bloom,
.bg-gray-700 h6.text-bloom,
.bg-gray-700 .h1.text-bloom,
.bg-gray-700 .h2.text-bloom,
.bg-gray-700 .h3.text-bloom,
.bg-gray-700 .h4.text-bloom,
.bg-gray-700 .h5.text-bloom,
.bg-gray-700 .h6.text-bloom {
  color: #ef4056;
}

.bg-gray-700 p.text-chili,
.bg-gray-700 h1.text-chili,
.bg-gray-700 h2.text-chili,
.bg-gray-700 h3.text-chili,
.bg-gray-700 h4.text-chili,
.bg-gray-700 h5.text-chili,
.bg-gray-700 h6.text-chili,
.bg-gray-700 .h1.text-chili,
.bg-gray-700 .h2.text-chili,
.bg-gray-700 .h3.text-chili,
.bg-gray-700 .h4.text-chili,
.bg-gray-700 .h5.text-chili,
.bg-gray-700 .h6.text-chili {
  color: #8b0015;
}

.bg-gray-700 p.text-sky,
.bg-gray-700 h1.text-sky,
.bg-gray-700 h2.text-sky,
.bg-gray-700 h3.text-sky,
.bg-gray-700 h4.text-sky,
.bg-gray-700 h5.text-sky,
.bg-gray-700 h6.text-sky,
.bg-gray-700 .h1.text-sky,
.bg-gray-700 .h2.text-sky,
.bg-gray-700 .h3.text-sky,
.bg-gray-700 .h4.text-sky,
.bg-gray-700 .h5.text-sky,
.bg-gray-700 .h6.text-sky {
  color: #81d3eb;
}

.bg-gray-700 p.text-oasis,
.bg-gray-700 h1.text-oasis,
.bg-gray-700 h2.text-oasis,
.bg-gray-700 h3.text-oasis,
.bg-gray-700 h4.text-oasis,
.bg-gray-700 h5.text-oasis,
.bg-gray-700 h6.text-oasis,
.bg-gray-700 .h1.text-oasis,
.bg-gray-700 .h2.text-oasis,
.bg-gray-700 .h3.text-oasis,
.bg-gray-700 .h4.text-oasis,
.bg-gray-700 .h5.text-oasis,
.bg-gray-700 .h6.text-oasis {
  color: #378dbd;
}

.bg-gray-700 p.text-azurite,
.bg-gray-700 h1.text-azurite,
.bg-gray-700 h2.text-azurite,
.bg-gray-700 h3.text-azurite,
.bg-gray-700 h4.text-azurite,
.bg-gray-700 h5.text-azurite,
.bg-gray-700 h6.text-azurite,
.bg-gray-700 .h1.text-azurite,
.bg-gray-700 .h2.text-azurite,
.bg-gray-700 .h3.text-azurite,
.bg-gray-700 .h4.text-azurite,
.bg-gray-700 .h5.text-azurite,
.bg-gray-700 .h6.text-azurite {
  color: #1e5288;
}

.bg-gray-700 p.text-midnight,
.bg-gray-700 h1.text-midnight,
.bg-gray-700 h2.text-midnight,
.bg-gray-700 h3.text-midnight,
.bg-gray-700 h4.text-midnight,
.bg-gray-700 h5.text-midnight,
.bg-gray-700 h6.text-midnight,
.bg-gray-700 .h1.text-midnight,
.bg-gray-700 .h2.text-midnight,
.bg-gray-700 .h3.text-midnight,
.bg-gray-700 .h4.text-midnight,
.bg-gray-700 .h5.text-midnight,
.bg-gray-700 .h6.text-midnight {
  color: #001c48;
}

.bg-gray-700 p.text-cool-gray,
.bg-gray-700 h1.text-cool-gray,
.bg-gray-700 h2.text-cool-gray,
.bg-gray-700 h3.text-cool-gray,
.bg-gray-700 h4.text-cool-gray,
.bg-gray-700 h5.text-cool-gray,
.bg-gray-700 h6.text-cool-gray,
.bg-gray-700 .h1.text-cool-gray,
.bg-gray-700 .h2.text-cool-gray,
.bg-gray-700 .h3.text-cool-gray,
.bg-gray-700 .h4.text-cool-gray,
.bg-gray-700 .h5.text-cool-gray,
.bg-gray-700 .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-gray-700 p.text-warm-gray,
.bg-gray-700 h1.text-warm-gray,
.bg-gray-700 h2.text-warm-gray,
.bg-gray-700 h3.text-warm-gray,
.bg-gray-700 h4.text-warm-gray,
.bg-gray-700 h5.text-warm-gray,
.bg-gray-700 h6.text-warm-gray,
.bg-gray-700 .h1.text-warm-gray,
.bg-gray-700 .h2.text-warm-gray,
.bg-gray-700 .h3.text-warm-gray,
.bg-gray-700 .h4.text-warm-gray,
.bg-gray-700 .h5.text-warm-gray,
.bg-gray-700 .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-gray-700 p.text-leaf,
.bg-gray-700 h1.text-leaf,
.bg-gray-700 h2.text-leaf,
.bg-gray-700 h3.text-leaf,
.bg-gray-700 h4.text-leaf,
.bg-gray-700 h5.text-leaf,
.bg-gray-700 h6.text-leaf,
.bg-gray-700 .h1.text-leaf,
.bg-gray-700 .h2.text-leaf,
.bg-gray-700 .h3.text-leaf,
.bg-gray-700 .h4.text-leaf,
.bg-gray-700 .h5.text-leaf,
.bg-gray-700 .h6.text-leaf {
  color: #70b865;
}

.bg-gray-700 p.text-river,
.bg-gray-700 h1.text-river,
.bg-gray-700 h2.text-river,
.bg-gray-700 h3.text-river,
.bg-gray-700 h4.text-river,
.bg-gray-700 h5.text-river,
.bg-gray-700 h6.text-river,
.bg-gray-700 .h1.text-river,
.bg-gray-700 .h2.text-river,
.bg-gray-700 .h3.text-river,
.bg-gray-700 .h4.text-river,
.bg-gray-700 .h5.text-river,
.bg-gray-700 .h6.text-river {
  color: #007d84;
}

.bg-gray-700 p.text-silver,
.bg-gray-700 h1.text-silver,
.bg-gray-700 h2.text-silver,
.bg-gray-700 h3.text-silver,
.bg-gray-700 h4.text-silver,
.bg-gray-700 h5.text-silver,
.bg-gray-700 h6.text-silver,
.bg-gray-700 .h1.text-silver,
.bg-gray-700 .h2.text-silver,
.bg-gray-700 .h3.text-silver,
.bg-gray-700 .h4.text-silver,
.bg-gray-700 .h5.text-silver,
.bg-gray-700 .h6.text-silver {
  color: #9eabae;
}

.bg-gray-700 p.text-mesa,
.bg-gray-700 h1.text-mesa,
.bg-gray-700 h2.text-mesa,
.bg-gray-700 h3.text-mesa,
.bg-gray-700 h4.text-mesa,
.bg-gray-700 h5.text-mesa,
.bg-gray-700 h6.text-mesa,
.bg-gray-700 .h1.text-mesa,
.bg-gray-700 .h2.text-mesa,
.bg-gray-700 .h3.text-mesa,
.bg-gray-700 .h4.text-mesa,
.bg-gray-700 .h5.text-mesa,
.bg-gray-700 .h6.text-mesa {
  color: #a95c42;
}

.bg-gray-700 p.text-ash,
.bg-gray-700 h1.text-ash,
.bg-gray-700 h2.text-ash,
.bg-gray-700 h3.text-ash,
.bg-gray-700 h4.text-ash,
.bg-gray-700 h5.text-ash,
.bg-gray-700 h6.text-ash,
.bg-gray-700 .h1.text-ash,
.bg-gray-700 .h2.text-ash,
.bg-gray-700 .h3.text-ash,
.bg-gray-700 .h4.text-ash,
.bg-gray-700 .h5.text-ash,
.bg-gray-700 .h6.text-ash {
  color: #403635;
}

.bg-gray-700 p.text-sage,
.bg-gray-700 h1.text-sage,
.bg-gray-700 h2.text-sage,
.bg-gray-700 h3.text-sage,
.bg-gray-700 h4.text-sage,
.bg-gray-700 h5.text-sage,
.bg-gray-700 h6.text-sage,
.bg-gray-700 .h1.text-sage,
.bg-gray-700 .h2.text-sage,
.bg-gray-700 .h3.text-sage,
.bg-gray-700 .h4.text-sage,
.bg-gray-700 .h5.text-sage,
.bg-gray-700 .h6.text-sage {
  color: #4a634e;
}

.bg-gray-700 p.text-black,
.bg-gray-700 h1.text-black,
.bg-gray-700 h2.text-black,
.bg-gray-700 h3.text-black,
.bg-gray-700 h4.text-black,
.bg-gray-700 h5.text-black,
.bg-gray-700 h6.text-black,
.bg-gray-700 .h1.text-black,
.bg-gray-700 .h2.text-black,
.bg-gray-700 .h3.text-black,
.bg-gray-700 .h4.text-black,
.bg-gray-700 .h5.text-black,
.bg-gray-700 .h6.text-black {
  color: #000;
}

.bg-gray-700 p.text-primary,
.bg-gray-700 h1.text-primary,
.bg-gray-700 h2.text-primary,
.bg-gray-700 h3.text-primary,
.bg-gray-700 h4.text-primary,
.bg-gray-700 h5.text-primary,
.bg-gray-700 h6.text-primary,
.bg-gray-700 .h1.text-primary,
.bg-gray-700 .h2.text-primary,
.bg-gray-700 .h3.text-primary,
.bg-gray-700 .h4.text-primary,
.bg-gray-700 .h5.text-primary,
.bg-gray-700 .h6.text-primary {
  color: #1e5288;
}

.bg-gray-700 p.text-secondary,
.bg-gray-700 h1.text-secondary,
.bg-gray-700 h2.text-secondary,
.bg-gray-700 h3.text-secondary,
.bg-gray-700 h4.text-secondary,
.bg-gray-700 h5.text-secondary,
.bg-gray-700 h6.text-secondary,
.bg-gray-700 .h1.text-secondary,
.bg-gray-700 .h2.text-secondary,
.bg-gray-700 .h3.text-secondary,
.bg-gray-700 .h4.text-secondary,
.bg-gray-700 .h5.text-secondary,
.bg-gray-700 .h6.text-secondary {
  color: #9eabae;
}

.bg-gray-700 p.text-success,
.bg-gray-700 h1.text-success,
.bg-gray-700 h2.text-success,
.bg-gray-700 h3.text-success,
.bg-gray-700 h4.text-success,
.bg-gray-700 h5.text-success,
.bg-gray-700 h6.text-success,
.bg-gray-700 .h1.text-success,
.bg-gray-700 .h2.text-success,
.bg-gray-700 .h3.text-success,
.bg-gray-700 .h4.text-success,
.bg-gray-700 .h5.text-success,
.bg-gray-700 .h6.text-success {
  color: #70b865;
}

.bg-gray-700 p.text-info,
.bg-gray-700 h1.text-info,
.bg-gray-700 h2.text-info,
.bg-gray-700 h3.text-info,
.bg-gray-700 h4.text-info,
.bg-gray-700 h5.text-info,
.bg-gray-700 h6.text-info,
.bg-gray-700 .h1.text-info,
.bg-gray-700 .h2.text-info,
.bg-gray-700 .h3.text-info,
.bg-gray-700 .h4.text-info,
.bg-gray-700 .h5.text-info,
.bg-gray-700 .h6.text-info {
  color: #81d3eb;
}

.bg-gray-700 p.text-warning,
.bg-gray-700 h1.text-warning,
.bg-gray-700 h2.text-warning,
.bg-gray-700 h3.text-warning,
.bg-gray-700 h4.text-warning,
.bg-gray-700 h5.text-warning,
.bg-gray-700 h6.text-warning,
.bg-gray-700 .h1.text-warning,
.bg-gray-700 .h2.text-warning,
.bg-gray-700 .h3.text-warning,
.bg-gray-700 .h4.text-warning,
.bg-gray-700 .h5.text-warning,
.bg-gray-700 .h6.text-warning {
  color: #f19e1f;
}

.bg-gray-700 p.text-danger,
.bg-gray-700 h1.text-danger,
.bg-gray-700 h2.text-danger,
.bg-gray-700 h3.text-danger,
.bg-gray-700 h4.text-danger,
.bg-gray-700 h5.text-danger,
.bg-gray-700 h6.text-danger,
.bg-gray-700 .h1.text-danger,
.bg-gray-700 .h2.text-danger,
.bg-gray-700 .h3.text-danger,
.bg-gray-700 .h4.text-danger,
.bg-gray-700 .h5.text-danger,
.bg-gray-700 .h6.text-danger {
  color: #ef4056;
}

.bg-gray-700 p.text-light,
.bg-gray-700 h1.text-light,
.bg-gray-700 h2.text-light,
.bg-gray-700 h3.text-light,
.bg-gray-700 h4.text-light,
.bg-gray-700 h5.text-light,
.bg-gray-700 h6.text-light,
.bg-gray-700 .h1.text-light,
.bg-gray-700 .h2.text-light,
.bg-gray-700 .h3.text-light,
.bg-gray-700 .h4.text-light,
.bg-gray-700 .h5.text-light,
.bg-gray-700 .h6.text-light {
  color: #dee2e6;
}

.bg-gray-700 p.text-dark,
.bg-gray-700 h1.text-dark,
.bg-gray-700 h2.text-dark,
.bg-gray-700 h3.text-dark,
.bg-gray-700 h4.text-dark,
.bg-gray-700 h5.text-dark,
.bg-gray-700 h6.text-dark,
.bg-gray-700 .h1.text-dark,
.bg-gray-700 .h2.text-dark,
.bg-gray-700 .h3.text-dark,
.bg-gray-700 .h4.text-dark,
.bg-gray-700 .h5.text-dark,
.bg-gray-700 .h6.text-dark {
  color: #343a40;
}

.bg-gray-700 p.text-primary-dark,
.bg-gray-700 h1.text-primary-dark,
.bg-gray-700 h2.text-primary-dark,
.bg-gray-700 h3.text-primary-dark,
.bg-gray-700 h4.text-primary-dark,
.bg-gray-700 h5.text-primary-dark,
.bg-gray-700 h6.text-primary-dark,
.bg-gray-700 .h1.text-primary-dark,
.bg-gray-700 .h2.text-primary-dark,
.bg-gray-700 .h3.text-primary-dark,
.bg-gray-700 .h4.text-primary-dark,
.bg-gray-700 .h5.text-primary-dark,
.bg-gray-700 .h6.text-primary-dark {
  color: #0c234b;
}

.bg-gray-700 p.text-primary-light,
.bg-gray-700 h1.text-primary-light,
.bg-gray-700 h2.text-primary-light,
.bg-gray-700 h3.text-primary-light,
.bg-gray-700 h4.text-primary-light,
.bg-gray-700 h5.text-primary-light,
.bg-gray-700 h6.text-primary-light,
.bg-gray-700 .h1.text-primary-light,
.bg-gray-700 .h2.text-primary-light,
.bg-gray-700 .h3.text-primary-light,
.bg-gray-700 .h4.text-primary-light,
.bg-gray-700 .h5.text-primary-light,
.bg-gray-700 .h6.text-primary-light {
  color: #378dbd;
}

.bg-gray-700 p.text-accent,
.bg-gray-700 h1.text-accent,
.bg-gray-700 h2.text-accent,
.bg-gray-700 h3.text-accent,
.bg-gray-700 h4.text-accent,
.bg-gray-700 h5.text-accent,
.bg-gray-700 h6.text-accent,
.bg-gray-700 .h1.text-accent,
.bg-gray-700 .h2.text-accent,
.bg-gray-700 .h3.text-accent,
.bg-gray-700 .h4.text-accent,
.bg-gray-700 .h5.text-accent,
.bg-gray-700 .h6.text-accent {
  color: #ab0520;
}

.bg-gray-800 {
  color: #fff;
  background-color: #343a40;
}

.bg-gray-800 p,
.bg-gray-800 h1,
.bg-gray-800 h2,
.bg-gray-800 h3,
.bg-gray-800 h4,
.bg-gray-800 h5,
.bg-gray-800 h6,
.bg-gray-800 .h1,
.bg-gray-800 .h2,
.bg-gray-800 .h3,
.bg-gray-800 .h4,
.bg-gray-800 .h5,
.bg-gray-800 .h6 {
  color: #fff;
}

.bg-gray-800 p.text-blue,
.bg-gray-800 h1.text-blue,
.bg-gray-800 h2.text-blue,
.bg-gray-800 h3.text-blue,
.bg-gray-800 h4.text-blue,
.bg-gray-800 h5.text-blue,
.bg-gray-800 h6.text-blue,
.bg-gray-800 .h1.text-blue,
.bg-gray-800 .h2.text-blue,
.bg-gray-800 .h3.text-blue,
.bg-gray-800 .h4.text-blue,
.bg-gray-800 .h5.text-blue,
.bg-gray-800 .h6.text-blue {
  color: #0c234b;
}

.bg-gray-800 p.text-indigo,
.bg-gray-800 h1.text-indigo,
.bg-gray-800 h2.text-indigo,
.bg-gray-800 h3.text-indigo,
.bg-gray-800 h4.text-indigo,
.bg-gray-800 h5.text-indigo,
.bg-gray-800 h6.text-indigo,
.bg-gray-800 .h1.text-indigo,
.bg-gray-800 .h2.text-indigo,
.bg-gray-800 .h3.text-indigo,
.bg-gray-800 .h4.text-indigo,
.bg-gray-800 .h5.text-indigo,
.bg-gray-800 .h6.text-indigo {
  color: #6610f2;
}

.bg-gray-800 p.text-purple,
.bg-gray-800 h1.text-purple,
.bg-gray-800 h2.text-purple,
.bg-gray-800 h3.text-purple,
.bg-gray-800 h4.text-purple,
.bg-gray-800 h5.text-purple,
.bg-gray-800 h6.text-purple,
.bg-gray-800 .h1.text-purple,
.bg-gray-800 .h2.text-purple,
.bg-gray-800 .h3.text-purple,
.bg-gray-800 .h4.text-purple,
.bg-gray-800 .h5.text-purple,
.bg-gray-800 .h6.text-purple {
  color: #6f42c1;
}

.bg-gray-800 p.text-pink,
.bg-gray-800 h1.text-pink,
.bg-gray-800 h2.text-pink,
.bg-gray-800 h3.text-pink,
.bg-gray-800 h4.text-pink,
.bg-gray-800 h5.text-pink,
.bg-gray-800 h6.text-pink,
.bg-gray-800 .h1.text-pink,
.bg-gray-800 .h2.text-pink,
.bg-gray-800 .h3.text-pink,
.bg-gray-800 .h4.text-pink,
.bg-gray-800 .h5.text-pink,
.bg-gray-800 .h6.text-pink {
  color: #e83e8c;
}

.bg-gray-800 p.text-red,
.bg-gray-800 h1.text-red,
.bg-gray-800 h2.text-red,
.bg-gray-800 h3.text-red,
.bg-gray-800 h4.text-red,
.bg-gray-800 h5.text-red,
.bg-gray-800 h6.text-red,
.bg-gray-800 .h1.text-red,
.bg-gray-800 .h2.text-red,
.bg-gray-800 .h3.text-red,
.bg-gray-800 .h4.text-red,
.bg-gray-800 .h5.text-red,
.bg-gray-800 .h6.text-red {
  color: #ab0520;
}

.bg-gray-800 p.text-orange,
.bg-gray-800 h1.text-orange,
.bg-gray-800 h2.text-orange,
.bg-gray-800 h3.text-orange,
.bg-gray-800 h4.text-orange,
.bg-gray-800 h5.text-orange,
.bg-gray-800 h6.text-orange,
.bg-gray-800 .h1.text-orange,
.bg-gray-800 .h2.text-orange,
.bg-gray-800 .h3.text-orange,
.bg-gray-800 .h4.text-orange,
.bg-gray-800 .h5.text-orange,
.bg-gray-800 .h6.text-orange {
  color: #fd7e14;
}

.bg-gray-800 p.text-yellow,
.bg-gray-800 h1.text-yellow,
.bg-gray-800 h2.text-yellow,
.bg-gray-800 h3.text-yellow,
.bg-gray-800 h4.text-yellow,
.bg-gray-800 h5.text-yellow,
.bg-gray-800 h6.text-yellow,
.bg-gray-800 .h1.text-yellow,
.bg-gray-800 .h2.text-yellow,
.bg-gray-800 .h3.text-yellow,
.bg-gray-800 .h4.text-yellow,
.bg-gray-800 .h5.text-yellow,
.bg-gray-800 .h6.text-yellow {
  color: #ffc107;
}

.bg-gray-800 p.text-green,
.bg-gray-800 h1.text-green,
.bg-gray-800 h2.text-green,
.bg-gray-800 h3.text-green,
.bg-gray-800 h4.text-green,
.bg-gray-800 h5.text-green,
.bg-gray-800 h6.text-green,
.bg-gray-800 .h1.text-green,
.bg-gray-800 .h2.text-green,
.bg-gray-800 .h3.text-green,
.bg-gray-800 .h4.text-green,
.bg-gray-800 .h5.text-green,
.bg-gray-800 .h6.text-green {
  color: #28a745;
}

.bg-gray-800 p.text-teal,
.bg-gray-800 h1.text-teal,
.bg-gray-800 h2.text-teal,
.bg-gray-800 h3.text-teal,
.bg-gray-800 h4.text-teal,
.bg-gray-800 h5.text-teal,
.bg-gray-800 h6.text-teal,
.bg-gray-800 .h1.text-teal,
.bg-gray-800 .h2.text-teal,
.bg-gray-800 .h3.text-teal,
.bg-gray-800 .h4.text-teal,
.bg-gray-800 .h5.text-teal,
.bg-gray-800 .h6.text-teal {
  color: #20c997;
}

.bg-gray-800 p.text-cyan,
.bg-gray-800 h1.text-cyan,
.bg-gray-800 h2.text-cyan,
.bg-gray-800 h3.text-cyan,
.bg-gray-800 h4.text-cyan,
.bg-gray-800 h5.text-cyan,
.bg-gray-800 h6.text-cyan,
.bg-gray-800 .h1.text-cyan,
.bg-gray-800 .h2.text-cyan,
.bg-gray-800 .h3.text-cyan,
.bg-gray-800 .h4.text-cyan,
.bg-gray-800 .h5.text-cyan,
.bg-gray-800 .h6.text-cyan {
  color: #17a2b8;
}

.bg-gray-800 p.text-white,
.bg-gray-800 h1.text-white,
.bg-gray-800 h2.text-white,
.bg-gray-800 h3.text-white,
.bg-gray-800 h4.text-white,
.bg-gray-800 h5.text-white,
.bg-gray-800 h6.text-white,
.bg-gray-800 .h1.text-white,
.bg-gray-800 .h2.text-white,
.bg-gray-800 .h3.text-white,
.bg-gray-800 .h4.text-white,
.bg-gray-800 .h5.text-white,
.bg-gray-800 .h6.text-white {
  color: #fff;
}

.bg-gray-800 p.text-gray,
.bg-gray-800 h1.text-gray,
.bg-gray-800 h2.text-gray,
.bg-gray-800 h3.text-gray,
.bg-gray-800 h4.text-gray,
.bg-gray-800 h5.text-gray,
.bg-gray-800 h6.text-gray,
.bg-gray-800 .h1.text-gray,
.bg-gray-800 .h2.text-gray,
.bg-gray-800 .h3.text-gray,
.bg-gray-800 .h4.text-gray,
.bg-gray-800 .h5.text-gray,
.bg-gray-800 .h6.text-gray {
  color: #6c757d;
}

.bg-gray-800 p.text-gray-dark,
.bg-gray-800 h1.text-gray-dark,
.bg-gray-800 h2.text-gray-dark,
.bg-gray-800 h3.text-gray-dark,
.bg-gray-800 h4.text-gray-dark,
.bg-gray-800 h5.text-gray-dark,
.bg-gray-800 h6.text-gray-dark,
.bg-gray-800 .h1.text-gray-dark,
.bg-gray-800 .h2.text-gray-dark,
.bg-gray-800 .h3.text-gray-dark,
.bg-gray-800 .h4.text-gray-dark,
.bg-gray-800 .h5.text-gray-dark,
.bg-gray-800 .h6.text-gray-dark {
  color: #343a40;
}

.bg-gray-800 p.text-bloom,
.bg-gray-800 h1.text-bloom,
.bg-gray-800 h2.text-bloom,
.bg-gray-800 h3.text-bloom,
.bg-gray-800 h4.text-bloom,
.bg-gray-800 h5.text-bloom,
.bg-gray-800 h6.text-bloom,
.bg-gray-800 .h1.text-bloom,
.bg-gray-800 .h2.text-bloom,
.bg-gray-800 .h3.text-bloom,
.bg-gray-800 .h4.text-bloom,
.bg-gray-800 .h5.text-bloom,
.bg-gray-800 .h6.text-bloom {
  color: #ef4056;
}

.bg-gray-800 p.text-chili,
.bg-gray-800 h1.text-chili,
.bg-gray-800 h2.text-chili,
.bg-gray-800 h3.text-chili,
.bg-gray-800 h4.text-chili,
.bg-gray-800 h5.text-chili,
.bg-gray-800 h6.text-chili,
.bg-gray-800 .h1.text-chili,
.bg-gray-800 .h2.text-chili,
.bg-gray-800 .h3.text-chili,
.bg-gray-800 .h4.text-chili,
.bg-gray-800 .h5.text-chili,
.bg-gray-800 .h6.text-chili {
  color: #8b0015;
}

.bg-gray-800 p.text-sky,
.bg-gray-800 h1.text-sky,
.bg-gray-800 h2.text-sky,
.bg-gray-800 h3.text-sky,
.bg-gray-800 h4.text-sky,
.bg-gray-800 h5.text-sky,
.bg-gray-800 h6.text-sky,
.bg-gray-800 .h1.text-sky,
.bg-gray-800 .h2.text-sky,
.bg-gray-800 .h3.text-sky,
.bg-gray-800 .h4.text-sky,
.bg-gray-800 .h5.text-sky,
.bg-gray-800 .h6.text-sky {
  color: #81d3eb;
}

.bg-gray-800 p.text-oasis,
.bg-gray-800 h1.text-oasis,
.bg-gray-800 h2.text-oasis,
.bg-gray-800 h3.text-oasis,
.bg-gray-800 h4.text-oasis,
.bg-gray-800 h5.text-oasis,
.bg-gray-800 h6.text-oasis,
.bg-gray-800 .h1.text-oasis,
.bg-gray-800 .h2.text-oasis,
.bg-gray-800 .h3.text-oasis,
.bg-gray-800 .h4.text-oasis,
.bg-gray-800 .h5.text-oasis,
.bg-gray-800 .h6.text-oasis {
  color: #378dbd;
}

.bg-gray-800 p.text-azurite,
.bg-gray-800 h1.text-azurite,
.bg-gray-800 h2.text-azurite,
.bg-gray-800 h3.text-azurite,
.bg-gray-800 h4.text-azurite,
.bg-gray-800 h5.text-azurite,
.bg-gray-800 h6.text-azurite,
.bg-gray-800 .h1.text-azurite,
.bg-gray-800 .h2.text-azurite,
.bg-gray-800 .h3.text-azurite,
.bg-gray-800 .h4.text-azurite,
.bg-gray-800 .h5.text-azurite,
.bg-gray-800 .h6.text-azurite {
  color: #1e5288;
}

.bg-gray-800 p.text-midnight,
.bg-gray-800 h1.text-midnight,
.bg-gray-800 h2.text-midnight,
.bg-gray-800 h3.text-midnight,
.bg-gray-800 h4.text-midnight,
.bg-gray-800 h5.text-midnight,
.bg-gray-800 h6.text-midnight,
.bg-gray-800 .h1.text-midnight,
.bg-gray-800 .h2.text-midnight,
.bg-gray-800 .h3.text-midnight,
.bg-gray-800 .h4.text-midnight,
.bg-gray-800 .h5.text-midnight,
.bg-gray-800 .h6.text-midnight {
  color: #001c48;
}

.bg-gray-800 p.text-cool-gray,
.bg-gray-800 h1.text-cool-gray,
.bg-gray-800 h2.text-cool-gray,
.bg-gray-800 h3.text-cool-gray,
.bg-gray-800 h4.text-cool-gray,
.bg-gray-800 h5.text-cool-gray,
.bg-gray-800 h6.text-cool-gray,
.bg-gray-800 .h1.text-cool-gray,
.bg-gray-800 .h2.text-cool-gray,
.bg-gray-800 .h3.text-cool-gray,
.bg-gray-800 .h4.text-cool-gray,
.bg-gray-800 .h5.text-cool-gray,
.bg-gray-800 .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-gray-800 p.text-warm-gray,
.bg-gray-800 h1.text-warm-gray,
.bg-gray-800 h2.text-warm-gray,
.bg-gray-800 h3.text-warm-gray,
.bg-gray-800 h4.text-warm-gray,
.bg-gray-800 h5.text-warm-gray,
.bg-gray-800 h6.text-warm-gray,
.bg-gray-800 .h1.text-warm-gray,
.bg-gray-800 .h2.text-warm-gray,
.bg-gray-800 .h3.text-warm-gray,
.bg-gray-800 .h4.text-warm-gray,
.bg-gray-800 .h5.text-warm-gray,
.bg-gray-800 .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-gray-800 p.text-leaf,
.bg-gray-800 h1.text-leaf,
.bg-gray-800 h2.text-leaf,
.bg-gray-800 h3.text-leaf,
.bg-gray-800 h4.text-leaf,
.bg-gray-800 h5.text-leaf,
.bg-gray-800 h6.text-leaf,
.bg-gray-800 .h1.text-leaf,
.bg-gray-800 .h2.text-leaf,
.bg-gray-800 .h3.text-leaf,
.bg-gray-800 .h4.text-leaf,
.bg-gray-800 .h5.text-leaf,
.bg-gray-800 .h6.text-leaf {
  color: #70b865;
}

.bg-gray-800 p.text-river,
.bg-gray-800 h1.text-river,
.bg-gray-800 h2.text-river,
.bg-gray-800 h3.text-river,
.bg-gray-800 h4.text-river,
.bg-gray-800 h5.text-river,
.bg-gray-800 h6.text-river,
.bg-gray-800 .h1.text-river,
.bg-gray-800 .h2.text-river,
.bg-gray-800 .h3.text-river,
.bg-gray-800 .h4.text-river,
.bg-gray-800 .h5.text-river,
.bg-gray-800 .h6.text-river {
  color: #007d84;
}

.bg-gray-800 p.text-silver,
.bg-gray-800 h1.text-silver,
.bg-gray-800 h2.text-silver,
.bg-gray-800 h3.text-silver,
.bg-gray-800 h4.text-silver,
.bg-gray-800 h5.text-silver,
.bg-gray-800 h6.text-silver,
.bg-gray-800 .h1.text-silver,
.bg-gray-800 .h2.text-silver,
.bg-gray-800 .h3.text-silver,
.bg-gray-800 .h4.text-silver,
.bg-gray-800 .h5.text-silver,
.bg-gray-800 .h6.text-silver {
  color: #9eabae;
}

.bg-gray-800 p.text-mesa,
.bg-gray-800 h1.text-mesa,
.bg-gray-800 h2.text-mesa,
.bg-gray-800 h3.text-mesa,
.bg-gray-800 h4.text-mesa,
.bg-gray-800 h5.text-mesa,
.bg-gray-800 h6.text-mesa,
.bg-gray-800 .h1.text-mesa,
.bg-gray-800 .h2.text-mesa,
.bg-gray-800 .h3.text-mesa,
.bg-gray-800 .h4.text-mesa,
.bg-gray-800 .h5.text-mesa,
.bg-gray-800 .h6.text-mesa {
  color: #a95c42;
}

.bg-gray-800 p.text-ash,
.bg-gray-800 h1.text-ash,
.bg-gray-800 h2.text-ash,
.bg-gray-800 h3.text-ash,
.bg-gray-800 h4.text-ash,
.bg-gray-800 h5.text-ash,
.bg-gray-800 h6.text-ash,
.bg-gray-800 .h1.text-ash,
.bg-gray-800 .h2.text-ash,
.bg-gray-800 .h3.text-ash,
.bg-gray-800 .h4.text-ash,
.bg-gray-800 .h5.text-ash,
.bg-gray-800 .h6.text-ash {
  color: #403635;
}

.bg-gray-800 p.text-sage,
.bg-gray-800 h1.text-sage,
.bg-gray-800 h2.text-sage,
.bg-gray-800 h3.text-sage,
.bg-gray-800 h4.text-sage,
.bg-gray-800 h5.text-sage,
.bg-gray-800 h6.text-sage,
.bg-gray-800 .h1.text-sage,
.bg-gray-800 .h2.text-sage,
.bg-gray-800 .h3.text-sage,
.bg-gray-800 .h4.text-sage,
.bg-gray-800 .h5.text-sage,
.bg-gray-800 .h6.text-sage {
  color: #4a634e;
}

.bg-gray-800 p.text-black,
.bg-gray-800 h1.text-black,
.bg-gray-800 h2.text-black,
.bg-gray-800 h3.text-black,
.bg-gray-800 h4.text-black,
.bg-gray-800 h5.text-black,
.bg-gray-800 h6.text-black,
.bg-gray-800 .h1.text-black,
.bg-gray-800 .h2.text-black,
.bg-gray-800 .h3.text-black,
.bg-gray-800 .h4.text-black,
.bg-gray-800 .h5.text-black,
.bg-gray-800 .h6.text-black {
  color: #000;
}

.bg-gray-800 p.text-primary,
.bg-gray-800 h1.text-primary,
.bg-gray-800 h2.text-primary,
.bg-gray-800 h3.text-primary,
.bg-gray-800 h4.text-primary,
.bg-gray-800 h5.text-primary,
.bg-gray-800 h6.text-primary,
.bg-gray-800 .h1.text-primary,
.bg-gray-800 .h2.text-primary,
.bg-gray-800 .h3.text-primary,
.bg-gray-800 .h4.text-primary,
.bg-gray-800 .h5.text-primary,
.bg-gray-800 .h6.text-primary {
  color: #1e5288;
}

.bg-gray-800 p.text-secondary,
.bg-gray-800 h1.text-secondary,
.bg-gray-800 h2.text-secondary,
.bg-gray-800 h3.text-secondary,
.bg-gray-800 h4.text-secondary,
.bg-gray-800 h5.text-secondary,
.bg-gray-800 h6.text-secondary,
.bg-gray-800 .h1.text-secondary,
.bg-gray-800 .h2.text-secondary,
.bg-gray-800 .h3.text-secondary,
.bg-gray-800 .h4.text-secondary,
.bg-gray-800 .h5.text-secondary,
.bg-gray-800 .h6.text-secondary {
  color: #9eabae;
}

.bg-gray-800 p.text-success,
.bg-gray-800 h1.text-success,
.bg-gray-800 h2.text-success,
.bg-gray-800 h3.text-success,
.bg-gray-800 h4.text-success,
.bg-gray-800 h5.text-success,
.bg-gray-800 h6.text-success,
.bg-gray-800 .h1.text-success,
.bg-gray-800 .h2.text-success,
.bg-gray-800 .h3.text-success,
.bg-gray-800 .h4.text-success,
.bg-gray-800 .h5.text-success,
.bg-gray-800 .h6.text-success {
  color: #70b865;
}

.bg-gray-800 p.text-info,
.bg-gray-800 h1.text-info,
.bg-gray-800 h2.text-info,
.bg-gray-800 h3.text-info,
.bg-gray-800 h4.text-info,
.bg-gray-800 h5.text-info,
.bg-gray-800 h6.text-info,
.bg-gray-800 .h1.text-info,
.bg-gray-800 .h2.text-info,
.bg-gray-800 .h3.text-info,
.bg-gray-800 .h4.text-info,
.bg-gray-800 .h5.text-info,
.bg-gray-800 .h6.text-info {
  color: #81d3eb;
}

.bg-gray-800 p.text-warning,
.bg-gray-800 h1.text-warning,
.bg-gray-800 h2.text-warning,
.bg-gray-800 h3.text-warning,
.bg-gray-800 h4.text-warning,
.bg-gray-800 h5.text-warning,
.bg-gray-800 h6.text-warning,
.bg-gray-800 .h1.text-warning,
.bg-gray-800 .h2.text-warning,
.bg-gray-800 .h3.text-warning,
.bg-gray-800 .h4.text-warning,
.bg-gray-800 .h5.text-warning,
.bg-gray-800 .h6.text-warning {
  color: #f19e1f;
}

.bg-gray-800 p.text-danger,
.bg-gray-800 h1.text-danger,
.bg-gray-800 h2.text-danger,
.bg-gray-800 h3.text-danger,
.bg-gray-800 h4.text-danger,
.bg-gray-800 h5.text-danger,
.bg-gray-800 h6.text-danger,
.bg-gray-800 .h1.text-danger,
.bg-gray-800 .h2.text-danger,
.bg-gray-800 .h3.text-danger,
.bg-gray-800 .h4.text-danger,
.bg-gray-800 .h5.text-danger,
.bg-gray-800 .h6.text-danger {
  color: #ef4056;
}

.bg-gray-800 p.text-light,
.bg-gray-800 h1.text-light,
.bg-gray-800 h2.text-light,
.bg-gray-800 h3.text-light,
.bg-gray-800 h4.text-light,
.bg-gray-800 h5.text-light,
.bg-gray-800 h6.text-light,
.bg-gray-800 .h1.text-light,
.bg-gray-800 .h2.text-light,
.bg-gray-800 .h3.text-light,
.bg-gray-800 .h4.text-light,
.bg-gray-800 .h5.text-light,
.bg-gray-800 .h6.text-light {
  color: #dee2e6;
}

.bg-gray-800 p.text-dark,
.bg-gray-800 h1.text-dark,
.bg-gray-800 h2.text-dark,
.bg-gray-800 h3.text-dark,
.bg-gray-800 h4.text-dark,
.bg-gray-800 h5.text-dark,
.bg-gray-800 h6.text-dark,
.bg-gray-800 .h1.text-dark,
.bg-gray-800 .h2.text-dark,
.bg-gray-800 .h3.text-dark,
.bg-gray-800 .h4.text-dark,
.bg-gray-800 .h5.text-dark,
.bg-gray-800 .h6.text-dark {
  color: #343a40;
}

.bg-gray-800 p.text-primary-dark,
.bg-gray-800 h1.text-primary-dark,
.bg-gray-800 h2.text-primary-dark,
.bg-gray-800 h3.text-primary-dark,
.bg-gray-800 h4.text-primary-dark,
.bg-gray-800 h5.text-primary-dark,
.bg-gray-800 h6.text-primary-dark,
.bg-gray-800 .h1.text-primary-dark,
.bg-gray-800 .h2.text-primary-dark,
.bg-gray-800 .h3.text-primary-dark,
.bg-gray-800 .h4.text-primary-dark,
.bg-gray-800 .h5.text-primary-dark,
.bg-gray-800 .h6.text-primary-dark {
  color: #0c234b;
}

.bg-gray-800 p.text-primary-light,
.bg-gray-800 h1.text-primary-light,
.bg-gray-800 h2.text-primary-light,
.bg-gray-800 h3.text-primary-light,
.bg-gray-800 h4.text-primary-light,
.bg-gray-800 h5.text-primary-light,
.bg-gray-800 h6.text-primary-light,
.bg-gray-800 .h1.text-primary-light,
.bg-gray-800 .h2.text-primary-light,
.bg-gray-800 .h3.text-primary-light,
.bg-gray-800 .h4.text-primary-light,
.bg-gray-800 .h5.text-primary-light,
.bg-gray-800 .h6.text-primary-light {
  color: #378dbd;
}

.bg-gray-800 p.text-accent,
.bg-gray-800 h1.text-accent,
.bg-gray-800 h2.text-accent,
.bg-gray-800 h3.text-accent,
.bg-gray-800 h4.text-accent,
.bg-gray-800 h5.text-accent,
.bg-gray-800 h6.text-accent,
.bg-gray-800 .h1.text-accent,
.bg-gray-800 .h2.text-accent,
.bg-gray-800 .h3.text-accent,
.bg-gray-800 .h4.text-accent,
.bg-gray-800 .h5.text-accent,
.bg-gray-800 .h6.text-accent {
  color: #ab0520;
}

.bg-gray-900 {
  color: #fff;
  background-color: #212529;
}

.bg-gray-900 p,
.bg-gray-900 h1,
.bg-gray-900 h2,
.bg-gray-900 h3,
.bg-gray-900 h4,
.bg-gray-900 h5,
.bg-gray-900 h6,
.bg-gray-900 .h1,
.bg-gray-900 .h2,
.bg-gray-900 .h3,
.bg-gray-900 .h4,
.bg-gray-900 .h5,
.bg-gray-900 .h6 {
  color: #fff;
}

.bg-gray-900 p.text-blue,
.bg-gray-900 h1.text-blue,
.bg-gray-900 h2.text-blue,
.bg-gray-900 h3.text-blue,
.bg-gray-900 h4.text-blue,
.bg-gray-900 h5.text-blue,
.bg-gray-900 h6.text-blue,
.bg-gray-900 .h1.text-blue,
.bg-gray-900 .h2.text-blue,
.bg-gray-900 .h3.text-blue,
.bg-gray-900 .h4.text-blue,
.bg-gray-900 .h5.text-blue,
.bg-gray-900 .h6.text-blue {
  color: #0c234b;
}

.bg-gray-900 p.text-indigo,
.bg-gray-900 h1.text-indigo,
.bg-gray-900 h2.text-indigo,
.bg-gray-900 h3.text-indigo,
.bg-gray-900 h4.text-indigo,
.bg-gray-900 h5.text-indigo,
.bg-gray-900 h6.text-indigo,
.bg-gray-900 .h1.text-indigo,
.bg-gray-900 .h2.text-indigo,
.bg-gray-900 .h3.text-indigo,
.bg-gray-900 .h4.text-indigo,
.bg-gray-900 .h5.text-indigo,
.bg-gray-900 .h6.text-indigo {
  color: #6610f2;
}

.bg-gray-900 p.text-purple,
.bg-gray-900 h1.text-purple,
.bg-gray-900 h2.text-purple,
.bg-gray-900 h3.text-purple,
.bg-gray-900 h4.text-purple,
.bg-gray-900 h5.text-purple,
.bg-gray-900 h6.text-purple,
.bg-gray-900 .h1.text-purple,
.bg-gray-900 .h2.text-purple,
.bg-gray-900 .h3.text-purple,
.bg-gray-900 .h4.text-purple,
.bg-gray-900 .h5.text-purple,
.bg-gray-900 .h6.text-purple {
  color: #6f42c1;
}

.bg-gray-900 p.text-pink,
.bg-gray-900 h1.text-pink,
.bg-gray-900 h2.text-pink,
.bg-gray-900 h3.text-pink,
.bg-gray-900 h4.text-pink,
.bg-gray-900 h5.text-pink,
.bg-gray-900 h6.text-pink,
.bg-gray-900 .h1.text-pink,
.bg-gray-900 .h2.text-pink,
.bg-gray-900 .h3.text-pink,
.bg-gray-900 .h4.text-pink,
.bg-gray-900 .h5.text-pink,
.bg-gray-900 .h6.text-pink {
  color: #e83e8c;
}

.bg-gray-900 p.text-red,
.bg-gray-900 h1.text-red,
.bg-gray-900 h2.text-red,
.bg-gray-900 h3.text-red,
.bg-gray-900 h4.text-red,
.bg-gray-900 h5.text-red,
.bg-gray-900 h6.text-red,
.bg-gray-900 .h1.text-red,
.bg-gray-900 .h2.text-red,
.bg-gray-900 .h3.text-red,
.bg-gray-900 .h4.text-red,
.bg-gray-900 .h5.text-red,
.bg-gray-900 .h6.text-red {
  color: #ab0520;
}

.bg-gray-900 p.text-orange,
.bg-gray-900 h1.text-orange,
.bg-gray-900 h2.text-orange,
.bg-gray-900 h3.text-orange,
.bg-gray-900 h4.text-orange,
.bg-gray-900 h5.text-orange,
.bg-gray-900 h6.text-orange,
.bg-gray-900 .h1.text-orange,
.bg-gray-900 .h2.text-orange,
.bg-gray-900 .h3.text-orange,
.bg-gray-900 .h4.text-orange,
.bg-gray-900 .h5.text-orange,
.bg-gray-900 .h6.text-orange {
  color: #fd7e14;
}

.bg-gray-900 p.text-yellow,
.bg-gray-900 h1.text-yellow,
.bg-gray-900 h2.text-yellow,
.bg-gray-900 h3.text-yellow,
.bg-gray-900 h4.text-yellow,
.bg-gray-900 h5.text-yellow,
.bg-gray-900 h6.text-yellow,
.bg-gray-900 .h1.text-yellow,
.bg-gray-900 .h2.text-yellow,
.bg-gray-900 .h3.text-yellow,
.bg-gray-900 .h4.text-yellow,
.bg-gray-900 .h5.text-yellow,
.bg-gray-900 .h6.text-yellow {
  color: #ffc107;
}

.bg-gray-900 p.text-green,
.bg-gray-900 h1.text-green,
.bg-gray-900 h2.text-green,
.bg-gray-900 h3.text-green,
.bg-gray-900 h4.text-green,
.bg-gray-900 h5.text-green,
.bg-gray-900 h6.text-green,
.bg-gray-900 .h1.text-green,
.bg-gray-900 .h2.text-green,
.bg-gray-900 .h3.text-green,
.bg-gray-900 .h4.text-green,
.bg-gray-900 .h5.text-green,
.bg-gray-900 .h6.text-green {
  color: #28a745;
}

.bg-gray-900 p.text-teal,
.bg-gray-900 h1.text-teal,
.bg-gray-900 h2.text-teal,
.bg-gray-900 h3.text-teal,
.bg-gray-900 h4.text-teal,
.bg-gray-900 h5.text-teal,
.bg-gray-900 h6.text-teal,
.bg-gray-900 .h1.text-teal,
.bg-gray-900 .h2.text-teal,
.bg-gray-900 .h3.text-teal,
.bg-gray-900 .h4.text-teal,
.bg-gray-900 .h5.text-teal,
.bg-gray-900 .h6.text-teal {
  color: #20c997;
}

.bg-gray-900 p.text-cyan,
.bg-gray-900 h1.text-cyan,
.bg-gray-900 h2.text-cyan,
.bg-gray-900 h3.text-cyan,
.bg-gray-900 h4.text-cyan,
.bg-gray-900 h5.text-cyan,
.bg-gray-900 h6.text-cyan,
.bg-gray-900 .h1.text-cyan,
.bg-gray-900 .h2.text-cyan,
.bg-gray-900 .h3.text-cyan,
.bg-gray-900 .h4.text-cyan,
.bg-gray-900 .h5.text-cyan,
.bg-gray-900 .h6.text-cyan {
  color: #17a2b8;
}

.bg-gray-900 p.text-white,
.bg-gray-900 h1.text-white,
.bg-gray-900 h2.text-white,
.bg-gray-900 h3.text-white,
.bg-gray-900 h4.text-white,
.bg-gray-900 h5.text-white,
.bg-gray-900 h6.text-white,
.bg-gray-900 .h1.text-white,
.bg-gray-900 .h2.text-white,
.bg-gray-900 .h3.text-white,
.bg-gray-900 .h4.text-white,
.bg-gray-900 .h5.text-white,
.bg-gray-900 .h6.text-white {
  color: #fff;
}

.bg-gray-900 p.text-gray,
.bg-gray-900 h1.text-gray,
.bg-gray-900 h2.text-gray,
.bg-gray-900 h3.text-gray,
.bg-gray-900 h4.text-gray,
.bg-gray-900 h5.text-gray,
.bg-gray-900 h6.text-gray,
.bg-gray-900 .h1.text-gray,
.bg-gray-900 .h2.text-gray,
.bg-gray-900 .h3.text-gray,
.bg-gray-900 .h4.text-gray,
.bg-gray-900 .h5.text-gray,
.bg-gray-900 .h6.text-gray {
  color: #6c757d;
}

.bg-gray-900 p.text-gray-dark,
.bg-gray-900 h1.text-gray-dark,
.bg-gray-900 h2.text-gray-dark,
.bg-gray-900 h3.text-gray-dark,
.bg-gray-900 h4.text-gray-dark,
.bg-gray-900 h5.text-gray-dark,
.bg-gray-900 h6.text-gray-dark,
.bg-gray-900 .h1.text-gray-dark,
.bg-gray-900 .h2.text-gray-dark,
.bg-gray-900 .h3.text-gray-dark,
.bg-gray-900 .h4.text-gray-dark,
.bg-gray-900 .h5.text-gray-dark,
.bg-gray-900 .h6.text-gray-dark {
  color: #343a40;
}

.bg-gray-900 p.text-bloom,
.bg-gray-900 h1.text-bloom,
.bg-gray-900 h2.text-bloom,
.bg-gray-900 h3.text-bloom,
.bg-gray-900 h4.text-bloom,
.bg-gray-900 h5.text-bloom,
.bg-gray-900 h6.text-bloom,
.bg-gray-900 .h1.text-bloom,
.bg-gray-900 .h2.text-bloom,
.bg-gray-900 .h3.text-bloom,
.bg-gray-900 .h4.text-bloom,
.bg-gray-900 .h5.text-bloom,
.bg-gray-900 .h6.text-bloom {
  color: #ef4056;
}

.bg-gray-900 p.text-chili,
.bg-gray-900 h1.text-chili,
.bg-gray-900 h2.text-chili,
.bg-gray-900 h3.text-chili,
.bg-gray-900 h4.text-chili,
.bg-gray-900 h5.text-chili,
.bg-gray-900 h6.text-chili,
.bg-gray-900 .h1.text-chili,
.bg-gray-900 .h2.text-chili,
.bg-gray-900 .h3.text-chili,
.bg-gray-900 .h4.text-chili,
.bg-gray-900 .h5.text-chili,
.bg-gray-900 .h6.text-chili {
  color: #8b0015;
}

.bg-gray-900 p.text-sky,
.bg-gray-900 h1.text-sky,
.bg-gray-900 h2.text-sky,
.bg-gray-900 h3.text-sky,
.bg-gray-900 h4.text-sky,
.bg-gray-900 h5.text-sky,
.bg-gray-900 h6.text-sky,
.bg-gray-900 .h1.text-sky,
.bg-gray-900 .h2.text-sky,
.bg-gray-900 .h3.text-sky,
.bg-gray-900 .h4.text-sky,
.bg-gray-900 .h5.text-sky,
.bg-gray-900 .h6.text-sky {
  color: #81d3eb;
}

.bg-gray-900 p.text-oasis,
.bg-gray-900 h1.text-oasis,
.bg-gray-900 h2.text-oasis,
.bg-gray-900 h3.text-oasis,
.bg-gray-900 h4.text-oasis,
.bg-gray-900 h5.text-oasis,
.bg-gray-900 h6.text-oasis,
.bg-gray-900 .h1.text-oasis,
.bg-gray-900 .h2.text-oasis,
.bg-gray-900 .h3.text-oasis,
.bg-gray-900 .h4.text-oasis,
.bg-gray-900 .h5.text-oasis,
.bg-gray-900 .h6.text-oasis {
  color: #378dbd;
}

.bg-gray-900 p.text-azurite,
.bg-gray-900 h1.text-azurite,
.bg-gray-900 h2.text-azurite,
.bg-gray-900 h3.text-azurite,
.bg-gray-900 h4.text-azurite,
.bg-gray-900 h5.text-azurite,
.bg-gray-900 h6.text-azurite,
.bg-gray-900 .h1.text-azurite,
.bg-gray-900 .h2.text-azurite,
.bg-gray-900 .h3.text-azurite,
.bg-gray-900 .h4.text-azurite,
.bg-gray-900 .h5.text-azurite,
.bg-gray-900 .h6.text-azurite {
  color: #1e5288;
}

.bg-gray-900 p.text-midnight,
.bg-gray-900 h1.text-midnight,
.bg-gray-900 h2.text-midnight,
.bg-gray-900 h3.text-midnight,
.bg-gray-900 h4.text-midnight,
.bg-gray-900 h5.text-midnight,
.bg-gray-900 h6.text-midnight,
.bg-gray-900 .h1.text-midnight,
.bg-gray-900 .h2.text-midnight,
.bg-gray-900 .h3.text-midnight,
.bg-gray-900 .h4.text-midnight,
.bg-gray-900 .h5.text-midnight,
.bg-gray-900 .h6.text-midnight {
  color: #001c48;
}

.bg-gray-900 p.text-cool-gray,
.bg-gray-900 h1.text-cool-gray,
.bg-gray-900 h2.text-cool-gray,
.bg-gray-900 h3.text-cool-gray,
.bg-gray-900 h4.text-cool-gray,
.bg-gray-900 h5.text-cool-gray,
.bg-gray-900 h6.text-cool-gray,
.bg-gray-900 .h1.text-cool-gray,
.bg-gray-900 .h2.text-cool-gray,
.bg-gray-900 .h3.text-cool-gray,
.bg-gray-900 .h4.text-cool-gray,
.bg-gray-900 .h5.text-cool-gray,
.bg-gray-900 .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-gray-900 p.text-warm-gray,
.bg-gray-900 h1.text-warm-gray,
.bg-gray-900 h2.text-warm-gray,
.bg-gray-900 h3.text-warm-gray,
.bg-gray-900 h4.text-warm-gray,
.bg-gray-900 h5.text-warm-gray,
.bg-gray-900 h6.text-warm-gray,
.bg-gray-900 .h1.text-warm-gray,
.bg-gray-900 .h2.text-warm-gray,
.bg-gray-900 .h3.text-warm-gray,
.bg-gray-900 .h4.text-warm-gray,
.bg-gray-900 .h5.text-warm-gray,
.bg-gray-900 .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-gray-900 p.text-leaf,
.bg-gray-900 h1.text-leaf,
.bg-gray-900 h2.text-leaf,
.bg-gray-900 h3.text-leaf,
.bg-gray-900 h4.text-leaf,
.bg-gray-900 h5.text-leaf,
.bg-gray-900 h6.text-leaf,
.bg-gray-900 .h1.text-leaf,
.bg-gray-900 .h2.text-leaf,
.bg-gray-900 .h3.text-leaf,
.bg-gray-900 .h4.text-leaf,
.bg-gray-900 .h5.text-leaf,
.bg-gray-900 .h6.text-leaf {
  color: #70b865;
}

.bg-gray-900 p.text-river,
.bg-gray-900 h1.text-river,
.bg-gray-900 h2.text-river,
.bg-gray-900 h3.text-river,
.bg-gray-900 h4.text-river,
.bg-gray-900 h5.text-river,
.bg-gray-900 h6.text-river,
.bg-gray-900 .h1.text-river,
.bg-gray-900 .h2.text-river,
.bg-gray-900 .h3.text-river,
.bg-gray-900 .h4.text-river,
.bg-gray-900 .h5.text-river,
.bg-gray-900 .h6.text-river {
  color: #007d84;
}

.bg-gray-900 p.text-silver,
.bg-gray-900 h1.text-silver,
.bg-gray-900 h2.text-silver,
.bg-gray-900 h3.text-silver,
.bg-gray-900 h4.text-silver,
.bg-gray-900 h5.text-silver,
.bg-gray-900 h6.text-silver,
.bg-gray-900 .h1.text-silver,
.bg-gray-900 .h2.text-silver,
.bg-gray-900 .h3.text-silver,
.bg-gray-900 .h4.text-silver,
.bg-gray-900 .h5.text-silver,
.bg-gray-900 .h6.text-silver {
  color: #9eabae;
}

.bg-gray-900 p.text-mesa,
.bg-gray-900 h1.text-mesa,
.bg-gray-900 h2.text-mesa,
.bg-gray-900 h3.text-mesa,
.bg-gray-900 h4.text-mesa,
.bg-gray-900 h5.text-mesa,
.bg-gray-900 h6.text-mesa,
.bg-gray-900 .h1.text-mesa,
.bg-gray-900 .h2.text-mesa,
.bg-gray-900 .h3.text-mesa,
.bg-gray-900 .h4.text-mesa,
.bg-gray-900 .h5.text-mesa,
.bg-gray-900 .h6.text-mesa {
  color: #a95c42;
}

.bg-gray-900 p.text-ash,
.bg-gray-900 h1.text-ash,
.bg-gray-900 h2.text-ash,
.bg-gray-900 h3.text-ash,
.bg-gray-900 h4.text-ash,
.bg-gray-900 h5.text-ash,
.bg-gray-900 h6.text-ash,
.bg-gray-900 .h1.text-ash,
.bg-gray-900 .h2.text-ash,
.bg-gray-900 .h3.text-ash,
.bg-gray-900 .h4.text-ash,
.bg-gray-900 .h5.text-ash,
.bg-gray-900 .h6.text-ash {
  color: #403635;
}

.bg-gray-900 p.text-sage,
.bg-gray-900 h1.text-sage,
.bg-gray-900 h2.text-sage,
.bg-gray-900 h3.text-sage,
.bg-gray-900 h4.text-sage,
.bg-gray-900 h5.text-sage,
.bg-gray-900 h6.text-sage,
.bg-gray-900 .h1.text-sage,
.bg-gray-900 .h2.text-sage,
.bg-gray-900 .h3.text-sage,
.bg-gray-900 .h4.text-sage,
.bg-gray-900 .h5.text-sage,
.bg-gray-900 .h6.text-sage {
  color: #4a634e;
}

.bg-gray-900 p.text-black,
.bg-gray-900 h1.text-black,
.bg-gray-900 h2.text-black,
.bg-gray-900 h3.text-black,
.bg-gray-900 h4.text-black,
.bg-gray-900 h5.text-black,
.bg-gray-900 h6.text-black,
.bg-gray-900 .h1.text-black,
.bg-gray-900 .h2.text-black,
.bg-gray-900 .h3.text-black,
.bg-gray-900 .h4.text-black,
.bg-gray-900 .h5.text-black,
.bg-gray-900 .h6.text-black {
  color: #000;
}

.bg-gray-900 p.text-primary,
.bg-gray-900 h1.text-primary,
.bg-gray-900 h2.text-primary,
.bg-gray-900 h3.text-primary,
.bg-gray-900 h4.text-primary,
.bg-gray-900 h5.text-primary,
.bg-gray-900 h6.text-primary,
.bg-gray-900 .h1.text-primary,
.bg-gray-900 .h2.text-primary,
.bg-gray-900 .h3.text-primary,
.bg-gray-900 .h4.text-primary,
.bg-gray-900 .h5.text-primary,
.bg-gray-900 .h6.text-primary {
  color: #1e5288;
}

.bg-gray-900 p.text-secondary,
.bg-gray-900 h1.text-secondary,
.bg-gray-900 h2.text-secondary,
.bg-gray-900 h3.text-secondary,
.bg-gray-900 h4.text-secondary,
.bg-gray-900 h5.text-secondary,
.bg-gray-900 h6.text-secondary,
.bg-gray-900 .h1.text-secondary,
.bg-gray-900 .h2.text-secondary,
.bg-gray-900 .h3.text-secondary,
.bg-gray-900 .h4.text-secondary,
.bg-gray-900 .h5.text-secondary,
.bg-gray-900 .h6.text-secondary {
  color: #9eabae;
}

.bg-gray-900 p.text-success,
.bg-gray-900 h1.text-success,
.bg-gray-900 h2.text-success,
.bg-gray-900 h3.text-success,
.bg-gray-900 h4.text-success,
.bg-gray-900 h5.text-success,
.bg-gray-900 h6.text-success,
.bg-gray-900 .h1.text-success,
.bg-gray-900 .h2.text-success,
.bg-gray-900 .h3.text-success,
.bg-gray-900 .h4.text-success,
.bg-gray-900 .h5.text-success,
.bg-gray-900 .h6.text-success {
  color: #70b865;
}

.bg-gray-900 p.text-info,
.bg-gray-900 h1.text-info,
.bg-gray-900 h2.text-info,
.bg-gray-900 h3.text-info,
.bg-gray-900 h4.text-info,
.bg-gray-900 h5.text-info,
.bg-gray-900 h6.text-info,
.bg-gray-900 .h1.text-info,
.bg-gray-900 .h2.text-info,
.bg-gray-900 .h3.text-info,
.bg-gray-900 .h4.text-info,
.bg-gray-900 .h5.text-info,
.bg-gray-900 .h6.text-info {
  color: #81d3eb;
}

.bg-gray-900 p.text-warning,
.bg-gray-900 h1.text-warning,
.bg-gray-900 h2.text-warning,
.bg-gray-900 h3.text-warning,
.bg-gray-900 h4.text-warning,
.bg-gray-900 h5.text-warning,
.bg-gray-900 h6.text-warning,
.bg-gray-900 .h1.text-warning,
.bg-gray-900 .h2.text-warning,
.bg-gray-900 .h3.text-warning,
.bg-gray-900 .h4.text-warning,
.bg-gray-900 .h5.text-warning,
.bg-gray-900 .h6.text-warning {
  color: #f19e1f;
}

.bg-gray-900 p.text-danger,
.bg-gray-900 h1.text-danger,
.bg-gray-900 h2.text-danger,
.bg-gray-900 h3.text-danger,
.bg-gray-900 h4.text-danger,
.bg-gray-900 h5.text-danger,
.bg-gray-900 h6.text-danger,
.bg-gray-900 .h1.text-danger,
.bg-gray-900 .h2.text-danger,
.bg-gray-900 .h3.text-danger,
.bg-gray-900 .h4.text-danger,
.bg-gray-900 .h5.text-danger,
.bg-gray-900 .h6.text-danger {
  color: #ef4056;
}

.bg-gray-900 p.text-light,
.bg-gray-900 h1.text-light,
.bg-gray-900 h2.text-light,
.bg-gray-900 h3.text-light,
.bg-gray-900 h4.text-light,
.bg-gray-900 h5.text-light,
.bg-gray-900 h6.text-light,
.bg-gray-900 .h1.text-light,
.bg-gray-900 .h2.text-light,
.bg-gray-900 .h3.text-light,
.bg-gray-900 .h4.text-light,
.bg-gray-900 .h5.text-light,
.bg-gray-900 .h6.text-light {
  color: #dee2e6;
}

.bg-gray-900 p.text-dark,
.bg-gray-900 h1.text-dark,
.bg-gray-900 h2.text-dark,
.bg-gray-900 h3.text-dark,
.bg-gray-900 h4.text-dark,
.bg-gray-900 h5.text-dark,
.bg-gray-900 h6.text-dark,
.bg-gray-900 .h1.text-dark,
.bg-gray-900 .h2.text-dark,
.bg-gray-900 .h3.text-dark,
.bg-gray-900 .h4.text-dark,
.bg-gray-900 .h5.text-dark,
.bg-gray-900 .h6.text-dark {
  color: #343a40;
}

.bg-gray-900 p.text-primary-dark,
.bg-gray-900 h1.text-primary-dark,
.bg-gray-900 h2.text-primary-dark,
.bg-gray-900 h3.text-primary-dark,
.bg-gray-900 h4.text-primary-dark,
.bg-gray-900 h5.text-primary-dark,
.bg-gray-900 h6.text-primary-dark,
.bg-gray-900 .h1.text-primary-dark,
.bg-gray-900 .h2.text-primary-dark,
.bg-gray-900 .h3.text-primary-dark,
.bg-gray-900 .h4.text-primary-dark,
.bg-gray-900 .h5.text-primary-dark,
.bg-gray-900 .h6.text-primary-dark {
  color: #0c234b;
}

.bg-gray-900 p.text-primary-light,
.bg-gray-900 h1.text-primary-light,
.bg-gray-900 h2.text-primary-light,
.bg-gray-900 h3.text-primary-light,
.bg-gray-900 h4.text-primary-light,
.bg-gray-900 h5.text-primary-light,
.bg-gray-900 h6.text-primary-light,
.bg-gray-900 .h1.text-primary-light,
.bg-gray-900 .h2.text-primary-light,
.bg-gray-900 .h3.text-primary-light,
.bg-gray-900 .h4.text-primary-light,
.bg-gray-900 .h5.text-primary-light,
.bg-gray-900 .h6.text-primary-light {
  color: #378dbd;
}

.bg-gray-900 p.text-accent,
.bg-gray-900 h1.text-accent,
.bg-gray-900 h2.text-accent,
.bg-gray-900 h3.text-accent,
.bg-gray-900 h4.text-accent,
.bg-gray-900 h5.text-accent,
.bg-gray-900 h6.text-accent,
.bg-gray-900 .h1.text-accent,
.bg-gray-900 .h2.text-accent,
.bg-gray-900 .h3.text-accent,
.bg-gray-900 .h4.text-accent,
.bg-gray-900 .h5.text-accent,
.bg-gray-900 .h6.text-accent {
  color: #ab0520;
}

.bg-light {
  color: #000;
  background-color: #dee2e6;
}

.bg-light p,
.bg-light h1,
.bg-light h2,
.bg-light h3,
.bg-light h4,
.bg-light h5,
.bg-light h6,
.bg-light .h1,
.bg-light .h2,
.bg-light .h3,
.bg-light .h4,
.bg-light .h5,
.bg-light .h6 {
  color: #000;
}

.bg-light p.text-blue,
.bg-light h1.text-blue,
.bg-light h2.text-blue,
.bg-light h3.text-blue,
.bg-light h4.text-blue,
.bg-light h5.text-blue,
.bg-light h6.text-blue,
.bg-light .h1.text-blue,
.bg-light .h2.text-blue,
.bg-light .h3.text-blue,
.bg-light .h4.text-blue,
.bg-light .h5.text-blue,
.bg-light .h6.text-blue {
  color: #0c234b;
}

.bg-light p.text-indigo,
.bg-light h1.text-indigo,
.bg-light h2.text-indigo,
.bg-light h3.text-indigo,
.bg-light h4.text-indigo,
.bg-light h5.text-indigo,
.bg-light h6.text-indigo,
.bg-light .h1.text-indigo,
.bg-light .h2.text-indigo,
.bg-light .h3.text-indigo,
.bg-light .h4.text-indigo,
.bg-light .h5.text-indigo,
.bg-light .h6.text-indigo {
  color: #6610f2;
}

.bg-light p.text-purple,
.bg-light h1.text-purple,
.bg-light h2.text-purple,
.bg-light h3.text-purple,
.bg-light h4.text-purple,
.bg-light h5.text-purple,
.bg-light h6.text-purple,
.bg-light .h1.text-purple,
.bg-light .h2.text-purple,
.bg-light .h3.text-purple,
.bg-light .h4.text-purple,
.bg-light .h5.text-purple,
.bg-light .h6.text-purple {
  color: #6f42c1;
}

.bg-light p.text-pink,
.bg-light h1.text-pink,
.bg-light h2.text-pink,
.bg-light h3.text-pink,
.bg-light h4.text-pink,
.bg-light h5.text-pink,
.bg-light h6.text-pink,
.bg-light .h1.text-pink,
.bg-light .h2.text-pink,
.bg-light .h3.text-pink,
.bg-light .h4.text-pink,
.bg-light .h5.text-pink,
.bg-light .h6.text-pink {
  color: #e83e8c;
}

.bg-light p.text-red,
.bg-light h1.text-red,
.bg-light h2.text-red,
.bg-light h3.text-red,
.bg-light h4.text-red,
.bg-light h5.text-red,
.bg-light h6.text-red,
.bg-light .h1.text-red,
.bg-light .h2.text-red,
.bg-light .h3.text-red,
.bg-light .h4.text-red,
.bg-light .h5.text-red,
.bg-light .h6.text-red {
  color: #ab0520;
}

.bg-light p.text-orange,
.bg-light h1.text-orange,
.bg-light h2.text-orange,
.bg-light h3.text-orange,
.bg-light h4.text-orange,
.bg-light h5.text-orange,
.bg-light h6.text-orange,
.bg-light .h1.text-orange,
.bg-light .h2.text-orange,
.bg-light .h3.text-orange,
.bg-light .h4.text-orange,
.bg-light .h5.text-orange,
.bg-light .h6.text-orange {
  color: #fd7e14;
}

.bg-light p.text-yellow,
.bg-light h1.text-yellow,
.bg-light h2.text-yellow,
.bg-light h3.text-yellow,
.bg-light h4.text-yellow,
.bg-light h5.text-yellow,
.bg-light h6.text-yellow,
.bg-light .h1.text-yellow,
.bg-light .h2.text-yellow,
.bg-light .h3.text-yellow,
.bg-light .h4.text-yellow,
.bg-light .h5.text-yellow,
.bg-light .h6.text-yellow {
  color: #ffc107;
}

.bg-light p.text-green,
.bg-light h1.text-green,
.bg-light h2.text-green,
.bg-light h3.text-green,
.bg-light h4.text-green,
.bg-light h5.text-green,
.bg-light h6.text-green,
.bg-light .h1.text-green,
.bg-light .h2.text-green,
.bg-light .h3.text-green,
.bg-light .h4.text-green,
.bg-light .h5.text-green,
.bg-light .h6.text-green {
  color: #28a745;
}

.bg-light p.text-teal,
.bg-light h1.text-teal,
.bg-light h2.text-teal,
.bg-light h3.text-teal,
.bg-light h4.text-teal,
.bg-light h5.text-teal,
.bg-light h6.text-teal,
.bg-light .h1.text-teal,
.bg-light .h2.text-teal,
.bg-light .h3.text-teal,
.bg-light .h4.text-teal,
.bg-light .h5.text-teal,
.bg-light .h6.text-teal {
  color: #20c997;
}

.bg-light p.text-cyan,
.bg-light h1.text-cyan,
.bg-light h2.text-cyan,
.bg-light h3.text-cyan,
.bg-light h4.text-cyan,
.bg-light h5.text-cyan,
.bg-light h6.text-cyan,
.bg-light .h1.text-cyan,
.bg-light .h2.text-cyan,
.bg-light .h3.text-cyan,
.bg-light .h4.text-cyan,
.bg-light .h5.text-cyan,
.bg-light .h6.text-cyan {
  color: #17a2b8;
}

.bg-light p.text-white,
.bg-light h1.text-white,
.bg-light h2.text-white,
.bg-light h3.text-white,
.bg-light h4.text-white,
.bg-light h5.text-white,
.bg-light h6.text-white,
.bg-light .h1.text-white,
.bg-light .h2.text-white,
.bg-light .h3.text-white,
.bg-light .h4.text-white,
.bg-light .h5.text-white,
.bg-light .h6.text-white {
  color: #fff;
}

.bg-light p.text-gray,
.bg-light h1.text-gray,
.bg-light h2.text-gray,
.bg-light h3.text-gray,
.bg-light h4.text-gray,
.bg-light h5.text-gray,
.bg-light h6.text-gray,
.bg-light .h1.text-gray,
.bg-light .h2.text-gray,
.bg-light .h3.text-gray,
.bg-light .h4.text-gray,
.bg-light .h5.text-gray,
.bg-light .h6.text-gray {
  color: #6c757d;
}

.bg-light p.text-gray-dark,
.bg-light h1.text-gray-dark,
.bg-light h2.text-gray-dark,
.bg-light h3.text-gray-dark,
.bg-light h4.text-gray-dark,
.bg-light h5.text-gray-dark,
.bg-light h6.text-gray-dark,
.bg-light .h1.text-gray-dark,
.bg-light .h2.text-gray-dark,
.bg-light .h3.text-gray-dark,
.bg-light .h4.text-gray-dark,
.bg-light .h5.text-gray-dark,
.bg-light .h6.text-gray-dark {
  color: #343a40;
}

.bg-light p.text-bloom,
.bg-light h1.text-bloom,
.bg-light h2.text-bloom,
.bg-light h3.text-bloom,
.bg-light h4.text-bloom,
.bg-light h5.text-bloom,
.bg-light h6.text-bloom,
.bg-light .h1.text-bloom,
.bg-light .h2.text-bloom,
.bg-light .h3.text-bloom,
.bg-light .h4.text-bloom,
.bg-light .h5.text-bloom,
.bg-light .h6.text-bloom {
  color: #ef4056;
}

.bg-light p.text-chili,
.bg-light h1.text-chili,
.bg-light h2.text-chili,
.bg-light h3.text-chili,
.bg-light h4.text-chili,
.bg-light h5.text-chili,
.bg-light h6.text-chili,
.bg-light .h1.text-chili,
.bg-light .h2.text-chili,
.bg-light .h3.text-chili,
.bg-light .h4.text-chili,
.bg-light .h5.text-chili,
.bg-light .h6.text-chili {
  color: #8b0015;
}

.bg-light p.text-sky,
.bg-light h1.text-sky,
.bg-light h2.text-sky,
.bg-light h3.text-sky,
.bg-light h4.text-sky,
.bg-light h5.text-sky,
.bg-light h6.text-sky,
.bg-light .h1.text-sky,
.bg-light .h2.text-sky,
.bg-light .h3.text-sky,
.bg-light .h4.text-sky,
.bg-light .h5.text-sky,
.bg-light .h6.text-sky {
  color: #81d3eb;
}

.bg-light p.text-oasis,
.bg-light h1.text-oasis,
.bg-light h2.text-oasis,
.bg-light h3.text-oasis,
.bg-light h4.text-oasis,
.bg-light h5.text-oasis,
.bg-light h6.text-oasis,
.bg-light .h1.text-oasis,
.bg-light .h2.text-oasis,
.bg-light .h3.text-oasis,
.bg-light .h4.text-oasis,
.bg-light .h5.text-oasis,
.bg-light .h6.text-oasis {
  color: #378dbd;
}

.bg-light p.text-azurite,
.bg-light h1.text-azurite,
.bg-light h2.text-azurite,
.bg-light h3.text-azurite,
.bg-light h4.text-azurite,
.bg-light h5.text-azurite,
.bg-light h6.text-azurite,
.bg-light .h1.text-azurite,
.bg-light .h2.text-azurite,
.bg-light .h3.text-azurite,
.bg-light .h4.text-azurite,
.bg-light .h5.text-azurite,
.bg-light .h6.text-azurite {
  color: #1e5288;
}

.bg-light p.text-midnight,
.bg-light h1.text-midnight,
.bg-light h2.text-midnight,
.bg-light h3.text-midnight,
.bg-light h4.text-midnight,
.bg-light h5.text-midnight,
.bg-light h6.text-midnight,
.bg-light .h1.text-midnight,
.bg-light .h2.text-midnight,
.bg-light .h3.text-midnight,
.bg-light .h4.text-midnight,
.bg-light .h5.text-midnight,
.bg-light .h6.text-midnight {
  color: #001c48;
}

.bg-light p.text-cool-gray,
.bg-light h1.text-cool-gray,
.bg-light h2.text-cool-gray,
.bg-light h3.text-cool-gray,
.bg-light h4.text-cool-gray,
.bg-light h5.text-cool-gray,
.bg-light h6.text-cool-gray,
.bg-light .h1.text-cool-gray,
.bg-light .h2.text-cool-gray,
.bg-light .h3.text-cool-gray,
.bg-light .h4.text-cool-gray,
.bg-light .h5.text-cool-gray,
.bg-light .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-light p.text-warm-gray,
.bg-light h1.text-warm-gray,
.bg-light h2.text-warm-gray,
.bg-light h3.text-warm-gray,
.bg-light h4.text-warm-gray,
.bg-light h5.text-warm-gray,
.bg-light h6.text-warm-gray,
.bg-light .h1.text-warm-gray,
.bg-light .h2.text-warm-gray,
.bg-light .h3.text-warm-gray,
.bg-light .h4.text-warm-gray,
.bg-light .h5.text-warm-gray,
.bg-light .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-light p.text-leaf,
.bg-light h1.text-leaf,
.bg-light h2.text-leaf,
.bg-light h3.text-leaf,
.bg-light h4.text-leaf,
.bg-light h5.text-leaf,
.bg-light h6.text-leaf,
.bg-light .h1.text-leaf,
.bg-light .h2.text-leaf,
.bg-light .h3.text-leaf,
.bg-light .h4.text-leaf,
.bg-light .h5.text-leaf,
.bg-light .h6.text-leaf {
  color: #70b865;
}

.bg-light p.text-river,
.bg-light h1.text-river,
.bg-light h2.text-river,
.bg-light h3.text-river,
.bg-light h4.text-river,
.bg-light h5.text-river,
.bg-light h6.text-river,
.bg-light .h1.text-river,
.bg-light .h2.text-river,
.bg-light .h3.text-river,
.bg-light .h4.text-river,
.bg-light .h5.text-river,
.bg-light .h6.text-river {
  color: #007d84;
}

.bg-light p.text-silver,
.bg-light h1.text-silver,
.bg-light h2.text-silver,
.bg-light h3.text-silver,
.bg-light h4.text-silver,
.bg-light h5.text-silver,
.bg-light h6.text-silver,
.bg-light .h1.text-silver,
.bg-light .h2.text-silver,
.bg-light .h3.text-silver,
.bg-light .h4.text-silver,
.bg-light .h5.text-silver,
.bg-light .h6.text-silver {
  color: #9eabae;
}

.bg-light p.text-mesa,
.bg-light h1.text-mesa,
.bg-light h2.text-mesa,
.bg-light h3.text-mesa,
.bg-light h4.text-mesa,
.bg-light h5.text-mesa,
.bg-light h6.text-mesa,
.bg-light .h1.text-mesa,
.bg-light .h2.text-mesa,
.bg-light .h3.text-mesa,
.bg-light .h4.text-mesa,
.bg-light .h5.text-mesa,
.bg-light .h6.text-mesa {
  color: #a95c42;
}

.bg-light p.text-ash,
.bg-light h1.text-ash,
.bg-light h2.text-ash,
.bg-light h3.text-ash,
.bg-light h4.text-ash,
.bg-light h5.text-ash,
.bg-light h6.text-ash,
.bg-light .h1.text-ash,
.bg-light .h2.text-ash,
.bg-light .h3.text-ash,
.bg-light .h4.text-ash,
.bg-light .h5.text-ash,
.bg-light .h6.text-ash {
  color: #403635;
}

.bg-light p.text-sage,
.bg-light h1.text-sage,
.bg-light h2.text-sage,
.bg-light h3.text-sage,
.bg-light h4.text-sage,
.bg-light h5.text-sage,
.bg-light h6.text-sage,
.bg-light .h1.text-sage,
.bg-light .h2.text-sage,
.bg-light .h3.text-sage,
.bg-light .h4.text-sage,
.bg-light .h5.text-sage,
.bg-light .h6.text-sage {
  color: #4a634e;
}

.bg-light p.text-black,
.bg-light h1.text-black,
.bg-light h2.text-black,
.bg-light h3.text-black,
.bg-light h4.text-black,
.bg-light h5.text-black,
.bg-light h6.text-black,
.bg-light .h1.text-black,
.bg-light .h2.text-black,
.bg-light .h3.text-black,
.bg-light .h4.text-black,
.bg-light .h5.text-black,
.bg-light .h6.text-black {
  color: #000;
}

.bg-light p.text-primary,
.bg-light h1.text-primary,
.bg-light h2.text-primary,
.bg-light h3.text-primary,
.bg-light h4.text-primary,
.bg-light h5.text-primary,
.bg-light h6.text-primary,
.bg-light .h1.text-primary,
.bg-light .h2.text-primary,
.bg-light .h3.text-primary,
.bg-light .h4.text-primary,
.bg-light .h5.text-primary,
.bg-light .h6.text-primary {
  color: #1e5288;
}

.bg-light p.text-secondary,
.bg-light h1.text-secondary,
.bg-light h2.text-secondary,
.bg-light h3.text-secondary,
.bg-light h4.text-secondary,
.bg-light h5.text-secondary,
.bg-light h6.text-secondary,
.bg-light .h1.text-secondary,
.bg-light .h2.text-secondary,
.bg-light .h3.text-secondary,
.bg-light .h4.text-secondary,
.bg-light .h5.text-secondary,
.bg-light .h6.text-secondary {
  color: #9eabae;
}

.bg-light p.text-success,
.bg-light h1.text-success,
.bg-light h2.text-success,
.bg-light h3.text-success,
.bg-light h4.text-success,
.bg-light h5.text-success,
.bg-light h6.text-success,
.bg-light .h1.text-success,
.bg-light .h2.text-success,
.bg-light .h3.text-success,
.bg-light .h4.text-success,
.bg-light .h5.text-success,
.bg-light .h6.text-success {
  color: #70b865;
}

.bg-light p.text-info,
.bg-light h1.text-info,
.bg-light h2.text-info,
.bg-light h3.text-info,
.bg-light h4.text-info,
.bg-light h5.text-info,
.bg-light h6.text-info,
.bg-light .h1.text-info,
.bg-light .h2.text-info,
.bg-light .h3.text-info,
.bg-light .h4.text-info,
.bg-light .h5.text-info,
.bg-light .h6.text-info {
  color: #81d3eb;
}

.bg-light p.text-warning,
.bg-light h1.text-warning,
.bg-light h2.text-warning,
.bg-light h3.text-warning,
.bg-light h4.text-warning,
.bg-light h5.text-warning,
.bg-light h6.text-warning,
.bg-light .h1.text-warning,
.bg-light .h2.text-warning,
.bg-light .h3.text-warning,
.bg-light .h4.text-warning,
.bg-light .h5.text-warning,
.bg-light .h6.text-warning {
  color: #f19e1f;
}

.bg-light p.text-danger,
.bg-light h1.text-danger,
.bg-light h2.text-danger,
.bg-light h3.text-danger,
.bg-light h4.text-danger,
.bg-light h5.text-danger,
.bg-light h6.text-danger,
.bg-light .h1.text-danger,
.bg-light .h2.text-danger,
.bg-light .h3.text-danger,
.bg-light .h4.text-danger,
.bg-light .h5.text-danger,
.bg-light .h6.text-danger {
  color: #ef4056;
}

.bg-light p.text-light,
.bg-light h1.text-light,
.bg-light h2.text-light,
.bg-light h3.text-light,
.bg-light h4.text-light,
.bg-light h5.text-light,
.bg-light h6.text-light,
.bg-light .h1.text-light,
.bg-light .h2.text-light,
.bg-light .h3.text-light,
.bg-light .h4.text-light,
.bg-light .h5.text-light,
.bg-light .h6.text-light {
  color: #dee2e6;
}

.bg-light p.text-dark,
.bg-light h1.text-dark,
.bg-light h2.text-dark,
.bg-light h3.text-dark,
.bg-light h4.text-dark,
.bg-light h5.text-dark,
.bg-light h6.text-dark,
.bg-light .h1.text-dark,
.bg-light .h2.text-dark,
.bg-light .h3.text-dark,
.bg-light .h4.text-dark,
.bg-light .h5.text-dark,
.bg-light .h6.text-dark {
  color: #343a40;
}

.bg-light p.text-primary-dark,
.bg-light h1.text-primary-dark,
.bg-light h2.text-primary-dark,
.bg-light h3.text-primary-dark,
.bg-light h4.text-primary-dark,
.bg-light h5.text-primary-dark,
.bg-light h6.text-primary-dark,
.bg-light .h1.text-primary-dark,
.bg-light .h2.text-primary-dark,
.bg-light .h3.text-primary-dark,
.bg-light .h4.text-primary-dark,
.bg-light .h5.text-primary-dark,
.bg-light .h6.text-primary-dark {
  color: #0c234b;
}

.bg-light p.text-primary-light,
.bg-light h1.text-primary-light,
.bg-light h2.text-primary-light,
.bg-light h3.text-primary-light,
.bg-light h4.text-primary-light,
.bg-light h5.text-primary-light,
.bg-light h6.text-primary-light,
.bg-light .h1.text-primary-light,
.bg-light .h2.text-primary-light,
.bg-light .h3.text-primary-light,
.bg-light .h4.text-primary-light,
.bg-light .h5.text-primary-light,
.bg-light .h6.text-primary-light {
  color: #378dbd;
}

.bg-light p.text-accent,
.bg-light h1.text-accent,
.bg-light h2.text-accent,
.bg-light h3.text-accent,
.bg-light h4.text-accent,
.bg-light h5.text-accent,
.bg-light h6.text-accent,
.bg-light .h1.text-accent,
.bg-light .h2.text-accent,
.bg-light .h3.text-accent,
.bg-light .h4.text-accent,
.bg-light .h5.text-accent,
.bg-light .h6.text-accent {
  color: #ab0520;
}

.bg-dark {
  color: #fff;
  background-color: #343a40;
}

.bg-dark p,
.bg-dark h1,
.bg-dark h2,
.bg-dark h3,
.bg-dark h4,
.bg-dark h5,
.bg-dark h6,
.bg-dark .h1,
.bg-dark .h2,
.bg-dark .h3,
.bg-dark .h4,
.bg-dark .h5,
.bg-dark .h6 {
  color: #fff;
}

.bg-dark p.text-blue,
.bg-dark h1.text-blue,
.bg-dark h2.text-blue,
.bg-dark h3.text-blue,
.bg-dark h4.text-blue,
.bg-dark h5.text-blue,
.bg-dark h6.text-blue,
.bg-dark .h1.text-blue,
.bg-dark .h2.text-blue,
.bg-dark .h3.text-blue,
.bg-dark .h4.text-blue,
.bg-dark .h5.text-blue,
.bg-dark .h6.text-blue {
  color: #0c234b;
}

.bg-dark p.text-indigo,
.bg-dark h1.text-indigo,
.bg-dark h2.text-indigo,
.bg-dark h3.text-indigo,
.bg-dark h4.text-indigo,
.bg-dark h5.text-indigo,
.bg-dark h6.text-indigo,
.bg-dark .h1.text-indigo,
.bg-dark .h2.text-indigo,
.bg-dark .h3.text-indigo,
.bg-dark .h4.text-indigo,
.bg-dark .h5.text-indigo,
.bg-dark .h6.text-indigo {
  color: #6610f2;
}

.bg-dark p.text-purple,
.bg-dark h1.text-purple,
.bg-dark h2.text-purple,
.bg-dark h3.text-purple,
.bg-dark h4.text-purple,
.bg-dark h5.text-purple,
.bg-dark h6.text-purple,
.bg-dark .h1.text-purple,
.bg-dark .h2.text-purple,
.bg-dark .h3.text-purple,
.bg-dark .h4.text-purple,
.bg-dark .h5.text-purple,
.bg-dark .h6.text-purple {
  color: #6f42c1;
}

.bg-dark p.text-pink,
.bg-dark h1.text-pink,
.bg-dark h2.text-pink,
.bg-dark h3.text-pink,
.bg-dark h4.text-pink,
.bg-dark h5.text-pink,
.bg-dark h6.text-pink,
.bg-dark .h1.text-pink,
.bg-dark .h2.text-pink,
.bg-dark .h3.text-pink,
.bg-dark .h4.text-pink,
.bg-dark .h5.text-pink,
.bg-dark .h6.text-pink {
  color: #e83e8c;
}

.bg-dark p.text-red,
.bg-dark h1.text-red,
.bg-dark h2.text-red,
.bg-dark h3.text-red,
.bg-dark h4.text-red,
.bg-dark h5.text-red,
.bg-dark h6.text-red,
.bg-dark .h1.text-red,
.bg-dark .h2.text-red,
.bg-dark .h3.text-red,
.bg-dark .h4.text-red,
.bg-dark .h5.text-red,
.bg-dark .h6.text-red {
  color: #ab0520;
}

.bg-dark p.text-orange,
.bg-dark h1.text-orange,
.bg-dark h2.text-orange,
.bg-dark h3.text-orange,
.bg-dark h4.text-orange,
.bg-dark h5.text-orange,
.bg-dark h6.text-orange,
.bg-dark .h1.text-orange,
.bg-dark .h2.text-orange,
.bg-dark .h3.text-orange,
.bg-dark .h4.text-orange,
.bg-dark .h5.text-orange,
.bg-dark .h6.text-orange {
  color: #fd7e14;
}

.bg-dark p.text-yellow,
.bg-dark h1.text-yellow,
.bg-dark h2.text-yellow,
.bg-dark h3.text-yellow,
.bg-dark h4.text-yellow,
.bg-dark h5.text-yellow,
.bg-dark h6.text-yellow,
.bg-dark .h1.text-yellow,
.bg-dark .h2.text-yellow,
.bg-dark .h3.text-yellow,
.bg-dark .h4.text-yellow,
.bg-dark .h5.text-yellow,
.bg-dark .h6.text-yellow {
  color: #ffc107;
}

.bg-dark p.text-green,
.bg-dark h1.text-green,
.bg-dark h2.text-green,
.bg-dark h3.text-green,
.bg-dark h4.text-green,
.bg-dark h5.text-green,
.bg-dark h6.text-green,
.bg-dark .h1.text-green,
.bg-dark .h2.text-green,
.bg-dark .h3.text-green,
.bg-dark .h4.text-green,
.bg-dark .h5.text-green,
.bg-dark .h6.text-green {
  color: #28a745;
}

.bg-dark p.text-teal,
.bg-dark h1.text-teal,
.bg-dark h2.text-teal,
.bg-dark h3.text-teal,
.bg-dark h4.text-teal,
.bg-dark h5.text-teal,
.bg-dark h6.text-teal,
.bg-dark .h1.text-teal,
.bg-dark .h2.text-teal,
.bg-dark .h3.text-teal,
.bg-dark .h4.text-teal,
.bg-dark .h5.text-teal,
.bg-dark .h6.text-teal {
  color: #20c997;
}

.bg-dark p.text-cyan,
.bg-dark h1.text-cyan,
.bg-dark h2.text-cyan,
.bg-dark h3.text-cyan,
.bg-dark h4.text-cyan,
.bg-dark h5.text-cyan,
.bg-dark h6.text-cyan,
.bg-dark .h1.text-cyan,
.bg-dark .h2.text-cyan,
.bg-dark .h3.text-cyan,
.bg-dark .h4.text-cyan,
.bg-dark .h5.text-cyan,
.bg-dark .h6.text-cyan {
  color: #17a2b8;
}

.bg-dark p.text-white,
.bg-dark h1.text-white,
.bg-dark h2.text-white,
.bg-dark h3.text-white,
.bg-dark h4.text-white,
.bg-dark h5.text-white,
.bg-dark h6.text-white,
.bg-dark .h1.text-white,
.bg-dark .h2.text-white,
.bg-dark .h3.text-white,
.bg-dark .h4.text-white,
.bg-dark .h5.text-white,
.bg-dark .h6.text-white {
  color: #fff;
}

.bg-dark p.text-gray,
.bg-dark h1.text-gray,
.bg-dark h2.text-gray,
.bg-dark h3.text-gray,
.bg-dark h4.text-gray,
.bg-dark h5.text-gray,
.bg-dark h6.text-gray,
.bg-dark .h1.text-gray,
.bg-dark .h2.text-gray,
.bg-dark .h3.text-gray,
.bg-dark .h4.text-gray,
.bg-dark .h5.text-gray,
.bg-dark .h6.text-gray {
  color: #6c757d;
}

.bg-dark p.text-gray-dark,
.bg-dark h1.text-gray-dark,
.bg-dark h2.text-gray-dark,
.bg-dark h3.text-gray-dark,
.bg-dark h4.text-gray-dark,
.bg-dark h5.text-gray-dark,
.bg-dark h6.text-gray-dark,
.bg-dark .h1.text-gray-dark,
.bg-dark .h2.text-gray-dark,
.bg-dark .h3.text-gray-dark,
.bg-dark .h4.text-gray-dark,
.bg-dark .h5.text-gray-dark,
.bg-dark .h6.text-gray-dark {
  color: #343a40;
}

.bg-dark p.text-bloom,
.bg-dark h1.text-bloom,
.bg-dark h2.text-bloom,
.bg-dark h3.text-bloom,
.bg-dark h4.text-bloom,
.bg-dark h5.text-bloom,
.bg-dark h6.text-bloom,
.bg-dark .h1.text-bloom,
.bg-dark .h2.text-bloom,
.bg-dark .h3.text-bloom,
.bg-dark .h4.text-bloom,
.bg-dark .h5.text-bloom,
.bg-dark .h6.text-bloom {
  color: #ef4056;
}

.bg-dark p.text-chili,
.bg-dark h1.text-chili,
.bg-dark h2.text-chili,
.bg-dark h3.text-chili,
.bg-dark h4.text-chili,
.bg-dark h5.text-chili,
.bg-dark h6.text-chili,
.bg-dark .h1.text-chili,
.bg-dark .h2.text-chili,
.bg-dark .h3.text-chili,
.bg-dark .h4.text-chili,
.bg-dark .h5.text-chili,
.bg-dark .h6.text-chili {
  color: #8b0015;
}

.bg-dark p.text-sky,
.bg-dark h1.text-sky,
.bg-dark h2.text-sky,
.bg-dark h3.text-sky,
.bg-dark h4.text-sky,
.bg-dark h5.text-sky,
.bg-dark h6.text-sky,
.bg-dark .h1.text-sky,
.bg-dark .h2.text-sky,
.bg-dark .h3.text-sky,
.bg-dark .h4.text-sky,
.bg-dark .h5.text-sky,
.bg-dark .h6.text-sky {
  color: #81d3eb;
}

.bg-dark p.text-oasis,
.bg-dark h1.text-oasis,
.bg-dark h2.text-oasis,
.bg-dark h3.text-oasis,
.bg-dark h4.text-oasis,
.bg-dark h5.text-oasis,
.bg-dark h6.text-oasis,
.bg-dark .h1.text-oasis,
.bg-dark .h2.text-oasis,
.bg-dark .h3.text-oasis,
.bg-dark .h4.text-oasis,
.bg-dark .h5.text-oasis,
.bg-dark .h6.text-oasis {
  color: #378dbd;
}

.bg-dark p.text-azurite,
.bg-dark h1.text-azurite,
.bg-dark h2.text-azurite,
.bg-dark h3.text-azurite,
.bg-dark h4.text-azurite,
.bg-dark h5.text-azurite,
.bg-dark h6.text-azurite,
.bg-dark .h1.text-azurite,
.bg-dark .h2.text-azurite,
.bg-dark .h3.text-azurite,
.bg-dark .h4.text-azurite,
.bg-dark .h5.text-azurite,
.bg-dark .h6.text-azurite {
  color: #1e5288;
}

.bg-dark p.text-midnight,
.bg-dark h1.text-midnight,
.bg-dark h2.text-midnight,
.bg-dark h3.text-midnight,
.bg-dark h4.text-midnight,
.bg-dark h5.text-midnight,
.bg-dark h6.text-midnight,
.bg-dark .h1.text-midnight,
.bg-dark .h2.text-midnight,
.bg-dark .h3.text-midnight,
.bg-dark .h4.text-midnight,
.bg-dark .h5.text-midnight,
.bg-dark .h6.text-midnight {
  color: #001c48;
}

.bg-dark p.text-cool-gray,
.bg-dark h1.text-cool-gray,
.bg-dark h2.text-cool-gray,
.bg-dark h3.text-cool-gray,
.bg-dark h4.text-cool-gray,
.bg-dark h5.text-cool-gray,
.bg-dark h6.text-cool-gray,
.bg-dark .h1.text-cool-gray,
.bg-dark .h2.text-cool-gray,
.bg-dark .h3.text-cool-gray,
.bg-dark .h4.text-cool-gray,
.bg-dark .h5.text-cool-gray,
.bg-dark .h6.text-cool-gray {
  color: #e2e9eb;
}

.bg-dark p.text-warm-gray,
.bg-dark h1.text-warm-gray,
.bg-dark h2.text-warm-gray,
.bg-dark h3.text-warm-gray,
.bg-dark h4.text-warm-gray,
.bg-dark h5.text-warm-gray,
.bg-dark h6.text-warm-gray,
.bg-dark .h1.text-warm-gray,
.bg-dark .h2.text-warm-gray,
.bg-dark .h3.text-warm-gray,
.bg-dark .h4.text-warm-gray,
.bg-dark .h5.text-warm-gray,
.bg-dark .h6.text-warm-gray {
  color: #f4ede5;
}

.bg-dark p.text-leaf,
.bg-dark h1.text-leaf,
.bg-dark h2.text-leaf,
.bg-dark h3.text-leaf,
.bg-dark h4.text-leaf,
.bg-dark h5.text-leaf,
.bg-dark h6.text-leaf,
.bg-dark .h1.text-leaf,
.bg-dark .h2.text-leaf,
.bg-dark .h3.text-leaf,
.bg-dark .h4.text-leaf,
.bg-dark .h5.text-leaf,
.bg-dark .h6.text-leaf {
  color: #70b865;
}

.bg-dark p.text-river,
.bg-dark h1.text-river,
.bg-dark h2.text-river,
.bg-dark h3.text-river,
.bg-dark h4.text-river,
.bg-dark h5.text-river,
.bg-dark h6.text-river,
.bg-dark .h1.text-river,
.bg-dark .h2.text-river,
.bg-dark .h3.text-river,
.bg-dark .h4.text-river,
.bg-dark .h5.text-river,
.bg-dark .h6.text-river {
  color: #007d84;
}

.bg-dark p.text-silver,
.bg-dark h1.text-silver,
.bg-dark h2.text-silver,
.bg-dark h3.text-silver,
.bg-dark h4.text-silver,
.bg-dark h5.text-silver,
.bg-dark h6.text-silver,
.bg-dark .h1.text-silver,
.bg-dark .h2.text-silver,
.bg-dark .h3.text-silver,
.bg-dark .h4.text-silver,
.bg-dark .h5.text-silver,
.bg-dark .h6.text-silver {
  color: #9eabae;
}

.bg-dark p.text-mesa,
.bg-dark h1.text-mesa,
.bg-dark h2.text-mesa,
.bg-dark h3.text-mesa,
.bg-dark h4.text-mesa,
.bg-dark h5.text-mesa,
.bg-dark h6.text-mesa,
.bg-dark .h1.text-mesa,
.bg-dark .h2.text-mesa,
.bg-dark .h3.text-mesa,
.bg-dark .h4.text-mesa,
.bg-dark .h5.text-mesa,
.bg-dark .h6.text-mesa {
  color: #a95c42;
}

.bg-dark p.text-ash,
.bg-dark h1.text-ash,
.bg-dark h2.text-ash,
.bg-dark h3.text-ash,
.bg-dark h4.text-ash,
.bg-dark h5.text-ash,
.bg-dark h6.text-ash,
.bg-dark .h1.text-ash,
.bg-dark .h2.text-ash,
.bg-dark .h3.text-ash,
.bg-dark .h4.text-ash,
.bg-dark .h5.text-ash,
.bg-dark .h6.text-ash {
  color: #403635;
}

.bg-dark p.text-sage,
.bg-dark h1.text-sage,
.bg-dark h2.text-sage,
.bg-dark h3.text-sage,
.bg-dark h4.text-sage,
.bg-dark h5.text-sage,
.bg-dark h6.text-sage,
.bg-dark .h1.text-sage,
.bg-dark .h2.text-sage,
.bg-dark .h3.text-sage,
.bg-dark .h4.text-sage,
.bg-dark .h5.text-sage,
.bg-dark .h6.text-sage {
  color: #4a634e;
}

.bg-dark p.text-black,
.bg-dark h1.text-black,
.bg-dark h2.text-black,
.bg-dark h3.text-black,
.bg-dark h4.text-black,
.bg-dark h5.text-black,
.bg-dark h6.text-black,
.bg-dark .h1.text-black,
.bg-dark .h2.text-black,
.bg-dark .h3.text-black,
.bg-dark .h4.text-black,
.bg-dark .h5.text-black,
.bg-dark .h6.text-black {
  color: #000;
}

.bg-dark p.text-primary,
.bg-dark h1.text-primary,
.bg-dark h2.text-primary,
.bg-dark h3.text-primary,
.bg-dark h4.text-primary,
.bg-dark h5.text-primary,
.bg-dark h6.text-primary,
.bg-dark .h1.text-primary,
.bg-dark .h2.text-primary,
.bg-dark .h3.text-primary,
.bg-dark .h4.text-primary,
.bg-dark .h5.text-primary,
.bg-dark .h6.text-primary {
  color: #1e5288;
}

.bg-dark p.text-secondary,
.bg-dark h1.text-secondary,
.bg-dark h2.text-secondary,
.bg-dark h3.text-secondary,
.bg-dark h4.text-secondary,
.bg-dark h5.text-secondary,
.bg-dark h6.text-secondary,
.bg-dark .h1.text-secondary,
.bg-dark .h2.text-secondary,
.bg-dark .h3.text-secondary,
.bg-dark .h4.text-secondary,
.bg-dark .h5.text-secondary,
.bg-dark .h6.text-secondary {
  color: #9eabae;
}

.bg-dark p.text-success,
.bg-dark h1.text-success,
.bg-dark h2.text-success,
.bg-dark h3.text-success,
.bg-dark h4.text-success,
.bg-dark h5.text-success,
.bg-dark h6.text-success,
.bg-dark .h1.text-success,
.bg-dark .h2.text-success,
.bg-dark .h3.text-success,
.bg-dark .h4.text-success,
.bg-dark .h5.text-success,
.bg-dark .h6.text-success {
  color: #70b865;
}

.bg-dark p.text-info,
.bg-dark h1.text-info,
.bg-dark h2.text-info,
.bg-dark h3.text-info,
.bg-dark h4.text-info,
.bg-dark h5.text-info,
.bg-dark h6.text-info,
.bg-dark .h1.text-info,
.bg-dark .h2.text-info,
.bg-dark .h3.text-info,
.bg-dark .h4.text-info,
.bg-dark .h5.text-info,
.bg-dark .h6.text-info {
  color: #81d3eb;
}

.bg-dark p.text-warning,
.bg-dark h1.text-warning,
.bg-dark h2.text-warning,
.bg-dark h3.text-warning,
.bg-dark h4.text-warning,
.bg-dark h5.text-warning,
.bg-dark h6.text-warning,
.bg-dark .h1.text-warning,
.bg-dark .h2.text-warning,
.bg-dark .h3.text-warning,
.bg-dark .h4.text-warning,
.bg-dark .h5.text-warning,
.bg-dark .h6.text-warning {
  color: #f19e1f;
}

.bg-dark p.text-danger,
.bg-dark h1.text-danger,
.bg-dark h2.text-danger,
.bg-dark h3.text-danger,
.bg-dark h4.text-danger,
.bg-dark h5.text-danger,
.bg-dark h6.text-danger,
.bg-dark .h1.text-danger,
.bg-dark .h2.text-danger,
.bg-dark .h3.text-danger,
.bg-dark .h4.text-danger,
.bg-dark .h5.text-danger,
.bg-dark .h6.text-danger {
  color: #ef4056;
}

.bg-dark p.text-light,
.bg-dark h1.text-light,
.bg-dark h2.text-light,
.bg-dark h3.text-light,
.bg-dark h4.text-light,
.bg-dark h5.text-light,
.bg-dark h6.text-light,
.bg-dark .h1.text-light,
.bg-dark .h2.text-light,
.bg-dark .h3.text-light,
.bg-dark .h4.text-light,
.bg-dark .h5.text-light,
.bg-dark .h6.text-light {
  color: #dee2e6;
}

.bg-dark p.text-dark,
.bg-dark h1.text-dark,
.bg-dark h2.text-dark,
.bg-dark h3.text-dark,
.bg-dark h4.text-dark,
.bg-dark h5.text-dark,
.bg-dark h6.text-dark,
.bg-dark .h1.text-dark,
.bg-dark .h2.text-dark,
.bg-dark .h3.text-dark,
.bg-dark .h4.text-dark,
.bg-dark .h5.text-dark,
.bg-dark .h6.text-dark {
  color: #343a40;
}

.bg-dark p.text-primary-dark,
.bg-dark h1.text-primary-dark,
.bg-dark h2.text-primary-dark,
.bg-dark h3.text-primary-dark,
.bg-dark h4.text-primary-dark,
.bg-dark h5.text-primary-dark,
.bg-dark h6.text-primary-dark,
.bg-dark .h1.text-primary-dark,
.bg-dark .h2.text-primary-dark,
.bg-dark .h3.text-primary-dark,
.bg-dark .h4.text-primary-dark,
.bg-dark .h5.text-primary-dark,
.bg-dark .h6.text-primary-dark {
  color: #0c234b;
}

.bg-dark p.text-primary-light,
.bg-dark h1.text-primary-light,
.bg-dark h2.text-primary-light,
.bg-dark h3.text-primary-light,
.bg-dark h4.text-primary-light,
.bg-dark h5.text-primary-light,
.bg-dark h6.text-primary-light,
.bg-dark .h1.text-primary-light,
.bg-dark .h2.text-primary-light,
.bg-dark .h3.text-primary-light,
.bg-dark .h4.text-primary-light,
.bg-dark .h5.text-primary-light,
.bg-dark .h6.text-primary-light {
  color: #378dbd;
}

.bg-dark p.text-accent,
.bg-dark h1.text-accent,
.bg-dark h2.text-accent,
.bg-dark h3.text-accent,
.bg-dark h4.text-accent,
.bg-dark h5.text-accent,
.bg-dark h6.text-accent,
.bg-dark .h1.text-accent,
.bg-dark .h2.text-accent,
.bg-dark .h3.text-accent,
.bg-dark .h4.text-accent,
.bg-dark .h5.text-accent,
.bg-dark .h6.text-accent {
  color: #ab0520;
}

.bg-dark .close {
  color: #fff;
}

.border-blue {
  border-color: #0c234b;
}

.border-indigo {
  border-color: #6610f2;
}

.border-purple {
  border-color: #6f42c1;
}

.border-pink {
  border-color: #e83e8c;
}

.border-red {
  border-color: #ab0520;
}

.border-orange {
  border-color: #fd7e14;
}

.border-yellow {
  border-color: #ffc107;
}

.border-green {
  border-color: #28a745;
}

.border-teal {
  border-color: #20c997;
}

.border-cyan {
  border-color: #17a2b8;
}

.border-white {
  border-color: #fff;
}

.border-gray {
  border-color: #6c757d;
}

.border-gray-dark {
  border-color: #343a40;
}

.border-bloom {
  border-color: #ef4056;
}

.border-chili {
  border-color: #8b0015;
}

.border-sky {
  border-color: #81d3eb;
}

.border-oasis {
  border-color: #378dbd;
}

.border-azurite {
  border-color: #1e5288;
}

.border-midnight {
  border-color: #001c48;
}

.border-cool-gray {
  border-color: #e2e9eb;
}

.border-warm-gray {
  border-color: #f4ede5;
}

.border-leaf {
  border-color: #70b865;
}

.border-river {
  border-color: #007d84;
}

.border-silver {
  border-color: #9eabae;
}

.border-mesa {
  border-color: #a95c42;
}

.border-ash {
  border-color: #403635;
}

.border-sage {
  border-color: #4a634e;
}

.border-black {
  border-color: #000;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.15em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.15em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.jumbotron.jumbotron-dark, .jumbotron.jumbotron-blue, .jumbotron.jumbotron-red {
  color: #fff;
}

.jumbotron.jumbotron-dark {
  background-color: #343a40;
}

.jumbotron.jumbotron-blue {
  background-color: extra-color("primary-dark");
}

.jumbotron.jumbotron-red {
  background-color: extra-color("accent");
}

.table .thead-light th {
  color: #343a40;
  background-color: #dee2e6;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(222, 226, 230, 0.4);
}

.table-hover tbody tr:hover {
  background-color: rgba(222, 226, 230, 0.5);
}

h1.serif,
h2.serif,
.h1.serif,
.h2.serif {
  font-family: MiloSerifWeb, TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif !important;
}

.display-1,
.display-2,
.display-3,
.display-4 {
  font-family: MiloSerifWeb, TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif !important;
}

.text-serif {
  font-family: MiloSerifWeb, TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif !important;
}

ul.list-triangle {
  overflow: hidden;
}

ul.list-triangle > li,
ul.list-triangle > ul li {
  padding-left: 0;
  position: relative;
  list-style: none;
}

ul.list-triangle > li:before,
ul.list-triangle > ul li:before {
  position: absolute;
  top: 8px;
  left: -0.8em;
  width: 0;
  height: 0;
  border-left: 4px solid #000;
  border-bottom: 4px solid transparent;
  content: "";
  border-top: 4px solid transparent;
  border-right: 0;
}

.bg-blue {
  background-color: #0c234b !important;
}

a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #050e1f !important;
}

.bg-indigo {
  background-color: #6610f2 !important;
}

a.bg-indigo:hover, a.bg-indigo:focus,
button.bg-indigo:hover,
button.bg-indigo:focus {
  background-color: #510bc4 !important;
}

.bg-purple {
  background-color: #6f42c1 !important;
}

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #59339d !important;
}

.bg-pink {
  background-color: #e83e8c !important;
}

a.bg-pink:hover, a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
  background-color: #d91a72 !important;
}

.bg-red {
  background-color: #ab0520 !important;
}

a.bg-red:hover, a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
  background-color: #790417 !important;
}

.bg-orange {
  background-color: #fd7e14 !important;
}

a.bg-orange:hover, a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
  background-color: #dc6502 !important;
}

.bg-yellow {
  background-color: #ffc107 !important;
}

a.bg-yellow:hover, a.bg-yellow:focus,
button.bg-yellow:hover,
button.bg-yellow:focus {
  background-color: #d39e00 !important;
}

.bg-green {
  background-color: #28a745 !important;
}

a.bg-green:hover, a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
  background-color: #1e7e34 !important;
}

.bg-teal {
  background-color: #20c997 !important;
}

a.bg-teal:hover, a.bg-teal:focus,
button.bg-teal:hover,
button.bg-teal:focus {
  background-color: #199d76 !important;
}

.bg-cyan {
  background-color: #17a2b8 !important;
}

a.bg-cyan:hover, a.bg-cyan:focus,
button.bg-cyan:hover,
button.bg-cyan:focus {
  background-color: #117a8b !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-gray {
  background-color: #6c757d !important;
}

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #545b62 !important;
}

.bg-gray-dark {
  background-color: #343a40 !important;
}

a.bg-gray-dark:hover, a.bg-gray-dark:focus,
button.bg-gray-dark:hover,
button.bg-gray-dark:focus {
  background-color: #1d2124 !important;
}

.bg-bloom {
  background-color: #ef4056 !important;
}

a.bg-bloom:hover, a.bg-bloom:focus,
button.bg-bloom:hover,
button.bg-bloom:focus {
  background-color: #e9132e !important;
}

.bg-chili {
  background-color: #8b0015 !important;
}

a.bg-chili:hover, a.bg-chili:focus,
button.bg-chili:hover,
button.bg-chili:focus {
  background-color: #58000d !important;
}

.bg-sky {
  background-color: #81d3eb !important;
}

a.bg-sky:hover, a.bg-sky:focus,
button.bg-sky:hover,
button.bg-sky:focus {
  background-color: #55c4e4 !important;
}

.bg-oasis {
  background-color: #378dbd !important;
}

a.bg-oasis:hover, a.bg-oasis:focus,
button.bg-oasis:hover,
button.bg-oasis:focus {
  background-color: #2c7095 !important;
}

.bg-azurite {
  background-color: #1e5288 !important;
}

a.bg-azurite:hover, a.bg-azurite:focus,
button.bg-azurite:hover,
button.bg-azurite:focus {
  background-color: #15395e !important;
}

.bg-midnight {
  background-color: #001c48 !important;
}

a.bg-midnight:hover, a.bg-midnight:focus,
button.bg-midnight:hover,
button.bg-midnight:focus {
  background-color: #000815 !important;
}

.bg-cool-gray {
  background-color: #e2e9eb !important;
}

a.bg-cool-gray:hover, a.bg-cool-gray:focus,
button.bg-cool-gray:hover,
button.bg-cool-gray:focus {
  background-color: #c4d2d6 !important;
}

.bg-warm-gray {
  background-color: #f4ede5 !important;
}

a.bg-warm-gray:hover, a.bg-warm-gray:focus,
button.bg-warm-gray:hover,
button.bg-warm-gray:focus {
  background-color: #e5d4c1 !important;
}

.bg-leaf {
  background-color: #70b865 !important;
}

a.bg-leaf:hover, a.bg-leaf:focus,
button.bg-leaf:hover,
button.bg-leaf:focus {
  background-color: #55a04a !important;
}

.bg-river {
  background-color: #007d84 !important;
}

a.bg-river:hover, a.bg-river:focus,
button.bg-river:hover,
button.bg-river:focus {
  background-color: #004d51 !important;
}

.bg-silver {
  background-color: #9eabae !important;
}

a.bg-silver:hover, a.bg-silver:focus,
button.bg-silver:hover,
button.bg-silver:focus {
  background-color: #829397 !important;
}

.bg-mesa {
  background-color: #a95c42 !important;
}

a.bg-mesa:hover, a.bg-mesa:focus,
button.bg-mesa:hover,
button.bg-mesa:focus {
  background-color: #844834 !important;
}

.bg-ash {
  background-color: #403635 !important;
}

a.bg-ash:hover, a.bg-ash:focus,
button.bg-ash:hover,
button.bg-ash:focus {
  background-color: #241e1e !important;
}

.bg-sage {
  background-color: #4a634e !important;
}

a.bg-sage:hover, a.bg-sage:focus,
button.bg-sage:hover,
button.bg-sage:focus {
  background-color: #344637 !important;
}

.bg-black {
  background-color: #000 !important;
}

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: black !important;
}

.text-blue {
  color: #0c234b !important;
}

a.text-blue:hover, a.text-blue:focus {
  color: #010409 !important;
}

.text-indigo {
  color: #6610f2 !important;
}

a.text-indigo:hover, a.text-indigo:focus {
  color: #4709ac !important;
}

.text-purple {
  color: #6f42c1 !important;
}

a.text-purple:hover, a.text-purple:focus {
  color: #4e2d89 !important;
}

.text-pink {
  color: #e83e8c !important;
}

a.text-pink:hover, a.text-pink:focus {
  color: #c21766 !important;
}

.text-red {
  color: #ab0520 !important;
}

a.text-red:hover, a.text-red:focus {
  color: #610312 !important;
}

.text-orange {
  color: #fd7e14 !important;
}

a.text-orange:hover, a.text-orange:focus {
  color: #c35a02 !important;
}

.text-yellow {
  color: #ffc107 !important;
}

a.text-yellow:hover, a.text-yellow:focus {
  color: #ba8b00 !important;
}

.text-green {
  color: #28a745 !important;
}

a.text-green:hover, a.text-green:focus {
  color: #19692c !important;
}

.text-teal {
  color: #20c997 !important;
}

a.text-teal:hover, a.text-teal:focus {
  color: #158765 !important;
}

.text-cyan {
  color: #17a2b8 !important;
}

a.text-cyan:hover, a.text-cyan:focus {
  color: #0f6674 !important;
}

.text-white {
  color: #fff !important;
}

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important;
}

.text-gray {
  color: #6c757d !important;
}

a.text-gray:hover, a.text-gray:focus {
  color: #494f54 !important;
}

.text-gray-dark {
  color: #343a40 !important;
}

a.text-gray-dark:hover, a.text-gray-dark:focus {
  color: #121416 !important;
}

.text-bloom {
  color: #ef4056 !important;
}

a.text-bloom:hover, a.text-bloom:focus {
  color: #d1122a !important;
}

.text-chili {
  color: #8b0015 !important;
}

a.text-chili:hover, a.text-chili:focus {
  color: #3f0009 !important;
}

.text-sky {
  color: #81d3eb !important;
}

a.text-sky:hover, a.text-sky:focus {
  color: #3fbce1 !important;
}

.text-oasis {
  color: #378dbd !important;
}

a.text-oasis:hover, a.text-oasis:focus {
  color: #266182 !important;
}

.text-azurite {
  color: #1e5288 !important;
}

a.text-azurite:hover, a.text-azurite:focus {
  color: #102c49 !important;
}

.text-midnight {
  color: #001c48 !important;
}

a.text-midnight:hover, a.text-midnight:focus {
  color: black !important;
}

.text-cool-gray {
  color: #e2e9eb !important;
}

a.text-cool-gray:hover, a.text-cool-gray:focus {
  color: #b5c7cc !important;
}

.text-warm-gray {
  color: #f4ede5 !important;
}

a.text-warm-gray:hover, a.text-warm-gray:focus {
  color: #ddc8af !important;
}

.text-leaf {
  color: #70b865 !important;
}

a.text-leaf:hover, a.text-leaf:focus {
  color: #4c8f42 !important;
}

.text-river {
  color: #007d84 !important;
}

a.text-river:hover, a.text-river:focus {
  color: #003538 !important;
}

.text-silver {
  color: #9eabae !important;
}

a.text-silver:hover, a.text-silver:focus {
  color: #74878b !important;
}

.text-mesa {
  color: #a95c42 !important;
}

a.text-mesa:hover, a.text-mesa:focus {
  color: #723e2d !important;
}

.text-ash {
  color: #403635 !important;
}

a.text-ash:hover, a.text-ash:focus {
  color: #161312 !important;
}

.text-sage {
  color: #4a634e !important;
}

a.text-sage:hover, a.text-sage:focus {
  color: #29372c !important;
}

.text-black {
  color: #000 !important;
}

a.text-black:hover, a.text-black:focus {
  color: black !important;
}
/*# sourceMappingURL=bootstrap.css.map */