/*!
 * FSO-Bootstrap v1.1.0 (https://bootstrap.docs.fso.arizona.edu/)
 * Copyright 2017-2018 FSO IT - FAST Team
 * Licenced under MIT (https://gitlab.fso.arizona.edu/FAST/fso-bootstrap/blob/master/LICENSE)
 * A theme for Bootstrap
 */

// Additions and Tweaks
@import "https://cdn.uadigital.arizona.edu/lib/ua-brand-fonts/1.0.0/milo.min.css";
@import "variables";

// Bootstrap (imported here so that our variables don't get overriden
@import "../node_modules/bootstrap/scss/bootstrap";

// Overrides
@import "mixins/bg_transparent";
@import "mixins/caret";
@import "card";
@import "colors";
@import "dropdowns";
@import "jumbotron";
@import "table";
@import "type";
@import "utilities";
