// >> Card Header
.card {
  .card-header {
    background-color: $card-cap-bg;
  }
}

.card[class*=" bg-"] {
  .card-header {
    background-color: $card-cap-bg-darken;
  }
}

// check for color contrast
@each $color, $value in $theme-colors {
  .bg-#{$color} {
    // check for color contrast
    @if $value == $sky or $value == $leaf {
      .card-header {
        color: $midnight;
      }
    } @else if $value == $bloom {
      .card-header {
        color: $black;
      }
    }
  }
}
